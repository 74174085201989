import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect, useState } from 'react';

import { RootState } from 'redux/Store/store';
import { useIntl } from 'react-intl';

import Search from 'components/customSearch/Search';
import CustomSelect from 'components/customSelect/CustomSelect';
import {
  clearSingleUserActivitySearcText,
  closeSingleUserActivitySearchInMobile,
  expandSingleUserActivitySearchInMobile,
  getSingleUserActivityFilterValue,
  getSingleUserActivitySearchText,
} from 'redux/singleUserActivity/Reducers/singleUserActivitySlice';

interface FilterProps {
  isFiltersApplied: boolean;
}

const SingleUserActivityFilters = ({ isFiltersApplied }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const userActivityData = useAppSelector((state: RootState) => state.singleUserActivity);


  const dispatch = useAppDispatch();





  
  const options = [
    {
      id: 0,
      name: 'By Newest',
    },
    {
      id: 1,
      name: 'By Oldest',
    },
  ];

  const handleSearchClose = () => {
    dispatch(closeSingleUserActivitySearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSingleUserActivitySearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSingleUserActivitySearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSingleUserActivitySearchText(value));
  };
  useEffect(() => {
    dispatch(closeSingleUserActivitySearchInMobile());
  }, [isMobileDevice]);

  const handleChange=(value:any)=>{
    dispatch(getSingleUserActivityFilterValue(value))
  }
  return (
    <Box>
      {((userActivityData.singleUserActivityData && userActivityData.singleUserActivityData.length > 0) ||
        isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="26px"
          alignItems="center"
          
        >
          <Search
            data={userActivityData}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />

          <CustomSelect handleSelectChange={handleChange} options={options} selectedValue={userActivityData.selectedOrderValue} />
        </Box>
      )}
    </Box>
  );
};
export default SingleUserActivityFilters;