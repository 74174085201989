import { MedicalOrder } from './Exam';

export enum MALE_OPTIONS {
  MALE = 'MALE',
  M = 'M',
}

export enum FEMALE_OPTIONS {
  FEMALE = 'FEMALE',
  F = 'F',
}

export enum OTHER_OPTIONS {
  OTHER = 'OTHER',
  O = 'O',
}

type Gender = MALE_OPTIONS | FEMALE_OPTIONS | OTHER_OPTIONS;

export interface Patient {
  id: number;
  uuid: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  sex: Gender;
  dateOfBirth: string;
  patientIdentifiers: PatientIdentifier[];
}

export interface WorklistItem extends Patient {
  middleName: string;
  medicalOrders?: MedicalOrder[];
}
export interface WorklistOrder {
  accessionNumber: string | null;
  examSource: number;
  mrn: string;
  id: number;
  patientId: number;
  patientIdType: string;
  orderTransactionDatetime: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  dateOfBirth: string | null;
  sex: string | null;
}

export interface WorklistMedicalOrderApiResponse {
  next?: number;
  previous?: number;
  count: number;
  results: WorklistOrder[] | [];
}
export interface UpdatedWorklistItem extends Omit<WorklistItem, 'medicalOrders'> {
  medicalOrder?: MedicalOrder;
}

export interface PatientIdentifier {
  id: number;
  identifierValue: string;
  identifierType: string;
  patient: number;
}

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}
