import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { Error_Type_Enum } from 'utils/enum';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/Store/store';
import { getRegions, getUsers, getUsersRoles, getUsersStatuses } from '../../redux/user/Actions/userActions';
import { getPageNumber } from '../../redux/user/Reducers/userSlice';
import UserFilters from './userFilters/UsersFilters';

import UsersMainContent from './userMainContent/UsersMainContent';
import UserTitle from './usersTitle/UserTitle';

const Users = () => {
  const toggle = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const usersData = useAppSelector((state: RootState) => state.user);
  const editUserApiFail = useAppSelector((state) => state.user.editUserPostDataFail);
  const dispatch = useAppDispatch();
  const initialRender = useRef({
    primary: true,
    secondary: true,
  });
  useEffect(() => {
    dispatch(getUsers(''));
    dispatch(getUsersRoles(0));
    dispatch(getUsersStatuses());
    dispatch(getRegions());
  }, []);

  const getUsersData = () => {
    const payload: any = {
      search: usersData.searchText,
      page: usersData.selectedPage,
      role: usersData.filterData.roles_id?.filter((itr: any) => itr !== 'All'),
      status: usersData.filterData.status_id?.filter((itr: any) => itr !== 'All'),
    };
    const queryString = Object.entries(payload)
      .map(([key, value]: any) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
    dispatch(getUsers(queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getUsersData();
  }, [usersData.selectedPage]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getPageNumber(1));
    getUsersData();
  }, [usersData.searchText, usersData.filterData]);

  useEffect(() => {
    if (editUserApiFail === Error_Type_Enum.Success) {
      getUsersData();
    }
  }, [editUserApiFail]);
  const filters = usersData.filterData;
  const search = usersData.searchText;
  const isFiltersApplied = !!(
    (filters && filters.roles_id && filters.roles_id.length) ||
    (filters && filters.status_id && filters.status_id.length) ||
    search
  );

  return (
    <Box
      pl="20px"
      id="devices"
      py={{ lg: '15px' }}
      w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 240px)' : 'calc(100vw - 100px)' }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent={{ xs: 'none', lg: 'space-between' }}
        transition="0.5s ease"
      >
        <UserTitle />
        <UserFilters isFiltersApplied={isFiltersApplied} />
      </Box>
      <UsersMainContent isFiltersApplied={isFiltersApplied} />
    </Box>
  );
};
export default Users;
