/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/
import { createSlice } from '@reduxjs/toolkit';
type InitialState = {
  mappingLicenseItemModalPopUp: boolean;
  licenseData: {};
  transactions: {
    items: any[];
  };
  licenseItemToBundleAPIDataSuccess: any[];
  licenseItemToBundleAPIDataFail: boolean;
  licenseItemToBundleAPIDataFailStrings: string;
  enableSpinner:boolean
  licenseItemSearchData:any[]
  licenseItemSearchDataFail:boolean
  licenseItemPostValue:string
  licenseItemSearchValue:string
  editLicenseItemToBundle:boolean
  singleLicenseItemToBundleDataLoading:boolean;
  singleLicenseItemToBundleDataSuccess:any[];
  singleLicenseItemToBundleDataFail:boolean
  licenseItemSearchInputLabelValue:string
  licenseDefaultValuePostData : any[]
  licenseDefaultValueAllInputFieldsPostData:any[]
};
export const initialState: InitialState = {
  mappingLicenseItemModalPopUp: false,
  licenseData: {},
  transactions: {
    items: [],
  },
  licenseItemToBundleAPIDataSuccess: [],
  licenseItemToBundleAPIDataFail: false,
  licenseItemToBundleAPIDataFailStrings: '',
  enableSpinner:false,
  licenseItemSearchData:[],
  licenseItemSearchDataFail:false,
  licenseItemPostValue:'',
  licenseItemSearchValue:'',
  editLicenseItemToBundle:false,
  singleLicenseItemToBundleDataLoading:false,
  singleLicenseItemToBundleDataSuccess:[],
  singleLicenseItemToBundleDataFail:false,
  licenseItemSearchInputLabelValue:'',
  licenseDefaultValuePostData : [],
  licenseDefaultValueAllInputFieldsPostData:[]
};
const assignLicenseItemToBundleSlice = createSlice({
  name: 'assignLicenseItemToBundle',
  initialState,
  reducers: {
    handleMappingLicenseItemModalPopUp: (state, { payload }) => {
      state.mappingLicenseItemModalPopUp = payload;
    },

    getLicenseObjectData: (state, { payload }) => {
      state.licenseData = payload;
    },
    // postLicenseItemToBundle
    //   fetchPostLicenseItemToBundle:(state,{payload}) => {
    //     state.postLicenseItemToBundle = payload
    //   },

    addTransaction: (state, action) => {
      state.transactions.items.push(action.payload);
    },
    postLicenseItemToBundleAPIData: (state, { payload }) => {
      state.licenseItemToBundleAPIDataSuccess = payload;
    },
    getLicenseItemToBundleAPIDataFail: (state, { payload }) => {
      state.licenseItemToBundleAPIDataFail = payload;
    },
    getLicenseItemToBundleAPIDataFailStrings: (state, { payload }) => {
      state.licenseItemToBundleAPIDataFailStrings = payload;
    },
    fetchLicenseItemToBundleSpinnerEnable : (state,{payload}) => {
        state.enableSpinner = payload
    },

    fetchLicenseItemSearchData : (state,{payload}) => {
      state.licenseItemSearchData = payload
    },
    fetchLicenseItemSearchDataFail : (state,{payload}) => {
      state.licenseItemSearchDataFail = payload
    },

    getLicenseItemPostValue: (state,{payload}) => {
      state.licenseItemPostValue = payload;
    },
    getLicenseItemSearchValue: (state,{payload}) => {
      state.licenseItemSearchValue = payload;
    },
    handleLicenseItemToBundleModal : (state,{payload}) => {
      state.editLicenseItemToBundle = payload
    },
    fetchSingleLicenseItemToBundleDataLoading: (state,{payload}) => {
     state.singleLicenseItemToBundleDataLoading = payload
    },
    fetchSingleLicenseItemToBundleDataSuccess: (state,{payload}) => {
      state.singleLicenseItemToBundleDataSuccess = payload
     },
     fetchSingleLicenseItemToBundleDataFail: (state,{payload}) => {
      state.singleLicenseItemToBundleDataFail = payload
     },
     getLicenseItemSearchInputLabelValue:(state,{payload}) => {
       state.licenseItemSearchInputLabelValue = payload
     },
     handleDefaultValueInputPostData : (state,{payload}) => {
     state.licenseDefaultValuePostData = payload
     },
     handleDefaultValueAllInputFieldsPostData : (state,{payload}) => {
      state.licenseDefaultValueAllInputFieldsPostData = payload
      },


    resetMappingLicenseItems: (state) => {
      state.mappingLicenseItemModalPopUp = false;
      state.licenseData = {};
      state.licenseDefaultValuePostData = []
      state.licenseDefaultValueAllInputFieldsPostData = []
      state.licenseItemSearchData = []
      state.licenseItemSearchInputLabelValue = ''
      state.licenseItemSearchDataFail = false
      state.licenseItemToBundleAPIDataSuccess = [];
      state.licenseItemToBundleAPIDataFailStrings = '';
      state.licenseItemToBundleAPIDataFail = false;
      state.enableSpinner = false
      state.licenseItemSearchValue = '';
      state.licenseItemPostValue = '';
      state.editLicenseItemToBundle = false;
      state.singleLicenseItemToBundleDataFail = false;
      state.singleLicenseItemToBundleDataSuccess = [];
      state.singleLicenseItemToBundleDataLoading = false
    },
  },
});
export const {
  handleDefaultValueInputPostData,
  handleMappingLicenseItemModalPopUp,
  fetchSingleLicenseItemToBundleDataLoading,
  fetchLicenseItemSearchData,
  fetchSingleLicenseItemToBundleDataSuccess,
  getLicenseItemPostValue,
  fetchLicenseItemSearchDataFail,
  resetMappingLicenseItems,
  fetchLicenseItemToBundleSpinnerEnable,
  fetchSingleLicenseItemToBundleDataFail,
  handleDefaultValueAllInputFieldsPostData,
  getLicenseItemSearchInputLabelValue,
  getLicenseObjectData,
  getLicenseItemSearchValue,
  handleLicenseItemToBundleModal,
  addTransaction,
  postLicenseItemToBundleAPIData,
  getLicenseItemToBundleAPIDataFailStrings,
  getLicenseItemToBundleAPIDataFail,
} = assignLicenseItemToBundleSlice.actions;
export default assignLicenseItemToBundleSlice.reducer;
