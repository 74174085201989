/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';
import { PaginationTypes } from 'types/pagination-types';

type InitialState = {
  searchText: string;
  clientAccessList: any;
  statusLoading: boolean;
  statusError: boolean;
  clientAccessListLoading: boolean;
  clientAccessListError: boolean;
  createOrganizationApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  clientAccessApiData: any;
  clientAccessnumberOfPages: number;
  clientAccesspaginationData: PaginationTypes | null;
  SingleOrganizationDeviceDataLoading: boolean;
  SingleOrganizationDeviceDataError: boolean;
  SingleOrganizationsDeviceGetData: any;
  SingleOrganizationsDeviceGetDataLoading: boolean;
  SingleOrganizationsDeviceGetDataError: boolean;
  singleOrganizationID: string | number;
  isSearchExpand: boolean;
  deviceCreateFromOrg: boolean;
  selectedOrderValue: number;
  settingsModalPopUp: boolean;
  clientNameInputValue: string;
  clientIDInputValue: string;
  clientSecretInputValue: string;
  clientDBConnectionInputValue: string;
  clientAudienceInputValue: string;
  clientPhoneInputValue: string;
  clientEmailInputValue: string;
  clientNotesInputValue: string;
  clientPostDataLoading: boolean;
  clientPostDataSuccess: [];
  clientPostDataFail: boolean;
  clientPostDataFailStrings: string;
  clientPostDataCreateButtonEnable: boolean;
  postClientApiData: boolean;
  postClientApiDataSpinner: boolean;
  enableSuccessfulPostData: boolean;
  clientInputNameValidation: boolean;
  clientIDValidation: boolean;
  clientDBValidation: boolean;
  clientSecretValidation: boolean;
  clientAudienceValidation: boolean;
  partnerAccessApiKey: string;
  partnerAccessApiKeyFail: boolean;
  showPasswordIds: string[];
  editSettingsModalPopUp: boolean;
  editPartnerAccessApiDataValue: any[];
  editPartnerAccessApiFail: string;
  getClientAccessStatus: any[];
  getClientAccessStatusFail: boolean;
  clientNameUniqueValidation: any;
  clientNameUniqueValidationFail: boolean;
  editClientAccessPostData: any[];
  editClientAccessPostDataFail: string;
  clientSinglePartnerID: any;
  clientAccessPostLoading: boolean;
  specialCharacterValidationSettings: boolean;
};
export const initialState: InitialState = {
  searchText: '',
  clientAccessList: [],
  statusLoading: false,
  statusError: false,
  clientAccessListLoading: false,
  clientAccessListError: false,
  selectedPage: 1,
  createOrganizationApiFail: '',
  clientAccessApiData: null,
  clientAccessnumberOfPages: 1,
  filterData: { status_id: [] },
  clientAccesspaginationData: null,
  SingleOrganizationDeviceDataLoading: false,
  SingleOrganizationDeviceDataError: false,
  SingleOrganizationsDeviceGetData: [],
  SingleOrganizationsDeviceGetDataLoading: false,
  SingleOrganizationsDeviceGetDataError: false,
  singleOrganizationID: '',
  isSearchExpand: false,
  deviceCreateFromOrg: false,
  selectedOrderValue: 0,
  settingsModalPopUp: false,
  clientNameInputValue: '',
  clientIDInputValue: '',
  clientSecretInputValue: '',
  clientDBConnectionInputValue: '',
  clientAudienceInputValue: '',
  clientPhoneInputValue: '',
  clientEmailInputValue: '',
  clientNotesInputValue: '',
  clientPostDataLoading: false,
  clientPostDataSuccess: [],
  clientPostDataFail: false,
  clientPostDataFailStrings: '',
  clientPostDataCreateButtonEnable: false,
  postClientApiData: false,
  postClientApiDataSpinner: false,
  enableSuccessfulPostData: false,
  clientInputNameValidation: false,
  clientIDValidation: false,
  clientDBValidation: false,
  clientSecretValidation: false,
  clientAudienceValidation: false,
  partnerAccessApiKey: '',
  partnerAccessApiKeyFail: false,
  showPasswordIds: [],
  editSettingsModalPopUp: false,
  editPartnerAccessApiDataValue: [],
  editPartnerAccessApiFail: '',
  getClientAccessStatus: [],
  getClientAccessStatusFail: false,
  clientNameUniqueValidation: '',
  clientNameUniqueValidationFail: false,
  editClientAccessPostData: [],
  editClientAccessPostDataFail: '',
  clientSinglePartnerID: '',
  clientAccessPostLoading: false,
  specialCharacterValidationSettings: false,
};

const clientAccessSlice = createSlice({
  name: 'clientAccessSlice',
  initialState,
  reducers: {
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchClientAcessDataLoading: (state) => {
      state.clientAccessListLoading = true;
    },
    fetchClientAccessDataFail: (state) => {
      state.clientAccessListError = true;
      state.clientAccessListLoading = false;
    },
    fetchClientAccessDataSuccess: (state, { payload }) => {
      state.clientAccessList = payload.data;
      state.clientAccessApiData = payload;
      state.clientAccessnumberOfPages = payload.pagination.num_pages;
      state.clientAccesspaginationData = payload.pagination;
      state.clientAccessListLoading = false;
    },
    getClientAccessFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
    // Create developer settings  popup

    handleSettingsModalPopUp: (state, { payload }) => {
      state.settingsModalPopUp = payload;
    },

    fetchClientNameInputValue: (state, { payload }) => {
      state.clientNameInputValue = payload;
    },
    fetchClientIDInputValue: (state, { payload }) => {
      state.clientIDInputValue = payload;
    },
    fetchClientSecretInputValue: (state, { payload }) => {
      state.clientSecretInputValue = payload;
    },
    fetchClientDBConnectionInputValue: (state, { payload }) => {
      state.clientDBConnectionInputValue = payload;
    },
    fetchClientAudienceInputValue: (state, { payload }) => {
      state.clientAudienceInputValue = payload;
    },
    fetchClienPhoneInputValue: (state, { payload }) => {
      state.clientPhoneInputValue = payload;
    },
    fetchClientEmailInputValue: (state, { payload }) => {
      state.clientEmailInputValue = payload;
    },
    fetchClientNotesInputValue: (state, { payload }) => {
      state.clientNotesInputValue = payload;
    },

    // post Client settings api fields
    getClientPostDataLoading: (state, { payload }) => {
      state.clientPostDataLoading = payload;
    },
    getClientPostDataSuccess: (state, { payload }) => {
      state.clientPostDataSuccess = payload;
    },
    getClientPostDataFail: (state, { payload }) => {
      state.clientPostDataFail = payload;
    },
    getClientPostDataFailStrings: (state, { payload }) => {
      state.clientPostDataFailStrings = payload;
    },

    handleClientPostDataCreateButtonEnable: (state, { payload }) => {
      state.clientPostDataCreateButtonEnable = payload;
    },
    handleToPostApiData: (state, { payload }) => {
      state.postClientApiData = payload;
    },
    handlePostApiDataSpinner: (state, { payload }) => {
      state.postClientApiDataSpinner = payload;
    },
    handleClientSuccessfulPost: (state, { payload }) => {
      state.enableSuccessfulPostData = payload;
    },

    // required validations for  mandatory input fields
    handleClientInputNameValidation: (state, { payload }) => {
      state.clientInputNameValidation = payload;
    },
    handleClientIDValidation: (state, { payload }) => {
      state.clientIDValidation = payload;
    },
    handleClientDBValidation: (state, { payload }) => {
      state.clientDBValidation = payload;
    },
    handleClientSecretValidation: (state, { payload }) => {
      state.clientSecretValidation = payload;
    },
    handleClientAudienceValidation: (state, { payload }) => {
      state.clientAudienceValidation = payload;
    },

    // generate api key

    getPartnerAccessApiKeyData: (state, { payload }) => {
      state.partnerAccessApiKey = payload;
    },
    getPartnerAccessApiKeyDataFail: (state, { payload }) => {
      state.partnerAccessApiKeyFail = payload;
    },

    // Edit Partnet access fields

    handleSEditettingsModalPopUp: (state, { payload }) => {
      state.editSettingsModalPopUp = payload;
    },
    editPartnerAccessApiData: (state, { payload }) => {
      state.editPartnerAccessApiDataValue = payload;
    },
    editPartnerAccessApiDataFail: (state, { payload }) => {
      state.editPartnerAccessApiFail = payload;
    },
    getClientAccessStatusData: (state, { payload }) => {
      state.getClientAccessStatus = payload;
    },
    getClientAccessStatusDataFail: (state, { payload }) => {
      state.getClientAccessStatusFail = payload;
    },
    fetchClientNameUniqueValidation: (state, { payload }) => {
      state.clientNameUniqueValidation = payload;
    },
    fetchClientNameUniqueValidationFail: (state, { payload }) => {
      state.clientNameUniqueValidationFail = payload;
    },
    editClientAccessData: (state, { payload }) => {
      state.editClientAccessPostData = payload;
    },
    editClientAccessDataFail: (state, { payload }) => {
      state.editClientAccessPostDataFail = payload;
    },
    clientAccessSinglePartnerID: (state, { payload }) => {
      state.clientSinglePartnerID = payload;
    },
    clientAccessPostDataLoading: (state, { payload }) => {
      state.clientAccessPostLoading = payload;
    },
    specialCharactersValidation: (state, { payload }) => {
      state.specialCharacterValidationSettings = payload;
    },

    resetCreateSettingsPopUpValues: (state) => {
      state.settingsModalPopUp = false;
      state.specialCharacterValidationSettings = false;
      state.clientAccessPostLoading = false;
      state.clientNameInputValue = '';
      state.editClientAccessPostData = [];
      state.partnerAccessApiKey = '';
      state.editClientAccessPostDataFail = '';
      state.clientSinglePartnerID = '';
      state.editPartnerAccessApiDataValue = [];
      state.editPartnerAccessApiFail = '';
      state.clientIDInputValue = '';
      state.clientSecretInputValue = '';
      state.editSettingsModalPopUp = false;
      state.clientDBConnectionInputValue = '';
      state.clientAudienceInputValue = '';
      state.getClientAccessStatus = [];
      state.getClientAccessStatusFail = false;
      state.clientPhoneInputValue = '';
      state.clientEmailInputValue = '';
      state.enableSuccessfulPostData = false;
      state.postClientApiDataSpinner = false;
      state.clientNotesInputValue = '';
      state.clientNameUniqueValidation = '';
      state.clientPostDataLoading = false;
      state.partnerAccessApiKeyFail = false;
      state.postClientApiData = false;
      state.clientPostDataSuccess = [];
      state.clientPostDataFail = false;
      state.clientNameUniqueValidationFail = false;
      state.clientPostDataFailStrings = '';
      state.clientPostDataCreateButtonEnable = false;
      state.clientInputNameValidation = false;
      state.clientIDValidation = false;
      state.clientDBValidation = false;
      state.clientSecretValidation = false;
      state.clientAudienceValidation = false;
    },
    fetchShowPasswordIds: (state, { payload }) => {
      let data = [...state.showPasswordIds];

      if (data.includes(payload)) {
        data = data.filter((item: any) => item !== payload);
      } else {
        data.push(payload);
      }
      state.showPasswordIds = data;
    },
  },
});
export const {
  getSearchText,
  editClientAccessData,
  fetchClientNameUniqueValidationFail,
  fetchClientNameUniqueValidation,
  specialCharactersValidation,
  getClientAccessStatusData,
  getClientPostDataFail,
  handleClientIDValidation,
  handleClientSecretValidation,
  handleSEditettingsModalPopUp,
  handleClientDBValidation,
  handleClientInputNameValidation,
  editPartnerAccessApiData,
  handleClientAudienceValidation,
  getClientPostDataSuccess,
  getClientPostDataLoading,
  getClientPostDataFailStrings,
  editClientAccessDataFail,
  fetchClientIDInputValue,
  clientAccessSinglePartnerID,
  clearSearcText,
  handlePostApiDataSpinner,
  handleToPostApiData,
  editPartnerAccessApiDataFail,
  fetchClientSecretInputValue,
  fetchClientDBConnectionInputValue,
  expandSearchInMobile,
  fetchClientEmailInputValue,
  handleClientPostDataCreateButtonEnable,
  closeSearchInMobile,
  handleClientSuccessfulPost,
  getPageNumber,
  fetchClientAccessDataFail,
  fetchClientAccessDataSuccess,
  getFlterData,
  getClientAccessFilterValue,
  getClientAccessStatusDataFail,
  fetchClientAudienceInputValue,
  fetchClientNotesInputValue,
  fetchClienPhoneInputValue,
  getPartnerAccessApiKeyData,
  getPartnerAccessApiKeyDataFail,
  resetCreateSettingsPopUpValues,
  clientAccessPostDataLoading,
  handleSettingsModalPopUp,
  fetchClientNameInputValue,
  fetchClientAcessDataLoading,
  fetchShowPasswordIds,
} = clientAccessSlice.actions;
export default clientAccessSlice.reducer;
