import { VersionInfo } from '../../types';
import { createSlice } from '@reduxjs/toolkit';
import { apiVersionThunks } from './apiVersion.thunk';

export type ApiVersionState = {
  versionInfo: null | VersionInfo;
};

const initialState: ApiVersionState = {
  versionInfo: null,
};

const apiVersion = createSlice({
  name: 'apiVersion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(apiVersionThunks.getVersion.fulfilled, (state, action) => {
      state.versionInfo = action.payload;
    });
  },
});

export const selectVersionInfo = (state: { apiVersion: ApiVersionState }) => state.apiVersion.versionInfo;
export const apiVersionReducer = apiVersion.reducer;
