import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
interface RootModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onClickOverlay?: () => void;
  buttonText: string;
}

const RootModal: React.FC<RootModalProps> = ({ isOpen, onClose, title, message, onClickOverlay, buttonText }) => {
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  return (
    <Modal isOpen={isOpen} onClose={onClickOverlay || onClose} isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 0.5)" />
      <ModalContent maxW="600px" w="100%" borderRadius="10px" boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)">
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          fontSize={isMobile ? '20px' : '24px'}
          fontWeight="700"
          mt="10px"
          pb="0px"
        >
          {title}
        </ModalHeader>
        <ModalBody fontSize={isMobile ? '14px' : '16px'}>
          <Text>{message}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="brandBlue.50"
            color="white"
            size={isMobile ? 'sm' : 'md'}
            width="100%"
            fontWeight="bold"
            onClick={onClose}
            _hover={{ bg: 'brandBlue.100' }}
            _active={{ bg: 'brandBlue.100' }}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RootModal;
