/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';
import axios from 'axios';
import { Dispatch } from 'react';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import {
  getClientPostDataFailStrings,
  getClientPostDataFail,
  getClientPostDataLoading,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { Error_Type_Enum } from '../../../../../../libs/admin-ui/src/lib/utils/enum';

import { setError } from '../../error/errorSlice';
import {
  fetchOrganizationLicenseFail,
  fetchOrganizationLicenseLoading,
  fetchOrganizationLicenseSuccess,
  getOrgLicensePostApiSuccess,
  handleContractDurationPeriodData,
  setAddonsIsLoading,
  setAddonsData,
  handleOrgAddOnModalFail,
  handleOrgAddOnModalloading,
  handleOrgAddOnModalSuccess,
} from '../Reduces/orgLicenseSlice';
import moment from 'moment';
import { OrgAddon } from 'types/organization-types';
import { PostOrgAddons } from 'types/orgAddOns';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getOrganizationLicenses = (id: any, queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrganizationLicenseLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/${id}/licenses/?${queryString}`);
    if (res && res.status === 200) {
      dispatch(fetchOrganizationLicenseSuccess(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchOrganizationLicenseFail());
  }
};
export const postOrgLicenseData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/${id}/licenses/`, postData);

    if (res && (res?.data?.status_code == 200 || res?.data?.status_code == 201)) {
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
      dispatch(getOrgLicensePostApiSuccess(true));
    } else {
      dispatch(getClientPostDataFail(res && res?.data));
      dispatch(handlePostApiFailureMessage(res?.data.message));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(getOrgLicensePostApiSuccess(false));
      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
    dispatch(getOrgLicensePostApiSuccess(false));
  }
};

export const getLicenseContractDurationPeriod = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`licenses/licenseperiod/`);
    if (res && res.status === 200) {
      dispatch(handleContractDurationPeriodData(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

// Organization License Add-ons

export const fetchOrgLicenseAddons =
  (orgId: string, pageNumber = 1, searchText = '') =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setAddonsIsLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(
        `/organizations/${orgId}/licenses/addons/?page=${pageNumber}&search=${encodeURIComponent(searchText)}`,
      );
      if (res && res.status === 200) {
        res.data.data = res?.data?.data?.map((item: any) => {
          return {
            id: item.id,
            name: item.license_addon.name,
            catalogNumber: item.license_addon.catalog_number,
            activationDate: item.start_date ? moment(item.start_date).format('ll') : '-',
            expirationDate: item.expiration_date ? moment(item.expiration_date).format('ll') : '-',
            orderId: item.order_id,
            status: item.status,
          } as OrgAddon;
        });
        dispatch(setAddonsData(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };
export const postOrgAddOns = (orgID: any, payload: PostOrgAddons) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(handleOrgAddOnModalloading(true));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/${orgID}/licenses/`, payload);
    if ((res && res.data.status_code === 200) || res.data.status_code === 201) {
      dispatch(handleOrgAddOnModalSuccess(true));
      dispatch(handleOrgAddOnModalFail({ isFail: false, errorMessage: '' }));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handleOrgAddOnModalFail({ isFail: true, errorMessage: error?.response?.data?.message }));
    dispatch(handleOrgAddOnModalSuccess(false));
  }
};
