/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';

import { Dispatch } from 'react';
import { setError } from 'redux/error/errorSlice';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import { DeviceTypeUniqueValidations } from 'types/device-types';
import { Error_Type_Enum } from 'utils/enum';
import {
  clientAccessPostDataLoading,
  editClientAccessData,
  editClientAccessDataFail,
  editPartnerAccessApiData,
  editPartnerAccessApiDataFail,
  fetchClientAccessDataFail,
  fetchClientAccessDataSuccess,
  fetchClientAcessDataLoading,
  fetchClientNameUniqueValidation,
  fetchClientNameUniqueValidationFail,
  getClientAccessStatusData,
  getClientAccessStatusDataFail,
  getClientPostDataFail,
  getClientPostDataFailStrings,
  getClientPostDataLoading,
  getClientPostDataSuccess,
  getPartnerAccessApiKeyData,
  getPartnerAccessApiKeyDataFail,
} from '../Reducers/clientAccessSlice';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getClientAccess = (id: any, queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchClientAcessDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`settings/?${queryString}`);
    if (res && res.status === 200) {
      dispatch(fetchClientAccessDataSuccess(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchClientAccessDataFail());
  }
};
export const postClientData = (postData: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(getClientPostDataLoading(true));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`settings/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataSuccess(res.data.result));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
      // dispatch(postApiSuccess(true))
    } else {
      dispatch(getClientPostDataFail(true));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    // dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handlePostApiFailureMessage(error?.response?.data?.message[0]));
    dispatch(getClientPostDataFail(true));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};

export const generateApiKey = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`settings/generate-api-key/`);
    if (res && res.status === 200) {
      dispatch(getPartnerAccessApiKeyData(res.data.data.api_key));
      dispatch(getPartnerAccessApiKeyDataFail(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getPartnerAccessApiKeyDataFail(true));
  }
};

export const editPartnerAccess = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`settings/${id}/`);
    if (res && (res.status === 200 || res.status === 201)) {
      dispatch(editPartnerAccessApiData(res.data.data));
      dispatch(editPartnerAccessApiDataFail(Error_Type_Enum.Success));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(editPartnerAccessApiDataFail(Error_Type_Enum.Fail));
  }
};
export const getClientStatus = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`settings/types/status/`);
    if (res && res.status === 200) {
      dispatch(getClientAccessStatusData(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientAccessStatusDataFail(true));
  }
};

export const getClientAccesssUniqueNameValidation =
  (PostUniqueValues: DeviceTypeUniqueValidations) => async (dispatch: Dispatch<IGetAllAssets>) => {
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`settings/validate/`, PostUniqueValues);
      if (res && res.data.status_code === 404) {
        dispatch(fetchClientNameUniqueValidation(PostUniqueValues.field_value));
        dispatch(fetchClientNameUniqueValidationFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchClientNameUniqueValidationFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchClientNameUniqueValidationFail(false));
    }
  };

export const ediClientAccessPostData = (postedData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(clientAccessPostDataLoading(true));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`settings/${id}/`, postedData);
    if (res && (res.status === 200 || res.status === 201)) {
      dispatch(editClientAccessData(res.data.result));
      dispatch(editClientAccessDataFail(Error_Type_Enum.Success));
      dispatch(clientAccessPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(editClientAccessDataFail(Error_Type_Enum.Fail));
    dispatch(clientAccessPostDataLoading(false));
  }
};
