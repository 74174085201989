import React from 'react';
import { Box } from '@chakra-ui/react';
import { RootState } from 'redux/Store/store';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleOragnizationsView } from 'redux/organizations/Reducers/organizationSlice';
import { GridViewIc, ListViewIc } from 'assets/icons/icons';
import { customColor } from 'assets/styles/colors';
const OrganizationsDataSwitch = ({handleClick,isGridView}:{handleClick:()=>void,isGridView:boolean}) => {
  const colors = customColor();

  return (
    <Box
      cursor="pointer"
      onClick={handleClick}
      w="25px"
      display={{ xs: 'none', md: 'block' }}
      data-testid="cards_toggle_switch"
      color={colors.primaryColor}
    >
      {isGridView ? <ListViewIc /> : <GridViewIc />}
    </Box>
  );
};

export default OrganizationsDataSwitch;
