import { useColorModeValue } from '@chakra-ui/react';

export const customColor = () => {
  return {
    primaryColor: useColorModeValue('light.100', 'dark.100'),
    sidebarColor: useColorModeValue('light.200', 'dark.200'),
    subTextColor: useColorModeValue('light.300', 'dark.300'),
    navColor: useColorModeValue('light.400', 'dark.400'),
    userNameColor: useColorModeValue('light.500', 'dark.500'),
    inputBorderColor: useColorModeValue('light.600', 'dark.600'),
    textColor: useColorModeValue('light.800', 'dark.800'),
    copyRightColor: useColorModeValue('light.900', 'dark.900'),
    searchColor: useColorModeValue('light.1200', 'dark.1200'),
    tableHeadColor: useColorModeValue('rgba(51, 51, 51, 0.8)', 'rgba(255, 255, 255, 0.8)'),
    paginationDisplay: useColorModeValue('light.1400', 'dark.1400'),
    lightModeBlue: '#24B0FF',
    darkModeBlue: '#10F8E4',
    lightModeBanner: '#FE2D531A',
    darkModeBanner: '#25292F',
    mobilePaginationColor: useColorModeValue('rgba(217, 217, 217, 0.17)', 'rgba(62, 70, 80, 1)'),
    lightDarkModeTheme: useColorModeValue('light.500', 'dark.1500'),
    sidebarIconsColor: useColorModeValue('rgb(51 51 51 / 70%)', 'rgba(255, 255, 255, 0.7)'),
    lightModeRed: '#FB5654',
    darkModeRed: '#FB5654',
  };
};
