/* eslint-disable max-lines */
/* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/
import { createSlice } from '@reduxjs/toolkit';
import {
  OrganizationTypes,
  TierObject,
  StatusObject,
  OrganizationApiResponse,
  OrgDevice,
  OrganizationDevicesApiResponse,
  StatusType,
} from '../../../types/organization-types';
import { Phone_Country_Constants } from 'utils/constants/phoneCountryConstants';
import { PaginationTypes } from 'types/pagination-types';
import { FeatureFlag } from 'types/FeatureFlag';

type InitialState = {
  searchText: string;
  isSearchExpand: boolean;
  toggleSidebarInDesktop: boolean;
  organizationsList: OrganizationTypes[];
  organizationsGridView: boolean;
  tiersData: TierObject[];
  tiersLoading: boolean;
  organizationInputNameValue: string;
  statusLoading: boolean;
  tiersError: boolean;
  statusError: boolean;
  statusData: StatusType[];
  statusFilterValue: string;
  tiersFilterValue: string;
  languageData: string[];
  languageLoading: boolean;
  languageError: boolean;
  organizationDataLoading: boolean;
  organizationDataError: boolean;
  organizationPostDataLoading: boolean;
  organizationPostDataError: boolean;
  organizationsPostData: OrganizationTypes[];
  createButton: boolean;
  organizationCountry: string;
  organizationLanguage: string;
  organizationTier: string;
  organizationBundleName: string;
  organizationsListLoading: boolean;
  organizationsListError: boolean;
  postOrganizationToggle: boolean;
  postData: boolean;
  organizationsCodeData: string[];
  organizationCodeDataLoading: boolean;
  organizationCodeDataError: boolean;
  fetchOrganizationCodeData: string[];
  organizationAutoPopulateData: string[];
  organizationAutoPopulateDataLoading: boolean;
  organizationAutoPopulateDataError: boolean;
  organizationsCode: string;
  organizationHandleName: boolean;
  organizationAdminEmail: boolean;
  organizationOwnerEmail: boolean;
  createOrganizationApiFail: string;
  filterData: { status_id: string[]; tier_id: string[] };
  selectedPage: number;
  organizationApiData: OrganizationApiResponse | null;
  numberOfPages: number;
  organizationOpenModal: boolean;
  phoneNumberValidation: boolean;
  phoneNumberCountryCode: string;
  adminPhoneNumberValidation: boolean;
  organizationSubmitButtonEnable: boolean;
  organizationSuccesCreation: boolean;
  organizationNameDataLoading: boolean;
  organizationNameDataError: boolean;
  organizationsNameData: string[];
  organizationsName: string;
  organizationCreateButtonClicked: boolean;
  isOrganizationFilterApplied: boolean;
  paginationData: PaginationTypes | null;
  organizationFirstNameLengthValidation: boolean;
  organizationLastNameLengthValidation: boolean;
  organizationOwnerFirstNameData: string;
  organizationOwnerLastNameData: string;
  organizationOwnerEmailData: boolean;
  organizationOwnerPhoneData: boolean;
  organizationsCodeInputValue: string;
  SingleOrganizationDataLoading: boolean;
  SingleOrganizationDataError: boolean;
  SingleOrganizationsGetData: any;
  singleOrganizationID: string | number;
  editOrganizationInfoLoading: boolean;
  editOrganizationInfoData: string[];
  editOrganizationInfoFail: boolean;
  editOrganizationApiFail: string;
  editOrganizationInfoApiFail: string;
  getOrganizationEmailValue: string;
  organizationOwnerPhoneValue: string;
  organizationNameInputLength: boolean;
  organizationCodeInputLength: boolean;
  organizationDevicesList: OrgDevice[];
  organizationDevicesApiData: OrganizationDevicesApiResponse | null;
  orgDevicesnumberOfPages: number;
  orgDevicespaginationData: PaginationTypes | null;
  organizationEmailValidationLoading: boolean;
  organizationEmailValidationSuccess: string;
  organizationEmailValidationFail: boolean;
  selectedSection: string;
  bundleLoading: boolean;
  bundleError: boolean;
  bundleData: string[];
  tierBundleValue: string;
  bundleTierPostID: string;
  openDeleteModal: boolean;
  orgNameSearchValidationValue: boolean;
  openProvisionedModalValue: boolean;
  getSearchOrgNameProvisonValue: string;
  getSearchOrgNameDeleteValue: string;
  getDeactivateModalValue: boolean;
  getActivateModalValue: boolean;
  deleteOrgSpecialCharacterValidation: boolean;
  activateOrgSpecialCharacterValidation: boolean;
  deactivateOrgSpecialCharacterValidation: boolean;
  provisonedOrgSpecialCharacterValidation: boolean;
  orgDeletePostDataFailStrings: string;
  postApiFailureMessage: string;
  emptyStatusInput: boolean;

  toggleOrgUsersForDeleteStatus: boolean;
  toggleOrgUsersForProvisionStatus: boolean;
  togglePausedStatusModal: boolean;
  durationLoading: boolean;
  orgExpirationDays: any;
  durationError: boolean;
  licenseItems: FeatureFlag[];
  saveOrgLicenseItemStatus: string;
  //addOrg
  isAddOrgModalOpen: boolean;
  orgOwnerInfo: any;

  licenseEndDateValue: string;
};
export const initialState: InitialState = {
  searchText: '',
  isSearchExpand: false,
  toggleSidebarInDesktop: false,
  organizationsList: [],
  organizationsGridView: false,
  tiersData: [],
  tiersLoading: false,
  statusLoading: false,
  tiersError: false,
  statusError: false,
  statusData: [],
  statusFilterValue: '',
  tiersFilterValue: '',
  languageData: [],
  languageLoading: false,
  languageError: false,
  organizationDataLoading: false,
  organizationDataError: false,
  organizationPostDataLoading: false,
  organizationPostDataError: false,
  organizationsPostData: [],
  createButton: false,
  organizationCountry: '',
  organizationLanguage: '',
  organizationTier: '',
  organizationsListLoading: false,
  organizationsListError: false,
  postOrganizationToggle: false,
  selectedPage: 1,
  organizationApiData: null,
  postData: false,
  organizationCodeDataLoading: false,
  organizationCodeDataError: false,
  organizationsCodeData: [],
  organizationsCode: '',
  organizationAutoPopulateDataLoading: false,
  organizationAutoPopulateDataError: false,
  organizationAutoPopulateData: [],
  organizationsName: '',
  organizationHandleName: false,
  organizationAdminEmail: false,
  organizationOwnerEmail: false,
  createOrganizationApiFail: '',
  numberOfPages: 1,
  filterData: {
    status_id: [],
    tier_id: [],
  },
  organizationOpenModal: false,
  phoneNumberValidation: false,
  phoneNumberCountryCode: Phone_Country_Constants.default_Phone_Country_Code,
  adminPhoneNumberValidation: false,
  organizationSubmitButtonEnable: false,
  organizationSuccesCreation: false,
  organizationNameDataLoading: false,
  organizationNameDataError: false,
  organizationsNameData: [],
  organizationCreateButtonClicked: false,
  isOrganizationFilterApplied: false,
  paginationData: null,
  organizationInputNameValue: '',
  organizationFirstNameLengthValidation: false,
  organizationLastNameLengthValidation: false,
  organizationOwnerFirstNameData: '',
  organizationOwnerLastNameData: '',
  organizationsCodeInputValue: '',
  SingleOrganizationDataLoading: false,
  SingleOrganizationDataError: false,
  SingleOrganizationsGetData: null,
  singleOrganizationID: '',
  editOrganizationInfoLoading: false,
  editOrganizationInfoData: [],
  editOrganizationInfoFail: false,
  editOrganizationApiFail: '',
  editOrganizationInfoApiFail: '',
  organizationOwnerEmailData: false,
  organizationOwnerPhoneData: false,
  getOrganizationEmailValue: '',
  organizationOwnerPhoneValue: '',
  organizationNameInputLength: false,
  organizationCodeInputLength: false,
  organizationDevicesList: [],
  organizationDevicesApiData: null,
  orgDevicesnumberOfPages: 1,
  orgDevicespaginationData: null,
  fetchOrganizationCodeData: [],
  organizationEmailValidationLoading: false,
  organizationEmailValidationSuccess: '',
  organizationEmailValidationFail: false,
  selectedSection: 'information',
  bundleLoading: false,
  bundleError: false,
  bundleData: [],
  organizationBundleName: '',
  tierBundleValue: '',
  bundleTierPostID: '',
  openDeleteModal: false,
  orgNameSearchValidationValue: false,
  openProvisionedModalValue: false,
  getSearchOrgNameProvisonValue: '',
  getSearchOrgNameDeleteValue: '',
  getDeactivateModalValue: false,
  getActivateModalValue: false,
  deleteOrgSpecialCharacterValidation: false,
  activateOrgSpecialCharacterValidation: false,
  deactivateOrgSpecialCharacterValidation: false,
  provisonedOrgSpecialCharacterValidation: false,
  orgDeletePostDataFailStrings: '',
  postApiFailureMessage: '',
  durationLoading: false,
  emptyStatusInput: false,
  durationError: false,
  toggleOrgUsersForDeleteStatus: false,
  toggleOrgUsersForProvisionStatus: false,
  togglePausedStatusModal: false,
  orgExpirationDays: {},
  licenseItems: [],
  saveOrgLicenseItemStatus: '',
  //addOrg
  isAddOrgModalOpen: false,
  orgOwnerInfo: {},
  licenseEndDateValue: '',
};
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    toggleOragnizationsView: (state, { payload }) => {
      state.organizationsGridView = payload;
    },
    handleTypesFilterChange: (state, { payload }) => {
      state.tiersFilterValue = payload;
    },
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },
    handleStatusFilterChange: (state, { payload }) => {
      state.statusFilterValue = payload;
    },
    fetchTiersLoading: (state) => {
      state.tiersLoading = true;
      state.tiersError = false;
    },
    fetchTiersSuccess: (state, { payload }) => {
      state.tiersData = payload;
      state.tiersLoading = false;
      state.tiersError = false;
    },
    fetchTiersFail: (state) => {
      state.tiersError = true;
      state.tiersLoading = false;
    },

    // bundledata

    fetchBundleLoading: (state) => {
      state.bundleLoading = true;
      state.bundleError = false;
    },
    fetchBundleSuccess: (state, { payload }) => {
      state.bundleData = payload;
      state.bundleLoading = false;
      state.bundleError = false;
    },
    fetchBundleFail: (state) => {
      state.bundleError = true;
      state.bundleLoading = false;
    },

    handleBundleTierValue: (state, { payload }) => {
      state.tierBundleValue = payload;
    },

    handleBundleTierPostID: (state, { payload }) => {
      state.bundleTierPostID = payload;
    },

    fetchStatusLoading: (state) => {
      state.statusLoading = true;
      state.statusError = false;
    },
    fetchStatusSuccess: (state, { payload }) => {
      state.statusLoading = false;
      state.statusError = false;
      state.statusData = payload;
    },
    fetchStatusFail: (state) => {
      state.statusError = true;
      state.statusLoading = false;
    },
    fetchLanguageLoading: (state) => {
      state.languageLoading = true;
      state.languageError = false;
    },
    fetchLanguageSuccess: (state, { payload }) => {
      state.languageData = payload;
      state.languageLoading = false;
      state.languageError = false;
    },
    fetchLanguageFail: (state) => {
      state.languageError = true;
      state.languageLoading = false;
    },
    fetchOrganizationPostDataLoading: (state) => {
      state.organizationPostDataLoading = true;
      state.organizationPostDataError = false;
    },
    fetchOrganizationPostDataSuccess: (state, { payload }) => {
      state.organizationsPostData = payload;
      state.organizationPostDataLoading = false;
      state.organizationPostDataError = false;
      state.createOrganizationApiFail = 'SUCCESS';
    },
    fetchOrganizationPostDataFail: (state, { payload }) => {
      state.organizationPostDataError = payload;
      state.organizationPostDataLoading = false;
      state.createOrganizationApiFail = 'FAIL';
    },
    createButton: (state, { payload }) => {
      state.createButton = payload;
    },

    editOrganizationInfoDataLoading: (state) => {
      state.editOrganizationInfoLoading = true;
      state.editOrganizationInfoFail = false;
    },
    editOrganizationInfoDataSuccess: (state, { payload }) => {
      state.editOrganizationInfoData = payload;
      state.editOrganizationInfoLoading = false;
      state.editOrganizationInfoFail = false;
      state.editOrganizationApiFail = 'SUCCESS';
    },
    editOrganizationInfoDataFail: (state) => {
      state.editOrganizationInfoFail = true;
      state.editOrganizationInfoLoading = false;
      state.editOrganizationApiFail = 'FAIL';
    },
    fetchEditOrganizationInfoApiFail: (state, { payload }) => {
      state.editOrganizationInfoApiFail = payload;
    },

    fetchorganizationCountry: (state, { payload }) => {
      state.organizationCountry = payload;
    },
    fetchorganizationLanguage: (state, { payload }) => {
      state.organizationLanguage = payload;
    },
    fetchorganizationTier: (state, { payload }) => {
      state.organizationTier = payload;
    },
    fetchorganizationBundleName: (state, { payload }) => {
      state.organizationBundleName = payload;
    },
    fetchOrganizationDurationLoading: (state) => {
      state.durationLoading = true;
      state.orgExpirationDays = '';
      state.durationError = false;
    },
    fetchOrganizationDurationFail: (state) => {
      state.durationLoading = false;
      state.orgExpirationDays = {};
      state.durationError = true;
    },
    fetchOrganizationDurationSuccess: (state, { payload }) => {
      state.orgExpirationDays = payload?.data ? payload?.data : {};
      state.durationError = false;
      state.durationLoading = false;
    },
    fetchOrganizationsLoading: (state) => {
      state.organizationsListLoading = true;
      state.SingleOrganizationsGetData = null;
    },
    fetchOrganizationFail: (state) => {
      state.organizationsListError = true;
      state.organizationsListLoading = false;
    },
    fetchOrganizationSuccess: (state, { payload }) => {
      state.organizationsList = payload.data;
      state.organizationApiData = payload;
      state.numberOfPages = payload.pagination.num_pages;
      state.paginationData = payload.pagination;
      state.organizationsListLoading = false;
    },
    fetchOrganizationPostToggle: (state, { payload }) => {
      state.postOrganizationToggle = payload;
    },
    fetchPostData: (state, { payload }) => {
      state.postData = payload;
    },
    fetchOrganizationCodeLoading: (state) => {
      state.organizationCodeDataLoading = true;
      state.organizationCodeDataError = false;
    },
    fetchOrganizationCodeDataSuccess: (state, { payload }) => {
      state.organizationsCodeData = payload;
      state.organizationCodeDataError = false;
      state.organizationCodeDataLoading = false;
      state.postData = false;
    },
    fetchOrganizationCodeFail: (state, { payload }) => {
      state.organizationCodeDataError = payload;
      state.organizationCodeDataLoading = false;
    },
    fetchOrganizationAutoPopulateLoading: (state, { payload }) => {
      state.organizationAutoPopulateDataLoading = payload;
      state.organizationAutoPopulateDataError = false;
    },
    fetchOrganizationAutoPopulateSuccess: (state, { payload }) => {
      state.organizationAutoPopulateData = payload;
      state.organizationAutoPopulateDataError = false;
      state.organizationAutoPopulateDataLoading = false;
      state.postData = false;
    },
    fetchOrganizationAutoPopulateFail: (state, { payload }) => {
      state.organizationAutoPopulateDataError = payload;
      state.organizationAutoPopulateDataLoading = false;
    },
    fetchOrganizationNameLoading: (state, { payload }) => {
      state.organizationNameDataLoading = payload;
    },
    fetchOrganizationNameDataSuccess: (state, { payload }) => {
      state.organizationsNameData = payload;
      state.organizationNameDataLoading = false;
    },
    fetchOrganizationNameFail: (state, { payload }) => {
      state.organizationNameDataError = payload;
    },
    fetchOrganizationCodeData: (state, { payload }) => {
      state.organizationsCode = payload;
    },
    fetchOrganizationAutoPopulateData: (state, { payload }) => {
      state.organizationAutoPopulateDataLoading = false;
      state.organizationsCode = payload.data.org_code;
      state.organizationsName = payload.data.org_name;
      state.organizationInputNameValue = payload.data.org_name;
      state.organizationsCodeInputValue = payload.data.org_code;
    },

    fetchOrganizationNameData: (state, { payload }) => {
      state.organizationsName = payload;
    },
    fetchOrganizationNameStatus: (state, { payload }) => {
      state.organizationHandleName = payload;
    },
    fetchOrganizationOwnerEmailError: (state, { payload }) => {
      state.organizationOwnerEmail = payload;
    },
    fetchOrganizationAdminEmailError: (state, { payload }) => {
      state.organizationAdminEmail = payload;
    },
    fetchcreateOrganizationApiFail: (state, { payload }) => {
      state.createOrganizationApiFail = payload;
    },
    openOrganizationModal: (state, { payload }) => {
      state.organizationOpenModal = payload;
    },
    checkPhoneNumberValidation: (state, { payload }) => {
      state.phoneNumberValidation = payload;
    },
    checkAdminPhoneNumberValidation: (state, { payload }) => {
      state.adminPhoneNumberValidation = payload;
    },
    getOrganizationSubmitButtonEnable: (state, { payload }) => {
      state.organizationSubmitButtonEnable = payload;
    },
    fetchOrganizationSuccessCreation: (state, { payload }) => {
      state.organizationSuccesCreation = payload;
    },
    fetchOrganizationCreateButtonClicked: (state, { payload }) => {
      state.organizationCreateButtonClicked = payload;
    },
    getOrganizationFirstNameLength: (state, { payload }) => {
      state.organizationFirstNameLengthValidation = payload;
    },
    getOrganizationLastNameLength: (state, { payload }) => {
      state.organizationLastNameLengthValidation = payload;
    },

    getOrganizationOwnerFirstNameInputData: (state, { payload }) => {
      state.organizationOwnerFirstNameData = payload;
    },
    getOrganizationOwnerLastNameInputData: (state, { payload }) => {
      state.organizationOwnerLastNameData = payload;
    },
    getOrganizationOwnerEmailInputData: (state, { payload }) => {
      state.organizationOwnerEmailData = payload;
    },
    getOrganizationEmailInputValue: (state, { payload }) => {
      state.getOrganizationEmailValue = payload;
    },
    getOrganizationOwnerPhoneValidation: (state, { payload }) => {
      state.organizationOwnerPhoneData = payload;
    },
    getOrganizationOwnerPhoneInputValue: (state, { payload }) => {
      state.organizationOwnerPhoneValue = payload;
    },
    getOrganizationNameInputValidation: (state, { payload }) => {
      state.organizationNameInputLength = payload;
    },
    getOrganizationCodeInputValidation: (state, { payload }) => {
      state.organizationCodeInputLength = payload;
    },
    getOrganizationEmailValidationLoading: (state) => {
      state.organizationEmailValidationLoading = false;
    },
    getOrganizationEmailValidationSuccess: (state, { payload }) => {
      state.organizationEmailValidationSuccess = payload;
    },
    getOrganizationEmailValidationFail: (state, { payload }) => {
      state.organizationEmailValidationFail = payload;
    },
    handleOrgUsersForDeleteStatus: (state, { payload }) => {
      state.toggleOrgUsersForDeleteStatus = payload;
    },
    handleOrgUsersForProvisiontatus: (state, { payload }) => {
      state.toggleOrgUsersForProvisionStatus = payload;
    },
    handlePausedStatusModal: (state, { payload }) => {
      state.togglePausedStatusModal = payload;
    },
    handleEmptyInputs: (state, { payload }) => {
      state.emptyStatusInput = payload;
    },
    handleLicenseEndDateValue: (state, { payload }) => {
      state.licenseEndDateValue = payload;
    },

    resetOrganizationCreateActions: (state) => {
      state.organizationCodeInputLength = false;
      state.licenseEndDateValue = '';
      state.toggleOrgUsersForProvisionStatus = false;
      state.organizationNameInputLength = false;
      state.organizationSuccesCreation = false;
      state.organizationOwnerPhoneData = false;
      state.organizationCodeDataError = false;
      state.organizationPostDataError = false;
      state.organizationOwnerPhoneValue = '';
      state.postOrganizationToggle = false;
      state.postData = false;
      state.emptyStatusInput = false;
      state.bundleData = [];
      state.tierBundleValue = '';
      state.organizationEmailValidationFail = false;
      state.organizationEmailValidationLoading = false;
      state.organizationEmailValidationSuccess = '';
      state.organizationEmailValidationLoading = false;
      state.organizationOwnerEmailData = false;
      state.getOrganizationEmailValue = '';
      state.organizationOwnerEmailData = false;
      state.getOrganizationEmailValue = '';
      state.organizationInputNameValue = '';
      state.organizationHandleName = false;
      state.organizationOwnerEmail = false;
      state.organizationAdminEmail = false;
      state.createOrganizationApiFail = '';
      state.organizationSubmitButtonEnable = false;
      state.organizationNameDataError = false;
      state.organizationCreateButtonClicked = false;
      state.adminPhoneNumberValidation = false;
      state.organizationBundleName = '';
      state.phoneNumberValidation = false;
      (state.phoneNumberCountryCode = ''), (state.organizationFirstNameLengthValidation = false);
      state.organizationLastNameLengthValidation = false;
      state.organizationOwnerFirstNameData = '';
      state.organizationOwnerLastNameData = '';
      state.organizationsCodeInputValue = '';
      state.organizationLanguage = '';
      state.organizationCountry = '';
      state.organizationTier = '';
      state.openDeleteModal = false;
      state.bundleTierPostID = '';
      state.getSearchOrgNameDeleteValue = '';
      state.getSearchOrgNameProvisonValue = '';
      state.orgNameSearchValidationValue = false;
      state.organizationOwnerPhoneData = false;
      state.openProvisionedModalValue = false;
      state.getDeactivateModalValue = false;
      state.getActivateModalValue = false;
      state.deleteOrgSpecialCharacterValidation = false;
      state.activateOrgSpecialCharacterValidation = false;
      state.deactivateOrgSpecialCharacterValidation = false;
      state.provisonedOrgSpecialCharacterValidation = false;
      state.orgDeletePostDataFailStrings = '';
      state.postApiFailureMessage = '';
      state.togglePausedStatusModal = false;
    },
    organizationsFilterApplied: (state, { payload }) => {
      state.isOrganizationFilterApplied = payload;
    },
    getOrganizationInputNameValue: (state, { payload }) => {
      state.organizationInputNameValue = payload;
    },
    fetchOrganizationCodeInputValue: (state, { payload }) => {
      state.organizationsCodeInputValue = payload;
    },
    fetchSingleOrganizationDataLoading: (state) => {
      state.SingleOrganizationDataLoading = true;
      state.SingleOrganizationDataError = false;
    },
    fetchSingleOrganizationDataSuccess: (state, { payload }) => {
      state.SingleOrganizationsGetData = payload;
      state.SingleOrganizationDataLoading = false;
      state.SingleOrganizationDataError = false;
    },
    fetchSingleOrganizationDataFail: (state) => {
      state.SingleOrganizationDataError = true;
      state.SingleOrganizationDataLoading = false;
    },
    fetchSingleOrganizationID: (state, { payload }) => {
      state.singleOrganizationID = payload;
    },
    updatePhoneNumberWithCountry: (state, { payload }) => {
      state.phoneNumberCountryCode = payload;
    },
    fetchOrganizationDeviceSuccess: (state, { payload }) => {
      state.organizationDevicesList = payload.data;
      state.organizationDevicesApiData = payload;
      state.orgDevicesnumberOfPages = payload.pagination.num_pages;
      state.orgDevicespaginationData = payload.pagination;
      state.organizationsListLoading = false;
    },
    fetchSelectedSection: (state, { payload }) => {
      state.selectedSection = payload;
    },
    openDeleteModal: (state, { payload }) => {
      state.openDeleteModal = payload;
    },
    orgNameSearchValidation: (state, { payload }) => {
      state.orgNameSearchValidationValue = payload;
    },
    openProvisionedModal: (state, { payload }) => {
      state.openProvisionedModalValue = payload;
    },

    // dekete and provision

    getSearchOrgNameProvison: (state, { payload }) => {
      state.getSearchOrgNameProvisonValue = payload;
    },

    getSearchOrgNameDelete: (state, { payload }) => {
      state.getSearchOrgNameDeleteValue = payload;
    },
    handleDeactivateModal: (state, { payload }) => {
      state.getDeactivateModalValue = payload;
    },
    handleActivateModal: (state, { payload }) => {
      state.getActivateModalValue = payload;
    },
    handleDeleteOrganizationSCValidation: (state, { payload }) => {
      state.deleteOrgSpecialCharacterValidation = payload;
    },
    handleActivateOrganizationSCValidation: (state, { payload }) => {
      state.activateOrgSpecialCharacterValidation = payload;
    },
    handleDeactivateOrganizationSCValidation: (state, { payload }) => {
      state.deactivateOrgSpecialCharacterValidation = payload;
    },
    handleProvisonedOrganizationSCValidation: (state, { payload }) => {
      state.provisonedOrgSpecialCharacterValidation = payload;
    },
    getOrgDeletePostDataFailStrings: (state, { payload }) => {
      state.orgDeletePostDataFailStrings = payload;
    },
    handlePostApiFailureMessage: (state, { payload }) => {
      state.postApiFailureMessage = payload;
    },
    fetchLicenseItems: (state, { payload }) => {
      state.licenseItems = payload;
    },
    saveOrgLicenseItemsStatus: (state, { payload }) => {
      state.saveOrgLicenseItemStatus = payload;
    },
    // add org
    toggleAddOrgModal: (state, { payload }) => {
      state.isAddOrgModalOpen = payload;
    },
    setOrganizationOwnerInfo: (state, { payload }) => {
      state.orgOwnerInfo = payload;
    },
  },
});
export const {
  handleDeactivateModal,
  handlePostApiFailureMessage,
  handleActivateModal,
  fetchOrganizationDeviceSuccess,
  handleOrgUsersForDeleteStatus,
  getOrgDeletePostDataFailStrings,
  handleDeleteOrganizationSCValidation,
  handleActivateOrganizationSCValidation,
  handleDeactivateOrganizationSCValidation,
  handleProvisonedOrganizationSCValidation,
  handleOrgUsersForProvisiontatus,

  getSearchOrgNameDelete,
  fetchBundleLoading,
  getSearchOrgNameProvison,
  fetchBundleSuccess,
  fetchBundleFail,
  getSearchText,
  clearSearcText,
  closeSearchInMobile,
  openProvisionedModal,
  expandSearchInMobile,
  orgNameSearchValidation,
  toggleOragnizationsView,
  fetchTiersFail,
  fetchTiersSuccess,
  fetchTiersLoading,
  handleTypesFilterChange,
  fetchStatusLoading,
  fetchStatusSuccess,
  handleBundleTierPostID,
  fetchStatusFail,
  getPageNumber,
  handleStatusFilterChange,
  fetchOrganizationPostDataFail,
  fetchOrganizationPostDataSuccess,
  fetchOrganizationPostDataLoading,
  fetchLanguageLoading,
  fetchLanguageSuccess,
  fetchLanguageFail,
  createButton,
  fetchorganizationBundleName,
  fetchorganizationTier,
  fetchorganizationLanguage,
  fetchorganizationCountry,
  fetchOrganizationsLoading,
  fetchOrganizationFail,
  handleLicenseEndDateValue,
  fetchOrganizationSuccess,
  fetchOrganizationPostToggle,
  fetchPostData,
  fetchOrganizationCodeData,
  fetchOrganizationCodeLoading,
  fetchOrganizationCodeDataSuccess,
  fetchOrganizationCodeFail,
  fetchOrganizationAutoPopulateData,
  fetchOrganizationAutoPopulateLoading,
  fetchOrganizationAutoPopulateSuccess,
  fetchOrganizationAutoPopulateFail,
  handlePausedStatusModal,
  fetchOrganizationNameData,
  fetchOrganizationNameStatus,
  handleBundleTierValue,
  fetchOrganizationOwnerEmailError,
  fetchOrganizationAdminEmailError,
  fetchcreateOrganizationApiFail,
  resetOrganizationCreateActions,
  getFlterData,
  openOrganizationModal,
  checkPhoneNumberValidation,
  checkAdminPhoneNumberValidation,
  fetchOrganizationSuccessCreation,
  getOrganizationSubmitButtonEnable,
  fetchOrganizationNameLoading,
  fetchOrganizationNameDataSuccess,
  fetchOrganizationNameFail,
  fetchOrganizationCreateButtonClicked,
  organizationsFilterApplied,
  getOrganizationInputNameValue,
  getOrganizationFirstNameLength,
  getOrganizationLastNameLength,
  getOrganizationOwnerFirstNameInputData,
  getOrganizationOwnerLastNameInputData,
  fetchOrganizationCodeInputValue,
  fetchSingleOrganizationDataFail,
  fetchSingleOrganizationDataSuccess,
  fetchSingleOrganizationDataLoading,
  fetchSingleOrganizationID,
  editOrganizationInfoDataLoading,
  editOrganizationInfoDataSuccess,
  editOrganizationInfoDataFail,
  fetchEditOrganizationInfoApiFail,
  getOrganizationEmailInputValue,
  getOrganizationOwnerEmailInputData,
  getOrganizationOwnerPhoneValidation,
  getOrganizationOwnerPhoneInputValue,
  getOrganizationNameInputValidation,
  getOrganizationCodeInputValidation,
  updatePhoneNumberWithCountry,
  getOrganizationEmailValidationLoading,
  getOrganizationEmailValidationSuccess,
  getOrganizationEmailValidationFail,
  fetchSelectedSection,
  openDeleteModal,
  fetchOrganizationDurationLoading,
  fetchOrganizationDurationFail,
  fetchOrganizationDurationSuccess,
  fetchLicenseItems,
  saveOrgLicenseItemsStatus,
  toggleAddOrgModal,
  handleEmptyInputs,
  setOrganizationOwnerInfo,
} = organizationSlice.actions;
export default organizationSlice.reducer;
