import { UserActivity } from '@exo/ew-data-models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiUtil from '../../api';
import { UserActivityResponse, VersionInfo } from '../../types/types';

const getVersion = createAsyncThunk('version', async () => {
  const serviceName = process.env.NX_SERVICE_NAME;
  const response = await ApiUtil.get<VersionInfo>(`${serviceName === 'exo-pulse' ? '/v1' : ''}/version`, {}); // Hardcoding `/v1` here, see EWNXN-1077, below this we also hardcode `v2` in another call that uses this same 'ApiUtil'.
  return response.data;
});

const sendUserActivity = createAsyncThunk('userActivity', async (activity: UserActivity[]) => {
  const response = await ApiUtil.post<UserActivityResponse>(`/v2/activity/user`, activity);
  return response.data;
});

export const apiVersionThunks = {
  getVersion,
  sendUserActivity,
};
