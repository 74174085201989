import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// define the base component styles
const baseStyle = defineStyle({
  zIndex: 99999,
});

// export the component theme
export const tooltipTheme = defineStyleConfig({
  baseStyle,
});
