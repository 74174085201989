/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useEffect, useState } from 'react';
import { Box, Wrap, WrapItem, Input, Button, Icon, Menu, MenuButton, Text, MenuList } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import {
  fetchDeviceCreateButtonEnable,
  fetchDeviceManufacturedDateValidation,
  fetchDeviceManufacturedDay,
  fetchDeviceManufacturedYear,
  fetchDevicePostToggle,
  fetchDeviceSuccessCreation,
  fetchDeviceUDIDFieldValidation,
  fetchDeviceUDIDNumberFail,
  fetchDeviceUDIDNumberMinLength,
  fetchPostDeviceData,
  fetchPostDeviceFormDataFail,
  getDeviceDatePicker,
  getDevicePostCreateButton,
  getDeviceUDIDInputNumber,
} from 'redux/devices/Reducers/deviceSlice';
import { getDeviceUDIDNumberData } from 'redux/devices/Actions/deviceActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { DeviceUDIDNumber } from 'types/device-types';
import { specialAlphaNumericValidation } from 'utils/validations/specialCharacterValidation';
import { CalendarIcons } from 'assets/icons/icons';
const DeviceDateUdidInputs = () => {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const udidNumber = useAppSelector((state) => state.device.getDeviceUDIDInputNumber);
  const udidNumberLength = useAppSelector((state) => state.device.getDeviceUDIDNumberLength);
  const udidNumberValidation = useAppSelector((state) => state.device.getDeviceUDIDNumberFail);
  const datePicker = useAppSelector((state) => state.device.deviceDatePicker);
  const uidiNumberFieldValidation = useAppSelector((state) => state.device.getDeviceUDIDFieldValidation);
  const deviceManufacturedYear = useAppSelector((state) => state.device.getDeviceManufacturedYear);
  const deviceManufacturedDateValidation = useAppSelector((state) => state.device.getDeviceManufacturedDateValidation);
  const selectedDays = useAppSelector((state) => state.device.getDeviceManufacturedDay);
  const scannedValue = useAppSelector((state) => state.device.scannedValue);

  const [selectedDay, setSelectedDay] = useState<any>('');
  const calendarDateToday = utils(intl.locale).getToday();

  const postDataFail = useAppSelector((state) => state.device.postDeviceFormDataFail);
  const handleUDIDNumber = (e: any) => {
    dispatch(getDeviceUDIDInputNumber(e.target.value));
    if (postDataFail) {
      dispatch(fetchDeviceCreateButtonEnable(false));
      dispatch(fetchPostDeviceData(false));
      dispatch(getDevicePostCreateButton(false));
      dispatch(fetchDeviceSuccessCreation(false));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(fetchDevicePostToggle(false));
    }
  };

  const PostDeviceUDIDNumber: DeviceUDIDNumber = {
    field_name: 'udid',
    field_value: udidNumber,
  };

  useEffect(() => {
    udidNumberLength;
    udidNumberValidation;
  }, [udidNumber]);

  const handleUDIDChange = () => {
    if (udidNumber.length > 0 && udidNumber.length < 4) {
      dispatch(fetchDeviceUDIDNumberMinLength(true));
      dispatch(fetchDeviceUDIDNumberFail(false));
      dispatch(fetchDeviceUDIDFieldValidation(false));
    } else if (udidNumber.length > 3) {
      dispatch(getDeviceUDIDNumberData(PostDeviceUDIDNumber));
      dispatch(fetchDeviceUDIDNumberMinLength(false));
      dispatch(fetchDeviceUDIDFieldValidation(false));
      dispatch(fetchDeviceUDIDNumberFail(false));
    } else if (udidNumber.length === 0) {
      dispatch(fetchDeviceUDIDFieldValidation(true));
      dispatch(fetchDeviceUDIDNumberFail(false));
      dispatch(fetchDeviceUDIDNumberMinLength(false));
    } else {
      dispatch(fetchDeviceUDIDNumberFail(false));
      dispatch(fetchDeviceUDIDNumberMinLength(false));
      dispatch(fetchDeviceUDIDFieldValidation(false));
    }
  };
  function getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }

  const handleChange = (e: any, onClose: () => void) => {
    setSelectedDay(e);
    onClose();
    dispatch(fetchDeviceManufacturedDay(e.day));
    dispatch(getDeviceDatePicker(e.month));
    dispatch(fetchDeviceManufacturedYear(e.year));
    if (postDataFail) {
      dispatch(fetchDeviceCreateButtonEnable(false));
      dispatch(fetchPostDeviceData(false));
      dispatch(getDevicePostCreateButton(false));
      dispatch(fetchDeviceSuccessCreation(false));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(fetchDevicePostToggle(false));
    }
  };
  const handleDateChange = () => {
    if (deviceManufacturedYear.length == 0) {
      dispatch(fetchDeviceManufacturedDateValidation(true));
    } else {
      dispatch(fetchDeviceManufacturedDateValidation(false));
    }
  };
  return (
    <>
      <Box>
        <Wrap align="center" justify="first_name" spacingX="30px" data-testid="container-element">
          <WrapItem>
            <Box minH="90px">
              <InputLabel value={datePicker} name="manufactured_date" />
              <Menu>
                {({ isOpen, onClose }) => (
                  <>
                    <MenuButton
                      isActive={isOpen}
                      as={Button}
                      width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                      borderBottom="1px solid"
                      borderRadius="0px"
                      _focusVisible={{ borderBottomColor: colors.primaryColor }}
                      borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                      variant="flushed"
                      fontSize="14px"
                      data-testid="license-info"
                      onBlur={handleDateChange}
                      rightIcon={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                    >
                      {datePicker ? (
                        <Text
                          float="left"
                          marginLeft="-15px"
                          marginTop="5px"
                          fontWeight="400"
                          color={colors.subTextColor}
                        >
                          {getMonthName(datePicker)} {selectedDays}, {deviceManufacturedYear}
                        </Text>
                      ) : (
                        <Text float="left" marginLeft="-15px" marginTop="5px" fontWeight="400" color="#8c8c8c">
                          <FormattedMessage id="manufactured_date.message" />
                        </Text>
                      )}
                    </MenuButton>
                    <Box fontSize="12px" color="#FB5654">
                      {isOpen ? (
                        ''
                      ) : (
                        <>
                          {deviceManufacturedDateValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                        </>
                      )}
                    </Box>
                    <MenuList
                      width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                      backgroundColor="transparent"
                      border="none"
                      fontSize="14px"
                      onBlur={handleDateChange}
                      __css={{
                        '.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover':
                          {
                            color: colors.lightModeBlue,
                            background: 'none',
                          },
                        '.Calendar__monthArrowWrapper.-right': {
                          transform: 'none',
                        },
                        '.Calendar__monthArrowWrapper.-left': {
                          transform: 'rotate(180deg)',
                        },
                        '.Calendar__monthArrow': {
                          backgroundImage: "url('/assets/images/left-chevron.svg')",
                        },
                        '.Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus':
                          {
                            background: 'none',
                            color: colors.lightModeBlue,
                          },
                        '.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover':
                          {
                            background: 'none',
                            color: colors.lightModeBlue,
                          },
                      }}
                    >
                      <Calendar
                        value={selectedDay}
                        data-testid="data-testid"
                        onChange={(e) => handleChange(e, onClose)}
                        colorPrimary={colors.lightModeBlue}
                        calendarClassName="custom-calendar"
                        calendarTodayClassName="custom-today-day"
                        minimumDate={{
                          year: calendarDateToday.year - 5, // past 5 years
                          month: calendarDateToday.month,
                          day: calendarDateToday.day,
                        }}
                        maximumDate={{
                          year: calendarDateToday.year + 2, // future 2 years
                          month: calendarDateToday.month,
                          day: calendarDateToday.day,
                        }}
                      />
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
          </WrapItem>
          <WrapItem>
            <Box minH="90px">
              <InputLabel name="device_identifier" value={udidNumber} />
              <Input
                color={colors.subTextColor}
                name="serial number"
                width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                value={udidNumber}
                onChange={handleUDIDNumber}
                placeholder={intl.formatMessage({ id: 'device_identifier.message' })}
                type="text"
                fontSize="14px"
                _placeholder={{ fontSize: '14px' }}
                variant="flushed"
                paddingTop="5px"
                maxLength={16}
                onBlur={handleUDIDChange}
                onKeyPress={specialAlphaNumericValidation}
                required
                data-testid="device-form-udid"
              />
              <Box fontSize="12px" color="#FB5654">
                {udidNumberLength ? <FormattedMessage id="org_name_error.message" /> : ''}
                {uidiNumberFieldValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                {/* {udidNumberValidation ? <FormattedMessage id="device_identifier_number_validation.message" /> : '' } */}
              </Box>
            </Box>
          </WrapItem>
        </Wrap>
      </Box>
    </>
  );
};
export default DeviceDateUdidInputs;
