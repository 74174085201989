import { createSlice } from '@reduxjs/toolkit';

import { OrganizationDashboard, dashboardResponse } from 'types/dashboard-types';

interface FilteredData {
  [date: string]: {
    organizations: number;
    iris_devices: number;
    users: number;
    third_party_devices: number;
  };
}
export interface DashboardData {
  organizations: number;
  iris_devices: number;
  users: number;
  third_party_devices: number;
}
export interface DashboardResponse {
  users: number;
  third_party_devices: number;
  iris_devices: number;
  organizations: number;
  status_code: number;
  message: string;
  data: {
    filtered_data: { [date: string]: DashboardData };
    total_data: DashboardData;
  };
}
type InitialState = {
  statusLoading: boolean;

  statusError: boolean;

  dashboardResponse: dashboardResponse[];
  statusData: { id: string; name: string };
  dashboardData: OrganizationDashboard | null;
  dashboardDataLoading: boolean;
  dashboardDataFail: boolean;
  dashboardChartDataLoading: boolean;
  dashboardChartDataFail: boolean;
  dashboardChartData: any;
};

export const initialState: InitialState = {
  dashboardResponse: [],
  statusLoading: false,
  statusError: false,
  statusData: { id: '', name: '' },
  dashboardData: null,
  dashboardDataLoading: false,
  dashboardDataFail: false,
  dashboardChartDataLoading: false,
  dashboardChartDataFail: false,
  dashboardChartData: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',

  initialState,

  reducers: {
    fetchDashboardLoading: (state) => {
      state.statusLoading = true;

      state.statusError = false;
    },

    fetchDashboardSuccess: (state, { payload }) => {
      state.dashboardResponse = payload;
    },

    fetchDashboardFail: (state) => {
      state.statusError = true;

      state.statusLoading = false;
    },
    fetchDashboardStatus: (state, { payload }) => {
      state.statusData = payload;
    },

    setDashboardData: (state, { payload }) => {
      state.dashboardData = payload;
      state.dashboardDataLoading = false;
      state.dashboardDataFail = false;
    },
    setDashboardDataFail: (state) => {
      state.dashboardDataFail = true;
      state.dashboardDataLoading = false;
    },
    setDashboardDataLoading: (state) => {
      state.dashboardDataLoading = true;
      state.dashboardDataFail = false;
    },
    setDashboardChartDataLoading: (state) => {
      state.dashboardChartDataLoading = true;
      state.dashboardChartDataFail = false;
    },

    setDashboardChartData: (state, { payload }) => {
      state.dashboardChartData = payload;
      state.dashboardChartDataLoading = false;
      state.dashboardChartDataFail = false;
    },
    setDashboardDataChartFail: (state) => {
      state.dashboardChartDataFail = true;
      state.dashboardChartDataLoading = false;
    },
  },
});

export const {
  fetchDashboardSuccess,
  setDashboardDataLoading,
  setDashboardDataFail,
  setDashboardData,
  fetchDashboardStatus,
  fetchDashboardLoading,
  setDashboardChartDataLoading,
  setDashboardChartData,
  setDashboardDataChartFail,
  fetchDashboardFail,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
