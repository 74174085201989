import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { SharedUiState, useAppDispatch } from '../../redux/hooks';
import { fetchSelectedOrganization } from '../../redux/login.slice';
import { customColor } from '../../assets/styles/colors';
import { UserInfo } from '../../../lib/types/types';
import { RightChervonIcon } from '../../assets/icons/icons';
import { FormattedMessage } from 'react-intl';
import { AuthType } from '../../../lib/utils/constants';
import Lock from '../../assets/icons/Lock';

const OrganizationItem = ({ org, onOrgselect }: { org: UserInfo; onOrgselect: (selectedOrg: any) => void }) => {
  const selectedOrg = useSelector((state: SharedUiState) => state.login.selectedOrg);
  const colors = customColor();
  const dispatch = useDispatch<useAppDispatch>();

  const handleOrg = (org: UserInfo) => {
    dispatch(fetchSelectedOrganization(org));
    onOrgselect(org);
  };

  return (
    <Box
      key={org.org_id}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderWidth="0.5px"
      borderColor={colors.lightModeBlue}
      borderRadius="14px"
      my="5px"
      width="100%"
      minH="65px"
      onClick={() => handleOrg(org)}
      color={org.org_name === selectedOrg?.org_name ? colors.lightModeBlue : colors.textColor}
      boxShadow={org.org_name === selectedOrg?.org_name ? 'inset 0px 0px 8px 2px #24B0FF30' : ''}
      cursor="pointer"
      p="20px"
      _hover={{
        color: colors.lightModeBlue,
        boxShadow: 'inset 0px 0px 8px 2px #24B0FF30',

        '& .right_arrow': {
          display: 'block !important',
        },
      }}
    >
      <Box>
        <Text fontSize={{ xs: '16px', md: '18px' }} fontWeight={400}>
          {org.org_name}
        </Text>
        {org.auth_profiles[0].auth_type && org.auth_profiles[0].auth_type === AuthType.AUTH0_SAML && (
          <Flex alignItems="center" pt="5px">
            <Lock width="20px" height="20px" />
            <Text fontSize={{ xs: '10px', md: '12px' }} pl="5px" fontWeight={400}>
              <FormattedMessage id="single_sign_on_enabled.message" />
            </Text>
          </Flex>
        )}
      </Box>
      <Box className="right_arrow" display={org.org_name === selectedOrg?.org_name ? 'block' : 'none'} mt="-5px">
        <RightChervonIcon />
      </Box>
    </Box>
  );
};

export default OrganizationItem;
