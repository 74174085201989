import React, { useEffect, useState, ReactNode } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { SharedUiState } from '../../redux/hooks';
import { customColor } from '../../assets/styles/colors';
import { useNavigate } from 'react-router-dom';

type ErrorDescriptionModalProps = {
  heading?: string;
  message?: string;
  icon: React.ReactNode;
  innerTextComponent?: ReactNode;
  onClose?: () => void;
  buttonText?: string;
};

const ErrorDescriptionModal = (props: ErrorDescriptionModalProps) => {
  const [isErrorDescriptionModalOpen, setIsErrorDescriptionModalOpen] = useState<boolean>(false);
  useEffect(() => {
    if (props.message) {
      setIsErrorDescriptionModalOpen(true);
    }
  }, [props.message]);
  const navigate = useNavigate();
  const isExoWorks = useSelector((state: SharedUiState) => state.login.isExoWorks);
  const colors = customColor();
  const backgroundColor = useColorModeValue(
    'white',
    'linear-gradient(0deg, rgba(255, 149, 0, 0.05) 0%, rgba(255, 149, 0, 0.05) 100%), #1A1C22',
  );
  const closeModal = () => {
    if (props.onClose) props.onClose();
    setIsErrorDescriptionModalOpen(false);
    navigate('/');
  };
  const fontColor = useColorModeValue('black', '#C2EECD');
  return isExoWorks ? (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isErrorDescriptionModalOpen}
      onClose={closeModal}
      border-radius="0.1px"
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '80%', md: '60%', lg: '600px' }}>
        <ModalHeader fontSize="20px" fontWeight="700" pt="40px" pb="5px">
          <Text>
            <FormattedMessage id={props.heading} />
          </Text>
        </ModalHeader>
        <ModalBody backgroundColor="transparent">
          <Text fontSize="16px" fontWeight="500">
            <FormattedMessage id={props.message} />
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            type="button"
            width="100%"
            height="42px"
            borderRadius="10px"
            borderColor="#33333333"
            backgroundColor="#7676801F"
            data-testid="session-expired-ok-btn"
            onClick={closeModal}
          >
            <Text color="#333333" fontSize="18px" fontWeight="500">
              {props.buttonText ? props.buttonText : 'Okay'}
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Box h="84px">
      <Flex
        p="20px"
        borderRadius="10px"
        background={backgroundColor}
        boxShadow="0px 1px 20px 0px rgba(0, 0, 0, 0.60)"
        border={`1px solid ${colors.bannerWarningText}`}
        gap="16px"
        h="64px"
        alignItems="center"
      >
        {props.icon}
        {props.innerTextComponent}
        {props.message && (
          <Text color={fontColor} fontSize="16px" fontWeight="500">
            <FormattedMessage id={props.message} />
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default ErrorDescriptionModal;
