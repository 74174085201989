export function getUserStatusAndFlow(value: any) {
  let obj: { status: string; action: string; actionPast: string; statusPast: string; statusButtonText?: string } = {
    status: '',
    action: '',
    actionPast: '',
    statusPast: '',
    statusButtonText: '',
  };

  switch (value) {
    case 'Active':
      obj = { action: 'Deactivate', actionPast: 'Deactivated', status: '', statusPast: '' };
      break;
    case 'Inactive':
      obj = {
        action: 'Delete',
        actionPast: 'Deleted',
        status: 'Active',
        statusPast: 'Active',
        statusButtonText: 'Activate',
      };

      break;
    case 'Deactivated':
      obj = {
        action: '',
        actionPast: '',
        status: 'Active',
        statusPast: 'Active',
        statusButtonText: 'Activate',
      };
      break;

    default:
      obj = { action: '', status: '', statusPast: '', actionPast: '' };
  }

  return obj;
}
