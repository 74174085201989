import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect } from 'react';
import { RootState } from 'redux/Store/store';

import Search from 'components/customSearch/Search';
import { closeSearchInMobile ,clearSearcText,expandSearchInMobile,getSearchText} from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';


interface FilterProps {
  isFiltersApplied: boolean;
}

const OrgLicensesFilter = ({ isFiltersApplied }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
const organizationDevices = useAppSelector((state:RootState)=>state.orgLicense)



  const dispatch = useAppDispatch();

  const handleSearchClose = () => {
    dispatch(closeSearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSearchText(value));
  };
  useEffect(() => {
    dispatch(closeSearchInMobile());
  }, [isMobileDevice]);


  return (
    <Box>
      {' '}
      {((organizationDevices.organizationLincesesList && organizationDevices.organizationLincesesList.length > 0) ||
        isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="26px"
          pr="20px"
          alignItems="center"
        >
          {' '}
          <Search
            data={organizationDevices}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />{' '}
    
        </Box>
      )}{' '}
    </Box>
  );
};
export default OrgLicensesFilter;
