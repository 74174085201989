import { Box, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { useAppSelector } from 'redux/hooks';

const CustomerInfo = () => {
  const colors = customColor();

  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);

  return (
    <>
      {singleOrganizationData?.customer && (
        <Box>
          <Box pb="20px" color={colors.subTextColor} fontSize="18px" fontWeight="700">
            <FormattedMessage id="customer_info_heading.message" />
          </Box>
          <Wrap display="flex" spacing="40px" minH="60px">
            <WrapItem>
              <Box>
                <InputLabel value="-" name="customer_admin_fname" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                  {singleOrganizationData?.customer?.customer_admin_first_name}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box>
                <InputLabel value="-" name="customer_admin_lname" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                  {singleOrganizationData?.customer?.customer_admin_last_name}
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
          <Wrap display="flex" spacing="40px" minH="60px">
            <WrapItem>
              <Box>
                <InputLabel value="-" name="customer_admin_email" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                  {singleOrganizationData?.customer?.customer_admin_email}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box>
                <InputLabel value="-" name="customer_admin_phone" />
                {singleOrganizationData?.customer?.customer_admin_phone ? (
                  <Stack marginTop={2} display="flex" direction={['row']} spacing="5px">
                    <Text fontSize="14px">
                      {singleOrganizationData?.customer?.customer_admin_phone_code.country_phone_code}{' '}
                    </Text>
                    <Text fontWeight="400" fontSize="14px" className="truncatee" display="flex">
                      {' '}
                      {singleOrganizationData?.customer?.customer_admin_phone}
                    </Text>
                  </Stack>
                ) : (
                  '-'
                )}
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      )}
    </>
  );
};
export default CustomerInfo;
