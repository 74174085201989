/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';

import { OrgDevice } from '../../../types/organization-types';
import { PaginationTypes } from 'types/pagination-types';
import { OrgAddonsData } from 'types/organization-types';

type InitialState = {
  searchText: string;
  organizationLincesesList: any;
  statusLoading: boolean;
  statusError: boolean;
  organizationLicensesListLoading: boolean;
  organizationsListError: boolean;
  createOrganizationApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  organizationLicensesApiData: null;
  numberOfPages: number;
  paginationData: PaginationTypes | null;
  SingleOrganizationDeviceDataLoading: boolean;
  SingleOrganizationDeviceDataError: boolean;
  SingleOrganizationsDeviceGetData: OrgDevice[];
  SingleOrganizationsDeviceGetDataLoading: boolean;
  SingleOrganizationsDeviceGetDataError: boolean;
  singleOrganizationID: string | number;
  isSearchExpand: boolean;
  orderIdInputValue: string;
  reasonInputValue: string;
  orderIdDataLoading: boolean;
  orderIdDataError: boolean;
  orderIdData: string[];
  orderId: string;
  handleOrgLicenseModal: boolean;
  orgLicenseBundleName: string;
  orgLicenseOrderIDValue: string;
  orgLicenseSelectedContractDuration: string;
  orgLicenseUnitsValue: string;
  orgLicensePostApiSuccess: boolean;
  licenseContractDurationPeriodData: { contract_duration_years: number }[] | [];
  orgLicensesOrderIdValidation: boolean;
  orgLicensesDurationValidation: boolean;
  orgLicensesUnitsValidation: boolean;
  orgLicensesOrderIdCharacterLength: boolean;
  orgLicenseDataForStatusHandling: boolean;
  // Addons
  isAddonsLoading: boolean;
  addonsData: OrgAddonsData | null;
  isOrgAddOnModalActive: boolean;
  isOrgAddOnModalLoading: boolean;
  isOrgAddOnModalSuccess: boolean;
  isOrgAddOnModalFail: boolean;
  postFailErrorMessage: string;
};
export const initialState: InitialState = {
  searchText: '',
  organizationLincesesList: [],
  statusLoading: false,
  statusError: false,
  organizationLicensesListLoading: false,
  organizationsListError: false,
  selectedPage: 1,
  createOrganizationApiFail: '',
  organizationLicensesApiData: null,
  numberOfPages: 1,
  filterData: { status_id: [] },
  paginationData: null,
  SingleOrganizationDeviceDataLoading: false,
  SingleOrganizationDeviceDataError: false,
  SingleOrganizationsDeviceGetData: [],
  SingleOrganizationsDeviceGetDataLoading: false,
  SingleOrganizationsDeviceGetDataError: false,
  singleOrganizationID: '',
  isSearchExpand: false,
  orderIdInputValue: '',
  reasonInputValue: '',
  orderIdDataLoading: false,
  orderIdDataError: false,
  orderIdData: [],
  orderId: '',
  handleOrgLicenseModal: false,
  orgLicenseBundleName: '',
  orgLicenseOrderIDValue: '',
  orgLicenseSelectedContractDuration: '',
  orgLicenseUnitsValue: '1',
  orgLicensePostApiSuccess: false,
  licenseContractDurationPeriodData: [],
  orgLicensesOrderIdValidation: false,
  orgLicensesDurationValidation: false,
  orgLicensesUnitsValidation: false,
  orgLicensesOrderIdCharacterLength: false,
  orgLicenseDataForStatusHandling: false,
  // Addons
  isAddonsLoading: false,
  addonsData: null,
  isOrgAddOnModalActive: false,
  isOrgAddOnModalLoading: false,
  isOrgAddOnModalSuccess: false,
  isOrgAddOnModalFail: false,
  postFailErrorMessage: '',
};
const orgLinceseSlice = createSlice({
  name: 'organizationLinceseSlice',
  initialState,
  reducers: {
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchcreateOrganizationApiFail: (state, { payload }) => {
      state.createOrganizationApiFail = payload;
    },
    fetchOrganizationLicenseLoading: (state) => {
      state.organizationLicensesListLoading = true;
    },
    fetchOrganizationLicenseFail: (state) => {
      state.organizationsListError = true;
      state.organizationLicensesListLoading = false;
    },
    fetchOrganizationLicenseSuccess: (state, { payload }) => {
      state.organizationLincesesList = payload.data;
      state.organizationLicensesApiData = payload;
      state.numberOfPages = payload.pagination.num_pages;
      state.paginationData = payload.pagination ? payload.pagination : {};
      state.organizationLicensesListLoading = false;
    },
    handleOrgLicenseModalPopup: (state, { payload }) => {
      state.handleOrgLicenseModal = payload;
    },

    // org license modal values

    getOrgLicenseBundleName: (state, { payload }) => {
      state.orgLicenseBundleName = payload;
    },
    getOrgLicenseOrderIDValue: (state, { payload }) => {
      state.orgLicenseOrderIDValue = payload;
    },
    getOrgLicenseSelectedContractDuration: (state, { payload }) => {
      state.orgLicenseSelectedContractDuration = payload;
    },
    getOrgLicenseUnitsValue: (state, { payload }) => {
      state.orgLicenseUnitsValue = payload;
    },
    getOrgLicensePostApiSuccess: (state, { payload }) => {
      state.orgLicensePostApiSuccess = payload;
    },
    getOrgLicensesDataForStatusHandling: (state, { payload }) => {
      state.orgLicenseDataForStatusHandling = payload;
    },

    resetOrgLicenseModalValues: (state) => {
      (state.handleOrgLicenseModal = false), (state.orgLicenseBundleName = '');
      (state.orgLicenseOrderIDValue = ''),
        (state.orgLicenseSelectedContractDuration = ''),
        (state.orgLicenseUnitsValue = '1'),
        (state.orgLicensePostApiSuccess = false);
      state.orgLicensesOrderIdValidation = false;
      state.orgLicensesDurationValidation = false;
      state.orgLicensesUnitsValidation = false;
      state.orgLicensesOrderIdCharacterLength = false;
      state.orgLicenseDataForStatusHandling = false;
    },

    handleContractDurationPeriodData: (state, { payload }) => {
      state.licenseContractDurationPeriodData = payload ? payload : [];
    },
    handleOrgLicensesOrderIdValidation: (state, { payload }) => {
      state.orgLicensesOrderIdValidation = payload;
    },
    handleOrgLicensesOrderIdCharacterLengthValidation: (state, { payload }) => {
      state.orgLicensesOrderIdCharacterLength = payload;
    },
    handleOrgLicenseDurationValidation: (state, { payload }) => {
      state.orgLicensesDurationValidation = payload;
    },
    handleOrgLicensesUnitsValidation: (state, { payload }) => {
      state.orgLicensesUnitsValidation = payload;
    },

    // Add-ons
    setAddonsIsLoading: (state) => {
      state.isAddonsLoading = true;
    },
    setAddonsData: (state, { payload }) => {
      state.isAddonsLoading = false;
      state.addonsData = payload;
    },
    handleOrgAddOnModal: (state, action) => {
      state.isOrgAddOnModalActive = action.payload;
    },
    handleOrgAddOnModalloading: (state, { payload }) => {
      state.isOrgAddOnModalLoading = payload;
    },
    handleOrgAddOnModalSuccess: (state, { payload }) => {
      state.isOrgAddOnModalSuccess = payload;
      state.isOrgAddOnModalLoading = false;
    },
    handleOrgAddOnModalFail: (state, { payload }) => {
      state.isOrgAddOnModalFail = payload.isFail;
      state.postFailErrorMessage = payload.errorMessage;
      state.isOrgAddOnModalLoading = false;
    },
  },
});
export const {
  getSearchText,
  clearSearcText,
  getOrgLicensesDataForStatusHandling,
  handleOrgLicensesOrderIdValidation,
  handleOrgLicenseDurationValidation,
  handleOrgLicensesUnitsValidation,
  getOrgLicenseOrderIDValue,
  getOrgLicensePostApiSuccess,
  getOrgLicenseBundleName,
  getOrgLicenseUnitsValue,
  getOrgLicenseSelectedContractDuration,
  expandSearchInMobile,
  handleOrgLicensesOrderIdCharacterLengthValidation,
  closeSearchInMobile,
  handleOrgLicenseModalPopup,
  getPageNumber,
  fetchOrganizationLicenseLoading,
  fetchOrganizationLicenseFail,
  fetchOrganizationLicenseSuccess,
  resetOrgLicenseModalValues,
  handleContractDurationPeriodData,
  setAddonsIsLoading,
  handleOrgAddOnModal,
  handleOrgAddOnModalloading,
  handleOrgAddOnModalSuccess,
  handleOrgAddOnModalFail,
  setAddonsData,
} = orgLinceseSlice.actions;
export default orgLinceseSlice.reducer;
