import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IntialState = {
  systemColorTheme: string | null,
  systemLanguage: string,
  sidebarToggle:boolean;
  auth0Token:string
};

export const initialState: IntialState = {
  systemColorTheme: localStorage.getItem('chakra-ui-color-mode'),
  systemLanguage: 'en',
  sidebarToggle : false,
  auth0Token:""
};

const SystemSettingSlice = createSlice({
  name: 'SystemSettings',
  initialState,
  reducers: {
    getColorTheme: (state, action) => {
      state.systemColorTheme = action.payload;
    },
    getSystemLanguage: (state, action: PayloadAction<string>) => {
      state.systemLanguage = action.payload;
    },
    toggleSidebar:(state,{payload})=>{
      state.sidebarToggle = payload
    }
    ,
    getAuthoToken:(state,{payload})=>{
      state.auth0Token = payload
    }
  },
});

export const { getColorTheme, getSystemLanguage,toggleSidebar,getAuthoToken } = SystemSettingSlice.actions;
export default SystemSettingSlice.reducer;
