import { Box } from '@chakra-ui/react';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { postClientData } from 'redux/settings/clientAccess/Actions/clientAccessActions';
import { handleClientPostDataCreateButtonEnable } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import ClientNameInputField from './ClientNameInputField';
import ClientNotesInputField from './ClientNotesInputField';

const CreateClientAccessAllInputFields = () => {
  const dispatch = useAppDispatch();
  const clientNameValue = useAppSelector((state) => state.clientAccess.clientNameInputValue);
  const postClientApiData = useAppSelector((state) => state.clientAccess.postClientApiData);
  const clientPostDataFail = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const apiKeyValue = useAppSelector((state) => state.clientAccess.partnerAccessApiKey);
  const clientNotesInputValue = useAppSelector((state) => state.clientAccess.clientNotesInputValue);
  const clientNameUniqueValidationFail = useAppSelector((state) => state.clientAccess.clientNameUniqueValidationFail);
  const specialCharacterValidationSettings = useAppSelector(
    (state) => state.clientAccess.specialCharacterValidationSettings,
  );
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const postData: any = {
    name: clientNameValue,
    api_key: apiKeyValue,
    notes: clientNotesInputValue,
  };

  function SubmitButton() {
    if (clientNameValue && apiKeyValue && !clientNameUniqueValidationFail && !specialCharacterValidationSettings) {
      return dispatch(handleClientPostDataCreateButtonEnable(true));
    } else if (clientNameUniqueValidationFail == true) {
      return dispatch(handleClientPostDataCreateButtonEnable(false));
    } else {
      return dispatch(handleClientPostDataCreateButtonEnable(false));
    }
  }
  useEffect(() => {
    SubmitButton();
  }, [clientNameValue, apiKeyValue, clientNameUniqueValidationFail]);

  useEffect(() => {
    if (!clientPostDataFail && postClientApiData == true) {
      dispatch(postClientData(postData));
    }
  }, [postClientApiData]);

  return (
    <div>
      <ClientNameInputField />
      {/* <ClientIDSecretInputFields/>
    <ClientDBAudienceInputFields/>
    <ClientPhoneEmailInputFields/> */}
      <ClientNotesInputField />
      <Box mt="10px">
        {clientPostDataFail ? <BannerMessage message={postApiFailureMessage} defaultMessage={''} /> : ''}
      </Box>
    </div>
  );
};

export default CreateClientAccessAllInputFields;
