import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  HStack,
  CircularProgress,
  Text,
  Circle,
  Flex,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import StatusInfo from 'components/status/StatusInfo';
import StatusBadge from 'components/statusBadge/StatusBadge';
import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';

const EditDeveloperSettingsInfoPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const singleUserData = useAppSelector((state: RootState) => state.user.singlePulseUserData);
  const editPartnerAccessApiData = useAppSelector((state: any) => state.clientAccess.editPartnerAccessApiDataValue);
  const deviceStatusData = useAppSelector((state: RootState) => state.device.statusData);
  const devicesGridView = useAppSelector((state: RootState) => state.device.devicesGridView);
  const routes = [
    {
      id: 1,
      pathname: 'info',
      name: 'information',
      hasAccess: true,
    },
    // {
    //   id: 2,
    //   pathname: `/users/${params.modelId}/info/${params.deviceId}/useractivities`,
    //   name: 'users_activity',
    //   disable:true
    // },
  ];

  useEffect(() => {
    dispatch(getDeviceStatus());
  }, []);

  const handleDeviceClick = () => {
    navigate(`/settings/partners`);
    // dispatch(getSelectedDevice(singleDeviceData?.catalogue?.internal_name));
  };

  return (
    <>
      <Box data-testid="org-info-container">
        <Box display="flex" h="90px" borderBottom="1px solid #3333331A" pl="15px">
          <div data-testid="org-info-breadcumb">
            <Breadcrumb spacing="8px" pt="35px" separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  color="#24B0FF"
                  position="absolute"
                  left="16px"
                  top="14px"
                  onClick={handleDeviceClick}
                  display="flex"
                  alignItems="center"
                >
                  <RightChervonIcon />

                  <Text ml="5px">
                    <FormattedMessage id="partner_access.message" />
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Flex gap="20px" alignItems="center">
              {editPartnerAccessApiData?.name ? (
                <>
                  <Heading fontSize="36px" fontWeight="400" fontFamily="Helvetica" style={{ paddingLeft: '15px' }}>
                    {editPartnerAccessApiData.name}
                  </Heading>
                  <StatusBadge status={editPartnerAccessApiData?.status?.status} />
                </>
              ) : (
                <CircularProgress size="30px" isIndeterminate />
              )}
            </Flex>
          </div>
        </Box>
        <Box display="flex" h={`calc(100vh - 180px)`}>
          <Box borderRight="1px solid #3333331A">
            <OrganizationInfoSidebar routes={routes} />
          </Box>
          <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 470px)' : 'calc(100vw - 340px)' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default EditDeveloperSettingsInfoPage;
