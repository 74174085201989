import { createSlice } from '@reduxjs/toolkit';
import { OnboardingConfig, componentApiRes } from './types.gettingStarted';

export type OnboardingConfigState = {
  isLoading: boolean;
  config: OnboardingConfig | null;
  showGettingStarted: boolean;
};

const initialState: OnboardingConfigState = {
  isLoading: false,
  showGettingStarted: false,
  config: null,
};

const onboardingSlice = createSlice({
  name: 'gettingStarted',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setGettingStartedConfig: (state, action) => {
      state.config = action.payload;
      state.isLoading = false;
    },
    onboardingStatus: (state, action) => {
      state.showGettingStarted = action.payload;
    },
  },
});

export const { setGettingStartedConfig, setIsLoading, onboardingStatus } = onboardingSlice.actions;

export const gettingStartedReducer = onboardingSlice.reducer;
