import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../pages/layout';
import getAccessToken from '../utils/getAccessToken';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import splitNameToFirstLastName from '../utils/splitNameToFirstLastName';
import { setUserProfileInfo } from '../redux/user/Reducers/userSlice';
import { updateUserLastLogin } from 'redux/user/Actions/userActions';

type ProtectedRouteProp = {
  children: React.ReactNode;
};

function ProtectedRoutes(props: ProtectedRouteProp) {
  const { children } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loginSliceData = useAppSelector((state) => state.login);
  const isLoggedIn = localStorage.getItem('exo_sso');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, getIdTokenClaims, user } = useAuth0();

  useEffect(() => {
    getIdTokenClaims()
      .then((res) => {
        if (res) {
          const idToken = res.__raw;
          localStorage.setItem('exo_sso', idToken);
        } else {
          const exo_sso_token = localStorage.getItem('exo_sso');
          const accessToken = getAccessToken();
          if (!accessToken && !exo_sso_token) {
            navigate('/');
          }
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('something went worng while trying to get auth0 token');
      });

    if (user) {
      const [firstName, lastName] = splitNameToFirstLastName(user.name ? user.name : '');
      const avatar = user.picture ? user.picture : '';
      localStorage.setItem('exo_user_first_name', firstName);
      localStorage.setItem('exo_user_last_name', lastName);
      localStorage.setItem('exo_user_avatar', avatar);
      const postData = {
        email: user.email,
        last_login: new Date(),
      };
      dispatch(updateUserLastLogin(postData));
      dispatch(
        setUserProfileInfo({
          firstName: firstName,
          lastName: lastName,
          email: user.email ? user.email : '',
          avatar: avatar,
        }),
      );
    } else {
      // we set profile information based on login data,
      // but this will eventually change to fetch user details form auth0-user-api when ready.
      const email: string = loginSliceData?.user?.user_id ? loginSliceData?.user?.user_id : '';
      dispatch(
        setUserProfileInfo({
          firstName: email,
          lastName: '',
          email: email,
          avatar: '',
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  return (
    <Layout>
      <div data-testid="routes">{children}</div>
    </Layout>
  );
}
export default ProtectedRoutes;
