function addEllipsisToEmail(email: string): string {
  if(email) {
    const atIndex = email.indexOf('@');
    let username = email.slice(0, atIndex);
    const domain = email.slice(atIndex);

    const dotIndex = domain.indexOf('.');
    let domainName = domain.slice(0,dotIndex);
    let domainTag = domain.slice(dotIndex);
    
    if(username.length > 9) // 3 prefix + 3 elipsis(...) + 3 suffix = 9
    {
      const prefixUserName = username.slice(0, 3);
      const suffixUserName = username.slice(-3);
      username = `${prefixUserName}...${suffixUserName}`
    }

    if(domainName.length > 9)
    {
      const prefixDomainName = domainName.slice(0,3);
      const suffixDomainName = domainName.slice(-3);
      domainName = `${prefixDomainName}...${suffixDomainName}`
    }

    if(domainTag.length > 10)
    {
      const prefixDomainTag = domainTag.slice(0,4);
      const suffixDomainTag = domainTag.slice(-3);
      domainTag = `${prefixDomainTag}...${suffixDomainTag}`
    }
    
    return `${username}${domainName}${domainTag}`;
  }

  return email;
}

export default addEllipsisToEmail;
