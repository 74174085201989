/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';
import axios from 'axios';
import { Dispatch } from 'react';
import { OrganizationDashboard } from 'types/dashboard-types';
import { setError } from '../../error/errorSlice';

import {
  fetchDashboardFail,
  fetchDashboardLoading,
  fetchDashboardSuccess,
  setDashboardChartData,
  setDashboardChartDataLoading,
  setDashboardData,
  setDashboardDataChartFail,
  setDashboardDataFail,
  setDashboardDataLoading,
} from '../Reducers/dashboardSlice';
import moment from 'moment';

interface IGetAllAssets {
  type: boolean | string;
}
interface DashboardData {
  organizations: number;
  iris_devices: number;
  users: number;
  third_party_devices: number;
}

interface TotalData {
  organizations: number;
  iris_devices: number;
  users: number;
  third_party_devices: number;
}
interface FilteredData {
  [date: string]: DashboardData;
}
export interface DashboardResponse {
  status_code: number;
  message: string;
  data: {
    filtered_data: FilteredData;
    total_data: TotalData;
  };
}

const getDashboard = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchDashboardLoading);

  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('dashboard');

    if (res && res.status === 200) {
      dispatch(fetchDashboardSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchDashboardFail());
  }
};
export default getDashboard;

export const getDashboardData = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setDashboardDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const endDate = moment().utc().format('YYYY-MM-DD');
    const startDate = moment().utc().subtract(30, 'days').format('YYYY-MM-DD');
    const res = await userRequest.get(`/dashboard/summary/?start_date=${startDate}&end_date=${endDate}`);
    if (res && res.status === 200) {
      const data = res.data.data;
      const mappedData = {
        totalOrgs: {
          count: data.total_orgs.count,
          trends: data.total_orgs.organization_trends,
        },
        orgsNotProvisioned: {
          count: data.orgs_not_provisioned.count,
          trends: 0,
        },
        orgsProvisionedNotActive: { count: data.orgs_provisioned_notactive.count },
        orgsWithExpiringLicensesLessThan30Days: { count: data.orgs_with_expiring_licenses_lessthan_30_days.count },
        orgsWithLicensesBreached: { count: data.orgs_with_licenses_breached.count },
        activeDevice: {
          count: data.active_device.count,
          trends: data.active_device.active_device_trends,
        },
        activeUser: {
          count: data.active_user.count,
          trends: data.active_user.active_user_trends,
        },
        thirdPartyDevices: {
          count: data.third_party_devices.count,
          trends: data.third_party_devices.third_party_device_trends,
        },
      } as OrganizationDashboard;
      dispatch(setDashboardData(mappedData));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(setDashboardDataFail());
  }
};

export const getDashboardChartData = (startDate: string, endDate: string) => async (dispatch: Dispatch<any>) => {
  dispatch(setDashboardChartDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));

    const res = await userRequest.get(`/dashboard/graph-metrics/?start_date=${startDate}&end_date=${endDate}`);
    if (res && res.status === 200) {
      const responseData: DashboardResponse = res.data;
      const { total_data, filtered_data } = responseData.data;
      const mappedTotalData: FilteredData = {};
      for (const [date, data] of Object.entries(total_data)) {
        mappedTotalData[date] = {
          organizations: data.organizations,
          iris_devices: data.iris_devices,
          users: data.users,
          third_party_devices: data.third_party_devices,
        };
      }
      const mappedFilteredData: FilteredData = {};
      for (const [date, data] of Object.entries(filtered_data)) {
        mappedFilteredData[date] = {
          organizations: data.organizations,
          iris_devices: data.iris_devices,
          users: data.users,
          third_party_devices: data.third_party_devices,
        };
      }
      dispatch(
        setDashboardChartData({
          totalData: mappedTotalData,
          filteredData: mappedFilteredData,
        }),
      );
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(setDashboardDataFail());
  }
};
