/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import {
  Box,
  Button,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Wrap,
  WrapItem,
  Circle,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customColor } from 'assets/styles/colors';

import {
  editUserPostDataApiFail,
  fetchUserRolePostData,
  fetchUserRoleValue,
  handleActivateEditPulseUser,
  handleDeactivateEditPulseUser,
  handleDeleteEditPulseUser,
  resetUserValidationValues,
} from 'redux/user/Reducers/userSlice';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { editUsersData, getSinglePulseUserData, getUsersRoles, getUsersStatuses } from 'redux/user/Actions/userActions';
import { RootState } from 'redux/Store/store';

import { CircleCheckIconOrg } from 'assets/icons/icons';
import UserRolePermissions from '../createUserInputFields/UserRolePermissions';
import { getUserStatusAndFlow } from 'utils/statusFlow/UserStatusFlow';
import ActivatePulseUserStatusModal from './EditPulseUserStatus/ActivatePulseUserStatusModal';
import DeactivatePulseUserStatusModal from './EditPulseUserStatus/DeactivatePulseUserStatusModal';
import DeletePulseUserStatusModal from './EditPulseUserStatus/DeletePulseUserStatusModal';
import Loader from 'components/loader/Loader';
import { Error_Type_Enum } from '../../../utils/enum';
import { UserRolePermission } from 'types/user-types';
import { useParams } from 'react-router-dom';
import { capitalizeText } from '@exo/shared-ui';
import { userAccessConst } from 'utils/constants/userAccessConst';
import StatusInfo from 'components/status/StatusInfo';

const EditUserInputFields = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const { userId } = useParams();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const singlePulseUserData = useAppSelector((state: any) => state.user.singlePulseUserData);
  const [inputValues, setInputValues] = useState<{ [x: string]: string }>({
    ['owner_phone']: singlePulseUserData?.phone,
  });
  const deviceStatusData = useAppSelector((state: any) => state.user.userStatusesData);
  const [isEnabled, setIsEnable] = useState<any>(false);
  const userFirstNameValue = useAppSelector((state) => state.user.userFirstName);
  const userLastNameChange = useAppSelector((state) => state.user.userLastName);
  const userPhoneInputValue = useAppSelector((state) => state.user.userPhoneNumber);
  const userEmailValue = useAppSelector((state) => state.user.userEmail);
  const usersRole = useAppSelector((state) => state.user.userRoleIDPostData);
  const editUserPostDataLoading = useAppSelector((state) => state.user.editUserPostDataLoading);
  const allRegions = useAppSelector((state) => state.user.userAllRegionValue);
  const regionSelectedData = useAppSelector((state) => state.user.userRegionData);
  const roles = useAppSelector((state) => state.user.roles);
  const editUserApiFail = useAppSelector((state) => state.user.editUserPostDataFail);
  const isLoading = useAppSelector((state) => state.user.singlePulseUserDataLoading);
  const [deviceStatusValue, setDeviceStatusValue] = useState<any>(
    singlePulseUserData && singlePulseUserData?.status?.id,
  );
  const editUserPostDataFail = useAppSelector((state: RootState) => state.user.editUserPostDataFail);

  const editUserStatusValue = useAppSelector((state) => state.user.editUserStatusData);

  const [userRole, setUserRole] = useState<any>(singlePulseUserData?.role);
  const [statusValue, setStatusValue] = useState<any>(singlePulseUserData?.status?.id);
  const hasEditUserAccess = userPermissions.users?.includes(userAccessConst.user.updateUser);
  const hasUpdateUserStatusAccess = userPermissions.users?.includes(userAccessConst.user.updateUserStatus);

  useEffect(() => {
    singlePulseUserData;
    setInputValues((prevState: any) => ({ ...prevState, ['owner_phone']: singlePulseUserData?.phone }));
  }, [singlePulseUserData]);

  const postData: any = {
    first_name: capitalizeText(userFirstNameValue ? userFirstNameValue : singlePulseUserData?.first_name),
    last_name: capitalizeText(userLastNameChange ? userLastNameChange : singlePulseUserData?.last_name),
    email: singlePulseUserData?.email,
    status: editUserStatusValue ? editUserStatusValue : singlePulseUserData?.status?.id,
    role: usersRole ? usersRole : roles.find((item: any) => item.role == singlePulseUserData?.role)?.id,
    all_regions: regionSelectedData.length == 0 ? allRegions : false,
    regions: regionSelectedData,
    phone: userPhoneInputValue ? userPhoneInputValue : singlePulseUserData?.phone,
  };

  useEffect(() => {
    statusValue;
    setDeviceStatusValue(singlePulseUserData && singlePulseUserData.status);
  }, [singlePulseUserData]);

  const handleUserRole = (e: any) => {
    setIsEnable(true);
    setUserRole(e.target.value);
    dispatch(fetchUserRoleValue(e.target.name));
    dispatch(fetchUserRolePostData(e.target.value));
  };

  useEffect(() => {
    if (editUserPostDataFail === 'SUCCESS') {
      setTimeout(() => {
        dispatch(editUserPostDataApiFail(false));
        dispatch(getSinglePulseUserData(userId));
        dispatch(resetUserValidationValues());
        setIsEnable(false);
      }, 1000);
    }
  }, [editUserPostDataFail]);

  useEffect(() => {
    dispatch(getUsersRoles(0));
    dispatch(getUsersStatuses());
    setUserRole(singlePulseUserData?.role);
    setStatusValue(singlePulseUserData?.status?.id);
  }, [singlePulseUserData]);

  useEffect(() => {
    dispatch(getUsersRoles(0));
    dispatch(getUsersStatuses());
    dispatch(getSinglePulseUserData(userId));
    setUserRole(singlePulseUserData?.role);
    setStatusValue(singlePulseUserData?.status?.id);
  }, [userId]);

  const handleEditPulseUserData = () => {
    dispatch(editUsersData(postData, userId));
  };

  const a: any = getUserStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.id == statusValue)?.status,
  ).action;

  const b: any = getUserStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.id == statusValue)?.status,
  ).status;
  const c: any = getUserStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.id == statusValue)?.status,
  ).actionPast;

  const d: any = getUserStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.id == statusValue)?.status,
  ).statusPast;
  const e: any = getUserStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.id == statusValue)?.status,
  ).statusButtonText;

  const handleActiveModal = () => {
    if (b == 'Active') {
      dispatch(handleActivateEditPulseUser(true));
    }
  };

  const handleDelete = () => {
    if (a == 'Deactivate') {
      dispatch(handleDeactivateEditPulseUser(true));
    }
    if (a == 'Delete') {
      dispatch(handleDeleteEditPulseUser(true));
    }
  };
  return (
    <Box
      height="calc(100vh - 180px)"
      display="block"
      paddingBottom="50px"
      overflowX="hidden"
      overflowY="scroll"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Loader isLoading={isLoading} />
      {singlePulseUserData && !isLoading ? (
        <>
          <Wrap>
            <Box display="block">
              <Box display="block" pl="28px">
                <Box mt="20px" minH="90px">
                  <Wrap display="flex" spacing="40px">
                    <WrapItem>
                      <Box>
                        <Text fontWeight="700" fontSize="24px" minWidth={305}>
                          Information
                        </Text>
                      </Box>
                    </WrapItem>
                    {hasEditUserAccess && (
                      <WrapItem paddingTop="14px" minWidth={305} flexDirection="row-reverse">
                        <Wrap display="flex" spacingX="150px">
                          <WrapItem>
                            <Box ml="5px">
                              <Button
                                isLoading={editUserPostDataLoading}
                                width="82px"
                                h="24px"
                                border={isEnabled ? '1px solid #333333' : '1px solid #24B0FF'}
                                borderRadius="15px"
                                borderColor={isEnabled ? '#24B0FF' : '#333333'}
                                color={isEnabled ? '#24B0FF' : '#333333'}
                                loadingText="Save"
                                colorScheme="teal"
                                variant="outline"
                                type="button"
                                spinnerPlacement="end"
                                onClick={handleEditPulseUserData}
                                isDisabled={!isEnabled}
                                fontSize="14px"
                                fontWeight="500"
                              >
                                <Box display="flex" alignItems="center">
                                  <Text fontSize="14px" fontWeight="500" color={isEnabled ? '#24B0FF' : '#333333'}>
                                    Save
                                  </Text>

                                  {editUserApiFail === Error_Type_Enum.Success && (
                                    <Icon w="20px" pl="4px" h="20px">
                                      <CircleCheckIconOrg />
                                    </Icon>
                                  )}
                                </Box>
                              </Button>
                            </Box>
                          </WrapItem>
                        </Wrap>
                      </WrapItem>
                    )}
                  </Wrap>
                  <WrapItem>
                    <Box minH="60px" mt="10px">
                      <InputLabel value={singlePulseUserData?.status?.status} name="status" />

                      <Box display="flex" marginTop={2} fontSize="14px">
                        <StatusInfo
                          indicatorSize="6px"
                          label={singlePulseUserData?.status?.status}
                          indicatorColor={singlePulseUserData?.status?.color_code}
                        />
                      </Box>
                    </Box>
                  </WrapItem>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  <Box minH="60px">
                    <Box minH="60px">
                      <InputLabel value={singlePulseUserData?.first_name} name={'first_name'} />
                      <Text fontWeight="400" fontSize="14px" textTransform="capitalize" marginTop={2} minWidth={305}>
                        {singlePulseUserData?.first_name}
                      </Text>
                    </Box>

                    <Box minH="60px">
                      <InputLabel value={'phone'} name={'phone'} />
                      <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                        {singlePulseUserData?.phone
                          ? singlePulseUserData?.phone_code?.country_phone_code + ' ' + singlePulseUserData?.phone
                          : '-'}
                      </Text>
                    </Box>
                  </Box>
                  <Box minH="60px">
                    <Box minH="60px">
                      <InputLabel value={singlePulseUserData?.last_name} name={'last_name'} />
                      <Text fontWeight="400" fontSize="14px" textTransform="capitalize" marginTop={2} minWidth={305}>
                        {singlePulseUserData?.last_name}
                      </Text>
                    </Box>

                    <Box minH="60px">
                      <InputLabel value={singlePulseUserData?.email} name={'email'} />
                      <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                        {singlePulseUserData?.email}
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Wrap display="flex" spacingX="150px">
                      <WrapItem>
                        <Box>
                          <InputLabel
                            value={singlePulseUserData?.catalogue?.catalogue_number}
                            name="device_identifier"
                          />
                          <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                            {singlePulseUserData?.udid}
                          </Text>
                        </Box>
                      </WrapItem>
                    </Wrap>
                  </Box>
                </SimpleGrid>
                <WrapItem>
                  <Flex flexDirection="column" mt="-60px">
                    <InputLabel value={singlePulseUserData?.role} name="user_role" />
                    {hasEditUserAccess ? (
                      <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              isActive={isOpen}
                              as={Button}
                              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                              borderBottom="1px solid"
                              borderRadius="0px"
                              _focusVisible={{ borderBottomColor: colors.primaryColor }}
                              borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                              variant="flushed"
                              data-testid="license-info"
                              rightIcon={
                                isOpen ? (
                                  <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                                ) : (
                                  <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                                )
                              }
                            >
                              {userRole || singlePulseUserData?.role ? (
                                <Text
                                  float="left"
                                  marginLeft="-15px"
                                  fontSize="14px"
                                  fontWeight="400"
                                  color={colors.subTextColor}
                                >
                                  {roles && roles.find((role: UserRolePermission) => role.id == userRole)?.role
                                    ? roles && roles.find((role: UserRolePermission) => role.id == userRole)?.role
                                    : singlePulseUserData?.role}
                                </Text>
                              ) : (
                                <Text float="left" marginLeft="-15px" fontSize="16px" fontWeight="400" color="#8c8c8c">
                                  <FormattedMessage id="user_role.message" />
                                </Text>
                              )}
                            </MenuButton>
                            <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }}>
                              {roles &&
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                roles.map((role: any) => (
                                  <MenuItem
                                    key={role.id}
                                    value={role.id}
                                    name={role.role}
                                    fontSize="14px"
                                    backgroundColor="transparent"
                                    _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                                    onClick={handleUserRole}
                                  >
                                    {role.role}
                                  </MenuItem>
                                ))}
                            </MenuList>
                          </>
                        )}
                      </Menu>
                    ) : (
                      <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                        {roles && roles.find((role: UserRolePermission) => role.id == userRole)?.role
                          ? roles && roles.find((role: UserRolePermission) => role.id == userRole)?.role
                          : singlePulseUserData?.role}
                      </Text>
                    )}
                  </Flex>
                </WrapItem>

                <Box width="700px" mt="5px">
                  <UserRolePermissions />
                </Box>
                {hasUpdateUserStatusAccess && (
                  <Box width="650px">
                    {(a.length > 0 || b.length > 0) && (
                      <Text fontSize="18px" fontWeight="700">
                        Additional Action
                      </Text>
                    )}

                    {b.length > 0 ? (
                      <Box>
                        <Text fontSize="12px" color={colors.tableHeadColor} mt="15px" fontWeight="700">
                          Activate User
                        </Text>

                        <Box display="flex" mt="10px" justifyContent="space-between">
                          <Box width="80%">
                            <Text fontSize="14px" color={colors.copyRightColor}>
                              <FormattedMessage id="activate_user_text.message" />
                            </Text>
                          </Box>
                          <Box>
                            <Button
                              variant="outline"
                              borderRadius="15px"
                              height="30px"
                              fontSize="12px"
                              width="100px"
                              borderColor="#24B0FF"
                              _hover={{
                                bg: '#24B0FF',
                                color: 'white',
                              }}
                              color="#24B0FF"
                              onClick={handleActiveModal}
                            >
                              {e ? e : b}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      ''
                    )}
                    {a.length > 0 ? (
                      <Box>
                        <Text fontSize="12px" color={colors.tableHeadColor} mt="15px" fontWeight="700">
                          {a} User
                        </Text>
                        <Box display="flex" mt="10px" justifyContent="space-between">
                          <Box width="80%">
                            <Text fontSize="14px" color={colors.copyRightColor}>
                              {a == 'Deactivate' ? (
                                <FormattedMessage id="deactivate_user_text.message" values={{ br: <br /> }} />
                              ) : (
                                ''
                              )}
                              {a == 'Delete' ? <FormattedMessage id="delete_user_text.message" /> : ''}
                            </Text>
                          </Box>
                          <Box>
                            <Button
                              variant="outline"
                              borderRadius="15px"
                              height="30px"
                              width="100px"
                              fontSize="12px"
                              _hover={{
                                bg: '#FB5654',
                                color: 'white',
                              }}
                              borderColor="#FB5654"
                              color="#FB5654"
                              onClick={handleDelete}
                            >
                              {a}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                )}
              </Box>
              <ActivatePulseUserStatusModal />
              <DeactivatePulseUserStatusModal />
              <DeletePulseUserStatusModal />
            </Box>
          </Wrap>
        </>
      ) : (
        ''
      )}
    </Box>
  );
};

export default EditUserInputFields;
