import React, { useEffect } from 'react';
import {
  IconButton,
  Box,
  Flex,
  Text,
  useDisclosure,
  FlexProps,
  useColorModeValue,
  Slide,
  Circle,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import UserInfoBadge from 'components/UserInfoBadge';
import Sidebar from 'layoutWrapper/sidebar/Sidebar';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toggleSidebar } from 'redux/systemSettings/systemSettingSlice';
import { LeftChervonIcon, RightChervonIcon } from 'assets/icons/icons';
import { setError } from '../redux/error/errorSlice';
import ApiError from '../pages/error/apiError';
import { SessionTimeout } from '@exo/shared-ui';
import { getUserPermissions } from '../redux/user/Actions/userActions';
import { userAccessConst } from 'utils/constants/userAccessConst';
import { routePath } from 'routes/routePaths';
import { getCountry } from 'redux/common/commonActions';

export default function LayoutWrapper() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bg = useColorModeValue('white', '#394049');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = customColor();
  const toggle = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const error = useAppSelector((state) => state.error);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const location = useLocation();

  useEffect(() => {
    dispatch(getUserPermissions());
    dispatch(getCountry());
  }, []);

  const hasRouteAccess = () => {
    const locationPath = location.pathname.replace(/^\/+/g, '');
    switch (true) {
      case locationPath.startsWith(routePath.Organizations):
        return userPermissions.sideNavigation?.includes(userAccessConst.sideNavigation.organizations);
      case locationPath.startsWith(routePath.Customers):
        return userPermissions.customers?.includes(userAccessConst.customers.listCustomers);
      case locationPath.startsWith(routePath.DeviceModels):
        return userPermissions.sideNavigation?.includes(userAccessConst.sideNavigation.devices);
      case locationPath.startsWith(routePath.licenses):
        return userPermissions.sideNavigation?.includes(userAccessConst.sideNavigation.bundles);
      case locationPath.startsWith(routePath.Users):
        return userPermissions.sideNavigation?.includes(userAccessConst.sideNavigation.users);
      case locationPath.startsWith(routePath.Settings):
        return userPermissions.sideNavigation?.includes(userAccessConst.sideNavigation.settings);
      case locationPath.startsWith(routePath.Analytics):
        return userPermissions.sideNavigation?.includes(userAccessConst.sideNavigation.analytics);
      case locationPath.startsWith(routePath.Communication):
        return userPermissions.sideNavigation?.includes(userAccessConst.sideNavigation.communication);
      case locationPath.startsWith(routePath.Dashboard):
      case locationPath.startsWith(routePath.UnAuthorized):
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    // reset the error upon route navigation
    dispatch(setError({ isError: false, errorMessage: '' }));
    if (Object.keys(userPermissions).length && !hasRouteAccess()) {
      navigate('/un-authorized');
    }
  }, [location.pathname, userPermissions]);

  return (
    <Box minH="100vh" position="relative" overflow="hidden" bg={bg}>
      <SessionTimeout />
      <Sidebar onClose={() => onClose} display={{ base: 'none', lg: 'block' }} data-testid="close-btn" />
      <Slide direction="left" in={isOpen} style={{ zIndex: 10, transition: 'all .1s ease' }}>
        <Sidebar onClose={onClose} />
      </Slide>

      <MobileNav onOpen={onOpen} />
      <Box
        position="absolute"
        left={{ xs: '0', lg: toggle ? '240px' : '100px' }}
        h="100%"
        w={{
          xs: '100%',
          lg: `calc(100% - ${toggle ? '240px' : '100px'})`,
        }}
        transition="0.5s ease"
        data-testid="content_container"
      >
        <Box display={{ xs: 'none', lg: 'block' }}>
          <Circle
            size="22px"
            position="absolute"
            bg="white"
            cursor="pointer"
            alignItems="center"
            color={colors.primaryColor}
            justifyContent="center"
            top={{ lg: 'calc(100% - 200px)', xs: '200px', dt: 'calc(100% - 202px)' }}
            left={{ lg: toggle ? '-1.2%' : '-1%', xl: toggle ? '-1%' : '-1%', dt: toggle ? '-0.7%' : '-0.8%' }}
            onClick={() => dispatch(toggleSidebar(!toggle))}
            boxShadow={toggle ? `0px 4px 17px rgba(0, 0, 0, 0.2)` : `0px 4px 17px rgba(0, 0, 0, 0.08)`}
            z-index="999px"
            style={{ zIndex: '999' }}
          >
            {toggle ? (
              <Box mb="3px" mr="4px">
                <RightChervonIcon />
              </Box>
            ) : (
              <Box ml="4px" mb="3px">
                <LeftChervonIcon />
              </Box>
            )}
          </Circle>
        </Box>
        {error && error.isError && error.errorMessage.toLowerCase() == 'network error' ? <ApiError /> : <Outlet />}
      </Box>
    </Box>
  );
}
interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const colors = customColor();
  return (
    <Flex
      px={{ xs: 4, lg: 4 }}
      p="2"
      h="80px"
      borderBottomWidth="1px"
      borderBottomColor="light.1000"
      alignItems="center"
      justifyContent={{ xs: 'space-between' }}
      {...rest}
      data-testid="mobile-nav"
      pl={{ lg: '30px' }}
    >
      <Box display={{ xs: 'none', md: 'flex', lg: 'none' }} alignItems="center">
        <IconButton
          display={{ xs: 'flex', lg: 'none' }}
          onClick={onOpen}
          variant="none"
          aria-label="open menu"
          icon={<FiMenu />}
        />
        <Flex display={{ xs: 'flex' }} fontSize={{ xs: '20px', lg: '36px' }}>
          <Text data-testid="exo-text" color={colors.textColor}>
            <FormattedMessage id="exo_name.message" />
          </Text>
          <Text color="common.100" ml="2" data-testid="pulse-text">
            <FormattedMessage id="pulse_name.message" />
          </Text>
        </Flex>
      </Box>
      <IconButton
        display={{ xs: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="none"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Flex display={{ xs: 'flex', md: 'none', lg: 'flex' }} fontSize={{ xs: '20px', lg: '40px' }}>
        <Text data-testid="exo-text" color={colors.textColor}>
          <FormattedMessage id="exo_name.message" />
        </Text>
        <Text color="common.100" ml="2" data-testid="pulse-text">
          <FormattedMessage id="pulse_name.message" />
        </Text>
      </Flex>
      <Box data-testid="user_badge_info">
        <UserInfoBadge />
      </Box>
    </Flex>
  );
};
