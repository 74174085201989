/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react';
import { AbsoluteCenter, Box, CircularProgress, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

import { useRef } from 'react';

import { RootState } from '../../../../../../../redux/Store/store';
import OrgUserActivityMainContent from './OrgUserActivityMainContent';
import OrgUserActivityFilters from './OrgUserActivityFilters';
import { getOrgUsersActivityData } from 'redux/orgUserActivity/Actions/orgUserActivityActions';
import { getOrgUsersActivityPageNumber } from 'redux/orgUserActivity/Reducers/orgUserActivitySlice';
import { getUserPageNumber } from 'redux/orgUser/Reducer/orgUserSlice';
import { getDevicePageNumber } from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import { useParams } from 'react-router-dom';

const OrgUsersActivity = () => {
  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const usersData = useAppSelector((state: RootState) => state.orgUserActivity);
  const dispatch = useAppDispatch();
  const { orgId } = useParams();
  const initialRender = useRef({
    primary: true,
    secondary: true,
  });

  const getUsersData = () => {
    const payload: any = {
      search: usersData.searchText,
      page: usersData.orgUsersActivitySelectedPage,
      order: usersData.selectedOrderValue,
    };
    const queryString = Object.entries(payload)
      .map(([key, value]: any) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
    dispatch(getOrgUsersActivityData(orgId, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getUsersData();
  }, [usersData.orgUsersActivitySelectedPage]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getOrgUsersActivityPageNumber(1));
    getUsersData();
  }, [usersData.searchText, usersData.selectedOrderValue]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getOrgUsersActivityPageNumber(1));
    }, 200);
    getUsersData();
    dispatch(getUserPageNumber(1));
    dispatch(getDevicePageNumber(1));
  }, []);

  const search = usersData.searchText;
  const isDataLoading = usersData.orgUsersActivityDataLoading;

  const isFiltersApplied = !!search;
  return (
    <>
      <Box h={`calc(100vh - 180px)`} w="100%" data-testid="org-users-activity">
        {singleOrganizationData ? (
          <Box display="block" h={`calc(100vh - 170px)`} pt="20px">
            <Box
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row' }}
              justifyContent={{ xs: 'none', lg: 'space-between' }}
              transition="0.5s ease"
              h="70px"
            >
              <Text fontWeight="700" fontSize="24px" minWidth={305} pl="32px" mt="4px">
                User Activity
              </Text>
              <OrgUserActivityFilters isFiltersApplied={isFiltersApplied} />
            </Box>
            <OrgUserActivityMainContent isFiltersApplied={isFiltersApplied} />
          </Box>
        ) : (
          <Box position="relative" h="100%" display="flex" alignItems="center" justifyContent="center">
            <AbsoluteCenter p="4" axis="both">
              <CircularProgress isIndeterminate size="50px" />
            </AbsoluteCenter>
          </Box>
        )}
      </Box>
    </>
  );
};
export default OrgUsersActivity;
