import { AbsoluteCenter, Box, Text } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import React from 'react';
import { customColor } from 'assets/styles/colors';
import { useAppDispatch } from '../../../../../../libs/admin-ui/src/lib/redux/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomTable from 'components/customTable/CustomTable';
import moment from 'moment';
import { SoftwareBundle } from 'types/SoftwareBundle';

function LicenseBundleListView() {
  const colors = customColor();
  const intl = useIntl();
  const navigate = useNavigate();

  const bundles = useAppSelector((state) => state.softwareBundles.softwareBundles);
  const BundleData = bundles?.map((item: SoftwareBundle) => {
    return {
      id: item.id,
      name: item.name,
      catalogue: item.catalogueNumber,
      availability_date: moment(item.availabilityDate).format('ll'),
      expiration_grace_period: item.gracePeriodDays + ' ' + intl.formatMessage({ id: 'days.message' }),
      organization_tier: item.licenseTier?.tier_name,
      status: item.status,
    };
  });
  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'name') {
      navigate(`/licenses/bundles/${row.id}/info`);
    } else {
      navigate(``);
    }
  };

  const columns = [
    {
      field: 'name',
      header: <FormattedMessage id="name.message" />,
      link: true,
      isCapitalize: true,
    },
    {
      field: 'catalogue',
      header: <FormattedMessage id="catalogue.message" />,
      link: false,
      isCapitalize: true,
    },
    { field: 'availability_date', header: <FormattedMessage id="availability_date.message" />, link: false },
    {
      field: 'expiration_grace_period',
      header: <FormattedMessage id="expiration_grace_period.message" />,
      link: false,
    },
    {
      field: 'organization_tier',
      header: <FormattedMessage id="organization_tier.message" />,
      link: false,
    },
    { field: 'status', header: <FormattedMessage id="status.message" />, link: false },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pr="20px"
      overflowY="scroll"
      h="calc(100vh - 265px)"
      data-testid="license-bundles-data"
      ml="-15px"
    >
      {bundles?.length == 0 && (
        <AbsoluteCenter display="flex" justifyContent="center" w="100%">
          <Text
            opacity="0.5"
            color={colors.textColor}
            fontSize={{ xs: '16px', lg: '18px' }}
            data-testid="no-match-found"
          >
            <FormattedMessage id="no_matches_found.message" />
          </Text>
        </AbsoluteCenter>
      )}
      {bundles?.length > 0 && (
        <CustomTable columns={columns} data={BundleData} route={''} handleNavigateFromChild={handleNavigateFromChild} />
      )}
    </Box>
  );
}
export default LicenseBundleListView;
