import { Box, useDisclosure } from '@chakra-ui/react';
import CustomModal from 'components/customModal/CustomModal';
import React, { useEffect } from 'react';
import { resetDatePickerValues } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { useAppSelector } from 'redux/hooks';
import { getBundleData, getBundleGracePeriod } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import {
  handleBundleSuccessfullPost,
  handleToPostBundleData,
  resetSoftwareBundlePopUpValues,
  toggleBundleEditScreenModal,
  toggleModalButtonEnableSpinner,
} from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { Error_Type_Enum } from 'utils/enum';
import { useAppDispatch } from '../../../../../../libs/admin-ui/src/lib/redux/hooks';
import SoftwareBundleEditScreenInputs from './SoftwareBundleEditScreenInputs';
import { RootState } from 'redux/Store/store';

const SoftwareBundleEditModal = () => {
  const dispatch = useAppDispatch();
  const { onClose } = useDisclosure();
  const toggleSoftwareBundleEditModalOpen = useAppSelector((state) => state.softwareBundles.toggleBundleEditScreen);
  const createBundleApiFail = useAppSelector((state) => state.softwareBundles.editBundlePostApiFail);
  const createBundleModalToggle = useAppSelector((state) => state.softwareBundles.handleSoftwareBundleModal);
  const postFailure = useAppSelector((state) => state.softwareBundles.getCreateBundleApiDataFail);
  const enableCreateButton = useAppSelector((state) => state.softwareBundles.getBundleCreateButtonEnable);
  const postDataSuccessfulCreation = useAppSelector((state) => state.softwareBundles.fetchPostBundleApiSuccessCreation);
  const selectedPage = useAppSelector((state: RootState) => state.softwareBundles.selectedPage);
  const toggleModalButtonEnableSpinnerValue = useAppSelector(
    (state) => state.softwareBundles.toggleModalButtonEnableSpinnerValue,
  );

  useEffect(() => {
    if (createBundleApiFail === Error_Type_Enum.Fail) {
    } else if (createBundleApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetSoftwareBundlePopUpValues());
        dispatch(toggleBundleEditScreenModal(false));
        dispatch(getBundleData(selectedPage));
        onClose();
      }, 3200);
    }
  }, [createBundleApiFail]);

  const handleModalClose = () => {
    dispatch(toggleBundleEditScreenModal(false));
    dispatch(resetDatePickerValues());
    dispatch(resetSoftwareBundlePopUpValues());
  };

  const handleCreateButtonClick = () => {
    dispatch(handleToPostBundleData(true));
    dispatch(toggleModalButtonEnableSpinner(true));
    dispatch(getBundleGracePeriod());
    setTimeout(() => dispatch(handleBundleSuccessfullPost(true)), 1500);
    if (!createBundleModalToggle) {
      dispatch(handleBundleSuccessfullPost(false));
    }
  };

  return (
    <Box data-testid="edit-modal">
      <CustomModal
        openModal={toggleSoftwareBundleEditModalOpen}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createBundleApiFail && toggleModalButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={enableCreateButton}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleModalButtonEnableSpinnerValue}
        InputFieldsComponent={SoftwareBundleEditScreenInputs}
        modalHeader="edit_software_bundles"
        modalButtonName="save_modal"
      />
    </Box>
  );
};

export default SoftwareBundleEditModal;
