import { Box, useDisclosure } from '@chakra-ui/react';
import CustomModal from 'components/customModal/CustomModal';
import React, { useEffect } from 'react';
import { resetDatePickerValues } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { useAppSelector } from 'redux/hooks';
import { getBundleData } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import {
  handleBundleSuccessfullPost,
  handleCreateSoftwareBundleModal,
  handleToPostBundleData,
  resetSoftwareBundlePopUpValues,
  toggleModalButtonEnableSpinner,
} from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { Error_Type_Enum } from 'utils/enum';
import { useAppDispatch } from '../../../../../../libs/admin-ui/src/lib/redux/hooks';
import CreateSoftwareBundlesAllInputFields from '../createSoftwareBundlesInputFields/CreateSoftwareBundlesAllInputFields';
import { RootState } from 'redux/Store/store';

const CreateSoftwareBundleModal = () => {
  const dispatch = useAppDispatch();
  const { onClose } = useDisclosure();
  const toggleSoftwareBundleModalOpen = useAppSelector((state) => state.softwareBundles.handleSoftwareBundleModal);
  const createBundleApiFail = useAppSelector((state) => state.softwareBundles.getCreateBundleApiDataFailConstants);
  const createBundleModalToggle = useAppSelector((state) => state.softwareBundles.handleSoftwareBundleModal);
  const postFailure = useAppSelector((state) => state.softwareBundles.getCreateBundleApiDataFail);
  const enableCreateButton = useAppSelector((state) => state.softwareBundles.getBundleCreateButtonEnable);
  const postDataSuccessfulCreation = useAppSelector((state) => state.softwareBundles.fetchPostBundleApiSuccessCreation);
  const toggleModalButtonEnableSpinnerValue = useAppSelector(
    (state) => state.softwareBundles.toggleModalButtonEnableSpinnerValue,
  );

  useEffect(() => {
    if (createBundleApiFail.length > 0) {
      dispatch(handleBundleSuccessfullPost(true));
    }
    if (createBundleApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetSoftwareBundlePopUpValues());
        dispatch(handleCreateSoftwareBundleModal(false));
        dispatch(getBundleData(1));
        dispatch(resetDatePickerValues());
        onClose();
      }, ModalTimeOut);
    }
  }, [createBundleApiFail]);
  const handleModalClose = () => {
    dispatch(handleCreateSoftwareBundleModal(false));
    dispatch(resetDatePickerValues());
    dispatch(resetSoftwareBundlePopUpValues());
  };
  const handleCreateButtonClick = () => {
    // dispatch(fetchUserCreateButtonEnableValue(true));
    dispatch(handleToPostBundleData(true));
    dispatch(toggleModalButtonEnableSpinner(true));

    if (!createBundleModalToggle) {
      dispatch(handleBundleSuccessfullPost(false));
    }
  };

  return (
    <Box>
      <CustomModal
        openModal={toggleSoftwareBundleModalOpen}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createBundleApiFail && toggleModalButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={enableCreateButton}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleModalButtonEnableSpinnerValue}
        InputFieldsComponent={CreateSoftwareBundlesAllInputFields}
        modalHeader="add_license_bundle"
        modalButtonName="add_modal"
      />
    </Box>
  );
};

export default CreateSoftwareBundleModal;
