/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useEffect, useState } from 'react';
import {
  Box,
  Wrap,
  WrapItem,
  Text,
  Button,
  Circle,
  Icon,
  Textarea,
  CircularProgress,
  AbsoluteCenter,
  Input,
} from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { editClientAccessDataFail } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { FormattedMessage } from 'react-intl';
import {
  ediClientAccessPostData,
  editPartnerAccess,
  generateApiKey,
} from 'redux/settings/clientAccess/Actions/clientAccessActions';
import { customColor } from '../../../../../../libs/shared-ui/src/lib/assets/styles/colors';
import { CircleCheckIconOrg } from 'assets/icons/icons';
import { Error_Type_Enum } from 'utils/enum';
import { useParams } from 'react-router-dom';
import StatusInfo from 'components/status/StatusInfo';
import { userAccessConst } from 'utils/constants/userAccessConst';

const EditDeveloperSettingsFields = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const params = useParams();
  const editPartnerAccessApiData = useAppSelector((state: any) => state.clientAccess.editPartnerAccessApiDataValue);
  const clientAccessPostLoading = useAppSelector((state: any) => state.clientAccess.clientAccessPostLoading);
  const [isEnabled, setIsEnable] = useState<any>(false);
  const [partnerStatusValue, setpartnerStatusValue] = useState<any>(editPartnerAccessApiData?.status?.id);
  const generatedAPIkey = useAppSelector((state) => state.clientAccess.partnerAccessApiKey);
  const apiKeyValue = useAppSelector((state) => state.clientAccess.partnerAccessApiKey);
  const editClientAccessPostDataFail = useAppSelector((state) => state.clientAccess.editClientAccessPostDataFail);
  const [partnerAccessNotes, setPartnerAccessNotes] = useState<any>(editPartnerAccessApiData?.notes);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const hasEditAccess = userPermissions.settings?.includes(userAccessConst.setting.updatePartnerKey);
  const ID = params?.partnerId;

  const postData: any = {
    name: editPartnerAccessApiData?.name,
    api_key: apiKeyValue ? apiKeyValue : editPartnerAccessApiData?.api_key,
    status: partnerStatusValue ? partnerStatusValue : editPartnerAccessApiData?.status?.id,
    notes: partnerAccessNotes ? partnerAccessNotes : editPartnerAccessApiData?.notes,
  };
  useEffect(() => {
    setpartnerStatusValue(editPartnerAccessApiData && editPartnerAccessApiData?.status?.id);
    setPartnerAccessNotes(editPartnerAccessApiData && editPartnerAccessApiData?.notes);
  }, [editPartnerAccessApiData]);

  const handleNotes = (e: any) => {
    setPartnerAccessNotes(e.target.value);
    setIsEnable(true);
  };

  const getGeneratedApi = () => {
    dispatch(generateApiKey());
    setIsEnable(true);
  };
  useEffect(() => {
    if (editClientAccessPostDataFail === 'SUCCESS') {
      setTimeout(() => {
        dispatch(editClientAccessDataFail(Error_Type_Enum.Fail));
        setIsEnable(false);
      }, 1000);
    }
  }, [editClientAccessPostDataFail]);
  useEffect(() => {
    dispatch(editPartnerAccess(ID));
    editPartnerAccessApiData?.notes;
    setPartnerAccessNotes(editPartnerAccessApiData?.notes);
  }, []);
  useEffect(() => {
    editPartnerAccessApiData;
    setPartnerAccessNotes(editPartnerAccessApiData?.notes);
  }, [ID]);

  const handleEditPartnerAccessPage = () => {
    dispatch(ediClientAccessPostData(postData, ID));
  };
  return (
    <Box
      height="800px"
      display="block"
      overflowX="hidden"
      overflowY="scroll"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {editPartnerAccessApiData ? (
        <Wrap>
          <Box display="block">
            <Box w="1200px" height="1050px" display="block" pl="28px">
              <Box mt="5px" minH="90px">
                <Wrap display="flex" spacing="40px">
                  <WrapItem paddingTop="14px">
                    <Box>
                      <Text fontWeight="700" fontSize="24px" minWidth={305}>
                        Information
                      </Text>
                    </Box>
                  </WrapItem>
                  {hasEditAccess && (
                    <WrapItem paddingTop="14px" minWidth={305} flexDirection="row-reverse">
                      <Wrap display="flex" spacingX="150px">
                        <WrapItem>
                          <Box ml="5px" mt="5px">
                            <Button
                              isLoading={clientAccessPostLoading}
                              width="82px"
                              h="24px"
                              border={isEnabled ? '1px solid #333333' : '1px solid #24B0FF'}
                              borderRadius="15px"
                              borderColor={isEnabled ? '#24B0FF' : '#333333'}
                              color={isEnabled ? '#24B0FF' : '#333333'}
                              loadingText="Save"
                              colorScheme="teal"
                              variant="outline"
                              type="button"
                              spinnerPlacement="end"
                              onClick={handleEditPartnerAccessPage}
                              isDisabled={!isEnabled}
                              fontSize="14px"
                              fontWeight="500"
                            >
                              <Box display="flex" alignItems="center">
                                <Text fontSize="14px" fontWeight="500" color={isEnabled ? '#24B0FF' : '#333333'}>
                                  Save
                                </Text>

                                {editClientAccessPostDataFail === Error_Type_Enum.Success && (
                                  <Icon w="20px" pl="4px" h="18px">
                                    <CircleCheckIconOrg />
                                  </Icon>
                                )}
                              </Box>
                            </Button>
                          </Box>
                        </WrapItem>
                      </Wrap>
                    </WrapItem>
                  )}
                </Wrap>

                <>
                  <Wrap>
                    <WrapItem>
                      <Box minH="60px" flexDirection="row" mt="20px">
                        <InputLabel value={'phone'} name={'api_key'} />
                        <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                          {generatedAPIkey ? generatedAPIkey : editPartnerAccessApiData.api_key}
                        </Text>
                      </Box>
                    </WrapItem>
                    {hasEditAccess && (
                      <WrapItem>
                        <Box mt="20px">
                          <Button
                            pl="13px"
                            pt="2px"
                            mt="8px"
                            h="24px"
                            border="1px solid #24B0FF"
                            backgroundColor="transparent"
                            borderColor="#24B0FF"
                            fontWeight="700"
                            fontSize="12px"
                            alignItems="center"
                            borderRadius="20px"
                            color="#24B0FF"
                            _hover={{ backgroundColor: 'transparent' }}
                            onClick={getGeneratedApi}
                          >
                            <FormattedMessage id="refresh_api_key.message" />
                          </Button>
                        </Box>
                      </WrapItem>
                    )}
                  </Wrap>

                  <Wrap align="center" spacingX="30px" data-testid="container-element">
                    <Box flexDirection="row" minH="50px">
                      <WrapItem>
                        <Box minH="60px" flexDirection="row">
                          <InputLabel value={editPartnerAccessApiData?.name} name={'name'} />
                          <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                            {editPartnerAccessApiData?.name}
                          </Text>
                        </Box>
                      </WrapItem>
                    </Box>
                    <WrapItem>
                      <Box minH="60px" flexDirection="row">
                        <InputLabel value={editPartnerAccessApiData?.status?.id} name="status" />
                        <Box marginTop={2} fontSize="14px">
                          <StatusInfo
                            indicatorColor={editPartnerAccessApiData?.status?.color_code}
                            label={editPartnerAccessApiData?.status?.status}
                            indicatorSize="6px"
                          />
                        </Box>
                      </Box>
                    </WrapItem>
                  </Wrap>
                  <Wrap align="center" spacingX="30px" data-testid="container-element" width="635px">
                    <WrapItem>
                      {hasEditAccess ? (
                        <Box>
                          <InputLabel
                            value={editPartnerAccessApiData?.notes || partnerAccessNotes}
                            name={'notes_optional'}
                          />
                          <Input
                            variant="flushed"
                            key={editPartnerAccessApiData?.notes}
                            color={colors.subTextColor}
                            borderColor="#E0E0E0"
                            maxLength={75}
                            defaultValue={editPartnerAccessApiData?.notes}
                            width={{ xs: '85vw', smd: '89vw', md: '645px' }}
                            focusBorderColor={colors.primaryColor}
                            borderBottomColor={colors.inputBorderColor}
                            borderWidth="0 0 0.5px 0"
                            fontSize="14px"
                            _placeholder={{ fontsize: '14px' }}
                            onChange={handleNotes}
                            placeholder="Notes (Optional)"
                          />
                          <Text fontSize="12px" color="#333333" display="flex" float="right" justifyContent="flex-end">
                            {partnerAccessNotes ? Math.abs(partnerAccessNotes?.length - 75) : 75}
                          </Text>
                        </Box>
                      ) : (
                        <Box>
                          <InputLabel value={partnerAccessNotes} name={'notes'} />
                          <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                            {partnerAccessNotes}
                          </Text>
                        </Box>
                      )}
                    </WrapItem>{' '}
                  </Wrap>
                </>
              </Box>
            </Box>
          </Box>
        </Wrap>
      ) : (
        <Box position="relative" h="100%" display="flex" alignItems="center" justifyContent="center">
          <AbsoluteCenter p="4" axis="both">
            <CircularProgress isIndeterminate size="50px" />
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
};
export default EditDeveloperSettingsFields;
