import { capitalizeText } from '../../../../libs/shared-ui/src/lib/utils';

function addEllipsisToOrgName(orgName: string): string {
  let modifiedOrgName = capitalizeText(orgName);
  if (modifiedOrgName) {
    if (modifiedOrgName.length > 18) {
      const prefixOrgName = modifiedOrgName.slice(0, 7);
      const suffixOrgName = modifiedOrgName.slice(-7);
      modifiedOrgName = `${prefixOrgName}....${suffixOrgName}`;
    }
  }
  return modifiedOrgName;
}

export default addEllipsisToOrgName;
