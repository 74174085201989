import React, { useEffect, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';

import { RootState } from 'redux/Store/store';
import OrgDevicesMainContent from './mainContent/Main';
import { getOrganizationLicenses } from 'redux/organiztionLicenes/Actions/orgLicenseActions';
import Title from './title/Title';
import OrgLicensesFilter from './filters/OrgLicensesFilter';
import { getPageNumber } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import { getBundleData } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { getLicenseContractDurationPeriod } from 'redux/organiztionLicenes/Actions/orgLicenseActions';

const OrgLicense = () => {
  interface OrgFilterPayload {
    page?: number;
    search?: string;
    id?: string[];
  }
  const initialRender = useRef({ primary: true, secondary: true });

  const { orgId } = useParams<string>();
  const dispatch = useAppDispatch();
  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const organizationsData = useAppSelector((state: RootState) => state.orgLicense);
  const orgLicensePostApiSuccess = useAppSelector((state) => state.orgLicense.orgLicensePostApiSuccess);
  const getOrganizationLicenseData = () => {
    const data = [...organizationsData.filterData.status_id];
    const payload: OrgFilterPayload = {
      search: organizationsData.searchText,
      page: organizationsData.selectedPage,
      id: data.filter((itr) => itr !== 'All'),
    };
    const queryString = Object.entries(payload)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
    dispatch(getOrganizationLicenses(orgId, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getOrganizationLicenseData();
  }, [organizationsData.selectedPage]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getPageNumber(1));
    getOrganizationLicenseData();
  }, [organizationsData.searchText, organizationsData.filterData]);

  useEffect(() => {
    getOrganizationLicenseData();
  }, [orgLicensePostApiSuccess == true]);

  const filters = organizationsData.filterData;
  const search = organizationsData.searchText;
  const isFiltersApplied = !!((filters && filters.status_id && filters.status_id.length) || search);

  useEffect(() => {
    getOrganizationLicenseData();
    dispatch(getBundleData(1));
    dispatch(getLicenseContractDurationPeriod());
    return () => {
      dispatch(getPageNumber(1));
    };
  }, []);
  return (
    <>
      <Box>
        {singleOrganizationData && (
          <Box display="block" overflowX="auto">
            <Flex flexDirection="row" mt="20px" pl="30px" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection={{ xs: 'column', lg: 'row' }}
                justifyContent={{ xs: 'none', lg: 'space-between' }}
                transition="0.5s ease"
              >
                <Title />
              </Box>

              <OrgLicensesFilter isFiltersApplied={isFiltersApplied} />
            </Flex>
            <Box h={`calc(100vh - 250px)`} overflowY="hidden">
              <OrgDevicesMainContent isFiltersApplied={isFiltersApplied} />{' '}
            </Box>{' '}
          </Box>
        )}
      </Box>
    </>
  );
};
export default OrgLicense;
