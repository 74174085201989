import * as React from 'react';
import { useColorMode } from '@chakra-ui/react';

import { getColorTheme } from '../../redux/systemSettings/systemSettingSlice';
import { useAppDispatch } from '../../redux/hooks';

type ProtectedRouteProp = {
  children: React.ReactNode,
};

function Layout(props: ProtectedRouteProp) {
  const { children } = props;

  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  React.useEffect(() => {
    dispatch(getColorTheme(colorMode));
  }, [colorMode]);

  return <div data-testid="layout-test">{children}</div>;
}

export default Layout;
