import { Box } from '@chakra-ui/react';

import React from 'react';
import OrgUsersData from './OrgUsersData';

export default function OrgUsersMainContent({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  return (
    <Box data-testid="org-users">
      <OrgUsersData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
