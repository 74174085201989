import React from 'react';
import { Box, Text } from '@chakra-ui/react';

interface ItemProps {
  uuid: string;
  name: string;
  selected: boolean;
  onClick: (data: { uuid: string; name: string }) => void;
  disabled?: boolean;
}

const ListItem = ({ selected, uuid, name, onClick, disabled }: ItemProps) => {
  const handleItem = () => {
    onClick({
      uuid,
      name,
    });
  };

  return (
    <Box
      key={uuid}
      borderWidth="1px"
      border="1px solid rgba(255, 255, 255, 0.60)"
      borderRadius="14px"
      my="5px"
      display="flex"
      flexDir="column"
      width="305px"
      fontWeight={500}
      height="50px"
      backgroundColor={selected ? 'rgba(255, 255, 255, 0.15)' : 'transparent'}
      onClick={() => handleItem()}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      justifyContent="center"
      fontSize={{ xs: '16px', md: '24px', xl: '24px' }}
      letterSpacing="-0.24px"
      p={'15px 10px 15px 20px'}
    >
      <Text fontWeight={500} color={disabled ? 'rgba(255, 255, 255, 0.60)' : '#FFF'}>
        {name}
      </Text>
    </Box>
  );
};

export default ListItem;
