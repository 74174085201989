import { Box } from '@chakra-ui/react';
import React from 'react';

import SingleOrgDevicesActivityData from '../data/singleOrgDeviceActivityData';

interface FilterProps {
  isFiltersApplied: boolean;
}
export default function SingleOrgDevicesActivityMainContent({ isFiltersApplied }: FilterProps) {
  return (
    <Box h={`calc(100vh - 250px)`}>
      <SingleOrgDevicesActivityData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
