import React, { useEffect } from 'react';
import { handleCreateButtonEnableInModal } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { postOrgLicenseData } from 'redux/organiztionLicenes/Actions/orgLicenseActions';

import OrgLicenseBundleIDInputFields from './OrgLicenseBundleIDInputFields';
import OrgLicenseDateDurationInputFields from './OrgLicenseDateDurationInputFields';
import OrgLicenseNameCodeInputFields from './OrgLicenseNameCodeInputFields';
import OrgLicenseUnitsTrailInputFields from './OrgLicenseUnitsTrailInputFields';
import { useParams } from 'react-router-dom';
import { ExoPulseConnectUnlimited } from 'utils/constants/Constants';

const OrgLicenseCreateInputFields = () => {
  const dispatch = useAppDispatch();
  const { orgId } = useParams();
  const postClientApiData = useAppSelector((state) => state.clientAccess.postClientApiData);
  const clientPostDataFail = useAppSelector((state) => state.clientAccess.clientPostDataFail);

  const licenseunits = useAppSelector((state) => state.orgLicense.orgLicenseUnitsValue);
  const orderID = useAppSelector((state) => state.orgLicense.orgLicenseOrderIDValue);
  const bundelmanufactureYear = useAppSelector((state) => state.customInputFields.getDatePickerYear);
  const bundelmanufactureDay = useAppSelector((state) => state.customInputFields.getDatePickerDay);
  const bundelmanufactureMonth = useAppSelector((state) => state.customInputFields.getDatePickerMonth);
  const selectedContractDuration = useAppSelector((state) => state.orgLicense.orgLicenseSelectedContractDuration);
  const orgLicenseBundleName = useAppSelector((state: any) => state.orgLicense.orgLicenseBundleName);
  const softwareBundles = useAppSelector((state) => state.softwareBundles.softwareBundles);
  const bundleLicenseDropdown = useAppSelector((state) => state.orgLicense.organizationLincesesList);
  const orgLicensesOrderIdCharacterLength = useAppSelector(
    (state) => state.orgLicense.orgLicensesOrderIdCharacterLength,
  );
  const orgLicensesUnitsValidation = useAppSelector((state) => state.orgLicense.orgLicensesUnitsValidation);
  const endDateValue = useAppSelector((state) => state.organization.licenseEndDateValue);

  const postOrgLicenseDataValues = {
    catalog_id: orgLicenseBundleName
      ? softwareBundles?.find((softwareBundle: any) => softwareBundle.id == orgLicenseBundleName.id)?.catalogueNumber
      : bundleLicenseDropdown[0]?.bundle?.catalogue_number,
    order_id: orderID,
    start_date: `${bundelmanufactureYear}-${
      bundelmanufactureMonth < 10 ? '0' + bundelmanufactureMonth : bundelmanufactureMonth
    }-${bundelmanufactureDay < 10 ? '0' + bundelmanufactureDay : bundelmanufactureDay}`,
    end_date: endDateValue,
    purchased_quantity: orgLicenseBundleName.name == ExoPulseConnectUnlimited ? 0 : Number(licenseunits),
    validity_period: Number(selectedContractDuration) * 365,
    created_by: 'PULSE_USER',
  };
  useEffect(() => {
    if (!clientPostDataFail && postClientApiData == true) {
      dispatch(postOrgLicenseData(postOrgLicenseDataValues, orgId));
    }
  }, [postClientApiData]);

  function SubmitButton() {
    if (
      licenseunits &&
      orderID &&
      !orgLicensesUnitsValidation &&
      bundelmanufactureYear &&
      orgLicensesOrderIdCharacterLength == false &&
      (bundleLicenseDropdown.length > 0 || orgLicenseBundleName) &&
      selectedContractDuration
    ) {
      return dispatch(handleCreateButtonEnableInModal(true));
    } else {
      return dispatch(handleCreateButtonEnableInModal(false));
    }
  }
  useEffect(() => {
    SubmitButton();
  }, [
    licenseunits,
    orgLicensesOrderIdCharacterLength,
    orderID,
    bundelmanufactureYear,
    orgLicenseBundleName,
    orgLicensesUnitsValidation,
    selectedContractDuration,
  ]);

  return (
    <div>
      <OrgLicenseNameCodeInputFields />
      <OrgLicenseBundleIDInputFields />
      <OrgLicenseDateDurationInputFields />
      <OrgLicenseUnitsTrailInputFields />
    </div>
  );
};

export default OrgLicenseCreateInputFields;
