import { Tooltip } from '@chakra-ui/react';
import React from 'react';

const CustomToolTip = ({ label, icon, tooltipPlacement }: any) => {
  return (
    <>
      <Tooltip
        label={label}
        fontSize="12px"
        padding="12px"
        fontWeight={400}
        minH="50px"
        borderRadius="10px"
        boxShadow="0px 4px 17px rgba(0, 0, 0, 0.08)"
        bg="white"
        placement={tooltipPlacement}
        color={'rgba(51, 51, 51, 0.6)'}
        data-testid="tooltip-orgName"
      >
        <span>{icon}</span>
      </Tooltip>
    </>
  );
};

export default CustomToolTip;
