import { ColorMode } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';

type PreloaderProps = {
  width?: string;
  height?: string;
  borderRadius?: string;
  colorMode: ColorMode;
};

const DARK_MODE_BACKGROUND = {
  placeholder: '#34373E',
  background: 'linear-gradient(270deg, rgba(231, 233, 237, 0.1) 0%, rgba(231, 233, 237, 0) 100%)',
};
const LIGHT_MODE_BACKGROUND = {
  placeholder: 'rgba(206, 209, 216, 0.5)',
  background: 'linear-gradient(270deg, #ced1d8 0%, rgba(206, 209, 216, 0) 100%)',
};

export const Preloader = ({ width, height, borderRadius, colorMode }: PreloaderProps) => {
  const loaderStyles = colorMode === 'dark' ? DARK_MODE_BACKGROUND : LIGHT_MODE_BACKGROUND;
  return <Loader width={width} height={height} borderRadius={borderRadius} loaderStyles={loaderStyles} />;
};

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  50% {
    background-position: calc(100% + 200px) 0;
  }
  100% {
    background-position: calc(100% + 200px) 0;
  }
`;

type LoaderProps = {
  width?: string;
  height?: string;
  borderRadius?: string;
  loaderStyles: {
    placeholder: string;
    background: string;
  };
};

const Loader = styled.div<LoaderProps>`
  width: ${(props) => props.width || '409px'};
  height: ${(props) => props.height || '266px'};
  border-radius: ${(props) => props.borderRadius || '0px'};
  background: ${(props) => props.loaderStyles.background};
  background-color: ${(props) => props.loaderStyles.placeholder};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: ${loadingAnimation} 1.5s cubic-bezier(0.39, 0, 1, 0.64) infinite;
`;
