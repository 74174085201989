import React from 'react';
import { Box } from '@chakra-ui/react';
import NoDataFound from 'components/NoDataFound';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import Loader from 'components/loader/Loader';

export default function OrgDevicesActivityLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const isLoading = useAppSelector(
    (state: RootState) => state.organizationDevicesActivitySlice.organizationDevicesActivityListLoading,
  );
  return (
    <Box
      data-testid="landing-screen"
      h={`calc(100vh - 300px)`}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Loader isLoading={isLoading} />
      {!isLoading && <NoDataFound title="Organization Devices" isFiltersApplied={isFiltersApplied} />}
    </Box>
  );
}
