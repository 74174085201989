/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationTypes } from 'types/pagination-types';

import { UserRolePermission, Version, UserPermissions } from 'types/user-types';

type IntialState = {
  firstName: string;
  lastName: string;
  userPermissions: UserPermissions;
  searchText: string;
  isSearchExpand: boolean;
  usersList: any;

  tiersData: any | null;
  tiersLoading: boolean;
  tiersError: boolean;
  usersGridView: boolean;
  userStatusesData: any;
  userStatusesLoading: boolean;
  rolesLoading: boolean;
  userStatusesError: boolean;
  rolesError: boolean;
  roles: UserRolePermission[];
  statusFilterValue: string;
  usersApiData: any;
  usersListLoading: boolean;
  usersListError: boolean;
  paginationData: null | PaginationTypes;
  numberOfPages: number;
  selectedPage: number;
  filterData: {
    roles_id: any;
    status_id: any;
  };

  handleUserModalOpen: boolean;
  userFirstName: string;
  userLastName: string;
  userPhoneNumber: number | string;
  userEmail: string;
  userFirstNameFieldValidation: boolean;
  userLastNameFieldValidation: boolean;
  postApiFailureMessage: string;
  userRoleValue: string;
  userRoleIDPostData: string | number;
  userFirstNameLength: boolean;
  userLastNameLength: boolean;
  userCreateButtonEnable: boolean;
  userCreateButtonEnableValue: boolean;
  postUserData: boolean;
  postUserApiFail: string;
  userEmailFieldValidation: boolean;
  userEmailValidFormat: boolean;
  postUserFormDataLoading: boolean;
  postUserFormDataFail: boolean;
  postUserFormData: string[];
  createUserApiFail: string;
  userSuccessCreation: boolean;
  postUserToggle: boolean;
  userRegionData: [];
  userAllRegionValue: boolean;
  getUserEmailLoading: boolean;
  getUserEmailData: string[];
  getUserEmailFail: boolean;
  regionsLoading: boolean;
  regionsData: any;
  regionsError: boolean;
  userAvatar: string | '';
  singlePulseUserDataLoading: boolean;
  //Ediatble fields

  userEditModal: boolean;
  singlePulseUserData: any | null;
  singlePulseUserFail: boolean;
  createEditButtonEnable: boolean;
  editUserPostData: boolean;
  editUserPostDataFail: string;
  editPulseUserID: string | number;
  editUserSuccessCreation: boolean;
  editPulseUserEnableButton: boolean;
  editPulseUserPostData: boolean;
  editUserPostDataLoading: boolean;
  editUserStatusData: string | number;
  deactivateEditPulseUser: boolean;
  activateEditPulseUser: boolean;
  deleteEditPulseUser: boolean;
  roleFieldValidation: boolean;
  versionLoading: boolean;
  versionError: boolean;
  version: null | Version;
};

export const initialState: IntialState = {
  firstName: 'Augie',
  lastName: 'Mclovin',
  userPermissions: {} as UserPermissions,
  searchText: '',
  isSearchExpand: false,
  postApiFailureMessage: '',
  usersList: [],
  usersGridView: true,
  userStatusesData: [],
  userStatusesLoading: false,
  rolesLoading: false,
  userStatusesError: false,
  rolesError: false,
  roles: [],
  statusFilterValue: '',
  usersApiData: null,
  usersListLoading: false,
  usersListError: false,
  paginationData: null,
  numberOfPages: 1,
  selectedPage: 1,
  filterData: {
    roles_id: [],
    status_id: [],
  },
  tiersData: null,
  tiersLoading: false,
  tiersError: false,
  handleUserModalOpen: false,
  userFirstName: '',
  userLastName: '',
  userPhoneNumber: '',
  userEmail: '',
  userFirstNameFieldValidation: false,
  userLastNameFieldValidation: false,
  userEmailValidFormat: false,
  userRoleIDPostData: '',
  userRoleValue: '',
  userFirstNameLength: false,
  userLastNameLength: false,
  userCreateButtonEnable: false,
  userCreateButtonEnableValue: false,
  postUserData: false,
  postUserApiFail: '',
  userEmailFieldValidation: false,
  postUserFormDataLoading: false,
  postUserFormDataFail: false,
  postUserFormData: [],
  createUserApiFail: '',
  userSuccessCreation: false,
  postUserToggle: false,
  userRegionData: [],
  userAllRegionValue: false,
  getUserEmailLoading: false,
  getUserEmailData: [],
  getUserEmailFail: false,
  regionsLoading: false,
  regionsData: [],
  regionsError: false,
  userAvatar: '',
  //Editable fields for users
  userEditModal: false,
  singlePulseUserData: [],
  singlePulseUserFail: false,
  createEditButtonEnable: false,
  editUserPostData: false,
  editUserPostDataFail: '',
  editPulseUserID: '',
  editUserSuccessCreation: false,
  editPulseUserEnableButton: false,
  editPulseUserPostData: false,
  editUserPostDataLoading: false,
  editUserStatusData: '',
  deactivateEditPulseUser: false,
  activateEditPulseUser: false,
  deleteEditPulseUser: false,
  singlePulseUserDataLoading: false,
  roleFieldValidation: false,
  versionLoading: false,
  versionError: false,
  version: null,
};

export interface IProfileInfo {
  firstName: string | '';
  lastName: string | '';
  avatar: string | '';
  email: string | '';
}

const userSlice = createSlice({
  name: 'SystemSettings',
  initialState,
  reducers: {
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    handleStatusFilterChange: (state, { payload }) => {
      state.statusFilterValue = payload;
    },
    fetchUserStatusesLoading: (state) => {
      state.userStatusesLoading = true;
      state.userStatusesError = false;
    },
    fetchUserStatusesSuccess: (state, { payload }) => {
      state.userStatusesData = payload;
      state.userStatusesLoading = false;
      state.userStatusesError = false;
    },
    fetchUserStatusesFail: (state) => {
      state.userStatusesError = true;
      state.userStatusesLoading = false;
    },
    fetchRolesLoading: (state) => {
      state.rolesLoading = true;
      state.rolesError = false;
    },
    fetchRolesSuccess: (state, { payload }) => {
      state.rolesLoading = false;
      state.rolesError = false;
      state.roles = payload;
    },
    fetchRolesFail: (state) => {
      state.rolesError = true;
      state.rolesLoading = false;
    },

    fetchVersionLoading: (state) => {
      state.versionLoading = true;
      state.versionError = false;
    },
    fetchVersionSuccess: (state, { payload }) => {
      state.versionLoading = false;
      state.versionError = false;
      state.version = payload;
    },
    fetchVersionFail: (state) => {
      state.versionError = true;
      state.versionLoading = false;
    },
    fetchUsersLoading: (state) => {
      state.usersListLoading = true;
    },
    fetchUsersFail: (state) => {
      state.usersListLoading = false;
      state.usersListError = true;
    },
    fetchUsersuccess: (state, { payload }) => {
      state.usersList = payload.data;
      state.usersApiData = payload;
      state.paginationData = payload.pagination;
      state.numberOfPages = payload.pagination.num_pages;
      state.usersListLoading = false;
    },
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    handleCreateUserModal: (state, { payload }) => {
      state.handleUserModalOpen = payload;
    },

    fetchUserFirstName: (state, { payload }) => {
      state.userFirstName = payload;
    },
    fetchUserLastName: (state, { payload }) => {
      state.userLastName = payload;
    },
    getUserFirstNameLength: (state, { payload }) => {
      state.userFirstNameLength = payload;
    },
    getUserLastNameLength: (state, { payload }) => {
      state.userLastNameLength = payload;
    },

    fetchUserFirstNameFieldValidation: (state, { payload }) => {
      state.userFirstNameFieldValidation = payload;
    },
    fetchUserLastNameFieldValidation: (state, { payload }) => {
      state.userLastNameFieldValidation = payload;
    },

    fetchUserEmailFieldValidation: (state, { payload }) => {
      state.userEmailFieldValidation = payload;
    },

    fetchUserPhoneNumber: (state, { payload }) => {
      state.userPhoneNumber = payload;
    },
    fetchUserEmail: (state, { payload }) => {
      state.userEmail = payload;
    },

    fetchUserRoleValue: (state, { payload }) => {
      state.userRoleValue = payload;
    },
    fetchUserRolePostData: (state, { payload }) => {
      state.userRoleIDPostData = payload;
    },

    getUserCreateButtonEnable: (state, { payload }) => {
      state.userCreateButtonEnable = payload;
    },

    fetchUserCreateButtonEnableValue: (state, { payload }) => {
      state.userCreateButtonEnableValue = payload;
    },

    fetchPostUserFormDataLoading: (state) => {
      state.postUserFormDataLoading = true;
    },
    fetchPostUserFormDataSuccess: (state, { payload }) => {
      state.postUserFormData = payload;
      state.postUserFormDataLoading = false;
    },
    fetchPostUserFormDataFail: (state, { payload }) => {
      state.postUserFormDataFail = payload;
      state.postUserFormDataLoading = false;
    },
    fetchUserPostApiFail: (state, { payload }) => {
      state.postUserApiFail = payload;
    },

    fetchUserPostToggle: (state, { payload }) => {
      state.postUserToggle = payload;
    },

    fetchUserSuccessCreation: (state, { payload }) => {
      state.userSuccessCreation = payload;
    },

    fetchPostUserData: (state, { payload }) => {
      state.postUserData = payload;
    },

    fetchregionDataValue: (state, { payload }) => {
      state.userRegionData = payload;
    },
    handleUserAllRegionsValue: (state, { payload }) => {
      state.userAllRegionValue = payload;
    },

    handleUserEmailValidFormat: (state, { payload }) => {
      state.userEmailValidFormat = payload;
    },

    fetchUserEmailLoading: (state) => {
      state.getUserEmailLoading = true;
      state.getUserEmailFail = false;
    },
    fetchUserEmailDataSuccess: (state, { payload }) => {
      state.getUserEmailData = payload;
      state.getUserEmailFail = false;
      state.getUserEmailLoading = false;
    },
    fetchUserEmailFail: (state, { payload }) => {
      state.getUserEmailFail = payload;
      state.getUserEmailLoading = false;
    },
    fetchEditUserStatusData: (state, { payload }) => {
      state.editUserStatusData = payload;
    },

    resetUserValidationValues: (state) => {
      (state.createUserApiFail = ''),
        (state.postUserFormData = []),
        (state.userRoleIDPostData = ''),
        (state.userFirstNameLength = false),
        (state.userLastNameLength = false),
        (state.userCreateButtonEnableValue = false),
        (state.postUserData = false),
        (state.userSuccessCreation = false),
        (state.userRoleValue = ''),
        (state.userCreateButtonEnable = false);
      (state.userRegionData = []),
        (state.userAllRegionValue = false),
        (state.userFirstNameFieldValidation = false),
        (state.userLastNameFieldValidation = false),
        (state.userEmailFieldValidation = false),
        (state.userEmailValidFormat = false),
        (state.postUserApiFail = ''),
        (state.postUserToggle = false),
        (state.userFirstName = ''),
        (state.userLastName = ''),
        (state.userPhoneNumber = ''),
        (state.userEmail = ''),
        (state.postUserFormDataFail = false),
        (state.getUserEmailFail = false);
      state.singlePulseUserData = [];
      state.roleFieldValidation = false;
      state.editPulseUserEnableButton = false;
      state.editUserSuccessCreation = false;
      state.editUserPostDataFail = '';
      state.editPulseUserID = '';
      state.editUserSuccessCreation = false;
      state.createEditButtonEnable = false;
      state.editPulseUserPostData = false;
      state.editUserPostDataLoading = false;
      state.editUserStatusData = '';
      state.deleteEditPulseUser = false;
      state.activateEditPulseUser = false;
      state.deactivateEditPulseUser = false;
      state.postApiFailureMessage = '';
    },
    fetchRegionLoading: (state) => {
      state.regionsLoading = true;
      state.regionsError = false;
    },
    fetchRegionSuccess: (state, { payload }) => {
      state.regionsData = payload;
      state.regionsLoading = false;
      state.regionsError = false;
    },
    fetchRegionFail: (state) => {
      state.regionsError = true;
      state.regionsLoading = false;
    },
    setUserProfileInfo: (state, action: PayloadAction<IProfileInfo>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.userEmail = action.payload.email;
      state.userAvatar = action.payload.avatar;
    },
    setUserPermissions: (state, { payload }) => {
      state.userPermissions = payload;
    },
    //Editable Fields Slice

    handleUserEditModal: (state, { payload }) => {
      state.userEditModal = payload;
    },
    singlePulseUserValueLoading: (state) => {
      state.singlePulseUserDataLoading = true;
    },
    singlePulseUserValue: (state, { payload }) => {
      state.singlePulseUserData = payload;
      state.singlePulseUserDataLoading = false;
    },
    singlePulseUserDataFail: (state, { payload }) => {
      state.singlePulseUserFail = payload;
      state.singlePulseUserDataLoading = false;
    },
    fetchPulseUserCreateButtonEnable: (state, { payload }) => {
      state.createEditButtonEnable = payload;
    },
    editUserPostDataApiSuccess: (state, { payload }) => {
      state.editUserPostData = payload;
    },
    editUserPostDataApiFail: (state, { payload }) => {
      state.editUserPostDataFail = payload;
    },
    editUserPostDataApiLoading: (state, { payload }) => {
      state.editUserPostDataLoading = payload;
    },

    editPulseUserID: (state, { payload }) => {
      state.editPulseUserID = payload;
    },
    fetchUserPulseEditSuccessCreation: (state, { payload }) => {
      state.editUserSuccessCreation = payload;
    },
    enablEditPulseUserSaveButton: (state, { payload }) => {
      state.editPulseUserEnableButton = payload;
    },
    editPulsePostData: (state, { payload }) => {
      state.editPulseUserPostData = payload;
    },
    handleDeactivateEditPulseUser: (state, { payload }) => {
      state.deactivateEditPulseUser = payload;
    },
    handleActivateEditPulseUser: (state, { payload }) => {
      state.activateEditPulseUser = payload;
    },
    handleDeleteEditPulseUser: (state, { payload }) => {
      state.deleteEditPulseUser = payload;
    },
    handleRoleFieldValidation: (state, { payload }) => {
      state.roleFieldValidation = payload;
    },
  },
});

export const {
  getSearchText,
  handleDeleteEditPulseUser,
  handleRoleFieldValidation,
  handleActivateEditPulseUser,
  handleDeactivateEditPulseUser,
  editPulsePostData,
  fetchUserPulseEditSuccessCreation,
  enablEditPulseUserSaveButton,
  editPulseUserID,
  fetchEditUserStatusData,
  editUserPostDataApiSuccess,
  editUserPostDataApiFail,
  clearSearcText,
  closeSearchInMobile,
  expandSearchInMobile,
  fetchRolesLoading,
  fetchRolesSuccess,
  fetchRolesFail,
  handleStatusFilterChange,
  fetchUsersLoading,
  fetchUsersFail,
  fetchUsersuccess,
  fetchUserStatusesSuccess,
  fetchUserStatusesLoading,
  fetchUserStatusesFail,
  getFlterData,
  getPageNumber,
  handleCreateUserModal,
  fetchUserFirstName,
  fetchUserLastName,
  fetchUserPhoneNumber,
  fetchUserEmail,
  fetchUserRoleValue,
  fetchUserRolePostData,
  getUserFirstNameLength,
  getUserLastNameLength,
  getUserCreateButtonEnable,
  fetchPostUserFormDataLoading,
  fetchPostUserFormDataSuccess,
  fetchPostUserFormDataFail,
  fetchUserPostToggle,
  resetUserValidationValues,
  fetchUserSuccessCreation,
  fetchUserCreateButtonEnableValue,
  fetchPostUserData,
  fetchPulseUserCreateButtonEnable,
  fetchUserPostApiFail,
  fetchregionDataValue,
  handleUserAllRegionsValue,
  fetchUserFirstNameFieldValidation,
  fetchUserLastNameFieldValidation,
  fetchUserEmailFieldValidation,
  handleUserEmailValidFormat,
  fetchUserEmailLoading,
  singlePulseUserValue,
  singlePulseUserDataFail,
  fetchUserEmailDataSuccess,
  fetchUserEmailFail,
  fetchRegionLoading,
  fetchRegionSuccess,
  fetchRegionFail,
  setUserProfileInfo,
  setUserPermissions,
  editUserPostDataApiLoading,
  handleUserEditModal,
  singlePulseUserValueLoading,
  fetchVersionLoading,
  fetchVersionSuccess,
  fetchVersionFail,
} = userSlice.actions;

export default userSlice.reducer;
