import { Input, Text, Box } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { customColor } from '../../../../../libs/shared-ui/src/lib/assets';
import React, { memo } from 'react';
type InputProps = {
  name: string;
  onKeyPress: any;
  maxLength: any;
  handleOnChange: any;
  handleOnBlur: any;
  defaultValue?: any;
  testid?: string;
  isCapitalize?: boolean;
  isEditable: boolean;
  textSize?: string;
  placeholderSize?: string;
};
const CustomInputField = (props: InputProps) => {
  const { isEditable = false } = props;
  const colors = customColor();
  const intl = useIntl();

  return (
    <Box minH="80px" width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
      {isEditable ? (
        <Input
          color={colors.subTextColor}
          name={props.name}
          borderColor="#E0E0E0"
          fontSize={props?.textSize || '14px'}
          _placeholder={{ fontSize: props?.placeholderSize || '14px' }}
          focusBorderColor={colors.primaryColor}
          onChange={props.handleOnChange}
          borderBottomColor={colors.inputBorderColor}
          borderWidth="0 0 1px 0"
          placeholder={intl.formatMessage({ id: `${props.name}.message` })}
          type="text"
          paddingTop="5px"
          variant="flushed"
          style={{ textTransform: 'capitalize' }}
          defaultValue={props.defaultValue}
          onBlur={props.handleOnBlur}
          maxLength={props.maxLength}
          onKeyPress={props.onKeyPress}
          required
          data-testid={props?.testid ? props?.testid : 'device-form-serial'}
        />
      ) : (
        <Text mt="10px">{props.defaultValue}</Text>
      )}
    </Box>
  );
};
export default CustomInputField;
