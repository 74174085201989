import { ExamType } from './ExamTypes';
import { Specialty } from './User';
import { WorklistItem, Patient } from './WorklistItems';
import { WorksheetTemplate } from './Worksheet';

// TODO Exam API resposne differs slightly if we are getting a list or a single
// This complicates our model definitions. Currently marking inconsistent fields as optional
// We should consider a better solution such as multiple models or finding a way to generate models
// from swagger. Also we should consider our API validation work with io-ts.
export interface Exam {
  id: string;
  uuid: string;
  worksheet?: WorksheetValues;
  performingPhysician?: Physician;
  performingSignedDate?: string;
  performedWith?: Device;
  dicomStudy?: DicomStudy;
  createdTime?: string;
  medicalOrder?: MedicalOrder;
  thumbnailUrl?: string;
  reviewerSignedDate?: string;
  qaSignedDate?: string;
  examCategory: ExamCategory;
  billingCodes?: BillingCode[];
  assignedQaReviewer?: Physician;
  qaReviewer?: Physician;
  qaReviewedBy?: Physician;
  qaWorksheet?: WorksheetTemplate;
  reviewedBy?: Physician;
  assignedReviewer?: Reviewer;
  examStatus: EXAM_STATUS;
  patient?: WorklistItem;
  patientScannedId?: string;
  // Below are made optional for some older exams, slice might need to be updated if we want to require this, check exam.slice.ts for 'examThunks.getSharedExamData.fulfilled'
  examTypes?: ExamTypes;
  selectedExamType?: ExamType;
  studyDatetime?: string;
  favorites?: number[];
  examTags?: Tag[];
  statusLabel?: string;
  isQaOnlyMode?: boolean;
  aeTitle?: string;
  totalClips?: number;
  totalStills?: number;
  examProcessing?: boolean;
  readOnly?: boolean;
  isFavorite?: boolean;
  deletedTime?: string;
  readOnlyShareMode?: boolean;
  sharedWithUsers?: number[];
  examDestinationName?: string | null;
}

type ExamTypes = {
  [key: string]: ExamType;
};

export type ExamResponse = { count: number; results: UnparsedExam[]; prev: string; next: string };

export interface UnparsedExam extends Omit<Exam, 'examTypes'> {
  examTypes: ExamType[];
}

export enum ExamCategory {
  CLINICALLY_INDICATED = 'CLINICALLY_INDICATED',
  EDUCATIONAL = 'EDUCATIONAL',
}

export const ExamCategoryDisplay = {
  [ExamCategory.CLINICALLY_INDICATED]: 'CLINICAL',
  [ExamCategory.EDUCATIONAL]: 'EDUCATIONAL',
};

function hasKey<O extends object>(obj: O, key: ExamCategory) {
  return key in obj;
}

export const getExamCategoryDisplay = (value: ExamCategory) => {
  if (hasKey(ExamCategoryDisplay, value)) {
    return ExamCategoryDisplay[value];
  }
  return null;
};

export enum EXAM_STATUS {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
  INTERPRETED = 'INTERPRETED',
}

// Disabling any rule for now as the WS values are dynamic
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface WorksheetValues {
  [key: string]: any;
}

export interface Physician {
  firstName: string;
  lastName: string;
  id: number;
  credentialedForExamTypes?: ExamType[];
  picture?: null | string;
}

export interface Reviewer {
  id: number;
  lastLogin: null | string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isStaff: boolean;
  isActive: true;
  dateJoined: string;
  exoServiceName: null | string;
  picture: null | string;
  acceptedTos: boolean;
  hcsProviderId: null | string;
  isEnabled: boolean;
  userCategory: UserCategory;
  credentialedForExamTypes: ExamType[];
  specialty: Specialty;
  currentOrg: string;
}

export interface UserCategory {
  id: number;
  createdAt: string;
  modifiedAt: string;
  name: string;
  permissionNames: string[];
  identityProviderGroup: null;
  enabled: boolean;
  startDate: string;
  endDate: null | string;
  defaultQaPercentageRequired: number;
  licenseTier: string;
  examSource: any[];
}

export interface ExamPatch
  extends Omit<Exam, 'performingPhysician' | 'assignedReviewer' | 'patient' | 'examType' | 'medicalOrder'> {
  performingPhysician: number | null;
  assignedReviewer: number | null;
  patient: number | null;
  medicalOrder: number | null;
  examType: number;
  isNormal: boolean;
}

export enum IMAGE_TYPES {
  SINGLE_FRAME = 'SINGLE_FRAME',
  MULTI_FRAME = 'MULTI_FRAME',
  VIDEO = 'VIDEO',
}

export enum USER_PERMISSIONS {
  READ_ONLY = 'READ_ONLY',
  EXAM_WORKSHEET = 'EXAM_WORKSHEET',
  QA_WORKSHEET = 'QA_WORKSHEET',
  SUPER_USER = 'SUPER_USER',
}

export enum SIGN_BUTTON_STATUS {
  SIGN = 'SIGN',
  SIGNED = 'SIGNED',
  HIDE = 'HIDE',
}

export enum USER_EXAM_ROLES {
  PERFORMING = 'PERFORMING',
  ATTENDING = 'ATTENDING',
}

export interface DicomStudy {
  examSeries: ExamSeries[];
  exam: number;
  studyInstanceUid: string;
}

export interface ExamSeries {
  id: number;
  examDicoms: ExamDicom[];
  dicomStudy: number;
  seriesInstanceUid: string;
}

export interface ExamAnnotation {
  id: number;
  annotationText: string;
  topLeftX: number;
  topLeftY: number;
  examDicom: number;
}

export interface ExamDicom {
  id: number;
  exam: number;
  instanceNumber: number;
  frameRate: number;
  isMultiframe: boolean;
  studyInstanceUid: string;
  downloadDicomUrl: string;
  downloadImageUrl: string;
  thumbnailUrl: string;
  examAnnotations: ExamAnnotation[];
  dicomSeries: number;
  fileSize: number;
  imageFileSize: number;
  dicomFileSize: number;
  fromIris: boolean;
  // Some below properties may be removed in the future
  isFavorite?: boolean;
  videoDuration?: number;

  contentDatetime?: string;
  calculatedVideoDuration?: number;
  createdBy?: number;
  sopInstanceUid?: string;
  isSecondaryCapture?: boolean;
  primaryCaptureSopInstanceUid?: string;
  anonDownloadDicomUrl?: string;
  anonDownloadImageUrl?: string;
  anonThumbnailUrl?: string;
}

export interface MedicalOrder {
  id: number;
  accessionNumber: number;
  patient: Patient;
  examType?: ExamType;
}

export interface BillingCode {
  id: number;
  code: string;
  description: string;
}

export interface ExamFilterCounts {
  [key: string]: number;
}

export interface Device {
  aeTitle: string;
  examSource: {
    facility: {
      id: string;
    };
    specialty: {
      id: string;
    };
  };
}

export interface Tag {
  id: number;
  exam: number;
  tag: number;
  tagName: string;
}

export enum ExamLayout {
  TABLE = 'TABLE',
  CARDS = 'CARDS',
}
