import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Wrap, WrapItem, Menu, MenuButton, Button, Icon, MenuList, MenuItem, Text, Box } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import {
  getOrgLicenseBundleName,
  getOrgLicenseOrderIDValue,
  handleOrgLicensesOrderIdCharacterLengthValidation,
  handleOrgLicensesOrderIdValidation,
} from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import { resetCreateSettingsPopUpValues } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { SoftwareBundle } from 'types/SoftwareBundle';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';

const OrgLicenseBundleIDInputFields = () => {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const [bundleValue, setBundleValue] = useState<string>('');
  const softwareBundles = useAppSelector((state) => state.softwareBundles.softwareBundles);
  const orderID = useAppSelector((state) => state.orgLicense.orgLicenseOrderIDValue);
  const bundleLicenseDropdown = useAppSelector((state) => state.orgLicense.organizationLincesesList);
  const orgLicensesOrderIdValidation = useAppSelector((state) => state.orgLicense.orgLicensesOrderIdValidation);
  const orgLicensesOrderIdCharacterLength = useAppSelector(
    (state) => state.orgLicense.orgLicensesOrderIdCharacterLength,
  );
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const [fieldError, setFieldError] = useState(false);
  const orgLicenseBundleName = useAppSelector((state) => state.orgLicense.orgLicenseBundleName);

  const handleBundle = (state: { name: string; id: number }) => {
    setBundleValue(state.name);
    dispatch(getOrgLicenseBundleName(state));
    if (postApiFailureMessage) {
      dispatch(handlePostApiFailureMessage(false));
      dispatch(resetCreateSettingsPopUpValues());
    }
  };
  const handleBundleOnBlur = () => {
    if (!orgLicenseBundleName) {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
  };

  const handleOrderID = (e: any) => {
    const value = e.target.value;
    dispatch(getOrgLicenseOrderIDValue(value));
    if (value.length > 0 && value.length < 4) {
      dispatch(handleOrgLicensesOrderIdCharacterLengthValidation(true));
      dispatch(handleOrgLicensesOrderIdValidation(false));
    } else {
      dispatch(handleOrgLicensesOrderIdCharacterLengthValidation(false));
    }
    if (postApiFailureMessage) {
      dispatch(handlePostApiFailureMessage(false));
      dispatch(resetCreateSettingsPopUpValues());
    }
  };

  const handleOrderIDOnBlur = () => {
    if (orderID.length == 0) {
      dispatch(handleOrgLicensesOrderIdValidation(true));
    } else {
      dispatch(handleOrgLicensesOrderIdValidation(false));
    }
  };
  return (
    <Box>
      <Text fontSize="18px" fontWeight="700" ml="5px" mb="20px">
        <FormattedMessage id="information.message" />
      </Text>
      <Wrap spacing="30px" mt="5px" ml="5px">
        {softwareBundles.find(
          (softwareBundle: SoftwareBundle) => softwareBundle.name == bundleLicenseDropdown[0]?.bundle?.name,
        ) ? (
          <WrapItem w="305px">
            <Box minH="70px">
              <InputLabel value={'-'} name="add_org_bundle" />
              <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                {bundleLicenseDropdown[0]?.bundle?.name}
              </Text>
            </Box>
          </WrapItem>
        ) : (
          <WrapItem>
            <Box>
              <InputLabel value={bundleValue} name="add_org_bundle" />
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      isActive={isOpen}
                      as={Button}
                      fontSize="14px"
                      width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                      borderBottom="1px solid"
                      borderRadius="0px"
                      _focusVisible={{ borderBottomColor: colors.primaryColor }}
                      borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                      variant="flushed"
                      data-testid="license-info"
                      rightIcon={
                        isOpen ? (
                          <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                        ) : (
                          <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                        )
                      }
                    >
                      {bundleValue ? (
                        <Text
                          float="left"
                          marginLeft="-15px"
                          fontSize="14px"
                          fontWeight="400"
                          color={colors.subTextColor}
                          paddingTop="10px"
                        >
                          {bundleValue}
                        </Text>
                      ) : (
                        <Text float="left" marginLeft="-15px" fontSize="16px" fontWeight="400" color="#8c8c8c">
                          <FormattedMessage id="add_org_bundle.message" />
                        </Text>
                      )}
                    </MenuButton>
                    <Box fontSize="12px" color="#FB5654" mt="5px" ml="2px">
                      {fieldError && <FormattedMessage id="field_validation.message" />}
                    </Box>
                    <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                      {softwareBundles.map((softwareBundle: SoftwareBundle) => (
                        <MenuItem
                          key={softwareBundle.id}
                          data-testid="menu-item"
                          value={softwareBundle.id}
                          name={softwareBundle.name}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={() => handleBundle(softwareBundle)}
                          onBlur={handleBundleOnBlur}
                        >
                          {softwareBundle.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            </Box>
          </WrapItem>
        )}
        <WrapItem>
          <Box display="flex" flexDirection="column" minH="90px">
            <InputLabel value={orderID} name={'order_id_org'} />
            <Box>
              <CustomInputField
                defaultValue={''}
                name={'order_id_org'}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={handleOrderID}
                handleOnBlur={handleOrderIDOnBlur}
                isEditable={true}
              />
              <Text fontSize="12px" color="#FB5654" mt="-37px">
                {orgLicensesOrderIdValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                {orgLicensesOrderIdCharacterLength ? <FormattedMessage id="org_name_error.message" /> : ''}
              </Text>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default OrgLicenseBundleIDInputFields;
