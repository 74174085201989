import { Box } from '@chakra-ui/react';

import React from 'react';
import OrgUserActivityData from './OrgUserActivityData';

export default function OrgUserActivityMainContent({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  return (
    <Box data-testid="org-users">
      <OrgUserActivityData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
