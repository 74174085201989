import { Box } from '@chakra-ui/react';

import React from 'react';
import SingleUserActivityData from './SingleUserActivityData';

interface FilterProps {
  isFiltersApplied: boolean;
}
export default function SingleUserActivityMainContent({ isFiltersApplied }: FilterProps) {
  return (
    <Box>
      <SingleUserActivityData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
