import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React, { useEffect } from 'react';
import { customColor } from 'assets/styles/colors';
import { useAppDispatch } from '../../../../../../libs/admin-ui/src/lib/redux/hooks';
import {
  handleBundleEditScreenIDValue,
  handleCreateSoftwareBundleModal,
  resetSoftwareBundlePopUpValues,
} from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { getTiers } from 'redux/organizations/Actions/organizationActions';
import {
  getBundleData,
  getBundleGracePeriod,
  getBundleStatus,
  getSingleBundleEditData,
} from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { getPageNumber } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import { userAccessConst } from 'utils/constants/userAccessConst';
import LandingScreen from 'components/landingPage/LandingSreen';
import LicenseBundleListView from './LicenseBundleListView';

function LicenseBundleConatiner() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const softwareBundles = useAppSelector((state: RootState) => state.softwareBundles.softwareBundles);
  const isLoading = useAppSelector((state: RootState) => state.softwareBundles.handleBundleApiDataLoading);
  const numberOfPages = useAppSelector((state: RootState) => state.softwareBundles.SoftwareBundlenumberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.softwareBundles.SoftwareBundlepaginationData);
  const selectedPage = useAppSelector((state: RootState) => state.softwareBundles.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const hasViewBundleAccess = userPermissions.bundles?.includes(userAccessConst.bundle.viewBundle);
  const searchText = useAppSelector((state) => state.softwareBundles.getBundleSearch);
  const showEmptyMessage = !isLoading && !searchText.length && !paginationData?.count;
  const handleMobilePageClick = (item: any) => {
    dispatch(getPageNumber(item));
  };

  const handleDesktopPageClick = (data: any) => {
    dispatch(getPageNumber(data.selected + 1));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const handleEditScreen = (id: number) => {
    if (hasViewBundleAccess) {
      navigate(`/bundles/${id}/info`);
      dispatch(getSingleBundleEditData(id));

      dispatch(getBundleStatus());
      dispatch(getTiers());
      dispatch(getBundleGracePeriod());
      dispatch(handleBundleEditScreenIDValue(id));
    }
  };

  useEffect(() => {
    dispatch(getBundleData(selectedPage));
    dispatch(getTiers());
  }, [selectedPage]);

  useEffect(() => {
    dispatch(resetSoftwareBundlePopUpValues());
    return () => {
      dispatch(getPageNumber(1));
    };
  }, []);
  const handleSoftwareBundleModalOpen = () => {
    dispatch(getBundleStatus());
    dispatch(getBundleGracePeriod());
    dispatch(getTiers());
    dispatch(handleCreateSoftwareBundleModal(true));
  };

  return (
    <Box overflowY="scroll">
      <Box>
        {' '}
        {!isLoading && showEmptyMessage && (
          <LandingScreen
            handleClick={handleSoftwareBundleModalOpen}
            title="Software Bundles"
            isFiltersApplied={false}
            onlyTitle={!userPermissions.bundles?.includes(userAccessConst.bundle.addBundle)}
            pageName={'no_bundles_created'}
            newCreation={true}
          />
        )}
      </Box>

      {isLoading ? <Loader isLoading={isLoading} /> : <LicenseBundleListView />}

      {softwareBundles && softwareBundles.length > 0 && (
        <Box
          w="100%"
          display="flex"
          p="10px"
          pl="0px"
          ml="0px"
          pr="65px"
          h="100px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          bottom="70px"
          backgroundColor="white"
        >
          <TableInformation selectedPage={selectedPage} data={softwareBundles} pagination={paginationData} />

          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={softwareBundles && softwareBundles}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={paginationData}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={softwareBundles && softwareBundles}
              selectedPage={selectedPage}
              numberOfPages={numberOfPages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Box>
      )}

      {/* </Wrap> */}
    </Box>
  );
}

export default LicenseBundleConatiner;
