/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';
import axios from 'axios';
import { Dispatch } from 'react';
import {
  fetchOrganizationDeviceActivityLoading,
  fetchOrganizationDeviceActivitySuccess,
  fetchOrganizationDeviceActivityFail,
} from 'redux/orgDevicesActivity/Reducers/orgDevicesActivitySlice';
import { setError } from '../../error/errorSlice';
export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getOrganizationDevicesActivity =
  (id: any, queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchOrganizationDeviceActivityLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(`organizations/${id}/deviceactivity/?${queryString}`);
      if (res && res.status === 200) {
        dispatch(fetchOrganizationDeviceActivitySuccess(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchOrganizationDeviceActivityFail());
    }
  };
