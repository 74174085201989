import {
  HStack,
  Flex,
  Circle,
  Text,
  MenuButton,
  Menu,
  MenuItem,
  MenuList,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { customColor } from '../assets/styles/colors';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { IoLogOutOutline } from 'react-icons/io5';
import { useAuth0 } from '@auth0/auth0-react';
import { logoutHandler } from '@exo/shared-ui';
import { getVersion } from 'redux/user/Actions/userActions';
import { RootState } from 'redux/Store/store';

const UserInfoSection = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const versionInfo = useAppSelector((state: RootState) => state.user.version);
  const userInfo = useAppSelector((state) => state.user);
  const { logout } = useAuth0();
  const handleClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    dispatch(logoutHandler());
  };
  const firstName = localStorage.getItem('exo_user_first_name') ? localStorage.getItem('exo_user_first_name') : '';
  const lastName = localStorage.getItem('exo_user_last_name') ? localStorage.getItem('exo_user_last_name') : '';
  const exo_user_avatar = localStorage.getItem('exo_user_avatar')?.toString();
  const user_id = localStorage.getItem('exo_user_email');
  useEffect(() => {
    dispatch(getVersion());
  }, []);

  return (
    <React.Fragment>
      <HStack spacing={{ xs: '0', lg: '6' }}>
        <Flex alignItems={'center'}>
          <Text mr="3" fontSize="14px" display={{ xs: 'none', lg: 'block' }} data-testid="user-info">
            {firstName !== '' ? firstName : user_id} {lastName !== '' ? lastName : userInfo.lastName}
          </Text>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Circle size="40px" bg={colors.primaryColor} color={colors.userNameColor} data-testid="avatar">
                  {!exo_user_avatar && userInfo?.userAvatar === '' ? (
                    <Text>
                      {userInfo.firstName && userInfo.firstName[0] !== '' ? userInfo.firstName[0] : user_id?.[0]}
                      {userInfo.lastName && userInfo.lastName[0]}
                    </Text>
                  ) : (
                    <Image
                      src={exo_user_avatar !== '' ? exo_user_avatar : userInfo?.userAvatar}
                      borderRadius="full"
                      alt={`${firstName !== '' ? firstName : userInfo?.firstName[0]}${
                        lastName !== '' ? lastName : userInfo?.lastName[0]
                      }`}
                    />
                  )}
                </Circle>
              </HStack>
            </MenuButton>
            <MenuList
              color={colors.paginationDisplay}
              minWidth="233px"
              boxShadow="2px 10px 15px 0px rgba(0, 0, 0, 0.08)"
              borderWidth="0px"
              padding="10px"
              data-testid="logout"
              zIndex="100000"
            >
              <MenuItem
                fontSize="14px"
                onClick={handleClick}
                fontWeight="300"
                py="25px"
                background="transparent"
                _hover={{ background: 'transparent' }}
                borderBottom="1px solid rgba(51, 51, 51, 0.2)"
              >
                <Text fontSize="20px" mr="12px">
                  <IoLogOutOutline />
                </Text>{' '}
                <FormattedMessage id="logout.message" />
              </MenuItem>
              <MenuItem
                fontSize="12px"
                fontWeight="400"
                cursor="auto"
                m="0"
                p="17px 0 0px 12px"
                background="transparent"
                _hover={{ background: 'transparent' }}
              >
                <FormattedMessage id="exo_pulse.message" />
              </MenuItem>

              <MenuItem
                fontSize="12px"
                cursor="auto"
                fontWeight="400"
                m="0"
                p="0 0 0 12px"
                background="transparent"
                _hover={{ background: 'transparent' }}
              >
                <FormattedMessage id="relase_version.message" values={{ version: versionInfo?.releaseVersion }} />
              </MenuItem>
              <MenuItem
                m="0"
                p="0px 0 8px 12px"
                cursor="auto"
                fontSize="12px"
                fontWeight="400"
                background="transparent"
                _hover={{ background: 'transparent' }}
              >
                <FormattedMessage id="build_version.message" values={{ version: versionInfo?.buildVersion }} />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </React.Fragment>
  );
};

export default UserInfoSection;
