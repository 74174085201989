/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useState } from 'react';
import { Box, Wrap, WrapItem, Text } from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import CustomInputField from 'components/customInputField/CustomInputField';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';
import CustomDatePicker from 'components/customDatePicker/CustomDatePicker';
import {
  handleCreateSoftwareBundleCatalogueInputValue,
  handleSoftwareBundleCatalogueLengthValidation,
} from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { postUniqueCatalogueBundleValue } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { FormattedMessage } from 'react-intl';

const SoftwareBundlesDateCatalogueInputs = () => {
  const dispatch = useAppDispatch();
  const bundleCatalogueValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleCatalogueInputValue);
  const postuniqueCatalogueValidation = useAppSelector((state) => state.softwareBundles.postuniqueCatalogueValidation);
  const [timer, setTimer] = useState<any>(null);
  const softwareBundleCatalogueLengthValidation = useAppSelector(
    (state) => state.softwareBundles.softwareBundleCatalogueLengthValidation,
  );
  // const handleCatalogueValue = (e: any) => {
  //   dispatch(handleCreateSoftwareBundleCatalogueInputValue(e.target.value));
  // };
  const handleCatalogueValue = (e: any) => {
    const catalogueValue = e.target.value;
    dispatch(handleCreateSoftwareBundleCatalogueInputValue(e.target.value));
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      dispatch(
        postUniqueCatalogueBundleValue({
          field_name: 'catalogue_number',
          field_value: e.target.value,
        }),
      );
    }, 500);
    setTimer(newTimer);
    if (catalogueValue.length == 0) {
      dispatch(handleSoftwareBundleCatalogueLengthValidation(true));
    } else {
      dispatch(handleSoftwareBundleCatalogueLengthValidation(false));
    }
  };
  const handleCatalogueOnBlur = () => {
    if (bundleCatalogueValue.length == 0) {
      dispatch(handleSoftwareBundleCatalogueLengthValidation(true));
    } else {
      dispatch(handleSoftwareBundleCatalogueLengthValidation(false));
    }
  };

  return (
    <Wrap align="center" spacingX="30px" data-testid="container-element">
      <CustomDatePicker inputName={'availability_date'} />
      <Box display="flex" flexDirection="column" minH="90px">
        <InputLabel value={bundleCatalogueValue} name={'catalogue'} />
        <WrapItem>
          <Box>
            <CustomInputField
              defaultValue={''}
              name={'catalogue'}
              onKeyPress={specialCharacterValidation}
              maxLength={100}
              handleOnChange={handleCatalogueValue}
              handleOnBlur={handleCatalogueOnBlur}
              isEditable={true}
            />
            <Text fontSize="12px" color="#FB5654" mt="-40px">
              {postuniqueCatalogueValidation ? <FormattedMessage id="bundle_catalogue_validation.message" /> : ''}
              {softwareBundleCatalogueLengthValidation && !postuniqueCatalogueValidation ? (
                <FormattedMessage id="field_validation.message" />
              ) : (
                ''
              )}
            </Text>
          </Box>
        </WrapItem>
      </Box>
    </Wrap>
  );
};
export default SoftwareBundlesDateCatalogueInputs;
