import { Box, Wrap, WrapItem, Text } from '@chakra-ui/react';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  getCatalogueUniqueValidation,
  getModelIdentifierUniqueValidation,
} from 'redux/deviceTypes/Actions/DeviceTypeActions';
import {
  fetchDeviceTypeCatalogueUniqueValidationFail,
  fetchDeviceTypeCatalogueValidation,
  fetchDeviceTypeModelIdentifierUniqueValidationFail,
  fetchDeviceTypeModelIdentifierValidation,
  getCatalogueInputData,
  getModelIdentifierInputData,
} from 'redux/deviceTypes/Reducers/DeviceTypeSlice';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';

const ModelIdentifierCatalogueInputs = () => {
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<any>(null);
  const getModelIdentifierInputValue = useAppSelector((state) => state.deviceTypes.getModelIdentifierInputValue);
  const getCatalogueValue = useAppSelector((state) => state.deviceTypes.getCatalogueInputValue);
  const modelIdentifierUniqueValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeModelIdentifierUniqueValueFail,
  );
  const modelIdentifierValidation = useAppSelector((state) => state.deviceTypes.deviceTypeModelIdentifierValidation);
  const catalogueValidation = useAppSelector((state) => state.deviceTypes.deviceTypeCatalogueUniqueValueFail);
  const catalogueLengthValidation = useAppSelector((state) => state.deviceTypes.deviceTypeCatalogueValidation);

  const [modelIdentifierSpecialValidation, setModelIdentifierSpecialValidation] = useState(false);
  const validatorArr = [' ', ',', '.', '-', '_'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModelIdentier = (e: any) => {
    if (validatorArr.includes(e.target.value.split('')[0])) {
      setModelIdentifierSpecialValidation(true);
      dispatch(fetchDeviceTypeModelIdentifierValidation(false));
      dispatch(getModelIdentifierInputData(e.target.value));
    } else {
      setModelIdentifierSpecialValidation(false);
      dispatch(getModelIdentifierInputData(e.target.value));
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        dispatch(
          getModelIdentifierUniqueValidation({
            field_name: 'device_model',
            field_value: e.target.value,
          }),
        );
      }, 500);
      dispatch(fetchDeviceTypeModelIdentifierUniqueValidationFail(false));
      dispatch(fetchDeviceTypeModelIdentifierValidation(false));
      setTimer(newTimer);
    }
  };
  const handleModelIdentifierOnBlur = () => {
    if (getModelIdentifierInputValue.length == 0) {
      dispatch(fetchDeviceTypeModelIdentifierValidation(true));
    } else {
      dispatch(fetchDeviceTypeModelIdentifierValidation(false));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCatalogueValue = (e: any) => {
    dispatch(getCatalogueInputData(e.target.value));
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      dispatch(
        getCatalogueUniqueValidation({
          field_name: 'catalogue_number',
          field_value: e.target.value,
        }),
      );
    }, 500);
    dispatch(fetchDeviceTypeCatalogueUniqueValidationFail(false));
    dispatch(fetchDeviceTypeCatalogueValidation(false));
    setTimer(newTimer);
  };
  const handleCatalogueOnBlur = () => {
    if (getCatalogueValue.length == 0) {
      dispatch(fetchDeviceTypeCatalogueValidation(true));
    } else {
      dispatch(fetchDeviceTypeCatalogueValidation(false));
    }
  };

  return (
    <Box>
      <Wrap spacingX="30px">
        <Box>
          <InputLabel value={getModelIdentifierInputValue} name={'model_identifier'} />
          <WrapItem>
            <Box display="flex" flexDirection="column" minH="70px">
              <CustomInputField
                defaultValue={''}
                name={'model_identifier'}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={handleModelIdentier}
                handleOnBlur={handleModelIdentifierOnBlur}
                isEditable={true}
              />
              <Text fontSize="12px" color="#FB5654" mt="-40px">
                {modelIdentifierUniqueValidation ? <FormattedMessage id="model_identifier_exists.message" /> : ''}
                {modelIdentifierValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                {modelIdentifierSpecialValidation ? <FormattedMessage id="field_special_validation.message" /> : ''}
              </Text>
            </Box>
          </WrapItem>
        </Box>
        <Box>
          <InputLabel value={getCatalogueValue} name={'catalogue'} />
          <WrapItem>
            <Box display="flex" flexDirection="column" minH="70px">
              <CustomInputField
                defaultValue={''}
                name={'catalogue'}
                onKeyPress={specialCharacterValidation}
                maxLength={100}
                handleOnChange={handleCatalogueValue}
                handleOnBlur={handleCatalogueOnBlur}
                isEditable={true}
              />

              <Text fontSize="12px" color="#FB5654" mt="-40px">
                {catalogueValidation ? <FormattedMessage id="catalogue_identifier_exists.message" /> : ''}
                {catalogueLengthValidation ? <FormattedMessage id="field_validation.message" /> : ''}
              </Text>
            </Box>
          </WrapItem>
        </Box>
      </Wrap>
    </Box>
  );
};

export default ModelIdentifierCatalogueInputs;
