import { extendTheme } from '@chakra-ui/react';
import { checkboxTheme } from './components/checkbox';
import { radioTheme } from './components/radio';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const MyNewTheme = extendTheme({
  textStyles: {
    h1: {
      fontSize: '5px  !important',
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-2%',
    },
    h2: {
      fontSize: ['36px', '48px'],
      fontWeight: 'semibold',
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
  },
  config,

  colors: {
    brandBlue: {
      50: '#24B0FF',
      100: '#1D8DCC',
      150: '#0B354C',
      200: '#0E4666',
      250: '#6890FF',
      300: '#7679E1',
      350: '#5685E9',
    },
    dark: {
      100: '#10F8E4',
      200: '#2E333A',
      300: '#BAC5CD',
      400: '#2E333A',
      500: '#333333',
      600: '#BAC5CD',
      700: '#242a31',
      800: '#FFFFFF',
      900: '#BAC5CD',
      1000: 'rgba(196, 196, 196, 0.6)',
      1100: '#000000',
      1200: 'rgba(255, 255, 255, 0.5)',
      1300: '#C4C4C4',
      1400: 'rgba(255, 255, 255, 0.6)',
      1500: '#394249',
      1600: '#F5D5D8',
      1700: '#C8A8AE',
      1800: '#384049',
    },
    light: {
      100: '#24B0FF',
      200: '#F8F9FB',
      300: '#33333',
      400: '#F8F9FB',
      500: '#FFFFFF',
      600: ' #BAC5CD',
      700: '#fff',
      800: '#333333',
      900: '#999899',
      1200: 'rgba(51, 51, 51, 0.3)',
      1300: 'rgba(51, 51, 51, 0.5)',
      1400: 'rgba(51, 51, 51, 0.6)',

      1600: '#F5D5D8',
      1700: '#C8A8AE',
      1800: '#384049',
      1900: '#FF9500',
      1950: '#3840490D',
    },
    common: {
      100: '#24B0FF',
      200: '#FB5654',
      300: '#FF9500',
      400: '#FFFFFF',
    },
  },
  breakpoints: {
    xs: '0',
    sm: '375px',
    smd: '500px',
    md: '720px',
    lg: '1020',
    xl: '1200',
    dt: '1440',
  },
  shadows: { outline: '0 0 0 0px white' },
  components: {
    Radio: radioTheme,
    Checkbox: checkboxTheme,
    Input: {
      defaultProps: {
        variant: 'flushed',
      },
      baseStyle: {
        field: {
          borderColor: '#333333',
          overflow: 'hidden',
        },
      },
    },
    MenuButton: {
      defaultProps: {
        variant: 'flushed',
      },
      baseStyle: {
        field: {
          borderColor: '#333333',
          overflow: 'hidden',
        },
      },
    },
    Spinner: {
      color: '#7679E1',
    },
  },
});

export default MyNewTheme;
