import { Box, Text, Image, Link, AbsoluteCenter } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import eclipse from '../../assets/images/eclipse.svg';
import React from 'react';
import { customColor } from 'assets/styles/colors';

export default function NoDataFound({
  title = 'Organization',
  isFiltersApplied,
}: {
  title: string;
  isFiltersApplied: boolean;
}) {
  const colors = customColor();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" overflow="hidden">
      {' '}
      {isFiltersApplied ? (
        <Box display="flex" mt={{ xs: '40%', lg: '15%' }} justifyContent="center" w="100%">
          {' '}
          <Text
            opacity="0.5"
            color={colors.textColor}
            fontSize={{ xs: '16px', lg: '18px' }}
            data-testid="no-match-found"
          >
            {' '}
            <FormattedMessage id="no_matches_found.message" />{' '}
          </Text>{' '}
        </Box>
      ) : (
        <>
          {' '}
          <AbsoluteCenter
            flexDirection="column"
            justifyContent="center"
            mt="-80px"
            ml="-80px"
            data-testid="no-organization"
            textAlign="center"
          >
            <Text fontSize={{ xs: '22px', md: '26px', xl: '24px' }} data-testid="no-org-text" color={colors.textColor}>
              <FormattedMessage id="no_organizations_found.message" values={{ title: title }} />
            </Text>
          </AbsoluteCenter>
        </>
      )}{' '}
    </Box>
  );
}
