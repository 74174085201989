import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { RootState } from 'redux/Store/store';

import { generateApiKey, getClientAccess } from 'redux/settings/clientAccess/Actions/clientAccessActions';

import { AddIcon, customColor } from '../../../../../../libs/shared-ui/src/lib/assets';
import { getPageNumber, handleSettingsModalPopUp } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import DeveloperSettingsModal from '../createDeveloperSettings/createDeveloperSettingsModal/DeveloperSettingsModal';
import ClientAccesFilter from './filter/ClientAccesFilter';
import ClientMainScreen from './main/ClientMainScreen';
import { userAccessConst } from 'utils/constants/userAccessConst';

const ClientAccess = () => {
  const location = useLocation();
  interface OrgFilterPayload {
    page?: number;
    search?: string;
    order?: number;
  }
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');

  const initialRender = useRef({ primary: true, secondary: true });

  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: RootState) => state.clientAccess);
  const createClientPostApiFail = useAppSelector((state) => state.clientAccess.editClientAccessPostDataFail);
  const addClientPostApiFail = useAppSelector((state) => state.clientAccess.clientPostDataFailStrings);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const hasAddPartnerAccess = userPermissions.settings?.includes(userAccessConst.setting.addPartnerKey);

  const getOrganizationDeviceActivityData = () => {
    const payload: OrgFilterPayload = {
      search: data.searchText,
      page: data.selectedPage,
    };
    const queryString = Object.entries(payload)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
    dispatch(getClientAccess(id, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getOrganizationDeviceActivityData();
  }, [data.selectedPage]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getPageNumber(1));
    getOrganizationDeviceActivityData();
  }, [data.searchText, data.selectedOrderValue]);

  useEffect(() => {
    getOrganizationDeviceActivityData();
  }, [createClientPostApiFail, addClientPostApiFail]);

  const search = data.searchText;
  const isDataLoading = data.clientAccessListLoading;
  const isFiltersApplied = !!search;

  const handlSettingsModal = () => {
    dispatch(generateApiKey());
    dispatch(handleSettingsModalPopUp(true));
  };

  useEffect(() => {
    return () => {
      dispatch(getPageNumber(1));
    };
  }, []);

  return (
    <Box>
      <Box display="block" overflowX="scroll" overflowY="hidden" h={`calc(100vh - 180px)`} width="100%">
        <Box display="flex" h="80px" flexDirection="row" pt="20px" justifyContent="space-between">
          <Box fontWeight="700" fontSize="24px" pl="30px" display="flex">
            <Text>Partner Access</Text>
            {hasAddPartnerAccess && (
              <Box>
                <Flex alignItems="center">
                  <Icon
                    data-testid="plus-icon"
                    w="24px"
                    ml="10px"
                    mt="7px"
                    h="24px"
                    onClick={handlSettingsModal}
                    color={useColorModeValue('white', 'black')}
                    fill={useColorModeValue('white', 'transparent')}
                    _hover={{
                      color: 'url("#BlueGradient")',
                      cursor: 'pointer',
                      stroke: 'white',
                      fill: 'url("#BlueGradient")',
                      '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                      '&:hover>svg>circle': { stroke: useColorModeValue('white', 'none') },
                    }}
                  >
                    <AddIcon stroke={primarblackAndWhiteColorMode} />
                  </Icon>
                </Flex>
                <DeveloperSettingsModal />
              </Box>
            )}
          </Box>
          <ClientAccesFilter isFiltersApplied={isFiltersApplied} isLoading={isDataLoading} />
        </Box>
        <ClientMainScreen isFiltersApplied={isFiltersApplied} />{' '}
      </Box>
    </Box>
  );
};
export default ClientAccess;
