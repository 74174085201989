import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';

export function getStatusAndFlow(value: any) {
  const singleOrganizationData = useAppSelector((state: RootState) => state.organization.SingleOrganizationsGetData);

  let obj: { status: string; action: string; actionPast: string; statusPast: string; statusButtonText?: string } = {
    status: '',
    action: '',
    actionPast: '',
    statusPast: '',
    statusButtonText: '',
  };

  switch (value) {
    case 'Active':
      obj = {
        action: 'Deactivate',
        actionPast: 'Deactivated',
        status: '',
        statusPast: '',
        statusButtonText: '',
      };

      break;
    case 'Registered':
      obj = {
        action: 'Delete',
        actionPast: 'Deleted',
        status: 'Provision',
        statusPast: 'Provisioned',
      };
      break;
    case 'Deactivated':
      obj = {
        action: 'Delete',
        actionPast: 'Deleted',
        status: 'Active',
        statusPast: 'Activated',
        statusButtonText: 'Activate',
      };
      break;
    case 'Paused':
      obj = {
        action: 'Deactivate',
        actionPast: 'Deactivated',
        status: 'Active',
        statusPast: 'Active',
        statusButtonText: 'Activate',
      };
      break;
    case 'Provisioned':
      obj = {
        action: 'Deactivate',
        actionPast: 'Deactivated',
        status: '',
        statusPast: '',
        statusButtonText: 'Deactivate',
      };
      break;
    default:
      obj = { action: '', status: '', statusPast: '', actionPast: '' };
  }

  return obj;
}
