import { Tooltip } from '@chakra-ui/react';

function addEllipsisToOrgName(orgName: string): string {
  if (orgName) {
    if (orgName.length > 15) {
      // 7 prefix + 4 elipsis(....) + 7 suffix = 9
      const prefixOrgName = orgName.slice(0, 12);

      // eslint-disable-next-line no-param-reassign
      orgName = `${prefixOrgName}....`;
    }
  }

  return orgName;
}

const getOrgNameWithTooltip = (orgName: string) => {
  if (orgName) {
    const newOrgName = addEllipsisToOrgName(orgName);
    const isOrgNameWithEllipsis = newOrgName.match(/\.{3}/g);
    if (isOrgNameWithEllipsis !== null && isOrgNameWithEllipsis.length >= 1) {
      return (
        <Tooltip label={orgName} textTransform="capitalize" data-testid="tooltip-email">
          {newOrgName}
        </Tooltip>
      );
    } else {
      return newOrgName;
    }
  }
  return orgName;
};
function addEllipsisToOrgNameDropDowns(orgName: string): string {
  if (orgName) {
    if (orgName.length > 15) {
      // 7 prefix + 4 elipsis(....) + 7 suffix = 9
      const prefixOrgName = orgName.slice(0, 30);

      // eslint-disable-next-line no-param-reassign
      orgName = `${prefixOrgName}....`;
    }
  }

  return orgName;
}
export const getOrgNameWithTooltipForDropDowns = (orgName: string) => {
  if (orgName) {
    const newOrgName = addEllipsisToOrgNameDropDowns(orgName);
    const isOrgNameWithEllipsis = newOrgName.match(/\.{3}/g);
    if (isOrgNameWithEllipsis !== null && isOrgNameWithEllipsis.length >= 1) {
      return (
        <Tooltip label={orgName} data-testid="tooltip-email">
          {newOrgName}
        </Tooltip>
      );
    } else {
      return newOrgName;
    }
  }
  return orgName;
};
export default getOrgNameWithTooltip;
