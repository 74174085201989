import { Box, Text, Accordion, AccordionItem, AccordionButton, UnorderedList, ListItem } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { Link } from '@chakra-ui/react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';

const OrganizationInfoSidebar = ({ routes }: any) => {
  const location = useLocation();
  const colors = customColor();
  const firstLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    firstLinkRef.current?.click();
  }, []);

  const isParentRouteActive = (childRoutes: any) => {
    return childRoutes.some((route: any) => location.pathname.includes(route.pathname));
  };

  return (
    <Box display="flex" flexDirection="column" w="200px" data-testid="org-info-sidebar">
      <Accordion allowMultiple borderColor="rgb(255 255 255 / 60%)">
        {routes.map(
          (route: any) =>
            route.hasAccess && (
              <AccordionItem marginLeft="-21px" data-testid="org-info-sidebar-acc" key={route.id}>
                <h2 style={{ marginLeft: '21px' }}>
                  <AccordionButton
                    style={{ backgroundColor: '#fff' }}
                    paddingTop="0"
                    paddingBottom="0"
                    paddingLeft="0"
                    height="50px"
                    cursor={route.disable ? 'not-allowed' : ''}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize="16px" fontWeight="400" pl="33px" mt="20px">
                        <Link
                          as={RouterLink}
                          _hover={{ color: colors.primaryColor }}
                          _activeLink={{ color: colors.primaryColor }}
                          to={route.pathname}
                          color={
                            route.children?.length && isParentRouteActive(route.children)
                              ? colors.primaryColor
                              : 'light.1500'
                          }
                          pointerEvents={route.disable ? 'none' : 'auto'}
                        >
                          <FormattedMessage id={`${route.name}.message`} />
                        </Link>
                      </Text>
                    </Box>
                  </AccordionButton>
                  {route.children?.length && (
                    <UnorderedList pl="40px">
                      {route.children.map((child: any, index: number) => {
                        return (
                          child.hasAccess && (
                            <Link
                              key={index}
                              as={RouterLink}
                              _hover={{ color: colors.primaryColor }}
                              _activeLink={{ color: colors.primaryColor }}
                              to={child.pathname}
                              color="light.1500"
                              fontSize="16px"
                              pointerEvents={route.disable ? 'none' : 'auto'}
                            >
                              <ListItem mt="20px">
                                <FormattedMessage id={`${child.name}.message`} />
                              </ListItem>
                            </Link>
                          )
                        );
                      })}
                    </UnorderedList>
                  )}
                </h2>
              </AccordionItem>
            ),
        )}
      </Accordion>
    </Box>
  );
};

export default OrganizationInfoSidebar;
