import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Wrap,
  WrapItem,
  Input,
  Icon,
  Image,
} from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';

import { useAppSelector } from 'redux/hooks';

import { InputLabel } from 'components/InputField';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppDispatch } from 'redux/hooks';
import {
  checkPhoneNumberValidation,
  getOrganizationOwnerPhoneValidation,
  updatePhoneNumberWithCountry,
} from 'redux/organizations/Reducers/organizationSlice';

import { Phone_Country_Constants } from 'utils/constants/phoneCountryConstants';
import { Country } from 'types/common-types';

type phoneDropdownProps = {
  inputValues: { [x: string]: string } | undefined;
  setInputValues: any;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
};

const PhoneDropdown = ({ inputValues, setInputValues, onChange }: phoneDropdownProps) => {
  const colors = customColor();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [PhoneNumberCountry, setPhoneNumberCountry] = useState<string>(Phone_Country_Constants.default_Phone_Country);
  const countries = useAppSelector((state) => state.common.countries);

  const phoneNumberValidation = useAppSelector((state) => state.organization.phoneNumberValidation);
  const organizationOwnerPhoneValue = useAppSelector((state) => state.organization.organizationOwnerPhoneValue);
  const orgOwnePhoneLengthValidation = useAppSelector((state) => state.organization.organizationOwnerPhoneData);

  const maxLengthCheck = (object: any) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputValues((prevState: any) => ({ ...prevState, [name]: value }));
    onChange(e);
    dispatch(getOrganizationOwnerPhoneValidation(false));
    if (e.currentTarget.value.length > 0 && e.currentTarget.value.length < 10) {
      dispatch(checkPhoneNumberValidation(true));
      dispatch(getOrganizationOwnerPhoneValidation(false));
    } else if (e.currentTarget.value.length == 10) {
      dispatch(checkPhoneNumberValidation(false));
    } else {
      dispatch(checkPhoneNumberValidation(false));
    }
  };

  const handlePhoneNumberFormat = () => {
    if (organizationOwnerPhoneValue.length == 0) {
      dispatch(getOrganizationOwnerPhoneValidation(true));
    } else {
      dispatch(getOrganizationOwnerPhoneValidation(false));
    }
  };

  const handlePhoneNumberCountry = (e: any) => {
    setPhoneNumberCountry(e.currentTarget.name);
    dispatch(updatePhoneNumberWithCountry(e.currentTarget.value));
    const val = e.currentTarget.value;
    setInputValues((prevState: any) => ({ ...prevState, ['owner_phone_country_code']: val }));
  };

  const renderCountryList = () => {
    return (
      <>
        {countries &&
          countries.map((country: Country) => (
            <MenuItem
              key={country.id}
              value={country.country_phone_code}
              name={country.country_code}
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
              onClick={(e: any) => handlePhoneNumberCountry(e)}
            >
              <Box color="#33333" fontWeight="400" display="flex" alignItems="center" fontSize="15px">
                <Image
                  src={`assets/icons/flags/${country.country_code}.svg`}
                  alt={country.country_code}
                  fallbackSrc="assets/icons/flags/noFlag.svg"
                  fallbackStrategy="onError"
                  height="16px"
                  width="20px"
                  mr="10px"
                />
                <Text fontSize="14px">
                  {country.country_name} ({country.country_phone_code})
                </Text>
              </Box>
            </MenuItem>
          ))}
      </>
    );
  };

  const renderFlag = () => {
    return (
      <Image
        src={`assets/icons/flags/${PhoneNumberCountry}.svg`}
        alt={PhoneNumberCountry}
        fallbackSrc="assets/icons/flags/noFlag.svg"
        fallbackStrategy="onError"
        height="16px"
        width="20px"
      />
    );
  };

  const selectedCountryFlag = useMemo(renderFlag, [PhoneNumberCountry]);
  const countryMenuItems = useMemo(renderCountryList, [countries]);

  return (
    <Box minH="70px">
      <InputLabel value={inputValues?.owner_phone} name="phone" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={{ xs: '85vw', smd: '89vw', md: '305px' }}
        gap="10px"
      >
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                paddingRight="5px"
                width={
                  PhoneNumberCountry
                    ? { xs: '85vw', smd: '89vw', md: '90px' }
                    : { xs: '85vw', smd: '89vw', md: '305px' }
                }
                borderBottom=" 1px solid"
                _focusVisible={{ borderBottomColor: colors.primaryColor }}
                borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                borderRadius="0px"
                variant="flushed"
                paddingTop="5px"
                data-testid="orginfo-country"
                rightIcon={
                  isOpen ? (
                    <Icon as={ChevronUpIcon} w={6} h={6} paddingTop="5px" color={colors.primaryColor} />
                  ) : (
                    <Icon as={ChevronDownIcon} w={6} h={6} paddingTop="5px" color={colors.primaryColor} />
                  )
                }
              >
                <Box marginLeft="-10px" display="flex" h="40px" alignItems="center" mt="5px">
                  {selectedCountryFlag}
                  <Text fontSize="14px" ml="5px" fontWeight="400">
                    <FormattedMessage
                      id={PhoneNumberCountry.toLowerCase() + '.message'}
                      defaultMessage={PhoneNumberCountry}
                    />
                  </Text>
                </Box>
              </MenuButton>
              <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} h="200px" overflowY="scroll" fontSize="14px">
                {countryMenuItems}
              </MenuList>
            </>
          )}
        </Menu>
        {
          <Wrap style={{ overflow: 'visible', width: '205px' }}>
            <WrapItem>
              <Input
                color={colors.subTextColor}
                opacity="0.6"
                name="owner_phone_country_code"
                width={{ xs: '5vw', smd: '15vw', md: '50px' }}
                value={
                  inputValues && inputValues.owner_phone_country_code
                    ? inputValues.owner_phone_country_code
                    : Phone_Country_Constants.default_Phone_Country_Code
                }
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                variant="flushed"
                required
                fontSize="14px"
                paddingTop="5px"
                data-testid="orgForm-phone"
                readOnly
              />
              <Input
                color={colors.subTextColor}
                name="owner_phone"
                width={{ xs: '10vw', smd: '57vw', md: '160px' }}
                value={inputValues?.owner_phone || ''}
                onKeyPress={(event: { key: string; preventDefault: () => void }) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={handleInputChange}
                placeholder={intl.formatMessage({ id: 'phone.message' })}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                type="number"
                min={8}
                onInput={maxLengthCheck}
                onBlur={handlePhoneNumberFormat}
                maxLength={10}
                variant="flushed"
                required
                fontSize="14px"
                paddingTop="5px"
                data-testid="orgForm-phone"
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </WrapItem>
          </Wrap>
        }
      </Box>

      <Text fontSize="12px" color="#FB5654">
        {phoneNumberValidation ? <FormattedMessage id="valid_phone_number.message" /> : ''}
        {orgOwnePhoneLengthValidation ? <FormattedMessage id="field_validation.message" /> : ''}
      </Text>
    </Box>
  );
};
export default PhoneDropdown;
