import { Box, Icon } from '@chakra-ui/react';
import { IoArrowDownSharp, IoArrowUpSharp } from 'react-icons/io5';

const Trend = ({ trendValue }: any) => {
  const isPositive = trendValue && trendValue > 0;
  return (
    <Box fontSize="14px" fontWeight="700" pl="25px" pt="12px" color={isPositive ? 'rgba(31, 208, 2, 1)' : 'red'}>
      {Math.floor(Math.abs(trendValue))}%
      {isPositive ? (
        <Icon w={8} h={7} mt="5px">
          <IoArrowUpSharp />
        </Icon>
      ) : (
        <Icon w={8} h={7} mt="5px">
          <IoArrowDownSharp />
        </Icon>
      )}
    </Box>
  );
};

export default Trend;
