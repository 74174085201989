import { Icon, IconProps } from '@chakra-ui/react';

export const Lock = ({ color = '#333333', ...props }: any) => (
  <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0_4224_72841" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4224_72841)">
      <path
        d="M6.00684 17.167C5.67957 17.167 5.40215 17.0522 5.17459 16.8225C4.94703 16.5929 4.83325 16.3145 4.83325 15.9875V9.01314C4.83325 8.68611 4.94807 8.40777 5.17771 8.17812C5.40736 7.94848 5.6857 7.83366 6.01273 7.83366H6.83325V5.83366C6.83325 4.95135 7.14091 4.203 7.75623 3.5886C8.37155 2.97419 9.12102 2.66699 10.0046 2.66699C10.8882 2.66699 11.6361 2.97419 12.2483 3.5886C12.8605 4.203 13.1666 4.95135 13.1666 5.83366V7.83366H13.9871C14.3141 7.83366 14.5925 7.94848 14.8221 8.17812C15.0518 8.40777 15.1666 8.68611 15.1666 9.01314V15.9875C15.1666 16.3145 15.0517 16.5929 14.8219 16.8225C14.5921 17.0522 14.3135 17.167 13.9863 17.167H6.00684ZM6.01273 16.5003H13.9871C14.1367 16.5003 14.2595 16.4522 14.3557 16.3561C14.4518 16.2599 14.4999 16.1371 14.4999 15.9875V9.01314C14.4999 8.86357 14.4518 8.74071 14.3557 8.64455C14.2595 8.5484 14.1367 8.50033 13.9871 8.50033H6.01273C5.86316 8.50033 5.7403 8.5484 5.64415 8.64455C5.548 8.74071 5.49992 8.86357 5.49992 9.01314V15.9875C5.49992 16.1371 5.548 16.2599 5.64415 16.3561C5.7403 16.4522 5.86316 16.5003 6.01273 16.5003ZM10.0043 13.5837C10.3112 13.5837 10.5678 13.4791 10.774 13.27C10.9802 13.0608 11.0833 12.8028 11.0833 12.4959C11.0833 12.189 10.9787 11.9325 10.7695 11.7263C10.5604 11.5201 10.3024 11.417 9.9955 11.417C9.68861 11.417 9.43207 11.5216 9.22588 11.7307C9.01968 11.9399 8.91659 12.1979 8.91659 12.5047C8.91659 12.8116 9.02116 13.0682 9.23029 13.2744C9.43945 13.4806 9.69746 13.5837 10.0043 13.5837ZM7.49992 7.83366H12.4999V5.83366C12.4999 5.13921 12.2569 4.54894 11.7708 4.06283C11.2846 3.57671 10.6944 3.33366 9.99992 3.33366C9.30547 3.33366 8.7152 3.57671 8.22909 4.06283C7.74297 4.54894 7.49992 5.13921 7.49992 5.83366V7.83366Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
export default Lock;
