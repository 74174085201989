export enum INPUT_TYPES {
  RADIO = 'INPUT_SINGLE_SELECT',
  CHECKBOX = 'INPUT_MULTI_SELECT',
  TEXTAREA = 'INPUT_TEXT_AREA',
  MEASUREMENT = 'INPUT_NUMERIC',
  TEXT = 'INPUT_TEXT',
}

export type WorksheetExternalType = {
  [key: string]: string[];
};

export interface WorksheetTemplate {
  customerVersion: number;
  examTypeId: number;
  examTypeName: string;
  examTypeUuid: string;
  iconName: string;
  id: number;
  systemVersion: number;
  templateType: 'AS' | 'QA';
  uuid: string;
  worksheetContents: string;
  worksheetName: string;
  worksheetExternalInput: WorksheetExternalType[];
  worksheetExternalOutput: WorksheetExternalType[];
}

export interface WorksheetElement {
  type: string;
  element_key: string;
  title: string;
  is_hidden: boolean;
  actions?: ElementAction;
  subscriptions: ElementSubscription[];
  child_elements: WorksheetElement[];
  contents?: string;
}

export interface ElementSubscription {
  source_element: OptionalElement; // element_key is required here,
  concurring_elements: ConcurringElement[];
  modification: ElementModification;
  modification_options: ModificationOption[];
}
export interface ElementAction {
  options: ElementOption[];
  selected_options: string[];
}

export interface ElementOption {
  option_key: string;
  title: string;
  format: OptionFormat[];
}

export interface ExamWorksheetField {
  id: number;
  examworksheettemplateId: number;
  label: string;
  sequenceIndex: number;
  defaultValue: string[];
  key: string;
  dataType: string;
  inputType: INPUT_TYPES;
  choicesJson?: string | string[];
  choices?: string[]; // Hack because server doesn't return JSON - Jira ESDECV-35
  showIfField?: number;
  showIfFieldValue?: number | string;
  showIfFieldObject?: ExamWorksheetField;
  conditionalObj?: {
    [value: string]: ExamWorksheetField;
  };
  configJson?: ExamWorksheetFieldConfig;
  isRequired: boolean;
}

// Arbitrary options not captured in well defined fields
// These should be inferred from the input type
export interface ExamWorksheetFieldConfig {
  prefix?: string;
  postfix?: string;
  placeholder?: string;
}

export interface WorksheetSections {
  id: number;
  label: string;
  sequenceIndex: number;
  examWorksheet: number;
  examFields: ExamWorksheetField[];
}

export interface WorksheetElement {
  type: string;
  element_key: string;
  title: string;
  is_hidden: boolean;
  actions?: ElementAction;
  subscriptions: ElementSubscription[];
  child_elements: WorksheetElement[];
  contents?: string;
}

export interface ElementAction {
  options: ElementOption[];
  selected_options: string[];
}

export interface ElementOption {
  option_key: string;
  title: string;
  format: OptionFormat[];
}

export interface ElementSubscription {
  source_element: OptionalElement; // element_key is required here,
  concurring_elements: ConcurringElement[];
  modification: ElementModification;
  modification_options: ModificationOption[];
}

export interface ElementModification {
  contents?: string;
  actions?: ElementAction;
  is_hidden?: boolean;
  title?: string;
}

export interface ConcurringElement {
  element_key: string;
  contents?: string;
  actions?: ElementAction;
  is_hidden?: boolean;
  title?: string;
}

export enum ModificationOption {
  use_source_element_values = 'use_source_element_values',
  invoke_only_if_actions_are_empty = 'invoke_only_if_actions_are_empty',
  invoke_only_if_actions_are_not_empty = 'invoke_only_if_actions_are_not_empty',
  invoke_only_if_contents_are_empty = 'invoke_only_if_contents_are_empty',
  invoke_only_if_contents_are_not_empty = 'invoke_only_if_contents_are_not_empty',
}

export interface OptionalElement {
  type: string;
  element_key: string;
  title: string;
  is_hidden: boolean;
  actions?: ElementAction;
  contents?: string;
  child_elements: OptionalElement[];
}

export enum OptionFormat {
  neutral = 'neutral',
  positive = 'positive',
  negative = 'negative',
  left = 'left',
  right = 'right',
  preview_length_short = 'preview_length_short',
  preview_length_long = 'preview_length_long',
  indent = 'indent',
  suffix = 'suffix',
  placeholder = 'placeholder',
  nav_bar_left = 'nav_bar_left',
  nav_bar_right = 'nav_bar_right',
  trigger = 'trigger',
  other_editable = 'other_editable',
  other_user_added = 'other_user_added',
  final_report_value_hidden = 'final_report_value_hidden',
  limited = 'limited',
}

export type WorksheetType = {
  name: string;
  examType: number;
  isPublished: boolean;
  template: WorksheetTemplate;
};

// TODO: Change the model
export type WorksheetJSON = {
  name: string;
  exam_type: number;
  is_published: boolean;
  template: {
    global_header_image: string;
    system_version: number;
    user_revision: number;
    root_element_list: WorksheetElement[];
  };
};

export interface Worksheet {
  id: number;
  uuid: string;
  examId: string;
  submittedExamTypeId: number;
  clientCreatedAt: string;
  clientUpdatedAt: string;
  worksheetTemplate: WorksheetTemplate;
  elementRegistry: { [x: string]: WorksheetElement };
  subscriptionRegistry: { [x: string]: WorksheetElement[] };
  isL2SectionVisible: boolean;
  worksheetExternalInput: WorksheetExternalType[];
  worksheetExternalOutput: WorksheetExternalType[];
}

export type ExamWorksheetResult = {
  id: number;
  uuid: string;
  examId: string;
  submittedExamTypeId: number;
  worksheetTemplate: WorksheetTemplate;
  worksheetContents: string;
  clientCreatedAt: string;
  clientUpdatedAt: string;
  worksheetExternalInput: WorksheetExternalType[];
  worksheetExternalOutput: WorksheetExternalType[];
};

export type GetExamWorksheetResultMetaOnlyRequest = {
  examUuid: string;
  metaOnly: boolean;
};

export type ElementSubscriptionUpdateAction = {
  element_key: string;
};

export type ElementRegistryUpdateAction = {
  updatedElement: WorksheetElement;
  sectionKey: string;
};

export type ElementRegistry = Record<string, WorksheetElement>;

export type SubscriptionRegistry = Record<string, WorksheetElement[]>;
