import { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { utils } from '@hassanmojab/react-modern-calendar-datepicker';

import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleAddAddonModal } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { createNewLicenseAddon } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import SubmitButton from './SubmitButton';
import { useFormik } from 'formik';
import { addAddonValidationSchema, initialState } from './AddonValidationSchema';
import { capitalizeText } from '@exo/shared-ui';
import { TierObject } from 'types/organization-types';
import AddonInfoFields from './AddonInfoFields';
import moment from 'moment';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';

const AddAddonModal = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const isOpen = useAppSelector((state) => state.softwareBundles.isAddAddonModalOpen);
  const isCreateAddonPending = useAppSelector((state) => state.softwareBundles.isCreateAddonPending);
  const createAddonApiStatus = useAppSelector((state) => state.softwareBundles.createAddonStatus);
  const errorMsg = useAppSelector((state) => state.softwareBundles.createAddonErrorMessage);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [isAddFail, setIsAddFail] = useState(false);
  const [isAddSuccess, setIsAddSuccess] = useState(false);

  const initialValues = { ...initialState };
  const addAddonForm = useFormik({
    initialValues,
    validationSchema: addAddonValidationSchema(),
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission logic here
    },
  });

  const handleModalClose = () => {
    dispatch(toggleAddAddonModal(false));
    addAddonForm.resetForm();
    setIsAddSuccess(false);
    setIsAddFail(false);
  };

  useEffect(() => {
    setIsAddDisabled(addAddonForm.isSubmitting || !addAddonForm.dirty || Object.keys(addAddonForm.errors).length != 0);

    if (!addAddonForm.isSubmitting) {
      setIsAddFail(false);
    }
  }, [addAddonForm]);

  useEffect(() => {
    if (isCreateAddonPending) {
      addAddonForm.setSubmitting(true);
      setIsAddSuccess(false);
      setIsAddFail(false);
    }
    if (!isCreateAddonPending && createAddonApiStatus && createAddonApiStatus === 'SUCCESS') {
      setIsAddSuccess(true);
      setIsAddFail(false);
      setTimeout(() => {
        handleModalClose();
      }, 1500);
    } else if (createAddonApiStatus === 'FAIL') {
      setIsAddFail(true);
      setIsAddSuccess(false);
    }
  }, [createAddonApiStatus, isCreateAddonPending]);

  const handleAddBtn = () => {
    const addOnInfo = addAddonForm.values;
    let postData = {
      name: capitalizeText(addOnInfo.name),
      catalog_number: addOnInfo.catalogNumber,
      availability_date: moment(
        new Date(
          `${addOnInfo.availabilityDate.year}-${addOnInfo.availabilityDate.month}-${addOnInfo.availabilityDate.day}`,
        ),
      ).format('YYYY-MM-DD'),
      grace_period_days: addOnInfo.expirationGracePeriod,
      description: addOnInfo.notes,
      status: addOnInfo.status.id,
      license_tiers: addOnInfo.supportedTiers.map((tier: TierObject) => tier.tier_code),
    };
    dispatch(createNewLicenseAddon(postData));
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleModalClose}
      border-radius="0.1px"
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '368px', smd: '720px', md: '720px', lg: '720px', xl: '720px', dt: '720px' }} mt="80px">
        <ModalHeader fontSize="24px" ml="8px">
          <FormattedMessage id="add_addon.message" />
        </ModalHeader>
        <ModalCloseButton disabled={false} color={closeIconColor} backgroundColor="transparent" />
        <ModalBody ml="7px">
          <AddonInfoFields addonInfo={addAddonForm} />
          {isAddFail && <BannerMessage message={errorMsg} defaultMessage={''} />}
        </ModalBody>
        <ModalFooter>
          <SubmitButton
            label={<FormattedMessage id="add_modal.message" />}
            isFail={isAddFail}
            isSucces={isAddSuccess}
            isDisbaled={isAddDisabled || isCreateAddonPending || isAddFail}
            handleSubmit={handleAddBtn}
            isLoading={isCreateAddonPending}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddAddonModal;
