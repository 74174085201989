import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customerSubTabs } from 'routes/routePaths';
import CustomersContainer from './CustomersTab/CustomersContainer';
import Content from 'pages/organizations/components/organizations/OrganizationContent/Content';

import { setActiveTabs } from 'redux/common/CommonSlice';
import { useLocation } from 'react-router-dom';
import SubTabs from 'components/subTabs/SubTabs';

const CustomersLandingPage = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector((state) => state.common.activeTab);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    const currentTab = (query.get('tab') as string) || customerSubTabs.Customers;
    dispatch(setActiveTabs(currentTab));
  }, [query]);

  return (
    <Box pl="30px" data-test-id="customers-landing-page">
      <SubTabs selectedTab={selectedTab} routePaths={customerSubTabs} routePrefix={'/customers'} />
      {selectedTab === customerSubTabs.Customers && <CustomersContainer />}
      {selectedTab === customerSubTabs.Organizations && <Content />}
    </Box>
  );
};

export default CustomersLandingPage;
