/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Text } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { Device } from 'types/device-types';
import { Organization } from 'types/organization-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaginationTypes } from 'types/pagination-types';
import { displayPageInfo } from 'utils/constants/displayPageInfo';
function TableInformation({
  data,
  selectedPage,
  pagination,
}: {
  data: any;
  selectedPage: number;
  pagination: PaginationTypes | null;
}) {
  const colors = customColor();

  return (
    data &&
    pagination && (
      <Box display="flex" data-testid="pages-info-container">
        <Text fontSize="14px" color={colors.paginationDisplay} data-testid="pages-info">
          <FormattedMessage id="table_info.message" values={displayPageInfo(pagination, selectedPage)} />
        </Text>
      </Box>
    )
  );
}

export default TableInformation;
