import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect, useState } from 'react';
import { RootState } from 'redux/Store/store';
import { useIntl } from 'react-intl';
import CustomDropdown from 'components/customDropdown/CustomDropdown';
import Search from 'components/customSearch/Search';
import {
  clearSearcText,
  closeSearchInMobile,
  expandSearchInMobile,
  getFlterData,
  getSearchText,
} from 'redux/devices/Reducers/deviceSlice';
interface FilterProps {
  isFiltersApplied: boolean;
  defaultDevice: string;
}
let count = 0;
const DeviceFilter = ({ isFiltersApplied, defaultDevice = '' }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const devicesGridView = useAppSelector((state: RootState) => state.device.devicesGridView);
  const deviceData = useAppSelector((state: RootState) => state.device);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  type filtersType = { status: string[]; device: string[] };
  const [filters, setFilters] = useState<filtersType>({
    status: [`${intl.formatMessage({ id: 'all.message' })}`],
    device: [`${intl.formatMessage({ id: 'all.message' })}`],
  });

  const DROPDOWN_MAP = [
    {
      label: `${intl.formatMessage({ id: 'types.message' })}`,
      options: [
        `${intl.formatMessage({ id: 'all.message' })}`,
        ...deviceData?.deviceTypesData.map((itr) => itr?.internal_name),
      ],
      name: 'device',
      label2: `${intl.formatMessage({ id: 'singularType.message' })}`,
    },
    {
      label: `${intl.formatMessage({ id: 'statuses.message' })}`,
      options: [
        `${intl.formatMessage({ id: 'all.message' })}`,
        ...deviceData?.statusData.map((itr) => itr?.status_name),
      ],
      name: 'status',
      label2: `${intl.formatMessage({ id: 'singularStatus.message' })}`,
    },
  ];
  const handleDropDownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, id, checked }: { name: string; id: string; checked: boolean } = e.target;
    const index = name === 'status' ? 1 : 0;
    if (checked) {
      const filtersOptions = [...filters[name as keyof filtersType], id]?.filter((itr) => itr !== 'All');
      const exludeAll = DROPDOWN_MAP[index].options?.filter((itr) => itr !== 'All');

      if (id === `${intl.formatMessage({ id: 'all.message' })}` || filtersOptions.length === exludeAll.length) {
        setFilters({ ...filters, [name]: [`${intl.formatMessage({ id: 'all.message' })}`] });
        return;
      }
      setFilters({
        ...filters,
        [name]: filtersOptions.filter((itr) => itr !== `${intl.formatMessage({ id: 'all.message' })}`),
      });
    } else {
      const mutatedArray = [
        ...filters[name as keyof filtersType].filter(
          (itr) => ![id, `${intl.formatMessage({ id: 'all.message' })}`].includes(itr),
        ),
      ];
      setFilters({
        ...filters,
        [name]: [...(mutatedArray.length ? mutatedArray : [`${intl.formatMessage({ id: 'all.message' })}`])],
      });
    }
  };

  const applyFilter = () => {
    count += 1;
    if (count === 1) return;
    const payload = {
      device_catalogue_id: deviceData.deviceTypesData
        .filter((itr) => [...filters?.device].includes(itr?.internal_name))
        .map((itr) => itr.id),
      status_id: deviceData.statusData
        .filter((itr) => filters?.status.includes(itr?.status_name))
        .map((itr) => itr.status_id),
    };

      payload.device_catalogue_id = filters?.device?.includes('All') ? ['All'] : payload.device_catalogue_id;
    dispatch(getFlterData(payload));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      applyFilter();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filters]);

  useEffect(() => {
    if (devicesGridView) {
      setFilters({
        status: [`${intl.formatMessage({ id: 'all.message' })}`],
        device: [`${intl.formatMessage({ id: 'all.message' })}`],
      });
    }
  }, [deviceData.devicesGridView]);

  useEffect(() => {
    setFilters({
      ...filters,
      device: [defaultDevice],
    });
  }, []);

  const statusColors: { [key: string]: string } = deviceData?.statusData?.reduce(
    (acc, { status_name, color_code }) => ({ ...acc, [status_name]: color_code }),
    {},
  );
  const handleSearchClose = () => {
    dispatch(closeSearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSearchText(value));
  };
  useEffect(() => {
    dispatch(closeSearchInMobile());
    return () => {
      count = 0;
    };
  }, [isMobileDevice]);

  return (
    <Box mr="45px" px="-20px" mt="-20px">
      {' '}
      {(deviceData?.devicesList?.length > 0 || isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="6px"
          alignItems="center"
        >
          {' '}
          {!devicesGridView && (
            <Search
              data={deviceData}
              handleClose={handleSearchClose}
              handleClear={handleSearchClear}
              handleExpandClick={handleSearchExpand}
              handleSearch={handleSearchValue}
            />
          )}{' '}
          {!devicesGridView && !deviceData.isSearchExpand && (
            <Box display="flex" alignItems="center" pr={{ lg: '10px' }} id="status-types-container">
              {' '}
              {DROPDOWN_MAP.map(({ label, options, name, label2 }) => (
                <Box key={name} pl={{ xs: '26px' }} id="dropdown-container">
                  {' '}
                  <CustomDropdown
                    label={label}
                    statusColors={statusColors}
                    options={options}
                    activeFilters={filters[name as keyof filtersType]}
                    name={name}
                    onChange={handleDropDownChange}
                    label2={label2}
                  />{' '}
                </Box>
              ))}{' '}
            </Box>
          )}{' '}
          {/* <OrganizationsDataSwitch handleClick={handleDevicesView} isGridView={devicesGridView} />{' '} */}
        </Box>
      )}{' '}
    </Box>
  );
};
export default DeviceFilter;
