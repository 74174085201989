import React from 'react';
import { Box, Center, Text, useColorModeValue } from '@chakra-ui/react';
import { BiError } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';

interface BannerMessageProps {
  message: string;
  defaultMessage: string;
}

const BannerMessage: React.FC<BannerMessageProps> = (props) => {
  const { message, defaultMessage } = props;
  const colors = customColor();

  return (
    <Box
      w="100%"
      p="10px"
      h="100%"
      data-testid="banner-message"
      background={useColorModeValue(colors.lightModeBanner, colors.darkModeBanner)}
      borderRadius="5px"
      alignItems="center"
      alignContent="center"
    >
      <Center display="flex" flexDirection="row" alignItems="center" alignContent="center">
        <Text h="16px" w="16px" color="red" data-testid="error-icon" pr="10px">
          <BiError />
        </Text>
        <Text fontSize="14px" ml="10px" alignItems="center" color={useColorModeValue('light.800', 'common.200')}>
          {message} {defaultMessage}
        </Text>
      </Center>
    </Box>
  );
};

export default BannerMessage;
