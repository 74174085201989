import { Wrap, WrapItem, Text, Box } from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';

import React from 'react';
import { useAppSelector } from 'redux/hooks';

const OrgLicenseNameCodeInputFields = () => {
  const singleOrgName = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  return (
    <Box ml="5px">
      <Wrap alignItems={'center'} spacingX="30px">
        <WrapItem w="305px">
          <Box minH="70px" mt="10px">
            <InputLabel value={'name'} name="org_name" />
            <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
              {singleOrgName?.org_name}
            </Text>
          </Box>
        </WrapItem>
        <WrapItem w="305px">
          <Box minH="70px" mt="10px">
            <InputLabel value={'code'} name="org_code" />
            <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
              {singleOrgName?.org_code}
            </Text>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default OrgLicenseNameCodeInputFields;
