import { Box, Input, Text } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleCreateSoftwareBundleDescriptionValue } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';

const SoftwareBundleDescriptionInput = () => {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const bundelDescriptionvalue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleDescriptionValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDescription = (e: any) => {
    dispatch(handleCreateSoftwareBundleDescriptionValue(e.target.value));
  };
  return (
    <Box display="flex" flexDirection="column" minH="70px" mt="40px">
      <InputLabel value={bundelDescriptionvalue} name={'notes_optional'} />
      <Input
        variant="flushed"
        color={colors.subTextColor}
        borderColor="#E0E0E0"
        maxLength={75}
        fontSize="14px"
        _placeholder={{ fontSize: '14px' }}
        width={{ xs: '85vw', smd: '89vw', md: '645px' }}
        focusBorderColor={colors.primaryColor}
        borderBottomColor={colors.inputBorderColor}
        borderWidth="0 0 0.5px 0"
        onChange={handleDescription}
        placeholder="Notes (Optional)"
      />
      <Text fontSize="12px" color="grey" display="flex" float="right" justifyContent="flex-end">
        {Math.abs(bundelDescriptionvalue.length - 75)}
      </Text>
    </Box>
  );
};

export default SoftwareBundleDescriptionInput;
