/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React from 'react';
import { Box, Input, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customColor } from '../../../../../../../libs/shared-ui/src/lib/assets/styles/colors';
import { fetchClientNotesInputValue } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';

const ClientNotesInputField = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const clientNotesInputValue = useAppSelector((state) => state.clientAccess.clientNotesInputValue);
  const handleNotes = (e: any) => {
    dispatch(fetchClientNotesInputValue(e.target.value));
  };

  return (
    <Wrap spacingX="30px" data-testid="container-element">
      <WrapItem>
        <Box>
          <InputLabel value={clientNotesInputValue} name={'notes_optional'} />
          <Input
            variant="flushed"
            color={colors.subTextColor}
            borderColor="#E0E0E0"
            maxLength={75}
            fontSize="14px"
            _placeholder={{ fontSize: '14px' }}
            width={{ xs: '85vw', smd: '89vw', md: '645px' }}
            focusBorderColor={colors.primaryColor}
            borderBottomColor={colors.inputBorderColor}
            borderWidth="0 0 0.5px 0"
            onChange={handleNotes}
            placeholder="Notes (Optional)"
          />
          <Text fontSize="12px" color="grey" display="flex" float="right" justifyContent="flex-end">
            {Math.abs(clientNotesInputValue.length - 75)}
          </Text>
        </Box>
      </WrapItem>
    </Wrap>
  );
};
export default ClientNotesInputField;
