import {
  Box,
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import {
  deviceTypePostCreateButton,
  fetchDeviceTypeSuccessCreation,
  handleEditDeviceTypeModal,
  postDeviceTypeData,
  resetDeviceTypeInputValues,
} from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  customColor,
  CircleCrossIcon,
  CircleCheckIcon,
  CircleRightEnabledIcon,
  CircleRightDisabledIcon,
} from '../../../../../../libs/shared-ui/src/lib/assets';
import { Error_Type_Enum } from 'utils/enum';
import { getDeviceTypesApiData } from 'redux/deviceTypes/Actions/DeviceTypeActions';
import DeviceTypeEditFields from './DeviceTypeModalEditFields';
import { ModalTimeOut } from 'utils/constants/Constants';
import { userAccessConst } from 'utils/constants/userAccessConst';

const DeviceTypeEditModal = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const { onClose } = useDisclosure();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const openDeviceTypeModal = useAppSelector((state) => state.deviceTypes.handleDeviceTypeEditModal);
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');

  const postData = useAppSelector((state) => state.deviceTypes.postDeviceTypeFail);
  const handleButton = useAppSelector((state) => state.deviceTypes.getDeviceTypeCreateButtonEnable);
  const successCreation = useAppSelector((state) => state.deviceTypes.deviceTypeSuccessCreation);
  const toggleSubmitButton = useAppSelector((state) => state.deviceTypes.deviceTypeCreateButtonEnable);
  const createDeviceApiFail = useAppSelector((state) => state.deviceTypes.editDeviceTypeApiFail);
  const handleCreateDevicePopUp = useAppSelector((state) => state.deviceTypes.handleDeviceTypeEditModal);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);

  useEffect(() => {
    if (createDeviceApiFail.length > 0) {
      dispatch(fetchDeviceTypeSuccessCreation(true));
    }

    if (createDeviceApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetDeviceTypeInputValues());
        dispatch(handleEditDeviceTypeModal(false));
        dispatch(getDeviceTypesApiData());
        onClose();
      }, ModalTimeOut);
    }
  }, [createDeviceApiFail]);

  const handleDeviceTypeModalClose = () => {
    dispatch(handleEditDeviceTypeModal(false));
    dispatch(resetDeviceTypeInputValues());
  };
  const handleClick = () => {
    dispatch(postDeviceTypeData(true));
    dispatch(deviceTypePostCreateButton(true));

    if (handleCreateDevicePopUp == false) {
      dispatch(fetchDeviceTypeSuccessCreation(false));
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openDeviceTypeModal}
      onClose={handleDeviceTypeModalClose}
      border-radius="0.1px"
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '368px', smd: '700px', md: '700px', lg: '700px', xl: '700px', dt: '700px' }}>
        <ModalHeader fontSize="24px" ml="8px">
          {userPermissions.devices?.includes(userAccessConst.device.updateDeviceModel) ? (
            <FormattedMessage id="edit_device_model.message" />
          ) : (
            <FormattedMessage id="view_device_model.message" />
          )}
        </ModalHeader>
        <ModalCloseButton
          disabled={!postData && toggleSubmitButton}
          color={closeIconColor}
          data-testid="close-ic"
          backgroundColor="transparent"
        />
        <ModalBody ml="7px" backgroundColor="transparent">
          <DeviceTypeEditFields />
        </ModalBody>
        {userPermissions.devices?.includes(userAccessConst.device.updateDeviceModel) && (
          <ModalFooter backgroundColor="transparent" zIndex={-9999}>
            {postData ? (
              <Button
                variant="outline"
                type="button"
                width="195px"
                height="42px"
                borderRadius="20px"
                borderColor="#BAC5CD"
                color="#BAC5CD"
                disabled
                fontSize="17px"
                float="right"
                data-testid="org-button-disabled"
                _hover={{ cursor: 'not-allowed' }}
              >
                <Flex>
                  <Text
                    fontSize="12px"
                    ml="50px"
                    display="flex"
                    alignItems="center"
                    fontWeight="extrabold"
                    data-testid="org-button-text"
                  >
                    <FormattedMessage id="failure.message" />
                  </Text>
                  <Icon w="30px" h="30px" ml="32px" float="right" data-testid="org-button-icon">
                    <CircleCrossIcon />
                  </Icon>
                </Flex>
              </Button>
            ) : (
              <Box>
                {handleButton ? (
                  <Button
                    variant="outline"
                    type="button"
                    width="195px"
                    height="42px"
                    borderRadius="20px"
                    color={blueColorMode}
                    cursor={successCreation ? 'not-allowed' : ''}
                    fill={blueColorMode}
                    borderColor={blueColorMode}
                    fontSize="17px"
                    float="right"
                    data-testid="create-button-active"
                    _hover={{
                      bg: blueColorMode,
                      color: blackAndWhiteColorMode,
                      '&>div>svg>svg>circle': {
                        fill: blueColorMode,
                        stroke: blackAndWhiteColorMode,
                      },
                      '&>div>div>svg>svg>circle': {
                        fill: blueColorMode,
                        stroke: blackAndWhiteColorMode,
                      },
                      '&>div>svg>svg>path': {
                        fill: blackAndWhiteColorMode,
                        stroke: 'none',
                      },
                      '&>div>div>svg>svg>path': {
                        fill: blackAndWhiteColorMode,
                        stroke: 'none',
                      },
                    }}
                    onClick={handleClick}
                    _disabled={{
                      bg: blueColorMode,
                      color: blackAndWhiteColorMode,
                      '&>div>div>svg>svg>circle': {
                        fill: blueColorMode,
                        stroke: blackAndWhiteColorMode,
                      },
                      '&>div>div>svg>svg>path': {
                        fill: blackAndWhiteColorMode,
                        stroke: 'none',
                      },
                    }}
                    isDisabled={successCreation}
                  >
                    <Flex>
                      <Box
                        fontSize="10px"
                        ml="50px"
                        display="flex"
                        alignItems="center"
                        data-testid="org-button-text-diabled"
                      >
                        {successCreation ? (
                          <FormattedMessage id="done.message">
                            {(txt) => (
                              <Text fontSize="12px" fontWeight="extrabold" ml="17px">
                                {txt}
                              </Text>
                            )}
                          </FormattedMessage>
                        ) : (
                          <FormattedMessage id="save.message">
                            {(txt) => (
                              <Text fontWeight="extrabold" fontSize="12px">
                                {txt}
                              </Text>
                            )}
                          </FormattedMessage>
                        )}
                      </Box>
                      {toggleSubmitButton ? (
                        <Box>
                          {successCreation ? (
                            <Icon w="30px" h="30px" ml="40px" float="right" data-testid="org-button-checkIcon">
                              <CircleCheckIcon />
                            </Icon>
                          ) : (
                            <Box ml="30px" pl="10px" h="40px" w="40px" mt="13px" data-testid="org-button-spinner">
                              <Spinner />
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Icon w="30px" h="30px" ml="52px" float="right">
                          <CircleRightEnabledIcon />
                        </Icon>
                      )}
                    </Flex>
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    type="button"
                    width="195px"
                    height="42px"
                    borderRadius="20px"
                    borderColor="#BAC5CD"
                    color="#BAC5CD"
                    disabled
                    fontSize="17px"
                    float="right"
                    data-testid="org-button-disabled"
                    _hover={{ cursor: 'not-allowed' }}
                  >
                    <Flex>
                      <Text
                        fontSize="12px"
                        ml="50px"
                        display="flex"
                        alignItems="center"
                        fontWeight="extrabold"
                        data-testid="org-button-text"
                      >
                        <FormattedMessage id="save.message" />
                      </Text>
                      <Icon w="30px" h="30px" ml="52px" float="right" data-testid="org-button-icon">
                        <CircleRightDisabledIcon />
                      </Icon>
                    </Flex>
                  </Button>
                )}
              </Box>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DeviceTypeEditModal;
