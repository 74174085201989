import { UserState } from '../../../../admin-ui/src/lib/redux/User/user.slice';
import { ExamExportState } from './examExport/examExport.slice';
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { LoginState } from './login.slice';
import { AgreementsState } from './Agreements/agreements.slice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { OnboardingConfigState } from './gettingStarted/gettingStarted.slice';
import { ApiVersionState } from './apiVersion';

export type SharedUiState = {
  login: LoginState;
  agreements: AgreementsState;
  examExport: ExamExportState;
  gettingStarted: OnboardingConfigState;
  user: UserState;
  apiVersion: ApiVersionState;
};

export type useAppDispatch = ThunkDispatch<SharedUiState, unknown, AnyAction>;
export const useAppSelector: TypedUseSelectorHook<SharedUiState> = useSelector;
