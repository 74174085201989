import { Box, useDisclosure } from '@chakra-ui/react';
import CustomModal from 'components/customModal/CustomModal';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  handleClientPostDataCreateButtonEnable,
  handleClientSuccessfulPost,
  handlePostApiDataSpinner,
  handleSEditettingsModalPopUp,
  handleSettingsModalPopUp,
  handleToPostApiData,
  resetCreateSettingsPopUpValues,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { Error_Type_Enum } from 'utils/enum';
import EditDeveloperSettingsFields from './EditDeveloperSettingsFields';

const EditDeveloperSettingsModal = () => {
  const dispatch = useAppDispatch();
  const { onClose } = useDisclosure();
  const postDataSuccessfulCreation = useAppSelector((state) => state.clientAccess.enableSuccessfulPostData);
  const enableCreateButton = useAppSelector((state) => state.clientAccess.clientPostDataCreateButtonEnable);
  const editSettingsModalPopUp = useAppSelector((state) => state.clientAccess.editSettingsModalPopUp);
  const createClientPostApiFail = useAppSelector((state) => state.clientAccess.editClientAccessPostDataFail);
  const postFailure = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const toggleClientButtonEnableSpinnerValue = useAppSelector((state) => state.clientAccess.postClientApiDataSpinner);

  useEffect(() => {
    if (createClientPostApiFail.length > 0) {
      dispatch(handleClientSuccessfulPost(true));
    }
    if (createClientPostApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetCreateSettingsPopUpValues());
        dispatch(handleClientPostDataCreateButtonEnable(false));
        onClose();
      }, ModalTimeOut);
    }
  }, [createClientPostApiFail]);
  const handleModalClose = () => {
    dispatch(handleSEditettingsModalPopUp(false));
    dispatch(resetCreateSettingsPopUpValues());
  };
  const handleCreateButtonClick = () => {
    dispatch(handleToPostApiData(true));
    dispatch(handlePostApiDataSpinner(true));

    if (!editSettingsModalPopUp) {
      dispatch(handleClientSuccessfulPost(false));
    }
  };

  return (
    <Box>
      <CustomModal
        openModal={editSettingsModalPopUp}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createClientPostApiFail && toggleClientButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={enableCreateButton}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleClientButtonEnableSpinnerValue}
        InputFieldsComponent={EditDeveloperSettingsFields}
        modalHeader="edit_partner_access"
        modalButtonName="update_modal"
      />
    </Box>
  );
};

export default EditDeveloperSettingsModal;
