import { Day } from '@hassanmojab/react-modern-calendar-datepicker';
import { TierObject } from 'types/organization-types';
import * as Yup from 'yup';

export const initialState = {
  name: '',
  status: {
    id: 1,
    status: 'Active',
    color_code: '#00CA14',
  },
  availabilityDate: {} as Day,
  catalogNumber: '',
  supportedTiers: [],
  expirationGracePeriod: 30,
  notes: '',
};

export const addAddonValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .required('This field is required')
      .min(4, 'Must be 4 characters or more')
      .max(50, 'Must be less than 50 characters'),
    status: Yup.object().required('This field is required'),
    availabilityDate: Yup.object().required('This field is required'),
    catalogNumber: Yup.string().required('This field is required'),
    supportedTiers: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          tier_name: Yup.string(),
          tier_code: Yup.string(),
        }),
      )
      .required('This field is required')
      .min(1, 'This field is required'),
    expirationGracePeriod: Yup.number().required('This field is required'),
    notes: Yup.string(),
  });
};
