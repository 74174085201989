export enum DicomFormats {
  JPEG_MP4 = 'JPEG_MP4',
  DICOM = 'DICOM',
  ALL = 'ALL',
}

export interface ExportExamRequest {
  exam?: string;
  examDicomsIds: number[];
  format: DicomFormats;
  exportAll: boolean;
  anonymized: boolean;
}

export interface GetExportExamDetailsRequest {
  id: string | number;
}

export enum ExportStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export interface ExamExport extends ExportExamRequest {
  id: number | string;
  status: ExportStatus;
  presignedUrl: string;
  expiresAt: string | null;
}
