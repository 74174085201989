import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { examExportThunks } from './examExport.thunk';
import { ExamExport } from './examExport.types';
import { SharedUiState } from '../hooks';

export type ExamExportState = {
  exportExamData?: ExamExport;
  selectedExportImageIds: number[];
  exportFailed: boolean;
  loadingExport: boolean;
};

const initialState: ExamExportState = {
  selectedExportImageIds: [],
  exportFailed: false,
  loadingExport: false,
};

const examExportSlice = createSlice({
  name: 'examExport',
  initialState,
  reducers: {
    clearExportExamData: (state) => {
      state.exportExamData = undefined;
    },
    setLoadingExport: (state, action: PayloadAction<boolean>) => {
      state.loadingExport = action.payload;
    },
    setSelectedExportImageIds: (state, action: PayloadAction<number[]>) => {
      state.selectedExportImageIds = action.payload;
    },
    setExportFailed: (state, action: PayloadAction<boolean>) => {
      state.exportFailed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(examExportThunks.exportExam.fulfilled, (state, action: PayloadAction<ExamExport>) => {
        state.exportExamData = action.payload;
        if (state.exportFailed) {
          state.exportFailed = false;
          state.loadingExport = false;
        }
      })
      .addCase(examExportThunks.exportExam.rejected, (state, _) => {
        state.exportFailed = true;
        state.loadingExport = false;
      })
      .addCase(examExportThunks.getExportExamDetails.fulfilled, (state, action: PayloadAction<ExamExport>) => {
        state.exportExamData = action.payload;
      });
  },
});

// Selectors
const selectExamExportState = (state: SharedUiState) => state.examExport;
const selectors = {
  selectExamExportData: createSelector(selectExamExportState, (examExportState) => examExportState.exportExamData),
};
export const { selectExamExportData } = selectors;

// Actions
export const { clearExportExamData, setLoadingExport, setSelectedExportImageIds, setExportFailed } =
  examExportSlice.actions;

// Reducer
export const examExportReducer = examExportSlice.reducer;
