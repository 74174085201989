import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import CustomTable from 'components/customTable/CustomTable';

function SingleUserActivityListView() {
  const OrganizationInfoUsersData = useAppSelector((state) => state.singleUserActivity.singleUserActivityData);
  const intl = useIntl();

  const data = OrganizationInfoUsersData?.map((item: any) => {
    return {
      product: item.product ? item.product + ' ' + item?.app_version : '-',
      platform: item.platform ? item.platform : '-',
      platformVersionInfo: item?.app_device_os ? item?.app_device_os : '-',
      platform_info: item?.app_device_model ? item?.app_device_model : '-',
      event: item.event ? intl.formatMessage({ id: `user_activity_events.${item.event}.message` }) : '-',

      date_and_time: item.date_and_time ? (
        moment.utc(item.date_and_time).format('MMM DD, YYYY HH:mm')
      ) : (
        <FormattedMessage id="empty.message" />
      ),
    };
  });
  const columns = [
    { field: 'product', header: <FormattedMessage id="product.message" />, link: false },
    { field: 'platform', header: <FormattedMessage id="platform.message" />, link: false },
    { field: 'platform_info', header: <FormattedMessage id="platform_info.message" />, link: false },
    { field: 'event', header: <FormattedMessage id="event.message" />, link: false },
    { field: 'date_and_time', header: <FormattedMessage id="date_and_time.message" />, link: false },
  ];

  return (
    <Box>
      <CustomTable data={data} columns={columns} />
    </Box>
  );
}

export default SingleUserActivityListView;
