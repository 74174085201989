import { AbsoluteCenter, Box, CircularProgress } from '@chakra-ui/react';
import CustomTable from 'components/customTable/CustomTable';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { userAccessConst } from 'utils/constants/userAccessConst';

function DevicesListView() {
  const deviceList = useAppSelector((state: RootState) => state.device.devicesList);
  const loader = useAppSelector((state: RootState) => state.device.devicesListLoading);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const navigate = useNavigate();
  const data = deviceList.map((item) => {
    return {
      serial_number: item.serial_number,
      device_status: item.device_status.status + '*' + item.device_status.color_code,
      org_name: item.org_id ? item.org_id.org_name : <FormattedMessage id="empty.message" />,
      device_name: item.catalogue.device_name + ' - ' + item.catalogue.internal_name,
      org_licence:
        item.org_id && item.org_id.tier ? item.org_id.tier.tier_name : <FormattedMessage id="empty.message" />,
      manufacture_date: item.manufacture_date ? (
        moment(item.manufacture_date).format('ll')
      ) : (
        <FormattedMessage id="empty.message" />
      ),
      last_used_by: item.last_used_by + '*' + (item.last_used_on ? moment(item.last_used_on).format('ll') : '-'),
      udid: item.udid,
      org_id: item?.org_id?.org_id,
      id: item?.id,
    };
  });
  const columns = [
    {
      field: 'serial_number',
      header: <FormattedMessage id="serial#.message" />,
      link: userPermissions.devices?.includes(userAccessConst.device.viewDevice),
    },
    { field: 'device_name', header: <FormattedMessage id="name.message" />, link: false },
    { field: 'udid', header: <FormattedMessage id="device_identifier.message" />, link: false },
    { field: 'manufacture_date', header: <FormattedMessage id="manufacture_date.message" />, link: false },
    {
      field: 'org_name',
      header: <FormattedMessage id="organizationKey.message" />,
      link: userPermissions.organizations?.includes(userAccessConst.organization.viewOrg),
      isCapitalize: true,
    },
    { field: 'org_licence', header: <FormattedMessage id="organization_tier.message" />, link: false },
    { field: 'last_used_by', header: <FormattedMessage id="last_used_by.message" />, link: true },
    { field: 'device_status', header: <FormattedMessage id="status_field.message" />, link: false, color: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'serial_number') {
      navigate(`${row.id}/info`);
    } else if (field === 'org_name') {
      navigate(`/organizations/${row.org_id}/info`);
    } else {
      navigate(``);
    }
  };
  return (
    <Box pos="relative" h="calc(100vh - 50px)">
      <CustomTable data={data} columns={columns} route="" handleNavigateFromChild={handleNavigateFromChild} />
    </Box>
  );
}

export default DevicesListView;
