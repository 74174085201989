import {
  Box,
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { CircleCheckIcon, CircleCrossIcon, CircleRightDisabledIcon } from 'assets/icons/icons';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import getOrgNameWithTooltip from 'utils/getTextTooltip';

const CustomDeleteModal = ({
  openModal,
  closeModal,
  modalCloseIconDisabled,
  postFailure,
  enableCreateButton,
  postDataSuccessfulCreation,
  handleModalButtonClick,
  toggleModalButtonEnableSpinner,
  InputFieldsComponent,
  modalHeader,
  modalButtonName,
  modalHeaderPassingName,
  colorMode,
  RightEnabledIcon,
  modalHeaderPassingLastName,
}: any) => {
  const intl = useIntl();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');
  const blueColorMode = useColorModeValue(colorMode, colorMode);

  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      border-radius="0.1px"
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '368px', smd: '700px', md: '700px', lg: '700px', xl: '700px', dt: '700px' }} mt="170px">
        <ModalHeader fontSize="24px" pr="40px" pl="30px">
          <Box>
            <Text fontWeight="400" fontSize={'20px'}>
              {intl.formatMessage({ id: `${modalHeader}.message` })}{' '}
              <span style={{ textTransform: 'capitalize', fontWeight: '900' }}>
                {`${modalHeaderPassingName} ${modalHeaderPassingLastName ? modalHeaderPassingLastName : ' '}?`}
              </span>
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton
          disabled={modalCloseIconDisabled}
          color={closeIconColor}
          data-testid="close-ic"
          mt="4px"
          _hover={{ backgroundColor: 'white' }}
          backgroundColor="transparent"
        />
        <ModalBody ml="7px" backgroundColor="transparent">
          {<InputFieldsComponent />}
        </ModalBody>
        <ModalFooter>
          {modalButtonName == 'provision_modal' && (
            <Button
              variant="outline"
              type="button"
              width="155px"
              height="42px"
              borderRadius="20px"
              fill={blueColorMode}
              color={blueColorMode}
              borderColor={blueColorMode}
              fontSize="12px"
              mr={3}
              _hover={{
                bg: blueColorMode,
                color: 'white',
              }}
              onClick={closeModal}
            >
              <Text letterSpacing={'5px'} textTransform="uppercase" fontWeight="extrabold">
                <FormattedMessage id="cancel.message" />
              </Text>
            </Button>
          )}
          {postFailure ? (
            <Button
              variant="outline"
              type="button"
              width="195px"
              height="42px"
              borderRadius="20px"
              borderColor="#BAC5CD"
              color="#BAC5CD"
              disabled
              fontSize="17px"
              float="right"
              data-testid="org-button-disabled"
              _hover={{ cursor: 'not-allowed' }}
            >
              <Flex>
                <Text
                  fontSize="12px"
                  ml="50px"
                  display="flex"
                  pr="4px"
                  alignItems="center"
                  fontWeight="extrabold"
                  data-testid="org-button-text"
                >
                  <FormattedMessage id="failure.message" />
                </Text>
                <Icon w="30px" h="30px" ml="32px" pr="4px" float="right" data-testid="org-button-icon">
                  <CircleCrossIcon />
                </Icon>
              </Flex>
            </Button>
          ) : (
            <Box>
              {enableCreateButton ? (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  position="relative"
                  color={blueColorMode}
                  cursor={postDataSuccessfulCreation || toggleModalButtonEnableSpinner ? 'not-allowed' : ''}
                  fill={blueColorMode}
                  borderColor={blueColorMode}
                  fontSize="17px"
                  float="right"
                  data-testid="org-button-active"
                  _hover={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  onClick={handleModalButtonClick}
                  _disabled={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  isDisabled={postDataSuccessfulCreation || toggleModalButtonEnableSpinner}
                >
                  <Flex>
                    <Box
                      fontSize="10px"
                      pr="4px"
                      display="flex"
                      alignItems="center"
                      data-testid="org-button-text-diabled"
                    >
                      {postDataSuccessfulCreation ? (
                        <FormattedMessage id="done.message">
                          {(txt) => (
                            <Text fontSize="12px" fontWeight="extrabold">
                              {txt}
                            </Text>
                          )}
                        </FormattedMessage>
                      ) : (
                        <Text fontWeight="extrabold" fontSize="12px">
                          {intl.formatMessage({ id: `${modalButtonName}.message` })}
                        </Text>
                      )}
                    </Box>
                    {toggleModalButtonEnableSpinner ? (
                      <Box>
                        {postDataSuccessfulCreation ? (
                          <Icon
                            w="30px"
                            h="30px"
                            mt="-7.5px"
                            float="right"
                            data-testid="org-button-checkIcon"
                            position="absolute"
                            right="7px"
                          >
                            <CircleCheckIcon />
                          </Icon>
                        ) : (
                          <Box
                            pl="10px"
                            h="40px"
                            mt="-7.5px"
                            w="40px"
                            data-testid="org-button-spinner"
                            position="absolute"
                            right="7px"
                          >
                            <Spinner />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Icon w="30px" h="30px" mt="-7.5px" float="right" position="absolute" right="7px">
                        {<RightEnabledIcon />}
                      </Icon>
                    )}
                  </Flex>
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  borderColor="#BAC5CD"
                  color="#BAC5CD"
                  disabled
                  fontSize="17px"
                  float="right"
                  position="relative"
                  data-testid="org-button-disabled"
                  _hover={{ cursor: 'not-allowed' }}
                >
                  <Flex>
                    <Text
                      fontSize="12px"
                      mr="10px"
                      display="flex"
                      alignItems="center"
                      fontWeight="extrabold"
                      data-testid="org-button-text"
                    >
                      {intl.formatMessage({ id: `${modalButtonName}.message` })}
                    </Text>
                    <Icon
                      w="30px"
                      h="30px"
                      mt="-7.5px"
                      pr="4px"
                      float="right"
                      data-testid="org-button-icon"
                      position="absolute"
                      right="7px"
                    >
                      <CircleRightDisabledIcon />
                    </Icon>
                  </Flex>
                </Button>
              )}
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomDeleteModal;
