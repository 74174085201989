import styled from 'styled-components/macro';

/**
 * Two ways to use this
 *
 * 1. As a standalone component
 * <Icon url={Icons.item} size='24px' />
 *
 * 2. Extending with styled components. Useful if you want to attach other styles
 * styled.Icon`
 *   background-image: url(${Icons.item});
 *   height: 24px;
 *   width: 24px;
 * `
 */

export interface IconProps {
  size?: string;
  width?: string;
  height?: string;
  url?: string;
}

export const Icon = styled.svg<IconProps>`
  width: ${({ width, size }) => width || size || 'auto'};
  height: ${({ height, size }) => height || size || 'auto'};

  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: contain;
`;
