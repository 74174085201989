import { Box, Flex, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getOrgFeatureGroups, updateFeature } from 'redux/orgFeatures/Actions/OrgFeaturesActions';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/loader/Loader';
import FeatureGroupsAccordion from 'pages/softwareBundles/features/FeatureGroupsAccordion';
import { userAccessConst } from 'utils/constants/userAccessConst';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { InformationTextIcon } from 'assets/icons/icons';
import LandingScreen from 'components/landingPage/LandingSreen';
import { handleOrgLicenseModalPopup } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';

const OrgFeatures = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgId = '' } = useParams<string>();
  const orgFeatureGroups = useAppSelector((state) => state.orgFeatures?.orgFeatureGroups);
  const isLoading = useAppSelector((state) => state.orgFeatures.isLoading);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const hasFeatureEditAccess = userPermissions?.organizations?.includes(userAccessConst.organization?.updateFeatures);
  const isOrgFeatureAvailableSuccess = useAppSelector((state) => state.orgFeatures.orgFeatureAvailablitySuccess);
  const isOrgFeatureAvailableFail = useAppSelector((state) => state.orgFeatures.orgFeatureAvailablityFail);
  const isOrgFeatureAvailableLoading = useAppSelector((state) => state.orgFeatures.orgFeatureAvailablityLoading);
  const showEmptyMessage = !isLoading && !orgFeatureGroups?.length;
  const handleModalOpen = () => {
    dispatch(handleOrgLicenseModalPopup(true));
    navigate(`/organizations/${orgId}/licenses/bundles`);
  };
  useEffect(() => {
    dispatch(getOrgFeatureGroups(orgId));
  }, []);

  return (
    <Box h="100%" pl="30px" position="relative">
      <Box>
        <Flex alignItems="center" h="50px" m="15px 20px 15px 0" justifyContent="space-between">
          <Box mr="10px">
            <Text fontWeight="700" fontSize="24px">
              <FormattedMessage id="features.message" />
            </Text>
            {!hasFeatureEditAccess && (
              <Flex alignItems="center" my="10px">
                <InformationTextIcon />
                <Text color="rgba(51, 51, 51, 0.8)" mt="-5px">
                  <FormattedMessage id="feature_info_status.message" />
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
      {showEmptyMessage && (
        <Box data-testid="landing-screen">
          <LandingScreen handleClick={handleModalOpen} title="Features" newCreation={true} onlyTitle={false} />
        </Box>
      )}

      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <FeatureGroupsAccordion
          dispatchCallback={updateFeature}
          orgId={orgId}
          featureGroupsData={orgFeatureGroups}
          hasEditAccess={hasFeatureEditAccess}
          isFeatureAvailableSuccess={isOrgFeatureAvailableSuccess}
          isFeatureAvailableFail={isOrgFeatureAvailableFail}
          isFeatureAvailableLoading={isOrgFeatureAvailableLoading}
        />
      )}
    </Box>
  );
};

export default OrgFeatures;
