/* eslint-disable react/no-children-prop */
import { ChakraProvider, ColorModeScript, Flex, Image } from '@chakra-ui/react';
import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { Routes, Route, useNavigate, BrowserRouter } from 'react-router-dom';
import myNewTheme from './assets/styles/theme';
import LayoutWrapper from './layoutWrapper/LayoutWrapper';
import Analytics from './pages/analytics/Analytics';
import Communication from './pages/communication/Communication';
import CustomersLandingPage from 'pages/customers/customersLandingPage';
import CustomerInfo from 'pages/customers/CustomerInfo';
import { routePath } from './routes/routePaths';
import ProtectedRoutes from './protectedRoute/ProtectedRoutes';
import Login from './pages/login';
import Users from './pages/users/Users';
import OrgInfo from './pages/organizations/components/organizations/OrganizationInfoPages/pages/OrgInfo';
import OrgUsers from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgUsers/OrgUsers';
import OrgDevices from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgDevices/OrgDevices';
import SubLayout from 'subLayoutWrapper/SubLayout';
import OrgDevicesActivity from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgDevicesActivity/OrgDevicesActivity';
import OrgUsersActivity from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgUsersActivity/OrgUsersActivity';
import AddDeviceTypes from 'pages/deviceTypes/deviceTypeLandingPage/AddDeviceTypes';
import Devices from 'pages/devices/Devices';
import SingleUserInfo from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgUsersEdit/OrgUserInfo/SingleUserInfo';
import SingleUserActivity from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgUsersEdit/singleUserActvity/SingleUserActivity';
import SingleOrgDevicesActivity from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgDevicesEdit/SingleDeviceActivity/singleOrgDeviceActivity';
import Dashboard from 'pages/dashboard/Dashboard';
import UnAuthorized from 'pages/error/UnAuthorizedError';

import SingleDeviceInfo from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgDevicesEdit/SingleDeviceInfo/SingleDeviceInfo';
import DeviceSubSayout from 'pages/devices/components/deviceSublayout/DeviceSublayout';

import EditPulseUserFields from 'pages/users/editUserInputFields/EditPulseUserFields';
import EditPulseUserScreen from 'pages/users/editUserInputFields/EditPulseUserScreen';
import SettingsSubSayout from 'pages/settings/SettingsSubLayout';
import ClientAccess from 'pages/settings/clientAccess/ClientAccess';
import EditDeveloperSettingsInfoPage from 'pages/settings/editDeveloperSettings/EditDeveloperSettingsInfopage';
import EditDeveloperSettingsFields from 'pages/settings/editDeveloperSettings/EditDeveloperSettingsFields';
import SoftwareBundleEditScreenInfo from 'pages/softwareBundles/softwareBundleEditScreen/SoftwareBundleEditScreenInfo';
import SoftwareBundleEditScreenInputs from 'pages/softwareBundles/softwareBundleEditScreen/SoftwareBundleEditScreenInputs';
import OrgLicense from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgLicense/OrgLicense';
import FeatureFlagManagement from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/featureFlagManagement/FeatureFlagManagement';
import { SetupInterceptors } from 'utils/setupInterceptors';
import { ExoWorksLoader } from 'assets/icons';
import LicenseLandingScreen from 'pages/softwareBundles/LicenseTabViewLandingScreen/LicenseLandingScreen';
import AddOnInfo from 'pages/softwareBundles/add-ons/addOnInfo';
import FeatureInfo from 'pages/softwareBundles/features/FeatureInfo';
import OrgAddons from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgAddons/OrgAddons';
import OrgFeatures from 'pages/organizations/components/organizations/OrganizationInfoPages/pages/orgFeatures/orgFeatures';

function NavigateFunctionComponent() {
  const navigate = useNavigate();
  const [ran, setRan] = useState(false);
  // only run setup once
  if (!ran) {
    SetupInterceptors(navigate);
    setRan(true);
  }
  return <></>;
}

function App() {
  localStorage.setItem('Theme', myNewTheme.config.initialColorMode);

  const { createUserSessionSuccess, createUserSessionLoading } = useAppSelector((state) => state.login);

  if (createUserSessionLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" marginY="auto" height="100vh">
        <Image src={ExoWorksLoader} />
      </Flex>
    );
  } else {
    return (
      <>
        <ColorModeScript initialColorMode={myNewTheme.config.initialColorMode} />
        <ChakraProvider theme={myNewTheme}>
          <BrowserRouter>
            <NavigateFunctionComponent />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                element={
                  <ProtectedRoutes>
                    <LayoutWrapper />
                  </ProtectedRoutes>
                }
              >
                <Route path={routePath.Dashboard} element={<Dashboard />} />
                <Route path={routePath.Customers} element={<CustomersLandingPage />}></Route>
                <Route path="/customers/:customerId/info" element={<CustomerInfo />}></Route>
                <Route path="/organizations/:orgId" element={<SubLayout />}>
                  <Route path="info" element={<OrgInfo />} />
                  <Route path="users" element={<OrgUsers />}></Route>
                  <Route path="users/:userId/info" element={<SingleUserInfo />} />
                  <Route path="users/:userId/activity" element={<SingleUserActivity />} />
                  <Route path="devices" element={<OrgDevices />} />
                  <Route path="devices/:deviceId/info" element={<SingleDeviceInfo />} />
                  <Route path="devices/:deviceId/activity" element={<SingleOrgDevicesActivity />} />
                  <Route path="users-activity" element={<OrgUsersActivity />} />
                  <Route path="devices-activity" element={<OrgDevicesActivity />} />
                  <Route path="licenses">
                    <Route path="bundles" element={<OrgLicense />} />
                    <Route path="add-ons" element={<OrgAddons />} />
                    <Route path="features" element={<OrgFeatures />} />
                  </Route>
                </Route>
                <Route path={routePath.DeviceModels} element={<AddDeviceTypes />}></Route>
                <Route path="/device-models/:modelId/devices" element={<Devices />}></Route>
                <Route path="/device-models/:modelId/devices/:deviceId" element={<DeviceSubSayout />}>
                  <Route path="info" element={<SingleDeviceInfo />} />
                  <Route path="activity" element={<SingleOrgDevicesActivity />} />
                </Route>
                <Route path={routePath.licenses} element={<LicenseLandingScreen />}></Route>
                <Route path="/licenses/bundles/:bundleId/info" element={<SoftwareBundleEditScreenInfo />}></Route>
                <Route path="licenses/add-ons/:addonId/info" element={<AddOnInfo />}></Route>
                <Route path="licenses/features/:featureId/info" element={<FeatureInfo />}></Route>
                {/* <Route path="/bundles/:bundleId/info" element={<SoftwareBundleEditScreenInfo />}>
                  <Route path="" element={<SoftwareBundleEditScreenInputs />}></Route>
                </Route> */}
                {/* <Route path="bundlesLicenseItems" element={<License />}></Route> */}
                {/* <Route path="bundlesLicenseItems/:licenseId/info" element={<LicenseSublayout />}>
                <Route path="licenseInfo" element={<LicenseInfo />} />
              </Route> */}
                {/* <Route path="bundles/:id/info/" element={<BundleSubSayout />}>
                <Route path="bundleinfo" element={<Bundlelicense />} />
              </Route> */}
                <Route path={routePath.Users} element={<Users />}></Route>
                <Route path="/users/:userId/info" element={<EditPulseUserScreen />}>
                  <Route path="/users/:userId/info" element={<EditPulseUserFields />}></Route>
                  {/* <Route path="/users/:userId/info/activity" element={<EditUserActivity />}></Route> */}
                </Route>
                <Route path={routePath.Analytics} element={<Analytics />}></Route>
                <Route path={routePath.Communication} element={<Communication />}></Route>
                <Route path={routePath.Settings} element={<SettingsSubSayout />}>
                  <Route path="" element={<ClientAccess />} />
                  {/* <Route path="clientAccess" element={<ClientAccess />} />
                  <Route path="apiKeys" element={<ApiKeys />} /> */}
                </Route>
                <Route path="/settings/partners/:partnerId" element={<EditDeveloperSettingsInfoPage />}>
                  <Route path="/settings/partners/:partnerId/info" element={<EditDeveloperSettingsFields />}></Route>
                </Route>
                <Route path={routePath.UnAuthorized} element={<UnAuthorized />}></Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </ChakraProvider>
      </>
    );
  }
}

export default App;
