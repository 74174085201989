/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Box,
  Button,
  CircularProgress,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Wrap,
  WrapItem,
  Circle,
  Stack,
} from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { customColor } from '../../../../../../assets/styles/colors';
import { InputLabel } from '../../../../../../components/InputField';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

import {
  fetchorganizationLanguage,
  getOrganizationInputNameValue,
  fetchEditOrganizationInfoApiFail,
  fetchOrganizationNameFail,
  fetchOrganizationNameStatus,
  fetchSingleOrganizationID,
  fetchOrganizationOwnerEmailError,
  openDeleteModal,
  openProvisionedModal,
  handleDeactivateModal,
  handleActivateModal,
  handleOrgUsersForDeleteStatus,
  handlePausedStatusModal,
} from '../../../../../../redux/organizations/Reducers/organizationSlice';

import {
  getLanguage,
  getOrganizationNameData,
  getSingleOrganizationsData,
  getStatus,
  getTiers,
} from '../../../../../../redux/organizations/Actions/organizationActions';

import OrgInfoOwnerInputs from './OrgInfoOwnerInputs';
import CustomerInfo from './CustomerInfo';
import { editOrganizationsInfoData } from '../../../../../../redux/organizations/Actions/organizationActions';
import { specialCharacterValidation } from '../../../../../../utils/validations/specialCharacterValidation';
import { CircleCheckIconOrg, InformationIcon } from '../../../../../../assets/icons/icons';
import { RootState } from '../../../../../../redux/Store/store';
import { OrgName } from '../../../../../../types/organization-types';
import { useParams } from 'react-router-dom';
import { getStatusAndFlow } from 'utils/statusFlow/statusFlow';
import OrgInfoWarnModal from '../organizationStatusUpdateScreens/OrgInfoDeleteModal';
import OrgInfoProvisionedModal from '../organizationStatusUpdateScreens/OrgInfoProvisionedModal';
import OrgInfoDeactivateModal from '../organizationStatusUpdateScreens/OrgInfoDeactivateModal';
import OrgInfoActivateModal from '../organizationStatusUpdateScreens/OrgInfoActivateModal';
import { getOrganisationInfoUsersData } from 'redux/orgUser/Actions/orguserActions';
import OrgInfoPausedModal from '../organizationStatusUpdateScreens/OrgInfoPausedModal';
import { getUserPageNumber } from 'redux/orgUser/Reducer/orgUserSlice';
import { getDevicePageNumber } from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import { Error_Type_Enum } from '../../../../../../utils/enum';
import { getOrgLicensesDataForStatusHandling } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import CustomToolTip from 'components/customToolTip/CustomToolTip';
import StatusInfo from 'components/status/StatusInfo';
import { getOrganizationDevices } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import { capitalizeText } from '@exo/shared-ui';
import { userAccessConst } from 'utils/constants/userAccessConst';

const OrgInfo = () => {
  const colors = customColor();
  const intl = useIntl();
  const { orgId } = useParams();
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const [isEnabled, setButtonEnabled] = useState<boolean>(false);

  const [inputValues, setInputValues] = useState<{ [x: string]: string }>();
  const singleOrganizationData = useAppSelector((state: RootState) => state.organization.SingleOrganizationsGetData);
  const [country, setCountry] = useState<any>(
    singleOrganizationData && singleOrganizationData.country_code.country_name,
  );
  const [language, setLanguage] = useState<any>(
    singleOrganizationData && singleOrganizationData?.language_code.language_name,
  );
  const singleOrganizationID = useAppSelector((state) => state.organization.singleOrganizationID);
  const [deviceStatusValue, setDeviceStatusValue] = useState<any>(
    singleOrganizationData && singleOrganizationData.status,
  );
  const getDeviceStatusValue = useAppSelector((state) => state.device.deviceStatusInputValue);
  const selectedLanguage = useAppSelector((state) => state.organization.organizationLanguage);
  const orgInputNameValue = useAppSelector((state) => state.organization.organizationInputNameValue);
  const editOrganizationInfoLoading = useAppSelector((state) => state.organization.editOrganizationInfoLoading);
  const editOrganizationInfoApiFail = useAppSelector((state) => state.organization.editOrganizationInfoApiFail);
  const createClientPostApiFail = useAppSelector((state) => state.clientAccess.clientPostDataFailStrings);
  const nameErrorMessage = useAppSelector((state) => state.organization.organizationOwnerEmail);
  const deviceStatusData = useAppSelector((state: RootState) => state.organization.statusData);
  const languageData = useAppSelector((state: any) => state.organization.languageData);
  const orgName = useAppSelector((state) => state.organization.organizationHandleName);
  const orgNameStatus = useAppSelector((state) => state.organization.organizationNameDataError);
  const organizationLincesesList = useAppSelector((state) => state.orgLicense.organizationLincesesList);
  const usersData = useAppSelector((state: RootState) => state.orgUser?.usersApiData?.data);
  const toggleOrgUsersForDeleteStatus = useAppSelector(
    (state: any) => state.organization.toggleOrgUsersForDeleteStatus,
  );
  const orgUsersList = useAppSelector((state: any) => state.orgUser.usersList);
  const organizationDevicesList = useAppSelector((state) => state.organizationdevice.organizationDevicesList);
  const userOrgAccess = userAccessConst.organization;
  const hasOrgEditAccess = userPermissions.organizations?.includes(userOrgAccess.updateOrg);
  const enableProvisionButton =
    singleOrganizationData?.tier?.tier_code == 'connect' ||
    (organizationLincesesList?.length && organizationDevicesList?.length);

  useEffect(() => {
    dispatch(fetchSingleOrganizationID(orgId));
    dispatch(getSingleOrganizationsData(orgId));
  }, [orgId, createClientPostApiFail]);

  const PostOrgName: OrgName = {
    org_name: orgInputNameValue,
    status: undefined,
    success: false,
    message: undefined,
  };
  useEffect(() => {
    dispatch(getStatus());
    dispatch(getLanguage());
    dispatch(getTiers());
    dispatch(fetchSingleOrganizationID(orgId));
    dispatch(getOrganizationDevices(orgId, ''));
    dispatch(getOrganisationInfoUsersData(orgId, ''));
    deviceStatusValue;
  }, []);

  useEffect(() => {
    orgName;
    orgNameStatus;
  }, [orgInputNameValue, orgNameStatus, orgName]);

  const handleLanguage = (e: any) => {
    setLanguage(Number(e.target.value));
    setButtonEnabled(true);
    dispatch(fetchorganizationLanguage(e.target.value));
  };

  const handleInputName = (e: any) => {
    setButtonEnabled(true);
    dispatch(fetchOrganizationOwnerEmailError(false));
    dispatch(getOrganizationInputNameValue(e.target.value));
    specialCharacterValidation;
  };
  useEffect(() => {
    setButtonEnabled(false);
  }, [nameErrorMessage]);

  useEffect(() => {
    setDeviceStatusValue(singleOrganizationData && singleOrganizationData.status);
    setLanguage(singleOrganizationData && singleOrganizationData?.language_code?.id);
    setCountry(singleOrganizationData && singleOrganizationData.country_code.country_name);
  }, [singleOrganizationData]);

  useEffect(() => {
    dispatch(getOrganisationInfoUsersData(orgId, ''));
    dispatch(getUserPageNumber(1));
    dispatch(getDevicePageNumber(1));
    if (organizationLincesesList.length == 0) {
      dispatch(getOrgLicensesDataForStatusHandling(true));
    } else {
      dispatch(getOrgLicensesDataForStatusHandling(false));
    }
  }, []);
  const handleOrgNameChange = () => {
    if (!/^[a-zA-Z0-9.'_ ,+-]*$/.test(orgInputNameValue)) {
      dispatch(fetchOrganizationOwnerEmailError(true));
    }
    dispatch(fetchOrganizationNameFail(false));
    dispatch(fetchOrganizationNameStatus(false));
    if (orgInputNameValue.length > 0 && orgInputNameValue.length < 4) {
      dispatch(fetchOrganizationNameStatus(true));
      dispatch(fetchOrganizationNameFail(false));
    } else if (orgInputNameValue.length > 3) {
      dispatch(getOrganizationNameData(PostOrgName));
      dispatch(fetchOrganizationNameStatus(false));
      dispatch(fetchOrganizationNameFail(false));
    } else if (orgInputNameValue.length == 0) {
      dispatch(fetchOrganizationNameFail(false));
      dispatch(fetchOrganizationNameStatus(false));
    } else {
      dispatch(fetchOrganizationNameFail(false));
      dispatch(fetchOrganizationNameStatus(false));
    }
    specialCharacterValidation;
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setButtonEnabled(true);
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const postInfoData = {
    org_name:
      orgInputNameValue.length > 0
        ? capitalizeText(orgInputNameValue)
        : capitalizeText(singleOrganizationData?.org_name),
    language_code: Number(selectedLanguage.length > 0 ? selectedLanguage : singleOrganizationData?.language_code?.id),
    status: Number(
      getDeviceStatusValue && getDeviceStatusValue.length > 0 ? getDeviceStatusValue : singleOrganizationData?.status,
    ),
  };
  const StatusText = (value: any) => {
    return (
      <Text fontSize="14px" color={colors.copyRightColor}>
        <FormattedMessage id={`Org_Info.${value}_status_text.message`} values={{ br: <br /> }} />
      </Text>
    );
  };

  useEffect(() => {
    if (editOrganizationInfoApiFail === 'SUCCESS') {
      setTimeout(() => {
        dispatch(fetchEditOrganizationInfoApiFail(''));
        dispatch(getSingleOrganizationsData(orgId));
        setButtonEnabled(false);
      }, 1000);
    }
  }, [editOrganizationInfoApiFail]);

  const handleEditOrganization = () => {
    dispatch(editOrganizationsInfoData(postInfoData, singleOrganizationID));
  };

  useEffect(() => {
    getTiers();
  }, [singleOrganizationData]);

  const a: any = getStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.status_id == deviceStatusValue)?.status_name,
  ).action;

  const b: any = getStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.status_id == deviceStatusValue)?.status_name,
  ).status;
  const c: any = getStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.status_id == deviceStatusValue)?.status_name,
  ).actionPast;

  const d: any = getStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.status_id == deviceStatusValue)?.status_name,
  ).statusPast;
  const e: any = getStatusAndFlow(
    deviceStatusData && deviceStatusData.find((item: any) => item.status_id == deviceStatusValue)?.status_name,
  ).statusButtonText;

  const handleProvisionClick = () => {
    if (b == 'Active') {
      dispatch(handleActivateModal(true));
    }
    if (b == 'Provision') {
      dispatch(openProvisionedModal(true));
    }
    if (b == 'Pause') {
      dispatch(handlePausedStatusModal(true));
    }
    if (b == 'Deactivate') {
      dispatch(handleDeactivateModal(true));
    }
  };

  const handleDelete = () => {
    if (a == 'Deactivate') {
      dispatch(handleDeactivateModal(true));
    }
    if (a == 'Delete') {
      dispatch(openDeleteModal(true));
      dispatch(handleOrgUsersForDeleteStatus(false));
    }
  };
  useEffect(() => {
    const hasActiveUsers = orgUsersList.find((item: any) => item.status?.id == 1);
    dispatch(handleOrgUsersForDeleteStatus(hasActiveUsers ? true : false));
  }, [orgUsersList]);

  return (
    <>
      <Box height="100%" overflow="auto" paddingBottom="50px">
        {singleOrganizationData ? (
          <Box pl="18px">
            <Box>
              <Wrap display="flex" spacing="40px">
                <WrapItem paddingTop="14px">
                  <Box>
                    <Text fontWeight="700" fontSize="24px" minWidth={305}>
                      Information
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem paddingTop="14px" minWidth={305} flexDirection="row-reverse">
                  <Wrap display="flex">
                    <WrapItem>
                      <Box ml="5px">
                        {hasOrgEditAccess && (
                          <Button
                            isLoading={editOrganizationInfoLoading}
                            width="82px"
                            h="24px"
                            border={isEnabled ? '1px solid #333333' : '1px solid #24B0FF'}
                            borderRadius="15px"
                            borderColor={isEnabled ? '#24B0FF' : '#333333'}
                            color={isEnabled ? '#24B0FF' : '#333333'}
                            loadingText="Save"
                            colorScheme="teal"
                            variant="outline"
                            type="button"
                            spinnerPlacement="end"
                            onClick={handleEditOrganization}
                            isDisabled={!isEnabled}
                            fontSize="14px"
                            fontWeight="500"
                          >
                            <Box display="flex" alignItems="center">
                              <Text fontSize="14px" fontWeight="500" color={isEnabled ? '#24B0FF' : '#333333'}>
                                Save
                              </Text>

                              {editOrganizationInfoApiFail === Error_Type_Enum.Success && (
                                <Icon w="20px" pl="4px" h="20px">
                                  <CircleCheckIconOrg />
                                </Icon>
                              )}
                            </Box>
                          </Button>
                        )}
                      </Box>
                    </WrapItem>
                  </Wrap>
                </WrapItem>
              </Wrap>
            </Box>
            <Box mt="15px">
              <Wrap display="flex" spacing="40px" minH="60px">
                <WrapItem>
                  <Box minH="60px">
                    <InputLabel value="-" name="customer_name" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                      {singleOrganizationData?.customer?.customer_name}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box minH="60px">
                    <InputLabel value="-" name="customer_id" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                      {/* To update */}
                      {singleOrganizationData?.customer?.customer_id}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px">
                <WrapItem>
                  <Box minH="60px" minWidth={305}>
                    <InputLabel value="-" name="license_type" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2}>
                      {singleOrganizationData.tier.tier_name}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px">
                <WrapItem>
                  <Box minH="60px" minWidth={305}>
                    <InputLabel value="-" name="org_name" />
                    {hasOrgEditAccess ? (
                      <Box mb="30px">
                        <Input
                          minLength={4}
                          fontSize="14px"
                          textTransform="capitalize"
                          key={singleOrganizationData?.id}
                          color={colors.subTextColor}
                          className="form-input"
                          defaultValue={singleOrganizationData.org_name}
                          name="name"
                          width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                          onChange={handleInputName}
                          placeholder={intl.formatMessage({ id: 'name.message' })}
                          borderColor="#E0E0E0"
                          onBlur={handleOrgNameChange}
                          focusBorderColor={colors.primaryColor}
                          borderBottomColor={colors.inputBorderColor}
                          borderWidth="0 0 1px 0"
                          type="text"
                          onKeyPress={specialCharacterValidation}
                          variant="flushed"
                          required
                          maxLength={30}
                          data-testid="info-orginfo-name"
                        />
                        <Text fontSize="12px" color="#FB5654">
                          {nameErrorMessage ? <FormattedMessage id="enter_valid_characters.message" /> : ''}
                        </Text>
                      </Box>
                    ) : (
                      <Text fontWeight="400" className="truncatee" fontSize="14px" marginTop={2}>
                        {singleOrganizationData.org_name}
                      </Text>
                    )}
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box minH="60px" minWidth={305}>
                    <InputLabel value={language} name="language" />
                    {hasOrgEditAccess ? (
                      <Menu>
                        {({ isOpen }) => (
                          <Box mb="30px">
                            <MenuButton
                              isActive={isOpen}
                              as={Button}
                              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                              borderBottom="1px solid"
                              _focusVisible={{ borderBottomColor: colors.primaryColor }}
                              borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                              borderRadius="0px"
                              variant="flushed"
                              data-testid="info-orginfo-language"
                              rightIcon={
                                isOpen ? (
                                  <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                                ) : (
                                  <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                                )
                              }
                            >
                              {language ? (
                                <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400">
                                  {languageData && languageData.find((item: any) => item.id == language)?.language_name}
                                </Text>
                              ) : (
                                <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                                  <FormattedMessage id="language.message" />
                                </Text>
                              )}
                            </MenuButton>
                            <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} h="200px" overflowY="scroll">
                              {languageData &&
                                languageData.map((state: any) => (
                                  <MenuItem
                                    key={state.id}
                                    value={state.id}
                                    name={state.language_name}
                                    fontSize="14px"
                                    backgroundColor="transparent"
                                    _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                                    onClick={handleLanguage}
                                  >
                                    {state.language_name}
                                  </MenuItem>
                                ))}
                            </MenuList>
                          </Box>
                        )}
                      </Menu>
                    ) : (
                      <Text fontWeight="400" className="truncatee" fontSize="14px" marginTop={2}>
                        {languageData && languageData.find((item: any) => item.id == language)?.language_name}
                      </Text>
                    )}
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px">
                <WrapItem>
                  <Box minH="60px" minWidth={305}>
                    <InputLabel value="-" name="organization_code" />
                    <Text fontWeight="400" fontSize="14px" className="truncatee" marginTop={2}>
                      {singleOrganizationData.org_code}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box minH="60px" minWidth={305}>
                    <InputLabel
                      value={
                        singleOrganizationData.org_id ? singleOrganizationData.org_id : singleOrganizationData.org_name
                      }
                      name="organization_id"
                    />
                    <Text fontWeight="400" fontSize="14px" className="truncatee" marginTop={2}>
                      {singleOrganizationData.org_id ? singleOrganizationData.org_id : singleOrganizationData.org_name}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="20px">
                <WrapItem>
                  <Box minH="60px" minWidth={305}>
                    <InputLabel value={country} name="country" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2}>
                      {singleOrganizationData?.country_code?.country_name}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>

            <Box>
              <OrgInfoOwnerInputs inputValues={inputValues} handleInputChange={handleInputChange} />
            </Box>

            <CustomerInfo />

            {(a.length > 0 || b.length > 0) &&
            userPermissions.organizations?.includes(userOrgAccess.updateOrgStatus) ? (
              <Box>
                <Text fontSize="18px" fontWeight="700">
                  Additional Action
                </Text>
              </Box>
            ) : (
              ''
            )}
            <Box
              width="650px"
              display="flex"
              flexDirection={b == 'Provision' || a == 'Register' ? 'column-reverse' : 'column'}
            >
              {b.length > 0 ? (
                <>
                  {userPermissions.organizations?.includes(userOrgAccess.updateOrgStatus) && (
                    <Box>
                      <Text fontSize="12px" color={colors.tableHeadColor} mt="20px" fontWeight="700">
                        {b == 'Active' ? 'Activate' : b} <FormattedMessage id="organization.message" />
                        {b == 'Provision' && !enableProvisionButton && (
                          <CustomToolTip
                            label={intl.formatMessage({ id: 'provisioned_info_status.message' })}
                            icon={<InformationIcon color="#FB5654" />}
                            tooltipPlacement="right"
                          />
                        )}
                      </Text>
                      <Box display="flex" justifyContent="space-between">
                        <Box width="80%">{StatusText(b)}</Box>
                        <Box>
                          <Button
                            variant="outline"
                            borderRadius="15px"
                            height="30px"
                            fontSize="12px"
                            isDisabled={!enableProvisionButton}
                            width="100px"
                            borderColor={
                              !enableProvisionButton
                                ? '#858585'
                                : b == 'Pause'
                                ? '#F19D43'
                                : '#FB5654' || b == 'Deactivate'
                                ? '#24B0FF'
                                : '#FB5654'
                            }
                            _hover={{
                              background: !enableProvisionButton
                                ? ''
                                : b == 'Pause'
                                ? '#F19D43'
                                : '#FB5654' || b == 'Deactivate'
                                ? '#24B0FF'
                                : '#FB5654',
                              color: !enableProvisionButton ? '#858585' : 'white',
                            }}
                            color={
                              !enableProvisionButton
                                ? '#858585'
                                : b == 'Pause'
                                ? '#F19D43'
                                : '#FB5654' || b == 'Deactivate'
                                ? '#24B0FF'
                                : '#FB5654'
                            }
                            onClick={handleProvisionClick}
                          >
                            {e ? e : b}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                ''
              )}
              {a == 'Delete' ? (
                <>
                  {userPermissions.organizations?.includes(userOrgAccess.deleteOrg) && (
                    <Box>
                      <Text fontSize="12px" color={colors.tableHeadColor} mt="20px" fontWeight="700">
                        {a} <FormattedMessage id="organization.message" />
                        {organizationDevicesList?.length || toggleOrgUsersForDeleteStatus ? (
                          <CustomToolTip
                            label={intl.formatMessage({ id: 'delete_info_status.message' })}
                            icon={<InformationIcon color="#FB5654" />}
                            tooltipPlacement="right"
                          />
                        ) : (
                          ''
                        )}
                      </Text>
                      <Box display="flex" justifyContent="space-between">
                        <Box width="80%">{StatusText('Delete')}</Box>
                        <Box>
                          <Button
                            variant="outline"
                            borderRadius="15px"
                            height="30px"
                            width="100px"
                            fontSize="12px"
                            isDisabled={organizationDevicesList?.length > 0 || toggleOrgUsersForDeleteStatus}
                            borderColor={
                              toggleOrgUsersForDeleteStatus || organizationDevicesList?.length ? '#858585' : '#FB5654'
                            }
                            color={
                              toggleOrgUsersForDeleteStatus || organizationDevicesList?.length ? '#858585' : '#FB5654'
                            }
                            onClick={handleDelete}
                            _hover={{
                              background:
                                toggleOrgUsersForDeleteStatus || organizationDevicesList?.length
                                  ? '#858585'
                                  : '#FB5654',
                              color: 'white',
                            }}
                            ml="100px"
                          >
                            {a}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {a.length > 0 && userPermissions.organizations?.includes(userOrgAccess.updateOrgStatus) ? (
                    <>
                      <Box>
                        <Text fontSize="12px" color={colors.tableHeadColor} mt="20px" fontWeight="700">
                          {a} <FormattedMessage id="organization.message" />
                        </Text>
                        <Box display="flex" mt="10px" justifyContent="space-between">
                          <Box width="80%">
                            <Text fontSize="14px" color={colors.copyRightColor}>
                              {a === 'Deactivate' ? (
                                <FormattedMessage id="deactivate_status_change_info_warning.message" />
                              ) : (
                                <FormattedMessage id="status_info_warning.message" values={{ status: a }} />
                              )}
                            </Text>
                          </Box>
                          <Box>
                            <Button
                              variant="outline"
                              borderRadius="15px"
                              height="30px"
                              fontSize="12px"
                              width="100px"
                              borderColor={'#FB5654'}
                              color={'#FB5654'}
                              onClick={handleDelete}
                              _hover={{ background: '#FB5654', color: 'white' }}
                            >
                              {a}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Box>
            <OrgInfoWarnModal />
            <OrgInfoProvisionedModal />
            <OrgInfoDeactivateModal />
            <OrgInfoActivateModal />
            <OrgInfoPausedModal />
          </Box>
        ) : (
          <Box position="relative" h="100%" display="flex" alignItems="center" justifyContent="center">
            <AbsoluteCenter p="4" axis="both">
              <CircularProgress isIndeterminate size="50px" />
            </AbsoluteCenter>
          </Box>
        )}
      </Box>
    </>
  );
};
export default OrgInfo;
