import React from 'react';
import Login from './Login/Login';
import myNewTheme from '../assets/styles/theme';
import { ColorModeScript, ChakraProvider } from '@chakra-ui/react';
import './styles.scss';

type LoginFormProps = {
  handleLogin: (idToken: string | undefined) => void;
  baseUrl: string | undefined;
  allowPasswordReset: boolean;
};
export const LoginForm = ({ handleLogin, baseUrl, allowPasswordReset }: LoginFormProps) => {
  localStorage.setItem('Theme', myNewTheme.config.initialColorMode);

  return (
    <>
      <ColorModeScript initialColorMode={myNewTheme.config.initialColorMode} />
      <ChakraProvider theme={myNewTheme}>
        <Login handleLogin={handleLogin} baseUrl={baseUrl} allowPasswordReset={allowPasswordReset} />
      </ChakraProvider>
    </>
  );
};

export default LoginForm;
