import {
  Box,
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { deviceTypePostCreateButton } from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  customColor,
  CircleCrossIcon,
  CircleCheckIcon,
  CircleRightEnabledIcon,
  CircleRightDisabledIcon,
} from '../../../../../../libs/shared-ui/src/lib/assets';
import { Error_Type_Enum } from 'utils/enum';
import {
  editPulsePostData,
  fetchPulseUserCreateButtonEnable,
  fetchUserPulseEditSuccessCreation,
  getPageNumber,
  handleUserEditModal,
  resetUserValidationValues,
} from 'redux/user/Reducers/userSlice';

import {
  getOrganizationOwnerPhoneValidation,
  getOrganizationOwnerPhoneInputValue,
  checkPhoneNumberValidation,
} from 'redux/organizations/Reducers/organizationSlice';
import { fetchDeviceCreateButtonEnable } from 'redux/devices/Reducers/deviceSlice';
import { getUsers } from 'redux/user/Actions/userActions';

const EditUserModal = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const { onClose } = useDisclosure();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const openUserEditModal = useAppSelector((state) => state.user.userEditModal);
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');

  const postData = useAppSelector((state) => state.deviceTypes.postDeviceTypeFail);
  const handleButton = useAppSelector((state) => state.user.createEditButtonEnable);
  const successCreation = useAppSelector((state) => state.user.editUserSuccessCreation);
  const toggleSubmitButton = useAppSelector((state) => state.deviceTypes.deviceTypeCreateButtonEnable);
  const editUserApiFail = useAppSelector((state) => state.user.editUserPostDataFail);
  const handleCreateDevicePopUp = useAppSelector((state) => state.deviceTypes.handleDeviceTypeEditModal);

  const handleDeviceTypeModalClose = () => {
    dispatch(handleUserEditModal(false));
    dispatch(resetUserValidationValues());
    dispatch(fetchPulseUserCreateButtonEnable(false));
    dispatch(getOrganizationOwnerPhoneValidation(false));
    dispatch(getOrganizationOwnerPhoneInputValue(''));
    dispatch(checkPhoneNumberValidation(false));
  };

  const handleClick = () => {
    dispatch(fetchDeviceCreateButtonEnable(true));
    dispatch(editPulsePostData(true));
    dispatch(deviceTypePostCreateButton(true));
    setTimeout(() => dispatch(fetchUserPulseEditSuccessCreation(true)), 1000);
    if (handleCreateDevicePopUp == false) {
      dispatch(fetchUserPulseEditSuccessCreation(false));
    }
  };

  useEffect(() => {
    if (editUserApiFail === Error_Type_Enum.Fail) {
    } else if (editUserApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetUserValidationValues());
        dispatch(handleUserEditModal(false));
        dispatch(getUsers(''));
        dispatch(getPageNumber(1));
        dispatch(deviceTypePostCreateButton(false));
        onClose();
      }, 3300);
    }
  }, [editUserApiFail]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openUserEditModal}
      onClose={handleDeviceTypeModalClose}
      border-radius="0.1px"
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '368px', smd: '700px', md: '700px', lg: '700px', xl: '700px', dt: '700px' }}>
        <ModalHeader fontSize="24px" ml="8px">
          <FormattedMessage id="edit_pulse_user.message" />
        </ModalHeader>
        <ModalCloseButton disabled={!postData && toggleSubmitButton} color={closeIconColor} data-testid="close-ic" />
        <ModalBody ml="7px" backgroundColor="transparent"></ModalBody>
        <ModalFooter backgroundColor="transparent" zIndex={-9999}>
          {postData ? (
            <Button
              variant="outline"
              type="button"
              width="195px"
              height="42px"
              borderRadius="20px"
              borderColor="#BAC5CD"
              color="#BAC5CD"
              disabled
              fontSize="17px"
              float="right"
              data-testid="org-button-disabled"
              _hover={{ cursor: 'not-allowed' }}
            >
              <Flex>
                <Text
                  fontSize="12px"
                  ml="50px"
                  display="flex"
                  alignItems="center"
                  fontWeight="extrabold"
                  data-testid="org-button-text"
                >
                  <FormattedMessage id="failure.message" />
                </Text>
                <Icon w="30px" h="30px" ml="32px" float="right" data-testid="org-button-icon">
                  <CircleCrossIcon />
                </Icon>
              </Flex>
            </Button>
          ) : (
            <Box>
              {handleButton ? (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  color={blueColorMode}
                  cursor={successCreation ? 'not-allowed' : ''}
                  fill={blueColorMode}
                  borderColor={blueColorMode}
                  fontSize="17px"
                  float="right"
                  data-testid="create-button-active"
                  _hover={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  onClick={handleClick}
                  _disabled={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  isDisabled={successCreation}
                >
                  <Flex>
                    <Box
                      fontSize="10px"
                      ml="50px"
                      display="flex"
                      alignItems="center"
                      data-testid="org-button-text-diabled"
                    >
                      {successCreation ? (
                        <FormattedMessage id="done.message">
                          {(txt) => (
                            <Text fontSize="12px" fontWeight="extrabold" ml="17px">
                              {txt}
                            </Text>
                          )}
                        </FormattedMessage>
                      ) : (
                        <FormattedMessage id="save.message">
                          {(txt) => (
                            <Text fontWeight="extrabold" fontSize="12px">
                              {txt}
                            </Text>
                          )}
                        </FormattedMessage>
                      )}
                    </Box>
                    {toggleSubmitButton ? (
                      <Box>
                        {successCreation ? (
                          <Icon w="30px" h="30px" ml="40px" float="right" data-testid="org-button-checkIcon">
                            <CircleCheckIcon />
                          </Icon>
                        ) : (
                          <Box ml="30px" pl="20px" h="40px" w="40px" mt="13px" data-testid="org-button-spinner">
                            <Spinner />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Icon w="30px" h="30px" ml="52px" float="right">
                        <CircleRightEnabledIcon />
                      </Icon>
                    )}
                  </Flex>
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  borderColor="#BAC5CD"
                  color="#BAC5CD"
                  disabled
                  fontSize="17px"
                  float="right"
                  data-testid="org-button-disabled"
                  _hover={{ cursor: 'not-allowed' }}
                >
                  <Flex>
                    <Text
                      fontSize="12px"
                      ml="50px"
                      display="flex"
                      alignItems="center"
                      fontWeight="extrabold"
                      data-testid="org-button-text"
                    >
                      <FormattedMessage id="save.message" />
                    </Text>
                    <Icon w="30px" h="30px" ml="52px" float="right" data-testid="org-button-icon">
                      <CircleRightDisabledIcon />
                    </Icon>
                  </Flex>
                </Button>
              )}
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
