import React, { useEffect } from 'react';
import DeviceDateUdidInputs from './DeviceDateUdidInputs';
import DeviceNameIdInputs from './DeviceNameIdInputs';
import DeviceSerialStatusInputs from './DeviceSerialStatusInputs';
import DeviceTypeCatalogueInputs from './DeviceTypeCatalogueInputs';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { postDevicesData } from 'redux/devices/Actions/deviceActions';
import { fetchDeviceTypeCatalogueValue, getDeviceCreateButtonValue } from 'redux/devices/Reducers/deviceSlice';
import ScannerField from './ScannerField';
import { getDeviceCreateFromOrg } from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import { useLocation } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const DeviceInputFields = () => {
  const dispatch = useAppDispatch();
  const postDeviceToggle = useAppSelector((state) => state.device.postDeviceToggle);
  const buttonClick = useAppSelector((state) => state.device.deviceCreateButtonEnable);
  const organizationPostDataLoading = useAppSelector((state) => state.organization.organizationPostDataLoading);
  const serialNumber = useAppSelector((state) => state.device.getDeviceSerialInputNumber);
  const udidNumber = useAppSelector((state) => state.device.getDeviceUDIDInputNumber);
  const statusValue = useAppSelector((state) => state.device.deviceStatusInputValue);
  const serialNumberLength = useAppSelector((state) => state.device.getDeviceSerialNumberLength);
  const serialNumberValidation = useAppSelector((state) => state.device.getDeviceSerialNumberFail);
  const orgIdMinLength = useAppSelector((state) => state.device.getDeviceIDNumberLength);
  const udidNumberLength = useAppSelector((state) => state.device.getDeviceUDIDNumberLength);
  const postDeviceData = useAppSelector((state) => state.device.postDeviceData);
  const deviceManufacturedYear = useAppSelector((state) => state.device.getDeviceManufacturedYear);
  const selectedDay = useAppSelector((state) => state.device.getDeviceManufacturedDay);
  const datePicker = useAppSelector((state) => state.device.deviceDatePicker);
  const getDeviceIDInputValue = useAppSelector((state) => state.device.getDeviceIDInputValue);
  const getDeviceStatusInputValue = useAppSelector((state) => state.device.deviceStatusInputValue);
  const getDeviceCatalogueValue = useAppSelector((state) => state.device.getDeviceCatalogueValue);
  const getOrgNamePostValue = useAppSelector((state) => state.device.orgNamePostValue);
  const orgNameInputLength = useAppSelector((state) => state.device.orgNameInputLength);
  const orgNameSearchFail = useAppSelector((state) => state.device.orgNameSearchFail);

  const deviceCreateFromOrg = useAppSelector((state) => state.organizationdevice.deviceCreateFromOrg);
  const deviceTypesData = useAppSelector((state) => state.device.deviceTypesData);
  const devicesTypeValue = useAppSelector((state) => state.device.getDeviceTypeValue);

  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);

  useEffect(() => {
    const object = deviceTypesData.find((obj: any) => obj.device_name === devicesTypeValue);
    dispatch(fetchDeviceTypeCatalogueValue(object?.id));
  }, [devicesTypeValue]);
  const date = new Date();
  const postData: any = {
    org_id: parseFloat(getOrgNamePostValue),
    serial_number: serialNumber,
    order_id: getDeviceIDInputValue,
    udid: udidNumber,
    manufacture_date: `${deviceManufacturedYear}-${datePicker}-${selectedDay}T00:00:00.000000Z`,
    last_used_by: '',
    last_used_on: date,
    catalogue: parseFloat(getDeviceCatalogueValue),
    device_status: Number(getDeviceStatusInputValue) ? Number(getDeviceStatusInputValue) : 8,
  };

  function SubmitButton() {
    if (
      serialNumber &&
      udidNumber &&
      deviceManufacturedYear &&
      serialNumberLength == false &&
      serialNumberValidation == false &&
      orgIdMinLength == false &&
      udidNumberLength == false &&
      orgNameInputLength == false &&
      orgNameSearchFail == false &&
      devicesTypeValue
    ) {
      return dispatch(getDeviceCreateButtonValue(true));
    } else if (
      serialNumberLength == true ||
      serialNumberValidation == true ||
      orgIdMinLength == true ||
      udidNumberLength == true ||
      orgNameInputLength == true ||
      orgNameSearchFail == true
    ) {
      return dispatch(getDeviceCreateButtonValue(false));
    } else {
      return dispatch(getDeviceCreateButtonValue(false));
    }
  }
  useEffect(() => {
    SubmitButton();
  }, [
    serialNumber,
    udidNumber,
    devicesTypeValue,
    statusValue,
    deviceManufacturedYear,
    serialNumberLength,
    serialNumberValidation,
    orgIdMinLength,
    udidNumberLength,
    orgNameInputLength,
    orgNameSearchFail,
  ]);
  useEffect(() => {
    if (!postDeviceToggle && postDeviceData && !organizationPostDataLoading) {
      dispatch(
        postDevicesData(
          singleOrganizationData
            ? { ...postData, org_name: singleOrganizationData.org_name, org_id: singleOrganizationData.id }
            : postData,
        ),
      );
    }
  }, [buttonClick]);
  const location = useLocation();
  useEffect(() => {
    if (singleOrganizationData && location.pathname.includes('organizations')) {
      dispatch(getDeviceCreateFromOrg(true));
    } else {
      dispatch(getDeviceCreateFromOrg(false));
    }
  }, []);
  return (
    <>
      {deviceCreateFromOrg && (
        <Box display="flex" flexDirection="column" paddingBottom="20px">
          <Text paddingBottom="8px" fontSize="13px">
            <FormattedMessage id="Organization_name.message" />
          </Text>{' '}
          <Text fontSize="17px"> {singleOrganizationData?.org_name} </Text>
        </Box>
      )}
      <ScannerField /> <DeviceTypeCatalogueInputs /> <DeviceSerialStatusInputs />
      <DeviceDateUdidInputs /> <DeviceNameIdInputs />{' '}
    </>
  );
};
export default DeviceInputFields;
