import React from 'react';
import {  useAppSelector } from 'redux/hooks';
import LandingScreen from 'components/landingPage/LandingSreen';
import { Box } from '@chakra-ui/react';
import { RootState } from 'redux/Store/store';
import Loader from 'components/loader/Loader';

export default function OrgUserLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {

  const isLoading = useAppSelector((state:RootState)=>state.orgUser.usersListLoading)
  const handleModalOpen = () => {
    // to handle add user
  };
  return (
    <Box data-testid="landing-screen">
      <Loader isLoading={isLoading} />
     {!isLoading &&  <LandingScreen handleClick={handleModalOpen} onlyTitle={true} title="Organization Users" isFiltersApplied={isFiltersApplied}  />}
    </Box>
  );
}
