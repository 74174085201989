/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';

import { Dispatch } from 'react';
import {
  fetchOrganizationSingleDeviceActivityLoading,
  fetchOrganizationSingleDeviceActivitySuccess,
  fetchOrganizationSingleDeviceActivityFail,
} from 'redux/singleOrgDeviceActivity/Reducers/singleOrgDevicesActivitySlice';
import { setError } from '../../error/errorSlice';
export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getSingleOrganizationDevicesActivity =
  (orgId: any, deviceId: any, queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchOrganizationSingleDeviceActivityLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      // TODO : need to added device activity action under device redux folder based on the org ID check we are two separate APIS
      const request = orgId
        ? userRequest.get(`organizations/${orgId}/provisioning/device/${deviceId}/activity/?${queryString}`)
        : userRequest.get(`devices/${deviceId}/activity-list/?${queryString}`);

      const res = await request;
      if (res && res.status === 200) {
        dispatch(fetchOrganizationSingleDeviceActivitySuccess(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchOrganizationSingleDeviceActivityFail());
    }
  };
