/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';
import { LicenseApiResponse, License } from '../../../types/organization-types';
import { PaginationTypes } from 'types/pagination-types';
type InitialState = {
  searchText: string;
  licenseList: License[];
  statusLoading: boolean;
  statusError: boolean;
  licenseListLoading: boolean;
  licenseError: boolean;
  createLicenseApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  LicenseApiData: LicenseApiResponse | null;
  licensenumberOfPages: number;
  licensepaginationData: PaginationTypes | null;
  licenseDataLoading: boolean;
  licenseDataError: boolean;
  licenseGetData: License[];
  licenseGetDataLoading: boolean;
  licenseGetDataError: boolean;

  isSearchExpand: boolean;
  selectedOrderValue: number;
  licenseGridView: boolean;
  openLicenseModal: boolean;
  licenseStatusValue: string;
  licenseStatusData: any;
  gracePeriodValue: any;
  licenseGraceData: any;
  catelogFieldError: any;
  licenseCatelogValue: any;
  licenseNameError: any;
  licenseNameValue: any;
  licenseStatusError: any;
  licenseExpirationError: any;
  licenseExpirationValue: any;
  licenseCreateButtonEnableValue: any;
  description: string;
  postLicenseLoading: boolean;
  postLicenseSucc: boolean;
  postLicenseFail: boolean;
  spinnerEnable: boolean;
  singleLicenseData: any;
  singleLicenseDataLoading: boolean;
  singleLicenseDataFail: boolean;
  enableEditSave: boolean;
  isCreate: boolean;
  isEnabled: boolean;
  uniqueName: boolean;
  uniqueNameLoading: boolean;
  uniqueNameFail: boolean;
  uniqueCatelog: boolean;
  uniqueCatelogLoading: boolean;
  uniqueCatelogFail: boolean;

  updateLicenseLoading: boolean;
  updateLicenseSucc: boolean;
  updateLicenseFail: boolean;
  getBundleEditDatePickerDate: string | number;
  getBundleEditDatePickerDay: string | number;
  getBundleEditDatePickerYear: string | number;
  dateChanged: boolean;
};
export const initialState: InitialState = {
  searchText: '',
  licenseList: [],
  statusLoading: false,
  statusError: false,
  licenseListLoading: false,
  licenseError: false,
  selectedPage: 1,
  createLicenseApiFail: '',
  LicenseApiData: null,
  licensenumberOfPages: 1,
  filterData: { status_id: [] },
  licensepaginationData: null,
  licenseDataLoading: false,
  licenseDataError: false,
  licenseGetData: [],
  licenseGetDataLoading: false,
  licenseGetDataError: false,
  isSearchExpand: false,
  selectedOrderValue: 0,
  licenseGridView: true,
  openLicenseModal: false,
  licenseStatusValue: '',
  licenseStatusData: null,
  gracePeriodValue: '',
  licenseGraceData: null,
  catelogFieldError: false,
  licenseCatelogValue: false,
  licenseNameError: false,
  licenseNameValue: false,
  licenseStatusError: false,
  licenseExpirationError: false,
  licenseExpirationValue: false,
  licenseCreateButtonEnableValue: false,
  description: '',
  postLicenseLoading: false,
  postLicenseSucc: false,
  postLicenseFail: false,
  spinnerEnable: false,
  singleLicenseData: null,
  singleLicenseDataLoading: false,
  singleLicenseDataFail: false,
  enableEditSave: false,
  isCreate: true,
  isEnabled: false,
  uniqueName: false,
  uniqueNameLoading: false,
  uniqueNameFail: false,
  uniqueCatelog: false,
  uniqueCatelogLoading: false,
  uniqueCatelogFail: false,
  updateLicenseLoading: false,
  updateLicenseSucc: false,
  updateLicenseFail: false,
  getBundleEditDatePickerDate: '',
  getBundleEditDatePickerDay: '',
  getBundleEditDatePickerYear: '',
  dateChanged: false,
};
const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    toggleLicenseView: (state, { payload }) => {
      state.licenseGridView = payload;
    },
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getLicenseFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    getLicensePageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchCreateLicenseApiFail: (state, { payload }) => {
      state.createLicenseApiFail = payload;
    },
    fetchLicenseLoading: (state) => {
      state.licenseListLoading = true;
    },
    fetchLicenseFail: (state) => {
      state.licenseError = true;
      state.licenseListLoading = false;
    },
    fetchLicenseSuccess: (state, { payload }) => {
      state.licenseList = payload.data;
      state.LicenseApiData = payload;
      state.licensenumberOfPages = payload.pagination.num_pages;
      state.licensepaginationData = payload.pagination;
      state.licenseListLoading = false;
    },
    fetchlicenseDataLoading: (state) => {
      state.licenseGetDataLoading = true;
      state.licenseGetDataError = false;
    },
    fetchlicenseDataSuccess: (state, { payload }) => {
      state.licenseGetData = payload;
      state.licenseGetDataLoading = false;
      state.licenseGetDataError = false;
    },
    fetchlicenseDataFail: (state) => {
      state.licenseGetDataError = true;
      state.licenseGetDataLoading = false;
    },

    getLicenseFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
    handleCreateLicenseModal: (state, { payload }) => {
      state.openLicenseModal = payload;
    },
    getLicenseStatusValue: (state, { payload }) => {
      state.licenseStatusValue = payload;
    },
    getLicenseExpirationValue: (state, { payload }) => {
      state.licenseExpirationValue = payload;
    },
    fetchLicensesGracePeriodLoading: (state) => {
      state.licenseGraceData = [];
    },
    fetchLicensesGracePeriodFail: (state, { payload }) => {
      state.licenseGraceData = payload;
    },
    fetchLicensesGracePeriodSucc: (state, { payload }) => {
      state.licenseGraceData = payload;
    },
    checkCatelogError: (state, { payload }) => {
      state.catelogFieldError = payload;
    },
    getLicenseCatelogValue: (state, { payload }) => {
      state.licenseCatelogValue = payload;
    },
    checkLicenseNameError: (state, { payload }) => {
      state.licenseNameError = payload;
    },
    getLicenseNameValue: (state, { payload }) => {
      state.licenseNameValue = payload;
    },
    checkLicenseStatusError: (state, { payload }) => {
      state.licenseStatusError = payload;
    },
    checkLicenseExpirationError: (state, { payload }) => {
      state.licenseExpirationError = payload;
    },
    fetchLicenseCreateButtonEnableValue: (state, { payload }) => {
      state.licenseCreateButtonEnableValue = payload;
    },
    fetchLicenseStatusLoading: (state) => {
      state.licenseListLoading = true;
    },
    fetchLicenseStatusFail: (state) => {
      state.licenseError = true;
      state.licenseListLoading = false;
    },
    fetchLicenseStatusSuccess: (state, { payload }) => {
      state.licenseStatusData = payload;
    },
    handleLicenseDescriptionValue: (state, { payload }) => {
      state.description = payload;
    },
    fetchPostLicenseLoading: (state) => {
      state.postLicenseLoading = true;
    },

    fetchPostLicenseSucc: (state, { payload }) => {
      state.postLicenseSucc = payload;
    },

    fetchPostLicenseFail: (state, { payload }) => {
      state.postLicenseFail = payload;
    },
    fetchPostLicenseSpinnerSucc: (state, { payload }) => {
      state.spinnerEnable = payload;
    },

    resetPostlLicense: (state) => {
      state.licenseNameError = false;
      state.catelogFieldError = false;
      state.licenseExpirationError = false;
      state.licenseStatusError = false;
      state.licenseStatusValue = '';
      state.licenseExpirationValue = '30';
      state.postLicenseFail = false;
      state.licenseCatelogValue = '';
      state.description = '';
      state.licenseNameValue = '';
      state.postLicenseSucc = false;
      state.licenseCreateButtonEnableValue = false;
      state.uniqueName = false;
      state.uniqueCatelog = false;
      state.dateChanged = false;
    },
    fetchSingleLicenseDataLoading: (state) => {
      state.singleLicenseDataLoading = true;
      state.singleLicenseData = null;
    },
    fetchSingleLicenseDataSuccess: (state, { payload }) => {
      state.singleLicenseData = payload;
      state.singleLicenseDataLoading = false;
      state.singleLicenseDataFail = false;
      state.isCreate = false;
      state.licenseNameError = false;
      state.catelogFieldError = false;
      state.licenseExpirationError = false;
      state.licenseStatusError = false;
      state.licenseExpirationValue = payload?.grace_period_days;
      state.postLicenseFail = false;
      state.licenseCatelogValue = payload?.catalogue_number;
      state.description = payload?.description;
      state.licenseNameValue = payload?.name;
      state.postLicenseSucc = false;
      state.licenseCreateButtonEnableValue = false;
    },
    fetchSingleLicenseDataFail: (state) => {
      state.singleLicenseDataFail = true;
    },
    getEnableEditSave: (state, { payload }) => {
      state.enableEditSave = payload;
    },
    createLincense: (state, { payload }) => {
      state.isCreate = payload;
    },
    fetchLicenseSaveButtonEnableValue: (state, { payload }) => {
      state.isEnabled = payload;
    },
    fetchUniqueNameLoading: (state) => {
      state.uniqueNameLoading = true;
      state.uniqueName = false;
    },
    fetchUniqueNameFail: (state) => {
      state.uniqueNameFail = false;
    },
    fetchUniqueNameSucc: (state, { payload }) => {
      state.uniqueName = payload;
    },
    fetchUniqueCatelogLoading: (state) => {
      state.uniqueCatelogLoading = true;
      state.uniqueCatelog = false;
    },
    fetchUniqueCatelogFail: (state) => {
      state.uniqueCatelogFail = false;
    },
    fetchUniqueCatelogSucc: (state, { payload }) => {
      state.uniqueCatelog = payload;
    },
    fetchUpdateLicenseLoading: (state) => {
      state.updateLicenseLoading = true;
      state.updateLicenseFail = false;
    },
    fetchUpdateLicenseSucc: (state, { payload }) => {
      state.updateLicenseSucc = payload;
      state.isEnabled = false;
      state.dateChanged = false;
    },
    fetchUpdateLicenseFail: (state) => {
      state.updateLicenseFail = false;
      state.updateLicenseSucc = false;
    },
    handleBundleEditDatePickerMonth: (state, { payload }) => {
      state.getBundleEditDatePickerDate = payload;
    },
    handleBundleEditDatePickerDay: (state, { payload }) => {
      state.getBundleEditDatePickerDay = payload;
    },
    handleBundleEditDatePickerYear: (state, { payload }) => {
      state.getBundleEditDatePickerYear = payload;
    },
    handleBundelCreateButtonEnable: (state, { payload }) => {
      state.dateChanged = payload;
    },
  },
});
export const {
  getSearchText,
  clearSearcText,
  expandSearchInMobile,
  closeSearchInMobile,
  getLicensePageNumber,
  fetchLicenseLoading,
  fetchLicenseFail,
  fetchLicenseSuccess,
  fetchCreateLicenseApiFail,
  getLicenseFlterData,
  fetchlicenseDataLoading,
  fetchlicenseDataSuccess,
  fetchlicenseDataFail,
  getLicenseFilterValue,
  toggleLicenseView,
  handleCreateLicenseModal,
  fetchLicenseStatusLoading,
  fetchLicenseStatusFail,
  fetchLicenseStatusSuccess,
  getLicenseStatusValue,
  getLicenseExpirationValue,
  fetchLicensesGracePeriodLoading,
  fetchLicensesGracePeriodFail,
  fetchLicensesGracePeriodSucc,
  checkCatelogError,
  getLicenseCatelogValue,
  checkLicenseNameError,
  getLicenseNameValue,
  checkLicenseStatusError,
  checkLicenseExpirationError,
  fetchLicenseCreateButtonEnableValue,
  handleLicenseDescriptionValue,
  fetchPostLicenseLoading,
  fetchPostLicenseSucc,
  fetchPostLicenseFail,
  fetchPostLicenseSpinnerSucc,
  resetPostlLicense,
  fetchSingleLicenseDataLoading,
  fetchSingleLicenseDataSuccess,
  fetchSingleLicenseDataFail,
  getEnableEditSave,
  createLincense,
  fetchLicenseSaveButtonEnableValue,
  fetchUniqueCatelogLoading,
  fetchUniqueCatelogFail,
  fetchUniqueCatelogSucc,
  fetchUniqueNameLoading,
  fetchUniqueNameFail,
  fetchUniqueNameSucc,
  fetchUpdateLicenseLoading,
  fetchUpdateLicenseSucc,
  fetchUpdateLicenseFail,
  handleBundleEditDatePickerMonth,
  handleBundleEditDatePickerDay,
  handleBundleEditDatePickerYear,
  handleBundelCreateButtonEnable,
} = licenseSlice.actions;
export default licenseSlice.reducer;
