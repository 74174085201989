export const routePath = {
  Dashboard: 'dashboard',
  Customers: 'customers',
  Organizations: 'organizations',
  DeviceModels: 'device-models',
  licenses: 'licenses',
  Analytics: 'analytics',
  Communication: 'communication',
  Users: 'users',
  Settings: 'settings/partners',
  UnAuthorized: 'un-authorized',
};

export const customerSubTabs = {
  Customers: 'customers',
  Organizations: 'organizations',
};
export const dashboardSubTabs = {
  New: 'new',
  Total: 'total',
};
