import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { User_Roles } from 'utils/enum';

function OrgDevicesActivityListView() {
  const singleOrganizationDeviceData = useAppSelector(
    (state) => state.organizationDevicesActivitySlice.organizationDevicesActivityList,
  );
  const route = 'devices';
  const navigate = useNavigate();
  const intl = useIntl();
  const { orgId } = useParams();
  const {userRole } = useAppSelector((state) => state.user.userPermissions);
  const isSupportUserRole = userRole == User_Roles.Support;
  const data = Array.isArray(singleOrganizationDeviceData)
    ? singleOrganizationDeviceData &&
      singleOrganizationDeviceData.map((device: any) => {
        return {
          device_id: device.device_id,
          userId: device.user_id,
          user_name: device?.name || '-',
          orgId: orgId,
          device_serial_number: device?.serial_number,
          device_health: device?.device_health,
          device_model: device?.device?.catalogue
            ? device?.device?.catalogue + ' ' + device?.device?.internal_name + '*' + device?.device?.hardware_version
            : '-',
          device_internal_name: device?.device?.internal_name,
          two_rows_1: device?.mobile_device + '*' + device?.mobile_os,
          firmware: 'Firmware ' + device?.firmware + '*' + 'App ' + device?.app_version || '-',

          event: device?.event
            ? intl.formatMessage(
                { id: `device_activity_events.${device?.event}.message` },
                { value: device?.activity_value ? device?.activity_value : '' },
              )
            : '-',
          date_time: device.date_and_time ? (
            moment.utc(device.date_and_time).format('MMM DD, YYYY HH:mm')
          ) : (
            <FormattedMessage id="empty.message" />
          ),
        };
      })
    : [];
  const columns = [
    {
      field: 'user_name',
      header: <FormattedMessage id="name.message" />,
      link: !isSupportUserRole,
      isCapitalize: true,
    },
    {
      field: 'device_serial_number',
      header: <FormattedMessage id="serial#.message" />,
      link: !isSupportUserRole,
    },
    {
      field: 'device_model',
      header: <FormattedMessage id="device_modal.message" />,
      link: false,
    },
    {
      field: 'two_rows_1',
      header: <FormattedMessage id="mobile_device_info.message" />,
      link: false,
    },
    {
      field: 'firmware',
      header: <FormattedMessage id="firmware_app_version.message" />,
      link: false,
    },

    {
      field: 'event',
      header: <FormattedMessage id="eventAndHealth.message" />,
      link: false,
      infoData:
        'The temperature displayed holds the maximum of all the temperature values collected from different Iris components. The battery value displayed hold’s Iris’s battery captured during the event.',
    },
    {
      field: 'date_time',
      header: <FormattedMessage id="date_time_utc.message" />,
      link: false,
    },
  ];
  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'device_serial_number' && row.device_id != '') {
      navigate(`/device-models/${row?.device_internal_name}/devices/${row?.device_id}/info`);
    } else if (field === 'user_name') {
      navigate(`/organizations/${row?.orgId}/users/${row.userId}/info`);
    } else {
      navigate(``);
    }
  };

  return (
    <Box data-testid="org-devices-activity">
      <CustomTable data={data} columns={columns} route={route} handleNavigateFromChild={handleNavigateFromChild} />
    </Box>
  );
}
export default OrgDevicesActivityListView;
