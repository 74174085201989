import { Box } from '@chakra-ui/react';
import { AlertIcon } from '../../assets';
import { FormattedMessage } from 'react-intl';
import ErrorDescriptionModal from '../../components/ErrorDescriptionModal/ErrorDescriptionModal';
import { FeatureItem } from '../../types/types';
import { FormikProps } from 'formik';
import intlKeys from '../../lang/en.json';
import { IntlKeysType } from '../../types/types';
import { UseDisclosureReturn } from '@chakra-ui/react';

type LoginErrorModalProps = {
  searchParams: URLSearchParams;
  formik: FormikProps<{ email: string; password: string }>;
  openPasswordResetModal: UseDisclosureReturn['onOpen'];
};

type ModalErrorFromJsonProps = {
  keys: IntlKeysType;
  errorDescription: string;
};

const ModalErrorFromJson = ({ keys, errorDescription }: ModalErrorFromJsonProps) => {
  const errorData = keys[errorDescription] as FeatureItem;
  return (
    <Box minH="64px">
      <ErrorDescriptionModal icon={<AlertIcon />} heading={errorData.description || ''} message={errorData.message} />
    </Box>
  );
};

type ModalWithErrorFromTextProps = {
  searchParams: URLSearchParams;
  errorDescription?: string | null;
  openPasswordResetModal: UseDisclosureReturn['onOpen'];
};

const ModalWithErrorFromText = ({ searchParams, openPasswordResetModal }: ModalWithErrorFromTextProps) => {
  const errorDescription = searchParams.get('error_description') as string;

  const isPasswordExpiredError = (): boolean => {
    // TODO: I am still looking for a way to figure out whether or not we have the password expired error
    // I have created the following ticket: https://exoinc.atlassian.net/browse/EWNXN-1773
    // For now what we have is checking if the error message matches what Auth0 sends back
    const passwordExpiredRegex = /password.*expired/i;
    return passwordExpiredRegex.test(errorDescription);
  };

  const sendPasswordResetEmail = () => {
    openPasswordResetModal();
  };

  const getBannerText = (): { header: string; message: string } => {
    if (isPasswordExpiredError()) {
      return {
        header: 'password_expired.message',
        message: 'password_expired.description',
      };
    }

    return {
      header: `${errorDescription}.description`,
      message: errorDescription && `${errorDescription}.message`,
    };
  };

  const bannerText = getBannerText();

  return (
    <>
      {bannerText.message && (
        <ErrorDescriptionModal
          icon={<AlertIcon />}
          heading={bannerText.header}
          message={bannerText.message}
          innerTextComponent={<FormattedMessage id="error.message" />}
          onClose={isPasswordExpiredError() ? sendPasswordResetEmail : undefined}
          buttonText={isPasswordExpiredError() ? 'Change Password' : undefined}
        />
      )}
    </>
  );
};

const LoginErrorModal = ({ searchParams, formik, openPasswordResetModal }: LoginErrorModalProps) => {
  const keys = intlKeys as IntlKeysType;
  const errorDescription = searchParams.get('error_description') || null;

  const isErrorInIntlJson = (errorDescription: string): boolean => {
    return !!(errorDescription && !formik.values.email && keys[errorDescription]);
  };

  return errorDescription && typeof errorDescription === 'string' && isErrorInIntlJson(errorDescription) ? (
    <ModalErrorFromJson keys={keys} errorDescription={errorDescription} />
  ) : (
    <ModalWithErrorFromText
      openPasswordResetModal={openPasswordResetModal}
      searchParams={searchParams}
      errorDescription={errorDescription}
    />
  );
};

export default LoginErrorModal;
