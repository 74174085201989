import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect, useState } from 'react';

import { RootState } from 'redux/Store/store';
import { useIntl } from 'react-intl';

import Search from 'components/customSearch/Search';
import CustomDropdown from 'components/customDropdown/CustomDropdown';
import {
  clearSearcText,
  closeSearchInMobile,
  expandSearchInMobile,
  getFlterData,
  getSearchText,
} from 'redux/user/Reducers/userSlice';
import { UserRolePermission } from 'types/user-types';

interface FilterProps {
  isFiltersApplied: boolean;
}

const UserFilters = ({ isFiltersApplied }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const userData = useAppSelector((state: RootState) => state.user);
  const intl = useIntl();
  const dispatch = useAppDispatch();

  type filtersType = {
    roles: string[];
    status: string[];
  };
  const [filters, setFilters] = useState<filtersType>({
    roles: [`${intl.formatMessage({ id: 'all.message' })}`],
    status: [`${intl.formatMessage({ id: 'all.message' })}`],
  });

  const DROPDOWN_MAP = [
    {
      label: `${intl.formatMessage({ id: 'roles.message' })}`,
      options: ['All', ...userData?.roles?.map((role: UserRolePermission) => role?.role)],
      name: 'roles',
      label2: `${intl.formatMessage({ id: 'singlularRole.message' })}`,
    },
    {
      label: `${intl.formatMessage({ id: 'statuses.message' })}`,
      options: ['All', ...userData?.userStatusesData?.map((itr: any) => itr?.status)],
      name: 'status',
      label2: `${intl.formatMessage({ id: 'singularStatus.message' })}`,
    },
  ];

  const handleDropDownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, id, checked }: { name: string; id: string; checked: boolean } = e.target;
    const index = name === 'roles' ? 0 : 1;

    if (checked) {
      if (
        id === `${intl.formatMessage({ id: 'all.message' })}` ||
        filters[name as keyof filtersType].filter((itr) => itr !== 'All').length ===
          DROPDOWN_MAP[index].options.length - 2
      ) {
        setFilters({
          ...filters,
          [name]: [`${intl.formatMessage({ id: 'all.message' })}`],
        });
        return;
      }
      setFilters({
        ...filters,
        [name]: [...filters[name as keyof filtersType], id].filter(
          (itr) => itr !== `${intl.formatMessage({ id: 'all.message' })}`,
        ),
      });
    } else {
      const mutatedArray = [
        ...filters[name as keyof filtersType].filter(
          (itr) => ![id, `${intl.formatMessage({ id: 'all.message' })}`].includes(itr),
        ),
      ];
      setFilters({
        ...filters,
        [name]: [...(mutatedArray.length ? mutatedArray : [`${intl.formatMessage({ id: 'all.message' })}`])],
      });
    }
  };

  useEffect(() => {
    const payload = {
      status_id: userData.userStatusesData
        .filter((itr: any) => filters?.status.includes(itr?.status))
        .map((itr: any) => itr.id),
      roles_id: userData.roles
        .filter((role: UserRolePermission) => filters?.roles.includes(role?.role))
        .map((role: UserRolePermission) => role.id),
    };
    dispatch(getFlterData(payload));
  }, [filters]);

  const handleSearchClose = () => {
    dispatch(closeSearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSearchText(value));
  };
  useEffect(() => {
    dispatch(closeSearchInMobile());
  }, [isMobileDevice]);
  const statusColors: { [key: string]: string } = userData?.userStatusesData?.reduce(
    (acc: any, { status, color_code }: any) => ({ ...acc, [status]: color_code }),
    {},
  );

  return (
    <Box mr="45px" px="-20px">
      {((userData.usersList && userData.usersList.length > 0) || isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="6px"
          alignItems="center"
        >
          <Search
            data={userData}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />
          {!userData.isSearchExpand && (
            <Box display="flex" alignItems="center" pr={{ lg: '5px' }} id="status-types-container">
              {DROPDOWN_MAP.map(({ label, options, name, label2 }) => (
                <Box key={name} pl={{ xs: '26px' }} id="dropdown-container">
                  <CustomDropdown
                    label={label}
                    statusColors={statusColors}
                    options={options}
                    activeFilters={filters[name as keyof filtersType]}
                    name={name}
                    onChange={handleDropDownChange}
                    label2={label2}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default UserFilters;
