import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect } from 'react';
import { RootState } from 'redux/Store/store';

import Search from 'components/customSearch/Search';
import {
  clearSearcText,
  closeSearchInMobile,
  expandSearchInMobile,
  getSearchText,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';

interface FilterProps {
  isFiltersApplied: boolean;
  isLoading: boolean;
}

const ClientAccesFilter = ({ isFiltersApplied, isLoading }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const data = useAppSelector((state: RootState) => state.clientAccess);

  const dispatch = useAppDispatch();

  const handleSearchClose = () => {
    dispatch(closeSearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSearchText(value));
  };
  useEffect(() => {
    dispatch(closeSearchInMobile());
  }, [isMobileDevice]);

  return (
    <Box>
      {' '}
      {((data.clientAccessList && data.clientAccessList.length > 0) || isLoading || isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="26px"
          pr="20px"
          alignItems="center"
        >
          {' '}
          <Search
            data={data}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />{' '}
        </Box>
      )}{' '}
    </Box>
  );
};
export default ClientAccesFilter;
