/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useParams } from 'react-router-dom';
import { Box, Text, Wrap } from '@chakra-ui/react';
import { AutoCompleteContainer, Input, AutoCompleteItem, AutoCompleteItemButton } from './Styles';
import {
  fetchDeviceCreateButtonEnable,
  fetchDevicePostToggle,
  fetchDeviceSuccessCreation,
  fetchOrgNameInputLength,
  fetchPostDeviceData,
  fetchPostDeviceFormDataFail,
  getDevicePostCreateButton,
  getOrgNamePostValue,
  getOrgNameSearchValue,
  getOrganizationNameSearchValue,
} from '../../redux/devices/Reducers/deviceSlice';
import { getOrgNameSearch } from '../../redux/devices/Actions/deviceActions';
import { specialCharacterValidation } from '../../utils/validations/specialCharacterValidation';
import { getOrganizationInputNameValue } from 'redux/organizations/Reducers/organizationSlice';
import { customColor } from '../../../../../libs/shared-ui/src/lib/assets';

interface autoCompleteProps {
  iconColor?: string;
  inputStyle?: React.CSSProperties;
  optionsStyle?: React.CSSProperties;
  data: any[];
  orgName: string;
  textSize?: string;
}

export const InputAutoComplete: FC<autoCompleteProps> = ({ optionsStyle, data, orgName, textSize }) => {
  const [search, setSearch] = useState<any>({
    text: '',
    suggestions: [],
  });
  const colors = customColor();
  const [textChanged, setTextChanged] = useState(false);
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [target, setTarget] = useState<any>('');
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const orgNameValue = useAppSelector((state) => state.device.orgNameSearchValue);
  const postDataFail = useAppSelector((state) => state.device.postDeviceFormDataFail);
  const orgNameSearchFail = useAppSelector((state) => state.device.orgNameSearchFail);
  const orgNameSearchData = useAppSelector((state) => state.device.orgNameSearchData);
  const { suggestions } = search;

  const params = useParams();

  const suggestionSelected = (value: any) => {
    setIsComponentVisible(false);
    dispatch(getOrgNamePostValue(value.id));
    dispatch(getOrgNameSearchValue(value.org_name));
    if (params.id) {
      setTarget(value.org_name);
    } else {
      setTarget(value.org_name);
      dispatch(getOrganizationInputNameValue(value.id));
    }
    setSearch({
      text: value.org_name,
      suggestions: [],
    });
  };

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 0 && value.length < 4) {
      dispatch(fetchOrgNameInputLength(true));
    } else {
      dispatch(fetchOrgNameInputLength(false));
    }
    dispatch(getOrgNamePostValue(''));
    let suggestions = [];
    if (e.target.value.length > 0) {
      dispatch(getOrgNameSearch({ org_name: e.target.value }));
      dispatch(getOrganizationNameSearchValue(e.target.value));
      const regex = new RegExp(`^${e.target.value}`, 'i');
      suggestions = data.sort().filter((v: any) => regex.test(v.name));
    } else {
      dispatch(getOrganizationNameSearchValue(''));
    }
    setSearch({ suggestions, text: value });
    setIsComponentVisible(true);
    setTextChanged(true);

    setTarget('');
    if (postDataFail) {
      dispatch(fetchDeviceCreateButtonEnable(false));
      dispatch(fetchPostDeviceData(false));
      dispatch(getDevicePostCreateButton(false));
      dispatch(fetchDeviceSuccessCreation(false));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(fetchDevicePostToggle(false));
    }
  };

  useEffect(() => {
    if (orgNameValue.length < 4) {
      setIsComponentVisible(false);
    }
  }, [orgNameValue]);

  return (
    <Box position="relative" width="305px" data-testid="container" style={{ top: '10px', position: 'relative' }}>
      <Box
        onClick={() => setIsComponentVisible(false)}
        style={{
          display: isComponentVisible ? 'block' : 'none',
          height: '200vh',
          backgroundColor: 'transparent',
          position: 'fixed',
          zIndex: 0,
          top: 0,
          left: 0,
        }}
        data-testid="container-box-1"
      />
      <Box>
        <Input
          width="305px"
          id="input"
          color={colors.subTextColor}
          data-testid="container-box-2"
          autoComplete="off"
          value={textChanged ? search.text : orgName}
          onChange={onTextChanged}
          type="text"
          fontSize={textSize}
          onKeyPress={specialCharacterValidation}
          placeholder={intl.formatMessage({ id: 'organization_name_optional.message' })}
        />
      </Box>
      {search.text.length > 3 && isComponentVisible ? (
        <Wrap overflowX="scroll">
          {search.text.length > 3 && !orgNameSearchFail ? (
            <AutoCompleteContainer style={optionsStyle}>
              {orgNameSearchData &&
                orgNameSearchData.map((item: any) => (
                  <AutoCompleteItem key={item.id}>
                    <AutoCompleteItemButton key={item.id} name={item.org_name} onClick={() => suggestionSelected(item)}>
                      {item.org_name}
                    </AutoCompleteItemButton>
                  </AutoCompleteItem>
                ))}
            </AutoCompleteContainer>
          ) : null}
        </Wrap>
      ) : search.text.length > 3 && !target ? (
        <Text color="#FB5654" ml="5px" fontSize="14px">
          <FormattedMessage id="org_name_not_found.message" />
        </Text>
      ) : search.text.length > 0 && search.text.length < 4 && !target ? (
        <Text color="#FB5654" ml="5px" fontSize="14px">
          <FormattedMessage id="org_name_error.message" />
        </Text>
      ) : (
        ''
      )}
    </Box>
  );
};
