import { createSlice } from '@reduxjs/toolkit';
import { Feature, FeatureGroup } from 'types/feature';
import { PaginationTypes } from 'types/pagination-types';

type initialState = {
  orgFeatureGroups: FeatureGroup[];
  pagination: PaginationTypes | null;
  isLoading: boolean;
  featureId: number;
  isFeatureStatusFail: boolean;
  isStatusUpdated: boolean;
  orgFeatureAvailablitySuccess: boolean;
  orgFeatureAvailablityFail: boolean;
  orgFeatureAvailablityLoading: boolean;
};
export const initialState: initialState = {
  orgFeatureGroups: [],
  pagination: null,
  isLoading: false,
  featureId: 0,
  isFeatureStatusFail: false,
  isStatusUpdated: false,
  orgFeatureAvailablitySuccess: false,
  orgFeatureAvailablityFail: false,
  orgFeatureAvailablityLoading: false,
};

const orgFeatureSlice = createSlice({
  name: 'orgFeatures',
  initialState,
  reducers: {
    setOrgFeatureGroups: (state, { payload }) => {
      state.orgFeatureGroups = payload;
      state.isLoading = false;
    },
    fetchFeaturesLoading: (state) => {
      state.isLoading = true;
    },
    setOrgFeatureAvailablitySuccess: (state, { payload }) => {
      state.orgFeatureAvailablitySuccess = payload;
      state.orgFeatureAvailablityFail = false;
      state.orgFeatureAvailablityLoading = false;
    },
    setOrgFeatureAvailablityFail: (state, { payload }) => {
      state.orgFeatureAvailablityFail = payload;
      state.orgFeatureAvailablitySuccess = false;
      state.orgFeatureAvailablityLoading = false;
    },
    setOrgFeatureAvailablityLoading: (state, { payload }) => {
      state.orgFeatureAvailablityLoading = payload;
      state.orgFeatureAvailablityFail = false;
      state.orgFeatureAvailablitySuccess = false;
    },
    resetOrgFeatureUpdateValues: (state) => {
      state.orgFeatureAvailablityLoading = false;
      state.orgFeatureAvailablityFail = false;
      state.orgFeatureAvailablitySuccess = false;
    },
    isUpdatedStatusFail: (state) => {
      state.isFeatureStatusFail = true;
      state.isStatusUpdated = false;
    },

    updateFeatureAvailablity: (state, { payload: { id } }) => {
      state.isStatusUpdated = false;
      state.orgFeatureGroups = state.orgFeatureGroups.map((featureGroup: FeatureGroup) => ({
        ...featureGroup,
        features: featureGroup.features.map((feature: Feature) =>
          feature.id === id ? { ...feature, isUpdating: !feature.isUpdating, enabled: !feature.enabled } : feature,
        ),
      }));
    },
  },
});

export const {
  setOrgFeatureGroups,
  fetchFeaturesLoading,
  updateFeatureAvailablity,
  isUpdatedStatusFail,
  setOrgFeatureAvailablitySuccess,
  resetOrgFeatureUpdateValues,
  setOrgFeatureAvailablityFail,
  setOrgFeatureAvailablityLoading,
} = orgFeatureSlice.actions;
export default orgFeatureSlice.reducer;
