import { Flex, Spinner, Text, VStack, useColorModeValue, Box } from '@chakra-ui/react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { ExitIcon } from '../../assets/icons/icons';

type Props = {
  titleFormattedId: string;
  descriptionFormattedId: string;
  onClose: () => void;
  hideTime?: boolean;
};

export const LoadingToast = (props: Props) => {
  const bgColor = useColorModeValue(
    'linear-gradient(0deg, rgba(104, 100, 221, 0.05) 0%, rgba(104, 100, 221, 0.05) 100%), #FFF',
    'linear-gradient(0deg, rgba(104, 100, 221, 0.10) 0%, rgba(104, 100, 221, 0.10) 100%), #1A1C22',
  );
  const textColor = useColorModeValue('#2A2858', '#D2D1F5');
  const timeTextColor = useColorModeValue('#33333399', '#E7E9ED99');
  return (
    <Flex
      width={'500px'}
      p={'20px 30px'}
      bg={bgColor}
      borderRadius={'10px'}
      boxShadow={'0px 1px 20px 0px rgba(0, 0, 0, 0.25)'}
      border={'1px solid #6864DD'}
    >
      <Flex width={'37px'}>
        <Spinner color="#638afa" />
      </Flex>
      <VStack alignItems={'flex-start'} flex={1}>
        <Text fontSize={'16px'} fontWeight={700} color={textColor}>
          <FormattedMessage id={props.titleFormattedId} />
        </Text>
        <Text fontSize={'16px'} fontWeight={500} color={textColor}>
          <FormattedMessage id={props.descriptionFormattedId} />
        </Text>
        <Text fontSize={'14px'} fontWeight={500} color={timeTextColor} hidden={props.hideTime}>
          <FormattedTime value={new Date().getTime()} />
        </Text>
      </VStack>
      <Flex alignItems={'flex-start'}>
        <Box cursor="pointer" position={'relative'} top={'-10px'} right={'-20px'} onClick={props.onClose}>
          <ExitIcon color={timeTextColor} />
        </Box>
      </Flex>
    </Flex>
  );
};
