/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';
import { PaginationTypes } from 'types/pagination-types';

import { UserRole } from 'types/user-types';

type InitialState = {
  usersApiData: any;

  searchText: string;

  isSearchExpand: boolean;

  usersListLoading: boolean;

  usersListError: boolean;

  paginationData: null | PaginationTypes;

  numberOfPages: number;

  orgUsersStatus: {
    status_name: string;

    color_code: string;

    id: string;
  }[];

  usersList: string[] | null;

  selectedPage: number;

  filterData: {
    status_id: any;
  };

  SingleUserDataLoading: boolean;

  SingleUserDataError: boolean;

  SingleUsersGetData: any;

  singleUserID: string | number;

  editSingleUserInfoLoading: boolean;

  editSingleUserInfoData: string[];

  editSingleUserInfoFail: boolean;

  editSingleUserInfoApiFail: string;

  getUserStatusInputValue: string;

  orgUserStatusesLoading: boolean;

  orgUserRolesLoading: boolean;

  orgUserStatusesError: boolean;

  orgUserRolesError: boolean;

  orgUserRolesData: UserRole[];

  statusFilterValue: string;

  handleOrgUserModalOpen: boolean;

  orgUserFirstName: string;

  orgUserLastName: string;

  orgUserPhoneNumber: number | string;

  orgUserEmail: string;

  orgUserStatusName: string;

  orgUserFirstNameFieldValidation: boolean;

  orgUserLastNameFieldValidation: boolean;

  orgUserRoleValue: string;

  orgUserStatusValue: string;

  orgUserRoleIDPostData: string | number;

  orgUserStatusIDPostData: string | number;

  orgUserFirstNameLength: boolean;

  orgUserLastNameLength: boolean;

  orgUserCreateButtonEnable: boolean;

  orgUserCreateButtonEnableValue: boolean;

  postOrgUserData: boolean;

  postOrgUserApiFail: string;

  orgUserEmailFieldValidation: boolean;

  orgUserEmailValidFormat: boolean;

  postOrgUserToggle: boolean;

  postOrgUserFormDataLoading: boolean;

  postOrgUserFormDataFail: boolean;

  postOrgUserFormData: string[];

  createOrgUserApiFail: string;

  orgUserSuccessCreation: boolean;

  getOrgUserEmailLoading: boolean;

  getOrgUserEmailData: string[];

  getOrgUserEmailFail: boolean;

  orgUserReason: string;
  orgUserchangePasswordModal: boolean;
  userAnalyticsLoading: boolean;
  userAnalyticsData: { licenses_used: number; total_licenses: number } | null;
  userAnalyticsFail: boolean;
  resendWelcomeEmailModalStatus: boolean;
};

export const initialState: InitialState = {
  searchText: '',

  isSearchExpand: false,

  usersApiData: null,

  usersListLoading: false,

  usersListError: false,

  usersList: [],

  paginationData: null,

  numberOfPages: 1,

  selectedPage: 1,

  filterData: {
    status_id: [],
  },

  orgUsersStatus: [],

  SingleUserDataLoading: false,

  SingleUserDataError: false,

  SingleUsersGetData: null,

  singleUserID: '',

  editSingleUserInfoLoading: false,

  editSingleUserInfoData: [],

  editSingleUserInfoFail: false,

  editSingleUserInfoApiFail: '',

  getUserStatusInputValue: '',

  orgUserStatusesLoading: false,

  orgUserRolesLoading: false,

  orgUserStatusesError: false,

  orgUserRolesError: false,

  orgUserRolesData: [],

  statusFilterValue: '',

  handleOrgUserModalOpen: false,

  orgUserFirstName: '',

  orgUserLastName: '',

  orgUserPhoneNumber: '',

  orgUserEmail: '',

  orgUserStatusName: '',

  orgUserFirstNameFieldValidation: false,

  orgUserLastNameFieldValidation: false,

  orgUserRoleValue: '',

  orgUserStatusValue: '',

  orgUserRoleIDPostData: '',

  orgUserStatusIDPostData: '',

  orgUserFirstNameLength: false,

  orgUserLastNameLength: false,

  orgUserCreateButtonEnable: false,

  orgUserCreateButtonEnableValue: false,

  postOrgUserData: false,

  postOrgUserApiFail: '',

  orgUserEmailFieldValidation: false,

  orgUserEmailValidFormat: false,

  postOrgUserToggle: false,

  postOrgUserFormDataLoading: false,

  postOrgUserFormDataFail: false,

  postOrgUserFormData: [],

  createOrgUserApiFail: '',

  orgUserSuccessCreation: false,

  getOrgUserEmailLoading: false,

  getOrgUserEmailData: [],

  getOrgUserEmailFail: false,

  orgUserReason: '',
  orgUserchangePasswordModal: false,
  userAnalyticsLoading: false,
  userAnalyticsData: { licenses_used: 0, total_licenses: 0 },
  userAnalyticsFail: false,
  resendWelcomeEmailModalStatus: false,
};

const orguserSlice = createSlice({
  name: 'orgUser',

  initialState,

  reducers: {
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },

    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },

    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },

    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;

      state.searchText = '';
    },

    clearSearcText: (state) => {
      state.searchText = '';
    },

    fetchUsersLoading: (state) => {
      state.usersListLoading = true;
      state.usersListError = false;
    },

    fetchUsersFail: (state) => {
      state.usersListLoading = false;

      state.usersListError = true;

      state.usersList = [];

      state.paginationData = null;
    },

    fetchUserSuccess: (state, { payload }) => {
      state.usersList = payload.data;

      state.usersApiData = payload;

      state.paginationData = payload.pagination;

      state.numberOfPages = payload.pagination.num_pages;

      state.usersListLoading = false;
    },

    getUserPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchUsersStatusLoading: (state) => {
      state.orgUserStatusesLoading = true;

      state.orgUserStatusesError = false;
    },

    fetchUsersStatusFail: (state) => {
      state.orgUserStatusesError = true;

      state.orgUserStatusesLoading = false;
    },

    fetchUserStatusSuccess: (state, { payload }) => {
      state.orgUsersStatus = payload;

      state.orgUserStatusesLoading = false;

      state.orgUserStatusesError = false;
    },

    fetchSingleUserDataLoading: (state) => {
      state.SingleUserDataLoading = true;

      state.SingleUserDataError = false;
    },

    fetchSingleUserDataSuccess: (state, { payload }) => {
      state.SingleUsersGetData = payload;

      state.SingleUserDataLoading = false;

      state.SingleUserDataError = false;
    },

    fetchSingleUserDataFail: (state) => {
      state.SingleUserDataError = true;

      state.SingleUserDataLoading = false;
    },

    fetchSingleUserID: (state, { payload }) => {
      state.singleUserID = payload;
    },
    editSingleUserInfoDataLoading: (state) => {
      state.editSingleUserInfoLoading = true;
      state.editSingleUserInfoFail = false;
    },
    editSingleUserInfoDataSuccess: (state, { payload }) => {
      state.editSingleUserInfoData = payload;
      state.editSingleUserInfoLoading = false;
      state.editSingleUserInfoFail = false;
      state.editSingleUserInfoApiFail = 'SUCCESS';
    },

    editSingleUserInfoDataFail: (state) => {
      state.editSingleUserInfoFail = true;

      state.editSingleUserInfoLoading = false;

      state.editSingleUserInfoApiFail = 'FAIL';
    },

    fetchEditSingleUserInfoApiFail: (state, { payload }) => {
      state.editSingleUserInfoApiFail = payload;
    },

    getSingleUserStatusInputValue: (state, { payload }) => {
      state.getUserStatusInputValue = payload;
    },

    handleStatusFilterChange: (state, { payload }) => {
      state.statusFilterValue = payload;
    },

    fetchUserRolesLoading: (state) => {
      state.orgUserRolesLoading = true;

      state.orgUserRolesError = false;
    },

    fetchUserRolesSuccess: (state, { payload }) => {
      state.orgUserRolesLoading = false;

      state.orgUserRolesError = false;

      state.orgUserRolesData = payload;
    },

    fetchUserRolesFail: (state) => {
      state.orgUserRolesError = true;

      state.orgUserRolesLoading = false;
    },

    handleCreateOrgUserModal: (state, { payload }) => {
      state.handleOrgUserModalOpen = payload;
    },

    fetchOrgUserCreateButtonEnableValue: (state, { payload }) => {
      state.orgUserCreateButtonEnableValue = payload;
    },

    fetchOrgUserFirstName: (state, { payload }) => {
      state.orgUserFirstName = payload;
    },

    fetchOrgUserLastName: (state, { payload }) => {
      state.orgUserLastName = payload;
    },

    getOrgUserFirstNameLength: (state, { payload }) => {
      state.orgUserFirstNameLength = payload;
    },

    getOrgUserLastNameLength: (state, { payload }) => {
      state.orgUserLastNameLength = payload;
    },

    fetchOrgUserFirstNameFieldValidation: (state, { payload }) => {
      state.orgUserFirstNameFieldValidation = payload;
    },

    fetchOrgUserLastNameFieldValidation: (state, { payload }) => {
      state.orgUserLastNameFieldValidation = payload;
    },

    fetchOrgUserEmailFieldValidation: (state, { payload }) => {
      state.orgUserEmailFieldValidation = payload;
    },

    fetchOrgUserPhoneNumber: (state, { payload }) => {
      state.orgUserPhoneNumber = payload;
    },

    fetchOrgUserEmail: (state, { payload }) => {
      state.orgUserEmail = payload;
    },

    fetchOrgUserStatus: (state, { payload }) => {
      state.orgUserStatusName = payload;
    },

    fetchOrgUserRoleValue: (state, { payload }) => {
      state.orgUserRoleValue = payload;
    },

    fetchOrgUserRolePostData: (state, { payload }) => {
      state.orgUserRoleIDPostData = payload;
    },

    fetchOrgUserStatusPostData: (state, { payload }) => {
      state.orgUserStatusIDPostData = payload;
    },

    getOrgUserCreateButtonEnable: (state, { payload }) => {
      state.orgUserCreateButtonEnable = payload;
    },

    fetchPostOrgUserFormDataLoading: (state) => {
      state.postOrgUserFormDataLoading = true;

      state.postOrgUserFormDataFail = false;
    },

    fetchPostOrgUserFormDataSuccess: (state, { payload }) => {
      state.postOrgUserFormData = payload;

      state.postOrgUserFormDataFail = false;

      state.postOrgUserFormDataLoading = false;
    },

    fetchPostOrgUserFormDataFail: (state, { payload }) => {
      state.postOrgUserFormDataFail = payload;

      state.postOrgUserFormDataLoading = false;
    },

    fetchOrgUserPostApiFail: (state, { payload }) => {
      state.postOrgUserApiFail = payload;
    },

    fetchOrgUserSuccessCreation: (state, { payload }) => {
      state.orgUserSuccessCreation = payload;
    },

    fetchPostOrgUserData: (state, { payload }) => {
      state.postOrgUserData = payload;
    },

    handleOrgUserEmailValidFormat: (state, { payload }) => {
      state.orgUserEmailValidFormat = payload;
    },

    fetchOrgUserStatusValue: (state, { payload }) => {
      state.orgUserStatusValue = payload;
    },

    fetchOrgUserPostToggle: (state, { payload }) => {
      state.postOrgUserToggle = payload;
    },

    fetchOrgUserEmailLoading: (state) => {
      state.getOrgUserEmailLoading = true;

      state.getOrgUserEmailFail = false;
    },

    fetchOrgUserEmailDataSuccess: (state, { payload }) => {
      state.getOrgUserEmailData = payload;

      state.getOrgUserEmailFail = false;

      state.getOrgUserEmailLoading = false;
    },

    fetchOrgUserEmailFail: (state, { payload }) => {
      state.getOrgUserEmailFail = payload;

      state.getOrgUserEmailLoading = false;
    },

    fetchOrguserReason: (state, { payload }) => {
      state.orgUserReason = payload;
    },
    handleOrgUserPassword: (state, { payload }) => {
      state.orgUserchangePasswordModal = payload;
    },
    handleWelcomeEmailModal: (state, { payload }) => {
      state.resendWelcomeEmailModalStatus = payload;
    },

    fetchUserAnalyticsLoading: (state) => {
      state.userAnalyticsLoading = true;
      state.userAnalyticsFail = false;
    },

    fetchUserAnalyticsSucc: (state, { payload }) => {
      state.userAnalyticsLoading = false;
      state.userAnalyticsFail = false;
      state.userAnalyticsData = payload?.data;
    },

    fetchUserAnalyticsFail: (state) => {
      state.userAnalyticsFail = true;
    },

    resetOrgUserValidationValues: (state) => {
      (state.createOrgUserApiFail = ''),
        (state.postOrgUserFormData = []),
        (state.orgUserRoleIDPostData = ''),
        (state.orgUserStatusIDPostData = ''),
        (state.orgUserStatusValue = ''),
        (state.orgUserFirstNameLength = false),
        (state.orgUserLastNameLength = false),
        (state.orgUserCreateButtonEnableValue = false),
        (state.postOrgUserData = false),
        (state.orgUserSuccessCreation = false),
        (state.postOrgUserData = false),
        (state.orgUserRoleValue = ''),
        (state.orgUserCreateButtonEnable = false);

      (state.orgUserFirstNameFieldValidation = false),
        (state.orgUserLastNameFieldValidation = false),
        (state.orgUserEmailFieldValidation = false),
        (state.orgUserEmailValidFormat = false),
        (state.postOrgUserApiFail = ''),
        (state.postOrgUserToggle = false),
        (state.orgUserFirstName = ''),
        (state.orgUserLastName = ''),
        (state.orgUserPhoneNumber = ''),
        (state.orgUserEmail = ''),
        (state.postOrgUserFormDataFail = false),
        (state.getOrgUserEmailFail = false);
      state.orgUserchangePasswordModal = false;
      state.resendWelcomeEmailModalStatus = false;
    },
  },
});

export const {
  fetchUsersStatusLoading,

  fetchUsersStatusFail,

  fetchUserStatusSuccess,

  fetchUserSuccess,
  handleOrgUserPassword,
  fetchUsersFail,

  fetchUsersLoading,

  getFlterData,

  getUserPageNumber,

  getSearchText,

  closeSearchInMobile,

  expandSearchInMobile,

  clearSearcText,

  fetchSingleUserDataFail,

  fetchSingleUserDataSuccess,

  fetchSingleUserDataLoading,

  fetchSingleUserID,
  fetchOrguserReason,

  editSingleUserInfoDataFail,

  editSingleUserInfoDataLoading,

  editSingleUserInfoDataSuccess,

  fetchEditSingleUserInfoApiFail,

  getSingleUserStatusInputValue,

  getOrgUserCreateButtonEnable,

  fetchOrgUserCreateButtonEnableValue,

  fetchOrgUserEmail,

  fetchOrgUserEmailDataSuccess,

  fetchOrgUserEmailFail,

  fetchOrgUserEmailFieldValidation,

  fetchOrgUserEmailLoading,

  fetchOrgUserFirstName,

  fetchOrgUserFirstNameFieldValidation,

  fetchOrgUserLastName,

  fetchOrgUserLastNameFieldValidation,

  fetchOrgUserPhoneNumber,

  fetchOrgUserPostApiFail,

  fetchOrgUserRolePostData,

  fetchOrgUserRoleValue,

  fetchOrgUserPostToggle,

  fetchOrgUserSuccessCreation,

  fetchPostOrgUserData,

  fetchPostOrgUserFormDataFail,

  fetchPostOrgUserFormDataLoading,

  fetchPostOrgUserFormDataSuccess,

  fetchUserRolesFail,

  fetchUserRolesLoading,

  fetchUserRolesSuccess,

  getOrgUserFirstNameLength,

  getOrgUserLastNameLength,

  handleCreateOrgUserModal,

  handleOrgUserEmailValidFormat,

  handleStatusFilterChange,

  resetOrgUserValidationValues,

  fetchOrgUserStatus,

  fetchOrgUserStatusValue,

  fetchOrgUserStatusPostData,
  fetchUserAnalyticsFail,
  fetchUserAnalyticsSucc,
  fetchUserAnalyticsLoading,
  handleWelcomeEmailModal,
} = orguserSlice.actions;

export default orguserSlice.reducer;
