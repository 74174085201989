import { Box } from '@chakra-ui/react';
import CustomTable from 'components/customTable/CustomTable';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { editPulseUserID } from 'redux/user/Reducers/userSlice';
import EditUserModal from 'pages/users/editUserInputFields/EditUserModal';
import { useNavigate, useParams } from 'react-router-dom';
import { getSinglePulseUserData, getUsersRoles, getUsersStatuses } from 'redux/user/Actions/userActions';
import { userAccessConst } from 'utils/constants/userAccessConst';

function UserListView() {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const toggle = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const usersData = useAppSelector((state: RootState) => state.user);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const navigate = useNavigate();

  const formatDate = (date: string) => {
    return moment(date).format('MMM DD, YYYY HH:mm');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = usersData.usersList.map((item: any) => {
    return {
      id: item.id,
      email: item.email,
      last_login: item.last_login ? formatDate(item.last_login) : '-',
      regions: item.regions,
      role: item.role,
      users: item.first_name + ' ' + item.last_name,
      device_status: item.status.status + '*' + item.status.color_code,
    };
  });

  const columns = [
    {
      field: 'users',
      header: <FormattedMessage id="user.message" />,
      link: userPermissions.users?.includes(userAccessConst.user.viewUser),
      isCapitalize: true,
    },
    { field: 'email', header: <FormattedMessage id="email.message" />, link: true },
    { field: 'role', header: <FormattedMessage id="role.message" />, link: false },
    { field: 'last_login', header: <FormattedMessage id="last_login.message" />, link: false },
    { field: 'device_status', header: <FormattedMessage id="status.message" />, link: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'users') {
      navigate(`/users/${row.id}/info`);
      dispatch(editPulseUserID(row.id));
      dispatch(getUsersRoles(0));
      dispatch(getUsersStatuses());
      dispatch(getSinglePulseUserData(row.id));
    }
  };
  //   useEffect(() => {
  // dispatch(resetUserValidationValues())
  //   },[])

  return (
    <Box
      pos="relative"
      h="calc(100vh - 50px)"
      display="flex"
      w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 240px)' : 'calc(100vw - 120px)' }}
    >
      <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 250px)' : 'calc(100vw - 120px)' }}>
        <CustomTable data={data} columns={columns} route="" handleNavigateFromChild={handleNavigateFromChild} />
        <EditUserModal />
      </Box>
    </Box>
  );
}

export default UserListView;
