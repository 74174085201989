import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  HStack,
  CircularProgress,
  Text,
  Circle,
  Flex,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getTiers } from 'redux/organizations/Actions/organizationActions';
import {
  getBundleGracePeriod,
  getBundleStatus,
  getSingleBundleEditData,
} from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { getEditBundleName, resetSoftwareBundlePopUpValues } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { RootState } from 'redux/Store/store';
import { userAccessConst } from 'utils/constants/userAccessConst';
import StatusInfo from 'components/status/StatusInfo';
import SoftwareBundleEditScreenInputs from './SoftwareBundleEditScreenInputs';
import Loader from 'components/loader/Loader';
import StatusBadge from 'components/statusBadge/StatusBadge';

const SoftwareBundleEditScreenInfo = () => {
  const navigate = useNavigate();
  const { bundleId } = useParams();
  const dispatch = useAppDispatch();
  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const singleBundleApiData = useAppSelector((state: any) => state.softwareBundles.singleBundleEditData);
  const editBundlePostApiFail = useAppSelector((state) => state.softwareBundles.editBundlePostApiFail);
  const bundleStatusData = useAppSelector((state) => state.softwareBundles.getSoftwareBundleStatusData);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const isLoading = useAppSelector((state: RootState) => state.softwareBundles.editPostBundleDataLoading);

  const data2 = [
    {
      id: 1,
      pathname: '',
      name: 'information',
      hasAccess: userPermissions.bundles?.includes(userAccessConst.bundle.viewBundle),
    },
  ];

  const routes = data2;
  useEffect(() => {
    dispatch(getDeviceStatus());
  }, []);

  const handleDeviceClick = () => {
    navigate(`/licenses`);
    dispatch(resetSoftwareBundlePopUpValues());
  };
  useEffect(() => {
    dispatch(getSingleBundleEditData(bundleId));
  }, [editBundlePostApiFail]);
  useEffect(() => {
    dispatch(getEditBundleName(singleBundleApiData?.name));
  }, []);
  useEffect(() => {
    dispatch(getDeviceStatus());
    dispatch(getBundleStatus());
    dispatch(getBundleGracePeriod());
    dispatch(getTiers());
    dispatch(getSingleBundleEditData(bundleId));
  }, []);

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      {singleBundleApiData ? (
        <Box data-testid="org-info-container">
          <Box display="flex" h="90px" borderBottom="1px solid #3333331A" pl="15px">
            <div data-testid="org-info-breadcumb">
              <Breadcrumb spacing="8px" pt="35px" separator={<ChevronRightIcon color="gray.500" />}>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    color="#24B0FF"
                    position="absolute"
                    top="14px"
                    onClick={handleDeviceClick}
                    display="flex"
                    fontSize="14px"
                    alignItems="center"
                  >
                    <RightChervonIcon />

                    <Text ml="5px">
                      <FormattedMessage id="bundles.message" />
                    </Text>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              <Flex gap="20px" alignItems={'center'}>
                <Heading fontSize="36px" fontWeight="400" fontFamily="Helvetica" style={{ paddingLeft: '15px' }}>
                  {singleBundleApiData.name ? (
                    <>
                      <Box display="flex" style={{ textTransform: 'capitalize' }}>
                        {singleBundleApiData.name}
                      </Box>
                    </>
                  ) : (
                    <CircularProgress size="30px" isIndeterminate />
                  )}
                </Heading>
                <StatusBadge status={singleBundleApiData?.status?.status} />
                <Box>
                  <Text
                    display="flex"
                    style={{ textTransform: 'capitalize', fontSize: '10px', color: 'rgba(51, 51, 51, 0.6)' }}
                    flexDirection="column"
                  >
                    <FormattedMessage id="catalogue.message" />
                  </Text>
                  <Text style={{ textTransform: 'capitalize', fontSize: '13px', fontWeight: '700' }}>
                    {singleBundleApiData.catalog_number}
                  </Text>
                </Box>
              </Flex>
            </div>
          </Box>
          <SoftwareBundleEditScreenInputs />
          <Box display="flex" h={`calc(100vh - 180px)`}>
            <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 470px)' : 'calc(100vw - 300px)' }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};
export default SoftwareBundleEditScreenInfo;
