import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import CustomTable from 'components/customTable/CustomTable';
import moment from 'moment';

function SingleOrgDevicesActivityListView() {
  const { orgId } = useParams();
  const singleOrganizationDeviceActivityData = useAppSelector(
    (state) => state.singleOrgDevicesActivitySlice.organizationSingleDevicesActivityList,
  );

  const navigate = useNavigate();
  const intl = useIntl();
  const route = 'devicesactivity';

  const data = Array.isArray(singleOrganizationDeviceActivityData)
    ? singleOrganizationDeviceActivityData &&
      singleOrganizationDeviceActivityData.map((device: any) => {
        // TODO: remove this conditional orgId and make is consistent
        const org_id = orgId ? orgId : device?.org_id || '';
        return {
          user_name: device?.name || '-',
          two_rows_1: device?.mobile_device + '*' + device?.mobile_os,
          userId: device?.user_id,
          orgId: org_id,
          firmware: 'Firmware ' + device?.firmware + '*' + 'App ' + device?.app_version || '-',
          app_version: device?.app_version || '-',
          event: device?.event
            ? intl.formatMessage(
                { id: `device_activity_events.${device?.event}.message` },
                { value: device?.activity_value ? device?.activity_value : '' },
              )
            : '-',
          device_health: device?.device_health,
          date_time: device.date_and_time ? (
            moment.utc(device.date_and_time).format('MMM DD, YYYY HH:mm')
          ) : (
            <FormattedMessage id="empty.message" />
          ),
        };
      })
    : [];
  const columns = [
    { field: 'user_name', header: <FormattedMessage id="name.message" />, link: true },
    { field: 'two_rows_1', header: <FormattedMessage id="mobile_device_info.message" />, link: false },
    { field: 'firmware', header: <FormattedMessage id="firmware_app_version.message" />, link: false },
    {
      field: 'event',
      header: <FormattedMessage id="eventAndHealth.message" />,
      link: false,
      infoData:
        'The temperature displayed holds the maximum of all the temperature values collected from different Iris components. The battery value displayed hold’s Iris’s battery captured during the event.',
    },
    { field: 'date_time', header: <FormattedMessage id="date_time.message" />, link: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'user_name') {
      navigate(`/organizations/${row?.orgId}/users/${row.userId}/info`);
    } else {
      navigate(``);
    }
  };
  return (
    <Box data-testid="singleorg-activity">
      <CustomTable data={data} columns={columns} route={route} handleNavigateFromChild={handleNavigateFromChild} />
    </Box>
  );
}
export default SingleOrgDevicesActivityListView;
