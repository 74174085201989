import React from 'react';
import { Text, Flex, Box, Icon, useColorModeValue } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { AddIcon } from 'assets/icons/icons';
import OrgLicenseCreateModal from '../orglicenseModal/OrgLicenseCreateModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleOrgLicenseModalPopup } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import { userAccessConst } from 'utils/constants/userAccessConst';

export default function Title() {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');
  const handleLicensemodal = () => {
    dispatch(handleOrgLicenseModalPopup(true));
  };
  return (
    <Flex alignItems="center" h="50px">
      <Text mr="3" fontSize="24px" fontWeight="700" color={colors.textColor} data-testid="devices">
        <FormattedMessage id="license_bundles.message" />
      </Text>
      {userPermissions.organizations?.includes(userAccessConst.organization.addLicense) && (
        <Box>
          <Flex alignItems="center">
            <Icon
              data-testid="plus-icon"
              w="24px"
              h="24px"
              onClick={handleLicensemodal}
              color={textColorMode}
              fill={fillColorMode}
              _hover={{
                color: 'url("#BlueGradient")',
                cursor: 'pointer',
                stroke: 'white',
                fill: 'url("#BlueGradient")',
                '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                '&:hover>svg>circle': { stroke: strokeColorMode },
              }}
            >
              <AddIcon stroke={primarblackAndWhiteColorMode} />
            </Icon>
          </Flex>
          <OrgLicenseCreateModal />
        </Box>
      )}
    </Flex>
  );
}
