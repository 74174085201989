import { Box, Circle, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PacsWarning = ({ isDark }: { isDark: boolean }) => {
  return (
    <Popover placement="bottom-start" trigger="hover">
      <PopoverTrigger>
        <Box sx={{ position: 'relative', display: 'inline-block', paddingRight: '3px', paddingTop: '2px' }}>
          <Circle
            size="13px"
            backgroundColor="brandOrange.50"
            color={isDark ? 'brandBlack.50' : 'brandWhite.50'}
            fontSize="10px"
          >
            !
          </Circle>
          <PopoverContent
            width="200px"
            outline="none"
            borderRadius="10px"
            _focus={{
              boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.12)',
            }}
            mt="4px"
            boxShadow="0 0 8px 0 rgba(0, 0, 0, 0.12)"
            px="2px"
          >
            <PopoverArrow bg="brandWhite.50" />
            <Text
              width="100%"
              height="40px"
              fontSize="14px"
              display="flex"
              justifyContent="center"
              color="black"
              alignItems="center"
            >
              <FormattedMessage id="push_to_pacs_failed.message" />
            </Text>
          </PopoverContent>
        </Box>
      </PopoverTrigger>
    </Popover>
  );
};
