import { createSlice } from '@reduxjs/toolkit';
import { StatusType } from 'types/organization-types';
import { PaginationTypes } from 'types/pagination-types';

type InitialState = {
  orgUsersActivityData: string[] | null;
  orgUsersActivityDataLoading: boolean;
  orgUsersActivityDataError: boolean;
  orgUsersActivityDataFail: boolean;
  orgUsersActivityNumberOfPages: number;
  orgUsersActivityPaginationData: PaginationTypes | null;
  isOrgUsersActivityFilterApplied: boolean;
  searchText: string;
  isSearchExpand: boolean;
  orgUsersActivityFilterData: { latest_id: string[] };
  latestData: StatusType[];
  latestFilterValue: string;
  orgUsersActivitySelectedPage: number;
  selectedOrderValue: number;
};

export const initialState: InitialState = {
  orgUsersActivityData: [],
  orgUsersActivityDataLoading: false,
  orgUsersActivityDataError: false,
  orgUsersActivityDataFail: false,
  orgUsersActivityNumberOfPages: 1,
  orgUsersActivityPaginationData: null,
  isOrgUsersActivityFilterApplied: false,
  latestData: [],
  searchText: '',
  isSearchExpand: false,
  orgUsersActivityFilterData: { latest_id: [] },
  latestFilterValue: '',
  orgUsersActivitySelectedPage: 1,
  selectedOrderValue: 0,
};

const orgUserActivitySlice = createSlice({
  name: 'orgUserActivity',
  initialState,
  reducers: {
    getOrgUsersActivityPageNumber: (state, { payload }) => {
      state.orgUsersActivitySelectedPage = payload;
    },
    handleOrgUsersActivityFilterChange: (state, { payload }) => {
      state.latestFilterValue = payload;
    },
    getOrgUsersActivitySearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    getOrgUsersActivityFlterData: (state, { payload }) => {
      state.orgUsersActivityFilterData = payload;
    },
    expandOrgUsersActivitySearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeOrgUsersActivitySearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearOrgUsersActivitySearcText: (state) => {
      state.searchText = '';
    },

    fetchOrgUsersActivityDataLoading: (state) => {
      state.orgUsersActivityDataLoading = true;
      state.orgUsersActivityDataError = false;
    },
    fetchOrgUsersActivityDataSuccess: (state, { payload }) => {
      state.orgUsersActivityData = payload.data;
      state.orgUsersActivityPaginationData = payload.pagination;
      state.orgUsersActivityNumberOfPages = payload.pagination.num_pages;
      state.orgUsersActivityDataLoading = false;
      state.orgUsersActivityDataError = false;
    },
    fetchOrgUsersActivityDataError: (state) => {
      state.orgUsersActivityDataError = true;
      state.orgUsersActivityDataLoading = false;
    },

    getUserActivityFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
  },
});

export const {
  fetchOrgUsersActivityDataError,
  fetchOrgUsersActivityDataLoading,
  fetchOrgUsersActivityDataSuccess,
  expandOrgUsersActivitySearchInMobile,
  clearOrgUsersActivitySearcText,
  closeOrgUsersActivitySearchInMobile,
  getOrgUsersActivityFlterData,
  getOrgUsersActivityPageNumber,
  getOrgUsersActivitySearchText,
  handleOrgUsersActivityFilterChange,
  getUserActivityFilterValue,
} = orgUserActivitySlice.actions;
export default orgUserActivitySlice.reducer;
