import { Box, Wrap, WrapItem, Text } from '@chakra-ui/react';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  getDeviceTypeUniqueInternalNameValidation,
  getDeviceTypeUniqueNameValidation,
} from 'redux/deviceTypes/Actions/DeviceTypeActions';
import {
  fetchDeviceTypeInternalNameUniqueValidationFail,
  fetchDeviceTypeInternalNameValidation,
  fetchDeviceTypeNameUniqueValidationFail,
  fetchDeviceTypeNameValidation,
  getDeviceTypeNameInputValue,
  getDeviveTypeInternalName,
} from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';

const InternalNameInputFields = () => {
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<any>(null);
  const deviceTypeNameValue = useAppSelector((state) => state.deviceTypes.getDeviceTypeNameInputValue);
  const deviceTypeInternalNameValue = useAppSelector((state) => state.deviceTypes.getDeviveTypeInternalName);
  const deviceTypeNameValidation = useAppSelector((state) => state.deviceTypes.deviceTypeNameValidation);

  const [nameSpecialValidation, setNameSpecialValidation] = useState(false);
  const [internalNameSpecialValidation, setInternalNameSpecialValidation] = useState(false);
  const validatorArr = [' ', ',', '.', '-', '_'];

  const deviceTypeInternalNameValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeInternalNameValidation,
  );
  const deviceTypeInternalNameUniqueValueFail = useAppSelector(
    (state) => state.deviceTypes.deviceTypeInternalNameUniqueValueFail,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNameValue = (e: any) => {
    if (validatorArr.includes(e.target.value.split('')[0])) {
      setNameSpecialValidation(true);
      dispatch(fetchDeviceTypeNameValidation(false));
      dispatch(getDeviceTypeNameInputValue(e.target.value));
    } else {
      setNameSpecialValidation(false);
      dispatch(getDeviceTypeNameInputValue(e.target.value));
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        dispatch(
          getDeviceTypeUniqueNameValidation({
            field_name: 'device_name',
            field_value: e.target.value,
          }),
        );
      }, 500);
      dispatch(fetchDeviceTypeNameUniqueValidationFail(false));
      dispatch(fetchDeviceTypeNameValidation(false));
      setTimer(newTimer);
    }
  };
  const handleNameOnBlur = () => {
    if (deviceTypeNameValue.length == 0) {
      dispatch(fetchDeviceTypeNameValidation(true));
    } else {
      dispatch(fetchDeviceTypeNameValidation(false));
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInternalNameValue = (e: any) => {
    if (validatorArr.includes(e.target.value.split('')[0])) {
      setInternalNameSpecialValidation(true);
      dispatch(fetchDeviceTypeInternalNameValidation(false));
      dispatch(getDeviveTypeInternalName(e.target.value));
    } else {
      setInternalNameSpecialValidation(false);
      dispatch(getDeviveTypeInternalName(e.target.value));
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        dispatch(
          getDeviceTypeUniqueInternalNameValidation({
            field_name: 'internal_name',
            field_value: e.target.value,
          }),
        );
      }, 500);
      dispatch(fetchDeviceTypeInternalNameUniqueValidationFail(false));
      dispatch(fetchDeviceTypeInternalNameValidation(false));
      setTimer(newTimer);
    }
  };
  const handleInternalNameOnBlur = () => {
    if (deviceTypeInternalNameValue.length == 0) {
      dispatch(fetchDeviceTypeInternalNameValidation(true));
    } else {
      dispatch(fetchDeviceTypeInternalNameValidation(false));
    }
  };
  return (
    <Wrap spacingX="30px">
      <Box>
        <InputLabel value={deviceTypeNameValue} name={'name'} />
        <WrapItem>
          <Box display="flex" flexDirection="column" minH="70px">
            <CustomInputField
              defaultValue={''}
              name={'name'}
              onKeyPress={specialCharacterValidation}
              maxLength={50}
              handleOnChange={handleNameValue}
              handleOnBlur={handleNameOnBlur}
              isEditable={true}
            />
            <Text fontSize="12px" color="#FB5654" mt="-40px">
              {deviceTypeNameValidation ? <FormattedMessage id="field_validation.message" /> : ''}
              {nameSpecialValidation ? <FormattedMessage id="name_special_validation.message" /> : ''}
            </Text>
          </Box>
        </WrapItem>
      </Box>
      <Box>
        <InputLabel value={deviceTypeInternalNameValue} name={'internal_name'} />
        <WrapItem>
          <Box display="flex" flexDirection="column" minH="70px">
            <CustomInputField
              defaultValue={''}
              name={'internal_name'}
              onKeyPress={specialCharacterValidation}
              maxLength={50}
              handleOnChange={handleInternalNameValue}
              handleOnBlur={handleInternalNameOnBlur}
              isEditable={true}
            />
            <Text fontSize="12px" color="#FB5654" mt="-40px">
              {deviceTypeInternalNameUniqueValueFail ? <FormattedMessage id="name_identifier_exists.message" /> : ''}
              {deviceTypeInternalNameValidation ? <FormattedMessage id="field_validation.message" /> : ''}
              {internalNameSpecialValidation ? <FormattedMessage id="internal_name_special_validation.message" /> : ''}
            </Text>
          </Box>
        </WrapItem>
      </Box>
    </Wrap>
  );
};

export default InternalNameInputFields;
