import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AdminState } from './admin/admin.slice';
import { UserState } from './User/user.slice';
import { OrgState } from '../redux/infromation/information.slice';
import { DeviceState } from './Device/device.slice';
import { LicenseState } from './bundleLicnese/Reducers/license.slice';
import { CustomState } from './customModal/CustomAdminModalSlice';
import { OnboardingConfigState } from '@exo/shared-ui';
import { SpecialtyState } from './specialty/specialty.slice';
import { RoleState } from './roles/roles.slice';
import { ConnectionState } from './connection/connection.slice';
import { SAMLState } from './authentication/saml.slice';

export type RootState = {
  organization: any;
  user: UserState;
  admin: AdminState;
  org: OrgState;
  device: DeviceState;
  license: LicenseState;
  CustomAdminModal: CustomState;
  gettingStarted: OnboardingConfigState;
  specialty: SpecialtyState;
  role: RoleState;
  connection: ConnectionState;
  saml: SAMLState;
};

type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
