/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { Dispatch } from 'react';
import { fetchSingleSignOnCheckFail } from '../../../../../../libs/shared-ui/src';
import {
  fetchLanguageFail,
  fetchLanguageLoading,
  fetchLanguageSuccess,
  fetchOrganizationsLoading,
  fetchOrganizationSuccess,
  fetchOrganizationPostDataFail,
  fetchOrganizationPostDataLoading,
  fetchOrganizationPostDataSuccess,
  fetchOrganizationPostToggle,
  fetchOrganizationCodeLoading,
  fetchOrganizationCodeDataSuccess,
  fetchOrganizationCodeFail,
  fetchOrganizationCodeData,
  fetchcreateOrganizationApiFail,
  fetchOrganizationNameLoading,
  fetchOrganizationNameDataSuccess,
  fetchOrganizationNameData,
  fetchOrganizationNameFail,
  fetchOrganizationNameStatus,
  fetchOrganizationFail,
  fetchSingleOrganizationDataFail,
  fetchSingleOrganizationDataLoading,
  fetchSingleOrganizationDataSuccess,
  fetchEditOrganizationInfoApiFail,
  editOrganizationInfoDataLoading,
  editOrganizationInfoDataSuccess,
  editOrganizationInfoDataFail,
  fetchOrganizationAutoPopulateData,
  fetchOrganizationAutoPopulateLoading,
  fetchOrganizationAutoPopulateSuccess,
  getOrganizationEmailValidationFail,
  getOrganizationEmailValidationLoading,
  getOrganizationEmailValidationSuccess,
  fetchBundleFail,
  fetchBundleSuccess,
  getOrgDeletePostDataFailStrings,
  handlePostApiFailureMessage,
  // fetchOrganizationDurationLoading,
  // fetchOrganizationDurationSuccess,
  // fetchOrganizationDurationFail,
  fetchLicenseItems,
  saveOrgLicenseItemsStatus,
  setOrganizationOwnerInfo,
} from '../Reducers/organizationSlice';
import {
  fetchStatusFail,
  fetchStatusLoading,
  fetchStatusSuccess,
  fetchTiersFail,
  fetchTiersLoading,
  fetchTiersSuccess,
} from '../Reducers/organizationSlice';
import { OrganizationPostType, OrgAutoPopulateInfo, OrgCode, OrgName } from '../../../types/organization-types';
import { Error_Type_Enum } from '../../../utils/enum';
import { setError } from '../../error/errorSlice';
import { userRequest } from 'api';
import {
  getClientPostDataFailStrings,
  getClientPostDataFail,
  getClientPostDataLoading,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { FeatureFlag } from 'types/FeatureFlag';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getOrganizations =
  (queryString?: string, expiringData?: boolean, licenseData?: boolean) =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchOrganizationsLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      let queryParams = queryString || '';
      if (expiringData) {
        queryParams += `${queryString ? '&' : ''}license_expiration_window=30`;
      }
      if (licenseData) {
        queryParams += `${queryString ? '&' : ''}exceeded_license_quota=true`;
      }
      const res = await userRequest.get(`/organizations/?${queryParams}`);
      if (res && res.status === 200) {
        dispatch(fetchOrganizationSuccess(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchSingleSignOnCheckFail(error?.message));
      dispatch(fetchOrganizationFail());
    }
  };

// commented for future use
// export const getOrganizationDuration = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
//   dispatch(fetchOrganizationDurationLoading());
//   try {
//     dispatch(setError({ isError: false, errorMessage: '' }));
//     const res = await userRequest.get(`/organizations/${id}/remainingdays/`);
//     if (res && res.status === 200) {
//       dispatch(fetchOrganizationDurationSuccess(res.data));
//     }
//   } catch (error: any) {
//     dispatch(fetchOrganizationDurationFail());
//   }
// };
export const getTiers = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchTiersLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('organizations/tiers/');
    if (res && res.status === 200) {
      dispatch(fetchTiersSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchTiersFail());
  }
};
export const getStatus = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchStatusLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('organizations/status/');
    if (res && res.status === 200) {
      dispatch(fetchStatusSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchStatusFail());
  }
};

export const getLanguage = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchLanguageLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`languages`);
    if (res && res.status === 200) {
      dispatch(fetchLanguageSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchLanguageFail());
  }
};
export const postOrganizationsData = (postData: OrganizationPostType) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrganizationPostDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/register/`, postData);
    if (res && (res.status === 200 || res.status === 201)) {
      dispatch(fetchOrganizationPostDataSuccess(res?.data?.message));
    } else {
      dispatch(fetchOrganizationPostDataSuccess(res?.data?.message));
      dispatch(fetchcreateOrganizationApiFail(Error_Type_Enum.Fail));
      dispatch(handlePostApiFailureMessage(res?.data?.message[0]));
    }
  } catch (error: any) {
    // dispatch(setError({ isError: true, errorMessage: error?.message || error?.response?.data.errors.toString() }));
    dispatch(fetchcreateOrganizationApiFail(Error_Type_Enum.Fail));
    dispatch(fetchOrganizationPostToggle(true));
    dispatch(handlePostApiFailureMessage(error?.response?.data?.message));

    dispatch(fetchOrganizationPostDataFail(true));
  }
};
export const getOrganizationCodeData = (posOrgCode: OrgCode) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrganizationCodeLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/orgcode/`, posOrgCode);
    if (res && res.data.status_code === 404) {
      dispatch(fetchOrganizationCodeData(posOrgCode.org_code));
      dispatch(fetchOrganizationCodeDataSuccess(res.data.status_code));
    } else if (res && res.data.status_code === 200) {
      dispatch(fetchOrganizationCodeFail(true));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchOrganizationCodeFail(false));
  }
};
export const getOrganizationAutoPopulateData =
  (posAutoPopulateInfo: OrgAutoPopulateInfo) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchOrganizationAutoPopulateLoading(true));
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(
        `organizations/generate/?first_name=${posAutoPopulateInfo.first_name}&last_name=${posAutoPopulateInfo.last_name}&tier=${posAutoPopulateInfo.tier}`,
      );
      if (res && res.data.status_code === 200) {
        dispatch(fetchOrganizationAutoPopulateData(res.data));
        dispatch(fetchOrganizationCodeFail(false));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchOrganizationAutoPopulateLoading(true));
      dispatch(fetchOrganizationCodeFail(false));
    }
  };
export const getOrganizationNameData = (posOrgName: OrgName) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrganizationNameLoading(true));
  dispatch(fetchOrganizationNameFail(false));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/orgname/`, posOrgName);
    dispatch(fetchOrganizationNameLoading(false));
    if (res && res.data.status_code === 200) {
      dispatch(fetchOrganizationNameFail(true));
      dispatch(fetchOrganizationNameStatus(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchOrganizationNameLoading(false));
    dispatch(fetchOrganizationNameFail(false));
  }
};
export const getSingleOrganizationsData = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchSingleOrganizationDataLoading());

  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/${id}/detail/`);
    if (res && res.status === 200) {
      dispatch(fetchSingleOrganizationDataSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchSingleOrganizationDataFail());
  }
};
export const editOrganizationsInfoData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(editOrganizationInfoDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`organizations/${id}/detail/`, postData);
    if (res && (res.status === 200 || res.status === 201)) {
      dispatch(editOrganizationInfoDataSuccess(res.data.result));
      dispatch(fetchEditOrganizationInfoApiFail(Error_Type_Enum.Success));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    // dispatch(fetchOrganizationPostToggle(true));
    dispatch(fetchEditOrganizationInfoApiFail(Error_Type_Enum.Fail));
    dispatch(editOrganizationInfoDataFail());
  }
};
export const getOrganizationEmailValidation = (postEmailData: OrgName) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(getOrganizationEmailValidationLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/users/email/`, postEmailData);
    if (res && res.data.status_code === 404) {
      dispatch(getOrganizationEmailValidationSuccess(postEmailData));
      // dispatch(fetchOrganizationNameDataSuccess(res.data.status));
      dispatch(getOrganizationEmailValidationFail(false));
    } else if (res && res.data.status_code === 200) {
      dispatch(getOrganizationEmailValidationFail(true));
      // dispatch(fetchOrganizationNameStatus(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

export const postDeleteOrgData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.delete(`organizations/${id}/delete/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getOrgDeletePostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
    } else {
      dispatch(getClientPostDataFail(res.data));
      dispatch(getOrgDeletePostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(getClientPostDataLoading(false));
      dispatch(handlePostApiFailureMessage(res && res?.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message + '_' + 'DELETE'));
    dispatch(getOrgDeletePostDataFailStrings(Error_Type_Enum.Fail));
  }
};
export const provisionOrganization = (id: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`organizations/${id}/provision`);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
    } else {
      dispatch(getClientPostDataFail(res && res?.data));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(getClientPostDataLoading(false));
      dispatch(handlePostApiFailureMessage(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};
export const postDeactivateData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`organizations/${id}/deactivate/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
    } else {
      dispatch(getClientPostDataFail(res.data.status_code));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(handlePostApiFailureMessage(res.data.message));
      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message + '_' + 'DEACTIVATE'));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};
export const postActivateData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`organizations/${id}/activate/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
    } else {
      dispatch(getClientPostDataFail(res && res?.data));
      dispatch(handlePostApiFailureMessage(res.data));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));

      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};
export const postPauseData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    const res = await userRequest.put(`organizations/${id}/pause/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
    } else {
      dispatch(getClientPostDataFail(res && res?.data));
      dispatch(handlePostApiFailureMessage(res.data));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));

      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};

export const getOrgLicenseItems = (orgId: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    const res = await userRequest.get(`organizations/${orgId}/license-items/`);
    if (res && res.data.status_code == 200) {
      var licenseItems = res.data.data.items.map((feature: any) => {
        let featureItem = {
          id: feature.id,
          name: feature.code,
          isParent: feature.items?.length > 0,
          isEnabled: feature.pulse_enabled,
        } as FeatureFlag;
        if (feature.items && feature.items.length) {
          featureItem.children = feature.items.map((cfeature: any) => {
            return {
              id: cfeature.id,
              name: cfeature.code,
              isParent: false,
              isEnabled: cfeature.pulse_enabled,
            } as FeatureFlag;
          });
        }
        return featureItem;
      });
      dispatch(fetchLicenseItems(licenseItems));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

export const saveOrgLicenseItems = (orgId: string, postData: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(saveOrgLicenseItemsStatus(''));
  try {
    const res = await userRequest.put(`organizations/${orgId}/license-items/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(saveOrgLicenseItemsStatus(Error_Type_Enum.Success));
    } else {
      dispatch(saveOrgLicenseItemsStatus(Error_Type_Enum.Fail));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(saveOrgLicenseItemsStatus(Error_Type_Enum.Fail));
  }
};

export const getOrganizationOwnerInfoByEmail = (email: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/users/email/?email=${encodeURIComponent(email)}`);
    if (res && res.status === 200) {
      dispatch(setOrganizationOwnerInfo(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(setOrganizationOwnerInfo({}));
  }
};
