/* eslint-disable max-lines */
/* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/
import { createSlice } from '@reduxjs/toolkit';
import { Device, DeviceApiResponse, DeviceType, StatusType } from 'types/device-types';
import { PaginationTypes } from 'types/pagination-types';

type InitialState = {
  searchText: string;
  isSearchExpand: boolean;
  devicesList: Device[];
  devicesGridView: boolean;
  deviceTypesData: DeviceType[];
  deviceTypesLoading: boolean;
  statusLoading: boolean;
  deviceTypesError: boolean;
  statusError: boolean;
  statusData: StatusType[];
  statusFilterValue: string;
  deviceTypesFilterValue: string;
  devicesApiData: DeviceApiResponse | null;
  devicesListLoading: boolean;
  devicesListError: boolean;
  paginationData: null | PaginationTypes;
  numberOfPages: number;
  selectedPage: number;
  filterData: { status_id: string[]; device_catalogue_id: string[] };
  selectedDevice: string;
  handleCreateDevicePopUp: boolean;
  getDeviceTypesLoading: boolean;
  getDeviceTypesFail: boolean;
  getDeviceTypesData: string[];
  getDeviceStatusLoading: boolean;
  getDeviceStatusFail: boolean;
  getDeviceStatusData: any[];
  getDeviceSerialNumberLoading: boolean;
  getDeviceSerialNumberData: string[];
  getDeviceSerialNumberFail: boolean;
  getDeviceSerialInputNumber: string;
  getDeviceSerialNumberLength: boolean;
  getDeviceTypeValue: string | number;
  getDeviceSerialFieldValidation: boolean;
  getDeviceUDIDNumberLoading: boolean;
  getDeviceUDIDNumberData: string[];
  getDeviceUDIDNumberFail: boolean;
  getDeviceUDIDInputNumber: string;
  getDeviceUDIDNumberLength: boolean;
  getDeviceUDIDFieldValidation: boolean;
  getDeviceIDInputValue: string;
  getDeviceIDNumberLength: boolean;
  createDeviceApiFail: string;
  getDeviceManufacturedYear: string;
  postDeviceFormData: [];
  postDeviceFormDataLoading: boolean;
  postDeviceFormDataFail: boolean;
  postDeviceFormDataToggle: boolean;
  postDeviceApiFail: string;
  getDeviceCatalogueValue: string;
  deviceStatusInputValue: string;
  getDeviceManufacturedDateValidation: boolean;
  getDeviceManufacturedDay: string;
  deviceCreateButton: boolean;
  deviceDatePicker: string;
  deviceSuccesCreation: boolean;
  postDeviceToggle: boolean;
  postDeviceData: boolean;
  deviceButtonEnable: boolean;
  deviceCreateButtonEnable: boolean;
  deviceOrganizationNameSearchValue: string;
  orgNameSearchValue: string;
  orgNameSearchLoading: boolean;
  orgNameSearchData: string[];
  orgNameSearchFail: boolean;
  deviceCatelogueValue: string;
  orgNamePostValue: string;
  deviceTypesFilterData: DeviceType[];
  devicesTypesData: string[];
  triggerDevicesApi: boolean;
  scannedValue: string;
  validQRcode: boolean;
  orgNameInputLength: boolean;
  devicePostApiSuccess: boolean;
  getOrganizationNameSearchValue: string;
  deviceTypeFieldValidation: boolean;
  deviceListViewLoading: boolean;
};
export const initialState: InitialState = {
  searchText: '',
  isSearchExpand: false,
  devicesList: [],
  devicesGridView: true,
  deviceTypesData: [],
  deviceTypesLoading: false,
  statusLoading: false,
  deviceTypesError: false,
  statusError: false,
  statusData: [],
  statusFilterValue: '',
  deviceTypesFilterValue: '',
  devicesApiData: null,
  devicesListLoading: false,
  devicesListError: false,
  paginationData: null,
  numberOfPages: 1,
  selectedPage: 1,
  filterData: {
    status_id: [],
    device_catalogue_id: [],
  },
  selectedDevice: '',
  handleCreateDevicePopUp: false,
  getDeviceTypesLoading: false,
  getDeviceTypesFail: false,
  getDeviceTypesData: [],
  getDeviceStatusLoading: false,
  getDeviceStatusFail: false,
  getDeviceStatusData: [],
  getDeviceSerialNumberLoading: false,
  getDeviceSerialNumberData: [],
  getDeviceSerialNumberFail: false,
  getDeviceSerialInputNumber: '',
  getDeviceSerialNumberLength: false,
  getDeviceTypeValue: '',
  getDeviceSerialFieldValidation: false,
  getDeviceUDIDNumberLoading: false,
  getDeviceUDIDNumberData: [],
  getDeviceUDIDNumberFail: false,
  getDeviceUDIDInputNumber: '',
  getDeviceUDIDNumberLength: false,
  getDeviceUDIDFieldValidation: false,
  getDeviceIDInputValue: '',
  getDeviceIDNumberLength: false,
  createDeviceApiFail: '',
  getDeviceManufacturedYear: '',
  postDeviceFormData: [],
  postDeviceFormDataLoading: false,
  postDeviceFormDataFail: false,
  postDeviceFormDataToggle: false,
  postDeviceApiFail: '',
  getDeviceCatalogueValue: '',
  deviceStatusInputValue: '',
  getDeviceManufacturedDateValidation: false,
  getDeviceManufacturedDay: '',
  deviceCreateButton: false,
  deviceDatePicker: '',
  deviceSuccesCreation: false,
  postDeviceToggle: false,
  postDeviceData: false,
  deviceButtonEnable: false,
  deviceCreateButtonEnable: false,
  deviceOrganizationNameSearchValue: '',
  orgNameSearchValue: '',
  orgNameSearchLoading: false,
  orgNameSearchData: [],
  orgNameSearchFail: false,
  deviceCatelogueValue: '',
  orgNamePostValue: '',
  deviceTypesFilterData: [],
  devicesTypesData: [],
  triggerDevicesApi: false,
  scannedValue: '',
  validQRcode: false,
  orgNameInputLength: false,
  devicePostApiSuccess: false,
  getOrganizationNameSearchValue: '',
  deviceTypeFieldValidation: false,
  deviceListViewLoading: false,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    resetFilters: (state) => {
      state.filterData = {
        status_id: [],
        device_catalogue_id: [],
      };
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    handleStatusFilterChange: (state, { payload }) => {
      state.statusFilterValue = payload;
    },
    fetchDeviceTypesLoading: (state) => {
      state.deviceTypesLoading = true;
      state.deviceTypesError = false;
    },
    fetchDeviceTypesSuccess: (state, { payload }) => {
      const data = payload?.existing_catalogues.concat(payload?.non_existing_catalogues);
      state.deviceTypesData = data;
      state.deviceTypesFilterData = payload.existing_catalogues;
      state.deviceTypesLoading = false;
      state.deviceTypesError = false;
    },
    fetchDeviceTypesFail: (state) => {
      state.deviceTypesError = true;
      state.deviceTypesLoading = false;
    },
    fetchDeviceTypeCatalogueValue: (state, { payload }) => {
      state.deviceCatelogueValue = payload;
    },

    fetchOrgNameSearchLoading: (state) => {
      state.orgNameSearchLoading = true;
      state.orgNameSearchFail = false;
    },
    fetchOrgNameSearchData: (state, { payload }) => {
      state.orgNameSearchData = payload;
      state.orgNameSearchLoading = false;
      state.orgNameSearchFail = false;
    },
    fetchOrgNameSearchFail: (state) => {
      state.orgNameSearchFail = true;
      state.orgNameSearchLoading = false;
    },

    getOrgNamePostValue: (state, { payload }) => {
      state.orgNamePostValue = payload;
    },

    fetchStatusLoading: (state) => {
      state.statusLoading = true;
      state.statusError = false;
    },
    fetchStatusSuccess: (state, { payload }) => {
      state.statusLoading = false;
      state.statusError = false;
      state.statusData = payload;
    },
    fetchStatusFail: (state) => {
      state.statusError = true;
      state.statusLoading = false;
    },
    fetchDevicesLoading: (state) => {
      state.devicesListLoading = true;
    },
    fetchDeviceListLoading: (state, { payload }) => {
      state.deviceListViewLoading = payload;
    },
    fetchDevicesFail: (state) => {
      state.devicesListLoading = false;
      state.devicesListError = true;
    },
    fetchDevicesuccess: (state, { payload }) => {
      state.devicesList = payload.data;
      state.devicesApiData = payload;
      state.paginationData = payload.pagination;
      state.numberOfPages = payload.pagination.num_pages;
      state.devicesListLoading = false;
    },
    toggleDevicesView: (state, { payload }) => {
      state.devicesGridView = payload;
    },
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },
    getSelectedDevice: (state, { payload }) => {
      state.selectedDevice = payload;
    },
    toggleCreateDevicePopUp: (state, { payload }) => {
      state.handleCreateDevicePopUp = payload;
      state.devicePostApiSuccess = false;
    },
    fetchDeviceTypeValue: (state, { payload }) => {
      state.getDeviceTypeValue = payload;
    },
    fetchDeviceStatusLoading: (state) => {
      state.getDeviceStatusLoading = true;
      state.getDeviceStatusFail = false;
    },
    fetchDeviceStatusSuccess: (state, { payload }) => {
      state.getDeviceStatusData = payload;
      state.getDeviceStatusLoading = false;
      state.getDeviceStatusFail = false;
    },
    fetchDeviceStatusFail: (state) => {
      state.getDeviceStatusFail = true;
      state.getDeviceStatusLoading = false;
    },
    fetchDeviceCatalogueValue: (state, { payload }) => {
      state.getDeviceCatalogueValue = payload;
    },
    fetchDeviceSerialNumberLoading: (state) => {
      state.getDeviceSerialNumberLoading = true;
      state.getDeviceSerialNumberFail = false;
    },
    fetchDeviceSerialNumberDataSuccess: (state, { payload }) => {
      state.getDeviceSerialNumberData = payload;
      state.getDeviceSerialNumberFail = false;
      state.getDeviceSerialNumberLoading = false;
    },
    fetchDeviceSerialNumberFail: (state, { payload }) => {
      state.getDeviceSerialNumberFail = payload;
      state.getDeviceSerialNumberLoading = false;
    },
    getDeviceSerialInputNumber: (state, { payload }) => {
      state.getDeviceSerialInputNumber = payload;
    },
    fetchDeviceSerialNumberMinLength: (state, { payload }) => {
      state.getDeviceSerialNumberLength = payload;
    },
    fetchDeviceSerialNumberFieldValidation: (state, { payload }) => {
      state.getDeviceSerialFieldValidation = payload;
    },
    getDeviceStatusInputValue: (state, { payload }) => {
      state.deviceStatusInputValue = payload;
    },
    fetchDeviceUDIDNumberLoading: (state) => {
      state.getDeviceUDIDNumberLoading = true;
      state.getDeviceUDIDNumberFail = false;
    },
    fetchDeviceUDIDNumberDataSuccess: (state, { payload }) => {
      state.getDeviceUDIDNumberData = payload;
      state.getDeviceUDIDNumberFail = false;
      state.getDeviceUDIDNumberLoading = false;
    },
    fetchDeviceUDIDNumberFail: (state, { payload }) => {
      state.getDeviceUDIDNumberFail = payload;
      state.getDeviceUDIDNumberLoading = false;
    },
    getDeviceUDIDInputNumber: (state, { payload }) => {
      state.getDeviceUDIDInputNumber = payload;
    },
    fetchDeviceUDIDNumberMinLength: (state, { payload }) => {
      state.getDeviceUDIDNumberLength = payload;
    },
    fetchDeviceUDIDFieldValidation: (state, { payload }) => {
      state.getDeviceUDIDFieldValidation = payload;
    },
    fetchDeviceManufacturedDateValidation: (state, { payload }) => {
      state.getDeviceManufacturedDateValidation = payload;
    },
    fetchDeviceManufacturedDay: (state, { payload }) => {
      state.getDeviceManufacturedDay = payload;
    },
    getDeviceIDInputNumberValue: (state, { payload }) => {
      state.getDeviceIDInputValue = payload;
    },
    fetchDeviceIDNumberMinLength: (state, { payload }) => {
      state.getDeviceIDNumberLength = payload;
    },
    fetchPostDeviceFormDataLoading: (state) => {
      state.postDeviceFormDataLoading = true;
      state.postDeviceFormDataFail = false;
    },
    fetchPostDeviceFormDataSuccess: (state, { payload }) => {
      state.postDeviceFormData = payload;
      state.postDeviceFormDataFail = false;
      state.postDeviceFormDataLoading = false;
    },
    fetchPostDeviceFormDataFail: (state, { payload }) => {
      state.postDeviceFormDataFail = payload;
      state.postDeviceFormDataLoading = false;
    },
    fetchDevicePostToggle: (state, { payload }) => {
      state.postDeviceToggle = payload;
    },
    fetchDevicePostApiFail: (state, { payload }) => {
      state.postDeviceApiFail = payload;
    },
    getDeviceCreateButtonValue: (state, { payload }) => {
      state.deviceCreateButton = payload;
    },
    getDeviceDatePicker: (state, { payload }) => {
      state.deviceDatePicker = payload;
    },
    fetchDeviceManufacturedYear: (state, { payload }) => {
      state.getDeviceManufacturedYear = payload;
    },
    fetchDeviceSuccessCreation: (state, { payload }) => {
      state.deviceSuccesCreation = payload;
    },
    fetchPostDeviceData: (state, { payload }) => {
      state.postDeviceData = payload;
    },
    fetchDeviceCreateButtonEnable: (state, { payload }) => {
      state.deviceButtonEnable = payload;
    },
    getDevicePostCreateButton: (state, { payload }) => {
      state.deviceCreateButtonEnable = payload;
    },
    getDeviceOrganizationNameSearchValue: (state, { payload }) => {
      state.deviceOrganizationNameSearchValue = payload;
    },
    getOrgNameSearchValue: (state, { payload }) => {
      state.orgNameSearchValue = payload;
    },
    fetchOrgNameInputLength: (state, { payload }) => {
      state.orgNameInputLength = payload;
    },
    handleDeviceTypeFieldValidation: (state, { payload }) => {
      state.deviceTypeFieldValidation = payload;
    },

    resetDevicePopUpErrors: (state) => {
      state.scannedValue = '';
      state.deviceTypeFieldValidation = false;
      state.getDeviceSerialFieldValidation = false;
      state.getDeviceManufacturedDateValidation = false;
      state.getDeviceUDIDFieldValidation = false;
      state.getDeviceIDNumberLength = false;
      state.getDeviceUDIDNumberLength = false;
      state.getDeviceSerialNumberLength = false;
      state.getDeviceSerialInputNumber = '';
      state.getDeviceIDInputValue = '';
      state.getDeviceUDIDInputNumber = '';
      state.postDeviceToggle = false;
      state.postDeviceApiFail = '';
      state.deviceCreateButton = false;
      state.deviceSuccesCreation = false;
      state.postDeviceData = false;
      state.postDeviceToggle = false;
      state.deviceButtonEnable = false;
      state.deviceCreateButtonEnable = false;
      state.createDeviceApiFail = '';
      state.getDeviceManufacturedYear = '';
      state.deviceDatePicker = '';
      state.orgNameSearchValue = '';
      state.getDeviceSerialNumberFail = false;
      state.getDeviceUDIDNumberFail = false;
      state.postDeviceFormDataFail = false;
      state.validQRcode = false;
      state.orgNamePostValue = '';
      state.orgNameInputLength = false;
      state.deviceStatusInputValue = '';
    },
    postApiSuccess: (state, { payload }) => {
      state.triggerDevicesApi = payload;
      state.devicePostApiSuccess = true;
    },
    getOrganizationNameSearchValue: (state, { payload }) => {
      state.getOrganizationNameSearchValue = payload;
    },
    getScannerValue: (state, { payload }) => {
      state.scannedValue = payload.value;
      state.validQRcode = payload.isValidQR;
      if (payload.isValidQR) {
        state.getDeviceUDIDInputNumber = payload.value.slice(2, 16);
        state.getDeviceSerialInputNumber = payload.value.slice(26, 39);
        state.getDeviceManufacturedDay = payload.value.slice(22, 24);
        state.getDeviceManufacturedYear = '20' + payload.value.slice(18, 20);
        state.deviceDatePicker = payload.value.slice(20, 22);
      } else {
        state.getDeviceUDIDInputNumber = '';
        state.getDeviceSerialInputNumber = '';
        state.getDeviceManufacturedDay = '';
        state.getDeviceManufacturedYear = '';
        state.deviceDatePicker = '';
      }
    },
  },
});

export const {
  getSearchText,
  clearSearcText,
  closeSearchInMobile,
  fetchDeviceListLoading,
  expandSearchInMobile,
  fetchStatusLoading,
  fetchStatusSuccess,
  fetchStatusFail,
  handleStatusFilterChange,
  fetchDevicesLoading,
  fetchDevicesFail,
  fetchDevicesuccess,
  handleDeviceTypeFieldValidation,
  fetchDeviceTypesSuccess,
  fetchDeviceTypesLoading,
  fetchDeviceTypesFail,
  toggleDevicesView,
  getFlterData,
  resetFilters,
  getPageNumber,
  getSelectedDevice,
  toggleCreateDevicePopUp,
  fetchDeviceSerialNumberLoading,
  fetchDeviceSerialNumberDataSuccess,
  fetchDeviceSerialNumberFail,
  getDeviceSerialInputNumber,
  fetchDeviceStatusSuccess,
  fetchDeviceStatusLoading,
  fetchDeviceStatusFail,
  fetchDeviceUDIDNumberLoading,
  fetchDeviceUDIDNumberDataSuccess,
  fetchDeviceUDIDNumberFail,
  getDeviceUDIDInputNumber,
  fetchDeviceUDIDNumberMinLength,
  getDeviceIDInputNumberValue,
  fetchDeviceIDNumberMinLength,
  resetDevicePopUpErrors,
  fetchPostDeviceFormDataLoading,
  fetchPostDeviceFormDataSuccess,
  fetchPostDeviceFormDataFail,
  fetchDevicePostToggle,
  fetchDeviceCatalogueValue,
  getDeviceStatusInputValue,
  getDeviceCreateButtonValue,
  getDeviceDatePicker,
  fetchDeviceSuccessCreation,
  fetchDeviceCreateButtonEnable,
  fetchPostDeviceData,
  fetchDeviceTypeValue,
  getDevicePostCreateButton,
  fetchDevicePostApiFail,
  fetchDeviceUDIDFieldValidation,
  getDeviceOrganizationNameSearchValue,
  fetchDeviceSerialNumberFieldValidation,
  fetchDeviceManufacturedYear,
  fetchDeviceManufacturedDateValidation,
  fetchDeviceManufacturedDay,
  fetchDeviceSerialNumberMinLength,
  fetchOrgNameSearchLoading,
  fetchOrgNameSearchData,
  fetchOrgNameSearchFail,
  getOrgNameSearchValue,
  fetchDeviceTypeCatalogueValue,
  getOrgNamePostValue,
  postApiSuccess,
  getScannerValue,
  fetchOrgNameInputLength,
  getOrganizationNameSearchValue,
} = deviceSlice.actions;

export default deviceSlice.reducer;
