import { PaginationTypes } from 'types/pagination-types';

export const displayPageInfo = (pagination: PaginationTypes, selectedPage: number) => {
  const noOfRows = selectedPage * pagination?.per_page;
  return {
    startingItem: noOfRows + 1 - pagination?.per_page,
    endingItem: noOfRows <= pagination.count ? noOfRows : pagination.count,
    totalCount: pagination.count,
  };
};
