import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useLocation } from 'react-router';
import { setActiveTabs } from 'redux/common/CommonSlice';
import SubTabs from 'components/subTabs/SubTabs';
import SoftwareBundlesMainPage from '../softwareBundlesLandingPage/LicenseMainPage';
import AddOnsContainer from 'pages/softwareBundles/add-ons/AddOnsContainer';
import FeaturesContainer from 'pages/softwareBundles/features/FeaturesContainer';
import { getBundleStatus, getBundleGracePeriod } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { getTiers } from 'redux/organizations/Actions/organizationActions';

const LicenseLandingScreen = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector((state) => state.common.activeTab);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const licenseBundleSubTabs = {
    Bundles: 'bundles',
    Add_ons: 'add-ons',
    Features: 'features',
  };
  useEffect(() => {
    dispatch(getBundleStatus());
    dispatch(getBundleGracePeriod());
    dispatch(getTiers());
  }, []);
  useEffect(() => {
    const currentTab = (query.get('tab') as string) || licenseBundleSubTabs.Bundles;
    dispatch(setActiveTabs(currentTab));
  }, [query]);

  return (
    <Box pl="30px" data-test-id="licenses-landing-page">
      <SubTabs selectedTab={selectedTab} routePaths={licenseBundleSubTabs} routePrefix={'/licenses'} />
      {selectedTab === licenseBundleSubTabs.Bundles && <SoftwareBundlesMainPage />}
      {selectedTab === licenseBundleSubTabs.Add_ons && <AddOnsContainer />}
      {selectedTab === licenseBundleSubTabs.Features && <FeaturesContainer />}
    </Box>
  );
};

export default LicenseLandingScreen;
