/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useEffect, useState } from 'react';
import { Box, Wrap, WrapItem, Button, Icon, Menu, MenuButton, Text, MenuList } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CalendarIcons } from 'assets/icons/icons';
import CustomInputField from 'components/customInputField/CustomInputField';
import { specialNumericDotValidation } from 'utils/validations/specialCharacterValidation';
import {
  fetchDeviceTypeManufacturedDateValidation,
  fetchDeviceTypeManufacturedDay,
  fetchDeviceTypeManufacturedMonth,
  fetchDeviceTypeManufacturedYear,
  getDeviceTypeHarwareInputValue,
} from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
const HardwareDateInputFields = () => {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const udidNumber = useAppSelector((state) => state.device.getDeviceUDIDInputNumber);
  const udidNumberLength = useAppSelector((state) => state.device.getDeviceUDIDNumberLength);
  const udidNumberValidation = useAppSelector((state) => state.device.getDeviceUDIDNumberFail);
  const datePicker = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedMonth);
  const deviceManufacturedYear = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedYear);
  const deviceManufacturedDateValidation = useAppSelector(
    (state) => state.deviceTypes.getDeviceTypeManufacturedValidation,
  );
  const selectedDays = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedDay);
  const [selectedDay, setSelectedDay] = useState<any>('');
  const calendarDateToday = utils(intl.locale).getToday();
  const deviceTypeHardwarevalue = useAppSelector((state) => state.deviceTypes.getDeviceTypeHarwareInputValue);

  const [hardwareSpecialValidation, setHardwareSpecialValidation] = useState(false);

  useEffect(() => {
    udidNumberLength;
    udidNumberValidation;
  }, [udidNumber]);
  function getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }
  const handleHardwareValue = (e: any) => {
    if (e.target.value.split('')[0] == '.') {
      setHardwareSpecialValidation(true);
      dispatch(getDeviceTypeHarwareInputValue(e.target.value));
    } else {
      setHardwareSpecialValidation(false);
      dispatch(getDeviceTypeHarwareInputValue(e.target.value));
    }
  };
  const handleChange = (e: any, onClose: () => void) => {
    setSelectedDay(e);
    onClose();
    dispatch(fetchDeviceTypeManufacturedDay(e.day));
    dispatch(fetchDeviceTypeManufacturedMonth(e.month));
    dispatch(fetchDeviceTypeManufacturedYear(e.year));
  };
  const handleDateChange = () => {
    if (deviceManufacturedYear.length == 0) {
      dispatch(fetchDeviceTypeManufacturedDateValidation(true));
    } else {
      dispatch(fetchDeviceTypeManufacturedDateValidation(false));
    }
  };
  return (
    <Wrap align="center" spacingX="30px" data-testid="container-element">
      <Box display="flex" flexDirection="column" minH="80px">
        <InputLabel value={deviceTypeHardwarevalue} name={'hardware_version'} />
        <WrapItem>
          <Box>
            <CustomInputField
              defaultValue={''}
              name={'hardware_version'}
              onKeyPress={specialNumericDotValidation}
              maxLength={16}
              handleOnChange={handleHardwareValue}
              handleOnBlur={undefined}
              isEditable={true}
            />
            <Text fontSize="12px" color="#FB5654" mt="-40px">
              {hardwareSpecialValidation ? <FormattedMessage id="hardware_special_validation.message" /> : ''}
            </Text>
          </Box>
        </WrapItem>
      </Box>
      <WrapItem>
        <Box minH="80px">
          <InputLabel value={datePicker} name="availability_date" />
          <Menu>
            {({ isOpen, onClose }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                  borderBottom="1px solid"
                  borderRadius="0px"
                  _focusVisible={{ borderBottomColor: colors.primaryColor }}
                  borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                  variant="flushed"
                  data-testid="license-info"
                  onBlur={handleDateChange}
                  rightIcon={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                >
                  {datePicker ? (
                    <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color={colors.subTextColor}>
                      {getMonthName(datePicker)} {selectedDays}, {deviceManufacturedYear}
                    </Text>
                  ) : (
                    <Text
                      float="left"
                      marginLeft="-15px"
                      fontSize="14px"
                      fontWeight="400"
                      color="#8c8c8c"
                      paddingTop="5px"
                    >
                      <FormattedMessage id="availability_date.message" />
                    </Text>
                  )}
                </MenuButton>
                <Box fontSize="12px" color="#FB5654">
                  {isOpen ? (
                    ''
                  ) : (
                    <>{deviceManufacturedDateValidation ? <FormattedMessage id="field_validation.message" /> : ''}</>
                  )}
                </Box>
                <MenuList
                  width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                  backgroundColor="transparent"
                  border="none"
                  onBlur={handleDateChange}
                  __css={{
                    '.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover':
                      {
                        color: colors.lightModeBlue,
                        background: 'none',
                      },
                    '.Calendar__monthArrowWrapper.-right': {
                      transform: 'none',
                    },
                    '.Calendar__monthArrowWrapper.-left': {
                      transform: 'rotate(180deg)',
                    },
                    '.Calendar__monthArrow': {
                      backgroundImage: "url('/assets/images/left-chevron.svg')",
                    },
                    '.Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus':
                      {
                        background: 'none',
                        color: colors.lightModeBlue,
                      },
                    '.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover':
                      {
                        background: 'none',
                        color: colors.lightModeBlue,
                      },
                  }}
                >
                  <Calendar
                    value={selectedDay}
                    data-testid="data-testid"
                    onChange={(e) => handleChange(e, onClose)}
                    colorPrimary={colors.lightModeBlue}
                    calendarClassName="custom-calendar"
                    calendarTodayClassName="custom-today-day"
                    minimumDate={{
                      year: calendarDateToday.year - 5, // past 5 years
                      month: calendarDateToday.month,
                      day: calendarDateToday.day,
                    }}
                    maximumDate={{
                      year: calendarDateToday.year + 2, // future 2 years
                      month: calendarDateToday.month,
                      day: calendarDateToday.day,
                    }}
                  />
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
      </WrapItem>
    </Wrap>
  );
};
export default HardwareDateInputFields;
