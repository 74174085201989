import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import moment from 'moment';
import { parseDaysIntoYears } from '@exo/shared-ui';

function OrgLicensesListView() {
  const singleOrganizationDeviceData = useAppSelector((state) => state.orgLicense.organizationLincesesList);

  const formatDate = (date: string) => {
    if (date) {
      return moment(date).format('ll').slice(0, 15);
    } else {
      return <FormattedMessage id="empty.message" />;
    }
  };

  const handleTrail = (is_trial: boolean, start_date: any, expire_date: any) => {
    if (is_trial) {
      return 'Yes' + '*' + formatDate(start_date) + ' - ' + formatDate(expire_date);
    } else return 'No' + '*' + '';
  };

  const data = Array.isArray(singleOrganizationDeviceData)
    ? singleOrganizationDeviceData &&
      singleOrganizationDeviceData.map((item: any) => {
        return {
          id: item.id,
          software_bundle: item?.license_bundle?.name,
          catalog: item?.license_bundle?.catalog_number,
          units: item?.quantity,
          activation_date: formatDate(item?.start_date),
          contract_duration: item?.validity_period ? parseDaysIntoYears(item?.validity_period) : '',
          expiration_date: formatDate(item?.expiration_date),
          device_status: item?.status?.status + '*' + item?.status?.color_code,
          ord_id: item.order_id ? item.order_id : '-',
          subscription: item.subscription_id || '-',
        };
      })
    : [];

  const columns = [
    { field: 'software_bundle', header: <FormattedMessage id="license_bundle.message" />, link: false },
    { field: 'ord_id', header: <FormattedMessage id="ord_id.message" />, link: false },
    { field: 'catalog', header: <FormattedMessage id="catalogue.message" />, link: false },
    { field: 'subscription', header: <FormattedMessage id="subscription.message" />, link: false },

    {
      field: 'activation_date',
      header: <FormattedMessage id="start_date.message" />,
      link: false,
      infoData: 'It is the date of first Iris shipped to the Organization for an order.',
    },
    {
      field: 'expiration_date',
      header: <FormattedMessage id="expiration_date.message" />,
      link: false,
      infoData: 'It is the date of software license expiration for an order',
    },
    { field: 'units', header: <FormattedMessage id="purchased_quantity.message" />, link: false },
    { field: 'device_status', header: <FormattedMessage id="status_field.message" />, link: false, color: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    // to handle the table click
  };

  return (
    <Box data-testid="org-device-list">
      <CustomTable data={data} columns={columns} handleNavigateFromChild={handleNavigateFromChild} />{' '}
    </Box>
  );
}
export default OrgLicensesListView;
