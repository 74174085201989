/* eslint-disable react/no-children-prop */
import { Input, InputGroup, InputLeftElement, Box, InputRightElement, Button } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { useIntl } from 'react-intl';

import { useMediaQuery } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import debounce from 'utils/debounce';
import { RootState } from '../../redux/Store/store';
import { customColor } from '../../assets/styles/colors';
import { toggleSidebar } from 'redux/systemSettings/systemSettingSlice';

interface Data {
  isSearchExpand: boolean;
  searchText: string;
}
const Search = ({
  data,
  handleClose,
  handleClear,
  handleSearch,
  handleExpandClick,
}: {
  data: Data;
  handleClose: () => void;
  handleClear: () => void;
  handleSearch: (value: string) => void;
  handleExpandClick: () => void;
}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const colors = customColor();
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  useEffect(() => {
    if (isMobileDevice) {
      handleClose();
      dispatch(toggleSidebar(false));
    }
  }, [isMobileDevice]);

  const debounceCb = debounce((value: string) => handleSearch(value), 300);
  return (
    <Box display="flex" alignItems="center" mt="-10px" w={{ xs: '70%', sm: '240px' }}>
      <InputGroup size="md" px="0">
        <InputLeftElement
          children={<FiSearch onClick={handleExpandClick} />}
          mx="0"
          color={colors.primaryColor}
          data-testid="organization_search_icon"
          _focus={{ color: colors.textColor, opacity: 1 }}
          w="15px"
          paddingTop="10px"
          position={!isMobileDevice || data.isSearchExpand ? 'absolute' : 'relative'}
          px="0"
        />
        {(!isMobileDevice || data.isSearchExpand) && (
          <Input
            placeholder={intl.formatMessage({ id: 'search.message' })}
            variant="flushed"
            data-testid="organization_search"
            fontSize={{ xs: '16px', lg: '16px' }}
            pl="6"
            w="240px"
            pr={{ xs: '15%', lg: '10%' }}
            borderColor={colors.textColor}
            ref={inputRef}
            type="text"
            paddingTop="10px"
            onChange={(e) => debounceCb(e.target.value)}
            fontWeight="400"
            _focus={{ color: colors.textColor, opacity: 1 }}
            color={colors.textColor}
            focusBorderColor={colors.primaryColor}
            borderBottomColor={colors.searchColor}
            borderWidth="0 0 1px 0"
            boxShadow="0px 1px 0px -1px common.100 !important"
          />
        )}
        {data.searchText.length > 0 && (
          <InputRightElement
            children={<AiOutlineClose color={colors.primaryColor} />}
            mx="0"
            py="0"
            paddingTop="10px"
            color={colors.primaryColor}
            data-testid="organization_search_close_icon"
            px="0"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.value = '';
              }
              handleClear();
            }}
          />
        )}
      </InputGroup>
      {isMobileDevice && data.isSearchExpand && (
        <Button
          onClick={handleClose}
          variant="ghost"
          color={colors.primaryColor}
          fontWeight="300"
          _hover={{ bg: 'none' }}
          ml="30px"
          data-testid="organization_search_cancel_text"
        >
          Cancel
        </Button>
      )}
    </Box>
  );
};

export default Search;
