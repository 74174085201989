/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';
import { PaginationTypes } from 'types/pagination-types';

type InitialState = {
  searchText: string;
  apiKeysList: any;
  statusLoading: boolean;
  statusError: boolean;
  apiKeysListLoading: boolean;
  apiKeysListError: boolean;
  createOrganizationApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  apiKeysApiData: any | null;
  apiKeysnumberOfPages: number;
  apiKeyspaginationData: PaginationTypes | null;
  SingleOrganizationDeviceDataLoading: boolean;
  SingleOrganizationDeviceDataError: boolean;
  SingleOrganizationsDeviceGetData: any;
  SingleOrganizationsDeviceGetDataLoading: boolean;
  SingleOrganizationsDeviceGetDataError: boolean;
  singleOrganizationID: string | number;
  isSearchExpand: boolean;
  deviceCreateFromOrg: boolean;
  selectedOrderValue: number;
};
export const initialState: InitialState = {
  searchText: '',
  apiKeysList: [],
  statusLoading: false,
  statusError: false,
  apiKeysListLoading: false,
  apiKeysListError: false,
  selectedPage: 1,
  createOrganizationApiFail: '',
  apiKeysApiData: null,
  apiKeysnumberOfPages: 1,
  filterData: { status_id: [] },
  apiKeyspaginationData: null,
  SingleOrganizationDeviceDataLoading: false,
  SingleOrganizationDeviceDataError: false,
  SingleOrganizationsDeviceGetData: [],
  SingleOrganizationsDeviceGetDataLoading: false,
  SingleOrganizationsDeviceGetDataError: false,
  singleOrganizationID: '',
  isSearchExpand: false,
  deviceCreateFromOrg: false,
  selectedOrderValue: 0,
};
const apiKeysSlice = createSlice({
  name: 'apiKeysSlice',
  initialState,
  reducers: {
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchApiKeysDataLoading: (state) => {
      state.apiKeysListLoading = true;
    },
    fetchApiKeysDataFail: (state) => {
      state.apiKeysListError = true;
      state.apiKeysListLoading = false;
    },
    fetchApiKeysDataSuccess: (state, { payload }) => {
      state.apiKeysList = payload.data;
      state.apiKeysApiData = payload;
      state.apiKeysnumberOfPages = payload.pagination.num_pages;
      state.apiKeyspaginationData = payload.pagination;
      state.apiKeysListLoading = false;
    },
    getApiKeysFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
  },
});
export const {
  getSearchText,
  clearSearcText,
  expandSearchInMobile,
  closeSearchInMobile,
  getPageNumber,
  fetchApiKeysDataLoading,
  fetchApiKeysDataFail,
  fetchApiKeysDataSuccess,
  getFlterData,
  getApiKeysFilterValue,
} = apiKeysSlice.actions;
export default apiKeysSlice.reducer;
