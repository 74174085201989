import { createSlice } from '@reduxjs/toolkit';
import { Country } from 'types/common-types';
import { Error_Type_Enum } from 'utils/enum';

type IntialState = {
  activeTab: string;
  countries: Country[];
  isCountriesLoading: boolean;
  fetchCountriesStatus: string;
};

export const initialState: IntialState = {
  activeTab: '',
  countries: [],
  isCountriesLoading: false,
  fetchCountriesStatus: '',
};

const common = createSlice({
  name: 'Common',
  initialState,
  reducers: {
    setActiveTabs: (state, action) => {
      state.activeTab = action.payload;
    },
    setCountriesIsLoading: (state) => {
      state.isCountriesLoading = true;
      state.fetchCountriesStatus = '';
    },
    setCountriesData: (state, { payload }) => {
      state.countries = payload;
      state.isCountriesLoading = false;
      state.fetchCountriesStatus = Error_Type_Enum.Success;
    },
    fetchCountriesFail: (state) => {
      state.fetchCountriesStatus = Error_Type_Enum.Fail;
      state.isCountriesLoading = false;
    },
  },
});

export const { setActiveTabs, setCountriesIsLoading, setCountriesData, fetchCountriesFail } = common.actions;
export default common.reducer;
