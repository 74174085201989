import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect } from 'react';

import { RootState } from 'redux/Store/store';
import { useIntl } from 'react-intl';

import Search from 'components/customSearch/Search';;

import CustomSelect from 'components/customSelect/CustomSelect';
import {
  clearOrgUsersActivitySearcText,
  closeOrgUsersActivitySearchInMobile,
  expandOrgUsersActivitySearchInMobile,
  getOrgUsersActivitySearchText,
  getUserActivityFilterValue,
} from 'redux/orgUserActivity/Reducers/orgUserActivitySlice';

interface FilterProps {
  isFiltersApplied: boolean;
}

const OrgUserActivityFilters = ({ isFiltersApplied }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const userActivityData = useAppSelector((state: RootState) => state.orgUserActivity);

  const dispatch = useAppDispatch();


  const options = [
    {
      id: 0,
      name: 'By Newest',
    },
    {
      id: 1,
      name: 'By Oldest',
    },
  ];

  const handleSearchClose = () => {
    dispatch(closeOrgUsersActivitySearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearOrgUsersActivitySearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandOrgUsersActivitySearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getOrgUsersActivitySearchText(value));
  };
  useEffect(() => {
    dispatch(closeOrgUsersActivitySearchInMobile());
  }, [isMobileDevice]);

  const handleChange = (value: any) => {
    dispatch(getUserActivityFilterValue(value));
  };

  return (
    <Box>
      {((userActivityData.orgUsersActivityData && userActivityData.orgUsersActivityData.length > 0) ||
        isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="26px"
          alignItems="center"
        >
          <Search
            data={userActivityData}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />
          {!userActivityData.isSearchExpand && (
            <Box display="flex" alignItems="center" data-testid="status-types-container" minW={{ xs: '110px', md: '150px' }}>
              <CustomSelect
                handleSelectChange={handleChange}
                options={options}
                selectedValue={userActivityData.selectedOrderValue}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default OrgUserActivityFilters;
