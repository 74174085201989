import { configureStore } from '@reduxjs/toolkit';
import deviceSlice from '../devices/Reducers/deviceSlice';
import { loginReducer, apiVersionReducer } from '@exo/shared-ui';
import organizationSlice from '../organizations/Reducers/organizationSlice';
import SystemSettingSlice from '../systemSettings/systemSettingSlice';
import userSlice from '../user/Reducers/userSlice';
import orgUserSlice from 'redux/orgUser/Reducer/orgUserSlice';
import orgDeviceSlice from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import orgUserActivitySlice from 'redux/orgUserActivity/Reducers/orgUserActivitySlice';
import orgDevicesActivitySlice from 'redux/orgDevicesActivity/Reducers/orgDevicesActivitySlice';
import DeviceTypeSlice from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import singleUserActivitySlice from 'redux/singleUserActivity/Reducers/singleUserActivitySlice';
import singleOrgDevicesActivitySlice from 'redux/singleOrgDeviceActivity/Reducers/singleOrgDevicesActivitySlice';
import dashboardSlice from 'redux/dashbaord/Reducers/dashboardSlice';
import { errorReducer } from '../error/errorSlice';
import SoftwareBundleSlice from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import CustomInputFieldsSlice from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import licenseSlice from 'redux/license/Reducers/licenseSlice';
import apiKeysSlice from 'redux/settings/apiKeys/Reducers/apiKeysSlice';
import clientAccessSlice from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import bundleLicenseSlice from 'redux/bundleLicnese/Reducers/bundleLicenseSlice';
import assignLicenseItemToBundleSlice from 'redux/license/Reducers/assignLicenseItemToBundleSlice';
import orgLicenseSlice from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import customersSlice from 'redux/customers/customersSlice';
import CommonSlice from 'redux/common/CommonSlice';
import OrgFeatureSlice from 'redux/orgFeatures/Reducers/OrgFeaturesSlice';
const store = configureStore({
  reducer: {
    error: errorReducer,
    login: loginReducer,
    systemSettings: SystemSettingSlice,
    organization: organizationSlice,
    customers: customersSlice,
    user: userSlice,
    device: deviceSlice,
    orgUser: orgUserSlice,
    organizationdevice: orgDeviceSlice,
    orgUserActivity: orgUserActivitySlice,
    organizationDevicesActivitySlice: orgDevicesActivitySlice,
    orgFeatures: OrgFeatureSlice,
    singleUserActivity: singleUserActivitySlice,
    singleOrgDevicesActivitySlice: singleOrgDevicesActivitySlice,
    dashboard: dashboardSlice,
    deviceTypes: DeviceTypeSlice,
    softwareBundles: SoftwareBundleSlice,
    customInputFields: CustomInputFieldsSlice,
    license: licenseSlice,
    clientAccess: clientAccessSlice,
    apiKeys: apiKeysSlice,
    bundleLicense: bundleLicenseSlice,
    assignLicenseItemToBundle: assignLicenseItemToBundleSlice,
    orgLicense: orgLicenseSlice,
    apiVersion: apiVersionReducer,
    common: CommonSlice,
  },
});
export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
