import React from 'react';
import LandingScreen from 'components/landingPage/LandingSreen';
import { Box } from '@chakra-ui/react';
import { RootState } from 'redux/Store/store';
import { useAppSelector } from 'redux/hooks';
import Loader from 'components/loader/Loader';

export default function OrgDevicesLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const isDataLoading = useAppSelector((state: RootState) => state.organizationdevice.organizationDevicesListLoading);
  const handleModalOpen = () => {
    // this function is to open the modal
  };
  return (
    <Box data-testid="landing-screen">
      <Loader isLoading={isDataLoading} />
      {!isDataLoading && (
        <LandingScreen
          handleClick={handleModalOpen}
          title="Organization Devices"
          isFiltersApplied={isFiltersApplied}
          onlyTitle={true}
        />
      )}
    </Box>
  );
}
