/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import {
  Box,
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { CircleRightDisabledIcon } from 'assets/icons/icons';
import { customColor } from 'assets/styles/colors';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CiCircleCheck, CiCircleChevRight, CiCircleRemove } from 'react-icons/ci';
import { fetchLicenseAddons, getBundleData } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { initialValues, OrgAddOnsSchema } from './OrgAddOnsValidationSchema';

import moment from 'moment';
import OrgAddOnsInputFields from './OrgAddOnsInputFields';
import {
  handleOrgAddOnModal,
  handleOrgAddOnModalSuccess,
  handleOrgAddOnModalFail,
} from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import { fetchOrgLicenseAddons, postOrgAddOns } from 'redux/organiztionLicenes/Actions/orgLicenseActions';
import { PostOrgAddons } from 'types/orgAddOns';
import { useParams } from 'react-router-dom';

const OrgAddOnsAddModal = () => {
  const colors = customColor();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const { orgId } = useParams();
  const orgAddOnsPostLoading = useAppSelector((state) => state.orgLicense.isOrgAddOnModalLoading);
  const orgAddOnsPostSuccess = useAppSelector((state) => state.orgLicense.isOrgAddOnModalSuccess);
  const orgAddOnsPostFail = useAppSelector((state) => state.orgLicense.isOrgAddOnModalFail);
  const handleModal = useAppSelector((state) => state.orgLicense.isOrgAddOnModalActive);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    dispatch(getBundleData());
    dispatch(fetchLicenseAddons());
    setIsDisabled(true);
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: OrgAddOnsSchema(),

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        setSubmitting(true);
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const isDisabled = !formik.dirty || Object.keys(formik.errors).length > 0;
    setIsDisabled(isDisabled);
  }, [formik.dirty, formik.errors]);

  const getActionLabels = () => {
    if (orgAddOnsPostLoading) {
      return { label: <FormattedMessage id="add_modal.message" />, icon: <Spinner /> };
    }
    if (orgAddOnsPostSuccess) {
      return {
        label: <FormattedMessage id="done.message" />,
        icon: <CiCircleCheck style={{ width: '30px', height: '30px' }} />,
      };
    }
    if (orgAddOnsPostFail) {
      return {
        label: <FormattedMessage id="failure.message" />,
        icon: <CiCircleRemove style={{ width: '30px', height: '30px' }} />,
      };
    }
    return {
      label: <FormattedMessage id="add_modal.message" />,
      icon: isDisabled ? <CircleRightDisabledIcon /> : <CiCircleChevRight style={{ width: '30px', height: '30px' }} />,
    };
  };
  const actionLabels = getActionLabels();

  const getTheme = () => {
    if (isDisabled || orgAddOnsPostFail) {
      return {
        color: '#BAC5CD',
        borderColor: '#BAC5CD',
        hoverBg: 'auto',
        hoverColor: 'auto',
      };
    }
    if (isSuccess) {
      return {
        color: blackAndWhiteColorMode,
        borderColor: blueColorMode,
        bgColor: blueColorMode,
        hoverBg: 'auto',
        hoverColor: 'auto',
      };
    }
    return {
      color: blueColorMode,
      borderColor: blueColorMode,
      hoverBg: blueColorMode,
      hoverColor: blackAndWhiteColorMode,
    };
  };
  const theme = getTheme();

  const handleAdd = () => {
    const postFeaturesData: any = {
      add_on: formik.values.addOn?.catalogNumber,
      type: 'addon',
      catalog_id: formik.values.addOn?.catalogNumber,
      order_id: formik.values.orderID,
      start_date: moment(
        new Date(
          `${formik.values.availabilityDate.year}-${formik.values.availabilityDate.month}-${formik.values.availabilityDate.day}`,
        ),
      ).format('YYYY-MM-DD'),
      expiration_date: moment(
        new Date(
          `${formik.values.expirationDate.year}-${formik.values.expirationDate.month}-${formik.values.expirationDate.day}`,
        ),
      ).format('YYYY-MM-DD'),
      created_by: 'PULSE_USER',
    } as PostOrgAddons;

    dispatch(postOrgAddOns(orgId || '', postFeaturesData));
  };
  useEffect(() => {
    if (orgAddOnsPostSuccess) {
      setTimeout(() => {
        formik.setTouched({}, false);
        formik.resetForm();
        dispatch(handleOrgAddOnModal(false));
        dispatch(handleOrgAddOnModalSuccess(false));
        dispatch(fetchOrgLicenseAddons(orgId || ''));
      }, 1500);
    }
  }, [orgAddOnsPostSuccess]);

  const handleModalClose = () => {
    formik.setTouched({}, false);
    formik.resetForm();
    dispatch(handleOrgAddOnModal(false));
  };

  useEffect(() => {
    if (orgAddOnsPostFail) {
      dispatch(handleOrgAddOnModalFail({ isFail: false, errorMessage: '' }));
    }
  }, [formik.values, formik.errors]);
  return (
    <Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={handleModal}
        onClose={handleModalClose}
        border-radius="0.1px"
        scrollBehavior={'outside'}
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ xs: '368px', smd: '700px', md: '700px', lg: '700px', xl: '700px', dt: '700px' }}
          mt="80px"
        >
          <ModalHeader fontSize="24px" ml="6px">
            <FormattedMessage id="add_addon.message" />
          </ModalHeader>
          <ModalCloseButton
            color={closeIconColor}
            data-testid="close-ic"
            backgroundColor="transparent"
            onClick={handleModalClose}
          />
          <ModalBody ml="7px" backgroundColor="transparent">
            <OrgAddOnsInputFields formik={formik} />
          </ModalBody>
          <ModalFooter backgroundColor="transparent" zIndex={999}>
            <Button
              variant="outline"
              type="button"
              width="195px"
              height="42px"
              borderRadius="20px"
              backgroundColor={theme?.bgColor ?? 'auto'}
              borderColor={theme?.borderColor}
              color={theme?.color}
              isDisabled={isDisabled}
              fontSize="17px"
              float="right"
              data-testid="org-button-disabled"
              fill={blueColorMode}
              _hover={{
                cursor: isDisabled || orgAddOnsPostFail ? 'not-allowed' : 'pointer',
                // ...(!isDisabled && {
                bg: theme?.hoverBg,
                color: theme?.hoverColor,

                '&>div>svg>svg>circle': {
                  fill: blueColorMode,
                  stroke: blackAndWhiteColorMode,
                },
                '&>div>div>svg>svg>circle': {
                  fill: blueColorMode,
                  stroke: blackAndWhiteColorMode,
                },
                '&>div>svg>svg>path': {
                  fill: blackAndWhiteColorMode,
                  stroke: 'none',
                },
                '&>div>div>svg>svg>path': {
                  fill: blackAndWhiteColorMode,
                  stroke: 'none',
                },
              }}
              onClick={handleAdd}
              _disabled={{
                opacity: 1,
              }}
            >
              <Flex alignItems="center">
                <Text
                  fontSize="12px"
                  display="flex"
                  alignItems="center"
                  fontWeight="extrabold"
                  data-testid="org-button-text"
                  ml="-24px"
                >
                  {actionLabels?.label}
                </Text>
                <Box
                  position="absolute"
                  right="10px"
                  _hover={{
                    '&>div>div>svg>svg>circle': {
                      fill: 'white',
                      stroke: 'white',
                    },
                  }}
                  sx={{
                    '& > svg': {
                      width: '24px',
                      height: '24px',
                    },
                  }}
                >
                  {actionLabels?.icon}
                </Box>
              </Flex>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default OrgAddOnsAddModal;
