/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Wrap,
  WrapItem,
  Icon,
  Input,
  Circle,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { customColor } from '../../../../../assets/styles/colors';
import { InputLabel } from '../../../../../components/InputField';
import { getDeviceSerialNumberData } from '../../../../../redux/devices/Actions/deviceActions';
import {
  getDeviceStatusInputValue,
  getDeviceSerialInputNumber,
  fetchDeviceSerialNumberMinLength,
  fetchDeviceSerialNumberFail,
  fetchDeviceSerialNumberFieldValidation,
  fetchDeviceCreateButtonEnable,
  fetchDevicePostToggle,
  fetchDeviceSuccessCreation,
  fetchPostDeviceData,
  fetchPostDeviceFormDataFail,
  getDevicePostCreateButton,
} from '../../../../../redux/devices/Reducers/deviceSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { DeviceSerialNumber } from '../../../../../types/device-types';
import { specialAlphaNumericValidation } from '../../../../../utils/validations/specialCharacterValidation';
import StatusInfo from 'components/status/StatusInfo';

const DeviceSerialStatusInputs = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const intl = useIntl();
  const serialNumber = useAppSelector((state) => state.device.getDeviceSerialInputNumber);
  const serialNumberLength = useAppSelector((state) => state.device.getDeviceSerialNumberLength);
  const serialNumberValidation = useAppSelector((state) => state.device.getDeviceSerialNumberFail);
  const serialNumberFieldValidation = useAppSelector((state) => state.device.getDeviceSerialFieldValidation);
  const scannedValue = useAppSelector((state) => state.device.scannedValue);
  const [deviceStatusValue, setDeviceStatusValue] = useState<any>(8);
  const isValidQR = useAppSelector((state) => state.device.validQRcode);
  const postDataFail = useAppSelector((state) => state.device.postDeviceFormDataFail);
  const deviceStatusData = useAppSelector((state) => state.device.getDeviceStatusData);

  const handleDevice = (status_id: any) => {
    setDeviceStatusValue(status_id);
    dispatch(getDeviceStatusInputValue(status_id.toString()));
    if (postDataFail) {
      dispatch(fetchDeviceCreateButtonEnable(false));
      dispatch(fetchPostDeviceData(false));
      dispatch(getDevicePostCreateButton(false));
      dispatch(fetchDeviceSuccessCreation(false));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(fetchDevicePostToggle(false));
    }
  };

  const PostDeviceSerialNumber: DeviceSerialNumber = {
    field_name: 'serial_number',
    field_value: serialNumber,
  };
  const handleSerialNumber = (e: any) => {
    dispatch(getDeviceSerialInputNumber(e.target.value));
    if (postDataFail) {
      dispatch(fetchDeviceCreateButtonEnable(false));
      dispatch(fetchPostDeviceData(false));
      dispatch(getDevicePostCreateButton(false));
      dispatch(fetchDeviceSuccessCreation(false));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(fetchDevicePostToggle(false));
    }
  };
  const handleSerialNumberChange = () => {
    if (serialNumber.length > 0 && serialNumber.length < 4) {
      dispatch(fetchDeviceSerialNumberMinLength(true));
      dispatch(fetchDeviceSerialNumberFail(false));
      dispatch(fetchDeviceSerialNumberFieldValidation(false));
    } else if (serialNumber.length > 3) {
      dispatch(getDeviceSerialNumberData(PostDeviceSerialNumber));
      dispatch(fetchDeviceSerialNumberMinLength(false));
      dispatch(fetchDeviceSerialNumberFail(false));
      dispatch(fetchDeviceSerialNumberFieldValidation(false));
    } else if (serialNumber.length == 0) {
      dispatch(fetchDeviceSerialNumberFieldValidation(true));
      dispatch(fetchDeviceSerialNumberFail(false));
      dispatch(fetchDeviceSerialNumberMinLength(false));
    } else {
      dispatch(fetchDeviceSerialNumberFail(false));
      dispatch(fetchDeviceSerialNumberMinLength(false));
      dispatch(fetchDeviceSerialNumberFieldValidation(false));
    }
  };

  // useEffect(()=>{
  //   if(scannedValue && isValidQR){
  //     let obj = {
  //       target:{
  //         name:"Purchase Ready",
  //         value:"5"
  //       }
  //     }
  //     handleDevice(obj)
  //   }
  //   else{
  //     let obj = {
  //       target:{
  //         name:"",
  //         value:""
  //       }
  //     }
  //     handleDevice(obj)
  //   }
  // },[scannedValue])
  const status = !!deviceStatusData.length && deviceStatusData.find((item: any) => item.status_id == deviceStatusValue);

  return (
    <Box data-testid="container">
      <Wrap align="center" justify="serial_number" spacingX="30px">
        <WrapItem>
          <Box minH="90px">
            <InputLabel name="serial" value={serialNumber} />
            <Input
              color={colors.subTextColor}
              name="serial number"
              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              onChange={handleSerialNumber}
              placeholder={intl.formatMessage({ id: 'serial.message' })}
              value={serialNumber}
              type="text"
              fontSize="14px"
              _placeholder={{ fontSize: '14px' }}
              variant="flushed"
              maxLength={16}
              onBlur={handleSerialNumberChange}
              onKeyPress={specialAlphaNumericValidation}
              required
              data-testid="device-form-serial"
            />
            <Box fontSize="12px" color="#FB5654">
              {serialNumberFieldValidation ? <FormattedMessage id="field_validation.message" /> : ''}
              {serialNumberLength ? (
                <FormattedMessage id="org_name_error.message" />
              ) : (
                <>{serialNumberValidation ? <FormattedMessage id="device_serial_number_validation.message" /> : ''}</>
              )}
            </Box>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box minH="90px">
            <InputLabel value={deviceStatusValue} name="status" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={
                      isOpen ? (
                        <Icon mr="-10px" as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon mr="-10px" as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {deviceStatusValue ? (
                      <Box display="flex" ml="-15px" alignItems="center" fontSize="14px" fontWeight="400">
                        <StatusInfo
                          indicatorSize="6px"
                          label={status?.status_name}
                          indicatorColor={status?.color_code}
                        />
                      </Box>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="status.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {!!deviceStatusData.length &&
                      deviceStatusData.map((state: any) => (
                        <MenuItem
                          id={state.id}
                          key={state.status_id}
                          value={state.status_id}
                          name={state.status_name}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={() => handleDevice(state.status_id)}
                        >
                          <StatusInfo indicatorSize="6px" label={state.status_name} indicatorColor={state.color_code} />
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default DeviceSerialStatusInputs;
