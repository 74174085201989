import { useState } from 'react';
import { Box, Text, Image, Flex, VStack, HStack, Spinner, useColorModeValue } from '@chakra-ui/react';
import { WhiteChevronRightCircled, exoWorksLogoLight } from '../../assets/icons';
import ListItem from '../ListItem/ListItem';
import { FormattedMessage } from 'react-intl';
import { SharedUiState, useAppSelector } from '../../redux/hooks';
import { customColor } from '../../assets/styles/colors';

type OnboardingContainerProps = {
  list?: {
    name: string;
    uuid: string;
  }[];
  handleListItemClick?: (uuid: string, name: string) => void;
  orgName?: string;
  userName?: string;
  subText: string;
  onClickContinue?: (selectedItem?: string) => void;
  onClickBack: () => void;
  customList?: React.ReactNode;
  allSelected?: boolean;
  isListLoading?: boolean;
};

export const OnboardingContainer = (props: OnboardingContainerProps) => {
  const { loading } = useAppSelector((state: SharedUiState) => state.user);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const enableContinueButton = props.allSelected && !props.isListLoading;
  const colors = customColor();

  const handleListItemClick = (uuid: string, name: string) => {
    setSelectedItem(uuid);
    props.handleListItemClick && props.handleListItemClick(uuid, name);
  };

  const onClickBack = () => {
    props.onClickBack();
  };

  const handleOnClickContinue = () => {
    if (props.allSelected && props.onClickContinue) {
      props.onClickContinue(selectedItem);
    }
  };

  return (
    <Box>
      <Box>
        <Box width="100%">
          <Box py="50px">
            <Image h={{ xs: '35px', md: '40px' }} src={exoWorksLogoLight} />
          </Box>
        </Box>
        <Box minH="calc(100vh - 230px)">
          {loading ? (
            <Box>
              <Spinner size="xl" color="colors.light1800" />
            </Box>
          ) : (
            <Box>
              <Box
                fontSize="16px"
                fontWeight="500"
                color={colors.light1800}
                textTransform="uppercase"
                letterSpacing="1px"
              >
                {props.orgName}
              </Box>

              <Box mt="10px" fontSize="32px" fontWeight="300" lineHeight="47.5px">
                <Text as="span" color={colors.violetColor}>
                  {props.userName}
                </Text>
                <Text as="span" color={colors.light1800}>
                  {', '}
                  {props.subText}
                </Text>
              </Box>

              <Box maxW={{ xs: '100%', md: '500px' }}>
                {props.customList ? (
                  props.customList
                ) : props.isListLoading ? (
                  <Spinner color={colors.light1800} />
                ) : (
                  <>
                    <VStack mt="50px" gap="10px" alignItems="flex-start">
                      {props.list?.map((item) => {
                        return (
                          <ListItem
                            key={item.uuid}
                            name={item.name}
                            uuid={item.uuid}
                            selected={selectedItem === item.uuid}
                            onClick={() => handleListItemClick(item.uuid, item.name)}
                            disabled={props.list?.length === 1 ? true : false}
                          />
                        );
                      })}
                    </VStack>
                  </>
                )}

                <Box
                  as="button"
                  textAlign="center"
                  width="100%"
                  height="58px"
                  mt="45px"
                  borderRadius="14px"
                  bg={enableContinueButton ? colors.violetColor : colors.brandGrey600}
                  cursor={enableContinueButton ? 'pointer' : 'not-allowed'}
                  onClick={handleOnClickContinue}
                >
                  <Text
                    color={enableContinueButton ? 'white' : 'light.1800'}
                    opacity={enableContinueButton ? '' : '50%'}
                    fontSize={{ xs: '18px', md: '20px' }}
                    fontWeight="500"
                    lineHeight={{ xs: '26.46px', md: '29.4px' }}
                  >
                    <FormattedMessage id="continue.message" />
                  </Text>
                </Box>

                <Text
                  mt="20px"
                  fontSize={{ xs: '16px', md: '18px' }}
                  fontWeight={500}
                  color={colors.lightModeBlue}
                  onClick={onClickBack}
                  cursor="pointer"
                >
                  <FormattedMessage id="back_to_login.message" />
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
