/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { Dispatch } from 'react';

import { userRequest } from 'api';
import {
  fetchSingleUserActivityDataError,
  fetchSingleUserActivityDataLoading,
  fetchSingleUserActivityDataSuccess,
} from '../../singleUserActivity/Reducers/singleUserActivitySlice';
import { setError } from '../../error/errorSlice';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getSingleUserActivityData =
  (id: any, userId: any, queryString: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchSingleUserActivityDataLoading());

    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(`organizations/${id}/provisioning/user/${userId}/activity/?${queryString}`);
      if (res && res.status === 200) {
        dispatch(fetchSingleUserActivityDataSuccess(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchSingleUserActivityDataError());
    }
  };
