import { Box, Text, Image, Link, Flex, AbsoluteCenter } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import eclipse from '../../assets/images/eclipse.svg';
import React from 'react';
import { customColor } from '../../assets/styles/colors';

export default function LandingScreen({
  handleClick,
  title = 'Dashboard',
  isFiltersApplied,
  onlyTitle,
  newCreation,
  pageName,
}: {
  handleClick?: () => void;
  title: string;
  isFiltersApplied?: boolean;
  onlyTitle?: boolean;
  newCreation?: boolean;
  pageName?: string;
}) {
  const colors = customColor();
  const isFeatures = title === 'Features';
  return (
    <Box display="flex" style={{ overflow: 'hidden' }}>
      {isFiltersApplied ? (
        <AbsoluteCenter display="flex" mt="-80px" ml="-100px" justifyContent="center" w="100%">
          <Text
            opacity="0.5"
            color={colors.textColor}
            fontSize={{ xs: '16px', lg: '18px' }}
            data-testid="no-match-found"
          >
            <FormattedMessage id="no_matches_found.message" />
          </Text>
        </AbsoluteCenter>
      ) : (
        <>
          <Box
            w={{ xs: '100%', md: '60%' }}
            ml={newCreation ? '' : { xs: '20px', lg: '15%' }}
            display="flex"
            h={{ xs: 'calc(100vh - 300px)', lg: 'calc(100vh - 300px)' }}
            flexDirection="column"
            justifyContent="center"
            data-testid="no-organization"
          >
            {title === 'org_add_ons_available_page' && (
              <Box fontSize={{ xs: '22px', md: '26px', xl: '24px' }} data-testid="no-org-text" color={colors.textColor}>
                <Box mb="15px">
                  <Text>
                    <FormattedMessage id={`${title}.message`} />
                  </Text>
                  <Text fontWeight="400px" fontSize="16px" pt="10px">
                    <FormattedMessage id="add_licence_addon_description.message" values={{ title: title }} />
                  </Text>
                  <Flex fontSize="16px" pt="10px">
                    <Text as="u" color={colors.primaryColor} mr="5px">
                      <Link color={colors.primaryColor} onClick={handleClick} data-testid="click-here">
                        <FormattedMessage id="click_here.message" />
                      </Link>
                    </Text>
                    <Text data-testid="to-get-started" color={colors.textColor}>
                      to add License
                    </Text>
                  </Flex>
                </Box>
              </Box>
            )}
            {title === 'org_add_ons_empty_page' && (
              <Box fontSize={{ xs: '22px', md: '26px', xl: '24px' }} data-testid="no-org-text" color={colors.textColor}>
                <Box mb="15px">
                  <Text>
                    <FormattedMessage id={`${title}.message`} />
                  </Text>
                  <Flex fontSize="16px" pt="10px">
                    <Text data-testid="click+" color={colors.textColor}>
                      <FormattedMessage id="click_the_plus.message" />
                    </Text>
                    <Text as="u" color={colors.primaryColor} mx="5px">
                      <Link color={colors.primaryColor} onClick={handleClick} data-testid="click-here">
                        <FormattedMessage id="click_here.message" />
                      </Link>
                    </Text>
                    <Text data-testid="to-get-started" color={colors.textColor}>
                      <FormattedMessage id="to_get_started.message" />
                    </Text>
                  </Flex>
                </Box>
              </Box>
            )}
            {isFeatures && (
              <Box fontSize={{ xs: '22px', md: '26px', xl: '24px' }} data-testid="no-org-text" color={colors.textColor}>
                <Box mb="15px">
                  <Text>
                    <FormattedMessage id="no_features_available.message" values={{ title: title }} />
                  </Text>
                  <Text fontWeight="400px" fontSize="16px" pt="15px">
                    <FormattedMessage id="add_licence_description.message" values={{ title: title }} />
                  </Text>
                </Box>
              </Box>
            )}
            {title === 'Devices' && (
              <Text
                fontSize={{ xs: '22px', md: '26px', xl: '24px' }}
                data-testid="no-org-text"
                color={colors.textColor}
                mt={'-150px'}
              >
                <FormattedMessage id="no_organizations_added.message" values={{ title: title }} />
              </Text>
            )}
            {title === 'Organization Devices' && (
              <Text
                fontSize={{ xs: '22px', md: '26px', xl: '24px' }}
                data-testid="no-org-text"
                color={colors.textColor}
                mt={'-150px'}
              >
                <FormattedMessage id="no_organizations_added.message" values={{ title: title }} />
              </Text>
            )}
            {title === 'Dashboard' && (
              <Box>
                <Text fontSize={{ xs: '30px', xl: '40px' }} fontWeight={{ xl: 'thin' }}>
                  <FormattedMessage id="dashboard_info.message" />
                </Text>
                <Text pl="12px">
                  <FormattedMessage id="dashboard_info.description" />
                </Text>
              </Box>
            )}
            {title !== 'Dashboard' && pageName && (
              <Text fontSize={{ xs: '22px', md: '26px', xl: '24px' }} data-testid="no-org-text">
                <FormattedMessage id={`${pageName}.message`} />
              </Text>
            )}
            {!onlyTitle && (
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'start', sm: 'center' }}
                fontSize={{ xs: '14px', md: '18px', xl: '18px' }}
                ml={newCreation ? '-8px' : ''}
              >
                {newCreation ? (
                  ''
                ) : (
                  <Text data-testid="click+" color={colors.textColor}>
                    <FormattedMessage id="click_the_plus.message" />
                  </Text>
                )}
                <Text as="u" mx={{ sm: '4px' }} color={colors.primaryColor}>
                  <Link mx={{ sm: '4px' }} color={colors.primaryColor} onClick={handleClick} data-testid="click-here">
                    <FormattedMessage id="click_here.message" />
                  </Link>
                </Text>
                {isFeatures ? (
                  <Text data-testid="to-get-started" color={colors.textColor}>
                    to add License Bundles
                  </Text>
                ) : (
                  <Text data-testid="to-get-started" color={colors.textColor}>
                    <FormattedMessage id="to_get_started.message" />
                  </Text>
                )}
              </Box>
            )}
          </Box>
          <Box w={{ xs: '0%', md: '40%' }} position="relative" alignItems="center" display="flex" data-testid="Image">
            <Image
              display={{ xs: 'none', md: 'block' }}
              src={eclipse}
              minW="521px"
              minH="521px"
              position="absolute"
              right={{ md: '-300px' }}
              data-testid="image-landingsection"
            />
          </Box>
        </>
      )}
    </Box>
  );
}
