import React, { useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { InputLabel } from 'components/InputField';
import { postUnAssignDevices } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import { RootState } from 'redux/Store/store';

function OrgDeviceUnAssignInputFields() {
  const dispatch = useAppDispatch();
  const orgId = useAppSelector((state) => state.organizationdevice.SingleDeviceGetData?.org_id?.org_id);
  const postClientApiData = useAppSelector((state) => state.clientAccess.postClientApiData);
  const clientPostDataFail = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const orgDeviceUnAssignInputValue = useAppSelector((state) => state.organizationdevice.orgDeviceUnAssignInputValue);
  const deviceID = useAppSelector((state) => state.organizationdevice.SingleDeviceGetData?.id);
  const singleDeviceData = useAppSelector((state: RootState) => state.organizationdevice.SingleDeviceGetData);
  const postData = {
    reason: orgDeviceUnAssignInputValue,
  };

  useEffect(() => {
    if (!clientPostDataFail && postClientApiData == true) {
      dispatch(postUnAssignDevices(postData, orgId, deviceID));
    }
  }, [postClientApiData]);

  return (
    <>
      <Text mb="10px" fontSize="20px" fontWeight="400" color="rgba(51, 51, 51, 0.6)" fontFamily="helvetica">
        Device status will be changed to ‘Return Initiated’ upon Un-assigning this device from{' '}
        <span style={{ textTransform: 'capitalize' }}>{singleDeviceData?.org_id?.org_name}</span> organization.
      </Text>

      <Box alignItems="center" data-testid="device_name_field_container">
        <Box>
          <Box mt="20px">
            {postApiFailureMessage ? <BannerMessage message={postApiFailureMessage} defaultMessage={''} /> : ''}
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default OrgDeviceUnAssignInputFields;
