/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import {
  clientAccessSinglePartnerID,
  handleSEditettingsModalPopUp,
  resetCreateSettingsPopUpValues,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import EditDeveloperSettingsModal from 'pages/settings/editDeveloperSettings/EditDeveloperSettingsModal';
import { editPartnerAccess, getClientStatus } from 'redux/settings/clientAccess/Actions/clientAccessActions';
import { getUsersStatuses } from 'redux/user/Actions/userActions';
import { userAccessConst } from 'utils/constants/userAccessConst';

function ClientAccessListView() {
  const navigate = useNavigate();
  const data = useAppSelector((state) => state.clientAccess.clientAccessList);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);

  const dispatch = useAppDispatch();
  const data2 = Array.isArray(data)
    ? data &&
      data.map((item: any) => {
        return {
          id: item.id,
          name: item?.name,
          client_id: item?.auth0_app_client_id,
          client_secret: item?.auth0_app_client_secret,
          api_key: item?.api_key,
          device_status: item?.status.status + '*' + item?.status.color_code,
        };
      })
    : [];
  const columns = [
    {
      field: 'name',
      header: <FormattedMessage id="name.message" />,
      link: userPermissions.settings?.includes(userAccessConst.setting.viewPartnerKeys),
    },
    { field: 'api_key', header: <FormattedMessage id="api_key.message" />, link: false },
    { field: 'client_id', header: <FormattedMessage id="client_id.message" />, link: false, twoRows: true },
    { field: 'client_secret', header: <FormattedMessage id="client_secret.message" />, link: false },
    { field: 'device_status', header: <FormattedMessage id="status.message" />, link: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'name') {
      navigate(`/settings/partners/${row.id}/info`);
      dispatch(clientAccessSinglePartnerID(row.id));
      dispatch(getClientStatus());
      dispatch(editPartnerAccess(row.id));
      dispatch(resetCreateSettingsPopUpValues());
    } else {
    }
  };

  return (
    <Box data-testid="org-device-list">
      <CustomTable data={data2} columns={columns} handleNavigateFromChild={handleNavigateFromChild} />{' '}
      <EditDeveloperSettingsModal />
    </Box>
  );
}
export default ClientAccessListView;
