import axios, { AxiosRequestConfig } from 'axios';

const orgIdHeader = localStorage.getItem('exo_org_id');
const userEmailHeader = localStorage.getItem('exo_user_email');
const baseUrl = process.env.NX_EW_BASE_URL || localStorage.getItem('exo_api_url');

export const makeHeaders = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem('exo_sso')}`,
    'X-Exo-Org-Id': orgIdHeader,
    'X-Exo-User-Email': userEmailHeader,
    'X-Exo-Platform': `browser; ${navigator.userAgent}`,
    'X-Exo-Facility-Id': localStorage.getItem('exo_facility_id'),
    'X-Exo-Specialty-Id': localStorage.getItem('exo_specialty_id'),
  };
};

export default class ApiUtil {
  public static async get<T>(path: string, opts: { blob?: boolean } = {}) {
    const url = baseUrl + path;
    const baseOpts: AxiosRequestConfig = {
      headers: makeHeaders(),
      withCredentials: true,
    };
    if (opts.blob) {
      baseOpts.responseType = 'blob';
    }
    return await axios.get<T>(url, baseOpts);
  }

  public static async post<T>(path: string, data: unknown, opts: { blob?: boolean } = {}) {
    const url = baseUrl + path;
    const baseOpts: AxiosRequestConfig = {
      headers: makeHeaders(),
      withCredentials: true,
    };
    if (opts.blob) {
      baseOpts.responseType = 'blob';
    }
    return await axios.post<T>(url, data, baseOpts);
  }

  public static async put<T>(path: string, data: unknown, opts: { blob?: boolean } = {}) {
    const url = baseUrl + path;
    const baseOpts: AxiosRequestConfig = {
      headers: makeHeaders(),
      withCredentials: true,
    };
    if (opts.blob) {
      baseOpts.responseType = 'blob';
    }
    return await axios.put<T>(url, data, baseOpts);
  }

  public static async delete<T>(path: string, opts: { blob?: boolean } = {}) {
    const url = baseUrl + path;
    const baseOpts: AxiosRequestConfig = {
      headers: makeHeaders(),
      withCredentials: true,
    };

    return await axios.delete<T>(url, baseOpts);
  }
}
