import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalOverlay, ModalContent, Button, Text, Flex, Image } from '@chakra-ui/react';
import { useAppDispatch } from '../../redux/hooks';
import { alertIconBright } from '../../assets/icons';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { logoutHandler } from '../../redux/login.slice';
import { customColor } from '../../assets/styles/colors';
import { apiVersionThunks, selectVersionInfo } from '../../redux/apiVersion';
import { getUserActivity } from '../../../lib/utils';
import { UserActivityEvent } from '@exo/ew-data-models';

interface IdleModalProps {
  isOpen: boolean;
  timeout: number;
  onClose: () => void;
}

const IdleModal = ({ isOpen, timeout, onClose }: IdleModalProps) => {
  const dispatch = useDispatch<useAppDispatch>();
  const { logout } = useAuth0();
  const [remaining, setRemaining] = useState(timeout);
  const colors = customColor();
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const versionInfoStr = useSelector(selectVersionInfo)?.productVersion;

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setRemaining((prevRemaining) => Math.max(prevRemaining - 1, 0));
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isOpen, remaining]);

  useEffect(() => {
    if (remaining === 0) {
      onClickLogout(true);
    }
  }, [remaining]);

  const onClickLogout = (userIdle: boolean) => {
    dispatch(logoutHandler());
    dispatch(
      apiVersionThunks.sendUserActivity(getUserActivity(UserActivityEvent.SessionTimeout, currentUser, versionInfoStr)),
    ).then(() => {
      const queryParams = '?status=idle';
      const logoutParams = userIdle
        ? { returnTo: window.location.origin + queryParams }
        : { returnTo: window.location.origin };
      logout({ logoutParams });
    });
  };

  const handleOnClickCloseOrContinue = () => {
    onClose();
    setRemaining(timeout);
  };

  return (
    <Modal
      data-testid="idle-timeout-modal"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleOnClickCloseOrContinue}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent
        maxW={{ xs: 'auto', md: '601px' }}
        maxH={{ xs: 'auto', md: '270px' }}
        p="40px 20px 20px 20px"
        borderRadius="10px"
        boxShadow="0px 1px 20px 0px rgba(0, 0, 0, 0.15)"
        w={{ xs: '90%', md: '100%' }}
      >
        <Flex alignItems="center" gap="10px">
          <Image src={alertIconBright} w="38px" h="38px" />
          <Text data-testid="session-expire-header" fontSize="20px" fontWeight="700" lineHeight="25px">
            <FormattedMessage id="session_expire_header.message" />
          </Text>
        </Flex>
        <Text data-testid="session-expire-message" p="10px 20px 40px 51px">
          <FormattedMessage
            id="session_expire_message.message"
            values={{
              time: (
                <Text as="span" fontWeight="bold">
                  {formatTime(remaining)}
                </Text>
              ),
            }}
          />
        </Text>
        <Flex justifyContent="space-around" w="100%" gap={{ xs: '24px', md: '0px' }}>
          <Button
            data-testid="logout-btn"
            backgroundColor={colors.buttonBlack}
            color="white"
            w="158px"
            h="47px"
            borderRadius="30px"
            onClick={() => onClickLogout(false)}
            _hover={{ backgroundColor: colors.buttonHoverBlack }}
            _focus={{ border: 'none' }}
          >
            <FormattedMessage id="log_out.message" />
          </Button>
          <Button
            data-testid="continue-btn"
            backgroundColor={colors.buttonBlue}
            color="white"
            w="158px"
            h="47px"
            borderRadius="30px"
            onClick={handleOnClickCloseOrContinue}
            _hover={{ backgroundColor: colors.buttonHoverBlue }}
            _focus={{ border: 'none' }}
          >
            <FormattedMessage id="continue.message" />
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default IdleModal;
