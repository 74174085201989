import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import IdleModal from '../components/IdleModal/IdleModal';
import { useIdleTimer } from 'react-idle-timer';

const SessionTimeout = () => {
  const idleConfirmation = useDisclosure();
  const timeoutDuration = 1000 * 60 * 14; // milliseconds
  const modalTimeoutInSec = 60 * 1; // seconds

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = Math.ceil(getRemainingTime() / 1000);
      if (remaining <= modalTimeoutInSec) {
        idleConfirmation.onOpen();
        reset();
        pause();
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const { getRemainingTime, pause, reset, start } = useIdleTimer({
    timeout: timeoutDuration,
    throttle: 500,
    element: document,
    crossTab: true,
  });

  const handleOnCloseModal = () => {
    idleConfirmation.onClose();
    start();
  };

  return <IdleModal isOpen={idleConfirmation.isOpen} timeout={modalTimeoutInSec} onClose={handleOnCloseModal} />;
};

export default SessionTimeout;
