import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';

import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';

import OrgUserListView from './OrgUserListView';
import { getUserPageNumber } from 'redux/orgUser/Reducer/orgUserSlice';
import OrgUserLandingScreen from './OrgUserLandingScreen';

function OrgUsersData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: RootState) => state.orgUser.usersList);
  const numberOfPages = useAppSelector((state: RootState) => state.orgUser.numberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.orgUser.paginationData);
  const selectedPage = useAppSelector((state: RootState) => state.orgUser.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isLoading = useAppSelector((state: RootState) => state.orgUser.usersListLoading);
  const handleMobilePageClick = (item: number) => {
    dispatch(getUserPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getUserPageNumber(data.selected + 1));
  };
  return (
    <Box position="relative" h={`calc(100vh - 250px)`} overflowY="hidden">
      <Box h={`calc(100vh - 250px)`} overflowY="hidden">
        <Box position="relative" h={`calc(100vh - 300px)`} overflowY="hidden">
          {userData && userData.length > 0 && !isLoading ? (
            <Box h={`calc(100vh - 300px)`} px="18px" overflowY="scroll">
              {' '}
              <OrgUserListView />{' '}
            </Box>
          ) : (
            <OrgUserLandingScreen isFiltersApplied={isFiltersApplied} />
          )}
        </Box>
        {userData && userData.length > 0 && (
          <Box
            display="flex"
            h="50px"
            borderTop="1px solid var(--chakra-colors-chakra-border-color)"
            pl="18px"
            justifyContent="space-between"
            alignItems="center"
            data-testid="org-users-data"
          >
            <TableInformation selectedPage={selectedPage} data={userData} pagination={paginationData} />
            <Box display={{ xs: 'none', xl: 'block' }}>
              <DesktopPaginate
                data={userData && userData}
                handleDesktopPageClick={handleDesktopPageClick}
                theme={theme}
                pagination={paginationData}
                selectedPage={selectedPage}
              />
            </Box>
            <Box display={{ xs: 'block', xl: 'none' }}>
              <MobilePagination
                data={userData && userData}
                selectedPage={selectedPage}
                numberOfPages={numberOfPages}
                handleMobilePageClick={handleMobilePageClick}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default OrgUsersData;
