import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiUtil from '../../api/index';
import { PendingAgreements } from '@exo/ew-data-models';
import { AcceptedIds, EmailRequest, EmailResponse } from './agreements.slice';
import { AxiosResponse } from 'axios';

interface CustomError {
  status: number;
  message: string;
}

const fetchPendingAgreements = createAsyncThunk('agreements/fetchPending', async () => {
  const res = await ApiUtil.get<PendingAgreements>(`/v1/access-control/users/pending-agreements/`);
  return res.data;
});

const acceptAgreements = createAsyncThunk('agreements/accept', async (acceptedIds: number[]) => {
  const postBody = {
    acceptedAgreementIds: acceptedIds,
  };
  const res = await ApiUtil.post<AcceptedIds>(`/v1/access-control/users/accepted-agreements/`, postBody);
  return res.data;
});

const postEmail = createAsyncThunk<EmailResponse | undefined, EmailRequest, { rejectValue: CustomError }>(
  'access-control/users',
  async (emailRequestBody: EmailRequest, { rejectWithValue }) => {
    const url = `/v1/access-control/users/current/contact-us`;

    try {
      const response = await ApiUtil.post(url, emailRequestBody);
      return response.data as EmailResponse;
    } catch (error) {
      const axiosError = error as AxiosResponse;
      const customError: CustomError = {
        status: axiosError.status,
        message: axiosError.data?.message || 'An error occurred',
      };
      return rejectWithValue(customError);
    }
  },
);

export const agreementsThunks = {
  fetchPendingAgreements,
  acceptAgreements,
  postEmail,
};
