import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { FormattedMessage } from 'react-intl';
import { Box, Text, Flex, Icon, useColorModeValue } from '@chakra-ui/react';
import LandingScreen from 'components/landingPage/LandingSreen';
import { fetchOrgLicenseAddons } from 'redux/organiztionLicenes/Actions/orgLicenseActions';
import { AddIcon } from 'assets/icons/icons';
import { userAccessConst } from 'utils/constants/userAccessConst';
import Search from 'components/customSearch/Search';
import Loader from 'components/loader/Loader';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import OrgAddonsListView from './OrgAddonsListView';
import { handleOrgAddOnModal, handleOrgLicenseModalPopup } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import OrgAddOnsAddModal from './OrgAddOnsModal';

const OrgAddons = () => {
  const { orgId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');
  const theme = useAppSelector((state) => state.systemSettings.systemColorTheme);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const isLoading = useAppSelector((state) => state.orgLicense.isAddonsLoading);
  const addonsData = useAppSelector((state) => state.orgLicense.addonsData);
  const orgLicenseBundles = useAppSelector((state) => state.orgLicense.organizationLincesesList);
  const isActiveBundleAdded = !!orgLicenseBundles.length;
  const addonsList = addonsData?.data;
  const [searchText, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const showEmptyMessage = !isLoading && !searchText.length && !addonsData?.pagination?.count;

  useEffect(() => {
    dispatch(fetchOrgLicenseAddons(orgId || ''));
  }, []);

  const handleAddAddonModal = () => {
    dispatch(handleOrgAddOnModal(true));
  };

  const handleAddBundle = () => {
    dispatch(handleOrgLicenseModalPopup(true));
    navigate(`/organizations/${orgId}/licenses/bundles`);
  };

  const handleMobilePageClick = (item: any) => {
    dispatch(fetchOrgLicenseAddons(orgId || '', item, searchText));
    setSelectedPage(item);
  };
  const handleDesktopPageClick = (data: any) => {
    dispatch(fetchOrgLicenseAddons(orgId || '', data.selected + 1, searchText));
    setSelectedPage(data.selected + 1);
  };

  const handleSearchValue = (value: string) => {
    dispatch(fetchOrgLicenseAddons(orgId || '', 1, value));
    setSearchText(value);
    setSelectedPage(1);
  };

  const handleSearchClear = () => {
    dispatch(fetchOrgLicenseAddons(orgId || '', 1, ''));
    setSearchText('');
    setSelectedPage(1);
  };

  return (
    <Box h="100%" pl="30px" position="relative">
      <Box>
        <Flex alignItems="center" h="50px" m="15px 20px 0 0" justifyContent="space-between">
          <Flex alignItems="center">
            <Box mr="10px">
              <Text fontWeight="700" fontSize="24px">
                <FormattedMessage id="add_addons.message" />
              </Text>
            </Box>
            {isActiveBundleAdded &&
              userPermissions.organizations?.includes(userAccessConst.organization.addLicense) && (
                <Icon
                  data-testid="plus-icon"
                  w="24px"
                  h="24px"
                  onClick={handleAddAddonModal}
                  color={textColorMode}
                  fill={fillColorMode}
                  _hover={{
                    color: 'url("#BlueGradient")',
                    cursor: 'pointer',
                    stroke: 'white',
                    fill: 'url("#BlueGradient")',
                    '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                    '&:hover>svg>circle': { stroke: strokeColorMode },
                  }}
                >
                  <AddIcon stroke={primarblackAndWhiteColorMode} />
                </Icon>
              )}
          </Flex>
          {!showEmptyMessage && (
            <Box>
              <Search
                data={{ searchText, isSearchExpand: false }}
                handleClose={() => {}}
                handleClear={handleSearchClear}
                handleExpandClick={() => {}}
                handleSearch={handleSearchValue}
              />
            </Box>
          )}
        </Flex>
      </Box>
      {showEmptyMessage && (
        <Box data-testid="landing-screen">
          <LandingScreen
            handleClick={isActiveBundleAdded ? handleAddAddonModal : handleAddBundle}
            title={isActiveBundleAdded ? 'org_add_ons_empty_page' : 'org_add_ons_available_page'}
            isFiltersApplied={false}
            onlyTitle={true}
            newCreation={true}
          />
        </Box>
      )}
      {isLoading ? <Loader isLoading={isLoading} /> : <OrgAddonsListView searchText={searchText} />}
      {addonsData?.pagination && addonsList && addonsList.length > 0 && (
        <Flex
          w="100%"
          pl="20px"
          pr="20px"
          h="50px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          bottom="5px"
          left="0"
          borderTop="1px solid var(--chakra-colors-chakra-border-color)"
        >
          <TableInformation selectedPage={selectedPage} data={addonsList} pagination={addonsData.pagination} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={addonsList}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={addonsData.pagination}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={addonsList}
              selectedPage={selectedPage}
              numberOfPages={addonsData.pagination?.num_pages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Flex>
      )}
      <OrgAddOnsAddModal />
    </Box>
  );
};

export default OrgAddons;
