import { FormattedMessage, useIntl } from 'react-intl';
import { ColorModeScript, ChakraProvider, Box, Text, Alert, AlertIcon, AlertTitle, Spinner } from '@chakra-ui/react';
import myNewTheme from '../assets/styles/theme';
import Checklist, { ICheckListItem } from './Checklist';
import { useSelector } from 'react-redux';
import { SharedUiState } from '../redux/hooks';

type AgreementsProps = {
  handleUpdateDocsInfo: ({ allSelected, agreedIds }: { allSelected: boolean; agreedIds: number[] }) => void;
};
export const Agreements = (props: AgreementsProps) => {
  const agreementsData = useSelector((state: SharedUiState) => state.agreements);
  const isLoading = agreementsData.isLoading;
  const pendingDocs = agreementsData.pendingDocs;
  const error = agreementsData.error;
  const intl = useIntl();

  const onChangeHandler = (activeItems: ICheckListItem[]) => {
    const activeIds = activeItems.map((i) => (typeof i.id === 'number' ? i.id : parseInt(i.id)));
    const allChecked: boolean = pendingDocs.every((each) => {
      return activeIds.includes(each.id);
    });
    props.handleUpdateDocsInfo({
      allSelected: allChecked,
      agreedIds: [...activeIds],
    });
  };

  // Convert pending agreement data to checklist prop format
  const checklistItems = pendingDocs.map((doc) => {
    const label = intl.formatMessage({ id: `agreement_${doc.agreementType}.message` });
    return {
      id: doc.id,
      label: label,
      url: doc.url || '',
    };
  });

  return (
    <>
      <ColorModeScript initialColorMode={myNewTheme.config.initialColorMode} />
      <ChakraProvider theme={myNewTheme}>
        <Box paddingTop="20px" alignItems="center">
          <Text
            marginTop={'5px'}
            fontSize={{ xs: '14px', lg: '16px', xl: '18px' }}
            fontWeight="400"
            p={'5px 5px 3px 0px'}
          >
            <FormattedMessage id="review_our_terms_subheader.message" />
          </Text>
          {isLoading ? (
            <Box minH="90px" paddingTop={'20px'}>
              <Spinner />
            </Box>
          ) : error ? (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>
                <FormattedMessage id="review_our_terms_error.message" />
              </AlertTitle>
            </Alert>
          ) : (
            <Box>
              <Checklist items={checklistItems} onChange={onChangeHandler} />
            </Box>
          )}
        </Box>
      </ChakraProvider>
    </>
  );
};
