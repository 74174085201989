/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Icon,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
} from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface MobilePaginateProps {
  data: any;
  selectedPage: number ;
  numberOfPages: number;
  handleMobilePageClick: any;
}
const MobilePagination = ({ data, selectedPage, numberOfPages, handleMobilePageClick }: MobilePaginateProps) => {
  const colors = customColor();
  const popoverBg = useColorModeValue('white', 'rgba(57, 66, 73, 1)');
  const pageNumbers: number[] = [];
  for (let i = 1; i <= numberOfPages; i++) {
    pageNumbers.push(i);
  }
  const renderList = () => {
    return (
      <>
        {data && (
          <List>
            {pageNumbers.map((item) => (
              <ListItem
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                py="10px"
                data-testid="selcted-page"
                color={selectedPage == item ? colors.primaryColor : colors.paginationDisplay}
                bgColor={selectedPage == item ? colors.mobilePaginationColor : ''}
                key={item}
                onClick={() => handleMobilePageClick(item)}
              >
                {item}
              </ListItem>
            ))}
          </List>
        )}
      </>
    );
  };

  return (
    <>
      {data && (
        <Box overflowY="scroll" bg={popoverBg}>
          <Popover placement="top" closeOnBlur={true} data-testid="pop-over">
            <PopoverTrigger>
              <Button
                textDecor="none"
                bg="none"
                _hover={{ bg: 'none' }}
                _active={{ bg: 'none' }}
                fontSize="14px"
                p="0px"
                m="0px"
                fontWeight="400"
                data-testid="pageSelect-button"
                color={colors.paginationDisplay}
                rightIcon={<Icon as={ChevronDownIcon} w={6} h={6} color={colors.paginationDisplay} />}
              >
                <FormattedMessage id="page.message" /> {selectedPage}
              </Button>
            </PopoverTrigger>

            <PopoverContent w={{ xs: '100vw', md: '200px' }} mr="20px">
              <PopoverBody maxH="300px" overflowY="scroll" data-testid="pages-list">
                {renderList()}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </>
  );
};

export default MobilePagination;