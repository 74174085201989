import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { RootState } from 'redux/Store/store';
import { fetchLicenseFeatures, updateLicenseFeature } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import Loader from 'components/loader/Loader';
import LandingScreen from 'components/landingPage/LandingSreen';

import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { AddIcon, InformationTextIcon } from 'assets/icons/icons';
import { handleFeaturesModal } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import FeaturesAddModal from './FeaturesModal/FeaturesAddModal';
import { userAccessConst } from 'utils/constants/userAccessConst';
import FeatureGroupsAccordion from './FeatureGroupsAccordion';

const FeaturesContainer = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isLoading = useAppSelector((state: RootState) => state.softwareBundles.isFeaturesLoading);
  const featureGroups = useAppSelector((state: RootState) => state.softwareBundles.featureGroups);
  const hasFeatureEditAccess = userPermissions.bundles?.includes(userAccessConst.bundle.addBundle);
  const isFeatureAvailableSuccess = useAppSelector((state) => state.softwareBundles.featureAvailablitySuccess);
  const isFeatureAvailableFail = useAppSelector((state) => state.softwareBundles.featureAvailablityFail);
  const isFeatureAvailableLoading = useAppSelector((state) => state.softwareBundles.featureAvailablityLoading);
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(fetchLicenseFeatures());
  }, []);
  const handleAddModal = () => {
    dispatch(handleFeaturesModal(true));
  };

  const showEmptyMessage = !isLoading && !searchText.length && featureGroups?.length == 0;

  return (
    <Box>
      {showEmptyMessage && (
        <Box data-testid="landing-screen" ml="15px" mt="50px">
          <LandingScreen
            handleClick={handleAddModal}
            title="License features"
            isFiltersApplied={false}
            onlyTitle={false}
            newCreation={true}
            pageName={'features_empty_page'}
          />
        </Box>
      )}
      {!showEmptyMessage && (
        <Box h="90px" m="15px 15px 0 0">
          <Flex flexDirection={'column'} justifyContent="space-between">
            <Text
              mr={{ xs: '3' }}
              fontSize={{ xs: '22px', xl: '24px' }}
              fontWeight="medium"
              data-testid="org-title"
              color={colors.textColor}
            >
              <FormattedMessage id="add_features.message" />
            </Text>
            {!hasFeatureEditAccess && (
              <Flex alignItems="center" mt="15px">
                <InformationTextIcon />
                <Text color="rgba(51, 51, 51, 0.8)" mt="-5px">
                  <FormattedMessage id="feature_info_status.message" />
                </Text>
              </Flex>
            )}
          </Flex>

          {/* 
          // Add New Feature is being handled directly from BE, not required now. 
          // Keeping it for now if incase we need it in future.
          {userPermissions.bundles?.includes(userAccessConst.bundle.addBundle) && (
            <Icon
              w="24px"
              h="24px"
              color={textColorMode}
              fill={fillColorMode}
              onClick={handleAddModal}
              _hover={{
                color: 'url("#BlueGradient")',
                cursor: 'pointer',
                stroke: 'white',
                fill: 'url("#BlueGradient")',
                '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                '&:hover>svg>circle': { stroke: strokeColorMode },
              }}
            >
              <AddIcon stroke={primarblackAndWhiteColorMode} />
            </Icon>
          )} */}
        </Box>
      )}
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <FeatureGroupsAccordion
          dispatchCallback={updateLicenseFeature}
          featureGroupsData={featureGroups}
          hasEditAccess={hasFeatureEditAccess}
          isFeatureAvailableSuccess={isFeatureAvailableSuccess}
          isFeatureAvailableFail={isFeatureAvailableFail}
          isFeatureAvailableLoading={isFeatureAvailableLoading}
        />
      )}
      <>
        <FeaturesAddModal />
      </>
    </Box>
  );
};

export default FeaturesContainer;
