import { Box, FormLabel } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import { customColor } from '../assets/styles/colors';

type InputProps = {
  value: string | undefined | number;
  name?: string;
};
export function InputLabel(props: InputProps) {
  const colors = customColor();
  return (
    <Box h="10px" data-testid="container">
      {props.value && props.name && (
        <FormLabel
          color={colors.textColor}
          opacity="0.6"
          fontSize={{ xs: '10px', lg: '10px', xl: '10px' }}
          mb="5px"
          data-testid="container-label"
          fontWeight="400"
          pb="0"
        >
          <FormattedMessage id={`${props.name}.message`} />
        </FormLabel>
      )}
    </Box>
  );
}
