import React from 'react';
import { Box, CloseButton, Flex, Text, BoxProps, Circle, Show, useMediaQuery, Button } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import {
  Analytics,
  Communication,
  Iris,
  Organization,
  Users,
  Dashboard,
  LeftNavSoftwareBundleIcon,
  Settings,
  UserNewICON,
} from '../../assets/icons/icons';
import NavItem from '../navbar/NavItem';
import { customColor } from '../../assets/styles/colors';

import { RootState } from '../../redux/Store/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { routePath } from 'routes/routePaths';
import { IconType } from 'react-icons';
import { Iris1 } from '../../../../../libs/shared-ui/src/lib/assets';
import { userAccessConst } from 'utils/constants/userAccessConst';

interface LinkItemProps {
  name: string;
  icon: IconType;
  routePath: string;
  hasAccess: boolean;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

function Sidebar({ onClose, ...rest }: SidebarProps) {
  const [isLargeDevice] = useMediaQuery('(min-width: 1019px)');
  const colors = customColor();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const toggleSidebarInDesktop = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const sideNavUserAccess = userAccessConst.sideNavigation;
  const LinkItems: Array<LinkItemProps> = [
    {
      name: intl.formatMessage({ id: 'dashboard.message' }),
      icon: Dashboard,
      routePath: routePath.Dashboard,
      hasAccess: true,
    },
    {
      name: intl.formatMessage({ id: 'customers.message' }),
      icon: Organization,
      routePath: routePath.Customers,
      hasAccess: userPermissions.sideNavigation?.includes(sideNavUserAccess.organizations),
    },
    {
      name: intl.formatMessage({ id: 'devices.message' }),
      icon: Iris1,
      routePath: routePath.DeviceModels,
      hasAccess: userPermissions.sideNavigation?.includes(sideNavUserAccess.devices),
    },
    {
      name: intl.formatMessage({ id: 'licenses.message' }),
      icon: LeftNavSoftwareBundleIcon,
      routePath: routePath.licenses,
      hasAccess: userPermissions.sideNavigation?.includes(sideNavUserAccess.bundles),
    },

    {
      name: intl.formatMessage({ id: 'pulse_users.message' }),
      icon: UserNewICON,
      routePath: routePath.Users,
      hasAccess: userPermissions.sideNavigation?.includes(sideNavUserAccess.users),
    },
    {
      name: intl.formatMessage({ id: 'analytics.message' }),
      icon: Analytics,
      routePath: routePath.Analytics,
      hasAccess: userPermissions.sideNavigation?.includes(sideNavUserAccess.analytics),
    },
    {
      name: intl.formatMessage({ id: 'communication.message' }),
      icon: Communication,
      routePath: routePath.Communication,
      hasAccess: userPermissions.sideNavigation?.includes(sideNavUserAccess.communication),
    },
    {
      name: intl.formatMessage({ id: 'settings.message' }),
      icon: Settings,
      routePath: routePath.Settings,
      hasAccess: userPermissions.sideNavigation?.includes(sideNavUserAccess.settings),
    },
  ];
  return (
    <Box position="relative" transition="0.5s ease">
      <Box
        transition="0.5s ease"
        bg={{ xs: colors.sidebarColor, lg: 'none' }}
        w={{ xs: 'full', lg: toggleSidebarInDesktop ? '240px' : '100px' }}
        pos="fixed"
        h="100vh"
        top={{ xs: '0', lg: '80px' }}
        {...rest}
        p={{ xs: '7', lg: '0' }}
        borderRightWidth="1px"
        borderRightColor="light.1000"
        overflowY="auto"
        overflowX="hidden"
        scrollbar="hidden"
        height="100%"
      >
        <Flex justifyContent={{ xs: 'space-between' }}>
          <CloseButton
            display={{ xs: 'flex', lg: 'none' }}
            onClick={onClose}
            data-testid="close-btn"
            transition="0.5s ease"
          />
          <Box display={{ xs: 'flex', lg: 'none' }}>
            <Text fontSize="20px" fontFamily="Helvetica">
              Exo
            </Text>
            <Text fontSize="20px" fontFamily="Helvetica" ml="4px" color="common.100">
              Pulse
            </Text>
          </Box>
          <Show breakpoint="(max-width: 1019px)">
            <Circle size="40px" bg={colors.primaryColor} color={colors.userNameColor}>
              <Text>AM</Text>
            </Circle>
          </Show>
        </Flex>

        <Flex
          flexDirection="column"
          data-testid="nav-items"
          _hover={{ color: '#24B0FF' }}
          pl={{ lg: toggleSidebarInDesktop ? '30px' : '30px' }}
          alignItems={{ xs: 'flex-start', lg: toggleSidebarInDesktop ? 'flex-start' : 'center' }}
          width={{ lg: toggleSidebarInDesktop ? '220px' : '100px' }}
          height={{ xs: '100%', lg: '70%' }}
          minH="450px"
          mt="20px"
        >
          {LinkItems.map((link: LinkItemProps) => {
            return (
              link.hasAccess && (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  name={link.name}
                  routePath={link.routePath}
                  onClose={onClose}
                  _hover={{ color: '#24B0FF' }}
                >
                  {link.name}
                </NavItem>
              )
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
}
export default Sidebar;
