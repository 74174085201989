import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';

import { RootState } from 'redux/Store/store';
import React from 'react';

import ClientAccessData from '../data/ClientAccessData';
interface FilterProps {
  isFiltersApplied: boolean;
}
export default function ClientMainScreen({ isFiltersApplied }: FilterProps) {
  const data = useAppSelector((state: RootState) => state.clientAccess);

  return (
    <Box data-testid="org-devices" h={`calc(100vh - 260px)`}>
      <ClientAccessData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
