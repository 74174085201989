import { Box, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from '../../assets/styles/colors';

const Submit = ({
  handleEmailSubmit,
  isEnable = false,
  className = '',
}: {
  handleEmailSubmit: () => void;
  isEnable: boolean;
  className: string;
}) => {
  const colors = customColor();
  return (
    <Box
      as="button"
      textAlign="center"
      width="100%"
      height="58px"
      borderRadius="14px"
      bg={isEnable ? colors.violetColor : colors.brandGrey600}
      cursor={isEnable ? 'pointer' : 'not-allowed'}
      onClick={isEnable ? handleEmailSubmit : () => false}
      position="absolute"
      bottom="0"
      className={className}
    >
      <Text
        color={isEnable ? 'dark.800' : 'light.1800'}
        opacity={isEnable ? '' : '50%'}
        fontSize={{ xs: '18px', md: '20px' }}
        fontWeight="500"
        lineHeight={{ xs: '26.46px', md: '29.4px' }}
      >
        <FormattedMessage id="continue.message" />
      </Text>
    </Box>
  );
};

export default Submit;
