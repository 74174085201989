import React from 'react';
import { InputRightElement, IconButton } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

type CustomFormSubmitButtonProps = {
  icon?: React.ReactElement;
};

const CustomFormSubmitButton = ({ icon }: CustomFormSubmitButtonProps) => {
  const intl = useIntl();
  return (
    <InputRightElement width="4.5rem" justifyContent="end" p="0" m="0" data-testid="icon">
      <IconButton
        aria-label={intl.formatMessage({ id: 'submit.message' })}
        type="submit"
        variant="iconPlaceholder"
        icon={icon}
        _focusVisible={{ boxShadow: 'none' }}
      />
    </InputRightElement>
  );
};
export default CustomFormSubmitButton;
