export interface UserActivity {
  activityType: UserActivityEvent;
  activityValue?: string;
  activityDate: Date;
  userId: string;
  firstName: string;
  lastName: string;
  orgId: string;
  appVersion: string;
  appDeviceOs: string;
  appDeviceModel: string;
  orgName: string;
  orgFacility: string;
  orgSpeciality: string;
  product?: string;
}

export enum UserActivityEvent {
  LoginSuccessful = 'USER_LOGIN_SUCCESSFUL',
  LogoutSuccessful = 'USER_LOGOUT_SUCCESSFUL',
  SessionTimeout = 'USER_SESSION_TIMEOUT',
}
