import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ErrorState = {
  isError: boolean,
  errorMessage: string,
};

export const initialState: ErrorState = {
  isError: false,
  errorMessage: '',
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorState>) => {
      state.isError = action.payload.isError;
      state.errorMessage = action.payload.errorMessage;
    },
  }
});

export const {
  setError,
} = errorSlice.actions;

export const errorReducer = errorSlice.reducer;