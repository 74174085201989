import { useColorMode } from '@chakra-ui/react';
import * as React from 'react';
import styled from 'styled-components/macro';

// TODO: Add a 404 image and page once we have the new design
export const PageNotFound = () => {
  const { colorMode } = useColorMode();

  return <StyledPageNotFound theme={colorMode}>We couldn&apos;t find the page you were looking for</StyledPageNotFound>;
};

interface StyledPageNotFoundProps {
  theme: 'light' | 'dark';
}

const StyledPageNotFound = styled.div<StyledPageNotFoundProps>`
  padding: 2%;

  color: ${({ theme }) => (theme === 'light' ? 'black' : 'white')};
`;
