import LandingScreen from 'components/landingPage/LandingSreen';
import { Box } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import Loader from 'components/loader/Loader';
import { getOrganizationNameSearchValue, toggleCreateDevicePopUp } from 'redux/devices/Reducers/deviceSlice';
import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';

export default function DevicesLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.device.deviceListViewLoading);
  const handleModalOpen = () => {
    dispatch(toggleCreateDevicePopUp(true));
    dispatch(getOrganizationNameSearchValue(''));
    dispatch(getDeviceStatus());
  };

  return (
    <Box data-testid="landing-screen">
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <LandingScreen handleClick={handleModalOpen} title="Devices" isFiltersApplied={isFiltersApplied} />
      )}
    </Box>
  );
}
