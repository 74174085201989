import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getDevices, getDeviceStatuses, getDeviceTypes } from '../../redux/devices/Actions/deviceActions';
import { getFlterData, getPageNumber, toggleDevicesView, resetFilters } from '../../redux/devices/Reducers/deviceSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/Store/store';
import DeviceFilter from './components/deviceFilters/DeviceFilters';
import DevicesMainContent from './components/devices/devicesMainContent/DeviceMainContent';
import DeviceTitle from './components/deviceTitle/DeviceTitle';
import Loader from 'components/loader/Loader';
import { userAccessConst } from 'utils/constants/userAccessConst';
import DeviceCreateModal from './components/createDevice/DeviceCreateModal';

const Devices = () => {
  const toggle = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const location = useLocation();
  const devicesData = useAppSelector((state: RootState) => state.device);
  const dispatch = useAppDispatch();
  const { modelId } = useParams();
  const initialRender = useRef({
    isMounted: false,
  });
  const filters = devicesData.filterData;
  const search = devicesData.searchText;
  const isDataLoading = devicesData.devicesListLoading;

  const isFiltersApplied = !!(
    (filters && filters.status_id && filters.status_id.length) ||
    (filters && filters.device_catalogue_id && filters.device_catalogue_id.length) ||
    search ||
    isDataLoading
  );

  const getFilteredDevices = (customPayload = {}) => {
    if (devicesData.filterData.device_catalogue_id.length || devicesData.deviceTypesData.length) {
      const catalogueId = devicesData.filterData.device_catalogue_id.length
        ? devicesData.filterData.device_catalogue_id?.filter((itr) => itr !== 'All')
        : [devicesData.deviceTypesData.filter((itr) => itr.internal_name == modelId)[0]?.id];

      const payload: any = {
        search: devicesData.searchText,
        page: devicesData.selectedPage,

        status_id: devicesData.filterData.status_id?.filter((itr) => itr !== 'All'),
        ...customPayload,
      };

      const queryString = Object.entries(
        !devicesData.filterData.device_catalogue_id.length && modelId?.length == 0
          ? payload
          : Object.assign(payload, { device_catalogue_id: catalogueId }),
      )
        .map(([key, value]: any) => {
          if (Array.isArray(value)) {
            return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
          } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          }
        })
        .join('&');

      dispatch(getDevices(queryString));
    }
  };

  useEffect(() => {
    if (!devicesData.deviceTypesData.length) {
      dispatch(getDeviceTypes());
    }
    return () => {
      dispatch(getPageNumber(1));
    };
  }, []);

  useEffect(() => {
    dispatch(resetFilters());
    initialRender.current.isMounted = false;
  }, [modelId]);

  useEffect(() => {
    getFilteredDevices();
  }, [devicesData.selectedPage]);

  useEffect(() => {
    if (initialRender.current.isMounted) {
      dispatch(getPageNumber(1));
      getFilteredDevices();
    }
  }, [devicesData.searchText, devicesData.filterData]);

  useEffect(() => {
    if (devicesData.deviceTypesData.length) {
      initialRender.current.isMounted = true;
      getFilteredDevices();
    }
  }, [devicesData.deviceTypesData]);

  useEffect(() => {
    if (devicesData.triggerDevicesApi) {
      getFilteredDevices();
    }
  }, [devicesData.triggerDevicesApi]);

  useEffect(() => {
    dispatch(toggleDevicesView(false));
    dispatch(getDeviceStatuses());
  }, [location.pathname]);

  return (
    <Box
      id="devices"
      pl="20px"
      py={{ lg: '20px' }}
      w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 240px)' : 'calc(100vw - 100px)' }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent={{ xs: 'none', lg: 'space-between' }}
        transition="0.5s ease"
        mt="20px"
      >
        <DeviceTitle showAddBtn={userPermissions.devices?.includes(userAccessConst.device.addDevice)} />
        <DeviceFilter isFiltersApplied={isFiltersApplied} defaultDevice={modelId as string} />
      </Box>
      <DevicesMainContent isFiltersApplied={isFiltersApplied} />
      <DeviceCreateModal />
    </Box>
  );
};
export default Devices;
