export enum Error_Type_Enum {
  Fail = 'FAIL',
  Success = 'SUCCESS',
}
export enum Account_Owner {
  Owner = 'Account Owner',
  Performer = 'Performer',
  None = 'None',
}
export enum User_Roles {
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  Marketing = 'Marketing',
  Support = 'Support',
}
