import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';

import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';

import UserListView from './userListView/UserListView';
import { getPageNumber } from 'redux/user/Reducers/userSlice';
import UserLandingScreen from '../userLanding/UserLandingScreen';

function UsersData({ isFiltersApplied }: any) {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: RootState) => state.user.usersList);
  const numberOfPages = useAppSelector((state: RootState) => state.user.numberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.user.paginationData);
  const selectedPage = useAppSelector((state: RootState) => state.user.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isLoading = useAppSelector((state: RootState) => state.user.usersListLoading);
  const handleMobilePageClick = (item: number) => {
    dispatch(getPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getPageNumber(data.selected + 1));
  };

  return (
    <>
      <Box position="relative" h={{ xs: 'calc(100vh - 140px)', lg: 'calc(100vh - 140px)' }}>
        {userData && userData.length > 0 && !isLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            overflowY="scroll"
            h={{ xs: 'calc(100vh - 240px)', lg: 'calc(100vh - 240px)' }}
          >
            <UserListView />
          </Box>
        ) : (
          <UserLandingScreen isFiltersApplied={isFiltersApplied} />
        )}
        {userData && userData.length > 0 && (
          <Box
            w="100%"
            display="flex"
            h="100px"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            bottom="10px"
          >
            <TableInformation selectedPage={selectedPage} data={userData} pagination={paginationData} />
            <Box display={{ xs: 'none', xl: 'block' }}>
              <DesktopPaginate
                data={userData && userData}
                handleDesktopPageClick={handleDesktopPageClick}
                theme={theme}
                pagination={paginationData}
                selectedPage={selectedPage}
              />
            </Box>
            <Box display={{ xs: 'block', xl: 'none' }}>
              <MobilePagination
                data={userData && userData}
                selectedPage={selectedPage}
                numberOfPages={numberOfPages}
                handleMobilePageClick={handleMobilePageClick}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
export default UsersData;
