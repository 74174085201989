import React, { useEffect, useRef } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/Store/store';
import { Error_Type_Enum } from 'utils/enum';
import {
  getSingleDeviceActivityPageNumber,
  resetDeviceActivity,
} from 'redux/singleOrgDeviceActivity/Reducers/singleOrgDevicesActivitySlice';
import { getSingleOrganizationDevicesActivity } from 'redux/singleOrgDeviceActivity/Actions/singleOrgDevicesActivityActions';
import SingleOrgDevicesActivityFilter from './filters/singleOrgDeviceActivityFilter';
import SingleOrgDevicesActivityMainContent from './mainContent/singleOrgDeviceActivityMain';

const SingleOrgDevicesActivity = () => {
  const initialRender = useRef({ primary: true, secondary: true });
  const { orgId, deviceId } = useParams();
  const dispatch = useAppDispatch();

  const organizationSingleDeviceActivityData = useAppSelector(
    (state: RootState) => state.singleOrgDevicesActivitySlice,
  );

  const getDeviceActivityData = () => {
    const payload: any = {
      search: organizationSingleDeviceActivityData.searchText,
      order: organizationSingleDeviceActivityData.selectedOrderValue,
      page: organizationSingleDeviceActivityData.selectedPage,
    };
    const queryString = Object.entries(payload)
      .map(([key, value]: any) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');

    dispatch(getSingleOrganizationDevicesActivity(orgId, deviceId, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getDeviceActivityData();
  }, [
    organizationSingleDeviceActivityData.selectedPage,
    organizationSingleDeviceActivityData.createOrganizationSingleDeviceActivityApiFail == Error_Type_Enum.Success,
  ]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getSingleDeviceActivityPageNumber(1));
    getDeviceActivityData();
  }, [organizationSingleDeviceActivityData.searchText, organizationSingleDeviceActivityData.selectedOrderValue]);

  useEffect(() => {
    getDeviceActivityData();
    return () => {
      dispatch(getSingleDeviceActivityPageNumber(1));
      dispatch(resetDeviceActivity());
    };
  }, []);
  const search = organizationSingleDeviceActivityData.searchText;
  const isFiltersApplied = !!search;

  return (
    <>
      <Box data-testid="singleorg-activity">
        <Box display="block" overflowX="scroll" overflowY="hidden" width="100%">
          <Box display="flex" h="80px" flexDirection="row" pt="20px" pl="18px" justifyContent="space-between">
            <Box fontWeight="700" fontSize="24px" minWidth={305} ml="15px">
              <Text>Device Activity</Text>
            </Box>
            <SingleOrgDevicesActivityFilter isFiltersApplied={isFiltersApplied} />
          </Box>
          <Box h={`calc(100vh - 250px)`} overflowY="hidden">
            <SingleOrgDevicesActivityMainContent isFiltersApplied={isFiltersApplied} />{' '}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default SingleOrgDevicesActivity;
