import { Box, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import ClientAccessListView from './ClientAccessListView';
import { getPageNumber } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import ClientAccessLandingScreen from '../landingScreen/ClientAccessLandingScreen';

function ClientAccessData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: RootState) => state.clientAccess.clientAccessList);
  const numberOfPages = useAppSelector((state: RootState) => state.clientAccess.clientAccessnumberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.clientAccess.clientAccesspaginationData);
  const selectedPage = useAppSelector((state: RootState) => state.clientAccess.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const { id } = useParams<string>();
  const isLoading = useAppSelector((state: RootState) => state.clientAccess.clientAccessListLoading);
  const handleMobilePageClick = (item: number) => {
    dispatch(getPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getPageNumber(data.selected + 1));
  };
  return (
    <Box position="relative" h={`calc(100vh - 250px)`} overflowY="hidden">
      <Box h={`calc(100vh - 250px)`} overflowY="hidden">
        <Box h={`calc(100vh - 330px)`} px="18px" overflowY="scroll">
          {data && data.length > 0 && !isLoading ? (
            <ClientAccessListView />
          ) : (
            <ClientAccessLandingScreen isFiltersApplied={isFiltersApplied} />
          )}
        </Box>
        {data && data.length > 0 && (
          <Box
            display="flex"
            h="80px"
            borderTop="1px solid var(--chakra-colors-chakra-border-color)"
            pl="18px"
            justifyContent="space-between"
            alignItems="center"
            data-testid="org-device-data"
          >
            <TableInformation selectedPage={selectedPage} data={data} pagination={paginationData} />
            <Box display={{ xs: 'none', xl: 'block' }}>
              <DesktopPaginate
                data={data && data}
                handleDesktopPageClick={handleDesktopPageClick}
                theme={theme}
                pagination={paginationData}
                selectedPage={selectedPage}
              />
            </Box>
            <Box display={{ xs: 'block', xl: 'none' }}>
              <MobilePagination
                data={data && data}
                selectedPage={selectedPage}
                numberOfPages={numberOfPages}
                handleMobilePageClick={handleMobilePageClick}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default ClientAccessData;
