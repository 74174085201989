import { examExportThunks } from '../redux/examExport/examExport.thunk';
import { clearExportExamData, setLoadingExport, setExportFailed } from '../redux/examExport/examExport.slice';
import { ExportStatus } from '../redux/examExport/';

export const EXPORT_SUCCESS_TIMEOUT = 5 * 60 * 1000;

export enum MODAL_CONTENT {
  DETAILS = 'export_status_details.message',
  IN_PROGRESS = 'export_status_in_progress.message',
  ERROR = 'export_status_error.message',
  NOT_CONNECTED = 'export_status_not_connected.message',
  SUCCESS = 'export_status_success.message',
}

const handleToastClose = (message: MODAL_CONTENT, setModalContent: any, dispatch: any) => {
  setModalContent(message);
  dispatch(setLoadingExport(false));
};

export const handleToastContent = (
  exportData: any,
  setModalContent: any,
  downloadLink: any,
  setShowToast: any,
  dispatch: any,
  dispatchTimer: any,
) => {
  if (exportData) {
    if (exportData.presignedUrl && downloadLink) {
      downloadLink.current?.click();
      dispatch(setLoadingExport(false));
      setModalContent(MODAL_CONTENT.SUCCESS);
      setShowToast(true);
    } else if (exportData?.status === ExportStatus.ERROR) {
      setModalContent(MODAL_CONTENT.ERROR);
      dispatch(setExportFailed(false));
    } else {
      if (!dispatchTimer.current) {
        dispatchTimer.current = setTimeout(() => {
          dispatch(examExportThunks.getExportExamDetails({ id: exportData.id }));
          dispatchTimer.current = null;
        }, 2000);
      }
    }
  }
};

export const closeToast = (
  setShowToast: any,
  loadingExport: any,
  setModalContent: any,
  exportData: any,
  downloadLink: any,
  dispatch: any,
) => {
  setShowToast(false);
  setModalContent(null);
  dispatch(setLoadingExport(false));
  dispatch(clearExportExamData());
  if (loadingExport) {
    setModalContent(MODAL_CONTENT.DETAILS);
    if (exportData?.presignedUrl && downloadLink) {
      handleToastClose(MODAL_CONTENT.SUCCESS, setModalContent, dispatch);
    }
  }
  if (exportData?.status === ExportStatus.ERROR) {
    dispatch(setExportFailed(false));
  }
};
