import { Box, useDisclosure } from '@chakra-ui/react';
import { CircleRightEnabledIcon } from 'assets/icons/icons';
import CustomDeleteModal from 'components/customModal/CustomDeleteModal';
import CustomModal from 'components/customModal/CustomModal';
import React, { useEffect } from 'react';
import { resetCustomModalEnableFields } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { openProvisionedModal, resetOrganizationCreateActions } from 'redux/organizations/Reducers/organizationSlice';
import { handleOrgUserPassword } from 'redux/orgUser/Reducer/orgUserSlice';
import {
  handleClientPostDataCreateButtonEnable,
  handleClientSuccessfulPost,
  handlePostApiDataSpinner,
  handleToPostApiData,
  resetCreateSettingsPopUpValues,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { Error_Type_Enum } from 'utils/enum';
import ResetOrgUserInputFields from './ResetOrgUserInputFields';

const ResetOrgUserPasswordModal = () => {
  const dispatch = useAppDispatch();
  const { onClose } = useDisclosure();
  const postDataSuccessfulCreation = useAppSelector((state) => state.clientAccess.enableSuccessfulPostData);
  const enableCreateButton = useAppSelector((state) => state.customInputFields.handleCreateButtonEnable);
  const handleSettingsModal = useAppSelector((state) => state.orgUser.orgUserchangePasswordModal);
  const createClientPostApiFail = useAppSelector((state) => state.clientAccess.clientPostDataFailStrings);
  const postFailure = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const toggleClientButtonEnableSpinnerValue = useAppSelector((state) => state.clientAccess.postClientApiDataSpinner);

  useEffect(() => {
    if (createClientPostApiFail.length > 0) {
      dispatch(handleClientSuccessfulPost(true));
    }
    if (createClientPostApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetCustomModalEnableFields());
        dispatch(resetCreateSettingsPopUpValues());
        dispatch(resetOrganizationCreateActions());
        dispatch(handleOrgUserPassword(false));
        dispatch(handleClientPostDataCreateButtonEnable(false));
        onClose();
      }, ModalTimeOut);
    }
  }, [createClientPostApiFail]);
  const handleModalClose = () => {
    dispatch(handleOrgUserPassword(false));
    dispatch(resetOrganizationCreateActions());
    dispatch(resetCreateSettingsPopUpValues());
    dispatch(resetCustomModalEnableFields());
  };
  const handleCreateButtonClick = () => {
    dispatch(handleToPostApiData(true));
    dispatch(handlePostApiDataSpinner(true));

    if (!handleSettingsModal) {
      dispatch(handleClientSuccessfulPost(false));
    }
  };

  return (
    <Box>
      <CustomModal
        openModal={handleSettingsModal}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createClientPostApiFail && toggleClientButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={true}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleClientButtonEnableSpinnerValue}
        InputFieldsComponent={ResetOrgUserInputFields}
        modalHeader="reset_password"
        modalButtonName="send_mail"
        RightEnabledIcon={CircleRightEnabledIcon}
        colorMode="#24B0FF"
        modalHeight="300px"
      />
    </Box>
  );
};
export default ResetOrgUserPasswordModal;
