export const specialCharacterValidation = (event: any) => {
  if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(event.key)) {
    event.preventDefault();
  }
};
export const specialAlphaNumericValidation = (event: any) => {
  if (!/^[a-zA-Z0-9]*$/.test(event.key)) {
    event.preventDefault();
  }
};
export const specialNumericDotValidation = (event: any) => {
  if (!/^[0-9.]*$/.test(event.key)) {
    event.preventDefault();
  }
};
export const specialEmailValidation = (event: any) => {
  const currentValue = event.target.value;
  const keyPressed = event.key;
  if (!/^[a-zA-Z0-9.@+-]*$/.test(keyPressed)) {
    event.preventDefault();
    return;
  }
  if (currentValue.includes('+')) {
    if (keyPressed === '+') {
      event.preventDefault();
      return;
    }
    if ((currentValue.match(/\+/g) || []).length >= 2) {
      event.preventDefault();
      return;
    }
  }
};

export const specialNumericValidation = (event: any) => {
  if (!/^[0-9]*$/.test(event.key)) {
    event.preventDefault();
  }
};
export const specialNegativeValueValidation = (event: any) => {
  if (!/^[0-9]*$/.test(event.key)) {
    event.preventDefault();
  }
};
