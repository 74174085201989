import React from 'react';
import { Box } from '@chakra-ui/react';

import MainContent from './MainContent';
import Filter from '../../filters/Filter';
import { RootState } from 'redux/Store/store';
import { useAppSelector } from 'redux/hooks';
import OrganizationTitle from '../OrganizationLandingScreen/OrganizationTitle';

const Content = () => {
  const organizationData = useAppSelector((state: RootState) => state.organization);
  const filters = organizationData.filterData;
  const toggle = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const search = organizationData.searchText;
  const isFiltersApplied = !!(
    (filters && filters.status_id && filters.status_id.length) ||
    (filters && filters.tier_id && filters.tier_id.length) ||
    search
  );

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent={{ xs: 'none', lg: 'space-between' }}
        transition="0.5s ease"
        pt={{ lg: '15px' }}
        data-testid="container"
        pr="20px"
      >
        <OrganizationTitle />
        <Filter isFiltersApplied={isFiltersApplied} />
      </Box>
      <MainContent isFiltersApplied={isFiltersApplied} />
    </Box>
  );
};

export default Content;
