import React from 'react';
import { Box } from '@chakra-ui/react';
import NoDataFound from 'components/NoDataFound';
import { RootState } from 'redux/Store/store';
import { useAppSelector } from 'redux/hooks';
import Loader from 'components/loader/Loader';

export default function SingleOrgDevicesActivityLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const isLoading = useAppSelector(
    (state: RootState) => state.singleOrgDevicesActivitySlice.organizationSingleDevicesActivityListLoading,
  );

  return (
    <Box data-testid="landing-screen" h={`calc(100vh - 250px)`}>
      <Loader isLoading={isLoading} />
      {!isLoading && <NoDataFound title="Device Activity" isFiltersApplied={isFiltersApplied} />}
    </Box>
  );
}
