import { Box } from '@chakra-ui/react';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import React, { useEffect } from 'react';
import { postDeviceTypesData } from 'redux/deviceTypes/Actions/DeviceTypeActions';
import { fetchDeviceTypeCreateButtonEnable } from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import DeviceTypeStatusInputField from '../createDeviceTypeInputFields/DeviceTypeStatusInputField';
import HardwareDateInputFields from '../createDeviceTypeInputFields/HadwareDateInputFields';
import InternalNameInputFields from '../createDeviceTypeInputFields/InternalNameInputFields';
import ModelIdentifierCatalogueInputs from '../createDeviceTypeInputFields/ModelIdentifierCatalogueInputs';

const DeviceTypeInputFieldList = () => {
  const dispatch = useAppDispatch();
  const getModelIdentifierInputValue = useAppSelector((state) => state.deviceTypes.getModelIdentifierInputValue);
  const getCatalogueValue = useAppSelector((state) => state.deviceTypes.getCatalogueInputValue);
  const deviceTypeNameValue = useAppSelector((state) => state.deviceTypes.getDeviceTypeNameInputValue);
  const deviceTypeInternalNameValue = useAppSelector((state) => state.deviceTypes.getDeviveTypeInternalName);
  const datePicker = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedMonth);
  const deviceManufacturedYear = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedYear);
  const deviceTypeHardwarevalue = useAppSelector((state) => state.deviceTypes.getDeviceTypeHarwareInputValue);
  const selectedDays = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedDay);
  const getDeviceTypeStatusValue = useAppSelector((state) => state.deviceTypes.getDeviceTypeStatusValue);
  const deviceTypeDateValidation = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedValidation);
  const postDeviceTypeData = useAppSelector((state) => state.deviceTypes.postDeviceTypeData);
  const buttonClick = useAppSelector((state) => state.deviceTypes.deviceTypeCreateButtonEnable);
  const postDeviceTypeApi = useAppSelector((state) => state.deviceTypes.postDeviceTypeApiFailToggle);
  const postDeviceFailToggle = useAppSelector((state) => state.deviceTypes.postDeviceTypeFail);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const catalogueValidation = useAppSelector((state) => state.deviceTypes.deviceTypeCatalogueUniqueValueFail);
  const modelIdentifierUniqueValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeModelIdentifierUniqueValueFail,
  );
  const deviceTypeInternalNameUniqueValueFail = useAppSelector(
    (state) => state.deviceTypes.deviceTypeInternalNameUniqueValueFail,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const postData: any = {
    device_model: getModelIdentifierInputValue,
    internal_name: deviceTypeInternalNameValue,
    catalogue_number: getCatalogueValue,
    device_name: deviceTypeNameValue,
    hardware_version: deviceTypeHardwarevalue,
    availability_date: `${deviceManufacturedYear}-${datePicker}-${selectedDays}T00:00:00.000000Z`,
    endoflife_date: `${deviceManufacturedYear}-${datePicker}-${selectedDays}T00:00:00.000000Z`,
    status: getDeviceTypeStatusValue,
  };
  function SubmitButton() {
    if (
      getModelIdentifierInputValue &&
      deviceTypeInternalNameValue &&
      getCatalogueValue &&
      deviceTypeNameValue &&
      deviceTypeHardwarevalue &&
      getDeviceTypeStatusValue &&
      deviceManufacturedYear &&
      catalogueValidation == false &&
      modelIdentifierUniqueValidation == false &&
      deviceTypeInternalNameUniqueValueFail == false
    ) {
      return dispatch(fetchDeviceTypeCreateButtonEnable(true));
    } else if (deviceTypeDateValidation == true) {
      return dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      return dispatch(fetchDeviceTypeCreateButtonEnable(false));
    }
  }

  useEffect(() => {
    SubmitButton();
  }, [
    getModelIdentifierInputValue,
    deviceTypeInternalNameValue,
    getCatalogueValue,
    deviceTypeNameValue,
    deviceTypeHardwarevalue,
    getDeviceTypeStatusValue,
    deviceTypeDateValidation,
    catalogueValidation,
    modelIdentifierUniqueValidation,
    deviceTypeInternalNameUniqueValueFail,
  ]);
  useEffect(() => {
    if (!postDeviceTypeApi && postDeviceTypeData) {
      dispatch(postDeviceTypesData(postData));
    }
  }, [buttonClick]);
  return (
    <div>
      <ModelIdentifierCatalogueInputs />
      <InternalNameInputFields />
      <HardwareDateInputFields />
      <DeviceTypeStatusInputField />
      <Box mt="10px">
        {postDeviceFailToggle ? <BannerMessage message={postApiFailureMessage} defaultMessage="" /> : ''}
      </Box>
    </div>
  );
};

export default DeviceTypeInputFieldList;
