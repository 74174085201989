/* eslint-disable @typescript-eslint/no-explicit-any */
// here i put any because the event data could any means it could be string or number or aything we cannot predict that.
function debounce(func: Function, wait: number): Function {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default debounce;
