import { useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';
import { AbsoluteCenter, Box, Text } from '@chakra-ui/react';
import { customer } from 'types/customer';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import { customColor } from 'assets/styles/colors';
import { userAccessConst } from 'utils/constants/userAccessConst';

const CustomerListView = () => {
  const navigate = useNavigate();
  const colors = customColor();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const customersData = useAppSelector((state) => state.customers.customersData);

  const hasViewCustomerAccess = userPermissions?.customers?.includes(userAccessConst?.customers?.viewCustomer);

  const orgData = customersData?.data?.map((item: customer) => {
    return {
      id: item.id,
      customerName: item.customer_name,
      customerAdmin: item.customer_admin_first_name + ' ' + item.customer_admin_last_name,
      customerId: item.customer_id,
      email: item.customer_admin_email,
      status: item.status,
    };
  });

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'customerName') {
      navigate(`${row.id}/info`);
    } else {
      navigate(``);
    }
  };

  const columns = [
    {
      field: 'customerName',
      header: <FormattedMessage id="customer_name.message" />,
      link: { hasViewCustomerAccess },
      isCapitalize: true,
    },
    {
      field: 'customerAdmin',
      header: <FormattedMessage id="customer_admin.message" />,
      link: false,
      isCapitalize: true,
    },
    { field: 'customerId', header: <FormattedMessage id="customer_id.message" />, link: false },
    { field: 'email', header: <FormattedMessage id="email.message" />, link: false },
    { field: 'status', header: <FormattedMessage id="status.message" />, link: false },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pr="20px"
      overflowY="scroll"
      h="calc(100vh - 265px)"
      data-testid="customers-data"
      ml="-15px"
    >
      {!orgData?.length ? (
        <AbsoluteCenter display="flex" justifyContent="center" w="100%">
          <Text
            opacity="0.5"
            color={colors.textColor}
            fontSize={{ xs: '16px', lg: '18px' }}
            data-testid="no-match-found"
          >
            <FormattedMessage id="no_matches_found.message" />
          </Text>
        </AbsoluteCenter>
      ) : (
        <CustomTable columns={columns} data={orgData} route={''} handleNavigateFromChild={handleNavigateFromChild} />
      )}
    </Box>
  );
};

export default CustomerListView;
