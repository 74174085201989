export enum UserStatus {
  IDLE = 'idle',
}

export enum LicenseTier {
  BASIC = 'basic',
  ESSENTIAL = 'essential',
  CONNECT = 'connect',
  ENTERPRISE = 'enterprise',
}

export enum AuthType {
  AUTH_DB = 'AUTH0_DB',
  AUTH0_SAML = 'AUTH0_SAML',
}
export const Url_Constants = {
  customerSupport: 'https://www.exo.inc/support/customer-support/',
  termsOfUse: 'https://www.exo.inc/legal/exo-works-terms-of-use/',
  privacyPolicy: 'https://www.exo.inc/legal/privacy-policy/',
  helpCenter: 'https://support.exo.inc/hc/en-us',
  userManual: 'http://www.exo.inc/iris/manual',
  exoWorksPrivacyPolicy: 'https://www.exo.inc/legal/exo-works-privacy-policy/',
  exoWorksHomePage: 'https://www.exo.inc/',
};
