import { Dispatch } from 'react';
import { fetchCountriesFail, setCountriesData, setCountriesIsLoading } from './CommonSlice';
import { setError } from '../error/errorSlice';
import { userRequest } from 'api';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}

export const getCountry = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setCountriesIsLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`countries`);
    if (res && res.status === 200) {
      dispatch(setCountriesData(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchCountriesFail());
  }
};
