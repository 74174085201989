import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';

import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';

import DevicesListView from '../devicesListView/DevicesListView';
import DeviceCardView from '../deviceCardView/DeviceCardView';
import { getPageNumber } from 'redux/devices/Reducers/deviceSlice';
import DevicesLandingScreen from '../deviceLanding/DeviceLandingScreen';
import Loader from 'components/loader/Loader';
function DevicesData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const deviceData = useAppSelector((state: RootState) => state.device);
  const isDeviceGridView = useAppSelector((state: RootState) => state.device.devicesGridView);
  const numberOfPages = useAppSelector((state: RootState) => state.device.numberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.device.paginationData);
  const selectedPage = useAppSelector((state: RootState) => state.device.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);

  const handleMobilePageClick = (item: number) => {
    dispatch(getPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getPageNumber(data.selected + 1));
  };

  return (
    <Box
      position="relative"
      h={{ xs: 'calc(100vh - 140px)', lg: 'calc(100vh - 140px)' }}
      data-testid="devices-data-container"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflowY="scroll"
        h={{
          xs: deviceData.devicesList.length > 0 ? 'calc(100vh - 240px)' : 'calc(100vh - 140px)',
        }}
        data-testid="devices-data-icon"
      >
        {deviceData.deviceListViewLoading ? (
          <Loader isLoading={deviceData.deviceListViewLoading} />
        ) : deviceData &&
          ((deviceData.devicesGridView && deviceData.deviceTypesFilterData.length > 0) ||
            (!deviceData.devicesGridView && deviceData.devicesList.length > 0)) ? (
          <DevicesListView />
        ) : (
          <DevicesLandingScreen isFiltersApplied={isFiltersApplied} />
        )}
      </Box>
      {deviceData && !isDeviceGridView && deviceData.devicesList.length > 0 && (
        <Box
          w="100%"
          display="flex"
          h="100px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          bottom="10px"
          data-testid="devices-data"
        >
          <TableInformation selectedPage={selectedPage} data={deviceData} pagination={paginationData} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={deviceData && deviceData}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={paginationData}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={deviceData && deviceData}
              selectedPage={selectedPage}
              numberOfPages={numberOfPages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default DevicesData;
