/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { Box, Wrap, WrapItem, Text, Input } from '@chakra-ui/react';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  getModelIdentifierUniqueValidation,
  getCatalogueUniqueValidation,
  getDeviceTypeUniqueInternalNameValidation,
} from 'redux/deviceTypes/Actions/DeviceTypeActions';
import {
  fetchDeviceTypeModelIdentifierValidation,
  getModelIdentifierInputData,
  fetchDeviceTypeCreateButtonEnable,
  fetchDeviceTypeModelIdentifierUniqueValidationFail,
  fetchModelSpecialCharacterValidation,
  getCatalogueInputData,
  fetchDeviceTypeCatalogueValidation,
  fetchDeviceTypeCatalogueUniqueValidationFail,
  fetchDeviceTypeNameValidation,
  getDeviceTypeNameInputValue,
  fetchDeviceTypeInternalNameValidation,
  getDeviveTypeInternalName,
  fetchDeviceTypeInternalNameUniqueValidationFail,
  fetchCatalogueSpecialCharacterValidation,
  fetchNameSpecialCharacterValidation,
  fetchInternalNameSpecialCharacterValidation,
} from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  specialCharacterValidation,
  specialAlphaNumericValidation,
} from 'utils/validations/specialCharacterValidation';
import { customColor } from '../../../../../../libs/shared-ui/src/lib/assets';

const DeviceTypeModalNameEditFields = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const colors = customColor();
  const [timer, setTimer] = useState<any>(null);
  const getModelIdentifierInputValue = useAppSelector((state) => state.deviceTypes.getModelIdentifierInputValue);
  const modelIdentifierValidation = useAppSelector((state) => state.deviceTypes.deviceTypeModelIdentifierValidation);
  const [modelIdentifierChanged, setModelIdentifierChanged] = useState<any>(false);

  const getCatalogueValue = useAppSelector((state) => state.deviceTypes.getCatalogueInputValue);
  const catalogueLengthValidation = useAppSelector((state) => state.deviceTypes.deviceTypeCatalogueValidation);
  const [catalogueChanged, setCatalogueChanged] = useState<any>(false);

  const deviceTypeNameValue = useAppSelector((state) => state.deviceTypes.getDeviceTypeNameInputValue);
  const deviceTypeNameValidations = useAppSelector((state) => state.deviceTypes.deviceTypeNameValidation);
  const [deviceTypeNameChanged, setDeviceTypeNameChanged] = useState<any>(false);

  const deviceTypeInternalNameValue = useAppSelector((state) => state.deviceTypes.getDeviveTypeInternalName);
  const deviceTypeInternalNameValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeInternalNameValidation,
  );
  const [deviceTypeInternalNameChanged, setDeviceTypeInternalNameChanged] = useState<any>(false);
  const singleDeviceTypeData = useAppSelector((state: any) => state.deviceTypes.singleDeviceTypeData);
  const modelIdentifierUniqueValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeModelIdentifierUniqueValueFail,
  );
  const catalogueValidation = useAppSelector((state) => state.deviceTypes.deviceTypeCatalogueUniqueValueFail);
  const deviceTypeInternalNameUniqueValueFail = useAppSelector(
    (state) => state.deviceTypes.deviceTypeInternalNameUniqueValueFail,
  );
  const modelSpecialCharacterValidation = useAppSelector((state) => state.deviceTypes.modelSpecialCharacterValidation);

  const [modelIdentifierSpecialValidation, setModelIdentifierSpecialValidation] = useState(false);
  const [nameSpecialValidation, setNameSpecialValidation] = useState(false);
  const [internalNameSpecialValidation, setInternalNameSpecialValidation] = useState(false);
  const catalogueSpecialCharacterValidation = useAppSelector(
    (state) => state.deviceTypes.catalogueSpecialCharacterValidation,
  );
  const nameSpecialCharacterValidation = useAppSelector((state) => state.deviceTypes.nameSpecialCharacterValidation);
  const internalNameSpecialCharacterValidation = useAppSelector(
    (state) => state.deviceTypes.internalNameSpecialCharacterValidation,
  );
  const [internalNameValue, setInternalName] = useState<any>(singleDeviceTypeData?.internal_name);

  const validatorArr = [' ', ',', '.', '-', '_', '', '+'];
  const handleModelIdentier = (e: any) => {
    setModelIdentifierChanged(true);
    if (validatorArr.includes(e.target.value.split('')[0])) {
      setModelIdentifierSpecialValidation(true);
      dispatch(fetchDeviceTypeModelIdentifierValidation(false));
      dispatch(getModelIdentifierInputData(e.target.value));
    } else {
      setModelIdentifierSpecialValidation(false);
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
      dispatch(getModelIdentifierInputData(e.target.value));
      clearTimeout(timer);
      dispatch(fetchDeviceTypeModelIdentifierUniqueValidationFail(false));
      dispatch(fetchDeviceTypeModelIdentifierValidation(false));
    }
    if (e.target.value.trim() !== singleDeviceTypeData?.device_model) {
      dispatch(
        getModelIdentifierUniqueValidation({
          field_name: 'device_model',
          field_value: e.target.value,
        }),
      );
    }
    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(fetchModelSpecialCharacterValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchModelSpecialCharacterValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
    if (e.target.value.length == 0) {
      dispatch(fetchDeviceTypeModelIdentifierValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchDeviceTypeModelIdentifierValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
  };

  const handleDeviceModelOnBlur = () => {
    if (getModelIdentifierInputValue.length == 0 && singleDeviceTypeData?.device_model.length == 0) {
      dispatch(fetchDeviceTypeModelIdentifierValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchDeviceTypeModelIdentifierValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
  };

  const handleCatalogueValue = (e: any) => {
    setCatalogueChanged(true);
    dispatch(fetchDeviceTypeCreateButtonEnable(true));
    dispatch(getCatalogueInputData(e.target.value));
    clearTimeout(timer);
    if (e.target.value.trim() !== singleDeviceTypeData?.catalogue_number) {
      dispatch(
        getCatalogueUniqueValidation({
          field_name: 'catalogue_number',
          field_value: e.target.value.trim(),
        }),
      );
    }
    if (e.target.value.length == 0) {
      dispatch(fetchDeviceTypeCatalogueValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchDeviceTypeCatalogueValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(fetchCatalogueSpecialCharacterValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchCatalogueSpecialCharacterValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }

    dispatch(fetchDeviceTypeCatalogueUniqueValidationFail(false));
  };
  const handleCatalogueOnBlur = () => {
    if (getCatalogueValue.length == 0) {
      dispatch(fetchDeviceTypeCatalogueValidation(true));
    } else {
      dispatch(fetchDeviceTypeCatalogueValidation(false));
    }
  };

  const handleNameValue = (e: any) => {
    setDeviceTypeNameChanged(true);
    if (validatorArr.includes(e.target.value.split('')[0])) {
      setNameSpecialValidation(true);
      dispatch(fetchDeviceTypeNameValidation(false));
      dispatch(getDeviceTypeNameInputValue(e.target.value.trim()));
    } else {
      setNameSpecialValidation(false);
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
      dispatch(getDeviceTypeNameInputValue(e.target.value.trim()));
      dispatch(fetchDeviceTypeNameValidation(false));
    }
    if (e.target.value.trim().length == 0) {
      dispatch(fetchDeviceTypeNameValidation(true));
      setNameSpecialValidation(false);
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchDeviceTypeNameValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(fetchNameSpecialCharacterValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchNameSpecialCharacterValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
  };

  const handleNameOnBlur = () => {
    if (deviceTypeNameValue.length == 0) {
      dispatch(fetchDeviceTypeNameValidation(true));
      setNameSpecialValidation(false);
    } else {
      dispatch(fetchDeviceTypeNameValidation(false));
    }
  };

  const handleInternalNameValue = (e: any) => {
    setDeviceTypeInternalNameChanged(true);
    if (validatorArr.includes(e.target.value.split('')[0])) {
      setInternalNameSpecialValidation(true);
      dispatch(fetchDeviceTypeInternalNameValidation(false));
      setInternalName(e.target.value);
      dispatch(getDeviveTypeInternalName(e.target.value.trim()));
    } else {
      setInternalNameSpecialValidation(false);
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
      dispatch(getDeviveTypeInternalName(e.target.value.trim()));
      clearTimeout(timer);
      dispatch(fetchDeviceTypeInternalNameUniqueValidationFail(false));
      dispatch(fetchDeviceTypeInternalNameValidation(false));
      setInternalName(e.target.value);
      dispatch(getDeviveTypeInternalName(e.target.value));
    }
    if (e.target.value.trim() !== singleDeviceTypeData?.internal_name) {
      dispatch(
        getDeviceTypeUniqueInternalNameValidation({
          field_name: 'internal_name',
          field_value: e.target.value,
        }),
      );
    }
    if (e.currentTarget.value.length == 0) {
      dispatch(fetchDeviceTypeInternalNameValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchDeviceTypeInternalNameValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
    // if ( singleDeviceTypeData?.internal_name.length == 0) {
    //   dispatch(fetchDeviceTypeInternalNameValidation(true));
    //   dispatch(fetchDeviceTypeCreateButtonEnable(false));
    // } else {
    //   dispatch(fetchDeviceTypeInternalNameValidation(false));
    //   dispatch(fetchDeviceTypeCreateButtonEnable(true));
    // }

    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(fetchInternalNameSpecialCharacterValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchInternalNameSpecialCharacterValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
  };
  // const handleInternaLName = () => {
  //   if (deviceTypeInternalNameValue.length == 0) {
  //     dispatch(fetchDeviceTypeInternalNameValidation(true));
  //   } else if (singleDeviceTypeData?.internal_name) {
  //     dispatch(fetchDeviceTypeInternalNameValidation(false));
  //   } else {
  //     dispatch(fetchDeviceTypeInternalNameValidation(false));
  //   }
  // };

  return (
    <Box>
      <Wrap spacingX="30px">
        <Box display="flex" flexDirection="column" minH="80px">
          <InputLabel value={singleDeviceTypeData?.device_model} name={'model_identifier'} />
          <WrapItem>
            <Box>
              {/* <CustomInputField
                defaultValue={singleDeviceTypeData?.device_model}
                name={'model_identifier'}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={handleModelIdentier}
                handleOnBlur={undefined}
              /> */}
              <Input
                color={colors.subTextColor}
                name="model_identifier"
                width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                onChange={handleModelIdentier}
                placeholder={intl.formatMessage({ id: 'model_identifier.message' })}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                type="text"
                fontSize="14px"
                _placeholder={{ fontSize: '14px' }}
                paddingTop="10px"
                maxLength={32}
                defaultValue={singleDeviceTypeData?.device_model}
                key={singleDeviceTypeData?.device_model}
                // onBlur={handleOwnerLastNameActions}
                onKeyPress={specialCharacterValidation}
                variant="flushed"
                required
                data-testid="orgForm-lname"
              />
              <Text fontSize="12px" color="#FB5654">
                {modelIdentifierUniqueValidation ? <FormattedMessage id="model_identifier_exists.message" /> : ''}
                {modelIdentifierValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                {modelIdentifierSpecialValidation ? <FormattedMessage id="field_special_validation.message" /> : ''}
                {modelSpecialCharacterValidation ? <FormattedMessage id="no_special_characters.message" /> : ''}
              </Text>
            </Box>
          </WrapItem>
        </Box>
        <Box display="flex" flexDirection="column" minH="70px">
          <InputLabel value={singleDeviceTypeData?.catalogue_number} name={'catalogue'} />
          <WrapItem>
            <Box>
              {/* <CustomInputField
                defaultValue={singleDeviceTypeData?.catalogue_number}
                name={'catalogue'}
                onKeyPress={specialAlphaNumericValidation}
                maxLength={50}
                handleOnChange={handleCatalogueValue}
                handleOnBlur={undefined}
              /> */}
              <Input
                color={colors.subTextColor}
                name="catalogue"
                width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                onChange={handleCatalogueValue}
                placeholder={intl.formatMessage({ id: 'catalogue.message' })}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                type="text"
                fontSize="14px"
                _placeholder={{ fontSize: '14px' }}
                paddingTop="10px"
                maxLength={100}
                defaultValue={singleDeviceTypeData?.catalogue_number}
                key={singleDeviceTypeData?.catalogue_number}
                // onBlur={handleOwnerLastNameActions}
                onKeyPress={specialCharacterValidation}
                variant="flushed"
                required
                data-testid="orgForm-lname"
              />
              <Text fontSize="12px" color="#FB5654">
                {catalogueValidation ? <FormattedMessage id="catalogue_identifier_exists.message" /> : ''}
                {catalogueLengthValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                {catalogueSpecialCharacterValidation ? <FormattedMessage id="no_special_characters.message" /> : ''}
              </Text>
            </Box>
          </WrapItem>
        </Box>
      </Wrap>
      <Wrap spacingX="30px">
        <Box display="flex" flexDirection="column" minH="70px">
          <InputLabel value={singleDeviceTypeData?.device_name} name={'name'} />
          <WrapItem>
            <Box>
              {/* <CustomInputField
                defaultValue={singleDeviceTypeData?.device_name}
                name={'name'}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={handleNameValue}
                handleOnBlur={undefined}
              /> */}
              <Input
                color={colors.subTextColor}
                name="name"
                width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                onChange={handleNameValue}
                placeholder={intl.formatMessage({ id: 'name.message' })}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                type="text"
                fontSize="14px"
                _placeholder={{ fontSize: '14px' }}
                paddingTop="10px"
                maxLength={32}
                defaultValue={singleDeviceTypeData?.device_name}
                key={singleDeviceTypeData?.device_name}
                // onBlur={handleOwnerLastNameActions}
                onKeyPress={specialCharacterValidation}
                variant="flushed"
                required
                data-testid="orgForm-lname"
              />
              <Text fontSize="12px" color="#FB5654">
                {deviceTypeNameValidations ? <FormattedMessage id="field_validation.message" /> : ''}
                {nameSpecialValidation ? <FormattedMessage id="name_special_validation.message" /> : ''}
                {nameSpecialCharacterValidation ? <FormattedMessage id="no_special_characters.message" /> : ''}
              </Text>
            </Box>{' '}
          </WrapItem>
        </Box>
        <Box display="flex" flexDirection="column" minH="70px">
          <InputLabel value={singleDeviceTypeData?.internal_name} name={'internal_name'} />
          <WrapItem>
            <Box>
              {/* <CustomInputField
                defaultValue={singleDeviceTypeData?.internal_name}
                name={'internal_name'}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={handleInternalNameValue}
                handleOnBlur={undefined}
              /> */}
              <Input
                color={colors.subTextColor}
                name="internal_name"
                width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                onChange={handleInternalNameValue}
                placeholder={intl.formatMessage({ id: 'internal_name.message' })}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                type="text"
                fontSize="14px"
                _placeholder={{ fontSize: '14px' }}
                paddingTop="10px"
                maxLength={32}
                defaultValue={singleDeviceTypeData?.internal_name}
                key={singleDeviceTypeData?.internal_name}
                // onBlur={handleOwnerLastNameActions}
                onKeyPress={specialCharacterValidation}
                variant="flushed"
                required
                data-testid="orgForm-lname"
              />
              <Text fontSize="12px" color="#FB5654">
                {deviceTypeInternalNameUniqueValueFail ? <FormattedMessage id="name_identifier_exists.message" /> : ''}
                {deviceTypeInternalNameValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                {internalNameSpecialValidation ? (
                  <FormattedMessage id="internal_name_special_validation.message" />
                ) : (
                  ''
                )}
                {internalNameSpecialCharacterValidation ? <FormattedMessage id="no_special_characters.message" /> : ''}
              </Text>
            </Box>
          </WrapItem>
        </Box>
      </Wrap>
    </Box>
  );
};

export default DeviceTypeModalNameEditFields;
