import { Box, Text } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import React from 'react';

const StatusInfo = ({
  indicatorColor,
  label,
  indicatorSize = '8px',
}: {
  label?: string;
  indicatorColor?: string;
  indicatorSize: string;
}) => {
  const colors = customColor();
  return (
    <Box display="flex" alignItems="center" color={colors.textColor}>
      <Text h={indicatorSize} w={indicatorSize} mr="5px" borderRadius="50%" backgroundColor={indicatorColor}></Text>
      <Text>{label}</Text>
    </Box>
  );
};
export default StatusInfo;
