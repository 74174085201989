/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { AbsoluteCenter, Box, Button, Circle, CircularProgress, Icon, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { customColor } from 'assets/styles/colors';
import { RootState } from 'redux/Store/store';
import { CircleCheckIconOrg } from 'assets/icons/icons';
import { InputLabel } from 'components/InputField';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchSingleOrganizationID } from 'redux/organizations/Reducers/organizationSlice';
import { editSingleUserInfo, getOrgUsersStatus, getSingleUserData } from 'redux/orgUser/Actions/orguserActions';
import { getSingleUserStatusInputValue, fetchEditSingleUserInfoApiFail } from 'redux/orgUser/Reducer/orgUserSlice';
import SingleUserNonEditFields from './SingleUserNonEditFields';
import Loader from 'components/loader/Loader';
import { Error_Type_Enum } from '../../../../../../../../utils/enum';
import StatusInfo from 'components/status/StatusInfo';

const SingleUserInfo = () => {
  const colors = customColor();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [isEnabled, setButtonEnabled] = useState<boolean>(false);
  const singleUser = useAppSelector((state: RootState) => state.orgUser.SingleUsersGetData);
  const singleUserEmail = useAppSelector((state: RootState) => state.orgUser.SingleUsersGetData?.email);
  const singleUserData = useAppSelector((state: RootState) => state.orgUser);
  const [userStatusValue, setUserStatusValue] = useState<any>(singleUser && singleUser.status);
  const getUserStatusValue = useAppSelector((state) => state.orgUser.getUserStatusInputValue);
  const userStatusData = useAppSelector((state: RootState) => state.orgUser.orgUsersStatus);
  useEffect(() => {
    dispatch(fetchSingleOrganizationID(params.orgId));
    dispatch(getSingleUserData(params.orgId, params.userId));
  }, [params]);

  useEffect(() => {
    dispatch(fetchSingleOrganizationID(params.orgId));
    dispatch(getSingleUserData(params.orgId, params.userId));
    userStatusValue;
  }, []);

  const handleStatusChange = (id: number) => {
    setButtonEnabled(true);
    setUserStatusValue(id);
    dispatch(getSingleUserStatusInputValue(id));
  };

  useEffect(() => {
    setUserStatusValue(singleUser && singleUser.status);
  }, [singleUser]);

  const postInfoData = {
    first_name: singleUser?.first_name,
    last_name: singleUser?.last_name,
    phone: singleUser?.phone,
    role: singleUser?.role,
    session_datetime: singleUser?.session_datetime,
    email: singleUserEmail,
    status: Number(getUserStatusValue && getUserStatusValue.length > 0 ? getUserStatusValue : singleUser?.status),
  };

  useEffect(() => {
    if (singleUserData.editSingleUserInfoApiFail === 'SUCCESS') {
      setTimeout(() => {
        dispatch(fetchEditSingleUserInfoApiFail(''));
        setButtonEnabled(false);
        dispatch(getSingleUserData(params.orgId, params.userId));
      }, 1000);
    }
  }, [singleUserData.editSingleUserInfoApiFail]);

  const handleEditUserInfo = () => {
    dispatch(editSingleUserInfo(postInfoData, params.orgId, params.userId));
  };

  useEffect(() => {
    dispatch(getSingleUserData(params.orgId, params.userId));

    dispatch(getOrgUsersStatus());
  }, [params]);

  return (
    <>
      <Box
        display="block"
        overflowX="hidden"
        overflowY="scroll"
        height="calc(100vh - 180px)"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        data-testid="container-singleuserInfo"
        paddingBottom="50px"
      >
        <Loader isLoading={singleUserData?.SingleUserDataLoading} />

        {singleUser && !singleUserData.SingleUserDataLoading ? (
          <Box w="1200px" display="block" pl="18px">
            <Box>
              <Wrap display="flex" spacing="150px">
                <WrapItem paddingTop="14px">
                  <Box>
                    <Text fontWeight="700" fontSize="24px" minWidth={305}>
                      Information
                    </Text>
                  </Box>
                </WrapItem>

                <WrapItem paddingTop="14px" minWidth={305}>
                  <Box ml="5px">
                    {singleUserData.editSingleUserInfoApiFail === Error_Type_Enum.Success && (
                      <Icon w="23px" h="23px">
                        <CircleCheckIconOrg />
                      </Icon>
                    )}
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>

            <Box mt="20px">
              <Wrap display="flex" spacing="40px">
                <WrapItem>
                  <Box minH="60px">
                    <InputLabel value={userStatusValue} name="status" />

                    {userStatusValue ? (
                      <Box marginLeft="5px" mt={2} fontSize="14px">
                        <StatusInfo
                          indicatorColor={singleUser?.status.color_code}
                          label={singleUser?.status.status_name}
                          indicatorSize="6px"
                        />
                      </Box>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="status.message" />
                      </Text>
                    )}
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
            <Box>
              <SingleUserNonEditFields />
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </>
  );
};

export default SingleUserInfo;
