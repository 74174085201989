/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useState } from 'react';
import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text, Wrap, WrapItem, Icon, Input } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { InputLabel } from 'components/InputField';
import {
  fetchDeviceCatalogueValue,
  fetchDeviceCreateButtonEnable,
  fetchDevicePostToggle,
  fetchDeviceSuccessCreation,
  fetchDeviceTypeValue,
  fetchPostDeviceData,
  fetchPostDeviceFormDataFail,
  getDevicePostCreateButton,
  handleDeviceTypeFieldValidation,
} from 'redux/devices/Reducers/deviceSlice';
import { RootState } from '../../../../../redux/Store/store';
import { getOrgNameWithTooltipForDropDowns } from 'utils/getTextTooltip';

const DeviceTypeCatalogueInputs = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const [deviceTypeValue, setDeviceTypeValue] = useState<string>('');
  const [catalogue, setCatalogue] = useState<string>('');
  const postDataFail = useAppSelector((state: RootState) => state.device.postDeviceFormDataFail);
  const deviceTypesData = useAppSelector((state: RootState) => state.device.deviceTypesData);
  const deviceTypeFieldValidation = useAppSelector((state) => state.device.deviceTypeFieldValidation);
  const handleDevice = (state: any) => {
    setDeviceTypeValue(state.internal_name);
    setCatalogue(state.catalogue_number);
    dispatch(fetchDeviceTypeValue(state.internal_name));
    dispatch(fetchDeviceCatalogueValue(state.id));
    if (postDataFail) {
      dispatch(fetchDeviceCreateButtonEnable(false));
      dispatch(fetchPostDeviceData(false));
      dispatch(getDevicePostCreateButton(false));
      dispatch(fetchDeviceSuccessCreation(false));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(fetchDevicePostToggle(false));
    }
  };
  const handleDeviceTypeOnBlur = () => {
    if (!deviceTypeValue) {
      dispatch(handleDeviceTypeFieldValidation(true));
    } else {
      dispatch(handleDeviceTypeFieldValidation(false));
    }
  };
  // useEffect(() => {
  //   if (scannedValue && isValidQR) {
  //     const obj = {
  //       target: {
  //         name: 'Iris Viola',
  //         value: '1',
  //       },
  //     };
  //     handleDevice(obj);
  //   } else {
  //     const obj = {
  //       target: {
  //         name: '',
  //         value: '',
  //       },
  //     };
  //     handleDevice(obj);
  //   }
  // }, [scannedValue]);

  return (
    <Box>
      <Wrap align="center" justify="first_name" spacingX="30px">
        <WrapItem>
          <Box minH="90px">
            <Box data-testid="device_type_label">
              <InputLabel value={deviceTypeValue} name="device_type_internal_name" />
            </Box>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    marginTop="5px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={
                      isOpen ? (
                        <Icon mr="-10px" as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon mr="-10px" as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {deviceTypeValue ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        marginTop="5px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {getOrgNameWithTooltipForDropDowns(deviceTypeValue)}
                      </Text>
                    ) : (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color="#858585"
                        marginTop="5px"
                      >
                        <FormattedMessage id="device_type.message" />
                      </Text>
                    )}
                  </MenuButton>
                  {isOpen ? (
                    ''
                  ) : (
                    <Text fontSize="12px" color="#FB5654">
                      {deviceTypeFieldValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                    </Text>
                  )}
                  <MenuList
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    fontSize="14px"
                    data-testid="device_menu_types"
                  >
                    {deviceTypesData &&
                      deviceTypesData.map((state: any) => (
                        <MenuItem
                          key={state.id}
                          value={state.catalogue_number}
                          name={state.internal_name}
                          data-testid={state.internal_name}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={() => handleDevice(state)}
                          onBlur={handleDeviceTypeOnBlur}
                        >
                          {getOrgNameWithTooltipForDropDowns(state.internal_name)}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>

        <WrapItem>
          {catalogue ? (
            <Box minH="90px">
              <InputLabel name="catalogue" value={catalogue} />
              <Text fontWeight="400" fontSize="14px" marginTop={4} minWidth={305}>
                {getOrgNameWithTooltipForDropDowns(catalogue)}
              </Text>
            </Box>
          ) : (
            ''
          )}
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default DeviceTypeCatalogueInputs;
