import React from 'react';
import LandingScreen from 'components/landingPage/LandingSreen';
import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import Loader from 'components/loader/Loader';
import { handleOrgLicenseModalPopup } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';

export default function OrgLicensesLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();

  const handleModalOpen = () => {
    dispatch(handleOrgLicenseModalPopup(true));
  };

  const isLoading = useAppSelector((state: RootState) => state.orgLicense.organizationLicensesListLoading);
  return (
    <Box data-testid="landing-screen" ml="10px">
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <LandingScreen
          handleClick={handleModalOpen}
          title="Organization Licenses"
          isFiltersApplied={isFiltersApplied}
          onlyTitle={false}
          newCreation={true}
          pageName={'org_license_empty_page'}
        />
      )}
    </Box>
  );
}
