import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    color: '#33333380',
    _dark: {
      color: 'white',
    },
    fontSize: '12px',
    fontWeight: '300',
  },
  control: {
    borderRadius: '50%', // change the border radius of the control
    _checked: {
      background: 'linear-gradient(302.19deg, #10F8E4 11.83%, #24B0FF 87.11%)',
      border: 0,
      _before: {
        width: 0,
      },
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
