import { Box, Flex, Spacer } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

export interface CustomBarChartData {
  month: string;
  organizations: number;
  irisdevices: number;
  users: number;
  thirdPartyDevices: number;
}

interface CustomBarChartProps {
  chartData: CustomBarChartData[];
}

const CustomBarChart = ({ chartData }: CustomBarChartProps) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box
          width="200px"
          height="157px"
          backgroundColor="white"
          borderRadius="10px"
          boxShadow="lg"
          display="flex"
          padding="10px"
          flexDirection="column"
          transform="translate(-120px,-110px)"
          justifyContent="space-evenly"
        >
          {payload.map((item: any, index: any) => (
            <Box key={index} style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: item.color,
                  borderRadius: '50%',
                  marginRight: '10px',
                }}
              />
              <Flex style={{ width: '100%' }}>
                <Box style={{ flex: 1, fontSize: '12px', fontWeight: '400', textTransform: 'capitalize' }}>
                  {item.name}
                </Box>
                <Spacer />
                <Box style={{ textAlign: 'right', fontSize: '12px', fontWeight: '400' }}>{item.value}</Box>
              </Flex>
            </Box>
          ))}

          <Box
            position="absolute"
            bottom="-20px"
            left="calc(50% - 10px)"
            width="0"
            height="0"
            borderTopWidth="20px"
            borderTopColor="white"
            borderTopStyle="solid"
            borderLeftWidth="10px"
            borderLeftColor="transparent"
            borderRightWidth="10px"
            borderRightColor="transparent"
          />
        </Box>
      );
    }
    return null;
  };

  const maxDataValue = Math.max(
    ...chartData.map((dataItem: any) =>
      Math.max(dataItem.organizations, dataItem.irisdevices, dataItem.users, dataItem.thirdPartyDevices),
    ),
  );

  const yAxisTicks = Array.from({ length: Math.ceil(maxDataValue / 50) + 1 }, (_, index) => index * 50);

  return (
    <Box height="420px">
      <ResponsiveContainer width="100%">
        <BarChart
          width={1170}
          height={320}
          data={chartData}
          barGap={10}
          barCategoryGap={150}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="month" stroke="rgba(51, 51, 51, 0.5)" />
          <YAxis domain={[0, maxDataValue + 50]} ticks={yAxisTicks} stroke="rgba(51, 51, 51, 0.5)" />

          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: 'transparent' }}
            wrapperStyle={{ top: -150, left: 0 }}
            allowEscapeViewBox={{ x: true, y: true }}
          />

          <Legend
            iconType="circle"
            iconSize={10}
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: '20px',
              textTransform: 'capitalize',
            }}
          />
          <Bar dataKey="organizations" fill="#24B0FF" barSize={10} radius={[10, 10, 0, 0]} />
          <Bar dataKey="users" fill="#FF9500" barSize={10} radius={[10, 10, 0, 0]} />
          <Bar name="Iris Devices" dataKey="irisdevices" fill="#4A84F0" barSize={10} radius={[10, 10, 0, 0]} />

          <Bar
            name="Non Iris Ultrasound Devices"
            dataKey="thirdPartyDevices"
            fill="#9124FF"
            barSize={10}
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CustomBarChart;
