import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import CustomTable from 'components/customTable/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { User_Roles } from 'utils/enum';

function OrgUserActivityListView() {
  const OrganizationInfoUsersData = useAppSelector((state) => state.orgUserActivity.orgUsersActivityData);
  const {userRole } = useAppSelector((state) => state.user.userPermissions);
  const isSupportUserRole = userRole == User_Roles.Support;
  const navigate = useNavigate();
  const intl = useIntl();
  const { orgId } = useParams();
  const data = OrganizationInfoUsersData?.map((item: any) => {
    return {
      email: item.email,
      user_name: item.name,
      product: item.product ? item.product + ' ' + item?.app_version : '-',
      platform: item.platform ? item.platform : '-',
      platformVersionInfo: item?.app_device_os ? item?.app_device_os : '-',
      platform_info: item?.app_device_model ? item?.app_device_model : '-',
      event: item.event ? intl.formatMessage({ id: `user_activity_events.${item.event}.message` }) : '-',
      date_and_time: item.date_and_time ? (
        moment.utc(item.date_and_time).format('MMM DD, YYYY HH:mm')
      ) : (
        <FormattedMessage id="empty.message" />
      ),
      userId: item.user_id,
      orgId: orgId,
    };
  });
  const columns = [
    { field: 'user_name', header: <FormattedMessage id="name.message" />, link: !isSupportUserRole, isCapitalize: true },
    { field: 'email', header: <FormattedMessage id="email.message" />, link: !isSupportUserRole },
    { field: 'product', header: <FormattedMessage id="product.message" />, link: false },
    { field: 'platform', header: <FormattedMessage id="platform.message" />, link: false, isCapitalize: true },
    { field: 'platform_info', header: <FormattedMessage id="platform_info.message" />, link: false },
    { field: 'event', header: <FormattedMessage id="event.message" />, link: false },
    { field: 'date_and_time', header: <FormattedMessage id="date_time_utc.message" />, link: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'user_name') {
      navigate(`/organizations/${row?.orgId}/users/${row.userId}/info`);
    } else {
      navigate(``);
    }
  };

  return (
    <Box data-testid="org-users-activity">
      <CustomTable data={data} columns={columns} handleNavigateFromChild={handleNavigateFromChild} />
    </Box>
  );
}

export default OrgUserActivityListView;
