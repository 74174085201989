import React from 'react';
import { Box } from '@chakra-ui/react';
import NoDataFound from 'components/NoDataFound';
import { useAppSelector } from 'redux/hooks';
import Loader from 'components/loader/Loader';
import { RootState } from 'redux/Store/store';

export default function ClientAccessLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const isLoading = useAppSelector((state: RootState) => state.clientAccess.clientAccessListLoading);

  return (
    <Box data-testid="landing-screen" height="100%">
      <Loader isLoading={isLoading} />
      {!isLoading && <NoDataFound title="Organization Devices" isFiltersApplied={isFiltersApplied} />}
    </Box>
  );
}
