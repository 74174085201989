import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { postUsersData } from 'redux/user/Actions/userActions';
import { getUserCreateButtonEnable } from 'redux/user/Reducers/userSlice';
import { UserPostData } from 'types/user-types';
import React, { useEffect } from 'react';
import UserEmailPhoneInputFields from './UserEmailPhoneInputFields';
import UserNameInputFields from './UserNameInputFields';
import UserRoleInputField from './UserRoleInputField';
import UserRolePermissions from './UserRolePermissions';
import { RootState } from 'redux/Store/store';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import { capitalizeText } from '@exo/shared-ui';
import { Country } from 'types/common-types';

const CreateUserFields = () => {
  const dispatch = useAppDispatch();
  const userFirstNameValue = useAppSelector((state: RootState) => state.user.userFirstName);
  const userLastNameChange = useAppSelector((state) => state.user.userLastName);
  const userPhoneValue = useAppSelector((state) => state.user.userPhoneNumber);
  const userEmailValue = useAppSelector((state) => state.user.userEmail);
  const userRole = useAppSelector((state) => state.user.userRoleIDPostData);
  const firstNameLength = useAppSelector((state) => state.user.userFirstNameLength);
  const lastNameLenghth = useAppSelector((state) => state.user.userLastNameLength);
  const postUserToggle = useAppSelector((state) => state.user.postUserToggle);
  const postuserData = useAppSelector((state) => state.user.postUserData);
  const buttonClick = useAppSelector((state) => state.user.userCreateButtonEnableValue);
  const allRegions = useAppSelector((state) => state.user.userAllRegionValue);
  const regionSelectedData = useAppSelector((state) => state.user.userRegionData);
  const userEmailValidation = useAppSelector((state) => state.user.userEmailValidFormat);
  const getUserEmailFail = useAppSelector((state) => state.user.getUserEmailFail);
  const userEmailField = useAppSelector((state) => state.user.userEmailFieldValidation);
  const phoneNumberValidation = useAppSelector((state) => state.organization.phoneNumberValidation);
  const countries = useAppSelector((state) => state.common.countries);
  const phoneNumberCountryCode = useAppSelector((state) => state.organization.phoneNumberCountryCode);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const postUserFormDataFail = useAppSelector((state) => state.user.postUserFormDataFail);

  const postData: UserPostData = {
    first_name: capitalizeText(userFirstNameValue),
    last_name: capitalizeText(userLastNameChange),
    email: userEmailValue,
    role: userRole,
    all_regions: regionSelectedData.length == 0 ? allRegions : false,
    regions: regionSelectedData,
    phone: userPhoneValue,
    phone_code: Number(countries.find((item: Country) => item?.country_phone_code == phoneNumberCountryCode)?.id)
      ? Number(countries.find((item: Country) => item?.country_phone_code == phoneNumberCountryCode)?.id)
      : 1,
  };

  function SubmitButton() {
    if (
      userFirstNameValue &&
      userLastNameChange &&
      userRole &&
      userEmailValue &&
      userEmailValidation == false &&
      getUserEmailFail == false &&
      userEmailField == false &&
      phoneNumberValidation == false
    ) {
      return dispatch(getUserCreateButtonEnable(true));
    } else if (phoneNumberValidation == true || userEmailValidation == true) {
      return dispatch(getUserCreateButtonEnable(false));
    } else {
      return dispatch(getUserCreateButtonEnable(false));
    }
  }
  useEffect(() => {
    SubmitButton();
  }, [
    userFirstNameValue,
    userLastNameChange,
    userEmailValue,
    userRole,
    userEmailField,
    getUserEmailFail,
    userEmailValidation,
    firstNameLength,
    lastNameLenghth,
    userPhoneValue,
  ]);
  useEffect(() => {
    if (!postUserToggle && postuserData == true) {
      dispatch(postUsersData(postData));
    }
  }, [buttonClick]);
  return (
    <Box>
      <UserNameInputFields />
      <UserEmailPhoneInputFields />
      <UserRoleInputField />
      <UserRolePermissions />
      {postUserFormDataFail ? <BannerMessage message={postApiFailureMessage} defaultMessage={''} /> : ''}
    </Box>
  );
};
export default CreateUserFields;
