import React from 'react';
import { Box, Button, Menu, MenuButton, MenuList, Stack, Circle } from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { RootState } from 'redux/Store/store';
import { useAppSelector } from 'redux/hooks';
import { DropIcon } from 'assets/icons/icons';
import getOrgNameWithTooltip from 'utils/getTextTooltip';

type dropDown = {
  label: string;
  options: string[];
  name: string;
  onChange(event: React.FormEvent<HTMLInputElement>): void;
  activeFilters: string[];
  label2: string;
  statusColors?: {};
};

const CustomDropdown = ({ label, options, statusColors = {}, name, onChange, activeFilters, label2 }: dropDown) => {
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const colors = customColor();
  const renderLabel = () => {
    if (activeFilters?.includes('All')) {
      return `All ${label}`;
    }
    return `${activeFilters.length && activeFilters.length === 1 ? activeFilters[0] : activeFilters.length} ${
      activeFilters.length === 1 ? label2 : label
    }`;
  };

  return (
    <Box>
      <Menu data-testid="dropdown_menulist" placement="bottom-end">
        <MenuButton
          backgroundColor="transparent"
          fontSize="16px"
          p="0"
          fontWeight="400"
          id="menu-button"
          color={colors.primaryColor}
          as={Button}
          rightIcon={<DropIcon />}
          _hover={{
            backgroundColor: 'transparent',
          }}
          _active={{
            backgroundColor: 'transparent',
          }}
          __css={{
            'span.chakra-button__icon': {
              marginInlineStart: '.2rem',
            },
          }}
          px="0"
          data-testid="dropdown_label"
          minW={{ xs: '110px', md: '120px' }}
          textTransform="capitalize"
        >
          {renderLabel()}
        </MenuButton>
        <MenuList
          minW="200px"
          bg={colors.lightDarkModeTheme}
          borderWidth="0px"
          zIndex="10000"
          padding="12px 0px 12px 0px"
          paddingBottom="12px"
          boxShadow={theme === 'light' ? `0px 4px 17px rgba(0, 0, 0, 0.08)` : `0px 4px 17px rgba(0, 0, 0, 0.08)`}
        >
          <CheckboxGroup data-testid="dropdown_checkbox_group">
            <Stack px="16px">
              {options?.map((item: string) => (
                <Checkbox
                  isChecked={activeFilters.includes(item)}
                  name={name}
                  key={item}
                  variant="onlyTick"
                  data-testid="dropdown_option"
                  id={item}
                  onChange={onChange}
                  py="6px"
                  className="customCheckbox"
                  textTransform="capitalize"
                >
                  <Box display="flex" alignItems="center">
                    {name === 'status' && !!Object.keys(statusColors)?.length && (
                      <Circle
                        size="6px"
                        bg={
                          statusColors[item as keyof typeof statusColors] ||
                          ' linear-gradient(180deg, #1FD002 1.56%, #9124FF 27.08%, #FF2424 50%, #FF9A24 75.52%, #2496FF 100%)'
                        }
                        mx="5px"
                      ></Circle>
                    )}
                    {getOrgNameWithTooltip(item)}
                  </Box>
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
export default CustomDropdown;
