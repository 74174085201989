import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Box, Flex, Icon, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import { RootState } from 'redux/Store/store';
import { fetchCustomers } from 'redux/customers/cutomersAction';
import { setSelectedPage, setSearchText, handleCustomerModal } from 'redux/customers/customersSlice';
import CustomersListView from './customersListView';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import Loader from 'components/loader/Loader';
import Search from 'components/customSearch/Search';
import CustomerAddModal from '../CustomerAddModal/CustomerAddModal';
import LandingScreen from 'components/landingPage/LandingSreen';

import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { AddIcon } from 'assets/icons/icons';
import { userAccessConst } from 'utils/constants/userAccessConst';

const CustomersContainer = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const selectedPage = useAppSelector((state: RootState) => state.customers.selectedPage);
  const searchText = useAppSelector((state: RootState) => state.customers.searchText);
  const isLoading = useAppSelector((state: RootState) => state.customers.isLoading);
  const customersData = useAppSelector((state: RootState) => state.customers.customersData);
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');
  const customersList = customersData?.data;
  const showEmptyMessage = !isLoading && !searchText.length && !customersData?.pagination?.count;

  useEffect(() => {
    dispatch(fetchCustomers());
    dispatch(setSelectedPage(1));
  }, []);

  const handleMobilePageClick = (item: any) => {
    dispatch(fetchCustomers(item, searchText));
    dispatch(setSelectedPage(item));
  };
  const handleDesktopPageClick = (data: any) => {
    dispatch(fetchCustomers(data.selected + 1, searchText));
    dispatch(setSelectedPage(data.selected + 1));
  };

  const handleSearchValue = (value: string) => {
    dispatch(fetchCustomers(1, value));
    dispatch(setSearchText(value));
  };

  const handleSearchClear = () => {
    dispatch(fetchCustomers(selectedPage, ''));
    dispatch(setSearchText(''));
  };
  const handleAddModal = () => {
    dispatch(handleCustomerModal(true));
  };

  return (
    <Box>
      {showEmptyMessage && (
        <Box data-testid="landing-screen" ml="15px">
          <LandingScreen
            handleClick={handleAddModal}
            title="Customers"
            isFiltersApplied={false}
            onlyTitle={false}
            newCreation={true}
            pageName={'customers_empty_page'}
          />
        </Box>
      )}
      {!showEmptyMessage && (
        <Flex alignItems="center" h="50px" m="15px 15px 0 0" justifyContent="space-between">
          {userPermissions?.customers?.includes(userAccessConst?.customers?.addCustomer) ? (
            <Flex alignItems="center">
              <Text
                mr={{ xs: '3' }}
                fontSize={{ xs: '22px', xl: '24px' }}
                fontWeight="medium"
                data-testid="org-title"
                color={colors.textColor}
              >
                <FormattedMessage id="add_customers.message" />
              </Text>

              <Icon
                w="24px"
                h="24px"
                color={textColorMode}
                fill={fillColorMode}
                onClick={handleAddModal}
                _hover={{
                  color: 'url("#BlueGradient")',
                  cursor: 'pointer',
                  stroke: 'white',
                  fill: 'url("#BlueGradient")',
                  '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                  '&:hover>svg>circle': { stroke: strokeColorMode },
                }}
              >
                <AddIcon stroke={primarblackAndWhiteColorMode} />
              </Icon>
            </Flex>
          ) : (
            <Text fontSize="24px" fontWeight="400" color="rgba(51, 51, 51, 1)">
              <FormattedMessage id="customers.message" />
            </Text>
          )}
          <Spacer />
          {customersData && (
            <Box>
              <Search
                data={{ searchText, isSearchExpand: false }}
                handleClose={() => {}}
                handleClear={handleSearchClear}
                handleExpandClick={() => {}}
                handleSearch={handleSearchValue}
              />
            </Box>
          )}
        </Flex>
      )}
      {isLoading ? <Loader isLoading={isLoading} /> : <CustomersListView />}
      {customersData?.pagination && customersList && customersList.length > 0 && (
        <Flex
          w="100%"
          pr="40px"
          h="100px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          bottom="55px"
        >
          <TableInformation selectedPage={selectedPage} data={customersList} pagination={customersData.pagination} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={customersList}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={customersData.pagination}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={customersList}
              selectedPage={selectedPage}
              numberOfPages={customersData.pagination?.num_pages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Flex>
      )}
      <CustomerAddModal />
    </Box>
  );
};

export default CustomersContainer;
