import { Box, Text, Image, Wrap, Link, WrapItem, Button, Icon } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React, { useEffect } from 'react';
import { customColor } from 'assets/styles/colors';
import DeviceTypeIcon from '../../../assets/images/deviceCircle.svg';
import {
  getDeviceTypesApiData,
  getDeviceTypeStatus,
  getSingleDeviceTypesData,
} from 'redux/deviceTypes/Actions/DeviceTypeActions';
import { useAppDispatch } from '../../../../../../libs/admin-ui/src/lib/redux/hooks';
import {
  getDeviceTypeEditID,
  handleCreateDeviceTypeModal,
  handleEditDeviceTypeModal,
} from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { FormattedMessage } from 'react-intl';
import { getDeviceTypes } from 'redux/devices/Actions/deviceActions';
import Loader from 'components/loader/Loader';
import getOrgNameWithTooltip from 'utils/getTextTooltip';
import { userAccessConst } from 'utils/constants/userAccessConst';
import LandingScreen from 'components/landingPage/LandingSreen';
import { resetDevicePopUpErrors } from 'redux/devices/Reducers/deviceSlice';

function DeviceTypesCardView() {
  const navigate = useNavigate();
  const colors = customColor();
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const deviceTypesData = useAppSelector((state: RootState) => state.deviceTypes.deviceTypesData);
  const isLoading = useAppSelector((state: RootState) => state.deviceTypes.deviceTypesDataLoading);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEditScreen = (e: any) => {
    e.preventDefault();
    const ID = e.currentTarget.id;

    dispatch(getDeviceTypeStatus());
    dispatch(getDeviceTypeEditID(ID));
    dispatch(getSingleDeviceTypesData(e.currentTarget.id));
    dispatch(handleEditDeviceTypeModal(true));
    e.stoppropagation;
  };
  const handleDeviceClick = (item: string) => {
    if (userPermissions.devices?.includes(userAccessConst.device.listDevices)) {
      navigate(`/device-models/${item}/devices`);
    }
  };

  useEffect(() => {
    dispatch(getDeviceTypes());
    dispatch(getDeviceTypesApiData());
    dispatch(resetDevicePopUpErrors());
  }, []);

  const handleDeviceModalOpen = () => {
    dispatch(getDeviceTypeStatus());
    dispatch(handleCreateDeviceTypeModal(true));
  };

  return (
    <Box overflowY="auto" h="calc(100vh - 175px)">
      {isLoading && <Loader isLoading={isLoading} />}
      {deviceTypesData && !isLoading && deviceTypesData.length > 0 ? (
        <Wrap spacingX="120px" alignItems="center">
          {deviceTypesData.map(({ id, device_name, internal_name, device_model }: any) => (
            <Box key={internal_name} alignItems="center" display="flex" mt="40px" flexDirection="column">
              <Box
                onClick={() => handleDeviceClick(internal_name)}
                alignItems="center"
                display="flex"
                cursor="pointer"
                flexDirection="column"
              >
                <Image src={DeviceTypeIcon} w="145px" h="145px" data-testid="device-card-image" />
                <Box alignItems="center" mt="10px">
                  <Text
                    fontSize="18px"
                    color={colors.textColor}
                    textAlign="center"
                    data-testid="device-card-name"
                    fontWeight="400"
                  >
                    {getOrgNameWithTooltip(device_name.concat('-' + internal_name))}
                  </Text>
                </Box>
                <Box data-testid="device-card-country" textDecoration="unset">
                  <Text
                    mb={4}
                    py="0"
                    color={colors.textColor}
                    opacity={{ xs: '0.5', md: '1' }}
                    my="0"
                    fontWeight="400"
                    fontSize="16px"
                  >
                    {getOrgNameWithTooltip(device_model)}
                  </Text>
                </Box>
              </Box>
              <Wrap mt="-8px">
                <WrapItem>
                  <Button
                    type="submit"
                    id={id}
                    onClick={handleEditScreen}
                    _hover={{ cursor: 'pointer' }}
                    _active={{ backgroundColor: 'transparent' }}
                    backgroundColor="transparent"
                    fontWeight="400"
                    fontSize="14px"
                    color="#24B0FF"
                  >
                    <Box>
                      {userPermissions.devices?.includes(userAccessConst.device.listDeviceModels) && (
                        <FormattedMessage id="view.message" />
                      )}
                      {userPermissions.devices?.includes(userAccessConst.device.updateDeviceModel) && (
                        <>
                          {'/'}
                          <FormattedMessage id="edit.message" />
                          <Icon ml="5px" mt="-4px">
                            <EditIcon />
                          </Icon>
                        </>
                      )}
                    </Box>
                  </Button>
                </WrapItem>
              </Wrap>
            </Box>
          ))}
        </Wrap>
      ) : (
        <Box>
          {' '}
          {!isLoading && (
            <LandingScreen
              title="Devices Models"
              onlyTitle={!userPermissions.devices?.includes(userAccessConst.device.addDeviceModel)}
              isFiltersApplied={false}
              handleClick={handleDeviceModalOpen}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default DeviceTypesCardView;
