/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useState } from 'react';
import { Box, Wrap, WrapItem, Button, Icon, Menu, MenuButton, Text, MenuList } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CalendarIcons } from 'assets/icons/icons';

import {
  handleDatePickerDay,
  handleDatePickerMonth,
  handleDatePickerValidation,
  handleDatePickerYear,
} from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';

import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import { resetCreateSettingsPopUpValues } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
const CustomDatePicker = ({ inputName, allowPastDate = true }: { inputName?: any; allowPastDate?: boolean }) => {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const datePicker = useAppSelector((state) => state.customInputFields.getDatePickerMonth);
  const manufacturedYear = useAppSelector((state) => state.customInputFields.getDatePickerYear);
  const manufacturedDateValidation = useAppSelector((state) => state.customInputFields.getDatePickerValidation);
  const selectedDays = useAppSelector((state) => state.customInputFields.getDatePickerDay);
  const [selectedDay, setSelectedDay] = useState<any>('');
  const calendarDateToday = utils(intl.locale).getToday();
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);

  function getMonthName(monthNumber: any) {
    const date = new Date();

    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }
  const handleChange = (e: any, onClose: () => void) => {
    setSelectedDay(e);
    onClose();
    dispatch(handleDatePickerDay(e.day));
    dispatch(handleDatePickerMonth(e.month));
    dispatch(handleDatePickerYear(e.year));
    if (postApiFailureMessage) {
      dispatch(handlePostApiFailureMessage(false));
      dispatch(resetCreateSettingsPopUpValues());
    }
  };
  const handleDateChange = () => {
    if (manufacturedYear.length == 0) {
      dispatch(handleDatePickerValidation(true));
    } else {
      dispatch(handleDatePickerValidation(false));
    }
  };
  return (
    <Wrap align="center" spacingX="30px" data-testid="container-element">
      <WrapItem>
        <Box minH="93px">
          <InputLabel value={datePicker} name={inputName} />
          <Menu>
            {({ isOpen, onClose }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                  borderBottom="1px solid"
                  borderRadius="0px"
                  _focusVisible={{ borderBottomColor: colors.primaryColor }}
                  borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                  variant="flushed"
                  fontSize="14px"
                  data-testid="license-info"
                  onBlur={handleDateChange}
                  rightIcon={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                >
                  {datePicker ? (
                    <Text float="left" marginLeft="-15px" fontWeight="400" color={colors.subTextColor}>
                      {getMonthName(datePicker)} {selectedDays}, {manufacturedYear}
                    </Text>
                  ) : (
                    <Text float="left" marginLeft="-15px" fontWeight="400" color="#8c8c8c">
                      <FormattedMessage id={`${inputName}.message`} />
                    </Text>
                  )}
                </MenuButton>
                <Box fontSize="12px" color="#FB5654">
                  {isOpen ? (
                    ''
                  ) : (
                    <>{manufacturedDateValidation ? <FormattedMessage id="field_validation.message" /> : ''}</>
                  )}
                </Box>
                <MenuList
                  width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                  backgroundColor="transparent"
                  fontSize="14px"
                  border="none"
                  onBlur={handleDateChange}
                  __css={{
                    '.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover':
                      {
                        color: colors.lightModeBlue,
                        background: 'none',
                      },
                    '.Calendar__monthArrowWrapper.-right': {
                      transform: 'none',
                    },
                    '.Calendar__monthArrowWrapper.-left': {
                      transform: 'rotate(180deg)',
                    },
                    '.Calendar__monthArrow': {
                      backgroundImage: "url('/assets/images/left-chevron.svg')",
                    },
                    '.Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus':
                      {
                        background: 'none',
                        color: colors.lightModeBlue,
                      },
                    '.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover':
                      {
                        background: 'none',
                        color: colors.lightModeBlue,
                      },
                  }}
                >
                  <Calendar
                    value={selectedDay}
                    data-testid="data-testid"
                    onChange={(e) => handleChange(e, onClose)}
                    colorPrimary={colors.lightModeBlue}
                    calendarClassName="custom-calendar"
                    calendarTodayClassName="custom-today-day"
                    minimumDate={{
                      year: allowPastDate ? calendarDateToday.year - 5 : calendarDateToday.year, // past 5 years
                      month: calendarDateToday.month,
                      day: calendarDateToday.day,
                    }}
                    maximumDate={{
                      year: calendarDateToday.year + 2, // future 2 years
                      month: calendarDateToday.month,
                      day: calendarDateToday.day,
                    }}
                  />
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
      </WrapItem>
    </Wrap>
  );
};
export default CustomDatePicker;
