import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect, useState } from 'react';

import { RootState } from 'redux/Store/store';
import { useIntl } from 'react-intl';
import {
  getFlterData,
  closeSearchInMobile,
  expandSearchInMobile,
  getSearchText,
  clearSearcText,
  toggleOragnizationsView,
} from 'redux/organizations/Reducers/organizationSlice';
import Search from 'components/customSearch/Search';
import OrganizationsDataSwitch from 'components/customSwich/CustomDataSwitch';
import CustomDropdown from 'components/customDropdown/CustomDropdown';
import { useLocation } from 'react-router-dom';

interface FilterProps {
  isFiltersApplied: boolean;
}

const Filter = ({ isFiltersApplied }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const gridView = useAppSelector((state: RootState) => state.organization.organizationsGridView);
  const organizationData = useAppSelector((state: RootState) => state.organization);
  const { state: locationState } = useLocation();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  type filtersType = {
    status: string[];
    type: string[];
  };

  const DROPDOWN_MAP = [
    {
      label: `${intl.formatMessage({ id: 'tiers.message' })}`,
      options: ['All', ...organizationData?.tiersData?.map((itr) => itr?.tier_name)],
      name: 'type',
      label2: `${intl.formatMessage({ id: 'tier.message' })}`,
    },
    {
      label: `${intl.formatMessage({ id: 'statuses.message' })}`,
      options: ['All', ...organizationData?.statusData?.map((itr) => itr?.status_name)],
      name: 'status',
      label2: `${intl.formatMessage({ id: 'singularStatus.message' })}`,
    },
  ];
  const [filters, setFilters] = useState<filtersType>({
    status: locationState?.statusName ? [locationState?.statusName] : ['All'],
    type: [`${intl.formatMessage({ id: 'all.message' })}`],
  });

  const handleDropDownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (locationState) {
      locationState.statusName = '';
    }
    const { name, id, checked }: { name: string; id: string; checked: boolean } = e.target;
    const index = name === 'status' ? 1 : 0;
    if (checked) {
      if (
        id === `${intl.formatMessage({ id: 'all.message' })}` ||
        filters[name as keyof filtersType].length === DROPDOWN_MAP[index].options.length - 2
      ) {
        setFilters({
          ...filters,
          [name]: [`${intl.formatMessage({ id: 'all.message' })}`],
        });
        return;
      }
      setFilters({
        ...filters,
        [name]: [...filters[name as keyof filtersType], id].filter(
          (itr) => itr !== `${intl.formatMessage({ id: 'all.message' })}`,
        ),
      });
    } else {
      const mutatedArray = [
        ...filters[name as keyof filtersType].filter(
          (itr) => ![id, `${intl.formatMessage({ id: 'all.message' })}`].includes(itr),
        ),
      ];
      setFilters({
        ...filters,
        [name]: [...(mutatedArray.length ? mutatedArray : [`${intl.formatMessage({ id: 'all.message' })}`])],
      });
    }
  };

  useEffect(() => {
    const payload = {
      tier_id: organizationData.tiersData.filter((itr) => filters?.type.includes(itr?.tier_name)).map((itr) => itr.id),
      status_id: organizationData.statusData
        .filter((itr) => filters?.status.includes(itr?.status_name))
        .map((itr) => itr.status_id),
    };
    dispatch(getFlterData(payload));
  }, [filters]);

  const handleSearchClose = () => {
    dispatch(closeSearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSearchText(value));
  };
  useEffect(() => {
    dispatch(closeSearchInMobile());
  }, [isMobileDevice]);

  const handleToggleClick = () => {
    dispatch(toggleOragnizationsView(!gridView));
  };

  const statusColors: { [key: string]: string } = organizationData?.statusData?.reduce(
    (acc, { status_name, color_code }) => ({ ...acc, [status_name]: color_code }),
    {},
  );
  return (
    <Box>
      {((organizationData.organizationsList && organizationData.organizationsList.length > 0) || isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="26px"
          alignItems="center"
        >
          <Search
            data={organizationData}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />
          {!organizationData.isSearchExpand && (
            <Box display="flex" alignItems="center" id="status-types-container">
              {DROPDOWN_MAP.map(({ label, options, name, label2 }) => (
                <Box key={name} pr={{ xs: '0px', md: '26px' }} pl={{ xs: '26px', md: '0px' }} id="dropdown-container">
                  <CustomDropdown
                    statusColors={statusColors}
                    label={label}
                    options={options}
                    activeFilters={filters[name as keyof filtersType]}
                    name={name}
                    onChange={handleDropDownChange}
                    label2={label2}
                  />
                </Box>
              ))}
              <OrganizationsDataSwitch isGridView={gridView} handleClick={handleToggleClick} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default Filter;
