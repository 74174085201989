import { createSlice } from '@reduxjs/toolkit';
import { customers, customer } from 'types/customer';

type InitialState = {
  activeTab: string;
  isLoading: boolean;
  customersData: customers | null;
  customerInfoData: customer | null;
  selectedPage: number;
  searchText: string;
  customersSearchResults: customer[];
  customersSearchLoading: boolean;
  customerEmailValidation: boolean;
  isCustomerEmailValidationLoading: boolean;
  customerNameValidation: boolean;
  isPostCustomerLoading: boolean;
  isPostCustomerDetailsSuccess: boolean;
  isPostCustomerDetailsFail: boolean;
  isCustomerNameValidationLoading: boolean;
  handleModal: boolean;
};

export const initialState: InitialState = {
  activeTab: 'customers',
  isLoading: false,
  customersData: null,
  customerInfoData: null,
  selectedPage: 1,
  searchText: '',
  customersSearchResults: [],
  customersSearchLoading: false,
  customerEmailValidation: false,
  isCustomerEmailValidationLoading: false,
  customerNameValidation: false,
  isPostCustomerLoading: false,
  isPostCustomerDetailsSuccess: false,
  isPostCustomerDetailsFail: false,
  isCustomerNameValidationLoading: false,
  handleModal: false,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomersActiveTabs: (state, { payload }) => {
      state.activeTab = payload;
    },
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setSelectedPage: (state, { payload }) => {
      state.selectedPage = payload;
    },
    setSearchText: (state, { payload }) => {
      state.selectedPage = 1;
      state.searchText = payload;
    },
    setCustomersData: (state, { payload }) => {
      state.isLoading = false;
      state.customersData = payload;
    },
    setCustomerInfoData: (state, { payload }) => {
      state.isLoading = false;
      state.customerInfoData = payload.data;
    },
    setCustomersSearchLoading: (state) => {
      state.customersSearchLoading = true;
    },
    setCustomersSearchResults: (state, { payload }) => {
      state.customersSearchLoading = false;
      state.customersSearchResults = payload.data;
    },
    clearSearchResults: (state) => {
      state.customersSearchResults = [];
    },
    setCustomerValidationLoading: (state) => {
      state.isCustomerEmailValidationLoading = true;
    },
    handleCustomerModal: (state, { payload }) => {
      state.handleModal = payload;
      state.isPostCustomerDetailsFail = false;
      state.isPostCustomerDetailsSuccess = false;
      state.isPostCustomerLoading = false;
      state.customerEmailValidation = false;
      state.customerNameValidation = false;
      state.isCustomerEmailValidationLoading = false;
    },

    setCustomerEmailValidation: (state, { payload }) => {
      state.isCustomerEmailValidationLoading = false;
      state.customerEmailValidation = payload;
    },
    setIsCustomerNameValidationLoading: (state) => {
      state.isCustomerNameValidationLoading = true;
    },
    setCustomerNameValidation: (state, { payload }) => {
      state.isCustomerNameValidationLoading = false;
      state.customerNameValidation = payload;
    },

    postCustomerDetailsLoading: (state) => {
      state.isPostCustomerLoading = true;
    },
    postCustomerDetailsSuccess: (state, { payload }) => {
      state.isPostCustomerLoading = false;
      state.isPostCustomerDetailsSuccess = payload;
    },
    postCustomerDetailsFail: (state, { payload }) => {
      state.isPostCustomerLoading = false;
      state.isPostCustomerDetailsFail = payload;
    },
  },
});

export const {
  setCustomersActiveTabs,
  setIsCustomerNameValidationLoading,
  postCustomerDetailsLoading,
  postCustomerDetailsSuccess,
  handleCustomerModal,
  setIsLoading,
  setSelectedPage,
  setSearchText,
  setCustomersData,
  setCustomerInfoData,
  setCustomersSearchLoading,
  setCustomersSearchResults,
  clearSearchResults,
  postCustomerDetailsFail,
  setCustomerValidationLoading,
  setCustomerEmailValidation,
  setCustomerNameValidation,
} = customersSlice.actions;

export default customersSlice.reducer;
