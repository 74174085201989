import React, { useEffect } from 'react';
import { Text, Flex, Box, useColorModeValue, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import DeviceTypeCreateModal from '../deviceTypeCreateModal/DeviceTypeCreateModal';
import { getDeviceTypeStatus, getDeviceTypesApiData } from 'redux/deviceTypes/Actions/DeviceTypeActions';
import DeviceTypesCardView from './DeviceTypesCardView';
import { getDeviceTypes } from 'redux/devices/Actions/deviceActions';
import { AddIcon } from 'assets/icons/icons';
import DeviceTypeEditModal from '../deviceTypeModelEditScreen/DeviceTypeEditModal';
import { handleCreateDeviceTypeModal } from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import { userAccessConst } from 'utils/constants/userAccessConst';

export default function AddDeviceTypes() {
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const colors = customColor();
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');

  useEffect(() => {
    dispatch(getDeviceTypes());
    dispatch(getDeviceTypesApiData());
  }, []);
  const handleDeviceModalOpen = () => {
    dispatch(getDeviceTypeStatus());
    dispatch(handleCreateDeviceTypeModal(true));
  };

  return (
    <Box alignItems="center" h="50px" ml="36px" mt="19px">
      <Flex alignItems="center" h="50px">
        <Box display="flex">
          <Text mr={{ xs: '3' }} fontSize={{ xs: '22px', xl: '24px' }} color={colors.textColor} data-testid="devices">
            <FormattedMessage id="device_models.message" />
          </Text>
          {userPermissions.devices?.includes(userAccessConst.device.addDeviceModel) && (
            <Box mt="7px">
              <Flex alignItems="center">
                <Icon
                  data-testid="plus-icon"
                  w="24px"
                  h="24px"
                  onClick={handleDeviceModalOpen}
                  color={textColorMode}
                  fill={fillColorMode}
                  _hover={{
                    color: 'url("#BlueGradient")',
                    cursor: 'pointer',
                    stroke: 'white',
                    fill: 'url("#BlueGradient")',
                    '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                    '&:hover>svg>circle': { stroke: strokeColorMode },
                  }}
                >
                  <AddIcon stroke={primarblackAndWhiteColorMode} />
                </Icon>
              </Flex>
            </Box>
          )}
        </Box>
      </Flex>
      <Box overflowY="scroll" scrollBehavior="smooth" h="1200px">
        <Flex mt="20px" flexFlow="column">
          <Box>
            <DeviceTypesCardView />
          </Box>
        </Flex>
        <DeviceTypeEditModal />
        <DeviceTypeCreateModal />
      </Box>
    </Box>
  );
}
