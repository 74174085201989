import * as React from 'react';
import { Box } from '@chakra-ui/react';
import LoginEclipseBackground from '../../assets/images/eclipse.svg';

type LoginImgComponentProps = {
  styles?: React.CSSProperties;
};
function LoginImgComponent(props: LoginImgComponentProps) {
  return (
    <Box
      display={{
        xs: 'none',
        sm: 'none',
        md: 'block',
        lg: 'block',
        xl: 'block',
      }}
      w="100%"
      position="relative"
      overflow="hidden"
      h="100%"
    >
      <Box
        style={props.styles}
        bgImage={LoginEclipseBackground}
        id="circle-image"
        bgPosition="right"
        bgRepeat="no-repeat"
        backgroundSize="contain"
        width="100%"
        top="0"
        left={{ md: '102%', lg: '55%', xl: '25%', dt: '40%' }}
        h="100%"
        position="absolute"
        transform={{
          sm: ' scale(2.8)',
          md: 'sclae(1)',
          lg: ' scale(2.2)',
          xl: 'scale(1)',
          dt: 'scale(1)',
        }}
        data-testid="login-eclipse-image"
      />
    </Box>
  );
}
export default LoginImgComponent;
