import React from 'react';
import { Box } from '@chakra-ui/react';

import NoDataFound from 'components/NoDataFound';
import { RootState } from 'redux/Store/store';
import { useAppSelector } from 'redux/hooks';
import Loader from 'components/loader/Loader';

export default function OrgUserActivityLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const isLoading = useAppSelector((state: RootState) => state.orgUserActivity.orgUsersActivityDataLoading);

  return (
    <Box
      data-testid="landing-screen"
      h={`calc(100vh - 320px)`}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Loader isLoading={isLoading} />
      {!isLoading && <NoDataFound title="Organization Users" isFiltersApplied={isFiltersApplied} />}
    </Box>
  );
}
