import React, { useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getSearchOrgNameDelete,
  handleActivateOrganizationSCValidation,
  orgNameSearchValidation,
} from 'redux/organizations/Reducers/organizationSlice';
import { InputLabel } from 'components/InputField';
import { FormattedMessage } from 'react-intl';
import CustomInputField from 'components/customInputField/CustomInputField';
import { handleCreateButtonEnableInModal } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { postUserActivateData } from 'redux/user/Actions/userActions';
import { useParams } from 'react-router-dom';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';

function ActivatePulseUserStatusInputField() {
  const dispatch = useAppDispatch();
  const { userId } = useParams();

  const orgName = useAppSelector((state) => state.user.singlePulseUserData);
  const orgNameSearchValidationValue = useAppSelector((state) => state.organization.orgNameSearchValidationValue);
  const getSearchOrgNameDeleteValue = useAppSelector((state) => state.organization.getSearchOrgNameDeleteValue);
  const postClientApiData = useAppSelector((state) => state.clientAccess.postClientApiData);
  const clientPostDataFail = useAppSelector((state) => state.clientAccess.clientPostDataFail);

  const postDeleteData = {
    status: Number('1'),
  };

  useEffect(() => {
    if (!clientPostDataFail && postClientApiData == true) {
      dispatch(postUserActivateData(postDeleteData, userId));
    }
  }, [postClientApiData]);

  const handleDelete = (e: any) => {
    dispatch(getSearchOrgNameDelete(e.target.value.trim()));
    dispatch(orgNameSearchValidation(false));
    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(handleActivateOrganizationSCValidation(true));
      dispatch(orgNameSearchValidation(false));
    } else {
      dispatch(handleActivateOrganizationSCValidation(false));
    }
  };
  const handleDeleteOnBlur = () => {
    if (getSearchOrgNameDeleteValue == orgName.first_name + ' ' + orgName.last_name) {
      dispatch(orgNameSearchValidation(false));
      dispatch(handleCreateButtonEnableInModal(true));
    } else {
      dispatch(orgNameSearchValidation(true));
      dispatch(handleActivateOrganizationSCValidation(false));
      dispatch(handleCreateButtonEnableInModal(false));
    }
  };

  return (
    <>
      <Text mb="10px" fontSize="20px" fontWeight="400" color="rgba(51, 51, 51, 0.6)" fontFamily="helvetica">
        This user will be activated and will be able to access Exo Pulse modules based on permissions associated with
        their user role.
      </Text>

      <Box minH="80px" alignItems="center" data-testid="device_name_field_container" mt="20px">
        <Box mb="5px" width={{ xs: '85vw', smd: '89vw', md: '305px' }} data-testid="organization_name_label">
          <InputLabel name="name_of_the_user_to_activated" value={getSearchOrgNameDeleteValue} />
        </Box>
        <Box>
          <CustomInputField
            name={'name_of_the_user_to_activated'}
            onKeyPress={specialCharacterValidation}
            maxLength={101}
            handleOnChange={handleDelete}
            handleOnBlur={handleDeleteOnBlur}
            defaultValue={undefined}
            isEditable={true}
            placeholderSize="13px"
            textSize="13px"
          />
          <Box fontSize="12px" color="#FB5654" mt="-40px">
            {orgNameSearchValidationValue ? <FormattedMessage id="exo_user_name_does_not_match.message" /> : ''}
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default ActivatePulseUserStatusInputField;
