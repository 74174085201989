/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Icon, Menu, MenuButton, MenuList, Wrap, WrapItem, Text, Circle, Input } from '@chakra-ui/react';
import { CalendarIcons } from 'assets/icons/icons';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  fetchDeviceTypeCreateButtonEnable,
  fetchDeviceTypeHardwareVersionValidation,
  fetchDeviceTypeManufacturedDateValidation,
  fetchDeviceTypeManufacturedDay,
  fetchDeviceTypeManufacturedMonth,
  fetchDeviceTypeManufacturedYear,
  fetchHardwareVersionSpecialCharacterValidation,
  getDeviceTypeHarwareInputValue,
  getDeviceTYpeStatusInputValue,
} from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { specialNumericDotValidation } from 'utils/validations/specialCharacterValidation';
import { customColor } from 'assets/styles/colors';

import { editDeviceTypesData } from 'redux/deviceTypes/Actions/DeviceTypeActions';
import DeviceTypeModalNameEditFields from './DeviceTypeModalNameEditFields';
import StatusInfo from 'components/status/StatusInfo';

const DeviceTypeEditFields = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const colors = customColor();
  const selectedDays = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedDay);
  const [selectedDay, setSelectedDay] = useState<any>('');

  const getModelIdentifierInputValue = useAppSelector((state) => state.deviceTypes.getModelIdentifierInputValue);
  const modelIdentifierValidation = useAppSelector((state) => state.deviceTypes.deviceTypeModelIdentifierValidation);
  const getCatalogueValue = useAppSelector((state) => state.deviceTypes.getCatalogueInputValue);
  const catalogueLengthValidation = useAppSelector((state) => state.deviceTypes.deviceTypeCatalogueValidation);
  const deviceTypeNameValue = useAppSelector((state) => state.deviceTypes.getDeviceTypeNameInputValue);
  const deviceTypeNameValidations = useAppSelector((state) => state.deviceTypes.deviceTypeNameValidation);
  const deviceTypeInternalNameValue = useAppSelector((state) => state.deviceTypes.getDeviveTypeInternalName);
  const deviceTypeInternalNameValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeInternalNameValidation,
  );
  const datePicker = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedMonth);
  const deviceManufacturedYear = useAppSelector((state) => state.deviceTypes.getDeviceTypeManufacturedYear);
  const calendarDateToday = utils(intl.locale).getToday();

  const deviceTypeHardwarevalue = useAppSelector((state) => state.deviceTypes.getDeviceTypeHarwareInputValue);

  const deviceStatusData = useAppSelector((state: any) => state.deviceTypes.deviceTypeStatusDataSuccess);
  const singleDeviceTypeData = useAppSelector((state: any) => state.deviceTypes.singleDeviceTypeData);
  const getDeviceTypeStatusValue = useAppSelector((state) => state.deviceTypes.getDeviceTypeStatusValue);
  const [deviceStatusValue, setDeviceStatusValue] = useState<any>(singleDeviceTypeData && singleDeviceTypeData?.status);
  const buttonClick = useAppSelector((state) => state.deviceTypes.deviceTypeCreateButtonEnable);
  const postDeviceTypeApi = useAppSelector((state) => state.deviceTypes.postDeviceTypeApiFailToggle);
  const postDeviceTypeData = useAppSelector((state) => state.deviceTypes.postDeviceTypeData);
  const deviceEditID = useAppSelector((state) => state.deviceTypes.deviceTypeEditID);
  const year = singleDeviceTypeData?.availability_date.slice(0, 4);
  const month = singleDeviceTypeData?.availability_date.slice(5, 7);
  const day = singleDeviceTypeData?.availability_date.slice(8, 10);
  const modelIdentifierUniqueValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeModelIdentifierUniqueValueFail,
  );
  const catalogueValidation = useAppSelector((state) => state.deviceTypes.deviceTypeCatalogueUniqueValueFail);

  const deviceTypeInternalNameUniqueValueFail = useAppSelector(
    (state) => state.deviceTypes.deviceTypeInternalNameUniqueValueFail,
  );
  const deviceTypeHardwareLengthValidation = useAppSelector(
    (state) => state.deviceTypes.deviceTypeHardwareLengthValidation,
  );
  const modelSpecialCharacterValidation = useAppSelector((state) => state.deviceTypes.modelSpecialCharacterValidation);
  const catalogueSpecialCharacterValidation = useAppSelector(
    (state) => state.deviceTypes.catalogueSpecialCharacterValidation,
  );
  const nameSpecialCharacterValidation = useAppSelector((state) => state.deviceTypes.nameSpecialCharacterValidation);
  const internalNameSpecialCharacterValidation = useAppSelector(
    (state) => state.deviceTypes.internalNameSpecialCharacterValidation,
  );
  const hardwareVersionSpecialCharacterFail = useAppSelector(
    (state) => state.deviceTypes.hardwareVersionSpecialCharacterValidation,
  );
  const [modelIdentifierSpecialValidation, setModelIdentifierSpecialValidation] = useState(false);
  const [internalNameSpecialValidation, setInternalNameSpecialValidation] = useState(false);
  const [hardwareSpecialValidation, setHardwareSpecialValidation] = useState(false);
  const validatorArr = [' ', ',', '.', '-', '_', ''];

  const postData: any = {
    device_model: getModelIdentifierInputValue ? getModelIdentifierInputValue : singleDeviceTypeData?.device_model,
    internal_name: deviceTypeInternalNameValue ? deviceTypeInternalNameValue : singleDeviceTypeData?.internal_name,
    catalogue_number: getCatalogueValue ? getCatalogueValue : singleDeviceTypeData?.catalogue_number,
    device_name: deviceTypeNameValue ? deviceTypeNameValue : singleDeviceTypeData?.device_name,
    hardware_version: deviceTypeHardwarevalue ? deviceTypeHardwarevalue : singleDeviceTypeData?.hardware_version,
    availability_date: `${deviceManufacturedYear ? deviceManufacturedYear : year}-${datePicker ? datePicker : month}-${
      selectedDays ? selectedDays : day
    }T00:00:00.000000Z`,
    endoflife_date: `${deviceManufacturedYear ? deviceManufacturedYear : year}-${datePicker ? datePicker : month}-${
      selectedDays ? selectedDays : day
    }T00:00:00.000000Z`,
    status: getDeviceTypeStatusValue ? getDeviceTypeStatusValue : singleDeviceTypeData?.status,
  };
  useEffect(() => {
    if (!postDeviceTypeApi && postDeviceTypeData) {
      dispatch(editDeviceTypesData(postData, deviceEditID));
    }
  }, [buttonClick]);

  const handleDevice = (e: any) => {
    setDeviceStatusValue(e.target.value);
    dispatch(fetchDeviceTypeCreateButtonEnable(true));
    dispatch(getDeviceTYpeStatusInputValue(e.target.value));
  };

  const handleHardwareValue = (e: any) => {
    if (e.target.value.split('')[0] == '.') {
      setHardwareSpecialValidation(true);
      dispatch(fetchDeviceTypeHardwareVersionValidation(false));
      dispatch(getDeviceTypeHarwareInputValue(e.target.value.trim()));
    } else {
      setHardwareSpecialValidation(false);

      dispatch(getDeviceTypeHarwareInputValue(e.target.value));
    }
    if (e.target.value.trim().length == 0) {
      dispatch(fetchDeviceTypeHardwareVersionValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchDeviceTypeHardwareVersionValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(fetchHardwareVersionSpecialCharacterValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchHardwareVersionSpecialCharacterValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
  };
  const handleHardwareVersionOnBlur = () => {
    if (deviceTypeHardwarevalue.length == 0) {
      dispatch(fetchDeviceTypeHardwareVersionValidation(true));
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    } else {
      dispatch(fetchDeviceTypeHardwareVersionValidation(false));
      dispatch(fetchDeviceTypeCreateButtonEnable(true));
    }
  };

  const handleChange = (e: any, onClose: () => void) => {
    setSelectedDay(e);
    onClose();
    dispatch(fetchDeviceTypeCreateButtonEnable(true));
    dispatch(fetchDeviceTypeManufacturedDay(e.day));
    dispatch(fetchDeviceTypeManufacturedMonth(e.month));
    dispatch(fetchDeviceTypeManufacturedYear(e.year));
  };
  const handleDateChange = () => {
    if (deviceManufacturedYear.length == 0) {
      dispatch(fetchDeviceTypeManufacturedDateValidation(true));
    } else {
      dispatch(fetchDeviceTypeManufacturedDateValidation(false));
    }
  };
  function getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }
  useEffect(() => {
    if (deviceTypeInternalNameUniqueValueFail || catalogueValidation || modelIdentifierUniqueValidation) {
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    }
  }, [deviceTypeInternalNameUniqueValueFail, catalogueValidation, modelIdentifierUniqueValidation]);
  useEffect(() => {
    if (
      modelIdentifierValidation == true ||
      catalogueLengthValidation == true ||
      deviceTypeNameValidations == true ||
      deviceTypeInternalNameValidation == true ||
      deviceTypeHardwareLengthValidation == true ||
      // getModelIdentifierInputValue.length == 0 ||
      // deviceTypeNameValue.length == 0 ||
      // deviceTypeHardwarevalue.length == 0 ||
      // getCatalogueValue.length == 0 ||
      // deviceTypeInternalNameValue.length == 0 ||
      modelIdentifierSpecialValidation == true ||
      modelIdentifierUniqueValidation == true ||
      deviceTypeInternalNameUniqueValueFail == true ||
      internalNameSpecialValidation == true ||
      modelSpecialCharacterValidation == true ||
      catalogueSpecialCharacterValidation == true ||
      nameSpecialCharacterValidation == true ||
      internalNameSpecialCharacterValidation == true ||
      hardwareVersionSpecialCharacterFail == true
    ) {
      dispatch(fetchDeviceTypeCreateButtonEnable(false));
    }
  }, [
    modelIdentifierValidation,
    getModelIdentifierInputValue,
    deviceTypeInternalNameValue,
    getCatalogueValue,
    modelSpecialCharacterValidation,
    catalogueLengthValidation,
    deviceTypeHardwarevalue,
    deviceTypeNameValidations,
    deviceManufacturedYear,
    deviceTypeNameValue,
    selectedDays,
    deviceTypeInternalNameValidation,
    deviceTypeHardwareLengthValidation,
    catalogueSpecialCharacterValidation,
    nameSpecialCharacterValidation,
    internalNameSpecialCharacterValidation,
    hardwareVersionSpecialCharacterFail,
  ]);

  const status =
    !!deviceStatusData.length && deviceStatusData.find((item: any) => item.status_id == singleDeviceTypeData?.status);
  return (
    <Box>
      <DeviceTypeModalNameEditFields />
      <Wrap spacingX="30px" mt="15px">
        <Box display="flex" flexDirection="column" minH="70px">
          <InputLabel value={singleDeviceTypeData?.hardware_version} name={'hardware_version'} />
          <WrapItem>
            <Box minH="90px">
              <Input
                color={colors.subTextColor}
                name="hardware_version"
                width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                onChange={handleHardwareValue}
                placeholder={intl.formatMessage({ id: 'hardware_version.message' })}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 1px 0"
                type="text"
                fontSize="14px"
                _placeholder={{ fontSize: '14px' }}
                paddingTop="10px"
                maxLength={32}
                defaultValue={singleDeviceTypeData?.hardware_version}
                key={singleDeviceTypeData?.hardware_version}
                // onBlur={handleOwnerLastNameActions}
                onKeyPress={specialNumericDotValidation}
                variant="flushed"
                required
                data-testid="orgForm-lname"
              />
              <Text fontSize="12px" color="#FB5654">
                {hardwareSpecialValidation ? <FormattedMessage id="hardware_special_validation.message" /> : ''}
                {deviceTypeHardwareLengthValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                {hardwareVersionSpecialCharacterFail ? <FormattedMessage id="no_special_characters.message" /> : ''}
              </Text>
            </Box>
          </WrapItem>
        </Box>
        <WrapItem>
          <Box minH="70px">
            <InputLabel value={month} name="manufactured_date" />
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    onBlur={handleDateChange}
                    rightIcon={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                  >
                    {month ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {getMonthName(datePicker ? datePicker : month)} {selectedDays ? selectedDays : day},{' '}
                        {deviceManufacturedYear ? deviceManufacturedYear : year}
                      </Text>
                    ) : (
                      ''
                    )}
                  </MenuButton>
                  <MenuList
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    backgroundColor="transparent"
                    border="none"
                    onBlur={handleDateChange}
                    __css={{
                      '.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover':
                        {
                          color: colors.lightModeBlue,
                          background: 'none',
                        },
                      '.Calendar__monthArrowWrapper.-right': {
                        transform: 'none',
                      },
                      '.Calendar__monthArrowWrapper.-left': {
                        transform: 'rotate(180deg)',
                      },
                      '.Calendar__monthArrow': {
                        backgroundImage: "url('/assets/images/left-chevron.svg')",
                      },
                      '.Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                      '.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                    }}
                  >
                    <Calendar
                      value={selectedDay}
                      data-testid="data-testid"
                      onChange={(e) => handleChange(e, onClose)}
                      colorPrimary={colors.lightModeBlue}
                      calendarClassName="custom-calendar"
                      calendarTodayClassName="custom-today-day"
                      minimumDate={{
                        year: calendarDateToday.year - 5, // past 5 years
                        month: calendarDateToday.month,
                        day: calendarDateToday.day,
                      }}
                      maximumDate={{
                        year: calendarDateToday.year + 2, // future 2 years
                        month: calendarDateToday.month,
                        day: calendarDateToday.day,
                      }}
                    />
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>
      <WrapItem>
        <Box minH="90px">
          <InputLabel value={singleDeviceTypeData?.status} name="status" />

          <Box display="flex" mt="10px" fontSize="14px">
            <StatusInfo indicatorSize="6px" label={status?.status_name} indicatorColor={status?.color_code} />
          </Box>
        </Box>
      </WrapItem>
    </Box>
  );
};

export default DeviceTypeEditFields;
