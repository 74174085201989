import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import { userAccessConst } from 'utils/constants/userAccessConst';

function OrgUserListView() {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const OrganizationInfoUsersData = useAppSelector((state) => state.orgUser.usersList);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);

  const data = OrganizationInfoUsersData?.map((item: any) => {
    return {
      email: item.email,
      last_login: '-',
      orgId: orgId,
      phone: item.phone ? item?.country_phone_code?.country_phone_code + ' ' + item?.phone : '-',
      role: item.role ? item.role : '-',
      user_name: item.name,
      device_status: item.status.status_name + '*' + item.status.color_code,
      primary_contact: item.primary_contact ? item.primary_contact : '-',
      userId: item.id,
    };
  });
  const columns = [
    {
      field: 'user_name',
      header: <FormattedMessage id="name.message" />,
      link: userPermissions.organizations?.includes(userAccessConst.organization.viewUser),
      isCapitalize: true,
    },
    { field: 'email', header: <FormattedMessage id="email.message" />, link: false },
    { field: 'phone', header: <FormattedMessage id="phone.message" />, link: false },
    { field: 'role', header: <FormattedMessage id="role.message" />, link: false },
    { field: 'last_login', header: <FormattedMessage id="last_login.message" />, link: false },
    { field: 'device_status', header: <FormattedMessage id="status.message" />, link: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'user_name') {
      navigate(`${row.userId}/info`);
    } else {
      navigate(``);
    }
  };

  return (
    <Box data-testid="org-users-list">
      <CustomTable data={data} columns={columns} handleNavigateFromChild={handleNavigateFromChild} />
    </Box>
  );
}

export default OrgUserListView;
