import React from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from '../../assets/styles/colors';

type ForgotPasswordButtonProps = {
  onOpenPasswordResetModal: () => void;
};

const ForgotPasswordButton = ({ onOpenPasswordResetModal }: ForgotPasswordButtonProps) => {
  const colors = customColor();
  return (
    <Text
      onClick={onOpenPasswordResetModal}
      color={colors.violetColor}
      ml="auto"
      fontSize={{ xs: '16px', md: '18px' }}
      cursor="pointer"
    >
      <FormattedMessage id="forgot_password.message" />
    </Text>
  );
};

export default ForgotPasswordButton;
