import { useColorModeValue } from '@chakra-ui/react';

export const customColor = () => {
  return {
    primaryColor: useColorModeValue('light.100', 'dark.100'),
    sidebarColor: useColorModeValue('light.200', 'dark.200'),
    subTextColor: useColorModeValue('light.300', 'dark.300'),
    navColor: useColorModeValue('light.400', 'dark.400'),
    userNameColor: useColorModeValue('light.500', 'dark.500'),
    inputBorderColor: useColorModeValue('light.600', 'dark.600'),
    textColor: useColorModeValue('light.800', 'dark.800'),
    copyRightColor: useColorModeValue('light.900', 'dark.900'),
    searchColor: useColorModeValue('light.1200', 'dark.1200'),
    tableHeadColor: useColorModeValue('light.800', 'dark.1300'),
    paginationDisplay: useColorModeValue('light.1400', 'dark.1400'),
    lightModeBlue: '#24B0FF',
    lightModeBlue20: '#24B0FF20',
    darkModeBlue: '#10F8E4',
    lightModeBanner: '#FE2D531A',
    darkModeBanner: '#25292F',
    fontColor: '#333333',
    shadowColorGradientOne: '#2a2828',
    shadowColorGradientTwo: '#000',
    selectedOrganizationColor: 'rgba(255, 255, 255, 0.15)',
    contactLink: '#5685E9',
    errorTextColor: '#FF2D55',
    bannerWarningText: '#FF9500',
    greyShadedColor: 'rgba(255, 255, 255, 0.50)',
    emailColor: '#FFFFFF',
    darklLoginBg: '#0C0D22',
    lightLoginBg: '#0C2734',
    fontColor60: 'rgba(51, 51, 51, 0.6)', // 60% transparency
    fontColor80: 'rgba(51, 51, 51, 0.8)', // 80% transparency
    mobilePaginationColor: useColorModeValue('rgba(217, 217, 217, 0.17)', 'rgba(62, 70, 80, 1)'),
    buttonBlue: useColorModeValue('#24B0FF', '#10F8E4'),
    buttonBlack: '#333333',
    buttonHoverBlue: '#1D8DCC',
    buttonHoverBlack: '#141414',
    lightModeWhite: useColorModeValue('light.500', 'dark.500'),
    selectedOrgColor: useColorModeValue('light.1600', 'dark.1600'),
    selectedOrgBorderColor: useColorModeValue('light.1700', 'dark.1700'),
    loginTextColor: useColorModeValue('light.1800', 'dark.1800'),
    black: '#000000',
    whiteColor: '#FFFFFF',
    redColor: '#FF2D55',
    whiteSmokeGray: '#F5F5F5',
    borderGray: '#D8DAEC',
    violetColor: '#6864DD',
    brandGrey600: '#C7C7CC',
    light1800: '#384049',
  };
};
