import { useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';
import { AbsoluteCenter, Box, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import { customColor } from 'assets/styles/colors';
import { OrgAddon } from 'types/organization-types';

interface OrgAddonsListViewProps {
  searchText: string;
}

const OrgAddonsListView = ({ searchText }: OrgAddonsListViewProps) => {
  const navigate = useNavigate();
  const colors = customColor();
  const intl = useIntl();

  const addonsData = useAppSelector((state) => state.orgLicense.addonsData);

  const addons: OrgAddon[] = addonsData?.data || [];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'name') {
      navigate(`add-ons/${row.id}/info`);
    } else {
      navigate(``);
    }
  };

  const showNoMatchFound = searchText.length > 0 && !addons?.length;

  const columns = [
    {
      field: 'name',
      header: <FormattedMessage id="addons.message" />,
      link: false,
      isCapitalize: false,
    },
    {
      field: 'catalogNumber',
      header: <FormattedMessage id="catalogue.message" />,
      link: false,
    },
    {
      field: 'activationDate',
      header: <FormattedMessage id="start_date.message" />,
      link: false,
      infoData: intl.formatMessage({ id: `addon_activationdate_info.message` }),
    },
    {
      field: 'expirationDate',
      header: <FormattedMessage id="expiration_date.message" />,
      link: false,
      infoData: intl.formatMessage({ id: `addon_enddate_info.message` }),
    },
    {
      field: 'orderId',
      header: <FormattedMessage id="ord_id.message" />,
      link: false,
    },
    {
      field: 'status',
      header: <FormattedMessage id="status_field.message" />,
      link: false,
    },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pr="20px"
      overflowY="scroll"
      h="calc(100vh - 295px)"
      data-testid="license-addons-data"
      ml="-15px"
    >
      {showNoMatchFound && (
        <AbsoluteCenter display="flex" justifyContent="center" w="100%">
          <Text
            opacity="0.5"
            color={colors.textColor}
            fontSize={{ xs: '16px', lg: '18px' }}
            data-testid="no-match-found"
          >
            <FormattedMessage id="no_matches_found.message" />
          </Text>
        </AbsoluteCenter>
      )}
      {addons?.length > 0 && (
        <CustomTable columns={columns} data={addons} route={''} handleNavigateFromChild={handleNavigateFromChild} />
      )}
    </Box>
  );
};

export default OrgAddonsListView;
