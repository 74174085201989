/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest, BASE_URL } from '../../../api';
import {
  fetchDevicesFail,
  fetchDevicesLoading,
  fetchDevicesuccess,
  fetchDeviceTypesFail,
  fetchDeviceTypesLoading,
  fetchDeviceTypesSuccess,
  fetchStatusFail,
  fetchStatusLoading,
  fetchStatusSuccess,
  fetchDevicePostApiFail,
  fetchDevicePostToggle,
  fetchDeviceSerialNumberFail,
  fetchDeviceSerialNumberLoading,
  fetchDeviceStatusFail,
  fetchDeviceStatusLoading,
  fetchDeviceStatusSuccess,
  fetchDeviceUDIDNumberFail,
  fetchDeviceUDIDNumberLoading,
  fetchPostDeviceFormDataLoading,
  fetchPostDeviceFormDataSuccess,
  getDeviceSerialInputNumber,
  getDeviceUDIDInputNumber,
  fetchOrgNameSearchFail,
  fetchOrgNameSearchData,
  fetchOrgNameSearchLoading,
  fetchPostDeviceFormDataFail,
  postApiSuccess,
  fetchDeviceListLoading,
} from '../Reducers/deviceSlice';
import { DevicePostData, DeviceSearchData, DeviceSerialNumber, DeviceUDIDNumber } from 'types/device-types';
import { Error_Type_Enum } from 'utils/enum';

import { Dispatch } from 'react';
import { setError } from '../../error/errorSlice';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

interface IGetAllAssets {
  type: boolean | string;
}
export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}

let cancelToken: CancelTokenSource;
export const getDevices = (queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchDeviceListLoading(true));
  if (cancelToken) {
    cancelToken.cancel('request canceled due to new request');
  }
  cancelToken = axios.CancelToken.source();

  const baseOpts: AxiosRequestConfig = {
    withCredentials: true,
    cancelToken: cancelToken.token,
  };
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`/devices/?${queryString}`, baseOpts);
    // Taking response data from only last request if multiple request triggered on same time and late response coming from old requests.
    if ((res && res.status === 200) || res.status === 201) {
      dispatch(fetchDevicesuccess(res.data));
      dispatch(fetchDeviceListLoading(false));
      dispatch(postApiSuccess(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchDevicesFail());
  }
};
export const getDeviceStatuses = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchStatusLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('devices/status/');
    dispatch(fetchStatusSuccess(res.data.data));
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchStatusFail());
  }
};

export const getDeviceTypes = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchDeviceTypesLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('catalogues/');
    if (res && res.status === 200) {
      dispatch(fetchDeviceTypesSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchDeviceTypesFail());
  }
};
export const getDeviceStatus = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchDeviceStatusLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`devices/status/`);
    if (res && res.status === 200) {
      dispatch(fetchDeviceStatusSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchDeviceStatusFail());
  }
};

export const getDeviceSerialNumberData =
  (postSerialNumber: DeviceSerialNumber) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchDeviceSerialNumberLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`devices/field/exists/`, postSerialNumber);
      if (res && res.data.status_code === 404) {
        dispatch(getDeviceSerialInputNumber(postSerialNumber.field_value));
        dispatch(fetchDeviceSerialNumberFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchDeviceSerialNumberFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchDeviceSerialNumberFail(false));
    }
  };

export const getDeviceUDIDNumberData =
  (postUDIDNumber: DeviceUDIDNumber) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchDeviceUDIDNumberLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`devices/field/exists/`, postUDIDNumber);
      if (res && res.data.status_code === 404) {
        dispatch(getDeviceUDIDInputNumber(postUDIDNumber.field_value));
        dispatch(fetchDeviceUDIDNumberFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchDeviceUDIDNumberFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchDeviceUDIDNumberFail(false));
    }
  };

export const postDevicesData = (postData: DevicePostData) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchPostDeviceFormDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`devices/add/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(fetchPostDeviceFormDataSuccess(res.data.errors));
      dispatch(fetchDevicePostApiFail(Error_Type_Enum.Success));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(postApiSuccess(true));
    } else {
      dispatch(fetchPostDeviceFormDataFail(true));
      dispatch(handlePostApiFailureMessage(res.data?.errors[0]));
      dispatch(fetchDevicePostApiFail(Error_Type_Enum.Fail));
    }
  } catch (error: any) {
    // dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchDevicePostToggle(true));
    dispatch(handlePostApiFailureMessage(error?.response?.data?.errors[0]));
    dispatch(fetchPostDeviceFormDataFail(true));
    dispatch(fetchDevicePostApiFail(Error_Type_Enum.Fail));
  }
};

export const getOrgNameSearch = (postNameData: DeviceSearchData) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrgNameSearchLoading());

  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/search/`, postNameData);
    if (res && res.status === 200) {
      dispatch(fetchOrgNameSearchData(res.data.data));
    } else {
      dispatch(fetchOrgNameSearchFail());
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchOrgNameSearchFail());
  }
};
