/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

import SingleUserActivityMainContent from './SingleUserActivityMainContent';
import SingleUserActivityFilters from './SingleUserActivityFilters';

import { useRef } from 'react';
import { getSingleUserActivityPageNumber } from 'redux/singleUserActivity/Reducers/singleUserActivitySlice';
import { getSingleUserActivityData } from 'redux/singleUserActivity/Actions/singleUserActivityActions';
import { RootState } from 'redux/Store/store';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';

const SingleUserActivity = () => {
  const singleOrganizationData = useAppSelector((state: RootState) => state.organization.SingleOrganizationsGetData);
  const usersData = useAppSelector((state: RootState) => state.singleUserActivity);
  const params = useParams();
  const dispatch = useAppDispatch();
  const initialRender = useRef({
    primary: true,
    secondary: true,
  });

  const getUserActivityData = () => {
    const payload: any = {
      search: usersData.searchText,
      order: usersData.selectedOrderValue,
      page: usersData.singleUserActivitySelectedPage,
    };
    const queryString = Object.entries(payload)
      .map(([key, value]: any) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');

    dispatch(getSingleUserActivityData(params.orgId, params.userId, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getUserActivityData();
  }, [usersData.singleUserActivitySelectedPage]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getSingleUserActivityPageNumber(1));
    getUserActivityData();
  }, [usersData.searchText, usersData.selectedOrderValue]);

  const search = usersData.searchText;
  const isDataLoading = usersData.singleUserActivityDataLoading;
  const isFiltersApplied = !!(search || isDataLoading);
  useEffect(() => {
    getUserActivityData();
    return () => {
      dispatch(getSingleUserActivityPageNumber(1));
    };
  }, []);

  return (
    <>
      <Box h={`calc(100vh - 180px)`} data-testid="single-user-container">
        {singleOrganizationData && (
          <Box display="block" h={`calc(100vh - 170px)`} pt="20px">
            <Box
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row' }}
              justifyContent={{ xs: 'none', lg: 'space-between' }}
              transition="0.5s ease"
              h="60px"
            >
              <Text fontWeight="700" fontSize="24px" minWidth={305} pl="18px">
                User Activity
              </Text>
              <SingleUserActivityFilters isFiltersApplied={isFiltersApplied} />
            </Box>
            <SingleUserActivityMainContent isFiltersApplied={isFiltersApplied} />
          </Box>
        )}
      </Box>
    </>
  );
};
export default SingleUserActivity;
