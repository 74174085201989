import { Box, Flex, Spacer } from '@chakra-ui/react';
import { CustomSubTabs } from 'types/common-types';
import SubTabItems from './SubTabItems';

const SubTabs = ({ selectedTab, routePaths, routePrefix, customSelect }: CustomSubTabs) => {
  return (
    <Box
      display="flex"
      alignSelf="center"
      mt="20px"
      width={{ xs: '90%', md: 'inherit' }}
      borderBottom="1px"
      borderBottomColor="rgba(51, 51, 51, 0.3)"
    >
      {Object.values(routePaths).map((tab: any, index: number) => {
        return <SubTabItems key={index} isActive={selectedTab === tab} tab={tab} routePrefix={routePrefix} />;
      })}
      <Spacer />
      {customSelect}
    </Box>
  );
};

export default SubTabs;
