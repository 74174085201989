import { createSlice } from '@reduxjs/toolkit';
import { StatusType } from 'types/organization-types';
import { PaginationTypes } from 'types/pagination-types';

type InitialState = {
  singleUserActivityData: string[] | null;
  singleUserActivityDataLoading: boolean;
  singleUserActivityDataError: boolean;
  singleUserActivityDataFail: boolean;
  singleUserActivityNumberOfPages: number;
  singleUserActivityPaginationData: PaginationTypes | null;
  isSingleUserActivityFilterApplied: boolean;
  searchText: string;
  isSearchExpand: boolean;
  singleUserActivityFilterData: { latest_id: string[] };
  latestData: StatusType[];
  latestFilterValue: string;
  singleUserActivitySelectedPage: number;
  selectedOrderValue: number;
};

export const initialState: InitialState = {
  singleUserActivityData: [],
  singleUserActivityDataLoading: false,
  singleUserActivityDataError: false,
  singleUserActivityDataFail: false,
  singleUserActivityNumberOfPages: 1,
  singleUserActivityPaginationData: null,
  isSingleUserActivityFilterApplied: false,
  latestData: [],
  searchText: '',
  isSearchExpand: false,
  singleUserActivityFilterData: { latest_id: [] },
  latestFilterValue: '',
  singleUserActivitySelectedPage: 1,
  selectedOrderValue: 0,
};

const singleUserActivitySlice = createSlice({
  name: 'singleUserActivity',
  initialState,
  reducers: {
    getSingleUserActivityPageNumber: (state, { payload }) => {
      state.singleUserActivitySelectedPage = payload;
    },
    handleSingleUserActivityFilterChange: (state, { payload }) => {
      state.latestFilterValue = payload;
    },
    getSingleUserActivitySearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    getSingleUserActivityFlterData: (state, { payload }) => {
      state.singleUserActivityFilterData = payload;
    },
    expandSingleUserActivitySearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSingleUserActivitySearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSingleUserActivitySearcText: (state) => {
      state.searchText = '';
    },

    fetchSingleUserActivityDataLoading: (state) => {
      state.singleUserActivityDataLoading = true;
      state.singleUserActivityDataError = false;
    },
    fetchSingleUserActivityDataSuccess: (state, { payload }) => {
      state.singleUserActivityData = payload.data;
      state.singleUserActivityPaginationData = payload.pagination;
      state.singleUserActivityNumberOfPages = payload.pagination.num_pages;
      state.singleUserActivityDataLoading = false;
      state.singleUserActivityDataError = false;
    },
    fetchSingleUserActivityDataError: (state) => {
      state.singleUserActivityDataError = true;
      state.singleUserActivityDataLoading = false;
    },

    getSingleUserActivityFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
  },
});

export const {
  fetchSingleUserActivityDataError,
  fetchSingleUserActivityDataLoading,
  fetchSingleUserActivityDataSuccess,
  expandSingleUserActivitySearchInMobile,
  clearSingleUserActivitySearcText,
  closeSingleUserActivitySearchInMobile,
  getSingleUserActivityFlterData,
  getSingleUserActivityPageNumber,
  getSingleUserActivitySearchText,
  handleSingleUserActivityFilterChange,
  getSingleUserActivityFilterValue,
} = singleUserActivitySlice.actions;
export default singleUserActivitySlice.reducer;
