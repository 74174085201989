import CustomTable from 'components/customTable/CustomTable';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { userAccessConst } from 'utils/constants/userAccessConst';

function OrganizationsListView() {
  const navigate = useNavigate();
  const organizationsData = useAppSelector((state: RootState) => state.organization.organizationsList);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const route = 'info';
  const orgData = organizationsData.map((item) => {
    return {
      countryName: item.country_code.country_name,
      fullName: item.org_owner_first_name + ' ' + item.org_owner_last_name,
      device_status: item.status.status + '*' + item.status.color_code,
      tierName: item.tier.tier_name,
      org_name: item.org_name,
      customerId: item.customer?.id,
      customerName: item.customer?.customer_name || '-',
      org_id: item.org_id,
      id: item.id,
      user_id: item.user_id,
      route: `${item.id}/info`,
    };
  });

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'org_name') {
      navigate(`/organizations/${row.org_id}/info`);
    } else if (field === 'fullName') {
      navigate(`/organizations/${row.org_id}/users/${row.user_id}/info`);
    } else if (field === 'customerName') {
      navigate(`/customers/${row.customerId}/info`);
    } else {
      navigate(``);
    }
  };
  const columns = [
    {
      field: 'org_name',
      header: <FormattedMessage id="organization.message" />,
      link: userPermissions.organizations?.includes(userAccessConst.organization.viewOrg),
      isCapitalize: true,
    },
    {
      field: 'fullName',
      header: <FormattedMessage id="organization_admin.message" />,
      link: userPermissions.organizations?.includes(userAccessConst.organization.viewUser),
      isCapitalize: true,
    },
    { field: 'customerName', header: <FormattedMessage id="customer_name.message" />, link: true },
    { field: 'org_id', header: <FormattedMessage id="orgid.message" />, link: false },
    { field: 'countryName', header: <FormattedMessage id="country.message" />, link: false },
    { field: 'tierName', header: <FormattedMessage id="tier.message" />, link: false },
    { field: 'device_status', header: <FormattedMessage id="status_field.message" />, link: false },
  ];

  return (
    <CustomTable columns={columns} data={orgData} route={route} handleNavigateFromChild={handleNavigateFromChild} />
  );
}

export default OrganizationsListView;
