import { AbsoluteCenter, Box, CircularProgress } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import { getSingleDeviceActivityPageNumber } from 'redux/singleOrgDeviceActivity/Reducers/singleOrgDevicesActivitySlice';
import SingleOrgDevicesActivityListView from './singleOrgDeviceActivityListView';
import SingleOrgDevicesActivityLandingScreen from '../landingScreen/singleOrgDeviceActivityLandingScreen';

function SingleOrgDevicesActivityData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const singleDeviceActivityData = useAppSelector(
    (state: RootState) => state.singleOrgDevicesActivitySlice.organizationSingleDevicesActivityList,
  );
  const numberOfPages = useAppSelector(
    (state: RootState) => state.singleOrgDevicesActivitySlice.orgSingleDeviceActivitynumberOfPages,
  );
  const paginationData = useAppSelector(
    (state: RootState) => state.singleOrgDevicesActivitySlice.orgSingleDeviceActivitypaginationData,
  );
  const selectedPage = useAppSelector((state: RootState) => state.singleOrgDevicesActivitySlice.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isLoading = useAppSelector(
    (state: RootState) => state.singleOrgDevicesActivitySlice.organizationSingleDevicesActivityListLoading,
  );

  const handleMobilePageClick = (item: number) => {
    dispatch(getSingleDeviceActivityPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getSingleDeviceActivityPageNumber(data.selected + 1));
  };
  return (
    <Box position="relative" h={`calc(100vh - 250px)`} overflowY="hidden" data-testid="single-org-devices">
      <Box h={`calc(100vh - 250px)`} overflowY="hidden">
        <Box h={`calc(100vh - 300px)`} pl="18px" overflowY="scroll">
          {isLoading && (
            <Box h="100%" display="flex" alignItems="center" justifyContent="center">
              <AbsoluteCenter p="4" axis="both">
                <CircularProgress isIndeterminate size="50px" />
              </AbsoluteCenter>
            </Box>
          )}
          {singleDeviceActivityData.length > 0 && !isLoading ? (
            <SingleOrgDevicesActivityListView />
          ) : (
            <SingleOrgDevicesActivityLandingScreen isFiltersApplied={isFiltersApplied} />
          )}{' '}
        </Box>
        {singleDeviceActivityData.length > 0 && (
          <Box
            display="flex"
            h="50px"
            borderTop="1px solid var(--chakra-colors-chakra-border-color)"
            pl="18px"
            justifyContent="space-between"
            alignItems="center"
          >
            <TableInformation selectedPage={selectedPage} data={singleDeviceActivityData} pagination={paginationData} />
            <Box display={{ xs: 'none', xl: 'block' }}>
              <DesktopPaginate
                data={singleDeviceActivityData && singleDeviceActivityData}
                handleDesktopPageClick={handleDesktopPageClick}
                theme={theme}
                pagination={paginationData}
                selectedPage={selectedPage}
              />
            </Box>
            <Box display={{ xs: 'block', xl: 'none' }}>
              <MobilePagination
                data={singleDeviceActivityData && singleDeviceActivityData}
                selectedPage={selectedPage}
                numberOfPages={numberOfPages}
                handleMobilePageClick={handleMobilePageClick}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default SingleOrgDevicesActivityData;
