import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';

import OrgLicensesListView from './OrgLicensesListView';
import { getPageNumber } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import OrgLicensesLandingScreen from '../landingScreen/OrgLicenseLandingScreen';
function OrgDevicesData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const organizationLicenses = useAppSelector((state: RootState) => state.orgLicense.organizationLincesesList);
  const numberOfPages = useAppSelector((state: RootState) => state.orgLicense.numberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.orgLicense.paginationData);
  const selectedPage = useAppSelector((state: RootState) => state.orgLicense.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isLoading = useAppSelector((state: RootState) => state.orgLicense.organizationLicensesListLoading);

  const handleMobilePageClick = (item: number) => {
    dispatch(getPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getPageNumber(data.selected + 1));
  };
  return (
    <Box position="relative" h={`calc(100vh - 250px)`} overflowY="hidden">
      {' '}
      <Box h={`calc(100vh - 300px)`} px="18px" overflowY="scroll">
        {organizationLicenses.length > 0 && !isLoading ? (
          <OrgLicensesListView />
        ) : (
          <OrgLicensesLandingScreen isFiltersApplied={isFiltersApplied} />
        )}
      </Box>
      {organizationLicenses && organizationLicenses.length > 0 && (
        <Box
          display="flex"
          h="50px"
          borderTop="1px solid var(--chakra-colors-chakra-border-color)"
          pl="25px"
          justifyContent="space-between"
          alignItems="center"
          data-testid="org-license-bundles-data"
        >
          <TableInformation selectedPage={selectedPage} data={organizationLicenses} pagination={paginationData} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={organizationLicenses && organizationLicenses}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={paginationData}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={organizationLicenses && organizationLicenses}
              selectedPage={selectedPage}
              numberOfPages={numberOfPages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default OrgDevicesData;
