import React, { useEffect } from 'react';
import { Box, Flex, Image, Link, Portal, Spinner, Text } from '@chakra-ui/react';
import { exitGrey, sizeIconBlue } from '../../assets/icons';
import { MODAL_CONTENT } from '@exo/shared-ui';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { EXPORT_SUCCESS_TIMEOUT } from '../../utils/exportExamCommonFunc';

type ExportToastProps = {
  loadingExport: boolean;
  downloadLink: React.RefObject<HTMLAnchorElement>;
  modalContent: MODAL_CONTENT | null;
  downloadUrl?: string;
  onClose: () => void;
};

export const ExportToast = ({ loadingExport, downloadLink, modalContent, downloadUrl, onClose }: ExportToastProps) => {
  useEffect(() => {
    if (modalContent === MODAL_CONTENT.ERROR || modalContent === MODAL_CONTENT.NOT_CONNECTED) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
    if (modalContent === MODAL_CONTENT.SUCCESS) {
      setTimeout(() => {
        onClose();
      }, EXPORT_SUCCESS_TIMEOUT);
    }
  }, [modalContent]);

  return (
    <Portal>
      <Flex
        justifyContent="space-between"
        alignItems="start"
        zIndex={9999}
        position="absolute"
        top="50px"
        left={{ xs: '10%', lg: '30%' }}
        right={{ xs: '10%', lg: '0%' }}
        background="brandBlack.100"
        width={{ xs: 'auto', lg: '500px' }}
        boxShadow="0px 1px 15px rgba(0, 0, 0, 0.6)"
        borderRadius="10px"
        padding="12.5px 14.5px 20px 31.5px"
      >
        <Flex color="brandWhite.50" gap="17.5px" pt="7.5px">
          {loadingExport ? (
            <Spinner width="18px" height="18px" color="brandBlue.50" pt="2px" />
          ) : (
            <Image src={sizeIconBlue} w="23px" h="19px" pt="2px" />
          )}
          <Box>
            <Text mb="6px" color="brandDarkGrey.50" fontWeight="700" fontSize="16px" lineHeight="24px">
              <FormattedMessage id="export_media.message" />
            </Text>
            <Text color="brandDarkGrey.50" fontWeight="500" fontSize="16px" lineHeight="24px">
              <FormattedMessage id={modalContent as string} />
            </Text>
            <Flex gap="25px" mt="8px">
              <Text color="rgba(231, 233, 237, 0.5)" fontWeight="500" fontSize="14px" lineHeight="21px">
                <FormattedTime value={new Date()} hour="numeric" minute="2-digit" hour12={true} />
              </Text>
              {!loadingExport &&
                modalContent !== MODAL_CONTENT.ERROR &&
                modalContent !== MODAL_CONTENT.NOT_CONNECTED && (
                  <Link ref={downloadLink} href={downloadUrl} color="brandBlue.50">
                    <FormattedMessage id="download.message" />
                  </Link>
                )}
            </Flex>
          </Box>
        </Flex>
        <Image src={exitGrey} onClick={onClose} cursor="pointer" />
      </Flex>
    </Portal>
  );
};
