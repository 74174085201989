export enum Error_Type_Enum {
  Loading = 'LOADING',
  Fail = 'FAIL',
  Success = 'SUCCESS',
}

export enum UserRole {
  CustomerAdmin = 'Customer Admin',
  FacilityAdmin = 'Facility Admin',
  SepcialtyAdmin = 'Specialty Admin',
  QaReviewer = 'QA Reviewer',
  Credentialed = 'Credentialed',
  NonCredentialed = 'Non Credentialed',
}

export enum User_Status_Enum {
  Done = 'Done',
  Required = 'Required',
  Pending = 'Pending',
}

export enum GettingStartedActionStatus {
  Pending = 'pending',
  Complete = 'complete',
}

export enum GettingStartedComponents {
  UserManagement = 'setup_users',
  OrgManagement = 'setup_org',
  AuthManagement = 'setup_authmgmt',
  ConnectionManagement = 'setup_connections',
}
