import { Dispatch } from '@reduxjs/toolkit';
import { setGettingStartedConfig, setIsLoading, onboardingStatus } from './gettingStarted.slice';
import ApiUtil from '../../api/index';
import { OnboardingConfigApiRes, componentApiRes } from './types.gettingStarted';

const GettingStartedActionRoutes: {
  [key: string]: string;
} = {
  MANAGE_FACILITY_ADMINS: '/admin/users',
  MANAGE_ORG: '/admin/organization/facility',
  MANAGE_SPECIALTY_MANAGEMENT: '/admin/organization/specialties',
  SETUP_SMARTLINK: '/admin/connection/smartlink',
  MANAGE_CONNECTIONS: '/admin/connection/settings',
  MANAGE_AUTH_MANAGEMENT: '/admin/authentication/saml',
};

interface IGetAllAssets {
  type: boolean | string;
}
const orgId = localStorage.getItem('exo_org_id');

export const fetchOnboardingConfigData = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setIsLoading());
  try {
    const response = await ApiUtil.get<OnboardingConfigApiRes>(`/v1/admin/organizations/${orgId}/onboarding-config`);
    const configRes = response.data?.data;
    const mappedRes = {
      id: configRes.id,
      org: configRes.org,
      title: configRes.title,
      subtitle: configRes.subtitle,
      description: configRes.description,
      components: configRes.configurableComponents.map((component: any) => ({
        id: component.id,
        code: component.componentCode,
        name: component.componentName,
        description: component.componentDescription,
        isOptional: component.isOptional,
        dependsOnId: component.dependsOnId,
        status: component.status,
        configurableComponents: component?.configurableItems?.map((item: any) => ({
          code: item.itemCode,
          label: item.itemText,
          status: item.itemStatus,
          url: GettingStartedActionRoutes[item.itemCode],
        })),
      })),
      navigationItems: configRes.navigationItems,
    };
    dispatch(setGettingStartedConfig(mappedRes));
  } catch (error) {}
};

export const fetchOnboardingStatus = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    const response = await ApiUtil.get<any>(`/v1/admin/organizations/${orgId}/onboarding-pending`);
    dispatch(onboardingStatus(response.data?.data));
  } catch (error) {}
};

export const gettingStartedThunk = { fetchOnboardingConfigData, fetchOnboardingStatus };
