import React, { useEffect, useRef } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/Store/store';

import { getPageNumber } from 'redux/orgDevicesActivity/Reducers/orgDevicesActivitySlice';
import { getOrganizationDevicesActivity } from 'redux/orgDevicesActivity/Actions/orgDevicesActivityActions';
import OrgDevicesActivityFilter from './filters/orgDevicesActivityFilter';
import OrgDevicesActivityMainContent from './mainContent/orgDevicesActivityMain';

const OrgDevicesActivity = () => {
  interface OrgFilterPayload {
    page?: number;
    search?: string;
    order?: number;
  }
  const initialRender = useRef({ primary: true, secondary: true });

  const { orgId } = useParams<string>();
  const dispatch = useAppDispatch();
  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const organizationsData = useAppSelector((state: RootState) => state.organizationDevicesActivitySlice);
  const getOrganizationDeviceActivityData = () => {
    const payload: OrgFilterPayload = {
      search: organizationsData.searchText,
      page: organizationsData.selectedPage,
      order: organizationsData.selectedOrderValue,
    };
    const queryString = Object.entries(payload)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');

    dispatch(getOrganizationDevicesActivity(orgId, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getOrganizationDeviceActivityData();
  }, [organizationsData.selectedPage]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getPageNumber(1));
    getOrganizationDeviceActivityData();
  }, [organizationsData.searchText, organizationsData.selectedOrderValue]);

  useEffect(() => {
    getOrganizationDeviceActivityData();
    return () => {
      dispatch(getPageNumber(1));
    };
  }, []);

  const search = organizationsData.searchText;
  const isFiltersApplied = !!search;

  return (
    <>
      <Box>
        {singleOrganizationData && (
          <Box display="block" overflowX="scroll" overflowY="hidden" width="100%">
            <Box display="flex" h="80px" flexDirection="row" pt="20px" justifyContent="space-between">
              <Box fontWeight="700" fontSize="24px" pl="28px" mt="3px">
                <Text>Device Activity</Text>
              </Box>
              <OrgDevicesActivityFilter isFiltersApplied={isFiltersApplied} />
            </Box>
            <Box h={`calc(100vh - 250px)`} overflowY="hidden">
              <OrgDevicesActivityMainContent isFiltersApplied={isFiltersApplied} />{' '}
            </Box>{' '}
          </Box>
        )}
      </Box>
    </>
  );
};
export default OrgDevicesActivity;
