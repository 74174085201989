import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect } from 'react';
import { RootState } from 'redux/Store/store';

import Search from 'components/customSearch/Search';
import { clearSearcText, closeSearchInMobile, expandSearchInMobile, getSearchText, getSingleDeviceActivityFilterValue } from 'redux/singleOrgDeviceActivity/Reducers/singleOrgDevicesActivitySlice';
import CustomSelect from 'components/customSelect/CustomSelect';

interface FilterProps {
  isFiltersApplied: boolean;
}

const SingleOrgDevicesActivityFilter = ({ isFiltersApplied }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
const organizationSingleDevicesActivity = useAppSelector((state:RootState)=>state.singleOrgDevicesActivitySlice)
  const organizationSingleDeviceActivityData = useAppSelector((state: RootState) => state.device);


  const dispatch = useAppDispatch();


  const options = [
    {
      id: 0,
      name: 'By Newest',
    },
    {
      id: 1,
      name: 'By Oldest',
    },
  ];


  const handleSearchClose = () => {
    dispatch(closeSearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSearchText(value));
  };
  useEffect(() => {
    dispatch(closeSearchInMobile());
  }, [isMobileDevice]);

  const handleChange=(value:any)=>{
    dispatch(getSingleDeviceActivityFilterValue(value))
  }
  return (
    <Box>
      {' '}
      {((organizationSingleDevicesActivity.organizationSingleDevicesActivityList && organizationSingleDevicesActivity.organizationSingleDevicesActivityList.length > 0) ||
        isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="26px"
          alignItems="center"
        >
          {' '}
          <Search
            data={organizationSingleDevicesActivity}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />{' '}
          {!organizationSingleDeviceActivityData.isSearchExpand && (
              <CustomSelect handleSelectChange={handleChange} options={options} selectedValue={organizationSingleDevicesActivity.selectedOrderValue} />
          )}{' '}
        </Box>
      )}{' '}
    </Box>
  );
};
export default SingleOrgDevicesActivityFilter;