import { Agreement } from '../../../../../ew-data-models/src/index';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { agreementsThunks } from './agreements.thunk';

export interface AgreementsState {
  pendingDocs: Agreement[];
  isLoading: boolean;
  isPendingDocsFetched: boolean;
  error: string;
}
export interface ErrorState {
  error: {
    message: string;
  };
}

export interface AcceptedIds {
  acceptedAgreementIds: number[];
}

export interface EmailRequest {
  messageTime: string;
  message: string;
  localTime: string;
  deviceInfo: {
    os: string;
    browser: {
      name: string;
      version: string;
    };
    deviceType: string;
  };
}

export interface EmailResponse extends EmailRequest {
  error: string;
}

const initialState: AgreementsState = {
  pendingDocs: [],
  isLoading: false,
  isPendingDocsFetched: false,
  error: '',
};

export const agreementsSlice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AgreementsState>) => {
    builder.addCase(agreementsThunks.fetchPendingAgreements.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(agreementsThunks.fetchPendingAgreements.fulfilled, (state, action) => {
      const { payload } = action;
      state.pendingDocs = payload.results;
      state.isLoading = false;
      state.isPendingDocsFetched = true;
    });
    builder.addCase(agreementsThunks.fetchPendingAgreements.rejected, (state, action) => {
      state.isLoading = false;
      if (action.error.message) state.error = action.error.message;
    });
    builder.addCase(agreementsThunks.acceptAgreements.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(agreementsThunks.acceptAgreements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pendingDocs = state.pendingDocs.filter((doc) => !action.meta.arg.includes(doc.id));
    });
    builder.addCase(agreementsThunks.acceptAgreements.rejected, (state, action) => {
      state.isLoading = false;
      if (action.error.message) {
        state.error = action.error.message;
      }
    });
  },
});

export const agreementActions = agreementsSlice.actions;

export const agreementsReducer = agreementsSlice.reducer;
