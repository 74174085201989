import React, { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { Flex, Icon, Link, FlexProps, Text, Box } from '@chakra-ui/react';
import { ReactText } from 'react';
import { customColor } from '../../assets/styles/colors';
import { routePath as routeConst } from '../../routes/routePaths';
import { RootState } from '../../redux/Store/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleSidebar } from 'redux/systemSettings/systemSettingSlice';
import { toggleLicenseView } from 'redux/license/Reducers/licenseSlice';

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  name: string;
  routePath: string;
  onClose: () => void;
}
export default function NavItem({ icon, name, routePath, onClose, ...rest }: NavItemProps) {
  const colors = customColor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const toggleSidebarInDesktop = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const [isActivePath, setIsActivePath] = useState(false);
  const isNavAllowed: boolean = [
    routeConst.Dashboard,
    routeConst.Customers,
    routeConst.DeviceModels,
    routeConst.Users,
    routeConst.licenses,
    routeConst.Settings,
  ].includes(routePath);

  const handleNavigation = (routePath: string) => {
    if (isNavAllowed) {
      navigate(`/${routePath}`);
      onClose();
    }
    dispatch(toggleLicenseView(true));
  };
  const matchUrlPath = () => {
    if (location.pathname.split('/')[1] === routePath.split('/')[0]) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsActivePath(matchUrlPath());
  }, [location.pathname]);

  return (
    <Link
      onClick={() => handleNavigation(routePath)}
      _disabled={{ disabled: 'true' }}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      textAlign={{ lg: 'center' }}
      flexGrow="1"
      width="100%"
      maxHeight="80px"
    >
      <Flex
        align="center"
        justify="start"
        w={{ lg: 'auto' }}
        borderRadius="lg"
        role="group"
        cursor={isNavAllowed ? 'pointer' : 'not-allowed'}
        color={isActivePath ? colors.primaryColor : colors.sidebarIconsColor}
        _hover={{
          color: isActivePath ? colors.primaryColor : colors.textColor,
          opacity: isActivePath ? 1 : 0.3,
        }}
        fontWeight={isActivePath ? '700' : '400'}
        _active={{
          color: isNavAllowed && colors.primaryColor,
        }}
        {...rest}
      >
        <Box width="40px" height="40px" lineHeight="35px" textAlign="center">
          {icon && <Icon opacity={isActivePath ? 1 : 0.3} as={icon} />}
        </Box>

        <Text
          fontSize={{ xs: '22px', lg: '16px' }}
          ml={{ xs: '22px', lg: '10px' }}
          _hover={{
            color: colors.primaryColor,
          }}
          display={{ xs: 'block', lg: toggleSidebarInDesktop ? 'block' : 'none' }}
          color={isActivePath ? colors.primaryColor : colors.textColor}
          textAlign="left"
        >
          {name}
        </Text>
      </Flex>
    </Link>
  );
}
