import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';

import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import OrgUserActivityListView from './OrgUserActivityListView';
import { getOrgUsersActivityPageNumber } from 'redux/orgUserActivity/Reducers/orgUserActivitySlice';
import OrgUserActivityLandingScreen from './OrgUserActivityLandingScreen';

function OrgUserActivityData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: RootState) => state.orgUserActivity.orgUsersActivityData);
  const numberOfPages = useAppSelector((state: RootState) => state.orgUserActivity.orgUsersActivityNumberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.orgUserActivity.orgUsersActivityPaginationData);
  const selectedPage = useAppSelector((state: RootState) => state.orgUserActivity.orgUsersActivitySelectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);

  const isLoading = useAppSelector((state: RootState) => state.orgUserActivity.orgUsersActivityDataLoading);
  const handleMobilePageClick = (item: number) => {
    dispatch(getOrgUsersActivityPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getOrgUsersActivityPageNumber(data.selected + 1));
  };

  return (
    <Box position="relative" h={`calc(100vh - 250px)`} data-testid="org-users-data" overflowY="hidden">
      <Box overflowY="hidden">
        <Box h={`calc(100vh - 320px)`} pl="18px" overflowY="auto">
          {userData && userData.length > 0 && !isLoading ? (
            <OrgUserActivityListView />
          ) : (
            <OrgUserActivityLandingScreen isFiltersApplied={isFiltersApplied} />
          )}
        </Box>
        {userData && userData.length > 0 && (
          <Box
            display="flex"
            h="70px"
            borderTop="1px solid var(--chakra-colors-chakra-border-color)"
            pl="18px"
            justifyContent="space-between"
            alignItems="center"
          >
            <TableInformation selectedPage={selectedPage} data={userData} pagination={paginationData} />
            <Box display={{ xs: 'none', xl: 'block' }}>
              <DesktopPaginate
                data={userData && userData}
                handleDesktopPageClick={handleDesktopPageClick}
                theme={theme}
                pagination={paginationData}
                selectedPage={selectedPage}
              />
            </Box>
            <Box display={{ xs: 'block', xl: 'none' }}>
              <MobilePagination
                data={userData && userData}
                selectedPage={selectedPage}
                numberOfPages={numberOfPages}
                handleMobilePageClick={handleMobilePageClick}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default OrgUserActivityData;
