export function parseDaysIntoYears(value: number) {
  const YEAR = 365;

  const year = value >= YEAR ? Math.floor(value / YEAR) : 0;
  const day = year ? value - year * YEAR : value;

  let daysInYears = year ? year + ' year' : '';

  daysInYears += year > 1 ? 's ' : ' ';

  daysInYears += day ? day + ' day' : '';

  daysInYears += day > 1 ? 's ' : '';

  return daysInYears;
}
