import { Addon } from 'types/add-on';
import * as Yup from 'yup';

export const initialValues = {
  name: '',
  featureGroup: '',
  featureCode: '',
  bundles: [],
  addOn: {} as Addon,
  notes: '',
  isEnabled: true,
};

export const featuresSchema = () => {
  return Yup.object({
    name: Yup.string().required('This field is required').min(4, 'Must be 4 characters or more'),
    featureGroup: Yup.string()
      .required('This field is required')
      .matches(/^[a-zA-Z0-9.+'_ ,-]+$/, 'Invalid format'),
    bundles: Yup.array(),
    featureCode: Yup.string().required('This field is required'),
    addOn: Yup.object(),
    notes: Yup.string().max(75, 'Max character limit is 75.'),
    isEnabled: Yup.boolean(),
  }).test('bundlesOrAddOn', 'Either bundles or addOn should be selected', function (values) {
    const { bundles, addOn } = values;
    if (!bundles?.length && !addOn.id) {
      return this.createError({
        path: 'bundles',
        message: 'This field is required',
      });
    }
    return true;
  });
};
