import { Box, Flex, Grid, Icon, Link, Text } from '@chakra-ui/react';
import { Organization } from 'assets/icons/icons';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IoArrowDownSharp, IoArrowUpSharp } from 'react-icons/io5';
import Trend from './Trend';

const OrganizationCountSection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector((state) => state.dashboard?.dashboardData);

  const handleRegistered = () => {
    navigate('/customers/?tab=organizations', { state: { statusName: 'Registered', statusId: '7' } });
  };
  const handleProvisioned = () => {
    navigate('/customers/?tab=organizations', { state: { statusName: 'Provisioned', statusId: '6' } });
  };
  const handleExpiringData = () => {
    navigate('/customers/?tab=organizations', { state: { licenseExpireData: true, exceededAllocateData: false } });
  };
  const handleExceededAllocateData = () => {
    navigate('/customers/?tab=organizations', {
      state: { licenseExpireData: false, exceededAllocateData: true },
    });
  };

  return (
    <>
      <Flex p="15px" alignItems="baseline">
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(36, 176, 255, 0.12)',
            width: '40px',
            height: '40px',
            borderRadius: '5px',
          }}
        >
          <Icon color="common.100" height="24px" width="26px">
            <Organization />
          </Icon>
        </Flex>

        <FormattedMessage
          id="dashboard_total_orgs.message"
          values={{
            count: (
              <Text
                style={{
                  color: 'rgba(51, 51, 51, 1)',
                  fontWeight: '700',
                  paddingRight: '10px',
                  fontSize: '30px',
                  paddingLeft: '10px',
                }}
              >
                {dashboardData?.totalOrgs?.count}
              </Text>
            ),
          }}
        />
        {dashboardData?.totalOrgs?.trends && <Trend trendValue={dashboardData?.totalOrgs?.trends} />}
      </Flex>
      <Grid templateColumns={['1fr', '1fr 1fr']} gap={['10px', '15px']} p={['10px', '15px']}>
        <Flex backgroundColor="rgba(0,0,0,0.03)" p="10px" height="auto" alignItems="top" borderRadius="12px">
          <Text color={'common.300'} style={{ fontWeight: '700', paddingRight: '10px' }}>
            {dashboardData?.orgsNotProvisioned?.count}
          </Text>
          <Text>
            <FormattedMessage
              id="dashboard_orgs_not_provisioned.message"
              values={{
                link: (chunks) => (
                  <Link
                    color="light.100"
                    sx={{
                      _hover: {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={handleRegistered}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Text>
        </Flex>

        <Flex backgroundColor="rgba(0,0,0,0.03)" p="10px" height="auto" alignItems="top" borderRadius="12px">
          <Text color={'common.300'} style={{ fontWeight: '700', paddingRight: '10px' }}>
            {dashboardData?.orgsProvisionedNotActive?.count}
          </Text>
          <Text>
            <FormattedMessage
              id="dashboard_orgs_provisioned_not_active.message"
              values={{
                link: (chunks) => (
                  <Link
                    color="light.100"
                    sx={{
                      _hover: {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={handleProvisioned}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Text>
        </Flex>

        <Flex backgroundColor="rgba(0,0,0,0.03)" p="10px" height="auto" alignItems="top" borderRadius="12px">
          <FormattedMessage
            id="dashboard_orgs_with_expiring_licenses.message"
            values={{
              count: (
                <Text color="common.200" fontWeight="700" paddingRight="10px">
                  {dashboardData?.orgsWithExpiringLicensesLessThan30Days?.count}
                </Text>
              ),
              link: (chunks) => (
                <Link
                  color="light.100"
                  sx={{
                    _hover: {
                      textDecoration: 'none',
                    },
                    paddingRight: '5px',
                  }}
                  onClick={handleExpiringData}
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Flex>

        <Flex backgroundColor="rgba(0,0,0,0.03)" p="10px" height="auto" alignItems="top" borderRadius="12px">
          <FormattedMessage
            id="dashboard_orgs_with_breached_licenses.message"
            values={{
              count: (
                <Text color="common.200" fontWeight="700" paddingRight="10px">
                  {dashboardData?.orgsWithLicensesBreached?.count}
                </Text>
              ),
              link: (chunks) => (
                <Link
                  color="light.100"
                  sx={{
                    _hover: {
                      textDecoration: 'none',
                    },
                  }}
                  onClick={handleExceededAllocateData}
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Flex>
      </Grid>
    </>
  );
};

export default OrganizationCountSection;
