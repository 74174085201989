import styled, { keyframes } from 'styled-components/macro';

const LoaderAnimation = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }
  50% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

export const Loader = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.palette.chineseBlack};
  border-radius: 5px;

  filter: brightness(1.5);

  animation-name: ${LoaderAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
`;
