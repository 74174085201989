/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useState } from 'react';
import { Box, Wrap, WrapItem, Text, Button } from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import CustomInputField from 'components/customInputField/CustomInputField';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';
import {
  fetchClientNameInputValue,
  fetchClientNameUniqueValidationFail,
  handleClientInputNameValidation,
  specialCharactersValidation,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { FormattedMessage } from 'react-intl';
import {
  generateApiKey,
  getClientAccesssUniqueNameValidation,
} from 'redux/settings/clientAccess/Actions/clientAccessActions';

const ClientNameInputField = () => {
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<any>(null);
  const clientNameValue = useAppSelector((state) => state.clientAccess.clientNameInputValue);
  const clientNameValidation = useAppSelector((state) => state.clientAccess.clientInputNameValidation);
  const generatedAPIkey = useAppSelector((state) => state.clientAccess.partnerAccessApiKey);
  const clientNameUniqueValidationFail = useAppSelector((state) => state.clientAccess.clientNameUniqueValidationFail);
  const specialCharacterValidationSettings = useAppSelector(
    (state) => state.clientAccess.specialCharacterValidationSettings,
  );
  const handleClientName = (e: any) => {
    dispatch(fetchClientNameInputValue(e.target.value));
    if (e.target.value.length == 0) {
      dispatch(handleClientInputNameValidation(true));
    } else {
      dispatch(handleClientInputNameValidation(false));
    }
    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(specialCharactersValidation(true));
    } else {
      dispatch(specialCharactersValidation(false));
    }

    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      dispatch(
        getClientAccesssUniqueNameValidation({
          field_name: 'name',
          field_value: e.target.value,
        }),
      );
    }, 200);
    dispatch(fetchClientNameUniqueValidationFail(false));
    setTimer(newTimer);
  };

  const handleNameOnBlur = () => {
    if (clientNameValue.length == 0) {
      dispatch(handleClientInputNameValidation(true));
    } else {
      dispatch(handleClientInputNameValidation(false));
    }
  };

  const getGeneratedApi = () => {
    dispatch(generateApiKey());
  };
  return (
    <>
      <Box mt="-53px" ml="240px" w="132px">
        <Button
          pl="13px"
          pt="2px"
          h="24px"
          border="1px solid #24B0FF"
          backgroundColor="transparent"
          borderColor="#24B0FF"
          fontWeight="700"
          fontSize="12px"
          alignItems="center"
          borderRadius="20px"
          color="#24B0FF"
          _hover={{ backgroundColor: 'transparent' }}
          onClick={getGeneratedApi}
        >
          <FormattedMessage id="refresh_api_key.message" />
        </Button>
      </Box>
      <Box>
        <Wrap>
          <WrapItem>
            <Box minH="80px" flexDirection="row" mt="20px">
              <InputLabel value={'phone'} name={'api_key'} />
              <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                {generatedAPIkey ? generatedAPIkey : '-'}
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
      </Box>
      <Box flexDirection="row" minH="90px">
        <InputLabel value={clientNameValue} name={'name'} />
        <WrapItem>
          <Box>
            <CustomInputField
              defaultValue={''}
              name={'name'}
              onKeyPress={specialCharacterValidation}
              maxLength={50}
              handleOnChange={handleClientName}
              handleOnBlur={handleNameOnBlur}
              isEditable={true}
            />
            <Text fontSize="12px" color="#FB5654" mt="-37px">
              {clientNameValidation ? <FormattedMessage id="field_validation.message" /> : ''}
              {clientNameUniqueValidationFail ? <FormattedMessage id="unique_client_name.message" /> : ''}
              {specialCharacterValidationSettings ? <FormattedMessage id="no_special_characters.message" /> : ''}
            </Text>
          </Box>
        </WrapItem>
      </Box>
    </>
  );
};
export default ClientNameInputField;
