import { Box, Input, InputGroup, InputRightElement, Text, WrapItem } from '@chakra-ui/react';
import { ScanIcon } from 'assets/icons/icons';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getScannerValue } from 'redux/devices/Reducers/deviceSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import debounce from 'utils/debounce';
import { AiOutlineClose } from 'react-icons/ai';

const ScannerField = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const intl = useIntl();
  const scannedValue = useAppSelector((state) => state.device.scannedValue);
  const isValid = useAppSelector((state) => state.device.validQRcode);
  let isValidQR = false;
  const inputRef = useRef<HTMLInputElement>(null);
  const handleScanInputChange = (value: string) => {
    const currentValue = value.trim().replace(scannedValue, '');
    let valid = false;

    valid =
      value
        .slice(18, 24)
        .split('')
        .every((item) => isFinite(Number(item))) &&
      Number(value.slice(22, 24)) > 0 &&
      Number(value.slice(22, 24)) < 32 &&
      Number(value.slice(20, 22)) >= 0 &&
      Number(value.slice(20, 22)) <= 12;
    if (currentValue.length > 38 && currentValue.length <= 40 && valid) {
      isValidQR = true;
    }
    dispatch(getScannerValue({ value: currentValue, isValidQR }));
  };

  const debounceCB = debounce((value: string) => handleScanInputChange(value), 1500);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  useEffect(() => {
    if (scannedValue) {
      inputRef.current?.blur();
    }
    if (!scannedValue) {
      inputRef.current?.focus();
    }
  }, [scannedValue]);
  return (
    <Box>
      <WrapItem w="645px">
        <Box minH="90px">
          <Box data-testid="device-form-scanner">
            <InputLabel name="scan" value={scannedValue} />
          </Box>
          <Box display="flex">
            <Box mt="10px" w="50px" data-testid="device-form-icon">
              <ScanIcon />
            </Box>
            <Box>
              <InputGroup size="md" px="0">
                <Input
                  color={colors.subTextColor}
                  name="serial number"
                  // value={scannedValue}
                  width={{ xs: '75vw', smd: '89vw', md: '590px' }}
                  borderColor="#E0E0E0"
                  focusBorderColor={!isValid && scannedValue.length > 0 ? '#FB5654' : colors.primaryColor}
                  borderBottomColor={!isValid && scannedValue.length > 0 ? '#FB5654' : colors.inputBorderColor}
                  borderWidth="0 0 1px 0"
                  onChange={(e) => debounceCB(e.target.value)}
                  placeholder={intl.formatMessage({ id: 'scan.message' })}
                  type="text"
                  ref={inputRef}
                  fontSize="14px"
                  _placeholder={{ fontSize: '14px' }}
                  variant="flushed"
                  required
                  data-testid="device-form-serial"
                />
                {scannedValue.length > 0 && (
                  <InputRightElement
                    children={<AiOutlineClose color={colors.primaryColor} />}
                    mx="0"
                    color={colors.primaryColor}
                    data-testid="organization_search_close_icon"
                    px="0"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (inputRef.current) {
                        inputRef.current.value = '';
                      }
                      dispatch(getScannerValue({ value: '', isValidQR: false }));
                    }}
                  />
                )}
              </InputGroup>
              {!isValid && scannedValue.length > 0 ? (
                <Text fontSize="12px" color="#FB5654" data-testid="invalid-scan-text">
                  <FormattedMessage id="invalid_bar_code.message" />
                </Text>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      </WrapItem>
    </Box>
  );
};

export default ScannerField;
