import { Box, Flex, Icon, Image, Spacer, Text } from '@chakra-ui/react';
import userImage from '../../assets/images/userImage.svg';
import irisDeviceImage from '../../assets/images/iris-deviceImage.svg';
import ultraSound from '../../assets/images/ultraSound.svg';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'redux/hooks';
import Trend from './Trend';

const UserCountSection = () => {
  const dashboardData = useAppSelector((state) => state.dashboard?.dashboardData);

  return (
    <Flex p="15px 0 15px 15px" gap="20px" flexDirection="column">
      <Flex alignItems="center">
        <Image src={userImage} />
        <Flex alignItems="baseline" height="auto" borderRadius="12px">
          <Text fontWeight="700" fontSize="30px" mx="10px">
            {dashboardData?.activeUser?.count}
          </Text>
          <FormattedMessage id="dashboard_active_users.message" />
        </Flex>
        <Spacer />
        {dashboardData?.activeUser?.trends && <Trend trendValue={dashboardData?.activeUser?.trends} />}
      </Flex>
      <Flex alignItems="center">
        <Image src={irisDeviceImage} />
        <Flex alignItems="baseline" height="auto" borderRadius="12px">
          <Text fontWeight="700" fontSize="30px" mx="10px">
            {dashboardData?.activeDevice?.count}
          </Text>
          <FormattedMessage id="dashboard_active_devices.message" />
        </Flex>
        <Spacer />
        {dashboardData?.activeDevice?.trends && <Trend trendValue={dashboardData?.activeDevice?.trends} />}
      </Flex>
      <Flex alignItems="center">
        <Image src={ultraSound} />
        <Flex alignItems="baseline" height="auto" borderRadius="12px>">
          <Text fontWeight="700" fontSize="30px" mx="10px">
            {dashboardData?.thirdPartyDevices?.count}
          </Text>
          <FormattedMessage id="dashboard_iris_ultrasound_devices.message" />
        </Flex>
        <Spacer />
        {dashboardData?.thirdPartyDevices?.trends && <Trend trendValue={dashboardData?.thirdPartyDevices?.trends} />}
      </Flex>
    </Flex>
  );
};

export default UserCountSection;
