import { Box, useDisclosure } from '@chakra-ui/react';
import { CircleRightEnabledIcon } from 'assets/icons/icons';
import CustomDeleteModal from 'components/customModal/CustomDeleteModal';
import CustomModal from 'components/customModal/CustomModal';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { resetCustomModalEnableFields } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { openProvisionedModal, resetOrganizationCreateActions } from 'redux/organizations/Reducers/organizationSlice';
import { getSingleDevicesData } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import { handleOrgDeviceUnAssignModal, resetOrgDeviceValues } from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import {
  handleClientPostDataCreateButtonEnable,
  handleClientSuccessfulPost,
  handlePostApiDataSpinner,
  handleToPostApiData,
  resetCreateSettingsPopUpValues,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { Error_Type_Enum } from 'utils/enum';
import OrgDeviceUnAssignInputFields from './OrgDeviceUnAssignInputFields';
const OrgInfoDeviceUnAssignModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { onClose } = useDisclosure();
  const postDataSuccessfulCreation = useAppSelector((state) => state.clientAccess.enableSuccessfulPostData);
  const enableCreateButton = useAppSelector((state) => state.customInputFields.handleCreateButtonEnable);
  const handleUnAssignOrgDeviceModal = useAppSelector((state) => state.organizationdevice.openOrgDeviceUnAssignModal);
  const createClientPostApiFail = useAppSelector((state) => state.clientAccess.clientPostDataFailStrings);
  const postFailure = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const toggleClientButtonEnableSpinnerValue = useAppSelector((state) => state.clientAccess.postClientApiDataSpinner);
  const deviceOrgID = useAppSelector((state) => state.organizationdevice.SingleDeviceGetData?.id);

  useEffect(() => {
    if (createClientPostApiFail.length > 0) {
      dispatch(handleClientSuccessfulPost(true));
    }
    if (createClientPostApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(handleOrgDeviceUnAssignModal(false));
        dispatch(resetCustomModalEnableFields());
        dispatch(resetCreateSettingsPopUpValues());
        dispatch(resetOrganizationCreateActions());
        dispatch(resetOrgDeviceValues());
        dispatch(handleClientPostDataCreateButtonEnable(false));
        if (location.pathname === `/organizations/${orgId}/devices/${deviceOrgID}/info`) {
          navigate(`/organizations/${orgId}/devices`);
        }
        dispatch(getSingleDevicesData(deviceOrgID));
        onClose();
      }, ModalTimeOut);
    }
  }, [createClientPostApiFail]);
  const handleModalClose = () => {
    dispatch(handleOrgDeviceUnAssignModal(false));
    dispatch(resetOrganizationCreateActions());
    dispatch(resetCreateSettingsPopUpValues());
    dispatch(resetCustomModalEnableFields());
    dispatch(resetOrgDeviceValues());
  };
  const handleCreateButtonClick = () => {
    dispatch(handleToPostApiData(true));
    dispatch(handlePostApiDataSpinner(true));

    if (!handleUnAssignOrgDeviceModal) {
      dispatch(handleClientSuccessfulPost(false));
    }
  };

  return (
    <Box>
      <CustomModal
        openModal={handleUnAssignOrgDeviceModal}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createClientPostApiFail && toggleClientButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={true}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleClientButtonEnableSpinnerValue}
        InputFieldsComponent={OrgDeviceUnAssignInputFields}
        modalHeader="un_assign_organization"
        modalButtonName="un_assign"
        RightEnabledIcon={CircleRightEnabledIcon}
        colorMode="#24B0FF"
        modalHeight="300px"
      />
    </Box>
  );
};
export default OrgInfoDeviceUnAssignModal;
