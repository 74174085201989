import React from 'react';
import { Flex, Box, useColorModeValue, Icon, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { AddIcon, RightChervonIcon } from 'assets/icons/icons';
import { useAppDispatch } from 'redux/hooks';
import { getOrganizationNameSearchValue, toggleCreateDevicePopUp } from 'redux/devices/Reducers/deviceSlice';
import { getDeviceStatus, getDeviceTypes } from 'redux/devices/Actions/deviceActions';
import { getDeviceCreateFromOrg } from 'redux/organiztionDevices/Reduces/orgDeviceSlice';

import { useLocation, useNavigate } from 'react-router-dom';

interface DeviceTitleProps {
  showAddBtn?: boolean;
}

export default function DeviceTitle({ showAddBtn = false }: DeviceTitleProps) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');

  const colors = customColor();
  const handleDeviceModalOpen = () => {
    dispatch(toggleCreateDevicePopUp(true));
    dispatch(getOrganizationNameSearchValue(''));
    dispatch(getDeviceStatus());
  };

  const handleNavigate = () => {
    navigate('/device-models');
  };
  return (
    <Flex alignItems="center" h="50px">
      <Box display="flex" ml="49px">
        {location.pathname.includes('device-models') && (
          <Breadcrumb spacing="8px">
            <BreadcrumbItem textDecoration="none">
              <BreadcrumbLink color="#24B0FF" position="absolute" left="18px" top="14px" onClick={handleNavigate}>
                <Box>
                  <Box mt={{ xs: '-18px', lg: '0px' }}>
                    <RightChervonIcon />
                  </Box>
                  <Box mt={{ xs: '-22px', lg: '-22px' }} fontSize="14px" ml="17px">
                    <FormattedMessage id="device_models.message" />
                  </Box>
                </Box>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
      </Box>
      <Box display="flex" ml="-36px" mt="-10px">
        <Box
          mr={{ xs: '3' }}
          fontSize={{ xs: '22px', xl: '24px' }}
          fontWeight="700"
          color={colors.textColor}
          data-testid="devices"
        >
          <FormattedMessage id="devices.message" />
        </Box>

        {showAddBtn && (
          <Box mt="7px">
            <Flex alignItems="center">
              <Icon
                data-testid="plus-icon"
                w="24px"
                h="24px"
                onClick={handleDeviceModalOpen}
                color={textColorMode}
                fill={fillColorMode}
                _hover={{
                  color: 'url("#BlueGradient")',
                  cursor: 'pointer',
                  stroke: 'white',
                  fill: 'url("#BlueGradient")',
                  '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                  '&:hover>svg>circle': { stroke: strokeColorMode },
                }}
              >
                <AddIcon stroke={primarblackAndWhiteColorMode} />
              </Icon>
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
}
