import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import React, { useEffect } from 'react';
import { RootState } from 'redux/Store/store';

import Search from 'components/customSearch/Search';
import {
  clearSearcText,
  closeSearchInMobile,
  expandSearchInMobile,

  getSearchText,
} from 'redux/orgDevicesActivity/Reducers/orgDevicesActivitySlice';
import CustomSelect from 'components/customSelect/CustomSelect';
import { getDeviceActivityFilterValue } from 'redux/orgDevicesActivity/Reducers/orgDevicesActivitySlice';

interface FilterProps {
  isFiltersApplied: boolean;
}

const OrgDevicesActivityFilter = ({ isFiltersApplied }: FilterProps) => {
  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const data = useAppSelector((state: RootState) => state.organizationDevicesActivitySlice);

  const dispatch = useAppDispatch();
  const options = [
    {
      id: 0,
      name: 'By Newest',
    },
    {
      id: 1,
      name: 'By Oldest',
    },
  ];

  const handleSearchClose = () => {
    dispatch(closeSearchInMobile());
  };
  const handleSearchClear = () => {
    dispatch(clearSearcText());
  };
  const handleSearchExpand = () => {
    if (isMobileDevice) {
      dispatch(expandSearchInMobile());
    }
  };
  const handleSearchValue = (value: string) => {
    dispatch(getSearchText(value));
  };
  useEffect(() => {
    dispatch(closeSearchInMobile());
  }, [isMobileDevice]);

  const handleChange = (value: any) => {
    dispatch(getDeviceActivityFilterValue(value));
  };

  return (
    <Box>
      {' '}
      {((data.organizationDevicesActivityList && data.organizationDevicesActivityList.length > 0) ||
        isFiltersApplied) && (
        <Box
          data-testid="filter"
          display="flex"
          justifyContent={{ xs: 'space-between', lg: 'none' }}
          gap="26px"
          alignItems="center"
        >
          {' '}
          <Search
            data={data}
            handleClose={handleSearchClose}
            handleClear={handleSearchClear}
            handleExpandClick={handleSearchExpand}
            handleSearch={handleSearchValue}
          />{' '}
          {!data.isSearchExpand && (
            <Box display="flex" alignItems="center" id="status-types-container" minW={{ xs: '110px', md: '150px' }}>
              <CustomSelect
                handleSelectChange={handleChange}
                options={options}
                selectedValue={data.selectedOrderValue}
              />
            </Box>
          )}{' '}
        </Box>
      )}{' '}
    </Box>
  );
};
export default OrgDevicesActivityFilter;
