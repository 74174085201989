import { Box, Button, ListItem, Text, UnorderedList, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchUserRoleValue } from 'redux/user/Reducers/userSlice';
import { UserRolePermission } from 'types/user-types';

const UserRolePermissions = () => {
  const dispatch = useAppDispatch();
  const [toggle, setToggle] = useState<boolean>(false);
  const roles = useAppSelector((state) => state.user.roles);
  const selectedRole = useAppSelector((state) => state.user.userRoleValue);
  const userRole = useAppSelector((state) => state.user.singlePulseUserData.role);

  useEffect(() => {
    dispatch(fetchUserRoleValue(userRole));
  }, []);
  return (
    <Box>
      {selectedRole && (
        <Box ml="-13px" mb="10px" data-testid="container">
          <Button
            onClick={() => setToggle(!toggle)}
            backgroundColor="transparent"
            textColor="#24B0FF"
            data-testid="button"
            fontSize="14px"
            _hover={{ backgroundColor: 'transparent' }}
            cursor="pointer"
          >
            {toggle ? (
              <Text data-testid="toggle1">
                <FormattedMessage id="hide_permissions.message" />
              </Text>
            ) : (
              <Text data-testid="toggle2">
                <FormattedMessage id="see_permissions.message" />
              </Text>
            )}
          </Button>
          <Box data-testid="cont">
            {toggle ? (
              <Box ml="17px" fontSize="12px" fontWeight="500">
                <Wrap spacingY="20px">
                  {roles
                    .filter((role: UserRolePermission) => role.role === selectedRole)[0]
                    ?.permissions.map((permission: string) => (
                      <WrapItem width="305px" key={permission}>
                        <UnorderedList>
                          <ListItem fontSize="13px" fontWeight="500" color="rgba(51, 51, 51, 0.6)">
                            <FormattedMessage id={`user_role_permissions.${permission}.message`} />
                          </ListItem>
                        </UnorderedList>
                      </WrapItem>
                    ))}
                </Wrap>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserRolePermissions;
