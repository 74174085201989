import { keyframes, usePrefersReducedMotion, Image, Box, useColorModeValue, Flex } from '@chakra-ui/react';
import React from 'react';
import { EWIconDark, EWIconLight } from '../../assets/icons';
export const UniversalEWLoader = () => {
  const bg = useColorModeValue('brandWhite.50', 'brandGrey.300');
  const icon = useColorModeValue(EWIconDark, EWIconLight);
  const spin = keyframes`
    0% {
      transform: scale(0.75);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.75);
    }
  `;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${spin} infinite 4s ease-in-out`;
  return (
    <Box
      display="flex"
      width="100vw"
      backgroundColor={bg}
      overflowX="hidden"
      data-testid="login-test"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Flex w="82px" h="57px">
        <Image animation={animation} transformOrigin="center" src={icon} />
      </Flex>
    </Box>
  );
};
