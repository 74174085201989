import { SimpleGrid, useColorModeValue, Box, Text, Image, Tooltip } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import exoLogo from '../../../../../../assets/images/exo-pulse-logo.svg';

import React from 'react';
import { customColor } from 'assets/styles/colors';
import { Link, useNavigate } from 'react-router-dom';
import addEllipsisToEmail from 'utils/addEllipsisToEmail';
import addEllipsisToOrgName from 'utils/addEllipsisToOrgName';
import { getOrganizationLicenses } from 'redux/organiztionLicenes/Actions/orgLicenseActions';
import { userAccessConst } from 'utils/constants/userAccessConst';

function OrganizationGridView() {
  const navigate = useNavigate();
  const colors = customColor();
  const dispatch = useAppDispatch();
  const organizationsData = useAppSelector((state: RootState) => state.organization.organizationsList);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);

  const getEmailWithTooltip = (email: string) => {
    if (email) {
      const newEmail = addEllipsisToEmail(email);
      const isEmailWithEllipsis = newEmail.match(/\.{3}/g);
      if (isEmailWithEllipsis !== null && isEmailWithEllipsis.length >= 1) {
        return (
          <Tooltip label={email} data-testid="tooltip-email">
            {newEmail}
          </Tooltip>
        );
      } else {
        return newEmail;
      }
    }

    return email;
  };

  const getOrgNameWithTooltip = (orgName: string) => {
    if (orgName) {
      const newOrgName = addEllipsisToOrgName(orgName);
      const isOrgNameWithEllipsis = newOrgName.match(/\.{3}/g);
      if (isOrgNameWithEllipsis !== null && isOrgNameWithEllipsis.length >= 1) {
        return (
          <Tooltip label={orgName} textTransform="capitalize" data-testid="tooltip-email">
            {newOrgName}
          </Tooltip>
        );
      } else {
        return newOrgName;
      }
    }
    return orgName;
  };

  const handleNaviage = (id: number) => {
    if (userPermissions.organizations?.includes(userAccessConst.organization.viewOrg)) {
      navigate(`/organizations/${id}/info`);
    }
  };

  return (
    <SimpleGrid
      columns={{ xs: 1, md: 3, lg: 4 }}
      // spacingY={{ xs: '0px', md: '10px' }}
      data-testid="grid-view-container"
      spacingX={{ md: '10%', xl: '12%' }}
      pl={{ md: '12px' }}
    >
      {organizationsData.map(({ id, country_code, org_name, org_owner_email, org_id }) => (
        <Box key={id}>
          <Box
            w={{ xs: '100%', md: '153px', xl: '190px' }}
            h={{ xs: '120px', md: '193px', xl: '205px' }}
            onClick={() => handleNaviage(org_id)}
            borderBottom={{ xs: '0.5px solid grey', md: 'none' }}
            bg={useColorModeValue('white', '#24BOFF)')}
            _hover={{
              boxShadow: {
                xs: 'none',
                xl: theme === 'light' ? `0px 4px 17px rgba(0, 0, 0, 0.08)` : `0px 4px 17px rgba(0, 0, 0, 0.08)`,
              },
              borderRadius: '20px',
              cursor: 'pointer',
            }}
            rounded={{ xs: 'none', xl: 'lg' }}
            key={id}
            my={{ md: '10px' }}
            textAlign={{ xs: 'start', md: 'center' }}
            display="flex"
            data-testid="organization-card"
            flexDirection={{ xs: 'row', md: 'column' }}
            justifyContent={{ xs: 'flex-start', md: 'flex-start' }}
            alignItems="center"
            borderBottomColor={{ xs: colors.inputBorderColor, md: 'none' }}
          >
            <Image src={exoLogo} w="60px" h="60px" data-testid="organization-card-image" mt={{ md: '40px' }} />
            <Box ml={{ xs: '30px', md: '0px' }}>
              <Text fontSize="16px" color={colors.textColor} py="0" my="0" data-testid="organization-card-name">
                {getOrgNameWithTooltip(org_name)}
              </Text>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', md: 'column' }}
                alignItems={{ xs: 'center' }}
                data-testid="organization-card-country"
                fontSize="14px"
              >
                <Text mb={4} py="0" color={colors.textColor} fontSize="14px" opacity={{ xs: '0.5', md: '1' }} my="0">
                  {country_code?.country_name}
                </Text>
                <Text
                  fontSize="12px"
                  ml={{ xs: '10px', md: '0px' }}
                  opacity={{ xs: '0.5', md: '1' }}
                  data-testid="organization-card-email"
                  mb={4}
                  color="light.1400"
                  py="0"
                  my="0"
                  noOfLines={1}
                >
                  {getEmailWithTooltip(org_owner_email)}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </SimpleGrid>
  );
}

export default OrganizationGridView;
