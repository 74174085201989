import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Wrap,
  Heading,
  Box,
  Text,
  WrapItem,
  Flex,
  AbsoluteCenter,
  CircularProgress,
  Circle,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import moment from 'moment';
import { InputLabel } from 'components/InputField';
import StatusInfo from 'components/status/StatusInfo';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { LicenseTier } from 'types/LicenseTier';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchLicenseAddon } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import StatusBadge from 'components/statusBadge/StatusBadge';

const AddOnInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addonId } = useParams();
  const isLoading = useAppSelector((state) => state.softwareBundles.isAddonLoading);
  const addonInfo = useAppSelector((state) => state.softwareBundles.addonData);
  useEffect(() => {
    dispatch(fetchLicenseAddon(addonId));
  }, []);

  const navigateToAddons = () => {
    navigate('/licenses?tab=add-ons');
  };

  return (
    <Box>
      <Box display="flex" h="100px" justifyContent="space-between" borderBottom="1px solid #3333331A">
        <Box data-testid="addon-info-breadcumb" pl="15px">
          <Breadcrumb spacing="8px" pt="15px" separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem minH="20px">
              <BreadcrumbLink
                color="#24B0FF"
                position="absolute"
                onClick={navigateToAddons}
                display="flex"
                fontSize="14px"
                alignItems="center"
              >
                <RightChervonIcon />
                <Text ml="5px">Add-ons</Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          {!isLoading && (
            <Flex gap="20px" alignItems={'center'} pl="15px" pt="5px">
              <Heading
                fontSize="36px"
                w="auto"
                fontWeight="400"
                fontFamily="Helvetica"
                style={{ paddingLeft: '0px' }}
                textTransform="capitalize"
              >
                {addonInfo?.name}
              </Heading>
              <StatusBadge status={addonInfo?.status?.status} />
              <Box>
                <InputLabel value="-" name="catalogue" />
                <Text fontWeight="700" fontSize="13px" fontFamily="helvetica" mt="5px">
                  {addonInfo?.catalogNumber ? addonInfo?.catalogNumber : '-'}
                </Text>
              </Box>
            </Flex>
          )}
        </Box>
      </Box>
      {!isLoading ? (
        <Box pl="30px" pb="20px" height="calc(100vh - 180px)" overflowY="auto">
          <Box mt="20px">
            <Text fontWeight="700" fontSize="24px">
              Information
            </Text>
            <Box mt="25px">
              <Wrap display="flex" spacing="40px" minH="60px">
                <WrapItem>
                  <Box minH="60px">
                    <InputLabel value="-" name="name" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305} textTransform="capitalize">
                      {addonInfo?.name}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box>
                    <InputLabel value="-" name="catalogue" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305}>
                      {addonInfo?.catalogNumber}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px" minH="60px">
                <WrapItem>
                  <Box minH="60px" minWidth={305}>
                    <InputLabel value="-" name="status" />
                    <Box display="flex" alignItems="center" mt="8px" fontSize="13px">
                      <StatusInfo
                        indicatorSize="6px"
                        indicatorColor={addonInfo?.status?.colorCode}
                        label={addonInfo?.status?.status}
                      />
                    </Box>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box>
                    <InputLabel value="-" name="availability_date" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305}>
                      {addonInfo?.availabilityDate}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px" minH="60px">
                <WrapItem>
                  <Box minWidth={305}>
                    <InputLabel value="-" name="supported_tiers" />
                    <Flex marginTop={2}>
                      {addonInfo?.supportedTiers.length
                        ? addonInfo?.supportedTiers?.map((tiers: LicenseTier, index: number) => (
                            <Text fontWeight="400" fontSize="13px" key={index}>
                              {(index ? ', ' : '') + tiers.tierName}
                            </Text>
                          ))
                        : '-'}
                    </Flex>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box>
                    <InputLabel value="-" name="expiration_grace_period" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305}>
                      {addonInfo?.gracePeriodDays}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px" minH="60px">
                <WrapItem>
                  <Box>
                    <InputLabel value="-" name="notes" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305}>
                      {addonInfo?.description || '-'}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <AbsoluteCenter p="4" axis="both">
            <CircularProgress isIndeterminate size="50px" />
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
};

export default AddOnInfo;
