/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useRef } from 'react';
import { RootState } from '../../../../../../../redux/Store/store';
import OrgUsersMainContent from './OrgUsersMainContent';
import OrgUserFilters from './OrgUserFilters';
import { getOrganisationInfoUsersData, getOrgUsersStatus, getUserAnaltics } from 'redux/orgUser/Actions/orguserActions';
import OrgUserTitle from './OrgUserTitle';

import { getUserPageNumber } from 'redux/orgUser/Reducer/orgUserSlice';
import { useParams } from 'react-router-dom';

const OrgUsers = () => {
  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const usersData = useAppSelector((state: RootState) => state.orgUser);

  const dispatch = useAppDispatch();
  const initialRender = useRef({
    primary: true,
    secondary: true,
  });
  const { orgId } = useParams();

  const getUsersData = () => {
    const payload: any = {
      search: usersData.searchText,
      page: usersData.selectedPage,
      status: usersData.filterData.status_id?.filter((itr: any) => itr !== 'All'),
    };
    const queryString = Object.entries(payload)
      .map(([key, value]: any) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');

    dispatch(getOrganisationInfoUsersData(orgId, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getUsersData();
  }, [usersData.selectedPage]);

  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getUserPageNumber(1));
    getUsersData();
  }, [usersData.searchText, usersData.filterData]);

  useEffect(() => {
    dispatch(getOrgUsersStatus());
    dispatch(getUserAnaltics(orgId));
    return () => {
      dispatch(getUserPageNumber(1));
    };
  }, []);

  const filters = usersData.filterData;
  const search = usersData.searchText;
  const isFiltersApplied = !!((filters && filters.status_id && filters.status_id.length) || search);
  return (
    <>
      <Box h={`calc(100vh - 170px)`} data-testid="org-users" w="100%">
        {singleOrganizationData ? (
          <Box display="block" h={`calc(100vh - 170px)`} pt="20px">
            <Box
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row' }}
              justifyContent={{ xs: 'none', lg: 'space-between' }}
              transition="0.5s ease"
              h="60px"
            >
              <OrgUserTitle />
              <OrgUserFilters isFiltersApplied={isFiltersApplied} />
            </Box>
            <OrgUsersMainContent isFiltersApplied={isFiltersApplied} />
          </Box>
        ) : (
          ''
        )}
      </Box>
    </>
  );
};
export default OrgUsers;
