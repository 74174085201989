import React, { useState } from 'react';
import {
  WrapItem,
  Box,
  Text,
  Wrap,
  Input,
  Icon,
  useColorModeValue,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import { AddIcon, SubtractIcon } from 'assets/icons/icons';

import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import {
  getOrgLicenseSelectedContractDuration,
  getOrgLicenseUnitsValue,
  handleOrgLicenseDurationValidation,
  handleOrgLicensesUnitsValidation,
} from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';
import { resetCreateSettingsPopUpValues } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { specialNegativeValueValidation } from 'utils/validations/specialCharacterValidation';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { ExoPulseConnectUnlimited } from 'utils/constants/Constants';

const OrgLicenseUnitsTrailInputFields = () => {
  const colors = customColor();
  const intl = useIntl();
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const dispatch = useAppDispatch();
  const licenseunits = useAppSelector((state) => state.orgLicense.orgLicenseUnitsValue);
  const orgLicensesUnitsValidation = useAppSelector((state) => state.orgLicense.orgLicensesUnitsValidation);
  const licenseContractDurationArray = useAppSelector((state) => state.orgLicense.licenseContractDurationPeriodData);
  const [selectedContractDuration, setsSlectedContractDuration] = useState<string>('');
  const orgLicensesDurationValidation = useAppSelector((state) => state.orgLicense.orgLicensesDurationValidation);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const orgLicenseBundleName = useAppSelector((state: any) => state.orgLicense.orgLicenseBundleName);

  const handleLicenseUnits = (value: string) => {
    dispatch(getOrgLicenseUnitsValue(value));
    if (value.length > 0) {
      dispatch(handleOrgLicensesUnitsValidation(false));
    }
    if (postApiFailureMessage) {
      dispatch(handlePostApiFailureMessage(false));
      dispatch(resetCreateSettingsPopUpValues());
    }
  };
  const handleLicenseChange = (isAdd: boolean) => {
    const updatedUnits = isAdd ? Number(licenseunits) + 1 : Number(licenseunits) - 1;
    if (updatedUnits > 0) {
      handleLicenseUnits(updatedUnits.toString());
    }
  };
  const handleLicenseUnitsOnBlur = () => {
    dispatch(handleOrgLicensesUnitsValidation(!Number(licenseunits)));
  };
  const handleLincenseContractDurationChange = (e: any) => {
    const value = e.target.name;
    setsSlectedContractDuration(value);
    dispatch(getOrgLicenseSelectedContractDuration(value));
    if (value > 0) {
      dispatch(handleOrgLicenseDurationValidation(false));
    }
    if (postApiFailureMessage) {
      dispatch(handlePostApiFailureMessage(false));
      dispatch(resetCreateSettingsPopUpValues());
    }
  };
  const handleLicenseDuration = () => {
    if (selectedContractDuration.length == 0) {
      dispatch(handleOrgLicenseDurationValidation(true));
    } else {
      dispatch(handleOrgLicenseDurationValidation(false));
    }
  };

  return (
    <Box ml="5px">
      <Wrap spacingX={30}>
        <WrapItem>
          <Box>
            <InputLabel value={selectedContractDuration} name="contract_duration" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    fontSize="14px"
                    data-testid="license-info"
                    onBlur={handleLicenseDuration}
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {selectedContractDuration ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {selectedContractDuration}
                        {
                          <FormattedMessage
                            id="year.message"
                            values={{ text: Number(selectedContractDuration) == 1 ? '' : 's' }}
                          />
                        }
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="contract_duration.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <Box fontSize="12px" color="#FB5654">
                    {isOpen ? (
                      ''
                    ) : (
                      <>{orgLicensesDurationValidation ? <FormattedMessage id="field_validation.message" /> : ''}</>
                    )}
                  </Box>
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {licenseContractDurationArray &&
                      licenseContractDurationArray.map((contractDuration: any) => (
                        <MenuItem
                          id={contractDuration.contract_duration_years}
                          key={contractDuration.contract_duration_years}
                          data-testid="menu-item"
                          value={contractDuration.contract_duration_years}
                          name={contractDuration.contract_duration_years}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={handleLincenseContractDurationChange}
                        >
                          {contractDuration.contract_duration_years}
                          {
                            <FormattedMessage
                              id="year.message"
                              values={{ text: Number(contractDuration.contract_duration_years) == 1 ? '' : 's' }}
                            />
                          }
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <WrapItem>
          {orgLicenseBundleName.name == ExoPulseConnectUnlimited ? (
            <Box display="flex" flexDirection="column" minH="90px" />
          ) : (
            <Box display="flex" flexDirection="column" minH="90px">
              <InputLabel value={licenseunits} name={'purchased_quantity'} />
              <Box position="relative">
                <Input
                  color={colors.subTextColor}
                  name="licenseunits"
                  width="305px"
                  onChange={(e: any) => handleLicenseUnits(e.target.value)}
                  placeholder={intl.formatMessage({ id: 'purchased_quantity.message' })}
                  onKeyPress={specialNegativeValueValidation}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => e.preventDefault()}
                  borderColor="#E0E0E0"
                  focusBorderColor={colors.primaryColor}
                  borderBottomColor={colors.inputBorderColor}
                  borderWidth="0 0 1px 0"
                  type="number"
                  value={licenseunits}
                  onBlur={handleLicenseUnitsOnBlur}
                  variant="flushed"
                  required
                  fontSize="16px"
                  inputMode="numeric"
                />
                <Box width="55px" position="absolute" top="5px" right="0">
                  <Icon
                    data-testid="subtract-icon"
                    w="22px"
                    h="22px"
                    mr="5px"
                    onClick={() => handleLicenseChange(false)}
                    color={useColorModeValue('white', 'black')}
                    fill={useColorModeValue('white', 'transparent')}
                    _hover={{
                      color: 'url("#BlueGradient")',
                      cursor: 'pointer',
                      stroke: 'white',
                      fill: 'url("#BlueGradient")',
                      '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                      '&:hover>svg>circle': { stroke: useColorModeValue('white', 'none') },
                    }}
                  >
                    <SubtractIcon stroke={primarblackAndWhiteColorMode} />
                  </Icon>
                  <Icon
                    data-testid="plus-icon"
                    w="22px"
                    h="22px"
                    onClick={() => handleLicenseChange(true)}
                    color={useColorModeValue('white', 'black')}
                    fill={useColorModeValue('white', 'transparent')}
                    _hover={{
                      color: 'url("#BlueGradient")',
                      cursor: 'pointer',
                      stroke: 'white',
                      fill: 'url("#BlueGradient")',
                      '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                      '&:hover>svg>circle': { stroke: useColorModeValue('white', 'none') },
                    }}
                  >
                    <AddIcon stroke={primarblackAndWhiteColorMode} />
                  </Icon>
                </Box>
                <Text fontSize="12px" color="#FB5654" mt="3px">
                  {orgLicensesUnitsValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                </Text>
              </Box>
            </Box>
          )}
        </WrapItem>
      </Wrap>
      {postApiFailureMessage ? <BannerMessage message={postApiFailureMessage} defaultMessage={''} /> : ''}
    </Box>
  );
};

export default OrgLicenseUnitsTrailInputFields;
