import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiUtil from '../../api/index';
import { ExamExport, ExportExamRequest, GetExportExamDetailsRequest } from './examExport.types';

const exportExam = createAsyncThunk(
  'exam-workflow/examExports',
  async (exportExamRequestBody: ExportExamRequest, { rejectWithValue }) => {
    const url = `/v1/exam-workflow/exam-exports/`;
    try {
      const response = await ApiUtil.post<ExamExport>(url, {
        ...exportExamRequestBody,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getExportExamDetails = createAsyncThunk(
  'exam-workflow/geExamExportDetails',
  async (exportExamDetail: GetExportExamDetailsRequest) => {
    const url = `/v1/exam-workflow/exam-exports/${exportExamDetail.id}`;

    const response = await ApiUtil.get<ExamExport>(url);
    return response.data;
  },
);

export const examExportThunks = {
  exportExam,
  getExportExamDetails,
};
