import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface CustomStatusBadgeProps {
  status: string | undefined;
}

const getStatusColor = (status: string | undefined) => {
  switch (status) {
    case 'Active':
      return { textColor: 'rgba(31, 208, 2, 1)', bgColor: 'rgba(31, 208, 2, 0.08)' };
    case 'Deactivated':
      return { textColor: 'rgba(251, 86, 84, 1)', bgColor: 'rgba(251, 86, 84, 0.08)' };
    case 'Return Initiated':
      return { textColor: 'rgba(36, 150, 255, 1)', bgColor: 'rgba(36, 150, 255, 0.08)' };
    case 'Provisioned':
      return { textColor: 'rgba(255, 152, 36, 1)', bgColor: 'rgba(255, 152, 36, 0.08)' };
    case 'Deployed':
      return { textColor: 'rgba(145, 36, 255, 1)', bgColor: 'rgba(145, 36, 255, 0.08)' };
    case 'Shipped':
      return { textColor: 'rgba(145, 36, 255, 1)', bgColor: 'rgba(145, 36, 255, 0.08)' };
    case 'Registered':
      return { textColor: 'rgba(145, 36, 255, 1)', bgColor: 'rgba(145, 36, 255, 0.08)' };

    case 'Purchase Ready':
      return { textColor: 'rgba(255, 152, 36, 1)', bgColor: 'rgba(255, 152, 36, 0.08)' };
    case 'License Paused':
      return { textColor: 'rgba(255, 152, 36, 1)', bgColor: 'rgba(255, 152, 36, 0.08)' };
    case 'Ready to Ship':
      return { textColor: 'rgba(145, 36, 255, 1)', bgColor: 'rgba(145, 36, 255, 0.08)' };

    case 'Not Provisioned':
      return { textColor: 'rgba(255, 152, 36, 1)', bgColor: 'rgba(255, 152, 36, 0.08)' };
    case 'Delivered':
      return { textColor: 'rgba(145, 36, 255, 1)', bgColor: 'rgba(145, 36, 255, 0.08)' };
    case 'Deprecated':
      return { textColor: 'rgba(251, 86, 84, 1)', bgColor: 'rgba(251, 86, 84, 0.08)' };
    case 'Inactive':
      return { textColor: 'rgba(251, 86, 84, 1)', bgColor: 'rgba(251, 86, 84, 0.08)' };
    default:
      return { textColor: '#000000', bgColor: '#FFFFFF' };
  }
};
const StatusBadge = ({ status }: CustomStatusBadgeProps) => {
  const { textColor, bgColor } = getStatusColor(status);

  return (
    <Flex
      style={{
        border: status && `1px solid ${textColor}`,
        borderRadius: '50px',
        backgroundColor: `${bgColor}`,
        width: 'auto',
        height: '24px',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          fontSize: '14px',
          fontWeight: '400',
          color: `${textColor}`,
        }}
      >
        {status}
      </Text>
    </Flex>
  );
};

export default StatusBadge;
