import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import { Text, Wrap, WrapItem } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import { Account_Owner } from 'utils/enum';
import { handleOrgUserPassword, handleWelcomeEmailModal } from 'redux/orgUser/Reducer/orgUserSlice';
import ResetOrgUserPasswordModal from '../../orgUsers/resetOrgUserPassword/ResetOrgUserPasswordModal';
import { userAccessConst } from 'utils/constants/userAccessConst';
import ResendWelcomeEmailModal from '../../orgUsers/resendWelcomeEmail/WelcomeEmailModal';
import { FormattedMessage } from 'react-intl';

interface UserRole {
  role_name: string;
}
const SingleUserNonEditFields = () => {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const RegisteredStatus = 'Registered';
  const singleUser = useAppSelector((state: RootState) => state.orgUser.SingleUsersGetData);
  const SingleUsersGetData = useAppSelector((state: RootState) => state.orgUser.SingleUsersGetData);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const orgStatus = useAppSelector((state) => state.organization.SingleOrganizationsGetData?.status_name);
  const handleresetPassword = () => {
    dispatch(handleOrgUserPassword(true));
  };
  const handleResendEmail = () => {
    dispatch(handleWelcomeEmailModal(true));
  };

  return (
    <Box>
      {singleUser && (
        <Box>
          <Wrap align="center" justify="first_name" spacingX="110px">
            <WrapItem>
              <Box minH="60px">
                <InputLabel value="First Name" name="first_name" />
                <Text
                  fontSize="14px"
                  color={colors.subTextColor}
                  width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                  pt="10px"
                  className="truncatee"
                  textTransform="capitalize"
                >
                  {' '}
                  {singleUser.first_name}{' '}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="60px">
                <InputLabel value="Last Name" name="last_name" />
                <Text
                  fontSize="14px"
                  color={colors.subTextColor}
                  width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                  pt="10px"
                  className="truncatee"
                  textTransform="capitalize"
                >
                  {' '}
                  {singleUser.last_name}{' '}
                </Text>
              </Box>
            </WrapItem>
          </Wrap>{' '}
          <Wrap align="center" justify="first_name" spacingX="110px">
            <WrapItem>
              <Box minH="60px">
                <InputLabel value="Organization Name" name="org_name" />
                <Text
                  fontSize="14px"
                  color={colors.subTextColor}
                  width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                  pt="10px"
                  className="truncatee"
                  textTransform="capitalize"
                >
                  {' '}
                  {singleUser?.org?.org_name}{' '}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="60px">
                <InputLabel value="Organization Code" name="org_code" />
                <Text
                  fontSize="14px"
                  color={colors.subTextColor}
                  width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                  pt="10px"
                  className="truncatee"
                >
                  {' '}
                  {singleUser?.org?.org_code}{' '}
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
          <Wrap align="center" justify="first_name" spacingX="120px" minH="60px">
            <WrapItem>
              <Box minH="60px">
                <InputLabel value="phone" name="phone" />
                <Text
                  fontSize="14px"
                  color={colors.subTextColor}
                  width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                  pt="10px"
                >
                  {singleUser.phone
                    ? `${SingleUsersGetData?.phone_code?.country_phone_code}  ${singleUser?.phone}`
                    : '-'}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="60px">
                <InputLabel value="Email" name="email" />
                <Text
                  fontSize="14px"
                  color={colors.subTextColor}
                  width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                  pt="10px"
                  className="truncatee"
                >
                  {' '}
                  {singleUser.email}
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
          <Wrap align="center" justify="first_name" spacingX="120px" minH="60px">
            <WrapItem>
              <Box minH="60px">
                <InputLabel value="role" name="role" />
                <Box
                  fontSize="14px"
                  color={colors.subTextColor}
                  width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                  pt="10px"
                >
                  {SingleUsersGetData?.roles?.map((state: UserRole, id: number) => (
                    <Text key={id} py="3px">
                      {state?.role_name}
                    </Text>
                  ))}
                </Box>
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      )}

      {userPermissions.organizations?.includes(userAccessConst.organization.resetUserPassword) &&
        orgStatus != RegisteredStatus && (
          <Box w="757px" h="98px" mt="20px">
            <Box>
              <Text fontSize="18px" fontWeight="700">
                Additional Action
              </Text>
            </Box>
            <Box fontSize="12px" color={colors.tableHeadColor} mt="20px" fontWeight="700">
              {' '}
              Reset Password
            </Box>
            <Box display="flex" mt="10px" justifyContent="space-between">
              <Box width="80%">
                <Text fontSize="14px" color={colors.copyRightColor}>
                  User will receive a link to their mail to reset their password
                </Text>
              </Box>
              <Box>
                <Button
                  variant="outline"
                  borderRadius="15px"
                  height="27px"
                  fontWeight="700"
                  fontSize="12px"
                  width="110px"
                  mt="-25px"
                  // borderColor={b == 'Pause' ? '#F19D43' : '#FB5654' || b == 'Deactivate' ? '#24B0FF' : '#FB5654'}
                  _hover={{
                    background: '#24B0FF',
                    color: 'white',
                  }}
                  color={'#24B0FF'}
                  borderColor="#24B0FF"
                  onClick={handleresetPassword}
                >
                  Reset Password
                </Button>
                <ResetOrgUserPasswordModal />
              </Box>
            </Box>
            <Box fontSize="12px" color={colors.tableHeadColor} mt="20px" fontWeight="700">
              <FormattedMessage id="resend_welcome_email.message" />
            </Box>
            <Box display="flex" mt="10px" justifyContent="space-between">
              <Box width="80%">
                <Text fontSize="14px" color={colors.copyRightColor}>
                  <FormattedMessage id="user_receives_email.message" />
                </Text>
              </Box>
              <Box>
                <Button
                  variant="outline"
                  borderRadius="15px"
                  height="27px"
                  fontWeight="700"
                  fontSize="12px"
                  width="100%"
                  mt="-25px"
                  _hover={{
                    background: colors.lightModeBlue,
                    color: 'white',
                  }}
                  color={colors.lightModeBlue}
                  borderColor="#24B0FF"
                  onClick={handleResendEmail}
                >
                  <FormattedMessage id="resend_welcome_email.message" />
                </Button>
                <ResendWelcomeEmailModal />
              </Box>
            </Box>
          </Box>
        )}
    </Box>
  );
};
export default SingleUserNonEditFields;
