import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';

const SelectedCustomerInfo = ({ customerInfo }: any) => {
  return (
    customerInfo && (
      <Box>
        <Box py="10px">
          <Text fontWeight="700" fontSize="16px" minWidth={305}>
            Customer Information
          </Text>
        </Box>
        <Box>
          <Wrap display="flex" spacingX="30px" minH="50px">
            <WrapItem>
              <Box>
                <InputLabel value="-" name="customer_name" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                  {customerInfo?.customer_name}
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
          <Wrap display="flex" spacingX="30px" minH="50px">
            <WrapItem>
              <Box>
                <InputLabel value="-" name="email" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                  {customerInfo?.customer_admin_email}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box>
                <InputLabel value="-" name="phone" />
                {customerInfo?.customer_admin_phone ? (
                  <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                    {customerInfo?.customer_admin_phone_code?.country_phone_code +
                      ' ' +
                      customerInfo?.customer_admin_phone}
                  </Text>
                ) : (
                  '-'
                )}
              </Box>
            </WrapItem>
          </Wrap>
          <Wrap display="flex" spacingX="30px" minH="50px">
            <WrapItem>
              <Box>
                <InputLabel value="-" name="customer_admin_fname" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                  {customerInfo?.customer_admin_first_name}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box>
                <InputLabel value="-" name="customer_admin_lname" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                  {customerInfo?.customer_admin_last_name}
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      </Box>
    )
  );
};
export default SelectedCustomerInfo;
