/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';
import {
  OrganizationTypes,
  TierObject,
  OrgDevice,
  OrganizationDevicesActivityApiResponse,
  OrgDeviceActivity,
} from '../../../types/organization-types';
import { PaginationTypes } from 'types/pagination-types';
type InitialState = {
  searchText: string;
  organizationSingleDevicesActivityList: OrgDeviceActivity[];
  statusLoading: boolean;
  statusError: boolean;
  organizationSingleDevicesActivityListLoading: boolean;
  organizationSingleDeviceActivityListError: boolean;
  createOrganizationSingleDeviceActivityApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  organizationSingleDeviceActivityApiData: OrganizationDevicesActivityApiResponse | null;
  orgSingleDeviceActivitynumberOfPages: number;
  orgSingleDeviceActivitypaginationData: PaginationTypes | null;
  SingleOrganizationDeviceActivityDataLoading: boolean;
  SingleOrganizationDeviceActivityDataError: boolean;
  SingleOrganizationsDeviceActivityGetData: OrgDevice[];
  SingleOrganizationsDeviceActivityGetDataLoading: boolean;
  SingleOrganizationsDeviceActivityGetDataError: boolean;
  singleOrganizationDeviceActivityID: string | number;
  isSearchExpand: boolean;
  deviceCreateFromOrg: boolean;
  selectedOrderValue: number;
};
export const initialState: InitialState = {
  searchText: '',
  organizationSingleDevicesActivityList: [],
  statusLoading: false,
  statusError: false,
  organizationSingleDevicesActivityListLoading: false,
  organizationSingleDeviceActivityListError: false,
  selectedPage: 1,
  createOrganizationSingleDeviceActivityApiFail: '',
  organizationSingleDeviceActivityApiData: null,
  orgSingleDeviceActivitynumberOfPages: 1,
  filterData: { status_id: [] },
  orgSingleDeviceActivitypaginationData: null,
  SingleOrganizationDeviceActivityDataLoading: false,
  SingleOrganizationDeviceActivityDataError: false,
  SingleOrganizationsDeviceActivityGetData: [],
  SingleOrganizationsDeviceActivityGetDataLoading: false,
  SingleOrganizationsDeviceActivityGetDataError: false,
  singleOrganizationDeviceActivityID: '',
  isSearchExpand: false,
  deviceCreateFromOrg: false,
  selectedOrderValue: 0,
};
const singleOrganizationDeviceActivitySlice = createSlice({
  name: 'editorganizationdeviceactivity',
  initialState,
  reducers: {
    getDeviceCreateFromOrg: (state, { payload }) => {
      state.deviceCreateFromOrg = payload;
    },
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getSingleDeviceActivityFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    getSingleDeviceActivityPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchCreateOrganizationSingleDeviceActivityApiFail: (state, { payload }) => {
      state.createOrganizationSingleDeviceActivityApiFail = payload;
    },
    resetDeviceActivity: (state) => {
      state.organizationSingleDeviceActivityListError = false;
      state.organizationSingleDevicesActivityList = [];
      state.orgSingleDeviceActivitypaginationData = null;
    },
    fetchOrganizationSingleDeviceActivityLoading: (state) => {
      state.organizationSingleDevicesActivityListLoading = true;
      state.organizationSingleDeviceActivityListError = false;
    },
    fetchOrganizationSingleDeviceActivityFail: (state) => {
      state.organizationSingleDeviceActivityListError = true;
      state.organizationSingleDevicesActivityList = [];
      state.organizationSingleDevicesActivityListLoading = false;
    },
    fetchOrganizationSingleDeviceActivitySuccess: (state, { payload }) => {
      state.organizationSingleDevicesActivityList = payload.data;
      state.organizationSingleDeviceActivityApiData = payload;
      state.orgSingleDeviceActivitynumberOfPages = payload.pagination.num_pages;
      state.orgSingleDeviceActivitypaginationData = payload.pagination;
      state.organizationSingleDevicesActivityListLoading = false;
    },
    fetchSingleOrganizationDeviceActivityID: (state, { payload }) => {
      state.singleOrganizationDeviceActivityID = payload;
    },
    fetchSingleOrganizationDeviceActivityDataLoading: (state) => {
      state.SingleOrganizationsDeviceActivityGetDataLoading = true;
      state.SingleOrganizationsDeviceActivityGetDataError = false;
    },
    fetchSingleOrganizationDeviceActivityDataSuccess: (state, { payload }) => {
      state.SingleOrganizationsDeviceActivityGetData = payload;
      state.SingleOrganizationsDeviceActivityGetDataLoading = false;
      state.SingleOrganizationsDeviceActivityGetDataError = false;
    },
    fetchSingleOrganizationDeviceActivityDataFail: (state) => {
      state.SingleOrganizationsDeviceActivityGetDataError = true;
      state.SingleOrganizationsDeviceActivityGetDataLoading = false;
    },

    getSingleDeviceActivityFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
  },
});
export const {
  getSearchText,
  clearSearcText,
  expandSearchInMobile,
  closeSearchInMobile,
  getSingleDeviceActivityPageNumber,
  fetchOrganizationSingleDeviceActivityLoading,
  fetchOrganizationSingleDeviceActivityFail,
  fetchOrganizationSingleDeviceActivitySuccess,
  fetchCreateOrganizationSingleDeviceActivityApiFail,
  getSingleDeviceActivityFlterData,
  fetchSingleOrganizationDeviceActivityDataLoading,
  fetchSingleOrganizationDeviceActivityDataSuccess,
  fetchSingleOrganizationDeviceActivityDataFail,
  fetchSingleOrganizationDeviceActivityID,
  getDeviceCreateFromOrg,
  getSingleDeviceActivityFilterValue,
  resetDeviceActivity,
} = singleOrganizationDeviceActivitySlice.actions;
export default singleOrganizationDeviceActivitySlice.reducer;
