import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    height: '10px',
    width: '10px',
  },
  track: {
    width: '19px',
    height: '10px',
    bg: '#D9D9D9',
    _checked: {
      bg: 'light.100',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
