import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';

import { RootState } from 'redux/Store/store';
import React from 'react';
import DevicesLandingScreen from '../../deviceLanding/DeviceLandingScreen';
import DevicesData from '../../devicesData/DevicesData';

interface FilterProps {
  isFiltersApplied: boolean;
}
export default function DevicesMainContent({ isFiltersApplied }: FilterProps) {
  return (
    <Box h={{ xs: 'calc(100vh - 140px)', lg: 'calc(100vh - 140px)' }} position="relative" data-testid="container">
      <DevicesData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
