import { Box } from '@chakra-ui/react';
import React from 'react';
import UsersData from '../userData/UsersData';

export interface FilterProps {
  isFiltersApplied: boolean;
}
export default function UsersMainContent({ isFiltersApplied }: FilterProps) {
  return (
    <Box h={{ xs: 'calc(100vh - 140px)', lg: 'calc(100vh - 140px)' }} mt="20px" position="relative">
      <UsersData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
