import { useMemo } from 'react';
import {
  Text,
  Box,
  Button,
  Wrap,
  WrapItem,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  CheckboxGroup,
  Stack,
  Checkbox,
  Icon,
} from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { CalendarIcons } from 'assets/icons/icons';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';

import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from 'redux/hooks';
import { customColor } from 'assets/styles/colors';
import StatusInfo from 'components/status/StatusInfo';
import { TierObject } from 'types/organization-types';

interface AddonInfoFieldsProps {
  addonInfo: any;
}

const AddonInfoFields = ({ addonInfo }: AddonInfoFieldsProps) => {
  const intl = useIntl();
  const calendarDateToday = utils(intl.locale).getToday();
  const colors = customColor();
  const isOpen = useAppSelector((state) => state.softwareBundles.isAddAddonModalOpen);
  const licenseStatuses = useAppSelector((state: any) => state.softwareBundles.getSoftwareBundleStatusData);
  const licenseGracePeriods = useAppSelector((state) => state.softwareBundles.bundleGracePeriodData);
  const licenseTiers = useAppSelector((state) => state.organization.tiersData);

  const { values, errors, handleBlur, handleChange, touched, setFieldValue, setSubmitting } = addonInfo;

  function getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }
  const handleFieldBlur = (e: any) => {
    setSubmitting(false);
    handleBlur(e);
  };
  const handleDateChange = (e: any, onClose: () => void) => {
    setFieldValue('availabilityDate', e);
    handleFieldBlur('availabilityDate');
    onClose();
  };

  const handleLicenseTierSelect = (licenseTier: TierObject, e: any) => {
    const newValues = values.supportedTiers.filter((tier: TierObject) => tier.id == licenseTier.id).length
      ? values.supportedTiers.filter((tier: TierObject) => tier !== licenseTier).sort()
      : [...values.supportedTiers, licenseTier].sort();
    setFieldValue('supportedTiers', newValues);
    handleFieldBlur(e);
  };

  const renderStatuses = () => {
    return licenseStatuses.map((status: any) => (
      <MenuItem
        id={status.id}
        key={status.id}
        value={status.id}
        name={status.status}
        backgroundColor="transparent"
        _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
        onClick={(e) => {
          setFieldValue('status', status);
          handleFieldBlur(e);
        }}
      >
        <Box display="flex">
          <StatusInfo indicatorSize="6px" label={status.status} indicatorColor={status.color_code} />
        </Box>
      </MenuItem>
    ));
  };

  const renderMenuItems: JSX.Element[] = useMemo(renderStatuses, [licenseStatuses]);

  return (
    <>
      <Wrap spacingX="30px">
        <WrapItem>
          <Box minH="70px">
            <InputLabel value={values.name} name="name" />
            <Input
              color={colors.subTextColor}
              name="name"
              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              onChange={handleChange}
              value={values.name}
              placeholder={intl.formatMessage({ id: 'name.message' })}
              type="text"
              textTransform="capitalize"
              variant="flushed"
              maxLength={32}
              _placeholder={{ fontSize: '14px' }}
              fontSize="14px"
              paddingTop="10px"
              onBlur={handleFieldBlur}
              required
              data-testid="addon-name"
            />
            <Text fontSize="12px" color="#FB5654">
              {touched.name && errors.name}
            </Text>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box minH="90px">
            <InputLabel value={values.status.id} name="status" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    paddingTop={'5px'}
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    <Box display="flex" ml="-15px" alignItems="center" fontWeight="400" fontSize="14px">
                      <StatusInfo
                        indicatorSize="6px"
                        label={values.status.status}
                        indicatorColor={values.status.color_code}
                      />
                    </Box>
                  </MenuButton>
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {renderMenuItems}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box minH="80px">
            <InputLabel value={values.availabilityDate.day} name="availability_date" />
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                  >
                    {values.availabilityDate.day ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {getMonthName(values.availabilityDate.month)} {values.availabilityDate.day},{' '}
                        {values.availabilityDate.year}
                      </Text>
                    ) : (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color="#8c8c8c"
                        paddingTop="5px"
                      >
                        <FormattedMessage id="availability_date.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    backgroundColor="transparent"
                    border="none"
                    __css={{
                      '.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover':
                        {
                          color: colors.lightModeBlue,
                          background: 'none',
                        },
                      '.Calendar__monthArrowWrapper.-right': {
                        transform: 'none',
                      },
                      '.Calendar__monthArrowWrapper.-left': {
                        transform: 'rotate(180deg)',
                      },
                      '.Calendar__monthArrow': {
                        backgroundImage: "url('/assets/images/left-chevron.svg')",
                      },
                      '.Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                      '.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                    }}
                  >
                    <Calendar
                      value={values.availabilityDate.day ? values.availabilityDate : calendarDateToday}
                      data-testid="availabilityDate-calender"
                      onChange={(e) => handleDateChange(e, onClose)}
                      colorPrimary={colors.lightModeBlue}
                      calendarClassName="custom-calendar"
                      calendarTodayClassName="custom-today-day"
                      minimumDate={{
                        year: calendarDateToday.year,
                        month: calendarDateToday.month,
                        day: calendarDateToday.day,
                      }}
                      maximumDate={{
                        year: calendarDateToday.year + 1, // future 1 years
                        month: calendarDateToday.month,
                        day: calendarDateToday.day,
                      }}
                    />
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box minH="70px">
            <InputLabel value={values.catalogNumber} name="catalogue" />
            <Input
              color={colors.subTextColor}
              name="catalogNumber"
              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              onChange={handleChange}
              value={values.catalogNumber}
              placeholder={intl.formatMessage({ id: 'catalogue.message' })}
              type="text"
              textTransform="capitalize"
              variant="flushed"
              maxLength={32}
              _placeholder={{ fontSize: '14px' }}
              fontSize="14px"
              paddingTop="10px"
              onBlur={handleFieldBlur}
              required
              data-testid="addon-catalog-id"
            />
            <Text fontSize="12px" color="#FB5654">
              {touched.catalogNumber && errors.catalogNumber}
            </Text>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box minH="70px">
            <InputLabel value={values.supportedTiers.length ? '-' : ''} name="supported_tiers" />
            <Menu closeOnSelect={false}>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="expiration-grace-period"
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    <Box marginLeft="-15px">
                      {values.supportedTiers.length ? (
                        values.supportedTiers.map((licenseTier: TierObject, index: number) => {
                          return (
                            <Text key={index} float="left" fontSize="14px" fontWeight="400" color={colors.subTextColor}>
                              {(index ? ', ' : '') + licenseTier.tier_name}
                            </Text>
                          );
                        })
                      ) : (
                        <Text float="left" fontSize="14px" fontWeight="400" color="#8c8c8c">
                          <FormattedMessage id="supported_tiers.message" />
                        </Text>
                      )}
                    </Box>
                  </MenuButton>
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    <CheckboxGroup>
                      <Stack px="16px">
                        {licenseTiers.map((licenseTier: TierObject, index: number) => (
                          <Checkbox
                            name={licenseTier.tier_name}
                            key={index}
                            variant="themeNoBg"
                            data-testid="dropdown_option"
                            id={licenseTier.tier_code}
                            onChange={(e) => handleLicenseTierSelect(licenseTier, e)}
                            py="5px"
                            _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          >
                            {licenseTier.tier_name}
                          </Checkbox>
                        ))}
                      </Stack>
                    </CheckboxGroup>
                  </MenuList>
                </>
              )}
            </Menu>
            <Text fontSize="12px" color="#FB5654">
              {touched.supportedTiers && errors.supportedTiers}
            </Text>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box>
            <InputLabel value={'-'} name="expiration_grace_period" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="expiration-grace-period"
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {values.expirationGracePeriod ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {values.expirationGracePeriod}
                        {<FormattedMessage id="days.message" />}
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="expiration_grace_period.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {licenseGracePeriods &&
                      licenseGracePeriods.map((state: any) => (
                        <MenuItem
                          id={state.id}
                          key={state.grace_period_days}
                          data-testid="menu-item"
                          value={state.id}
                          name={state.grace_period_days}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={() => setFieldValue('expirationGracePeriod', state.grace_period_days)}
                        >
                          {state.grace_period_days}
                          {<FormattedMessage id="days.message" />}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>
      <Wrap>
        <WrapItem>
          <Box display="flex" flexDirection="column" minH="70px">
            <InputLabel value={values.notes} name={'notes_optional'} />
            <Input
              color={colors.subTextColor}
              name="notes"
              width="635px"
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              onChange={handleChange}
              value={values.notes}
              placeholder={intl.formatMessage({ id: 'notes_optional.message' })}
              type="text"
              variant="flushed"
              maxLength={75}
              _placeholder={{ fontSize: '14px' }}
              fontSize="14px"
              paddingTop="10px"
              onBlur={handleFieldBlur}
              required
              data-testid="addon-notes"
            />
            <Text fontSize="12px" color="grey" display="flex" float="right" justifyContent="flex-end">
              {Math.abs(values.notes.length - 75)}
            </Text>
          </Box>
          <Text fontSize="12px" color="#FB5654">
            {touched.notes && errors.notes}
          </Text>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default AddonInfoFields;
