import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Wrap,
  WrapItem,
  Menu,
  MenuButton,
  Button,
  Icon,
  MenuList,
  Input,
  Box,
  Text,
  MenuItem,
  CheckboxGroup,
  Checkbox,
  Stack,
  Switch,
} from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from 'redux/hooks';
import { Addon } from 'types/add-on';
import { SoftwareBundle } from 'types/SoftwareBundle';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';

const FeatureModalInputFields = (props: any) => {
  const colors = customColor();
  const intl = useIntl();
  const softwareBundles = useAppSelector((state: any) => state.softwareBundles.softwareBundles);
  const postFeatureFailMessage = useAppSelector((state) => state.softwareBundles.postFeatureFailMessage);
  const addonsData = useAppSelector((state: any) => state.softwareBundles.unlinkedAddonsData?.data);
  const featurePostFail = useAppSelector((state) => state.softwareBundles.isPostFeaturesFail);
  const [isDisabled, setIsDisabled] = useState(true);
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = props.formik;

  const handleBundleMenulist = (selectedBundle: SoftwareBundle) => {
    const selectedBundles = values.bundles.filter((bundle: SoftwareBundle) => bundle.id == selectedBundle.id).length
      ? values.bundles.filter((bundle: SoftwareBundle) => bundle.id !== selectedBundle.id)
      : [...values.bundles, selectedBundle];

    setFieldValue('bundles', selectedBundles);
  };

  const handleToggleButton = () => {
    setIsDisabled(!isDisabled);
    setFieldValue('isEnabled', !isDisabled);
  };

  return (
    <Box>
      <Wrap spacingX="30px" h="90px">
        <WrapItem>
          <Box>
            <InputLabel value={values?.name} name={'name'} />
            <Box display="flex" flexDirection="column" minH="70px">
              <CustomInputField
                defaultValue={''}
                name={'name'}
                isEditable={true}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
              />
              <Text fontSize="12px" color="#FB5654" mt="-40px">
                {touched.name && errors.name}
              </Text>
            </Box>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box>
            <InputLabel value={'available'} name={'available'} />
            <Box display="flex" flexDirection="column" mt="15px">
              <Switch isChecked={values.isEnabled} size="sm" onChange={() => handleToggleButton()} />
            </Box>
          </Box>
        </WrapItem>
      </Wrap>

      <Wrap spacingX="30px" h="80px">
        <WrapItem>
          <Box>
            <InputLabel value={values?.featureGroup} name={'feature_group'} />
            <Box display="flex" flexDirection="column">
              <CustomInputField
                defaultValue={''}
                isEditable={true}
                name={'feature_group'}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={(e: any) => setFieldValue('featureGroup', e.target?.value)}
                handleOnBlur={handleBlur('featureGroup')}
              />
              <Text fontSize="12px" color="#FB5654" mt="-40px">
                {touched.featureGroup && errors.featureGroup}
              </Text>
            </Box>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box>
            <InputLabel value={values?.featureCode} name={'feature_code'} />
            <Box display="flex" flexDirection="column">
              <Input
                variant="flushed"
                color={colors.subTextColor}
                borderColor="#E0E0E0"
                maxLength={50}
                fontSize="14px"
                value={values?.featureCode}
                _placeholder={{ fontSize: '14px' }}
                width={{ md: '305px' }}
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                borderWidth="0 0 0.5px 0"
                onKeyPress={specialCharacterValidation}
                onChange={(e: any) => setFieldValue('featureCode', e.target?.value)}
                onBlur={handleBlur('featureCode')}
                placeholder={intl.formatMessage({ id: 'feature_code.message' })}
              />
              <Text fontSize="12px" color="#FB5654">
                {touched.featureCode && errors.featureCode}
              </Text>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
      <Wrap spacing="30px" h="100px">
        <WrapItem>
          <Box>
            <InputLabel value={values.bundles.length ? '-' : ''} name="feature_bundle_modal" />
            <Menu closeOnSelect={false}>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    overflowY="auto"
                    onBlur={handleBlur('bundles')}
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {values.bundles.length ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        maxInlineSize="305px"
                        color={colors.subTextColor}
                      >
                        {values.bundles.map((bundle: SoftwareBundle) => bundle.name).join(', ')}
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="feature_bundle_modal.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    zIndex="9999"
                    h="200px"
                    overflowY={'scroll'}
                  >
                    <CheckboxGroup>
                      <Stack px="16px">
                        {softwareBundles &&
                          softwareBundles.map((softwareBundle: SoftwareBundle, index: number) => (
                            <Checkbox
                              key={index}
                              data-testid="menu-item"
                              id={softwareBundle.id.toString()}
                              fontSize="14px"
                              variant="themeNoBg"
                              name={softwareBundle.name}
                              backgroundColor="transparent"
                              _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                              onChange={() => handleBundleMenulist(softwareBundle)}
                            >
                              {softwareBundle.name}
                            </Checkbox>
                          ))}
                      </Stack>
                    </CheckboxGroup>
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box>
            <InputLabel value={values.addOn.id ? '-' : ''} name="feature_addon_modal" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    // onBlur={handleBlur('bundles')}
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {values.addOn.id ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {values.addOn.name}
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="feature_addon_modal.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    fontSize="14px"
                    zIndex={9999}
                    h="200px"
                    overflowY={'scroll'}
                  >
                    {addonsData &&
                      addonsData?.map((addOn: Addon) => (
                        <MenuItem
                          key={addOn.id}
                          id={addOn.id.toString()}
                          data-testid="menu-item"
                          color="rgba(51, 51, 51, 1)"
                          px="16px"
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={() => setFieldValue('addOn', addOn)}
                        >
                          {addOn.name}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <Text
          fontSize="13px"
          color={(touched.bundles || touched.addOn) && errors.bundles ? 'red' : 'rgba(51, 51, 51, 0.7)'}
          mt="-20px"
        >
          <FormattedMessage id="features_note.message" />
        </Text>
      </Wrap>
      <Wrap>
        <WrapItem>
          <Box display="flex" flexDirection="column" minH="70px">
            <InputLabel value={values?.notes} name={'notes_optional'} />
            <Input
              variant="flushed"
              color={colors.subTextColor}
              borderColor="#E0E0E0"
              maxLength={75}
              fontSize="14px"
              name="notes"
              value={values?.notes}
              _placeholder={{ fontSize: '14px' }}
              width={{ xs: '85vw', smd: '89vw', md: '645px' }}
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 0.5px 0"
              onKeyPress={specialCharacterValidation}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'notes_optional.message' })}
            />
            <Text fontSize="12px" color="grey" display="flex" float="right" justifyContent="flex-end">
              {Math.abs(values?.notes?.length - 75)}
            </Text>
          </Box>
        </WrapItem>
      </Wrap>

      {featurePostFail ? <BannerMessage message={postFeatureFailMessage} defaultMessage={''} /> : ''}
    </Box>
  );
};

export default FeatureModalInputFields;
