import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import LandingScreen from 'components/landingPage/LandingSreen';
import { Box } from '@chakra-ui/react';
import { handleCreateUserModal } from 'redux/user/Reducers/userSlice';
import { getUsersRoles } from 'redux/user/Actions/userActions';
import { RootState } from 'redux/Store/store';
import Loader from 'components/loader/Loader';

export default function UserLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const isLoading = useAppSelector((state: RootState) => state.user.usersListLoading);
  const dispatch = useAppDispatch();
  const handleModalOpen = () => {
    dispatch(handleCreateUserModal(true));
    dispatch(getUsersRoles(0));
  };
  return (
    <Box data-testid="landing-screen">
      <Loader isLoading={isLoading} />
      {!isLoading && <LandingScreen handleClick={handleModalOpen} title="Users" isFiltersApplied={isFiltersApplied} />}
    </Box>
  );
}
