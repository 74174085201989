/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { useEffect } from 'react';
import { Input, Image, Box, Link, Text, InputGroup, useDisclosure, InputLeftElement } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import buttonActive from '../../assets/icons/submit_button_bubble_active.svg';
import buttonDisable from '../../assets/icons/submit_button_bubble_disabled.svg';
import emailIcon from '../../assets/icons/emailInputFieldIcon.svg';
import { useIntl, FormattedMessage } from 'react-intl';
import { customColor } from '../../assets/styles/colors';
import { LoginTitleSection, LoginEmailLabel } from '../LoginMainFrame/LoginLabels';
import CustomFormSubmitButton from '../SubmitButton/CustomFormSubmitButton';
import { getSingleSignInUser, createUserSession } from '../../redux/login.thunk';
import { InitialFormValues } from '../../types/types';
import { useDispatch, useSelector } from 'react-redux';
import { SharedUiState, useAppDispatch } from '../../redux/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useLoginEffect } from '../Hooks/useLoginEffect';
import Spinner from '../../components/Spinner/Spinner';
import {
  fetchOwnerOrganizations,
  fetchSelectedOrganization,
  logoutHandler,
  resetUserErrorMsg,
} from '../../redux/login.slice';
import LoginErrorModal from './LoginErrorModal';
import InactiveModal from './InactiveModal';
import { ResetPasswordModal } from '../../components/ResetPasswordModal/ResetPasswordModal';
import { apiVersionThunks, selectVersionInfo } from '../../redux/apiVersion';
import { getUserActivity } from '../../utils';
import { UserActivityEvent } from '@exo/ew-data-models';
import OwnerOrganizations from '../OwnerOrganizations/OwnerOrganizations';
import Submit from '../Submit/Submit';
import { Url_Constants } from '../../utils/constants';
import ForgotPasswordButton from './ForgotPasswordButton';

type LoginProps = {
  handleLogin: (idToken: string | undefined) => void;
  baseUrl: string | undefined;
  allowPasswordReset: boolean;
};

const initialValues = {
  email: '',
  password: '',
};

type LoginWithRedirectOptions = {
  domain: string;
  clientId: string;
  redirectUri: string;
};

const Login = ({ handleLogin, baseUrl, allowPasswordReset }: LoginProps) => {
  const {
    onClose: onClosePasswordResetModal,
    onOpen: onOpenPasswordResetModal,
    isOpen: isPasswordResetModalOpen,
  } = useDisclosure();
  const colors = customColor();
  const intl = useIntl();
  const dispatch = useDispatch<useAppDispatch>();
  const loginSliceData = useSelector((state: SharedUiState) => state.login);
  const isExoWorks = useSelector((state: SharedUiState) => state.login.isExoWorks);
  const currentUser = useSelector((state: SharedUiState) => state.user.currentUser);
  const versionInfoStr = useSelector(selectVersionInfo)?.productVersion;
  const { getAccessTokenSilently, getIdTokenClaims, user, logout, isAuthenticated, isLoading } = useAuth0();
  // Auth0 can redirect back to the landing page (/exams) with query params
  // that indicate an error, e.g. password has expired.
  // We intercept the error there, but direct back to the
  // login page with a query param to display the error
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('')
      .matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/)
      .email(intl.formatMessage({ id: 'invalid_email.message' }))
      .max(256, intl.formatMessage({ id: 'invalid_email.message' })),
  });

  const handleLoginWithRedirect = (opts: LoginWithRedirectOptions) => {
    // creating new auth0 client to login with dynamic authConfig from routing service.
    const newAuth0Client = new Auth0Client(opts as Auth0ClientOptions);
    return newAuth0Client.loginWithRedirect();
  };

  const onOrgselect = (selctedOrg: any) => {
    dispatch(
      getSingleSignInUser({
        email: formik.values.email,
        baseUrl: loginSliceData.baseUrl,
        loginWithRedirect: handleLoginWithRedirect,
        selectedOrg: selctedOrg,
      }),
    );
    setShowInvalidUser(true);
  };

  const onSubmit = async (values: { email: string; password: string }) => {
    if (values.email && !showPassword) {
      dispatch(
        getSingleSignInUser({
          email: values.email,
          baseUrl: loginSliceData.baseUrl,
          loginWithRedirect: handleLoginWithRedirect,
          selectedOrg: loginSliceData?.selectedOrg,
        }),
      );
    }
    // Commented out along with password login flow, keeping it as pulse team may need it for the future
    // if (values.email && values.password) {
    //   if (loginSliceData.singleSignOnUser.auth_profiles.length > 0) {
    //     dispatch(
    //       fetchUser({
    //         user: {
    //           email: formik.values.email,
    //           password: formik.values.password,
    //           endPoint: loginSliceData?.endPoint,
    //           org_code: loginSliceData.singleSignOnUser?.org_code,
    //         },
    //         baseUrl: baseUrl,
    //       }),
    //     );
    //   } else {
    //     setRegisteredUser(false);
    //   }
    // }
    setShowInvalidUser(true);
  };

  const handleEmailSubmit = () => {
    dispatch(
      getSingleSignInUser({
        email: formik.values.email,
        baseUrl: loginSliceData.baseUrl,
        loginWithRedirect: handleLoginWithRedirect,
        selectedOrg: loginSliceData?.selectedOrg,
      }),
    );
    setShowInvalidUser(true);
  };

  const formik = useFormik<InitialFormValues>({ initialValues, validationSchema, onSubmit });

  const { showPassword, showInvaliduser, setShowInvalidUser } = useLoginEffect(
    formik,
    loginSliceData,
    dispatch,
    handleLogin,
    baseUrl,
  );

  const handleURLUpdateOnIdleState = () => {
    const isUserIdle = searchParams.get('status');
    if (isUserIdle) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete('status');

      // Update the URL without refreshing the page
      navigate({ search: newSearchParams.toString() });
    }
  };

  useEffect(() => {
    const handlePageLoad = () => {
      // Page was reloaded
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        handleURLUpdateOnIdleState();
      }
    };

    window.addEventListener('load', handlePageLoad);

    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, [navigate, location.search]);

  useEffect(() => {
    // we sent the id token for verification
    getIdTokenClaims()
      .then((res) => {
        if (res) {
          const hasClientId = !!localStorage.getItem('auth0_clientId');
          if (hasClientId) {
            // setting idToken only if auth0 clientID is available.
            const idToken = res.__raw;
            const hasIdToken = !!localStorage.getItem('exo_sso');
            if (!hasIdToken) {
              localStorage.setItem('exo_sso', idToken);
              dispatch(createUserSession(baseUrl || loginSliceData.baseUrl));
              return idToken;
            }
          }
        }
      })
      .then((token) => {
        if (token) {
          handleLogin(token);
        }
      })
      .catch(() => {
        handleLogin(undefined);
      });
  }, [getAccessTokenSilently, user, getIdTokenClaims, handleLogin, isAuthenticated]);

  useEffect(() => {
    const errorId = searchParams.get('error');
    const errorDesc = searchParams.get('error_description');
    if (errorId && errorDesc) {
      // Detect Auth0 error in query string and log the
      // user out with an error, e.g. password has expired.
      const isLoggedIn = !!localStorage.getItem('exo_sso');
      if (errorId === 'access_denied' && isLoggedIn) {
        dispatch(
          apiVersionThunks.sendUserActivity(
            getUserActivity(UserActivityEvent.LogoutSuccessful, currentUser, versionInfoStr),
          ),
        ).then(() => {
          // Clear local storage and log out
          dispatch(logoutHandler());
          const loginRoute = window.location.origin;
          const queryParams = window.location.search;
          logout({ logoutParams: { returnTo: loginRoute + queryParams } });
        });
      }
    }
  }, [searchParams]);

  const onFocusEmailInput = () => {
    handleURLUpdateOnIdleState();
  };

  useEffect(() => {
    if (loginSliceData.selectedOrg) {
      dispatch(fetchSelectedOrganization(null));
      dispatch(fetchOwnerOrganizations(null));
    }

    if (loginSliceData.userErrorMessage) {
      dispatch(resetUserErrorMsg());
    }
  }, [formik.values.email]);

  const isLoginEmailView = () => {
    const userErrorMessage = loginSliceData.userErrorMessage;
    const ownerOrganizations = loginSliceData.ownerOrganizations;

    return userErrorMessage.length > 0 || ownerOrganizations.length === 0;
  };

  const canSubmit = () => {
    if (isLoginEmailView()) {
      return isExoWorks && !formik.errors.email && formik.values.email && !loginSliceData.singleSignOnLoading;
    } else {
      return (
        isExoWorks &&
        !formik.errors.email &&
        formik.values.email &&
        !loginSliceData.singleSignOnLoading &&
        loginSliceData.selectedOrg
      );
    }
  };

  return (
    <Box overflowY="auto" maxW="370px" display="contents">
      <LoginTitleSection />
      <Box>
        {isLoading || loginSliceData.singleSignOnLoading ? (
          <Spinner color={colors.textColor} />
        ) : (
          <form onSubmit={formik.handleSubmit} className="form">
            <Box>
              {isLoginEmailView() ? (
                <>
                  {!isExoWorks && <LoginEmailLabel email={isExoWorks ? 'email' : formik.values.email} />}
                  <InputGroup mt={isExoWorks ? '30px' : ''}>
                    {isExoWorks && (
                      <InputLeftElement
                        pointerEvents="none"
                        ml="20px"
                        w={{ xs: '27px', md: '29px' }}
                        h={{ xs: '56px', md: '59px' }}
                      >
                        <Image src={emailIcon} width={10} cursor="pointer" />
                      </InputLeftElement>
                    )}
                    <Input
                      sx={{
                        '&:-webkit-autofill, &:-webkit-autofill': {
                          WebkitTextFillColor: '',
                          caretColor: colors.textColor,
                          '&::placeholder': {
                            color: '',
                          },
                        },
                      }}
                      placeholder={intl.formatMessage({ id: 'email.message' })}
                      variant={'flushed'}
                      type="email"
                      width="100%"
                      fontSize={isExoWorks ? { xs: '18px', md: '20px' } : { xs: '18px', lg: '18px' }}
                      overflow="hidden"
                      style={{ textOverflow: 'ellipsis' }}
                      data-testid="input"
                      color={colors.textColor}
                      padding={
                        isExoWorks
                          ? {
                              xs: '5px 2px 5px 55px',
                              md: '15px 10px 15px 55px',
                            }
                          : '5px 0'
                      }
                      pr={isExoWorks ? '' : { xs: showPassword ? '0%' : '15%', lg: '10%' }}
                      focusBorderColor={colors.primaryColor}
                      borderWidth={isExoWorks ? '0.5px' : '0px 0px 1px 0px'}
                      borderColor={isExoWorks ? colors.lightModeBlue : colors.inputBorderColor}
                      borderRadius={isExoWorks ? { xs: '6px', md: '8px', lg: '10px', xl: '14px' } : '0px'}
                      fontWeight="400"
                      {...formik.getFieldProps('email')}
                      onFocus={onFocusEmailInput}
                      mb={isExoWorks ? '0px' : '10px'}
                      background={isExoWorks ? colors.lightModeWhite : ''}
                      h={isExoWorks ? { xs: '56px', md: '59px' } : ''}
                      lineHeight={isExoWorks ? '29.4px' : ''}
                    />
                    {!showPassword && !isExoWorks && (
                      <CustomFormSubmitButton
                        icon={
                          <Image
                            cursor={formik.values.email && !formik.errors.email ? '' : 'not-allowed'}
                            w="30px"
                            src={!formik.errors.email && formik.values.email ? buttonActive : buttonDisable}
                          />
                        }
                      />
                    )}
                  </InputGroup>
                </>
              ) : (
                isExoWorks &&
                loginSliceData.ownerOrganizations.length > 1 && (
                  <Box width="100%">
                    <OwnerOrganizations onOrgselect={onOrgselect} />
                  </Box>
                )
              )}
              {isExoWorks && isLoginEmailView() && (
                <Box display="flex" mt="10px" h="26px">
                  <Text
                    fontSize={{ xs: '16px', md: '18px' }}
                    color={colors.textColor}
                    fontWeight="400"
                    opacity="75%"
                    lineHeight={{ xs: '23.52px', md: '26.46px' }}
                  >
                    <FormattedMessage id="trouble_signing_in.message" />
                  </Text>
                  <Text as="p" color={colors.violetColor}>
                    <Link
                      ml="5px"
                      fontSize={{ xs: '16px', md: '18px' }}
                      fontWeight="400"
                      href={Url_Constants.customerSupport}
                      target="_blank"
                    >
                      <FormattedMessage id="contact_us.message" />
                      {'.'}
                    </Link>
                  </Text>
                  <ForgotPasswordButton onOpenPasswordResetModal={onOpenPasswordResetModal} />
                </Box>
              )}
            </Box>
            {isLoginEmailView() && (
              <Box h="130px" position="relative">
                <Box pt="8px">
                  {loginSliceData.userErrorMessage && showInvaliduser && (
                    <Box w="100%">
                      <Text
                        color={isExoWorks ? colors.errorTextColor : 'common.200'}
                        minH="30px"
                        fontSize={isExoWorks ? { xs: '16px', md: '18px' } : { xs: '14px' }}
                        fontWeight={isExoWorks ? '400' : ''}
                        lineHeight={isExoWorks ? '26.46px' : ''}
                      >
                        <FormattedMessage id={loginSliceData.userErrorMessage} />
                      </Text>
                    </Box>
                  )}
                  <LoginErrorModal
                    searchParams={searchParams}
                    formik={formik}
                    openPasswordResetModal={onOpenPasswordResetModal}
                  />
                  <InactiveModal searchParams={searchParams} formik={formik} />
                  {formik.errors.email &&
                    formik.touched.email &&
                    (isExoWorks ? (
                      <Text
                        color={colors.errorTextColor}
                        fontSize={{ xs: '16px', md: '18px' }}
                        fontWeight="400"
                        w="500px"
                        lineHeight="26.46px"
                      >
                        <FormattedMessage id="please_enter_a_valid_email.message" />
                      </Text>
                    ) : (
                      <Text color={'common.200'} fontSize={{ xs: '14px', lg: '14px' }}>
                        <FormattedMessage id="please_use_valid_email.message" />
                      </Text>
                    ))}
                </Box>
                {isExoWorks && isLoginEmailView() && (
                  <Submit
                    className="login-continue-button"
                    handleEmailSubmit={handleEmailSubmit}
                    isEnable={canSubmit() === true}
                  />
                )}
              </Box>
            )}
            {/*
              NOTE: Password field is commented temporarily since it is provided in auth0 login page when redirected.
                Not deleting this as it might be useful for when password will be validated using auth0 service,
                without redirecting to auth0 login page.
            */}
            {/* {loginSliceData.singleSignOnLoading
              ? loadingIndicator
              : !loginSliceData?.isSsoEnabledUser &&
                showPassword && (
                  <Box minH="90px" mt="3">
                    <LoginPasswordLabel password={formik.values.password} />
                    <InputGroup size="md">
                      <Input
                        placeholder={intl.formatMessage({ id: 'password.message' })}
                        variant="flushed"
                        fontSize={{ xs: '18px', lg: '18px' }}
                        pb="5"
                        pr={{ xs: '15%', lg: '10%' }}
                        borderBottomColor={colors.inputBorderColor}
                        type="password"
                        fontWeight="400"
                        color={colors.textColor}
                        focusBorderColor={colors.primaryColor}
                        borderWidth="0 0 1px 0"
                        boxShadow="0px 1px 0px -1px common.100 !important"
                        {...formik.getFieldProps('password')}
                      />
                      <CustomFormSubmitButton
                        icon={
                          <Image
                            cursor={formik.values.password ? '' : 'not-allowed'}
                            w="30px"
                            mb="3"
                            src={formik.values.password ? buttonActive : buttonDisable}
                          />
                        }
                      />
                    </InputGroup>
                    <Text color="common.200" minH="30px" fontSize={{ xs: '14px', lg: '14px' }}>
                      {((loginSliceData.userErrorMessage && showInvaliduser) || !registeredUser) && (
                        <FormattedMessage id={`E00003.message`} />
                      )}
                    </Text>
                  </Box>
                )} */}
          </form>
        )}
      </Box>
      <ResetPasswordModal onClose={onClosePasswordResetModal} isOpen={isPasswordResetModalOpen} />
    </Box>
  );
};

export default Login;
