import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import validator from 'validator';
import { getCustomerEmailValidation, getCustomerNameValidation } from 'redux/customers/cutomersAction';
import { useAppDispatch } from 'redux/hooks';
import { useState } from 'react';

export const initialValues = {
  customer_name: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
};

export const customerSchema = (customerNameUpdate: any) => {
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<any>(null);
  const emailValidation: Yup.StringSchema<string | undefined, AnyObject, string | undefined> = Yup.string()
    .required('This field is required')
    .email('Invalid format')
    .matches(/^[a-zA-Z0-9.@+-]*$/, { message: 'Invalid format', excludeEmptyString: true })
    .test('email-validation', 'Invalid format: More than one plus sign is not allowed', async function (value) {
      if (!value) return true;
      if (!validator.isEmail(value)) {
        return false;
      }
      if (value.includes('+') && value.match(/\+/g)!.length > 1) {
        return false;
      }
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        dispatch(
          getCustomerEmailValidation({
            field_name: 'customer_admin_email',
            field_value: encodeURIComponent(value),
          }),
        );
      }, 400);
      setTimer(newTimer);
      return true;
    });
  return Yup.object({
    customer_name: Yup.string()
      .required('This field is required')
      .min(4, 'Must be 4 characters or more')
      .matches(/^[a-zA-Z0-9.+'_ ,-]*$/, 'Invalid format')
      .test('is-name-unique', '', async function (value) {
        if (customerNameUpdate) {
          clearTimeout(timer);
          const newTimer = setTimeout(() => {
            dispatch(
              getCustomerNameValidation({
                field_name: 'customer_name',
                field_value: value ? value : '',
              }),
            );
          }, 400);
          setTimer(newTimer);
        }
        return true;
      }),
    first_name: Yup.string()
      .required('This field is required')
      .matches(/^[a-zA-Z0-9.+'_ ,-]+$/, 'Invalid format'),
    last_name: Yup.string()
      .required('This field is required')
      .matches(/^[a-zA-Z0-9.+'_ ,-]+$/, 'Invalid format'),
    email: emailValidation,
    phone: Yup.string()
      .required('This field is required')
      .matches(/^\d+$/, 'Phone number must contain only numbers')
      .min(10, 'Phone Number min & max length is 10 characters'),
  });
};
