import { useAppDispatch } from 'redux/hooks';
import { Box, Center, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { setActiveTabs } from 'redux/common/CommonSlice';

interface NavBarItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tab: string;
  isActive: boolean;
  routePrefix: string;
}

const SubTabItems = ({ tab, isActive, routePrefix }: NavBarItemProps) => {
  const colors = customColor();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setActiveTabs(tab));
  };

  return (
    <Box
      borderBottom={isActive ? `2px solid` : '0'}
      borderBottomColor={colors.primaryColor}
      backgroundColor={isActive ? '#FAFAFA' : ''}
      w="215px"
    >
      <NavLink data-test-id={tab} to={`${routePrefix}/?tab=${tab}`} onClick={handleClick}>
        <Center
          fontSize="20px"
          color={isActive ? colors.primaryColor : 'rgba(51, 51, 51, 0.7)'}
          py="5px"
          w="215px"
          alignItems="center"
        >
          <FormattedMessage id={`${tab}.message`} />
        </Center>
      </NavLink>
    </Box>
  );
};

export default SubTabItems;
