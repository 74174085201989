import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { postSoftwareBundleData } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { handleBundelCreateButtonEnable } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import SoftwareBundleDescriptionInput from './SoftwareBundleDescriptionInput';
import SoftwareBundleNameStatusInputs from './SoftwareBundleNameStatusInputs';
import SoftwareBundlesDateCatalogueInputs from './SoftwareBundlesDateCatalogueInputs';
import SoftwareBundleTierExpirationInputs from './SoftwareBundleTierExpirationInputs';

const CreateSoftwareBundlesAllInputFields = () => {
  const dispatch = useAppDispatch();
  const bundelnameValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleNameInputValue);
  const bundelStatusValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleStatusInputvalue);
  const bundelCatalogueValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleCatalogueInputValue);
  const bundelOrgTierValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleOrganizationTierValue);
  const bundelExpirationValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleExpirationPeriodValue);
  const bundelDescriptionvalue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleDescriptionValue);
  const bundelmanufactureYear = useAppSelector((state) => state.customInputFields.getDatePickerYear);
  const bundelmanufactureDay = useAppSelector((state) => state.customInputFields.getDatePickerDay);
  const bundelmanufactureMonth = useAppSelector((state) => state.customInputFields.getDatePickerMonth);
  const postBundleToggle = useAppSelector((state) => state.softwareBundles.getCreateBundleApiDataToggle);
  const fetchPostBundleDataApi = useAppSelector((state) => state.softwareBundles.fetchPostBundleDataApi);
  const postuniqueCatalogueValidation = useAppSelector((state) => state.softwareBundles.postuniqueCatalogueValidation);
  const postuniqueNameValidation = useAppSelector((state) => state.softwareBundles.postuniqueNameValidation);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const postData: any = {
    name: bundelnameValue,
    status: bundelStatusValue ? bundelStatusValue : 1,
    catalog_number: bundelCatalogueValue,
    license_tier: bundelOrgTierValue,
    grace_period_days: bundelExpirationValue ? bundelExpirationValue : 30,
    description: bundelDescriptionvalue,
    availability_date: `${bundelmanufactureYear}-${bundelmanufactureMonth}-${bundelmanufactureDay}T00:00:00.000000Z`,
  };

  function SubmitButton() {
    if (
      bundelnameValue &&
      bundelCatalogueValue &&
      bundelOrgTierValue &&
      bundelmanufactureYear &&
      postuniqueCatalogueValidation == false &&
      postuniqueNameValidation == false
    ) {
      return dispatch(handleBundelCreateButtonEnable(true));
    }
    // } else if (firstNameLength == true || lastNameLenghth == true || phoneNumberValidation == true) {
    //   return dispatch(handleBundelCreateButtonEnable(false));
    // }
    else {
      return dispatch(handleBundelCreateButtonEnable(false));
    }
  }
  useEffect(() => {
    SubmitButton();
  }, [
    bundelnameValue,
    postuniqueCatalogueValidation,
    bundelStatusValue,
    bundelCatalogueValue,
    bundelOrgTierValue,
    bundelExpirationValue,
    bundelDescriptionvalue,
    bundelmanufactureYear,
    postuniqueNameValidation,
  ]);

  useEffect(() => {
    if (!postBundleToggle && fetchPostBundleDataApi == true) {
      dispatch(postSoftwareBundleData(postData));
    }
  }, [fetchPostBundleDataApi]);

  return (
    <div>
      <SoftwareBundleNameStatusInputs />
      <SoftwareBundlesDateCatalogueInputs />
      <SoftwareBundleTierExpirationInputs />
      <SoftwareBundleDescriptionInput />
    </div>
  );
};

export default CreateSoftwareBundlesAllInputFields;
