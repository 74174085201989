import { Box, useDisclosure } from '@chakra-ui/react';
import CustomDeleteModal from 'components/customModal/CustomDeleteModal';
import React, { useEffect } from 'react';
import { resetCustomModalEnableFields } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { openProvisionedModal, resetOrganizationCreateActions } from 'redux/organizations/Reducers/organizationSlice';
import {
  handleClientPostDataCreateButtonEnable,
  handleClientSuccessfulPost,
  handlePostApiDataSpinner,
  handleToPostApiData,
  resetCreateSettingsPopUpValues,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { Error_Type_Enum } from 'utils/enum';
import { CircleRightEnabledIcon } from '../../../../../../../../../libs/shared-ui/src/lib/assets/icons/icons';
import OrgInfoActivateModalInputFields from './OrgInfoActivateInputFields';

const OrgInfoActivateModal = () => {
  const dispatch = useAppDispatch();
  const { onClose } = useDisclosure();
  const orgName = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const postDataSuccessfulCreation = useAppSelector((state) => state.clientAccess.enableSuccessfulPostData);
  const enableCreateButton = useAppSelector((state) => state.customInputFields.handleCreateButtonEnable);
  const handleSettingsModal = useAppSelector((state) => state.organization.getActivateModalValue);
  const createClientPostApiFail = useAppSelector((state) => state.clientAccess.clientPostDataFailStrings);
  const postFailure = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const toggleClientButtonEnableSpinnerValue = useAppSelector((state) => state.clientAccess.postClientApiDataSpinner);

  useEffect(() => {
    if (createClientPostApiFail.length > 0) {
      dispatch(handleClientSuccessfulPost(true));
    }
    if (createClientPostApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetCustomModalEnableFields());
        dispatch(resetCreateSettingsPopUpValues());
        dispatch(resetOrganizationCreateActions());
        dispatch(handleClientPostDataCreateButtonEnable(false));
        onClose();
      }, ModalTimeOut);
    }
  }, [createClientPostApiFail]);

  const handleModalClose = () => {
    dispatch(openProvisionedModal(false));
    dispatch(resetOrganizationCreateActions());
    dispatch(resetCreateSettingsPopUpValues());
    dispatch(resetCustomModalEnableFields());
  };
  const handleCreateButtonClick = () => {
    dispatch(handleToPostApiData(true));
    dispatch(handlePostApiDataSpinner(true));

    if (!handleSettingsModal) {
      dispatch(handleClientSuccessfulPost(false));
    }
  };

  return (
    <Box>
      <CustomDeleteModal
        openModal={handleSettingsModal}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createClientPostApiFail && toggleClientButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={enableCreateButton}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleClientButtonEnableSpinnerValue}
        InputFieldsComponent={OrgInfoActivateModalInputFields}
        modalHeader="are_you_sure_you_want_to_activate"
        modalButtonName="activate"
        modalHeaderPassingName={orgName.org_name}
        RightEnabledIcon={CircleRightEnabledIcon}
        colorMode="#24B0FF"
      />
    </Box>
  );
};
export default OrgInfoActivateModal;
