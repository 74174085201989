/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useState } from 'react';
import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text, Wrap, WrapItem, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { InputLabel } from 'components/InputField';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customColor } from '../../../../../../libs/shared-ui/src/lib/assets';
import {
  handleBundleOraganizationTierLengthValidation,
  handleCreateSoftwareBundleExpirationPeriodValue,
  handleCreateSoftwareBundleOrganizationTier,
} from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';

const SoftwareBundleTierExpirationInputs = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const [tierValue, setTierValue] = useState<string>('');
  const [gracePeriodValue, setGracePeriodValue] = useState<string>('30');
  const tierData = useAppSelector((state) => state.organization.tiersData);
  const bundleGracePeriodData = useAppSelector((state) => state.softwareBundles.bundleGracePeriodData);
  const bundleTierLengthValidation = useAppSelector(
    (state) => state.softwareBundles.bundleOraganizationTierLengthValidation,
  );

  const handleTier = (e: any) => {
    setTierValue(e.target.name);
    dispatch(handleCreateSoftwareBundleOrganizationTier(e.target.value));
  };
  const handleExpirationPeriod = (e: any) => {
    setGracePeriodValue(e.target.name);
    dispatch(handleCreateSoftwareBundleExpirationPeriodValue(e.target.name));
  };
  const handleorganizationTierOnBlur = () => {
    if (tierValue?.length == 0) {
      dispatch(handleBundleOraganizationTierLengthValidation(true));
    } else {
      dispatch(handleBundleOraganizationTierLengthValidation(false));
    }
  };
  return (
    <Box>
      <Wrap spacing="30px" mt="5px">
        <WrapItem>
          <Box>
            <InputLabel value={tierValue} name="license_type" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {tierValue ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {tierValue}
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="license_type.message" />
                      </Text>
                    )}
                  </MenuButton>
                  {isOpen ? (
                    ''
                  ) : (
                    <Text fontSize="12px" color="#FB5654">
                      {bundleTierLengthValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                    </Text>
                  )}
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {tierData &&
                      tierData.map((state: any) => (
                        <MenuItem
                          id={state.id}
                          key={state.tier_name}
                          data-testid="menu-item"
                          value={state.id}
                          onBlur={handleorganizationTierOnBlur}
                          name={state.tier_name}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={handleTier}
                        >
                          {state.tier_name}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box>
            <InputLabel value={gracePeriodValue} name="expiration_grace_period" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {gracePeriodValue ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {gracePeriodValue}
                        {<FormattedMessage id="days.message" />}
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="expiration_grace_period.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {bundleGracePeriodData &&
                      bundleGracePeriodData.map((state: any) => (
                        <MenuItem
                          id={state.id}
                          key={state.grace_period_days}
                          data-testid="menu-item"
                          value={state.id}
                          name={state.grace_period_days}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={handleExpirationPeriod}
                        >
                          {state.grace_period_days}
                          {<FormattedMessage id="days.message" />}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default SoftwareBundleTierExpirationInputs;
