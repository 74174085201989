import { userRequest } from 'api';
import { Dispatch } from 'react';
import {
  setOrgFeatureGroups,
  fetchFeaturesLoading,
  updateFeatureAvailablity,
  setOrgFeatureAvailablityLoading,
  setOrgFeatureAvailablityFail,
  setOrgFeatureAvailablitySuccess,
  isUpdatedStatusFail,
} from '../Reducers/OrgFeaturesSlice';
import { setError } from 'redux/error/errorSlice';
import { Feature, FeatureGroup } from 'types/feature';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}

export const getOrgFeatureGroups = (orgId: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchFeaturesLoading());
  try {
    const res = await userRequest.get(`organizations/${orgId}/licenses/features`);
    if (res && res.status == 200) {
      const mappedData = res.data.data.groups.map((group: any) => ({
        groupName: group.group_name,
        features: group.features.map(
          (feature: any) =>
            ({
              id: feature.id,
              name: feature.name,
              code: feature.code,
              isUpdating: false,
              groupName: feature.group_name,
              description: feature.description,
              enabled: feature.enabled,
              bundles: [],
              controlledByAdmin: feature.controlled_by_admin,
              controlledByPulse: feature.controlled_by_pulse,
            } as Feature),
        ) as FeatureGroup,
      }));
      dispatch(setOrgFeatureGroups(mappedData));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

export const updateFeature =
  (orgId: string, featureId: number, isEnabled: boolean) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setOrgFeatureAvailablityLoading(true));
    try {
      const res = await userRequest.put(`organizations/${orgId}/licenses/features/${featureId}`, {
        enabled: isEnabled,
      });
      if (res && res.status == 200) {
        dispatch(updateFeatureAvailablity({ id: featureId }));
        dispatch(setOrgFeatureAvailablitySuccess(true));
      }
    } catch (error: any) {
      dispatch(isUpdatedStatusFail());
      dispatch(setOrgFeatureAvailablityFail(true));
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };
