import { Dispatch } from 'react';
import {
  setCustomersData,
  setIsLoading,
  setCustomerInfoData,
  setCustomersSearchLoading,
  setCustomersSearchResults,
  setCustomerValidationLoading,
  setCustomerEmailValidation,
  postCustomerDetailsLoading,
  postCustomerDetailsSuccess,
  postCustomerDetailsFail,
  setIsCustomerNameValidationLoading,
  setCustomerNameValidation,
} from './customersSlice';
import { setError } from '../error/errorSlice';
import { userRequest } from 'api';
import { CustomerPostDetails, CustomerValidation } from 'types/customer';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const fetchCustomers =
  (pageNumber = 1, searchText = '') =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setIsLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(`/customers?page=${pageNumber}&search=${encodeURIComponent(searchText)}`);
      if (res && res.status === 200) {
        dispatch(setCustomersData(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };

export const getCustomerInfo = (customerId: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setIsLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`/customers/${customerId}`);
    if (res && res.status === 200) {
      dispatch(setCustomerInfoData(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

export const searchCustomers = (searchText: string, key: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setCustomersSearchLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`/customers/?search=${searchText}&key=${key}`);
    if (res && res.status === 200) {
      dispatch(setCustomersSearchResults(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};
export const getCustomerEmailValidation =
  (postEmailData: CustomerValidation) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setCustomerValidationLoading());

    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(
        `customers/validate/?field_name=${'customer_admin_email'}&field_value=${postEmailData.field_value}`,
      );
      if (res && res.data.status_code === 404) {
        dispatch(setCustomerEmailValidation(false));
      } else if ((res && res.data.status_code === 200) || res.data.status_code === 200) {
        dispatch(setCustomerEmailValidation(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(setCustomerEmailValidation(false));
    }
  };
export const getCustomerNameValidation =
  (postNameData: CustomerValidation) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setIsCustomerNameValidationLoading());

    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(
        `customers/validate/?field_name=${'customer_name'}&field_value=${postNameData.field_value}`,
      );
      if (res && res.data.status_code === 404) {
        dispatch(setCustomerNameValidation(false));
      } else if ((res && res.data.status_code === 200) || res.data.status_code === 200) {
        dispatch(setCustomerNameValidation(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(setCustomerNameValidation(false));
    }
  };

export const postCustomerDetails = (payload: CustomerPostDetails) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(postCustomerDetailsLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post('customers', payload);
    if ((res && res.data.status_code === 200) || res.data.status_code === 201) {
      dispatch(postCustomerDetailsSuccess(true));
      dispatch(postCustomerDetailsFail(false));
    } else {
      dispatch(postCustomerDetailsFail(true));
      dispatch(postCustomerDetailsSuccess(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(postCustomerDetailsFail(true));
    dispatch(postCustomerDetailsSuccess(false));
  }
};
