import { Box, Button, Flex, Text, useColorModeValue, Spinner } from '@chakra-ui/react';
import { CircleCheckIcon_v1, CircleCrossIcon_v1, CircleRightEnabledIcon_v1 } from 'assets/icons/icons';
import { customColor } from 'assets/styles/colors';

const SubmitButton = ({
  isDisbaled = false,
  isLoading = false,
  isFail = false,
  isSucces = false,
  handleSubmit,
  label = 'Add',
}: any) => {
  const colors = customColor();
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');
  const getActionLabels = () => {
    if (isLoading) {
      return { label: label, icon: <Spinner /> };
    }
    if (isSucces) {
      return { label: 'Done', icon: <CircleCheckIcon_v1 /> };
    }
    if (isFail) {
      return { label: 'Failure', icon: <CircleCrossIcon_v1 /> };
    }

    return { label: label, icon: <CircleRightEnabledIcon_v1 /> };
  };

  const actionLabels = getActionLabels();
  const getTheme = () => {
    if (isDisbaled || isFail) {
      return {
        color: '#BAC5CD',
        borderColor: '#BAC5CD',
        hoverBg: 'auto',
        hoverColor: 'auto',
      };
    }
    if (isSucces) {
      return {
        color: blackAndWhiteColorMode,
        borderColor: blueColorMode,
        bgColor: blueColorMode,
        hoverBg: 'auto',
        hoverColor: 'auto',
      };
    }
    return {
      color: blueColorMode,
      borderColor: blueColorMode,
      hoverBg: blueColorMode,
      hoverColor: blackAndWhiteColorMode,
    };
  };
  const theme = getTheme();
  return (
    <Button
      variant="outline"
      type="button"
      width="195px"
      height="42px"
      borderRadius="20px"
      backgroundColor={theme?.bgColor ?? 'auto'}
      borderColor={theme?.borderColor}
      color={theme?.color}
      isDisabled={isDisbaled}
      fontSize="17px"
      float="right"
      data-testid="org-button-disabled"
      _hover={{
        cursor: isDisbaled ? 'not-allowed' : 'pointer',
        // ...(!isDisbaled && {
        bg: theme?.hoverBg,
        color: theme?.hoverColor,
        // }),
      }}
      onClick={handleSubmit}
      _disabled={{
        opacity: 1,
      }}
    >
      <Flex alignItems="center">
        <Text
          fontSize="12px"
          display="flex"
          alignItems="center"
          fontWeight="extrabold"
          data-testid="org-button-text"
          ml="-24px"
          textTransform="uppercase"
        >
          {actionLabels?.label}
        </Text>
        <Box
          position="absolute"
          right="10px"
          sx={{
            '& > svg': {
              width: '24px',
              height: '24px',
            },
          }}
        >
          {actionLabels?.icon}
        </Box>
      </Flex>
    </Button>
  );
};

export default SubmitButton;
