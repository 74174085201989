import styled, { css } from 'styled-components';

export const Root = styled.div`
  position: relative;
  width: 305px;
`;

export const baseButtonMixin = css`
  background: none;
  border: none;
  padding: 0;
`;

export const ValueWrapper = styled.input`
  padding-left: 0px;
  padding-right: 32px;
  height: 32px;
  text-transform: capitalize;
  box-sizing: border-box;
  border: 1px solid #b6c1ce;
  line-height: 32px;
  font-size: ${(props: any) => props.fontSize || '14px'};
  &::placeholder {
    font-size: ${(props: any) => props.fontSize || '14px'};
  }
  &::placeholder {
    color: rgba(51, 51, 51, 0.6);
  }
`;

export const AutoCompleteIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  transition: all 150ms linear;
  transform: ${(props: any) => (props.isOpen ? 'rotate(0.5turn)' : 'none')};
  transform-origin: center;
  display: flex;

  svg {
    margin: auto;
  }

  ${ValueWrapper}:focus + & {
    color: ${(props: any) => props.color || '0063cc'};
    fill: ${(props: any) => props.fill || '0063cc'};
  }
`;

export const AutoCompleteContainer = styled.ul`
  background: #fff;
  padding: 8px 0;
  list-style-type: none;
  text-transform: capitalize;
  min-width: 305px;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 100%;
  left: 0;
  border: 1px solid #b6c1ce;
  border-radius: 8px;
  margin: 0;
  box-sizing: border-box;
  max-height: 180px;
  overflow-y: auto;
  z-index: 999;
`;

export const AutoCompleteItem = styled.li`
  padding: 5px 5px 5px 20px;
  border-radius:10px;
  font-size:14px;
  margin-left:20px
  box-sizing: border-box;
  &:hover {
    background-color: white;
    color: #24B0FF
  }
`;

export const AutoCompleteItemButton = styled.button`
  ${baseButtonMixin}
  line-height: 32px;
  text-align: right;
  text-transform: capitalize;
  &:active {
    outline: none;
    color: #0076f5;
  }
`;
export const Input = styled(ValueWrapper)<{
  fontSize?: string;
}>`
  min-width: 305px;
  outline: 0;

  border-width: 0 0 1px ;
  border-color: #24BOFF
  border-radius:10px
  &:hover{ border-bottom: 2px solid red; }

  &:focus {
    border-color: #24B0FF;
    outline: 2px dotted #24BOFF
    border-bottom: 1px solid red

    + ${AutoCompleteIcon} {
      color: ${(props: any) => props.color || '0063cc'};
      fill: ${(props: any) => props.fill || '0063cc'};
    }
  }
`;
