import {
  Box,
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { CircleCheckIcon, CircleCrossIcon, CircleRightDisabledIcon, CircleRightEnabledIcon } from 'assets/icons/icons';
import { customColor } from 'assets/styles/colors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getUsers } from 'redux/user/Actions/userActions';
import {
  fetchPostUserData,
  fetchUserCreateButtonEnableValue,
  fetchUserSuccessCreation,
  handleCreateUserModal,
  resetUserValidationValues,
} from 'redux/user/Reducers/userSlice';
import { Error_Type_Enum } from 'utils/enum';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CreateUserFields from '../createUserInputFields/CreateUserFields';
import {
  checkPhoneNumberValidation,
  getOrganizationOwnerPhoneValidation,
  resetOrganizationCreateActions,
} from 'redux/organizations/Reducers/organizationSlice';
import { ModalTimeOut } from 'utils/constants/Constants';

const CreateUsersModal = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const { onClose } = useDisclosure();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const openUsereModal = useAppSelector((state) => state.user.handleUserModalOpen);
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');

  const postData = useAppSelector((state) => state.user.postUserFormDataFail);
  const handleButton = useAppSelector((state) => state.user.userCreateButtonEnable);
  const successCreation = useAppSelector((state) => state.user.userSuccessCreation);
  const toggleSubmitButton = useAppSelector((state) => state.user.userCreateButtonEnableValue);
  const createUserApiFail = useAppSelector((state) => state.user.postUserApiFail);

  useEffect(() => {
    if (createUserApiFail.length > 0) {
      dispatch(fetchUserSuccessCreation(true));
    }
    if (createUserApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetUserValidationValues());
        dispatch(handleCreateUserModal(false));
        dispatch(resetOrganizationCreateActions());
        onClose();
      }, ModalTimeOut);
    }
  }, [createUserApiFail]);

  const handleDeviceModalClose = () => {
    dispatch(handleCreateUserModal(false));
    dispatch(resetUserValidationValues());
    dispatch(getOrganizationOwnerPhoneValidation(false));
    dispatch(checkPhoneNumberValidation(false));
  };
  const handleClick = () => {
    dispatch(fetchUserCreateButtonEnableValue(true));
    dispatch(fetchPostUserData(true));

    setTimeout(() => dispatch(getUsers('')), 3000);
    if (!openUsereModal) {
      dispatch(fetchUserSuccessCreation(false));
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openUsereModal}
      onClose={handleDeviceModalClose}
      border-radius="0.1px"
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '368px', smd: '700px', md: '700px', lg: '700px', xl: '700px', dt: '700px' }} mt="80px">
        <ModalHeader fontSize="24px" ml="8px">
          <FormattedMessage id="create_exo_pulse_user.message" />
        </ModalHeader>
        <ModalCloseButton
          disabled={!postData && toggleSubmitButton}
          color={closeIconColor}
          data-testid="close-ic"
          backgroundColor="transparent"
          _hover={{ backgroundColor: 'white' }}
        />
        <ModalBody ml="7px" backgroundColor="transparent">
          <CreateUserFields />
        </ModalBody>
        <ModalFooter>
          {postData ? (
            <Button
              variant="outline"
              type="button"
              width="195px"
              height="42px"
              borderRadius="20px"
              borderColor="#BAC5CD"
              color="#BAC5CD"
              disabled
              fontSize="17px"
              float="right"
              data-testid="org-button-disabled"
              _hover={{ cursor: 'not-allowed' }}
            >
              <Flex>
                <Text
                  fontSize="12px"
                  ml="50px"
                  display="flex"
                  alignItems="center"
                  fontWeight="extrabold"
                  data-testid="org-button-text"
                >
                  <FormattedMessage id="failure.message" />
                </Text>
                <Icon w="30px" h="30px" ml="32px" float="right" data-testid="org-button-icon">
                  <CircleCrossIcon />
                </Icon>
              </Flex>
            </Button>
          ) : (
            <Box>
              {handleButton ? (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  position="relative"
                  color={blueColorMode}
                  cursor={successCreation ? 'not-allowed' : ''}
                  fill={blueColorMode}
                  borderColor={blueColorMode}
                  fontSize="17px"
                  float="right"
                  data-testid="org-button-active"
                  _hover={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  onClick={handleClick}
                  _disabled={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  isDisabled={successCreation}
                >
                  <Flex>
                    <Box fontSize="10px" display="flex" alignItems="center" data-testid="org-button-text-diabled">
                      {successCreation ? (
                        <FormattedMessage id="done.message">
                          {(txt) => (
                            <Text fontSize="12px" fontWeight="extrabold">
                              {txt}
                            </Text>
                          )}
                        </FormattedMessage>
                      ) : (
                        <FormattedMessage id="add_modal.message">
                          {(txt) => (
                            <Text fontWeight="extrabold" fontSize="12px">
                              {txt}
                            </Text>
                          )}
                        </FormattedMessage>
                      )}
                    </Box>
                    {toggleSubmitButton ? (
                      <Box>
                        {successCreation ? (
                          <Icon
                            w="30px"
                            h="30px"
                            mt="-7.5px"
                            float="right"
                            data-testid="org-button-checkIcon"
                            position="absolute"
                            right="7px"
                          >
                            <CircleCheckIcon />
                          </Icon>
                        ) : (
                          <Box
                            pl="10px"
                            h="40px"
                            mt="-7.5px"
                            w="40px"
                            data-testid="org-button-spinner"
                            position="absolute"
                            right="7px"
                          >
                            <Spinner />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Icon w="30px" h="30px" mt="-7.5px" float="right" position="absolute" right="5px">
                        <CircleRightEnabledIcon />
                      </Icon>
                    )}
                  </Flex>
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  borderColor="#BAC5CD"
                  color="#BAC5CD"
                  disabled
                  fontSize="17px"
                  float="right"
                  position="relative"
                  data-testid="org-button-disabled"
                  _hover={{ cursor: 'not-allowed' }}
                >
                  <Flex>
                    <Text
                      fontSize="12px"
                      mr="10px"
                      display="flex"
                      alignItems="center"
                      fontWeight="extrabold"
                      data-testid="org-button-text"
                    >
                      <FormattedMessage id="add_modal.message" />
                    </Text>
                    <Icon
                      w="30px"
                      h="30px"
                      mt="-7.5px"
                      float="right"
                      data-testid="org-button-icon"
                      position="absolute"
                      right="7px"
                    >
                      <CircleRightDisabledIcon />
                    </Icon>
                  </Flex>
                </Button>
              )}
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateUsersModal;
