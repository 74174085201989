/* eslint-disable react/jsx-key */
import { Box, Heading, HStack } from '@chakra-ui/react';
import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Outlet } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import { userAccessConst } from 'utils/constants/userAccessConst';

const SettingsSubSayout = () => {
  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const routes = [
    {
      id: 1,
      pathname: `/settings/partners`,
      name: 'partner_access',
      hasAccess: userPermissions.settings?.includes(userAccessConst.setting.viewPartnerKeys),
    },
    // {
    //   id: 2,

    //   pathname: `/settings/apiKeys`,
    //   name: 'api_keys',
    // },
  ];

  return (
    <>
      <Box data-testid="org-info-container">
        <Box display="flex" h="90px" borderBottom="1px solid #3333331A" pt="10px" ml="13px">
          <div data-testid="org-info-breadcumb">
            <HStack spacing={2}>
              <Heading
                fontSize={{ xs: '22px', xl: '24px' }}
                ml="20px"
                p="20px"
                fontWeight="400"
                fontFamily="Helvetica"
                style={{ paddingLeft: '0px' }}
              >
                <FormattedMessage id="settings.message" />
              </Heading>
            </HStack>
          </div>
        </Box>
        <Box display="flex" h={`calc(100vh - 180px)`}>
          <Box borderRight="1px solid #3333331A">
            <OrganizationInfoSidebar routes={routes} />
          </Box>
          <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 430px)' : 'calc(100vw - 300px)' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SettingsSubSayout;
