import axios from 'axios';

export const BASE_URL = process.env.NX_EP_BASE_URL;

const baseURL: string | undefined = localStorage.getItem('exo_api_url') as string | undefined;
export const publicRequest = axios.create({
  baseURL: baseURL,
});

export const userRequest = (() => {
  if (localStorage.getItem('exo_sso')) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('exo_sso')}`;
  }
  return axios.create({
    baseURL: (localStorage.getItem('exo_api_url') + '/v1') as string | '',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8;',

      'X-Exo-Org-Id': localStorage.getItem('exo_org_id'),
      'X-Exo-User-Email': localStorage.getItem('exo_user_email'),
      'X-Exo-Platform': `browser; ${navigator.userAgent}`,
    },
  });
})();

// May be required for future v2 calls
// export const userRequestv2 = axios.create({
//   baseURL: (localStorage.getItem('exo_api_url') + '/v2') as string | '',
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json; charset=utf-8; application/x-www-form-urlencoded',
//   },
// });
