import React from 'react';
import ReactPaginate from 'react-paginate';
import { FormattedMessage } from 'react-intl';
import { PaginationTypes } from 'types/pagination-types';

function DesktopPaginate({
  data,
  handleDesktopPageClick,
  theme,
  pagination,
  selectedPage,
}: {
  data: any;
  handleDesktopPageClick: any;
  theme: any;
  pagination: PaginationTypes | null;
  selectedPage: number;
}) {
  return (
    <>
      {data && pagination && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={<FormattedMessage id="next.message" />}
          onPageChange={handleDesktopPageClick}
          pageRangeDisplayed={5}
          data-testid="desktop-pagination"
          forcePage={selectedPage - 1}
          pageCount={pagination.num_pages}
          marginPagesDisplayed={1}
          previousLabel={<FormattedMessage id="previous.message" />}
          containerClassName={`pageContainer ${theme === 'dark' ? 'dark' : ''}`}
          previousLinkClassName={`${pagination.current_page == 1 ? 'first-page' : ''}`}
          nextLinkClassName={`${pagination.current_page == pagination.num_pages ? 'last-page' : ''}`}
        />
      )}
    </>
  );
}

export default DesktopPaginate;
