import { UserActivity, UserActivityEvent, UserDetails } from '@exo/ew-data-models';
import { VersionInfo } from '../types/types';

export const getUserActivity = (
  activityType: UserActivityEvent,
  currentUser: UserDetails | undefined,
  versionInfoStr: string | undefined,
): UserActivity[] | [] => {
  if (!currentUser || !versionInfoStr) return [];
  const userActivity: UserActivity[] = [
    {
      activityType,
      activityDate: new Date(),
      userId: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      orgId: currentUser.currentOrgId,
      orgName: currentUser.currentOrgName,
      product: 'Exo Works',
      orgSpeciality: currentUser.specialty,
      orgFacility: currentUser.facility,
      appVersion: versionInfoStr,
      appDeviceOs: navigator.userAgent,
      appDeviceModel: navigator.platform,
    },
  ];
  return userActivity;
};

export const parseVersionFromTag = (): VersionInfo => {
  // Example:
  // _gnexgen-web-ui_brelease-2.1.0_v2.1.0_cbb44915c_rIR1
  // Format is _g{repo}_b{branch_name}_v{version}_c{short_commit_hash}_r{release}

  const ret: VersionInfo = {
    repo: 'nexgen-web-ui',
    branch: 'release/2.2.0',
    productVersion: '2.2.0',
    commit: process.env.NX_SERVICE_HEAD_GIT_COMMIT ? process.env.NX_SERVICE_HEAD_GIT_COMMIT?.slice(0, 7) : 'n/a',
    releaseName: 'Connect',
  };

  const versionTagStr: string | undefined = process.env.NX_SERVICE_GIT_TAG;
  if (!versionTagStr) return ret;
  const versionRegex = /_g(.*)_b(.*)_v(.*)_c(.*)_r(.*)/;
  const m: RegExpMatchArray | null = versionTagStr.match(versionRegex);

  if (m && m.length === 6) {
    ret.repo = m[1].toString();
    ret.branch = m[2].toString();
    ret.productVersion = m[3].toString();
    ret.commit = m[4].toString();
    ret.releaseName = m[5].toString();
  }

  return ret;
};
