import { Box } from '@chakra-ui/react';

import React from 'react';

import OrgDevicesActivityData from '../data/orgDevicesActivityData';

interface FilterProps {
  isFiltersApplied: boolean;
}
export default function OrgDevicesActivityMainContent({ isFiltersApplied }: FilterProps) {
  return (
    <Box h={`calc(100vh - 250px)`} data-testid="org-devices-activity">
      {' '}
      <OrgDevicesActivityData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
