/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';
import {
  OrganizationTypes,
  TierObject,
  OrgDevice,
  OrganizationDevicesActivityApiResponse,
  OrgDeviceActivity,
} from '../../../types/organization-types';
import { PaginationTypes } from 'types/pagination-types';
type InitialState = {
  searchText: string;
  organizationDevicesActivityList: OrgDeviceActivity[];
  statusLoading: boolean;
  statusError: boolean;
  organizationDevicesActivityListLoading: boolean;
  organizationsListError: boolean;
  createOrganizationApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  organizationDevicesApiData: OrganizationDevicesActivityApiResponse | null;
  orgDevicesActivitynumberOfPages: number;
  orgDevicesActivitypaginationData: PaginationTypes | null;
  SingleOrganizationDeviceDataLoading: boolean;
  SingleOrganizationDeviceDataError: boolean;
  SingleOrganizationsDeviceGetData: OrgDevice[];
  SingleOrganizationsDeviceGetDataLoading: boolean;
  SingleOrganizationsDeviceGetDataError: boolean;
  singleOrganizationID: string | number;
  isSearchExpand: boolean;
  deviceCreateFromOrg: boolean;
  selectedOrderValue: number;
};
export const initialState: InitialState = {
  searchText: '',
  organizationDevicesActivityList: [],
  statusLoading: false,
  statusError: false,
  organizationDevicesActivityListLoading: false,
  organizationsListError: false,
  selectedPage: 1,
  createOrganizationApiFail: '',
  organizationDevicesApiData: null,
  orgDevicesActivitynumberOfPages: 1,
  filterData: { status_id: [] },
  orgDevicesActivitypaginationData: null,
  SingleOrganizationDeviceDataLoading: false,
  SingleOrganizationDeviceDataError: false,
  SingleOrganizationsDeviceGetData: [],
  SingleOrganizationsDeviceGetDataLoading: false,
  SingleOrganizationsDeviceGetDataError: false,
  singleOrganizationID: '',
  isSearchExpand: false,
  deviceCreateFromOrg: false,
  selectedOrderValue: 0,
};
const orgDevicesActivitySlice = createSlice({
  name: 'organizationdeviceactivity',
  initialState,
  reducers: {
    getDeviceCreateFromOrg: (state, { payload }) => {
      state.deviceCreateFromOrg = payload;
    },
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchcreateOrganizationApiFail: (state, { payload }) => {
      state.createOrganizationApiFail = payload;
    },
    fetchOrganizationDeviceActivityLoading: (state) => {
      state.organizationDevicesActivityListLoading = true;
    },
    fetchOrganizationDeviceActivityFail: (state) => {
      state.organizationsListError = true;
      state.organizationDevicesActivityListLoading = false;
    },
    fetchOrganizationDeviceActivitySuccess: (state, { payload }) => {
      state.organizationDevicesActivityList = payload.data;
      state.organizationDevicesApiData = payload;
      state.orgDevicesActivitynumberOfPages = payload.pagination.num_pages;
      state.orgDevicesActivitypaginationData = payload.pagination;
      state.organizationDevicesActivityListLoading = false;
    },
    fetchSingleOrganizationID: (state, { payload }) => {
      state.singleOrganizationID = payload;
    },
    fetchSingleOrganizationDeviceDataLoading: (state) => {
      state.SingleOrganizationsDeviceGetDataLoading = true;
      state.SingleOrganizationsDeviceGetDataError = false;
    },
    fetchSingleOrganizationDeviceDataSuccess: (state, { payload }) => {
      state.SingleOrganizationsDeviceGetData = payload;
      state.SingleOrganizationsDeviceGetDataLoading = false;
      state.SingleOrganizationsDeviceGetDataError = false;
    },
    fetchSingleOrganizationDeviceDataFail: (state) => {
      state.SingleOrganizationsDeviceGetDataError = true;
      state.SingleOrganizationsDeviceGetDataLoading = false;
    },
    getDeviceActivityFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
  },
});
export const {
  getSearchText,
  clearSearcText,
  expandSearchInMobile,
  closeSearchInMobile,
  getPageNumber,
  fetchOrganizationDeviceActivityLoading,
  fetchOrganizationDeviceActivityFail,
  fetchOrganizationDeviceActivitySuccess,
  fetchcreateOrganizationApiFail,
  getFlterData,
  fetchSingleOrganizationDeviceDataLoading,
  fetchSingleOrganizationDeviceDataSuccess,
  fetchSingleOrganizationDeviceDataFail,
  fetchSingleOrganizationID,
  getDeviceCreateFromOrg,
  getDeviceActivityFilterValue,
} = orgDevicesActivitySlice.actions;
export default orgDevicesActivitySlice.reducer;
