import * as React from 'react';
import { Box, Text, Link, Heading, useColorModeValue } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import Layout from '../layout';
import { Url_Constants } from '../../utils/constants/urlConstants';
import { LoginForm, LoginImgComponent, SharedUiState, customColor } from '@exo/shared-ui';
import { useNavigate } from 'react-router-dom';
import { routePath } from '../../routes/routePaths';
import { userRequest } from 'api';
import { getAuthoToken } from 'redux/systemSettings/systemSettingSlice';
import { useAppDispatch } from 'redux/hooks';

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = customColor();
  const bg = useColorModeValue('white', '#394049');
  const currentYear = new Date().getFullYear();

  const handleLogin = (idToken: string | undefined) => {
    if (idToken) {
      dispatch(getAuthoToken(idToken));
      userRequest.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
      navigate(`/${routePath.Dashboard}`);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem('exo_sso') && location.pathname == '/') {
      navigate('/dashboard');
    }
  }, []);

  return (
    <Layout>
      <Box
        display="grid"
        width="100vw"
        backgroundColor={bg}
        gridTemplateColumns={{
          xs: '1fr',
          md: '1fr 1fr',
          lg: '2fr  1fr',
          xl: '1fr  1fr',
        }}
        overflowX="hidden"
        data-testid="login-test"
        columnGap="2px"
      >
        <Box
          minH="100vh"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          ml={{ xs: '7%', md: '12%' }}
        >
          <Box width="100%">
            <Box display="flex" fontWeight="400" mt={{ xs: '12%', md: '14%', lg: '6%' }}>
              <Heading fontSize={{ xs: '30px', md: '35px', xl: '40px' }} fontWeight="400" color={colors.textColor}>
                <FormattedMessage id="exo_name.message" />
              </Heading>
              <Heading fontSize={{ xs: '30px', md: '35px', xl: '40px' }} ml="3" color="common.100" fontWeight="400">
                <FormattedMessage id="pulse_name.message" />
              </Heading>
            </Box>
            <LoginForm
              handleLogin={handleLogin}
              baseUrl={process.env.NX_EP_LOGIN_API_BASE_URL}
              allowPasswordReset={false}
            />
          </Box>
          <Box mb={{ xs: '15%', lg: '10%', dt: '10%' }}>
            <Box display="flex">
              <Text fontSize={{ xs: '16px' }} color={colors.textColor} fontWeight="400">
                <FormattedMessage id="trouble_signing_in.message" />
              </Text>
              <Text as="u">
                <Link
                  ml="1"
                  textDecoration="inherit"
                  color={colors.primaryColor}
                  fontSize={{ xs: '16px' }}
                  fontWeight="400"
                  href={Url_Constants.Customer_Support}
                  target="_blank"
                >
                  <FormattedMessage id="contact_us.message" />
                </Link>
              </Text>
            </Box>
            <Text fontSize={{ xs: '12px' }} fontWeight={300} color={colors.textColor} opacity="0.5" mt="1">
              <FormattedMessage id="privacy_policy.message" />|{' '}
              <FormattedMessage id="exo_copyright.message" values={{ year: currentYear }} />
            </Text>
          </Box>
        </Box>
        <LoginImgComponent />
      </Box>
    </Layout>
  );
}

export default Login;
