/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useEffect } from 'react';
import { Box, Input, Wrap, WrapItem } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import { specialAlphaNumericValidation } from 'utils/validations/specialCharacterValidation';
import {
  fetchDeviceCreateButtonEnable,
  fetchDeviceIDNumberMinLength,
  fetchDevicePostToggle,
  fetchDeviceSuccessCreation,
  fetchPostDeviceData,
  fetchPostDeviceFormDataFail,
  getDeviceIDInputNumberValue,
  getDevicePostCreateButton,
} from 'redux/devices/Reducers/deviceSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { InputAutoComplete } from '../../../../../components/inputAutoComplete/InputAutoComplete';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';

const DeviceNameIdInputs = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const intl = useIntl();
  const orgID = useAppSelector((state) => state.device.getDeviceIDInputValue);
  const orgIdMinLength = useAppSelector((state) => state.device.getDeviceIDNumberLength);
  const orgNameSearch = useAppSelector((state) => state.device.orgNameSearchData);
  const orgNameValue = useAppSelector((state) => state.device.orgNameSearchValue);
  const postDataFail = useAppSelector((state) => state.device.postDeviceFormDataFail);
  const deviceCreateFromOrg = useAppSelector((state) => state.organizationdevice.deviceCreateFromOrg);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const getOrganizationNameSearchValue = useAppSelector((state) => state.device.getOrganizationNameSearchValue);

  const handleOrgIDNumber = (e: any) => {
    dispatch(getDeviceIDInputNumberValue(e.target.value));
    if (postDataFail) {
      dispatch(fetchDeviceCreateButtonEnable(false));
      dispatch(fetchPostDeviceData(false));
      dispatch(getDevicePostCreateButton(false));
      dispatch(fetchDeviceSuccessCreation(false));
      dispatch(fetchPostDeviceFormDataFail(false));
      dispatch(fetchDevicePostToggle(false));
    }
  };

  useEffect(() => {
    orgID;
  }, [orgIdMinLength]);

  const temp = orgNameSearch.map((item: any) => ({ name: item.org_name, code: item.id }));

  const handleIDChange = () => {
    if (orgID.length > 0 && orgID.length < 4) {
      dispatch(fetchDeviceIDNumberMinLength(true));
    } else if (orgID.length == 0) {
      dispatch(fetchDeviceIDNumberMinLength(false));
    } else {
      dispatch(fetchDeviceIDNumberMinLength(false));
    }
  };

  return (
    <>
      <Box minH="80px" alignItems="center" data-testid="device_name_field_container">
        {!deviceCreateFromOrg && (
          <Box mb="10px" width={{ xs: '85vw', smd: '89vw', md: '305px' }} data-testid="organization_name_label">
            <InputLabel name="organization_name" value={getOrganizationNameSearchValue} />
          </Box>
        )}
        {!deviceCreateFromOrg && (
          <Box mt="-5px">
            <InputAutoComplete
              inputStyle={{ position: 'fixed', backgroundColor: 'red', borderBlockColor: 'red' }}
              optionsStyle={{ zIndex: '999', backgroundColor: 'white' }}
              data={temp}
              orgName={''}
            />
          </Box>
        )}
      </Box>
      <Wrap float={!deviceCreateFromOrg ? 'right' : 'left'} mt={{ xs: '0px', smd: '0px', md: '-87px' }} spacingX="30px">
        <WrapItem>
          <Box minH="70px" float="right" style={{ top: '10px', position: 'relative' }}>
            <InputLabel name="order_id" value={orgID} />
            <Input
              color={colors.subTextColor}
              name="order id"
              mt="5px"
              fontSize="14px"
              _placeholder={{ fontSize: '14px' }}
              width={{ xs: '85vw', sm: '305px', smd: '89vw', md: '305px' }}
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              onChange={handleOrgIDNumber}
              placeholder={intl.formatMessage({ id: 'order_id.message' })}
              type="text"
              variant="flushed"
              maxLength={16}
              onBlur={handleIDChange}
              onKeyPress={specialAlphaNumericValidation}
              required
              data-testid="device-form-serial"
            />
            <Box fontSize="12px" color="#FB5654">
              {orgIdMinLength ? <FormattedMessage id="org_name_error.message" /> : ''}
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
      <Box mt="10px">{postDataFail ? <BannerMessage message={postApiFailureMessage} defaultMessage="" /> : ''}</Box>
    </>
  );
};
export default DeviceNameIdInputs;
