import { ChangeEvent, useState } from 'react';
import { Box, Checkbox, VStack, Link, Text, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export interface ICheckListItem {
  id: string | number;
  label: string;
  url?: string;
}

interface ICheckListProps {
  items: ICheckListItem[];
  onChange: (values: ICheckListItem[]) => void;
}

const Checklist = ({ items, onChange }: ICheckListProps) => {
  const [activeValues, setActiveValues] = useState<Array<string | number>>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    const newValues = activeValues.includes(val)
      ? activeValues.filter((i) => i !== val).sort()
      : [...activeValues, val].sort();
    setActiveValues([...newValues]);
    onChange(items.filter((i) => newValues.includes(i.id.toString()) || newValues.includes(i.id)));
  };

  return (
    <VStack mt="5px">
      {items?.map((i: ICheckListItem, index: number) => {
        return (
          <Flex
            key={i.id}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            backgroundColor="light.1950"
            borderRadius="10px"
            height="69px"
            padding="20px 15px"
          >
            <Box paddingLeft={{ sm: '5px' }}>
              {i.url && i.url.length > 0 ? (
                <Flex fontWeight={'400'} fontSize="16px" lineHeight="normal" letterSpacing="-0.24px" gap="5px">
                  <Text color="dark.1800">
                    <FormattedMessage id="agreement.message" />
                  </Text>
                  <Link color="brandBlue.350" href={i.url} isExternal>
                    {i.label}
                  </Link>
                </Flex>
              ) : (
                <Flex fontWeight={'400'} fontSize="16px" lineHeight="normal" letterSpacing="-0.24px" gap="5px">
                  <Text color="dark.1800">
                    <FormattedMessage id="agreement.message" />
                  </Text>
                  <Text color="brandBlue.350">{i.label}</Text>
                </Flex>
              )}
            </Box>
            <Checkbox variant="themeVioletBg" key={i.id} value={i.id} onChange={handleChange} aria-label={i.label} />
          </Flex>
        );
      })}
    </VStack>
  );
};

export default Checklist;
