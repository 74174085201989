import { Heading, Box, Text, FormLabel } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from '../../assets/styles/colors';
import { Email, Password } from '../../types/types';
import { SharedUiState } from '../../redux/hooks';
import { useSelector } from 'react-redux';

export function LoginTitleSection() {
  const colors = customColor();

  const isExoWorks = useSelector((state: SharedUiState) => state.login.isExoWorks);
  const orgs = useSelector((state: SharedUiState) => state.login.ownerOrganizations);
  return (
    <Box mt={isExoWorks ? '' : { xs: '10%', md: '30%', lg: '20%', xl: '20%' }} data-testid="form-Sign_text">
      {isExoWorks ? (
        orgs.length === 0 && (
          <Box w="306px" h="90px">
            <Heading
              fontSize={{ xs: '36px', md: '40px' }}
              fontWeight="325"
              color={colors.loginTextColor}
              lineHeight={{ xs: '52.92px', md: '58.8px' }}
            >
              <FormattedMessage id="login.message" />
            </Heading>
            <Text
              fontSize={{ xs: '16px', md: '18px' }}
              fontWeight="400"
              lineHeight={{ xs: '23.52px', md: '26px' }}
              color="light.1800"
              opacity="75%"
            >
              <FormattedMessage id="please_enter_your_valid_email.message" />
            </Text>
          </Box>
        )
      ) : (
        <Heading fontSize={{ xs: '28px', lg: '28px', xl: '32px' }} fontWeight="400" color={colors.textColor}>
          <FormattedMessage id="sign_in.message" />
        </Heading>
      )}
      {!isExoWorks && (
        <Text
          mt="1"
          fontSize={{ xs: '16px', lg: '16px', xl: '18px' }}
          fontWeight="300"
          color={colors.textColor}
          opacity="0.6"
        >
          <FormattedMessage id="please_enter_your_registered_email.message" />
        </Text>
      )}
    </Box>
  );
}

export function LoginEmailLabel(props: Email) {
  const colors = customColor();

  return (
    <Box
      minH="20px"
      data-testid="form-email-label"
      mt={{ xs: '10%', md: '16%', lg: '10%', xl: '8%' }}
      mb={{ xs: '3%', md: '5%', lg: '2%', xl: '2%' }}
    >
      {props.email && (
        <FormLabel
          color={colors.textColor}
          fontSize={{ xs: '16px', lg: '16px', xl: '17px' }}
          mb="0"
          fontWeight="300"
          pb="0"
        >
          <FormattedMessage id="email.message" />
        </FormLabel>
      )}
    </Box>
  );
}

export function LoginPasswordLabel(props: Password) {
  const colors = customColor();
  return (
    <Box minH="20px" mt={{ xs: '4%' }} mb={{ xs: '2%' }} data-testid="form-password-label">
      {props.password && (
        <FormLabel
          color={colors.textColor}
          opacity="0.6"
          fontSize={{ xs: '16px', lg: '16px', xl: '17px' }}
          mb="0"
          fontWeight="300"
          pb="0"
        >
          <FormattedMessage id="password.message" />
        </FormLabel>
      )}
    </Box>
  );
}
