/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { Dispatch } from 'react';
import { userRequest } from 'api';
import {
  fetchUserSuccess,
  fetchUsersLoading,
  fetchUsersFail,
  fetchUsersStatusLoading,
  fetchUserStatusSuccess,
  fetchUsersStatusFail,
  fetchSingleUserDataLoading,
  fetchSingleUserDataSuccess,
  fetchSingleUserDataFail,
  editSingleUserInfoDataLoading,
  editSingleUserInfoDataSuccess,
  fetchEditSingleUserInfoApiFail,
  fetchOrgUserPostApiFail,
  fetchOrgUserPostToggle,
  fetchPostOrgUserFormDataFail,
  fetchPostOrgUserFormDataLoading,
  fetchPostOrgUserFormDataSuccess,
  fetchUserAnalyticsLoading,
  fetchUserAnalyticsSucc,
  fetchUserAnalyticsFail,
} from '../Reducer/orgUserSlice';
import { Error_Type_Enum } from 'utils/enum';
import { setError } from '../../error/errorSlice';
import { OrgUserPostData } from 'types/user-types';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import {
  getClientPostDataFailStrings,
  getClientPostDataFail,
  getClientPostDataLoading,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import axios from 'axios';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getOrganisationInfoUsersData =
  (id: any, queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchUsersLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(`organizations/${id}/users/?${queryString}`);
      if (res && res.status === 200) {
        dispatch(fetchUserSuccess(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchUsersFail());
    }
  };

export const getOrgUsersStatus = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchUsersStatusLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/users/status`);
    if (res && res.status === 200) {
      // res.data.data

      dispatch(fetchUserStatusSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchUsersStatusFail());
  }
};

export const getSingleUserData = (id: any, userId: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchSingleUserDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/${id}/provisioning/user/${userId}/info/ `);
    if (res && res.status === 200) {
      dispatch(fetchSingleUserDataSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchSingleUserDataFail());
  }
};

export const editSingleUserInfo =
  (postData: any, id: any, userId: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(editSingleUserInfoDataLoading());

    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.put(`organizations/${id}/provisioning/user/${userId}/info/`, postData);
      if (res && (res.status === 200 || res.status === 201)) {
        dispatch(editSingleUserInfoDataSuccess(res.data.result));
        dispatch(fetchEditSingleUserInfoApiFail(Error_Type_Enum.Success));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchEditSingleUserInfoApiFail(Error_Type_Enum.Fail));
    }
  };
export const postOrgUsersData = (postData: OrgUserPostData) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchPostOrgUserFormDataLoading());

  try {
    const res = await userRequest.post(`organizations/users/add/`, postData);
    if (res && (res.data.status_code === 200 || res.data.status_code === 201)) {
      dispatch(fetchPostOrgUserFormDataSuccess(res.data.result));
      dispatch(fetchOrgUserPostApiFail(Error_Type_Enum.Success));
      dispatch(fetchPostOrgUserFormDataFail(false));
    } else {
      dispatch(fetchPostOrgUserFormDataFail(true));
    }
  } catch (error) {
    dispatch(fetchOrgUserPostToggle(true));
    dispatch(fetchPostOrgUserFormDataFail(true));
    dispatch(fetchOrgUserPostApiFail(Error_Type_Enum.Fail));
  }
};
export const postEmailResetPassword = (orgId: string, postData: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    const res = await userRequest.post(`organizations/${orgId}/users/reset_password/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
    } else {
      dispatch(getClientPostDataFail(res && res?.data));
      dispatch(handlePostApiFailureMessage(res.data));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));

      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};
export const postWelcomeEmail = (user_id: number | string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    const res = await userRequest.post(`organizations/org-users/welcome-email/?user_id=${user_id}`);
    if (res && res.data.status_code == null) {
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      console.log(true);
      dispatch(getClientPostDataLoading(false));
    } else if (res && res.data.status_code == 200) {
      dispatch(getClientPostDataFail(res && res?.data));
      dispatch(handlePostApiFailureMessage(res.data.message));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));

      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(handlePostApiFailureMessage(error.response.data.message));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};

export const getUserAnaltics = (id: string | undefined) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchUserAnalyticsLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/${id}/analytics/`);
    if (res && res.status === 200) {
      dispatch(fetchUserAnalyticsSucc(res.data ? res.data : []));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchUserAnalyticsFail());
  }
};
