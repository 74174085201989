import { BillingCode } from './Exam';
import { WorksheetTemplate } from './Worksheet';

export interface ExamTypeResponse {
  id: number;
  examType: ExamType;
  worksheetTemplate: WorksheetTemplate;
}
export interface ExamType {
  id: number;
  isEnabled: boolean;
  name: string;
  examSource: {
    facility: number;
    specialty: number;
  };
  billingCodes: BillingCode[];
  orderDescription: string;
  examWorksheet: number;
  qaWorksheet: number;
  examTypeCategory: string;
  worksheetTemplate?: WorksheetTemplate;
  uuid: string;
}

export enum CATEGORY {
  EXAM = 'exam',
  PROCEDURE = 'procedure',
}
