import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useLocation, useParams } from 'react-router-dom';

import { RootState } from 'redux/Store/store';
import OrgDevicesMainContent from './mainContent/OrgDevicesMain';
import DeviceTitle from 'pages/devices/components/deviceTitle/DeviceTitle';
import OrgDevicesFilter from './filters/OrgDevicesFilter';
import { getOrganizationDevices } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import { Error_Type_Enum } from 'utils/enum';
import { getDeviceStatuses, getDeviceTypes } from 'redux/devices/Actions/deviceActions';

import { getDevicePageNumber } from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import { userAccessConst } from 'utils/constants/userAccessConst';
import DeviceCreateModal from 'pages/devices/components/createDevice/DeviceCreateModal';

const OrgDevices = () => {
  interface OrgFilterPayload {
    page?: number;
    search?: string;
    id?: string[];
  }
  const initialRender = useRef({ primary: true, secondary: true });
  const location = useLocation();
  const { orgId } = useParams<string>();
  const dispatch = useAppDispatch();
  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const organizationsData = useAppSelector((state: RootState) => state.organizationdevice);
  const apiSucc = useAppSelector((state: RootState) => state.device.devicePostApiSuccess);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const getOrganizationDeviceData = () => {
    const data = [...organizationsData.filterData.status_id];
    const payload: OrgFilterPayload = {
      search: organizationsData.searchText,
      page: organizationsData.selectedPage,
      id: data.filter((itr) => itr !== 'All'),
    };
    const queryString = Object.entries(payload)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
    dispatch(getOrganizationDevices(orgId, queryString));
  };
  useEffect(() => {
    if (initialRender.current.primary) {
      initialRender.current.primary = false;
      return;
    }
    getOrganizationDeviceData();
  }, [organizationsData.selectedPage, organizationsData.createOrganizationApiFail == Error_Type_Enum.Success]);
  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getDevicePageNumber(1));
    getOrganizationDeviceData();
  }, [organizationsData.searchText, organizationsData.filterData]);

  useEffect(() => {
    if (apiSucc && location.pathname.includes('organizations')) {
      getOrganizationDeviceData();
    }
  }, [apiSucc]);
  const filters = organizationsData.filterData;
  const search = organizationsData.searchText;
  const isFiltersApplied = !!((filters && filters.status_id && filters.status_id.length) || search);

  useEffect(() => {
    dispatch(getDeviceStatuses());
    dispatch(getDeviceTypes());
    return () => {
      dispatch(getDevicePageNumber(1));
    };
  }, []);

  return (
    <>
      <Box>
        {singleOrganizationData && (
          <Box display="block" overflowX="scroll" overflowY="hidden" width="100%">
            <Box display="flex" h="80px" flexDirection="row" pt="20px" pl="18px" justifyContent="space-between">
              <Box
                display="flex"
                flexDirection={{ xs: 'column', lg: 'row' }}
                justifyContent={{ xs: 'none', lg: 'space-between' }}
                transition="0.5s ease"
              >
                <DeviceTitle showAddBtn={userPermissions.devices?.includes(userAccessConst.device.addDevice)} />
              </Box>
              <Box>
                <OrgDevicesFilter isFiltersApplied={isFiltersApplied} />
              </Box>
            </Box>
            <Box h={`calc(100vh - 250px)`} overflowY="hidden">
              <OrgDevicesMainContent isFiltersApplied={isFiltersApplied} />{' '}
            </Box>{' '}
          </Box>
        )}
        <DeviceCreateModal />
      </Box>
    </>
  );
};
export default OrgDevices;
