/* eslint-disable @typescript-eslint/no-explicit-any */
import { WrapItem, Input, Box, Wrap, Text } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getUserEmailValidation } from 'redux/user/Actions/userActions';

import {
  fetchPostUserData,
  fetchPostUserFormDataFail,
  fetchUserCreateButtonEnableValue,
  fetchUserEmail,
  fetchUserEmailFail,
  fetchUserEmailFieldValidation,
  fetchUserPhoneNumber,
  fetchUserPostApiFail,
  fetchUserPostToggle,
  fetchUserSuccessCreation,
  getUserCreateButtonEnable,
  handleUserEmailValidFormat,
} from 'redux/user/Reducers/userSlice';
import { UserEmailvalidation } from 'types/user-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import validator from 'validator';

import PhoneDropdown from 'components/phoneDropdown/phoneDropdown';
import {
  getOrganizationOwnerPhoneInputValue,
  getOrganizationOwnerPhoneValidation,
} from 'redux/organizations/Reducers/organizationSlice';
import { specialEmailValidation } from 'utils/validations/specialCharacterValidation';

const UserEmailPhoneInputFields = () => {
  const colors = customColor();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [inputValues, setInputValues] = useState<{ [x: string]: string }>();

  const userEmailValue = useAppSelector((state) => state.user.userEmail);
  const userEmailField = useAppSelector((state) => state.user.userEmailFieldValidation);
  const userEmailValidation = useAppSelector((state) => state.user.userEmailValidFormat);
  const getUserEmailFail = useAppSelector((state) => state.user.getUserEmailFail);
  const postDataFail = useAppSelector((state) => state.user.postUserFormDataFail);

  const handleUserPhoneValue = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputValues((prevState: any) => ({ ...prevState, [name]: value }));
    dispatch(fetchUserPhoneNumber(value));
    dispatch(getOrganizationOwnerPhoneValidation(false));
    dispatch(getOrganizationOwnerPhoneInputValue(value));
    if (postDataFail) {
      dispatch(fetchUserCreateButtonEnableValue(false));
      dispatch(fetchPostUserData(false));
      dispatch(fetchUserPostToggle(false));
      dispatch(fetchPostUserFormDataFail(false));
      dispatch(getUserCreateButtonEnable(false));
      dispatch(fetchUserSuccessCreation(false));
      dispatch(fetchUserEmailFail(false));
      dispatch(fetchUserPostApiFail(''));
    }
  };

  const PostUserEmailValidation: UserEmailvalidation = {
    field_name: 'email',
    field_value: userEmailValue,
  };

  const handleEmailValue = (e: any) => {
    dispatch(fetchUserEmail(e.target.value.replace(/[^a-zA-Z0-9.@+-]/g, '')));

    if (postDataFail) {
      dispatch(fetchUserCreateButtonEnableValue(false));
      dispatch(fetchPostUserData(false));
      dispatch(fetchUserPostToggle(false));
      dispatch(fetchPostUserFormDataFail(false));
      dispatch(getUserCreateButtonEnable(false));
      dispatch(fetchUserSuccessCreation(false));
      dispatch(fetchUserPostApiFail(''));
    }
  };

  const handleEmailFormat = () => {
    const email = userEmailValue;
    const plusSignCount = email.split('+').length - 1;
    if (!/^[a-zA-Z0-9.@+-]*$/g.test(email) || plusSignCount > 1) {
      dispatch(handleUserEmailValidFormat(true));
      dispatch(fetchUserEmailFieldValidation(false));
      return;
    }
    if (userEmailValue.length > 0 && !validator.isEmail(email)) {
      dispatch(handleUserEmailValidFormat(true));
      dispatch(fetchUserEmailFieldValidation(false));
    } else if (userEmailValue.length === 0) {
      dispatch(fetchUserEmailFieldValidation(true));
      dispatch(handleUserEmailValidFormat(false));
    } else if (userEmailValue.length > 3) {
      dispatch(getUserEmailValidation(PostUserEmailValidation));
      dispatch(fetchUserEmailFieldValidation(false));
      dispatch(handleUserEmailValidFormat(false));
    } else {
      dispatch(fetchUserEmailFieldValidation(false));
      dispatch(handleUserEmailValidFormat(false));
    }
  };

  return (
    <Box pt="10px" ml="5px">
      <Wrap align="center" justify="first_name" spacingX="30px">
        <WrapItem>
          <PhoneDropdown inputValues={inputValues} setInputValues={setInputValues} onChange={handleUserPhoneValue} />
        </WrapItem>
        <WrapItem>
          <Box minH="70px">
            <InputLabel value={userEmailValue} name="email" />
            <Input
              color={colors.subTextColor}
              name="owner_email"
              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              onChange={handleEmailValue}
              onBlur={handleEmailFormat}
              placeholder={intl.formatMessage({ id: 'email.message' })}
              type="text"
              onKeyPress={specialEmailValidation}
              maxLength={50}
              variant="flushed"
              fontSize="14px"
              _placeholder={{ fontSize: '14px' }}
              required
              data-testid="orgForm-email"
            />
            <Text fontSize="12px" color="#FB5654">
              {userEmailField ? <FormattedMessage id="field_validation.message" /> : ''}

              {getUserEmailFail && !userEmailValidation ? <FormattedMessage id="email_already_exits.message" /> : ''}
            </Text>
            <Text fontSize="12px" color="#FB5654">
              {userEmailValidation ? <FormattedMessage id="valid_email_format.message" /> : ''}
            </Text>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default UserEmailPhoneInputFields;
