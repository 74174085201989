import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';

import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';

import SingleUserActivityListView from './SingleUserActivityListView';
import { getSingleUserActivityPageNumber } from 'redux/singleUserActivity/Reducers/singleUserActivitySlice';
import SingleUserActivityLandingScreen from './SingleUserActivityLandingScreen';

function SingleUserActivityData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const singleUserActivityData = useAppSelector((state: RootState) => state.singleUserActivity.singleUserActivityData);
  const numberOfPages = useAppSelector((state: RootState) => state.singleUserActivity.singleUserActivityNumberOfPages);
  const paginationData = useAppSelector(
    (state: RootState) => state.singleUserActivity.singleUserActivityPaginationData,
  );
  const isLoading = useAppSelector((state: RootState) => state.singleUserActivity.singleUserActivityDataLoading);
  const selectedPage = useAppSelector((state: RootState) => state.singleUserActivity.singleUserActivitySelectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const handleMobilePageClick = (item: number) => {
    dispatch(getSingleUserActivityPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getSingleUserActivityPageNumber(data.selected + 1));
  };

  return (
    <Box h={`calc(100vh - 250px)`} overflowY="hidden" data-testid="singleUserActivityData-container">
      <Box h={`calc(100vh - 300px)`} pl="18px" overflowY="auto">
        {singleUserActivityData && singleUserActivityData.length > 0 && !isLoading ? (
          <SingleUserActivityListView />
        ) : (
          <SingleUserActivityLandingScreen isFiltersApplied={isFiltersApplied} />
        )}
      </Box>
      {singleUserActivityData && singleUserActivityData.length > 0 && (
        <Box
          display="flex"
          h="50px"
          borderTop="1px solid var(--chakra-colors-chakra-border-color)"
          pl="18px"
          justifyContent="space-between"
          alignItems="center"
        >
          <TableInformation selectedPage={selectedPage} data={singleUserActivityData} pagination={paginationData} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={singleUserActivityData && singleUserActivityData}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={paginationData}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={singleUserActivityData && singleUserActivityData}
              selectedPage={selectedPage}
              numberOfPages={numberOfPages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default SingleUserActivityData;
