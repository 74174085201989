/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';
import axios from 'axios';
import { Dispatch } from 'react';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import {
  fetchOrganizationDeviceLoading,
  fetchOrganizationDeviceSuccess,
  fetchOrganizationDeviceFail,
  fetchSingleDeviceDataLoading,
  fetchSingleDeviceDataSuccess,
  fetchSingleDeviceDataFail,
  fetchOrderIdLoading,
  fetchOrderIdData,
  fetchOrderIdDataSuccess,
  fetchOrderIdFail,
  fetchOrderIdStatus,
  editDeviceInfoDataLoading,
  editDeviceInfoDataSuccess,
  fetchEditDeviceInfoApiFail,
  editDeviceInfoDataFail,
} from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import {
  getClientPostDataFailStrings,
  getClientPostDataFail,
  getClientPostDataLoading,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { OrderId } from 'types/device-types';
import { Error_Type_Enum } from 'utils/enum';
import { setError } from '../../error/errorSlice';
export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getOrganizationDevices = (id: any, queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrganizationDeviceLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/${id}/devices/?${queryString}`);
    if (res && res.status === 200) {
      dispatch(fetchOrganizationDeviceSuccess(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchOrganizationDeviceFail());
  }
};

export const getSingleDevicesData = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchSingleDeviceDataLoading());

  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`devices/${id}`);
    if (res && res.status === 200) {
      dispatch(fetchSingleDeviceDataSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchSingleDeviceDataFail());
  }
};

export const getOrderIdData = (postOrderId: OrderId) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrderIdLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`organizations/orgname/`, postOrderId);
    if (res && res.data.status_code === 404) {
      dispatch(fetchOrderIdData(postOrderId.order_id));
      dispatch(fetchOrderIdDataSuccess(res.data.status));
      dispatch(fetchOrderIdFail(false));
    } else if (res && res.data.status_code === 200) {
      dispatch(fetchOrderIdFail(true));
      dispatch(fetchOrderIdStatus(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

export const editDeviceInfoData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(editDeviceInfoDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`devices/${id}/`, postData);
    if (res && (res.status === 200 || res.status === 201)) {
      dispatch(editDeviceInfoDataSuccess(res.data.result));
      dispatch(fetchEditDeviceInfoApiFail(Error_Type_Enum.Success));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    // dispatch(fetchOrganizationPostToggle(true));
    dispatch(fetchEditDeviceInfoApiFail(Error_Type_Enum.Fail));
    dispatch(editDeviceInfoDataFail());
  }
};
export const postUnAssignDevices =
  (postData: any, orgId: any, deviceID: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`organizations/${orgId}/devices/${deviceID}/`, postData);
      if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
        dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
        dispatch(getClientPostDataFail(false));
        dispatch(getClientPostDataLoading(false));
      } else {
        dispatch(getClientPostDataFail(res && res?.data));
        dispatch(handlePostApiFailureMessage(res.data));
        dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));

        dispatch(getClientPostDataLoading(false));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(getClientPostDataFail(true));
      dispatch(handlePostApiFailureMessage(error.response.data.message));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
    }
  };
