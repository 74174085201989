import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import getAuthConfig from '../utils/getAuthConfig';

type Auth0ProviderWrapperProps = {
  children: React.ReactNode;
};

export const Auth0ProviderWrapper = ({ children }: Auth0ProviderWrapperProps) => {
  const authConfig = getAuthConfig();
  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      authorizationParams={{
        connection: authConfig.connection,
        redirect_uri: authConfig.redirectURI,
        scope: 'openid profile email',
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWrapper;
