import { Box, useMediaQuery } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { RootState } from 'redux/Store/store';

import React from 'react';
import OrganizationsListView from '../../table/OrganizationsListView';
import OrganizationGridView from './GridView/OrganizationsGridView';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import { getPageNumber } from 'redux/organizations/Reducers/organizationSlice';
import OrganizationLandingScreen from '../OrganizationLandingScreen/OrganizationLandingScreen';
function OrganizationData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();

  const [isMobileDevice] = useMediaQuery('(max-width: 719px)');
  const organizationsGridView = useAppSelector((state: RootState) => state.organization.organizationsGridView);
  const organizationsData = useAppSelector((state: RootState) => state.organization.organizationsList);
  const numberOfPages = useAppSelector((state: RootState) => state.organization.numberOfPages);
  const paginationData = useAppSelector((state: RootState) => state.organization.paginationData);
  const selectedPage = useAppSelector((state: RootState) => state.organization.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isDataLoading = useAppSelector((state: RootState) => state.organization.organizationsListLoading);

  const handleMobilePageClick = (item: any) => {
    dispatch(getPageNumber(item));
  };
  const handleDesktopPageClick = (data: any) => {
    dispatch(getPageNumber(data.selected + 1));
  };
  return (
    <Box position="relative" h={'100%'}>
      {organizationsData && organizationsData.length > 0 && !isDataLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          pr="20px"
          data-testid="org-data"
          h={'calc(100% - 185px)'}
          overflowY="scroll"
          ml="-15px"
        >
          {organizationsGridView || isMobileDevice ? <OrganizationGridView /> : <OrganizationsListView />}
        </Box>
      ) : (
        <OrganizationLandingScreen isFiltersApplied={isFiltersApplied} />
      )}
      {organizationsData && organizationsData.length > 0 && (
        <Box
          w="100%"
          display="flex"
          h="100px"
          pr="10px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          bottom="105px"
        >
          <TableInformation selectedPage={selectedPage} data={organizationsData} pagination={paginationData} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={organizationsData && organizationsData}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={paginationData}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={organizationsData && organizationsData}
              selectedPage={selectedPage}
              numberOfPages={numberOfPages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default OrganizationData;
