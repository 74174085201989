import React from 'react';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/Store/store';
import Wrapper from './translations/wrapper';
import './styles.scss';
import { ColorModeScript } from '@chakra-ui/react';
import theme from './assets/styles/theme';
import { Auth0ProviderWrapper } from '@exo/shared-ui';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <Wrapper>
    <Auth0ProviderWrapper>
      <Provider store={store}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />

        <App />
      </Provider>
    </Auth0ProviderWrapper>
  </Wrapper>,
);
