import React from 'react';
import { Text, Flex, Box, useColorModeValue, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { AddIcon } from 'assets/icons/icons';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleCreateUserModal } from 'redux/user/Reducers/userSlice';
import CreateUsersModal from '../createUserModal/CreateUserModal';
import { userAccessConst } from 'utils/constants/userAccessConst';

export default function UserTitle() {
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const colors = customColor();
  const handleUserModal = () => {
    dispatch(handleCreateUserModal(true));
  };
  return (
    <Flex alignItems="center" h="50px" ml="13px">
      <Text mr={{ xs: '3' }} fontSize={{ xs: '22px', xl: '24px' }} color={colors.textColor} data-testid="devices">
        <FormattedMessage id="pulse_users.message" />
      </Text>
      {userPermissions.users?.includes(userAccessConst.user.addUser) && (
        <Box>
          <Flex alignItems="center">
            <Icon
              data-testid="plus-icon"
              w="24px"
              h="24px"
              onClick={handleUserModal}
              color={useColorModeValue('white', 'black')}
              fill={useColorModeValue('white', 'transparent')}
              _hover={{
                color: 'url("#BlueGradient")',
                cursor: 'pointer',
                stroke: 'white',
                fill: 'url("#BlueGradient")',
                '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                '&:hover>svg>circle': { stroke: useColorModeValue('white', 'none') },
              }}
            >
              <AddIcon stroke={primarblackAndWhiteColorMode} />
            </Icon>
          </Flex>
          <CreateUsersModal />
        </Box>
      )}
    </Flex>
  );
}
