/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Wrap,
  WrapItem,
  Icon,
  Circle,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import CustomInputField from 'components/customInputField/CustomInputField';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';
import {
  handleBundleStatusLengthValidation,
  handleCreateSoftwareBundleNameInput,
  handleCreateSoftwareBundleStatusInputValue,
  handleSoftwareBundleNameLengthValidation,
} from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { postUniqueNameBundleValue } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import StatusInfo from 'components/status/StatusInfo';

const SoftwareBundleNameStatusInputs = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const [timer, setTimer] = useState<any>(null);
  const [bundleStatusValue, setBundleStatusValue] = useState<any>(1);
  const bundleStatusData = useAppSelector((state: any) => state.softwareBundles.getSoftwareBundleStatusData);
  const bundleNameValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleNameInputValue);
  const postuniqueNameValidation = useAppSelector((state) => state.softwareBundles.postuniqueNameValidation);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const getCreateBundleApiDataFail = useAppSelector((state) => state.softwareBundles.getCreateBundleApiDataFail);
  const bundleStatusLength = useAppSelector((state) => state.softwareBundles.bundleStatusLengthValidation);
  const softwareBundleNameLengthValidation = useAppSelector(
    (state) => state.softwareBundles.softwareBundleNameLengthValidation,
  );
  // const handleBundleName = (e: any) => {
  //   dispatch(handleCreateSoftwareBundleNameInput(e.target.value));
  // };
  const handleBundleStatus = (id: string) => {
    setBundleStatusValue(Number(id));
    dispatch(handleCreateSoftwareBundleStatusInputValue(id.toString()));
  };
  const handleBundleName = (e: any) => {
    const bundleName = e.target.value;
    dispatch(handleCreateSoftwareBundleNameInput(e.target.value));
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      dispatch(
        postUniqueNameBundleValue({
          field_name: 'name',
          field_value: e.target.value,
        }),
      );
    }, 500);
    setTimer(newTimer);
    if (bundleName.length == 0) {
      dispatch(handleSoftwareBundleNameLengthValidation(true));
    } else {
      dispatch(handleSoftwareBundleNameLengthValidation(false));
    }
  };
  const handleBundleNameOnBlur = () => {
    if (bundleNameValue.length == 0) {
      dispatch(handleSoftwareBundleNameLengthValidation(true));
    } else {
      dispatch(handleSoftwareBundleNameLengthValidation(false));
    }
  };
  const handleStatusOnBlur = () => {
    if (!bundleStatusValue) {
      dispatch(handleBundleStatusLengthValidation(true));
    } else {
      dispatch(handleBundleStatusLengthValidation(false));
    }
  };
  const status = !!bundleStatusData.length && bundleStatusData.find((item: any) => item.id == bundleStatusValue);
  return (
    <Box>
      <Box mt="-10px">
        {getCreateBundleApiDataFail ? <BannerMessage message={postApiFailureMessage} defaultMessage={''} /> : ''}
      </Box>
      <Wrap spacing="30px" mt="5px">
        <Box display="flex" flexDirection="column" minH="70px">
          <InputLabel value={bundleNameValue} name={'name'} />
          <WrapItem>
            <Box>
              <CustomInputField
                defaultValue={''}
                name={'name'}
                onKeyPress={specialCharacterValidation}
                maxLength={100}
                handleOnChange={handleBundleName}
                handleOnBlur={handleBundleNameOnBlur}
                isEditable={true}
              />
              <Text fontSize="12px" color="#FB5654" mt="-40px">
                {postuniqueNameValidation ? <FormattedMessage id="bundle_name_validation.message" /> : ''}
                {softwareBundleNameLengthValidation && !postuniqueNameValidation ? (
                  <FormattedMessage id="field_validation.message" />
                ) : (
                  ''
                )}
              </Text>
            </Box>
          </WrapItem>
        </Box>
        <WrapItem>
          <Box minH="90px">
            <InputLabel value={bundleStatusValue} name="status" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {bundleStatusValue ? (
                      <Box display="flex" ml="-15px" fontSize="14px" fontWeight="400">
                        <StatusInfo indicatorSize="6px" indicatorColor={status?.color_code} label={status?.status} />
                      </Box>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="status.message" />
                      </Text>
                    )}
                  </MenuButton>

                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {bundleStatusData &&
                      bundleStatusData.map((state: any) => (
                        <MenuItem
                          id={state.id}
                          key={state.id}
                          value={state.id}
                          name={state.status}
                          onBlur={handleStatusOnBlur}
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={() => handleBundleStatus(state.id)}
                        >
                          <Box display="flex">
                            <StatusInfo indicatorSize="6px" indicatorColor={state.color_code} label={state.status} />
                          </Box>
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default SoftwareBundleNameStatusInputs;
