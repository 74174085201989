import { Day } from '@hassanmojab/react-modern-calendar-datepicker';
import * as Yup from 'yup';

export const initialValues = {
  orderID: '',
  availabilityDate: {} as Day,
  expirationDate: {} as Day,
  addOn: {
    catalogNumber: '',
  },
};

export const OrgAddOnsSchema = () => {
  return Yup.object({
    orderID: Yup.string().required('This field is required'),
    availabilityDate: Yup.object(),
    expirationDate: Yup.object(),
    addOn: Yup.object(),
  })
    .test('availabilityDate', 'availabilityDate required field validation', function (values) {
      const { availabilityDate, expirationDate } = values;
      if (!availabilityDate?.day) {
        return this.createError({
          path: 'availabilityDate',
          message: 'This field is required',
        });
      }
      return true;
    })
    .test('expirationDate', 'expirationDate required field validation', function (values) {
      const { expirationDate } = values;
      if (!expirationDate?.day) {
        return this.createError({
          path: 'expirationDate',
          message: 'This field is required',
        });
      }
      return true;
    })
    .test('addOn', 'addOn required field validation', function (values) {
      const { addOn } = values;
      if (!addOn?.catalogNumber) {
        return this.createError({
          path: 'addOn',
          message: 'This field is required',
        });
      }
      return true;
    });
};
