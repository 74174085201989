import { Box, useDisclosure } from '@chakra-ui/react';
import CustomModal from 'components/customModal/CustomModal';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClientAccess } from 'redux/settings/clientAccess/Actions/clientAccessActions';
import {
  handleClientPostDataCreateButtonEnable,
  handleClientSuccessfulPost,
  handlePostApiDataSpinner,
  handleSettingsModalPopUp,
  handleToPostApiData,
  resetCreateSettingsPopUpValues,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { Error_Type_Enum } from 'utils/enum';
import CreateClientAccessAllInputFields from '../createDeveloperSettingsInputFields/CreateClientAccessAllInputFields';

const DeveloperSettingsModal = () => {
  const dispatch = useAppDispatch();
  const { onClose } = useDisclosure();
  const postDataSuccessfulCreation = useAppSelector((state) => state.clientAccess.enableSuccessfulPostData);
  const enableCreateButton = useAppSelector((state) => state.clientAccess.clientPostDataCreateButtonEnable);
  const handleSettingsModal = useAppSelector((state) => state.clientAccess.settingsModalPopUp);
  const createClientPostApiFail = useAppSelector((state) => state.clientAccess.clientPostDataFailStrings);
  const postFailure = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const toggleClientButtonEnableSpinnerValue = useAppSelector((state) => state.clientAccess.postClientApiDataSpinner);

  useEffect(() => {
    if (createClientPostApiFail.length > 0) {
      dispatch(handleClientSuccessfulPost(true));
    }
    if (createClientPostApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetCreateSettingsPopUpValues());
        dispatch(handleClientPostDataCreateButtonEnable(false));
        onClose();
      }, ModalTimeOut);
    }
  }, [createClientPostApiFail]);

  const handleModalClose = () => {
    dispatch(handleSettingsModalPopUp(false));
    dispatch(resetCreateSettingsPopUpValues());
  };
  const handleCreateButtonClick = () => {
    dispatch(handleToPostApiData(true));
    dispatch(handlePostApiDataSpinner(true));

    if (!handleSettingsModal) {
      dispatch(handleClientSuccessfulPost(false));
    }
  };

  return (
    <Box>
      <CustomModal
        openModal={handleSettingsModal}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createClientPostApiFail && toggleClientButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={enableCreateButton}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleClientButtonEnableSpinnerValue}
        InputFieldsComponent={CreateClientAccessAllInputFields}
        modalHeader="add_partner_access"
        modalButtonName="add_modal"
      />
    </Box>
  );
};

export default DeveloperSettingsModal;
