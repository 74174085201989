/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';
import { SoftwareBundle } from 'types/SoftwareBundle';
import { Addon, AddonsData } from 'types/add-on';
import { Feature, FeatureGroup } from 'types/feature';
import { PaginationTypes } from 'types/pagination-types';

import { EditBundleApi } from 'types/software-types';

type InitialState = {
  handleSoftwareBundleModal: boolean;
  getSoftwareBundleNameInputValue: string;
  getSoftwareBundleStatusInputvalue: string;
  getSoftwareBundleCatalogueInputValue: any;
  getSoftwareBundleOrganizationTierValue: string | number;
  getSoftwareBundleExpirationPeriodValue: string | number;
  getSoftwareBundleDescriptionValue: string;
  getSoftwareBundleStatusData: any[];
  getSoftwareBundleStatusApiFail: boolean;
  getBundleCreateButtonEnable: boolean;
  getCreateBundleApiPostDataSuccess: any[];
  getCreateBundleApiDataFail: boolean;
  getCreateBundleApiDataFailConstants: string;
  getCreateBundleApiDataToggle: boolean;
  getCreateBundlePostApiDataLoading: boolean;
  fetchPostBundleDataApi: boolean;
  fetchPostBundleApiSuccessCreation: boolean;
  toggleModalButtonEnableSpinnerValue: boolean;
  bundleEditScreenIDValue: string | number;
  singleBundleEditData: EditBundleApi[] | null;
  singleBundleEditDataFail: boolean;
  toggleBundleEditScreen: boolean;
  getBundleEditDatePickerDate: string | number;
  getBundleEditDatePickerDay: string | number;
  getBundleEditDatePickerYear: string | number;
  softwareBundles: SoftwareBundle[];
  getBundleDataApiFail: boolean;
  editBundlePostApiFail: string;
  editBundlePostApiSuccess: any[];
  selectedPage: number;
  SoftwareBundlenumberOfPages: number;
  SoftwareBundlepaginationData: PaginationTypes | null;
  bundleGracePeriodData: any[];
  bundleGracePeriodDataFail: boolean;
  bundleNameSCValidation: boolean;
  catalogueSCValidation: boolean;
  editPostBundleDataLoading: boolean;
  editBundleName: string;
  postuniqueNameValidation: boolean;
  postuniqueCatalogueValidation: boolean;
  handleBundleApiDataLoading: boolean;
  softwareBundleNameLengthValidation: boolean;
  softwareBundleCatalogueLengthValidation: boolean;
  bundleStatusLengthValidation: boolean;
  bundleOraganizationTierLengthValidation: boolean;
  isAddonsLoading: boolean;
  addonsData: AddonsData | null;
  unlinkedAddonsData: AddonsData | null;
  getBundleSearch: string;
  uniqueValueLoading: boolean;
  addonData: Addon | null;
  isAddonLoading: boolean;
  isAddAddonModalOpen: boolean;
  isCreateAddonPending: boolean;
  createAddonStatus: any;
  createAddonErrorMessage: string;
  isFeaturesLoading: boolean;
  featureGroups: FeatureGroup[];
  toggleFeaturesModal: boolean;
  isPostFeaturesFail: boolean;
  isPostFeaturesLoading: boolean;
  isPostFeaturesSucccess: boolean;
  postFeatureFailMessage: string;
  isFeatureLoading: boolean;
  featureData: Feature | null;
  featureAvailablitySuccess: boolean;
  featureAvailablityFail: boolean;
  featureAvailablityLoading: boolean;
};

export const initialState: InitialState = {
  handleSoftwareBundleModal: false,
  getSoftwareBundleNameInputValue: '',
  getSoftwareBundleStatusInputvalue: '',
  getSoftwareBundleCatalogueInputValue: '',
  getSoftwareBundleOrganizationTierValue: '',
  getSoftwareBundleExpirationPeriodValue: '',
  getSoftwareBundleDescriptionValue: '',
  getSoftwareBundleStatusData: [],
  getSoftwareBundleStatusApiFail: false,
  getBundleCreateButtonEnable: false,
  getCreateBundleApiPostDataSuccess: [],
  getCreateBundleApiDataFail: false,
  getCreateBundleApiDataFailConstants: '',
  getCreateBundleApiDataToggle: false,
  getCreateBundlePostApiDataLoading: false,
  fetchPostBundleDataApi: false,
  fetchPostBundleApiSuccessCreation: false,
  toggleModalButtonEnableSpinnerValue: false,
  bundleEditScreenIDValue: '',
  singleBundleEditData: null,
  singleBundleEditDataFail: false,
  toggleBundleEditScreen: false,
  getBundleEditDatePickerDate: '',
  getBundleEditDatePickerDay: '',
  getBundleEditDatePickerYear: '',
  softwareBundles: [],
  getBundleDataApiFail: false,
  editBundlePostApiFail: '',
  editBundlePostApiSuccess: [],
  selectedPage: 1,
  SoftwareBundlenumberOfPages: 1,
  SoftwareBundlepaginationData: null,
  bundleGracePeriodData: [],
  bundleGracePeriodDataFail: false,
  bundleNameSCValidation: false,
  catalogueSCValidation: false,
  editPostBundleDataLoading: false,
  editBundleName: '',
  postuniqueNameValidation: false,
  postuniqueCatalogueValidation: false,
  handleBundleApiDataLoading: false,
  softwareBundleNameLengthValidation: false,
  softwareBundleCatalogueLengthValidation: false,
  bundleStatusLengthValidation: false,
  bundleOraganizationTierLengthValidation: false,
  isAddonsLoading: false,
  addonsData: null,
  unlinkedAddonsData: null,
  getBundleSearch: '',
  uniqueValueLoading: false,
  isAddonLoading: false,
  addonData: null,
  isAddAddonModalOpen: false,
  isCreateAddonPending: false,
  createAddonStatus: null,
  createAddonErrorMessage: '',
  isFeaturesLoading: false,
  featureGroups: [],
  toggleFeaturesModal: false,
  isPostFeaturesFail: false,
  isPostFeaturesLoading: false,
  isPostFeaturesSucccess: false,
  postFeatureFailMessage: '',
  isFeatureLoading: false,
  featureData: null,
  featureAvailablitySuccess: false,
  featureAvailablityFail: false,
  featureAvailablityLoading: false,
};

const SoftwareBundlesSlice = createSlice({
  name: 'softwareBundles',
  initialState,
  reducers: {
    getPageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },
    handleCreateSoftwareBundleModal: (state, { payload }) => {
      state.handleSoftwareBundleModal = payload;
    },
    handleCreateSoftwareBundleNameInput: (state, { payload }) => {
      state.getSoftwareBundleNameInputValue = payload;
    },
    handleCreateSoftwareBundleStatusInputValue: (state, { payload }) => {
      state.getSoftwareBundleStatusInputvalue = payload;
    },
    handleCreateSoftwareBundleCatalogueInputValue: (state, { payload }) => {
      state.getSoftwareBundleCatalogueInputValue = payload;
    },
    handleCreateSoftwareBundleOrganizationTier: (state, { payload }) => {
      state.getSoftwareBundleOrganizationTierValue = payload;
    },
    handleCreateSoftwareBundleExpirationPeriodValue: (state, { payload }) => {
      state.getSoftwareBundleExpirationPeriodValue = payload;
    },
    handleCreateSoftwareBundleDescriptionValue: (state, { payload }) => {
      state.getSoftwareBundleDescriptionValue = payload;
    },
    handleCreateSoftwareBundleStatusData: (state, { payload }) => {
      state.getSoftwareBundleStatusData = payload;
      // state.SoftwareBundlenumberOfPages = payload.pagination.num_pages;
      // state.SoftwareBundlepaginationData = payload.pagination;
    },
    handleCreateSoftwareBundleStatusApiFail: (state, { payload }) => {
      state.getSoftwareBundleStatusApiFail = payload;
    },
    handleBundelCreateButtonEnable: (state, { payload }) => {
      state.getBundleCreateButtonEnable = payload;
    },

    // create bundle api reducers

    handleCreateBundleApiPostDataSuccess: (state, { payload }) => {
      state.getCreateBundleApiPostDataSuccess = payload;
    },
    handleCreateBundleApiDataFail: (state, { payload }) => {
      state.getCreateBundleApiDataFail = payload;
    },
    handleCreateBundleApiDataFailConstants: (state, { payload }) => {
      state.getCreateBundleApiDataFailConstants = payload;
    },
    // in order to post the data while creating
    handleCreateBundleApiDataFailToggle: (state, { payload }) => {
      state.getCreateBundleApiDataToggle = payload;
    },
    handleCreateBundleApiPostDataLoading: (state, { payload }) => {
      state.getCreateBundlePostApiDataLoading = payload;
    },
    //handle to post bundle data to api
    handleToPostBundleData: (state, { payload }) => {
      state.fetchPostBundleDataApi = payload;
    },

    // handle Bundle successful post after clicking on button
    handleBundleSuccessfullPost: (state, { payload }) => {
      state.fetchPostBundleApiSuccessCreation = payload;
    },

    toggleModalButtonEnableSpinner: (state, { payload }) => {
      state.toggleModalButtonEnableSpinnerValue = payload;
    },

    // Editable fields reducers
    handleBundleEditScreenIDValue: (state, { payload }) => {
      state.bundleEditScreenIDValue = payload;
    },
    handleSingleBundleEditData: (state, { payload }) => {
      state.singleBundleEditData = payload;
    },
    handleSingleBundleEditDataFail: (state, { payload }) => {
      state.singleBundleEditDataFail = payload;
    },
    toggleBundleEditScreenModal: (state, { payload }) => {
      state.toggleBundleEditScreen = payload;
    },
    handleBundleEditDatePickerMonth: (state, { payload }) => {
      state.getBundleEditDatePickerDate = payload;
    },
    handleBundleEditDatePickerDay: (state, { payload }) => {
      state.getBundleEditDatePickerDay = payload;
    },
    handleBundleEditDatePickerYear: (state, { payload }) => {
      state.getBundleEditDatePickerYear = payload;
    },
    handleBundleData: (state, { payload }) => {
      state.softwareBundles = payload?.data;
      state.SoftwareBundlenumberOfPages = payload?.pagination?.num_pages;
      state.SoftwareBundlepaginationData = payload?.pagination;

      state.handleBundleApiDataLoading = false;
    },
    handleBundleApiDataFail: (state, { payload }) => {
      state.getBundleDataApiFail = payload;
      state.handleBundleApiDataLoading = false;
    },

    handleBundleApiDataLoading: (state) => {
      state.handleBundleApiDataLoading = true;
    },
    handleUniqueValueLoading: (state) => {
      state.uniqueValueLoading = true;
    },

    handleEditPostBundleDataLoading: (state, { payload }) => {
      state.editPostBundleDataLoading = payload;
    },

    // edit bundle put api data

    hanldeEditBundlePostApi: (state, { payload }) => {
      state.editBundlePostApiSuccess = payload;
    },
    hanldeEditBundlePostApiFail: (state, { payload }) => {
      state.editBundlePostApiFail = payload;
    },
    getEditBundleName: (state, { payload }) => {
      state.editBundleName = payload;
    },

    // grace period data

    handleCreateBundleGracePeriodData: (state, { payload }) => {
      state.bundleGracePeriodData = payload;
    },
    handleCreateBundleGracePeriodDataFail: (state, { payload }) => {
      state.bundleGracePeriodDataFail = payload;
    },

    handleBundleNameSCValidation: (state, { payload }) => {
      state.bundleNameSCValidation = payload;
    },
    handleBundleCatalogueSCValidation: (state, { payload }) => {
      state.catalogueSCValidation = payload;
    },

    handlePostUniqueNameValidation: (state, { payload }) => {
      state.postuniqueNameValidation = payload;
      state.uniqueValueLoading = false;
    },
    handlePostUniqueCatalogueValidation: (state, { payload }) => {
      state.postuniqueCatalogueValidation = payload;
      state.uniqueValueLoading = false;
    },

    handleSoftwareBundleNameLengthValidation: (state, { payload }) => {
      state.softwareBundleNameLengthValidation = payload;
    },
    handleSoftwareBundleCatalogueLengthValidation: (state, { payload }) => {
      state.softwareBundleCatalogueLengthValidation = payload;
    },
    handleBundleStatusLengthValidation: (state, { payload }) => {
      state.bundleStatusLengthValidation = payload;
    },
    handleBundleOraganizationTierLengthValidation: (state, { payload }) => {
      state.bundleOraganizationTierLengthValidation = payload;
    },
    handleBundleSearch: (state, { payload }) => {
      state.getBundleSearch = payload;
    },

    resetSoftwareBundlePopUpValues: (state) => {
      state.handleSoftwareBundleModal = false;
      state.softwareBundleNameLengthValidation = false;
      state.softwareBundleCatalogueLengthValidation = false;
      state.bundleStatusLengthValidation = false;
      state.bundleOraganizationTierLengthValidation = false;
      state.getSoftwareBundleNameInputValue = '';
      state.getSoftwareBundleStatusInputvalue = '';
      state.getSoftwareBundleCatalogueInputValue = '';
      state.getSoftwareBundleOrganizationTierValue = '';
      state.getSoftwareBundleExpirationPeriodValue = '';
      state.getSoftwareBundleDescriptionValue = '';
      state.bundleNameSCValidation = false;
      state.catalogueSCValidation = false;
      state.getSoftwareBundleStatusData = [];
      state.getSoftwareBundleStatusApiFail = false;
      state.getBundleCreateButtonEnable = false;
      (state.getCreateBundleApiPostDataSuccess = []),
        (state.getCreateBundleApiDataFail = false),
        (state.getCreateBundleApiDataFailConstants = ''),
        (state.getCreateBundleApiDataToggle = false),
        (state.getCreateBundlePostApiDataLoading = false);
      state.fetchPostBundleDataApi = false;
      state.editBundleName = '';

      (state.getCreateBundleApiDataFail = false),
        (state.getCreateBundleApiDataFailConstants = ''),
        (state.getCreateBundleApiDataToggle = false),
        (state.getCreateBundlePostApiDataLoading = false),
        (state.fetchPostBundleApiSuccessCreation = false),
        (state.toggleModalButtonEnableSpinnerValue = false),
        // state.bundleEditScreenIDValue = '',
        (state.singleBundleEditData = null),
        (state.singleBundleEditDataFail = false),
        (state.toggleBundleEditScreen = false),
        (state.getBundleEditDatePickerDate = '');
      state.getBundleEditDatePickerDay = '';
      state.getBundleEditDatePickerYear = '';
      state.bundleGracePeriodData = [];
      state.getBundleDataApiFail = false;
      state.editPostBundleDataLoading = false;
      state.editBundlePostApiFail = '';
      state.editBundlePostApiSuccess = [];
      state.postuniqueNameValidation = false;
      state.postuniqueCatalogueValidation = false;
    },

    // Add-ons
    setAddonsIsLoading: (state) => {
      state.isAddonsLoading = true;
    },
    setAddonsData: (state, { payload }) => {
      state.isAddonsLoading = false;
      state.addonsData = payload;
    },
    setUnlinkedAddonsData: (state, { payload }) => {
      state.unlinkedAddonsData = payload;
    },
    setAddonIsLoading: (state) => {
      state.isAddonLoading = true;
    },
    setAddonData: (state, { payload }) => {
      state.addonData = payload;
      state.isAddonLoading = false;
    },
    toggleAddAddonModal: (state, { payload }) => {
      state.isAddAddonModalOpen = payload;
    },
    createLicenseAddonPending: (state) => {
      state.isCreateAddonPending = true;
    },
    handleCreateAddonResponse: (state, { payload }) => {
      state.isCreateAddonPending = false;
      state.createAddonStatus = payload.status;
      state.createAddonErrorMessage = payload.errorMessage;
    },

    // Features
    setFeaturesIsLoading: (state) => {
      state.isFeaturesLoading = true;
    },
    setFeatureGroupsData: (state, { payload }) => {
      state.isFeaturesLoading = false;
      state.featureGroups = payload;
    },
    handleFeaturesModal: (state, { payload }) => {
      state.toggleFeaturesModal = payload;
    },
    setPostFeaturesLoading: (state) => {
      state.isPostFeaturesLoading = true;
    },
    setPostFeaturesSuccess: (state, { payload }) => {
      state.isPostFeaturesSucccess = payload;
      state.isPostFeaturesLoading = false;
    },
    setPostFeaturesFail: (state, { payload }) => {
      state.isPostFeaturesFail = payload.isFail;
      state.isPostFeaturesLoading = false;
      state.postFeatureFailMessage = payload.errorMessage;
    },

    setFeatureIsLoading: (state) => {
      state.isFeatureLoading = true;
    },
    setFeatureData: (state, { payload }) => {
      state.featureData = payload;
      state.isFeatureLoading = false;
    },
    setFeatureAvailablitySuccess: (state, { payload }) => {
      state.featureAvailablitySuccess = payload;
      state.featureAvailablityFail = false;
      state.featureAvailablityLoading = false;
    },
    setFeatureAvailablityFail: (state, { payload }) => {
      state.featureAvailablityFail = payload;
      state.featureAvailablitySuccess = false;
      state.featureAvailablityLoading = false;
    },
    setFeatureAvailablityLoading: (state, { payload }) => {
      state.featureAvailablityLoading = payload;
      state.featureAvailablityFail = false;
      state.featureAvailablitySuccess = false;
    },
    resetFeatureUpdateValues: (state) => {
      state.featureAvailablityLoading = false;
      state.featureAvailablityFail = false;
      state.featureAvailablitySuccess = false;
    },
    updateFeatureAvailability: (state, { payload: { id } }) => {
      if (state.featureGroups) {
        const featuresGroupDataArray = state.featureGroups;
        featuresGroupDataArray.forEach((featureGroup: FeatureGroup) => {
          featureGroup.features = featureGroup.features.map((feature: Feature) =>
            feature.id === id ? { ...feature, isUpdating: !feature.isUpdating, enabled: !feature.enabled } : feature,
          );
        });
      }
    },
  },
});

export const {
  getPageNumber,

  setPostFeaturesLoading,
  setPostFeaturesSuccess,
  setPostFeaturesFail,
  handleFeaturesModal,

  handleCreateSoftwareBundleModal,
  handleSoftwareBundleNameLengthValidation,
  handleSoftwareBundleCatalogueLengthValidation,
  handleBundleStatusLengthValidation,
  handleBundleOraganizationTierLengthValidation,
  handleCreateSoftwareBundleNameInput,
  handleCreateSoftwareBundleStatusInputValue,
  handleCreateSoftwareBundleCatalogueInputValue,
  handleCreateSoftwareBundleOrganizationTier,
  handleCreateSoftwareBundleExpirationPeriodValue,
  handleCreateSoftwareBundleDescriptionValue,
  handleCreateSoftwareBundleStatusData,
  handleCreateSoftwareBundleStatusApiFail,
  handleCreateBundleGracePeriodDataFail,
  handleEditPostBundleDataLoading,
  handleUniqueValueLoading,
  handleBundleSearch,
  resetSoftwareBundlePopUpValues,
  handleBundelCreateButtonEnable,
  handleBundleEditScreenIDValue,
  handlePostUniqueCatalogueValidation,
  handleCreateBundleApiPostDataSuccess,
  handleCreateBundleApiDataFail,
  handleCreateBundleApiDataFailConstants,
  handleCreateBundleApiDataFailToggle,
  handleCreateBundleApiPostDataLoading,
  handleToPostBundleData,
  getEditBundleName,
  handleBundleSuccessfullPost,
  handleCreateBundleGracePeriodData,
  toggleModalButtonEnableSpinner,
  handleSingleBundleEditData,
  handleSingleBundleEditDataFail,
  handleBundleEditDatePickerMonth,
  toggleBundleEditScreenModal,
  handleBundleEditDatePickerDay,
  handleBundleEditDatePickerYear,
  handleBundleApiDataFail,
  hanldeEditBundlePostApi,
  handleBundleCatalogueSCValidation,
  handleBundleNameSCValidation,
  hanldeEditBundlePostApiFail,
  handleBundleData,
  handlePostUniqueNameValidation,
  handleBundleApiDataLoading,
  setAddonsIsLoading,
  setAddonIsLoading,
  setAddonData,
  setAddonsData,
  setUnlinkedAddonsData,
  toggleAddAddonModal,
  createLicenseAddonPending,
  handleCreateAddonResponse,
  setFeaturesIsLoading,
  setFeatureGroupsData,
  setFeatureIsLoading,
  setFeatureData,
  updateFeatureAvailability,
  setFeatureAvailablityFail,
  setFeatureAvailablitySuccess,
  setFeatureAvailablityLoading,
  resetFeatureUpdateValues,
} = SoftwareBundlesSlice.actions;

export default SoftwareBundlesSlice.reducer;
