import { AbsoluteCenter, Box, Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import CustomBarChart, { CustomBarChartData } from './CustomBarChart';

const FilteredGraphView = () => {
  const [chartData, setChartData] = useState<CustomBarChartData[]>([]);
  const filterValues = useAppSelector((state) => state.dashboard.dashboardChartData);
  const isDataLoding = useAppSelector((state) => state.dashboard.dashboardChartDataLoading);

  useEffect(() => {
    if (filterValues && filterValues.filteredData) {
      const parsedData: CustomBarChartData[] = Object.keys(filterValues.filteredData).map(
        (key) =>
          ({
            month: key.replace('_', ' '),
            organizations: filterValues.filteredData[key]?.organizations,
            irisdevices: filterValues.filteredData[key]?.iris_devices,
            users: filterValues.filteredData[key]?.users,
            thirdPartyDevices: filterValues.filteredData[key]?.third_party_devices,
          } as CustomBarChartData),
      );
      setChartData(parsedData);
    }
  }, [filterValues]);

  return (
    <Box height="420px" data-test-id="dashboard-graph-section-new" position="relative">
      {isDataLoding && (
        <AbsoluteCenter>
          <Spinner />
        </AbsoluteCenter>
      )}
      <CustomBarChart chartData={chartData} />
    </Box>
  );
};

export default FilteredGraphView;
