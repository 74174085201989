import { ExamCategory } from './Exam';

export interface UserDetails {
  firstName: string;
  lastName: string;
  username: string;
  timezone: Timezone;
  id: number;
  credentialedForExamTypes: CredentialedExamType[];
  permissionNames: string[];
  credentialedExamTypeList: CredentialedExamType[];
  isCredentialed: boolean;
  specialty: string;
  specialtyId: number;
  uuid: string;
  facility: string;
  facilityId: number;
  tokenExp: number;
  acceptedTos: boolean;
  autoLogoffMinutes: number;
  specialtyWorkflowType: WORKFLOW_TYPE;
  specialties: Specialty[];
  lastLogin: string | null;
  email: string;
  currentOrgId: string;
  currentFacilityRole: string;
  currentOrgName: string;
  isStaff: boolean;
  isActive: boolean;
  dateJoined: string;
  exoServiceName: string | null;
  picture: string | null;
  hcsProviderId: string | null;
  isEnabled: boolean;
  featureFlags: FeatureFlags | any;
  customerOrgCode: string;
  licenseTier: string;
  category: string;
  facilities: Facility[];
  preferredSpecialtyName: string | null;
  preferredSpecialtyUuid: string | null;
  facilityUuid: string | null;
  specialtyUuid: string | null;
  defaultSpecialtyName: string;
  defaultSpecialtyUuid: string;
  currentSpecialtyRole: UserRole;
  isCustomerAdmin: boolean;
  isCustomerAdminOnly: boolean;
  hasExamAccess: boolean;
  hasAdminAccess: boolean;
}

interface Timezone {
  timezone: string;
}

export type SmartlinkClient = {
  id: number;
  uuid: string;
};

export type Comment = {
  clientCreatedAt: string;
  comment: string;
  createdBy: number;
  deletedTimestamp: string | null;
  examUuid: string;
  fullName: string;
  uuid: string;
};

export type Facility = {
  id: number;
  specialties: Specialty[];
  timezone: string;
  createdAt: string;
  modifiedAt: string;
  customerId: string;
  name: string;
  isEnabled: boolean;
  uuid: string;
  org: string;
  address: number;
  smartlinkClients: SmartlinkClient[];
};

export interface FeatureFlags {
  aiCardiac: boolean;
  aiHip: boolean;
  modalityWorklist: boolean;
  aiBladder: boolean;
  aiLung: boolean;
  examWorksheet: boolean;
  webWorksheets: boolean;
  mobileWorksheets: boolean;
  ewArchive: boolean;
  authEnterpriseAuth0: boolean;
  authEnterpriseSso: boolean;
  workflowDocSignoff: boolean;
  workflowQaReview: boolean;
  workflowExamPerfTracking: boolean;
  pacsCloudSyncConfig: boolean;
  mobileExportToPacs: boolean;
  pacsSmartlink: boolean;
  worklistMobileConfig: boolean;
  worklistSmartlink: boolean;
  cloudSyncIris: boolean;
  irisMobileCapture: boolean;
  cloudSyncDocumentation: boolean;
  thirdPartyUs: boolean;
  worksheetsAddMeasurements: boolean;
  worksheetsWebDoc: boolean;
  patientMgmtWeb: boolean;
  tagMgmt: boolean;
  userMgmtEss: boolean;
  userMgmtConnect: boolean;
  multiFacility: boolean;
  multiSpecialty: boolean;
  perfTrackingConfig: boolean;
  perfTrackingExport: boolean;
  insightsAdvanced: boolean;
  engagementMetrics: boolean;
  currentFacilityRole: string;
  editSpecialties: boolean;
  createAllUsers: boolean;
  updateUserDetail: boolean;
}

export interface CredentialedExamType {
  id: number;
  name: string;
}

export interface UserGroup {
  id: number;
  name: string;
  permissions: string[];
  group: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserInitials = (user: UserDetails | any) => {
  return (user.firstName?.charAt(0) + user.lastName?.charAt(0)).toUpperCase();
};

export enum WORKFLOW_TYPE {
  ORDER_BASED = 'ORDER_BASED',
  ENCOUNTER_BASED = 'ENCOUNTER_BASED',
}

export type Specialty = {
  id: number;
  createdAt: string;
  modifiedAt: string;
  name: string;
  code: string;
  description: string;
  unassignedTimestamp: number;
  performingTimestamp: number;
  attendingTimestamp: number;
  isEnabled: boolean;
  workflowType: WORKFLOW_TYPE;

  isQaOnlyMode: boolean;
  uuid: string;
  defaultExamCategory: ExamCategory;
  org: string;
  role: string;
};

export enum UserRole {
  CUSTOMER_ADMIN = 'Customer Admin',
  FACILITY_ADMIN = 'Facility Admin',
  SPECIALTY_ADMIN = 'Specialty Admin',
  CREDENTIALED = 'Credentialed',
  NON_CREDENTIALED = 'Non Credentialed',
  QA_REVIEWER = 'QA Reviewer',
}

export type UserExamRecord = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  examTypesUuids: string[];
};

export type ClinicalUser = {
  id: number;
  firstName: string;
  lastName: string;
  profilePhoto: string;
};
