import { RootState } from 'redux/Store/store';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { getOrganizationNameData } from 'redux/organizations/Actions/organizationActions';
export const initialState = {
  organizationName: '',
  orgCode: '',
  country: {
    id: '1',
    name: 'USA',
  },
  language: {
    id: '1',
    name: 'English',
  },
  phone: '',
  phone_code: '+1',
  ownerFirstName: '',
  ownerLastName: '',
  ownerEmail: '',
};
export const addOrgValidationSchema = () => {
  const dispatch = useAppDispatch();
  const organizationNameDataError = useAppSelector((state: RootState) => state.organization.organizationNameDataError);
  const isOrganizationNameDataLoading = useAppSelector(
    (state: RootState) => state.organization.organizationNameDataLoading,
  );
  let orgOwnerEmailValidation: Yup.StringSchema<string | undefined, AnyObject, string | undefined> = Yup.string()
    .required('This field is required')
    .email('Invalid format')
    .matches(/^[a-zA-Z0-9.@+-]*$/, 'Invalid format')
    .test('multiple-plus-signs', 'Invalid format: More than one plus sign is not allowed', function (value) {
      if (value && value.includes('+') && value.match(/\+/g)!.length > 1) {
        return false;
      }
      return true;
    });
  return Yup.object().shape({
    organizationName: Yup.string()
      .required('This field is required')
      .min(4, 'Must be 4 characters or more')
      .test('is-unique', 'Organization name is already in use', async function (value) {
        return !(!isOrganizationNameDataLoading && organizationNameDataError);
      }),
    orgCode: Yup.string().required('This field is required').min(4, 'Must be 4 characters or more'),
    country: Yup.object().shape({
      code: Yup.string(),
      name: Yup.string().required('country is required'),
    }),
    language: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string().required('language  is required'),
      })
      .required('This field is required'),
    ownerFirstName: Yup.string()
      .required('This field is required')
      .matches(/^[a-zA-Z0-9.+'_ ,-]+$/, 'Invalid format'),
    ownerLastName: Yup.string()
      .required('This field is required')
      .matches(/^[a-zA-Z0-9.+'_ ,-]+$/, 'Invalid format'),
    ownerEmail: orgOwnerEmailValidation,
    phone: Yup.string()
      .required('This field is required')
      .matches(/^\d+$/, 'Phone number must contain only numbers')
      .min(10, 'Phone Number min & max length is 10 characters'),
    phone_code: Yup.string().required('This field is required'),
  });
};
