import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import React from 'react';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';

import OrgDevicesActivityListView from './orgDevicesActivityListView';
import { getPageNumber } from 'redux/orgDevicesActivity/Reducers/orgDevicesActivitySlice';
import OrgDevicesActivityLandingScreen from '../landingScreen/OrgDevicesActivityLandingScreen';

function OrgDevicesActivityData({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const dispatch = useAppDispatch();
  const deviceData = useAppSelector(
    (state: RootState) => state.organizationDevicesActivitySlice.organizationDevicesActivityList,
  );
  const numberOfPages = useAppSelector(
    (state: RootState) => state.organizationDevicesActivitySlice.orgDevicesActivitynumberOfPages,
  );
  const paginationData = useAppSelector(
    (state: RootState) => state.organizationDevicesActivitySlice.orgDevicesActivitypaginationData,
  );
  const selectedPage = useAppSelector((state: RootState) => state.organizationDevicesActivitySlice.selectedPage);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isLoading = useAppSelector(
    (state: RootState) => state.organizationDevicesActivitySlice.organizationDevicesActivityListLoading,
  );
  const handleMobilePageClick = (item: number) => {
    dispatch(getPageNumber(item));
  };
  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getPageNumber(data.selected + 1));
  };

  return (
    <Box position="relative" h={`calc(100vh - 250px)`} overflowY="hidden" data-testid="org-devices-data">
      <Box h={`calc(100vh - 250px)`} overflowY="hidden">
        <Box h={`calc(100vh - 300px)`} pl="18px" overflowY="scroll">
          {deviceData.length > 0 && !isLoading ? (
            <OrgDevicesActivityListView />
          ) : (
            <OrgDevicesActivityLandingScreen isFiltersApplied={isFiltersApplied} />
          )}
        </Box>

        {deviceData.length > 0 && (
          <Box
            display="flex"
            h="50px"
            borderTop="1px solid var(--chakra-colors-chakra-border-color)"
            pl="18px"
            justifyContent="space-between"
            alignItems="center"
          >
            <TableInformation selectedPage={selectedPage} data={deviceData} pagination={paginationData} />
            <Box display={{ xs: 'none', xl: 'block' }}>
              <DesktopPaginate
                data={deviceData && deviceData}
                handleDesktopPageClick={handleDesktopPageClick}
                theme={theme}
                pagination={paginationData}
                selectedPage={selectedPage}
              />
            </Box>
            <Box display={{ xs: 'block', xl: 'none' }}>
              <MobilePagination
                data={deviceData && deviceData}
                selectedPage={selectedPage}
                numberOfPages={numberOfPages}
                handleMobilePageClick={handleMobilePageClick}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default OrgDevicesActivityData;
