/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';
import { Device } from 'types/device-types';
import {
  OrganizationTypes,
  TierObject,
  OrgDevice,
  OrganizationDevicesApiResponse,
} from '../../../types/organization-types';
import { PaginationTypes } from 'types/pagination-types';
type InitialState = {
  searchText: string;
  organizationDevicesList: OrgDevice[];
  statusLoading: boolean;
  statusError: boolean;
  organizationDevicesListLoading: boolean;
  organizationsListError: boolean;
  createOrganizationApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  organizationDevicesApiData: OrganizationDevicesApiResponse | null;
  orgDevicesnumberOfPages: number;
  orgDevicespaginationData: PaginationTypes | null;
  SingleOrganizationDeviceDataLoading: boolean;
  SingleOrganizationDeviceDataError: boolean;
  SingleOrganizationsDeviceGetData: OrgDevice[];
  SingleOrganizationsDeviceGetDataLoading: boolean;
  SingleOrganizationsDeviceGetDataError: boolean;
  singleOrganizationID: string | number;
  isSearchExpand: boolean;
  deviceCreateFromOrg: boolean;
  SingleDeviceDataLoading: boolean;
  SingleDeviceDataError: boolean;
  SingleDeviceGetData: Device | null;
  orderIdInputValue: string;
  reasonInputValue: string;
  orderIdDataLoading: boolean;
  orderIdDataError: boolean;
  orderIdData: string[];
  orderId: string;
  orderIdHandleStatus: boolean;
  editDeviceInfoData: string[];
  editDeviceInfoLoading: boolean;
  editDeviceInfoFail: boolean;
  editDeviceApiFail: string;
  editDeviceInfoApiFail: string;
  openOrgDeviceUnAssignModal: boolean;
  orgDeviceUnAssignInputValue: string;
};
export const initialState: InitialState = {
  searchText: '',
  organizationDevicesList: [],
  statusLoading: false,
  statusError: false,
  organizationDevicesListLoading: false,
  organizationsListError: false,
  selectedPage: 1,
  createOrganizationApiFail: '',
  organizationDevicesApiData: null,
  orgDevicesnumberOfPages: 1,
  filterData: { status_id: [] },
  orgDevicespaginationData: null,
  SingleOrganizationDeviceDataLoading: false,
  SingleOrganizationDeviceDataError: false,
  SingleOrganizationsDeviceGetData: [],
  SingleOrganizationsDeviceGetDataLoading: false,
  SingleOrganizationsDeviceGetDataError: false,
  singleOrganizationID: '',
  isSearchExpand: false,
  deviceCreateFromOrg: false,
  SingleDeviceDataLoading: false,
  SingleDeviceDataError: false,
  SingleDeviceGetData: null,
  orderIdInputValue: '',
  reasonInputValue: '',
  orderIdDataLoading: false,
  orderIdDataError: false,
  orderIdData: [],
  orderId: '',
  orderIdHandleStatus: false,
  editDeviceInfoData: [],
  editDeviceInfoLoading: false,
  editDeviceInfoFail: false,
  editDeviceApiFail: '',
  editDeviceInfoApiFail: '',
  openOrgDeviceUnAssignModal: false,
  orgDeviceUnAssignInputValue: '',
};
const organizationSlice = createSlice({
  name: 'organizationdevice',
  initialState,
  reducers: {
    getDeviceCreateFromOrg: (state, { payload }) => {
      state.deviceCreateFromOrg = payload;
    },
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    getDevicePageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchcreateOrganizationApiFail: (state, { payload }) => {
      state.createOrganizationApiFail = payload;
    },
    fetchOrganizationDeviceLoading: (state) => {
      state.SingleDeviceGetData = null;
      state.organizationDevicesListLoading = true;
    },
    fetchOrganizationDeviceFail: (state) => {
      state.organizationsListError = true;
      state.organizationDevicesListLoading = false;
    },
    fetchOrganizationDeviceSuccess: (state, { payload }) => {
      state.SingleDeviceGetData = null;
      state.organizationDevicesList = payload.data;
      state.organizationDevicesApiData = payload;
      state.orgDevicesnumberOfPages = payload.pagination.num_pages;
      state.orgDevicespaginationData = payload.pagination;
      state.organizationDevicesListLoading = false;
    },
    fetchSingleOrganizationID: (state, { payload }) => {
      state.singleOrganizationID = payload;
    },
    fetchSingleOrganizationDeviceDataLoading: (state) => {
      state.SingleOrganizationsDeviceGetDataLoading = true;
      state.SingleOrganizationsDeviceGetDataError = false;
    },
    fetchSingleOrganizationDeviceDataSuccess: (state, { payload }) => {
      state.SingleOrganizationsDeviceGetData = payload;
      state.SingleOrganizationsDeviceGetDataLoading = false;
      state.SingleOrganizationsDeviceGetDataError = false;
    },
    fetchSingleOrganizationDeviceDataFail: (state) => {
      state.SingleOrganizationsDeviceGetDataError = true;
      state.SingleOrganizationsDeviceGetDataLoading = false;
    },
    fetchOrderIdLoading: (state) => {
      state.orderIdDataLoading = true;
      state.orderIdDataError = false;
    },
    fetchOrderIdDataSuccess: (state, { payload }) => {
      state.orderIdData = payload;
      state.orderIdDataError = false;
      state.orderIdDataLoading = false;
    },
    fetchOrderIdFail: (state, { payload }) => {
      state.orderIdDataError = payload;
      state.orderIdDataLoading = false;
    },

    fetchOrderIdData: (state, { payload }) => {
      state.orderId = payload;
    },
    fetchOrderIdStatus: (state, { payload }) => {
      state.orderIdHandleStatus = payload;
    },

    editDeviceInfoDataLoading: (state) => {
      state.editDeviceInfoLoading = true;
      state.editDeviceInfoFail = false;
    },
    editDeviceInfoDataSuccess: (state, { payload }) => {
      state.editDeviceInfoData = payload;
      state.editDeviceInfoLoading = false;
      state.editDeviceInfoFail = false;
      state.editDeviceApiFail = 'SUCCESS';
    },
    editDeviceInfoDataFail: (state) => {
      state.editDeviceInfoFail = true;
      state.editDeviceInfoLoading = false;
      state.editDeviceApiFail = 'FAIL';
    },
    fetchEditDeviceInfoApiFail: (state, { payload }) => {
      state.editDeviceInfoApiFail = payload;
    },
    fetchSingleDeviceDataLoading: (state) => {
      state.SingleDeviceDataLoading = true;
      state.SingleDeviceDataError = false;
    },
    fetchSingleDeviceDataSuccess: (state, { payload }) => {
      state.SingleDeviceGetData = payload;
      state.SingleDeviceDataLoading = false;
      state.SingleDeviceDataError = false;
    },
    fetchSingleDeviceDataFail: (state) => {
      state.SingleDeviceDataError = true;
      state.SingleDeviceDataLoading = false;
    },
    getOrderIdInputValue: (state, { payload }) => {
      state.orderIdInputValue = payload;
    },
    getReasonInputValue: (state, { payload }) => {
      state.reasonInputValue = payload;
    },
    handleOrgDeviceUnAssignModal: (state, { payload }) => {
      state.openOrgDeviceUnAssignModal = payload;
    },
    getOrgDeviceUnAssignInputValue: (state, { payload }) => {
      state.orgDeviceUnAssignInputValue = payload;
    },
    resetOrgDeviceValues: (state) => {
      state.orgDeviceUnAssignInputValue = '';
    },
  },
});
export const {
  getSearchText,
  clearSearcText,
  getOrgDeviceUnAssignInputValue,
  handleOrgDeviceUnAssignModal,
  expandSearchInMobile,
  closeSearchInMobile,
  getDevicePageNumber,
  fetchOrganizationDeviceLoading,
  fetchOrganizationDeviceFail,
  fetchOrganizationDeviceSuccess,
  fetchcreateOrganizationApiFail,
  getFlterData,
  fetchSingleOrganizationDeviceDataLoading,
  fetchSingleOrganizationDeviceDataSuccess,
  fetchSingleOrganizationDeviceDataFail,
  fetchSingleOrganizationID,
  getDeviceCreateFromOrg,
  fetchSingleDeviceDataFail,
  fetchSingleDeviceDataLoading,
  fetchSingleDeviceDataSuccess,
  getOrderIdInputValue,
  getReasonInputValue,
  fetchOrderIdLoading,
  fetchOrderIdData,
  fetchOrderIdDataSuccess,
  fetchOrderIdFail,
  fetchOrderIdStatus,
  fetchEditDeviceInfoApiFail,
  editDeviceInfoDataLoading,
  editDeviceInfoDataSuccess,
  editDeviceInfoDataFail,
  resetOrgDeviceValues,
} = organizationSlice.actions;
export default organizationSlice.reducer;
