import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { dashboardSubTabs } from 'routes/routePaths';
import { setActiveTabs } from 'redux/common/CommonSlice';
import { useLocation } from 'react-router-dom';
import SubTabs from 'components/subTabs/SubTabs';
import FilteredGraphView from './graph/FilteredGraphView';
import TotalGraphView from './graph/TotalGraphView';
import CustomSelect from 'components/customSelect/CustomSelect';
import moment from 'moment';
import { getDashboardChartData } from 'redux/dashbaord/Actions/dashboardActions';

const GraphSection = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector((state) => state.common.activeTab);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [selectedDateRangeFilter, setSelectedDateRangeFilter] = useState(3);
  const query = useQuery();

  const options = [
    {
      id: 3,
      name: 'Last 3 Months',
    },
    {
      id: 6,
      name: 'Last 6 Months',
    },
    {
      id: 9,
      name: 'Last 9 Months',
    },
  ];

  const handleChange = (value: any) => {
    const endDate = moment().utc().format('YYYY-MM-DD');
    const startDate = moment().utc().subtract(`${value}`, 'months').startOf('month').format('YYYY-MM-DD');
    setSelectedDateRangeFilter(value);
    dispatch(getDashboardChartData(startDate, endDate));
  };

  useEffect(() => {
    handleChange(3);
    const currentTab = (query.get('tab') as string) || dashboardSubTabs.New;
    dispatch(setActiveTabs(currentTab));
  }, []);

  return (
    <Box data-test-id="dashboard-graph-section">
      <SubTabs
        selectedTab={selectedTab}
        routePaths={dashboardSubTabs}
        routePrefix={'/dashboard'}
        customSelect={
          <CustomSelect handleSelectChange={handleChange} options={options} selectedValue={selectedDateRangeFilter} />
        }
      />
      {selectedTab === dashboardSubTabs.New && <FilteredGraphView />}
      {selectedTab === dashboardSubTabs.Total && <TotalGraphView />}
    </Box>
  );
};

export default GraphSection;
