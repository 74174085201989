/* eslint-disable @typescript-eslint/no-explicit-any */
import { Wrap, WrapItem, Input, Box } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  fetchPostUserData,
  fetchPostUserFormDataFail,
  fetchUserCreateButtonEnableValue,
  fetchUserFirstName,
  fetchUserFirstNameFieldValidation,
  fetchUserLastName,
  fetchUserLastNameFieldValidation,
  fetchUserPostToggle,
  fetchUserSuccessCreation,
  getUserCreateButtonEnable,
  getUserFirstNameLength,
  getUserLastNameLength,
} from 'redux/user/Reducers/userSlice';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const UserNameInputFields = () => {
  const colors = customColor();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const userFirstNameValue = useAppSelector((state) => state.user.userFirstName);
  const userLastNameChange = useAppSelector((state) => state.user.userLastName);
  const userFirstNameFieldValidation = useAppSelector((state) => state.user.userFirstNameFieldValidation);
  const userLastNameFieldValidation = useAppSelector((state) => state.user.userLastNameFieldValidation);
  const postDataFail = useAppSelector((state) => state.user.postUserFormDataFail);

  const handleInputName = (e: any) => {
    dispatch(fetchUserFirstName(e.target.value.trim()));
    if (postDataFail) {
      dispatch(fetchUserCreateButtonEnableValue(false));
      dispatch(fetchPostUserData(false));
      dispatch(fetchUserPostToggle(false));
      dispatch(fetchPostUserFormDataFail(false));
      dispatch(getUserCreateButtonEnable(false));
      dispatch(fetchUserSuccessCreation(false));
    }
  };
  const handleLastNameChange = (e: any) => {
    dispatch(fetchUserLastName(e.target.value.trim()));
    if (postDataFail) {
      dispatch(fetchUserCreateButtonEnableValue(false));
      dispatch(fetchPostUserData(false));
      dispatch(fetchUserPostToggle(false));
      dispatch(fetchPostUserFormDataFail(false));
      dispatch(getUserCreateButtonEnable(false));
      dispatch(fetchUserSuccessCreation(false));
    }
  };
  const handleFirstName = () => {
    if (userFirstNameValue.length > 0 && userFirstNameValue.length < 4) {
      dispatch(getUserFirstNameLength(true));
      dispatch(fetchUserFirstNameFieldValidation(false));
    } else if (userFirstNameValue.length > 3) {
      dispatch(getUserFirstNameLength(false));
      dispatch(fetchUserFirstNameFieldValidation(false));
    } else if (userFirstNameValue.length == 0) {
      dispatch(getUserFirstNameLength(false));
      dispatch(fetchUserFirstNameFieldValidation(true));
    } else {
      dispatch(getUserFirstNameLength(false));
      dispatch(fetchUserFirstNameFieldValidation(false));
    }
  };
  const handleLastName = () => {
    if (userLastNameChange.length > 0 && userLastNameChange.length < 4) {
      dispatch(getUserLastNameLength(true));
      dispatch(fetchUserLastNameFieldValidation(false));
    } else if (userLastNameChange.length > 3) {
      dispatch(getUserLastNameLength(false));
      dispatch(fetchUserLastNameFieldValidation(false));
    } else if (userLastNameChange.length == 0) {
      dispatch(getUserLastNameLength(false));
      dispatch(fetchUserLastNameFieldValidation(true));
    } else {
      dispatch(getUserLastNameLength(false));
      dispatch(fetchUserLastNameFieldValidation(false));
    }
  };
  return (
    <Box pt="10px" ml="5px">
      <Wrap align="center" justify="first_name" spacingX="30px">
        <WrapItem>
          <Box minH="70px">
            <InputLabel value={userFirstNameValue} name="first_name" />
            <Input
              color={colors.subTextColor}
              name="first_name"
              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              fontSize="14px"
              _placeholder={{ fontSize: '14px' }}
              textTransform="capitalize"
              onChange={handleInputName}
              onBlur={handleFirstName}
              placeholder={intl.formatMessage({ id: 'first_name.message' })}
              type="text"
              variant="flushed"
              maxLength={50}
              onKeyPress={specialCharacterValidation}
              required
              data-testid="userForm-fname"
            />
            <Box fontSize="12px" color="#FB5654">
              {/* {firstnameLengthValidation ? <FormattedMessage id="org_name_error.message" /> : ''} */}
              {userFirstNameFieldValidation ? <FormattedMessage id="field_validation.message" /> : ''}
            </Box>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box minH="70px">
            <InputLabel value={userLastNameChange} name="last_name" />
            <Input
              color={colors.subTextColor}
              name="last_name"
              width={{ xs: '85vw', smd: '89vw', md: '305px' }}
              onChange={handleLastNameChange}
              placeholder={intl.formatMessage({ id: 'last_name.message' })}
              borderColor="#E0E0E0"
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              borderWidth="0 0 1px 0"
              onBlur={handleLastName}
              fontSize="14px"
              _placeholder={{ fontSize: '14px' }}
              textTransform="capitalize"
              type="text"
              maxLength={50}
              onKeyPress={specialCharacterValidation}
              variant="flushed"
              required
              data-testid="userForm-lname"
            />
            <Box fontSize="12px" color="#FB5654">
              {/* {lastNameLengthValidation ? <FormattedMessage id="org_name_error.message" /> : ''} */}
              {userLastNameFieldValidation ? <FormattedMessage id="field_validation.message" /> : ''}
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default UserNameInputFields;
