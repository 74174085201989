import { Box, FormLabel, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { InputLabel } from '../../../../../../components/InputField';
import { FormattedMessage } from 'react-intl';
import { customColor } from '../../../../../../assets/styles/colors';
import { useAppSelector } from '../../../../../../redux/hooks';

interface OrganizationInformationSectionProps {
  inputValues: { [x: string]: string } | undefined;
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
}
const OrgInfoOwnerInputs: React.FC<OrganizationInformationSectionProps> = (props) => {
  const colors = customColor();

  const singleOrganizationData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);

  return (
    <div className="Form">
      {singleOrganizationData && (
        <Box
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <FormLabel pb="15px" color={colors.subTextColor} fontSize="18px" fontWeight="700">
            <FormattedMessage id="organization_admin.message" />
          </FormLabel>
          <Wrap align="center" justify="first_name" spacingX="40px">
            <WrapItem>
              <Box minH="60px" minWidth={305}>
                <InputLabel value="First Name" name="first_name" />
                <Text fontWeight="400" className="truncatee" textTransform="capitalize" fontSize="14px" marginTop={2}>
                  {singleOrganizationData.org_owner_first_name}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="60px" minWidth={305}>
                <InputLabel value="Last Name" name="last_name" />
                <Text fontWeight="400" fontSize="14px" textTransform="capitalize" className="truncatee" marginTop={2}>
                  {singleOrganizationData.org_owner_last_name}
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
          <Wrap align="center" justify="first_name" spacingX="40px">
            <WrapItem>
              <Box minH="60px" minWidth={305}>
                <InputLabel value="Email" name="email" />
                <Text fontWeight="400" fontSize="14px" marginTop={2} className="truncatee">
                  {singleOrganizationData.org_owner_email}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="60px" minWidth={305}>
                <InputLabel value="phone" name="phone" />
                {singleOrganizationData.org_owner_phone ? (
                  <Stack marginTop={2} display="flex" direction={['row']} spacing="5px">
                    <Text fontSize="14px">{singleOrganizationData?.country_phone_code?.country_phone_code} </Text>
                    <Text fontWeight="400" fontSize="14px" className="truncatee" display="flex">
                      {' '}
                      {singleOrganizationData.org_owner_phone}
                    </Text>
                  </Stack>
                ) : (
                  '-'
                )}
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      )}
    </div>
  );
};
export default OrgInfoOwnerInputs;
