import { Box, Heading, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { customColor } from '../../assets/styles/colors';

import { FormattedMessage } from 'react-intl';
import { SharedUiState } from '../../redux/hooks';
import OrganizationItem from './OrganizationItem';
import { fetchOwnerOrganizations, fetchSelectedOrganization } from '../../redux/login.slice';
import { UserInfo } from '../../../lib/types/types';

const OwnerOrganizations = ({ onOrgselect }: { onOrgselect: (selectedOrg: any) => void }) => {
  const organizations = useSelector((state: SharedUiState) => state.login.ownerOrganizations);
  const isExoWorks = useSelector((state: SharedUiState) => state.login.isExoWorks);
  const dispatch = useDispatch();
  const colors = customColor();

  const handleBack = () => {
    dispatch(fetchOwnerOrganizations([]));
    dispatch(fetchSelectedOrganization(null));
  };

  return (
    <Box h="calc(100vh - 310px)" position="relative">
      {organizations.length && (
        <Box mb="30px">
          <Heading
            fontSize={{ xs: '36px', md: '40px' }}
            fontWeight="325"
            color={colors.fontColor}
            lineHeight={{ xs: '52.92px', md: '58.8px' }}
          >
            <FormattedMessage id="select_organization.message" />
          </Heading>
        </Box>
      )}
      <Box h="calc(100vh - 450px)" overflow="auto" pr="15px">
        {organizations && organizations.length && isExoWorks && (
          <Box>
            {organizations.map((organization: UserInfo) => (
              <OrganizationItem org={organization} key={organization.org_id} onOrgselect={onOrgselect} />
            ))}
          </Box>
        )}
        <Text
          fontSize={{ xs: '16px', md: '18px' }}
          fontWeight={500}
          color={colors.lightModeBlue}
          onClick={handleBack}
          cursor="pointer"
          position="absolute"
          bottom="0"
        >
          <FormattedMessage id="back_to_login.message" />
        </Text>
      </Box>
    </Box>
  );
};

export default OwnerOrganizations;
