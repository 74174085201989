import React, { useEffect } from 'react';
import { Text, Flex, Box, useColorModeValue, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import CreateSoftwareBundleModal from '../createSoftwareBundleModal/CreateSoftwareBundleModal';
import SoftwareBundleEditModal from '../softwareBundleEditScreen/SoftwareBundleEditModal';
import { AddIcon } from 'assets/icons/icons';
import {
  getBundleData,
  getBundleGracePeriod,
  getBundleStatus,
} from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { getTiers } from 'redux/organizations/Actions/organizationActions';
import { handleBundleSearch, handleCreateSoftwareBundleModal } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { RootState } from 'redux/Store/store';
import { toggleLicenseView } from 'redux/license/Reducers/licenseSlice';
import { useNavigate } from 'react-router-dom';
import { userAccessConst } from 'utils/constants/userAccessConst';
import LicenseBundleConatiner from '../LicenseBundleListView/LicenseBundleContainer';
import Search from 'components/customSearch/Search';

const SoftwareBundlesMainPage = () => {
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const colors = customColor();
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');
  const navigate = useNavigate();
  const bundleData = useAppSelector((state) => state.softwareBundles.softwareBundles);
  const searchText = useAppSelector((state: RootState) => state.softwareBundles.getBundleSearch);
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  useEffect(() => {
    dispatch(toggleLicenseView(true));
  }, []);
  const handleSoftwareBundleModalOpen = () => {
    dispatch(getBundleStatus());
    dispatch(getBundleGracePeriod());
    dispatch(getTiers());
    dispatch(handleCreateSoftwareBundleModal(true));
  };
  const handleClick = () => {
    dispatch(toggleLicenseView(false));

    navigate('/bundlesLicenseItems');
  };
  const handleSearchValue = (value: string) => {
    dispatch(getBundleData(1, value));
    dispatch(handleBundleSearch(value));
  };

  const handleSearchClear = () => {
    dispatch(getBundleData(1, ''));
    dispatch(handleBundleSearch(''));
  };

  return (
    <Box alignItems="center" h="50px" mt="25px">
      <Box display="flex" justifyContent="space-between">
        {bundleData && (
          <Box display="flex">
            <Text mr={{ xs: '3' }} fontSize={{ xs: '22px', xl: '24px' }} color={colors.textColor}>
              <FormattedMessage id="license_bundles.message" />
            </Text>
            {userPermissions.bundles?.includes(userAccessConst.bundle.addBundle) && (
              <Box mt="7px">
                <Flex alignItems="center">
                  <Icon
                    data-testid="plus-icon"
                    w="24px"
                    h="24px"
                    onClick={handleSoftwareBundleModalOpen}
                    color={textColorMode}
                    fill={fillColorMode}
                    _hover={{
                      color: 'url("#BlueGradient")',
                      cursor: 'pointer',
                      stroke: 'white',
                      fill: 'url("#BlueGradient")',
                      '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                      '&:hover>svg>circle': { stroke: strokeColorMode },
                    }}
                  >
                    <AddIcon stroke={primarblackAndWhiteColorMode} />
                  </Icon>
                </Flex>
              </Box>
            )}
          </Box>
        )}
        {bundleData && (
          <Box pr="20px">
            <Search
              data={{ searchText, isSearchExpand: false }}
              handleClose={() => {}}
              handleClear={handleSearchClear}
              handleExpandClick={() => {}}
              handleSearch={handleSearchValue}
            />
          </Box>
        )}
      </Box>

      <Box overflowY="scroll" scrollBehavior="smooth">
        <Flex mt="20px" flexFlow="column">
          <Box>
            <LicenseBundleConatiner />
          </Box>
        </Flex>
        <Box>
          <CreateSoftwareBundleModal />
        </Box>
        <Box>
          <SoftwareBundleEditModal />
        </Box>
      </Box>
    </Box>
  );
};
export default SoftwareBundlesMainPage;
