import { Box, Icon, WrapItem, Input, InputGroup, InputRightElement, Wrap } from '@chakra-ui/react';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { CalendarIcons } from 'assets/icons/icons';
import { customColor } from 'assets/styles/colors';
import CustomDatePicker from 'components/customDatePicker/CustomDatePicker';
import { InputLabel } from 'components/InputField';
import moment from 'moment';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleLicenseEndDateValue } from 'redux/organizations/Reducers/organizationSlice';
import { RootState } from 'redux/Store/store';

const OrgLicenseDateDurationInputFields = (props: any) => {
  const intl = useIntl();
  const colors = customColor();
  const dispatch = useAppDispatch();
  const singleOrgData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const expirationDate = singleOrgData?.bundle_info.expiration_date;
  const day = useAppSelector((state) => state.customInputFields.getDatePickerDay);
  const month = useAppSelector((state: any) => state.customInputFields.getDatePickerMonth);
  const year = useAppSelector((state) => state.customInputFields.getDatePickerYear);
  const contractDuration = useAppSelector((state) => state.orgLicense.orgLicenseSelectedContractDuration);
  const endDateValue = useAppSelector((state) => state.organization.licenseEndDateValue);
  const formattedEndDate = moment(endDateValue)?.format('ll');

  // End date calculation
  useEffect(() => {
    if (contractDuration && day) {
      const endDateValue = moment([year, month - 1, day]).add(contractDuration, 'year');
      dispatch(handleLicenseEndDateValue(endDateValue?.format('YYYY-MM-DD')));
    }
  }, [contractDuration, day]);

  return (
    <Box ml="5px">
      <Wrap spacingX="30">
        <WrapItem>
          <CustomDatePicker inputName={'start_date'} allowPastDate={true} />
        </WrapItem>
        <WrapItem>
          <Box>
            <InputLabel value={endDateValue} name="end_date" />
            <InputGroup>
              <InputRightElement
                pointerEvents="none"
                children={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                paddingRight={'10px'}
              />
              <Input
                color={colors.subTextColor}
                name="end date"
                isDisabled={true}
                defaultValue={endDateValue ? formattedEndDate : ''}
                width={{ xs: '85vw', sm: '305px', smd: '89vw', md: '305px' }}
                borderColor="#E0E0E0"
                focusBorderColor={colors.primaryColor}
                borderBottomColor={colors.inputBorderColor}
                _placeholder={{ fontSize: props?.placeholderSize || '14px' }}
                borderWidth="0 0 1px 0"
                placeholder={intl.formatMessage({ id: 'end_date.message' })}
                type="text"
                variant="flushed"
                required
                data-testid="device-form-serial"
              />{' '}
            </InputGroup>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default OrgLicenseDateDurationInputFields;
