/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { Dispatch } from 'react';
import axios from 'axios';
import { userRequest } from 'api';
import {
  fetchOrgUsersActivityDataError,
  fetchOrgUsersActivityDataLoading,
  fetchOrgUsersActivityDataSuccess,
} from '../../orgUserActivity/Reducers/orgUserActivitySlice';
import { setError } from '../../error/errorSlice';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}
export const getOrgUsersActivityData = (id: any, queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchOrgUsersActivityDataLoading());

  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`organizations/${id}/useractivity/?${queryString}`);
    if (res && res.status === 200) {
      dispatch(fetchOrgUsersActivityDataSuccess(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchOrgUsersActivityDataError());
  }
};
