import { Box } from '@chakra-ui/react';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { openOrganizationModal } from 'redux/organizations/Reducers/organizationSlice';
import { getLanguage } from 'redux/organizations/Actions/organizationActions';
import LandingScreen from 'components/landingPage/LandingSreen';
import Loader from 'components/loader/Loader';

interface FilterProps {
  isFiltersApplied: boolean;
}
export default function OrganizationLandingScreen({ isFiltersApplied }: FilterProps) {
  const dispatch = useAppDispatch();

  const isDataLoading = useAppSelector((state) => state.organization.organizationsListLoading);
  const handleModalOpen = () => {
    dispatch(openOrganizationModal(true));
    dispatch(getLanguage());
  };
  return (
    <Box>
      <Loader isLoading={isDataLoading} />
      {!isDataLoading && (
        <LandingScreen handleClick={handleModalOpen} title="Organizations" isFiltersApplied={isFiltersApplied} />
      )}
    </Box>
  );
}
