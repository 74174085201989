import { Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getOrganizations, getStatus, getTiers } from 'redux/organizations/Actions/organizationActions';
import { getPageNumber } from 'redux/organizations/Reducers/organizationSlice';
import { RootState } from 'redux/Store/store';
import { Error_Type_Enum } from 'utils/enum';
import React, { useEffect, useRef } from 'react';
import OrganizationData from './OrganizationData';
import { getLanguage } from '../../../../../redux/organizations/Actions/organizationActions';
import { getDeviceStatuses } from '../../../../../redux/devices/Actions/deviceActions';
import { useLocation } from 'react-router-dom';

interface FilterProps {
  isFiltersApplied: boolean;
}
export default function MainContent({ isFiltersApplied }: FilterProps) {
  interface OrgFilterPayload {
    page?: number;
    search?: string;
    tier_id?: string[];
    status_id?: string[];
  }
  const initialRender = useRef({
    primary: true,
    secondary: true,
  });
  const dispatch = useAppDispatch();
  const organizationsData = useAppSelector((state: RootState) => state.organization);
  const ref = useRef(false);

  const { state } = useLocation();
  useEffect(() => {
    dispatch(getTiers());
    dispatch(getStatus());
    dispatch(getLanguage());
    dispatch(getDeviceStatuses());
    return () => {
      dispatch(getPageNumber(1));
    };
  }, []);
  const getOrganizationsData = () => {
    const data = [...organizationsData.filterData.status_id];

    const payload: OrgFilterPayload = {
      search: organizationsData.searchText,
      page: organizationsData.selectedPage,
      tier_id: organizationsData.filterData.tier_id?.filter((itr) => itr !== 'All'),
      status_id: state?.statusName?.length ? [state?.statusId?.toString()] : data.filter((itr) => itr !== 'All'),
    };
    const queryString = Object.entries(payload)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
    dispatch(getOrganizations(queryString, state?.licenseExpireData, state?.exceededAllocateData));
    ref.current = true;
  };
  useEffect(() => {
    if (!initialRender.current.primary) {
      getOrganizationsData();
    }
    initialRender.current.primary = false;
  }, [organizationsData.selectedPage, organizationsData.createOrganizationApiFail === Error_Type_Enum.Success]);

  useEffect(() => {
    if (initialRender.current.secondary) {
      initialRender.current.secondary = false;
      return;
    }
    dispatch(getPageNumber(1));
    getOrganizationsData();
  }, [organizationsData.searchText, organizationsData?.filterData, state?.statusId]);

  return (
    <Box h={{ xs: 'calc(100vh - 85px)', lg: 'calc(100vh - 85px)' }} position="relative">
      <Box overflow="hidden" h="100%">
        <OrganizationData isFiltersApplied={isFiltersApplied} />
      </Box>
    </Box>
  );
}
