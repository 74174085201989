import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  getDatePickerDay: string | number;
  getDatePickerMonth: string | number;
  getDatePickerYear: string;
  getDatePickerValidation: boolean;
  handleCreateButtonEnable: boolean;
};

export const initialState: InitialState = {
  getDatePickerDay: '',
  getDatePickerMonth: '',
  getDatePickerYear: '',
  getDatePickerValidation: false,
  handleCreateButtonEnable: false,
};

const CustomInputFieldsSlice = createSlice({
  name: 'customInputFieldsSlice',

  initialState,

  reducers: {
    handleDatePickerDay: (state, { payload }) => {
      state.getDatePickerDay = payload;
    },
    handleDatePickerMonth: (state, { payload }) => {
      state.getDatePickerMonth = payload;
    },
    handleDatePickerYear: (state, { payload }) => {
      state.getDatePickerYear = payload;
    },
    handleDatePickerValidation: (state, { payload }) => {
      state.getDatePickerValidation = payload;
    },

    // In order to reset the day, month, year apply this reducer
    resetDatePickerValues: (state) => {
      state.getDatePickerDay = '';
      state.getDatePickerMonth = '';
      state.getDatePickerYear = '';
      state.getDatePickerValidation = false;
    },

    // custom modal reducers

    handleCreateButtonEnableInModal: (state, { payload }) => {
      state.handleCreateButtonEnable = payload;
    },

    resetCustomModalEnableFields: (state) => {
      state.handleCreateButtonEnable = false;
    },
  },
});

export const {
  handleDatePickerDay,
  handleDatePickerMonth,
  handleDatePickerYear,
  handleDatePickerValidation,
  resetDatePickerValues,
  handleCreateButtonEnableInModal,
  resetCustomModalEnableFields
} = CustomInputFieldsSlice.actions;

export default CustomInputFieldsSlice.reducer;
