import React from 'react';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import { sharedEn } from '@exo/shared-ui';

type LocaleData = {
  [key: string]: {
    [key: string]: string;
  };
};

const languages: {
  [key: string]: LocaleData;
} = {
  en: {
    ...sharedEn,
    ...require('../lang/en.json'),
    ...require('../lang/events.en.json'),
    ...require('../lang/userRolePermissions.en.json'),
  },
  ar: require('../lang/de.json'),
};

const locale = 'en';
const localeData = languages[locale];

type WrapperProps = {
  children: React.ReactNode;
};

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return (
    <IntlProvider messages={flatten(localeData)} locale={locale}>
      {children}
    </IntlProvider>
  );
};

export default Wrapper;
