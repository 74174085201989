import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  HStack,
  CircularProgress,
  Text,
  Circle,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import StatusBadge from 'components/statusBadge/StatusBadge';

import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Outlet, useNavigate } from 'react-router-dom';

import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import { resetUserValidationValues } from 'redux/user/Reducers/userSlice';

import { userAccessConst } from 'utils/constants/userAccessConst';

const EditPulseUserScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const singleUserData = useAppSelector((state: RootState) => state.user.singlePulseUserData);
  const loader = useAppSelector((state: RootState) => state.user.singlePulseUserDataLoading);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const routes = [
    {
      id: 1,
      pathname: '',
      name: 'information',
      hasAccess: userPermissions.users?.includes(userAccessConst.user.viewUser),
    },
    // {
    //   id: 2,
    //   pathname: `/users/${params.modelId}/info/${params.deviceId}/useractivities`,
    //   name: 'users_activity',
    //   disable:true
    // },
  ];

  useEffect(() => {
    dispatch(getDeviceStatus());
  }, []);

  const handleDeviceClick = () => {
    navigate(`/users`);
    dispatch(resetUserValidationValues());
  };

  return (
    <>
      <Box data-testid="user-info-container">
        <Box h="90px" borderBottom="1px solid #3333331A" pl="15px">
          <div data-testid="user-info-breadcumb">
            <Breadcrumb spacing="8px" pt="35px" separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  color="#24B0FF"
                  position="absolute"
                  top="14px"
                  onClick={handleDeviceClick}
                  display="flex"
                  alignItems="center"
                  fontSize="14px"
                >
                  <RightChervonIcon />

                  <Text ml="5px" textTransform="capitalize">
                    <FormattedMessage id="pulse_users.message" />
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            {loader ? (
              <Box ml="30px" mt="10px">
                <CircularProgress isIndeterminate size="30px" />
              </Box>
            ) : (
              <Flex alignItems={'center'} gap="20px">
                <Heading
                  fontSize="36px"
                  fontWeight="400"
                  textTransform="capitalize"
                  fontFamily="Helvetica"
                  style={{ paddingLeft: '15px' }}
                  width="95%"
                >
                  {singleUserData.first_name ? (
                    <Flex alignItems={'center'} gap="20px">
                      <Tooltip label={singleUserData.first_name + '  ' + singleUserData.last_name}>
                        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                          {singleUserData.first_name + '  ' + singleUserData.last_name}
                        </Box>
                      </Tooltip>
                      <StatusBadge status={singleUserData?.status?.status} />
                    </Flex>
                  ) : (
                    <CircularProgress size="30px" isIndeterminate />
                  )}
                </Heading>
              </Flex>
            )}
          </div>
        </Box>
        <Box display="flex" h={`calc(100vh - 180px)`}>
          <Box borderRight="1px solid #3333331A">
            <OrganizationInfoSidebar routes={routes} />
          </Box>
          <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 470px)' : 'calc(100vw - 300px)' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default EditPulseUserScreen;
