/* eslint-disable react/jsx-key */
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  HStack,
  CircularProgress,
  Text,
  Circle,
  Flex,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import StatusBadge from 'components/statusBadge/StatusBadge';
import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';
import { getSelectedDevice } from 'redux/devices/Reducers/deviceSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getSingleDevicesData } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import { RootState } from 'redux/Store/store';
import { userAccessConst } from 'utils/constants/userAccessConst';

const DeviceSubSayout = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const dispatch = useAppDispatch();
  const params = useParams();
  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const singleDeviceData = useAppSelector((state: RootState) => state.organizationdevice.SingleDeviceGetData);
  const deviceStatusData = useAppSelector((state: RootState) => state.device.statusData);
  const isLoading = useAppSelector((state: RootState) => state.organizationdevice.SingleDeviceDataLoading);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const routes = [
    {
      id: 1,
      pathname: `/device-models/${params.modelId}/devices/${params.deviceId}/info`,
      name: 'information',
      hasAccess: userPermissions.devices?.includes(userAccessConst.device.viewDevice),
    },
    {
      id: 2,
      pathname: `/device-models/${params.modelId}/devices/${params.deviceId}/activity`,
      name: 'device_activity',
      hasAccess: userPermissions.devices?.includes(userAccessConst.device.listDeviceActivity),
    },
  ];

  useEffect(() => {
    dispatch(getDeviceStatus());
  }, []);

  const handleDeviceClick = () => {
    navigate(`/device-models/${singleDeviceData?.catalogue?.internal_name}/devices`);
    dispatch(getSelectedDevice(singleDeviceData?.catalogue?.internal_name));
  };

  useEffect(() => {
    dispatch(getSingleDevicesData(deviceId));
  }, []);

  return (
    <>
      <Box data-testid="org-info-container">
        <Box display="flex" h="90px" borderBottom="1px solid #3333331A" pl="15px">
          <div data-testid="org-info-breadcumb">
            <Breadcrumb pt="35px" separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  color="#24B0FF"
                  position="absolute"
                  top="14px"
                  onClick={handleDeviceClick}
                  display="flex"
                  fontSize="14px"
                  alignItems="center"
                >
                  <RightChervonIcon />

                  <Text ml="5px">
                    <FormattedMessage id="devices.message" />
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Flex style={{ alignItems: 'center', paddingLeft: '15px' }} gap="20px">
              <Heading fontSize="36px" fontWeight="400" fontFamily="Helvetica">
                {singleDeviceData?.serial_number && !isLoading ? (
                  singleDeviceData?.serial_number
                ) : (
                  <CircularProgress size="30px" isIndeterminate />
                )}
              </Heading>
              {!isLoading && <StatusBadge status={singleDeviceData?.device_status?.status} />}
            </Flex>
          </div>
        </Box>
        <Box display="flex" h={`calc(100vh - 180px)`}>
          <Box borderRight="1px solid #3333331A">
            <OrganizationInfoSidebar routes={routes} />
          </Box>
          <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 470px)' : 'calc(100vw - 300px)' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DeviceSubSayout;
