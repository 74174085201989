import { userRequest } from 'api';
import { NavigateFunction } from 'react-router-dom';
import { useIntl } from 'react-intl';

export const SetupInterceptors = (navigate: NavigateFunction) => {
  const intl = useIntl();

  userRequest.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      var { response } = error;
      // Hanlding CORS error, check https://exoinc.atlassian.net/browse/DOPS-758 for more details.
      if (!response && error.code === 'ERR_NETWORK') {
        response = {
          status: 403,
          data: {
            auth_err_code: 'SESSION_ERR',
          },
        };
      }
      if (response && response.status) {
        const isLoggedIn = !!localStorage.getItem('exo_sso');
        // If the user clicked on a shared url, they are not logged in.
        // Don't take them back to the login page, since they cannot log in.
        if ((response.status === 401 || response.status === 403) && isLoggedIn) {
          const searchParams = new URLSearchParams();
          searchParams.append('error', 'access_denied');

          // Differentiate between a session based rejection vs others
          if (response.data?.auth_err_code === 'SESSION_ERR') {
            searchParams.append('error_description', intl.formatMessage({ id: 'session_error' }));
          } else {
            searchParams.append('error_description', intl.formatMessage({ id: 'generic_401_403' }));
          }

          const pathname = '/';
          const search = searchParams.toString();
          navigate({
            pathname,
            search,
          });
        }

        if (response.status === 418 && isLoggedIn) {
          const { data } = response;
          const pathname = 'error/418';
          navigate(pathname, {
            state: {
              error: 'authorization_error_418.message',
              description: `authorization_error_418.${data.detail[0]}`,
            },
          });
        }
      }
      return Promise.reject(error);
    },
  );
};
