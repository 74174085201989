import { Box, useDisclosure } from '@chakra-ui/react';
import { CircleRightEnabledRedIcon } from 'assets/icons/icons';
import CustomDeleteModal from 'components/customModal/CustomDeleteModal';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  handleCreateButtonEnableInModal,
  resetCustomModalEnableFields,
} from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetOrganizationCreateActions } from 'redux/organizations/Reducers/organizationSlice';
import {
  handleClientPostDataCreateButtonEnable,
  handleClientSuccessfulPost,
  handlePostApiDataSpinner,
  handleToPostApiData,
  resetCreateSettingsPopUpValues,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { handleDeleteEditPulseUser, resetUserValidationValues } from 'redux/user/Reducers/userSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { Error_Type_Enum } from 'utils/enum';
import { CircleRightEnabledIcon } from '../../../../../../../libs/shared-ui/src/lib/assets/icons/icons';
import DeletePulseUserStatusInputField from './DeletePulseUserInputField';

const DeletePulseUserStatusModal = () => {
  const dispatch = useAppDispatch();
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const orgName = useAppSelector((state) => state.user.singlePulseUserData);
  const postDataSuccessfulCreation = useAppSelector((state) => state.clientAccess.enableSuccessfulPostData);
  const enableCreateButton = useAppSelector((state) => state.customInputFields.handleCreateButtonEnable);
  const deleteEditPulseUser = useAppSelector((state) => state.user.deleteEditPulseUser);
  const createClientPostApiFail = useAppSelector((state) => state.organization.orgDeletePostDataFailStrings);
  const postFailure = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const toggleClientButtonEnableSpinnerValue = useAppSelector((state) => state.clientAccess.postClientApiDataSpinner);

  useEffect(() => {
    if (createClientPostApiFail.length > 0) {
      dispatch(handleClientSuccessfulPost(true));
    }
    if (createClientPostApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetCreateSettingsPopUpValues());
        dispatch(resetUserValidationValues());
        dispatch(resetOrganizationCreateActions());
        dispatch(handleCreateButtonEnableInModal(false));
        dispatch(handleClientPostDataCreateButtonEnable(false));
        navigate('/users');
        onClose();
      }, ModalTimeOut);
    }
  }, [createClientPostApiFail]);
  const handleModalClose = () => {
    dispatch(handleDeleteEditPulseUser(false));
    dispatch(resetOrganizationCreateActions());
    dispatch(resetCreateSettingsPopUpValues());
    dispatch(resetCustomModalEnableFields());
  };
  const handleCreateButtonClick = () => {
    dispatch(handleToPostApiData(true));
    dispatch(handlePostApiDataSpinner(true));

    if (!deleteEditPulseUser) {
      dispatch(handleClientSuccessfulPost(false));
    }
  };

  return (
    <Box>
      <CustomDeleteModal
        openModal={deleteEditPulseUser}
        closeModal={handleModalClose}
        modalCloseIconDisabled={!createClientPostApiFail && toggleClientButtonEnableSpinnerValue}
        postFailure={postFailure}
        enableCreateButton={enableCreateButton}
        postDataSuccessfulCreation={postDataSuccessfulCreation}
        handleModalButtonClick={handleCreateButtonClick}
        toggleModalButtonEnableSpinner={toggleClientButtonEnableSpinnerValue}
        InputFieldsComponent={DeletePulseUserStatusInputField}
        modalHeader="are_you_sure_you_want_to_delete"
        modalButtonName="delete"
        modalHeaderPassingName={orgName.first_name}
        RightEnabledIcon={CircleRightEnabledRedIcon}
        colorMode="#FB5654"
        modalHeaderPassingLastName={orgName.last_name}
      />
    </Box>
  );
};
export default DeletePulseUserStatusModal;
