import { useState, useEffect } from 'react';
import { Text, Box, Input, List, ListItem, Spinner, Link, Wrap } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import { searchCustomers } from 'redux/customers/cutomersAction';
import { clearSearchResults } from 'redux/customers/customersSlice';
import { customer } from 'types/customer';
import { useNavigate } from 'react-router-dom';
import { toggleAddOrgModal } from 'redux/organizations/Reducers/organizationSlice';
import { AutoCompleteContainer, AutoCompleteItem, AutoCompleteItemButton } from './Styles';
import { handleCustomerModal } from 'redux/customers/customersSlice';

interface autoCompleteProps {
  optionsStyle?: React.CSSProperties;
  textSize?: string;
  onSelect: (customer: customer) => void;
  clearSelectedCustomer: () => void;
  selectedCustomer: customer | undefined;
}

export const InputCutomerAutoComplete = ({
  optionsStyle,
  textSize,
  onSelect,
  clearSelectedCustomer,
  selectedCustomer,
}: autoCompleteProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = customColor();
  const intl = useIntl();
  const customersSearchResults = useAppSelector((state) => state.customers.customersSearchResults);
  const [searchText, setSearchText] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (event: any) => {
    let val = event.target.value;
    setSearchText(val);
  };

  const handleCustomerSelect = (customer: customer) => {
    setShowSuggestions(false);
    setSearchText('');
    dispatch(clearSearchResults());
    onSelect(customer);
  };

  const navigateToCustomers = () => {
    dispatch(toggleAddOrgModal(false));
    dispatch(handleCustomerModal(true));
    navigate('/customers?tab=customers');
  };

  useEffect(() => {
    setShowError(false);
    setShowSuggestions(false);
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length > 2) {
        clearSelectedCustomer();
        setIsLoading(true);
        dispatch(searchCustomers(searchText, 'customer_name'));
      }
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    setShowError(searchText.length > 2 && !customersSearchResults.length);
    setShowSuggestions(!!customersSearchResults.length);
    setIsLoading(false);
  }, [customersSearchResults]);

  return (
    <Box position="relative" width="305px" data-testid="container" style={{ position: 'relative' }}>
      <Box>
        <InputLabel value={searchText || selectedCustomer?.customer_name} name={'customer_name'} />
        <Box position="relative">
          <Input
            color={colors.subTextColor}
            name="Customer Name"
            borderColor="#E0E0E0"
            fontSize="14px"
            focusBorderColor={colors.primaryColor}
            value={searchText}
            onChange={handleOnChange}
            borderBottomColor={colors.inputBorderColor}
            borderWidth="0 0 1px 0"
            placeholder={
              selectedCustomer ? selectedCustomer.customer_name : intl.formatMessage({ id: 'customer_name.message' })
            }
            type="text"
            variant="flushed"
            data-testid={'customer-typeahead'}
          />
          {isLoading && (
            <Spinner position="absolute" right="5px" top="12px" h="16px" w="16px" color={colors.primaryColor} />
          )}
        </Box>
      </Box>
      <Wrap>
        {showSuggestions && (
          <AutoCompleteContainer style={optionsStyle}>
            {customersSearchResults &&
              customersSearchResults.map((customer: customer) => (
                <AutoCompleteItem key={customer.id}>
                  <AutoCompleteItemButton
                    key={customer.id}
                    name={customer.customer_name}
                    onClick={() => handleCustomerSelect(customer)}
                  >
                    {customer.customer_name}
                  </AutoCompleteItemButton>
                </AutoCompleteItem>
              ))}
          </AutoCompleteContainer>
        )}
      </Wrap>
      {showError && (
        <Box fontSize="14px">
          <Text color="red">No Customer found, create a customer</Text>
          <Text mt="20px">
            <Link mr="4px" color={colors.primaryColor} onClick={navigateToCustomers} data-testid="click-here">
              Click here
            </Link>{' '}
            to create new customer
          </Text>
        </Box>
      )}
    </Box>
  );
};
