import { AbsoluteCenter, Box, CircularProgress } from '@chakra-ui/react';
import React from 'react';

const Loader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Box>
      {isLoading && (
        <Box  h="100%" display="flex"  alignItems="center" justifyContent="center">
          <AbsoluteCenter p="4" axis="both">
            <CircularProgress isIndeterminate size="50px" />
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
};
export default Loader;
