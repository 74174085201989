/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from '../../../api';
import {
  editUserPostDataApiFail,
  editUserPostDataApiLoading,
  editUserPostDataApiSuccess,
  fetchPostUserFormDataFail,
  fetchPostUserFormDataLoading,
  fetchPostUserFormDataSuccess,
  fetchRegionFail,
  fetchRegionLoading,
  fetchRegionSuccess,
  fetchRolesFail,
  fetchRolesLoading,
  fetchRolesSuccess,
  fetchUserEmailDataSuccess,
  fetchUserEmailFail,
  fetchUserEmailLoading,
  fetchUserPostApiFail,
  fetchUserPostToggle,
  fetchUsersFail,
  fetchUsersLoading,
  fetchUserStatusesFail,
  fetchUserStatusesLoading,
  fetchUserStatusesSuccess,
  fetchUsersuccess,
  fetchVersionFail,
  fetchVersionLoading,
  fetchVersionSuccess,
  singlePulseUserDataFail,
  singlePulseUserValue,
  singlePulseUserValueLoading,
  setUserPermissions,
} from '../Reducers/userSlice';
import { Dispatch } from 'react';
import { UserEmailvalidation, UserPostData, UserPermissions } from 'types/user-types';
import { Error_Type_Enum } from 'utils/enum';
import { setError } from '../../error/errorSlice';
import {
  getOrgDeletePostDataFailStrings,
  handlePostApiFailureMessage,
} from 'redux/organizations/Reducers/organizationSlice';
import {
  getClientPostDataSuccess,
  getClientPostDataFail,
  getClientPostDataLoading,
  getClientPostDataFailStrings,
} from 'redux/settings/clientAccess/Reducers/clientAccessSlice';

interface IGetAllAssets {
  type: boolean | string;
}
export interface apiConfig {
  baseUrl: string;
  method: string;
}

export const getUserPermissions = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    const res = await userRequest.get(`/users/permissions`);
    const data = res.data.data;
    const permissions = {
      sideNavigation: data['menu_items'],
      organizations: data['organizations'],
      customers: data['customers'],
      devices: data['devices'],
      bundles: data['bundles'],
      users: data['users'],
      settings: data['settings'],
      userRole: data['userRole'],
    } as UserPermissions;
    dispatch(setUserPermissions(permissions));
  } catch (error: any) {}
};

export const getUsers = (queryString?: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchUsersLoading());

  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`/users/?${queryString}`);
    if (res && res.status === 200) {
      dispatch(fetchUsersuccess(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchUsersFail());
  }
};

export const getUsersRoles = (id: number) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchRolesLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`users/roles/?service_type=${id}`);
    if (res && res.status === 200) {
      res.data.data = res.data.data.map((role: any) => {
        return {
          id: role.id,
          roleType: role.role_type,
          role: role.role_name,
          permissions: role.permissions,
        };
      });
      dispatch(fetchRolesSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchRolesFail());
  }
};

export const getUsersStatuses = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchUserStatusesLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('users/status/');
    if (res && res.status === 200) {
      dispatch(fetchUserStatusesSuccess(res.data.data ? res?.data?.data : []));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchUserStatusesFail());
  }
};

export const postUsersData = (postData: UserPostData) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchPostUserFormDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`users/add/`, postData);
    if (res && (res.data.status_code === 200 || res.data.status_code === 201)) {
      dispatch(fetchPostUserFormDataSuccess(res.data.result));
      dispatch(fetchUserPostApiFail(Error_Type_Enum.Success));
      dispatch(fetchPostUserFormDataFail(false));
    } else {
      dispatch(fetchPostUserFormDataFail(true));
      dispatch(handlePostApiFailureMessage(res?.data?.errors?.user_error_obj?.error));
    }
  } catch (error: any) {
    // dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchUserPostToggle(true));
    dispatch(handlePostApiFailureMessage(error?.response?.data?.errors?.user_error_obj?.error));
    dispatch(fetchPostUserFormDataFail(true));
    dispatch(fetchUserPostApiFail(Error_Type_Enum.Fail));
  }
};

export const getUserEmailValidation =
  (postEmailValidation: UserEmailvalidation) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchUserEmailLoading());
    try {
      dispatch(setError({ isError: true, errorMessage: '' }));
      const res = await userRequest.post(`users/field/exists/`, postEmailValidation);
      if (res && res.data.status_code === 404) {
        dispatch(fetchUserEmailDataSuccess(postEmailValidation.field_value));
        dispatch(fetchUserEmailFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchUserEmailFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchUserEmailFail(false));
    }
  };

export const getRegions = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchRegionLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`regions/`);
    if (res && res.status === 200) {
      dispatch(fetchRegionSuccess(res.data.data ? res.data.data : []));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchRegionFail());
  }
};
export const getSinglePulseUserData = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(singlePulseUserValueLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`users/${id}`);
    if (res && res.status === 200) {
      dispatch(singlePulseUserValue(res.data.data));
      dispatch(singlePulseUserDataFail(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(singlePulseUserDataFail(true));
  }
};
export const updateUserLastLogin = (payload: any) => async () => {
  try {
    await userRequest.put('users', payload);
  } catch {}
};

export const editUsersData = (postedData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(editUserPostDataApiLoading(true));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`users/${id}/`, postedData);
    if (res && (res.data.status_code === 200 || res.data.status_code === 201)) {
      dispatch(editUserPostDataApiFail(Error_Type_Enum.Success));
      dispatch(editUserPostDataApiSuccess(true));
      dispatch(editUserPostDataApiLoading(false));
    } else {
      dispatch(editUserPostDataApiFail(Error_Type_Enum.Fail));
      dispatch(editUserPostDataApiSuccess(false));
      dispatch(editUserPostDataApiLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(editUserPostDataApiLoading(false));
  }
};

export const postUserDeleteData = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.delete(`users/${id}/delete/`);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataSuccess(res.data.result));
      dispatch(getOrgDeletePostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
      // dispatch(postApiSuccess(true))
    } else {
      dispatch(getClientPostDataFail(true));
      dispatch(getOrgDeletePostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(getOrgDeletePostDataFailStrings(Error_Type_Enum.Fail));
  }
};

export const postUserDeactivateData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`users/${id}/deactivate/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataSuccess(res.data.result));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
      // dispatch(postApiSuccess(true))
    } else {
      dispatch(getClientPostDataFail(true));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};
export const postUserActivateData = (postData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`users/${id}/activate/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(getClientPostDataSuccess(res.data.result));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Success));
      dispatch(getClientPostDataFail(false));
      dispatch(getClientPostDataLoading(false));
      // dispatch(postApiSuccess(true))
    } else {
      dispatch(getClientPostDataFail(true));
      dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
      dispatch(getClientPostDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getClientPostDataFail(true));
    dispatch(getClientPostDataFailStrings(Error_Type_Enum.Fail));
  }
};

export const getVersion = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchVersionLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`/version`);
    if (res && res.status === 200) {
      res.data.data = {
        buildVersion: res.data.data.build_version,
        releaseVersion: res.data.data.release_version,
      };
      dispatch(fetchVersionSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchVersionFail());
  }
};
