import { Box } from '@chakra-ui/react';
import { AlertIcon } from '../../assets';
import ErrorDescriptionModal from '../../components/ErrorDescriptionModal/ErrorDescriptionModal';
import { UserStatus } from '../../utils/constants';
import { FormikProps } from 'formik';

interface InactiveModalProps {
  searchParams: URLSearchParams;
  formik: FormikProps<{ email: string; password: string }>;
}

const InactiveModal = ({ searchParams, formik }: InactiveModalProps) => {
  return searchParams.get('status') === UserStatus.IDLE && !formik.values.email ? (
    <Box minH="64px">
      <ErrorDescriptionModal
        icon={<AlertIcon />}
        heading="inactivity_message.description"
        message="inactivity_message.message"
      />
    </Box>
  ) : null;
};

export default InactiveModal;
