/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';

import { Dispatch } from 'react';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import { DeviceTypePostData, DeviceTypeUniqueValidations } from 'types/device-types';
import { Error_Type_Enum } from 'utils/enum';
import { setError } from '../../error/errorSlice';

import {
  getDeviceTypeStatusDataLoading,
  getDeviceTypeStatusDataSuccess,
  getDeviceTypeStatusDataFail,
  postDeviceTypeFormDataLoading,
  postDeviceTypeFormDataSuccess,
  postDeviceTypeApiFail,
  postDeviceTypeFormDataFail,
  fetchDevicePostDataFailToggle,
  getDeviceTypesDataFail,
  getDeviceTypesDataLoading,
  getDeviceTypesData,
  getSingleDeviceTypeData,
  getSingleDeviceTypeDataFail,
  editDeviceTypeApiSuccess,
  editDeviceTypeFail,
  editDeviceTypeLoading,
  fetchDeviceTypeModelIdentifierUniqueValidation,
  fetchDeviceTypeModelIdentifierUniqueValidationFail,
  fetchDeviceTypeCatalogueUniqueValidationFail,
  fetchDeviceTypeCatalogueUniqueValidation,
  fetchDeviceTypeInternalNameUniqueValidation,
  fetchDeviceTypeInternalNameUniqueValidationFail,
  fetchDeviceTypeNameUniqueValidation,
  fetchDeviceTypeNameUniqueValidationFail,
} from '../Reducers/DeviceTypeSlice';

interface IGetAllAssets {
  type: boolean | string;
}
export const getDeviceTypeStatus = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(getDeviceTypeStatusDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`catalogues/types/status/`);
    if (res && res.status === 200) {
      dispatch(getDeviceTypeStatusDataSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getDeviceTypeStatusDataFail());
  }
};

export const getDeviceTypesApiData = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(getDeviceTypesDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('catalogues/');
    if (res && res.status === 200) {
      dispatch(getDeviceTypesData(res.data.data.existing_catalogues));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getDeviceTypesDataFail());
  }
};
export const getSingleDeviceTypesData = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`catalogues/${id}`);
    if (res && res.status === 200) {
      dispatch(getSingleDeviceTypeData(res.data.data));
      dispatch(getSingleDeviceTypeDataFail(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(getSingleDeviceTypeDataFail(true));
  }
};

export const postDeviceTypesData = (postData: DeviceTypePostData) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(postDeviceTypeFormDataLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`catalogues/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(postDeviceTypeFormDataSuccess(res.data.result));
      dispatch(postDeviceTypeApiFail(Error_Type_Enum.Success));
      dispatch(postDeviceTypeFormDataFail(false));
      dispatch(fetchDevicePostDataFailToggle(false));
      // dispatch(postApiSuccess(true))
    } else {
      dispatch(postDeviceTypeFormDataFail(true));
      dispatch(postDeviceTypeApiFail(Error_Type_Enum.Fail));
      // dispatch(handlePostApiFailureMessage(res?.data?.message[0]));
    }
  } catch (error: any) {
    // dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchDevicePostDataFailToggle(true));
    dispatch(handlePostApiFailureMessage(error?.response?.data?.errors[0]));
    dispatch(postDeviceTypeFormDataFail(true));
    dispatch(postDeviceTypeApiFail(Error_Type_Enum.Fail));
  }
};
export const editDeviceTypesData = (postedData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(editDeviceTypeLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`catalogues/${id}/`, postedData);
    if (res && (res.status === 200 || res.status === 201)) {
      dispatch(editDeviceTypeApiSuccess(res.data.result));
      dispatch(editDeviceTypeFail(Error_Type_Enum.Success));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(editDeviceTypeFail(Error_Type_Enum.Fail));
  }
};
export const getModelIdentifierUniqueValidation =
  (PostUniqueValues: DeviceTypeUniqueValidations) => async (dispatch: Dispatch<IGetAllAssets>) => {
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`catalogues/field/exists/`, PostUniqueValues);
      if (res && res.data.status_code === 404) {
        dispatch(fetchDeviceTypeModelIdentifierUniqueValidation(PostUniqueValues.field_value));
        dispatch(fetchDeviceTypeModelIdentifierUniqueValidationFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchDeviceTypeModelIdentifierUniqueValidationFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchDeviceTypeModelIdentifierUniqueValidationFail(false));
    }
  };

export const getCatalogueUniqueValidation =
  (PostUniqueValues: DeviceTypeUniqueValidations) => async (dispatch: Dispatch<IGetAllAssets>) => {
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`catalogues/field/exists/`, PostUniqueValues);
      if (res && res.data.status_code === 404) {
        dispatch(fetchDeviceTypeCatalogueUniqueValidation(PostUniqueValues.field_value));
        dispatch(fetchDeviceTypeCatalogueUniqueValidationFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchDeviceTypeCatalogueUniqueValidationFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchDeviceTypeCatalogueUniqueValidationFail(false));
    }
  };

export const getDeviceTypeUniqueInternalNameValidation =
  (PostUniqueValues: DeviceTypeUniqueValidations) => async (dispatch: Dispatch<IGetAllAssets>) => {
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`catalogues/field/exists/`, PostUniqueValues);
      if (res && res.data.status_code === 404) {
        dispatch(fetchDeviceTypeInternalNameUniqueValidation(PostUniqueValues.field_value));
        dispatch(fetchDeviceTypeInternalNameUniqueValidationFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchDeviceTypeInternalNameUniqueValidationFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchDeviceTypeInternalNameUniqueValidationFail(false));
    }
  };

export const getDeviceTypeUniqueNameValidation =
  (PostUniqueValues: DeviceTypeUniqueValidations) => async (dispatch: Dispatch<IGetAllAssets>) => {
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.post(`catalogues/field/exists/`, PostUniqueValues);
      if (res && res.data.status_code === 404) {
        dispatch(fetchDeviceTypeNameUniqueValidation(PostUniqueValues.field_value));
        dispatch(fetchDeviceTypeNameUniqueValidationFail(false));
      } else if (res && res.data.status_code === 200) {
        dispatch(fetchDeviceTypeNameUniqueValidationFail(true));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(fetchDeviceTypeNameUniqueValidationFail(false));
    }
  };
