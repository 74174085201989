import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { FormikProps } from 'formik';
import { useState, useEffect } from 'react';
import { SharedUiState } from '../../redux/hooks';
import { setBaseUrl, LoginState, resetPasswordError, setIsSsoEnabledUser } from '../../redux/login.slice';
import { getSingleSignInUser } from '../../redux/login.thunk';
import { InitialFormValues } from '../../types/types';
import getAccessToken from '../../utils/getAccessToken';

export const useLoginEffect = (
  formik: FormikProps<InitialFormValues>,
  loginSliceData: LoginState,
  dispatch: ThunkDispatch<SharedUiState, unknown, AnyAction>,
  handleLogin: (idToken: string | undefined) => void,
  loginURL?: string,
) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showInvaliduser, setShowInvalidUser] = useState(false);
  const [registeredUser, setRegisteredUser] = useState(false);

  useEffect(() => {
    if (loginURL != null) {
      dispatch(setBaseUrl(loginURL));
    } else {
      const url = process.env.NX_LOGIN_API_BASE_URL || '';
      dispatch(setBaseUrl(url));
    }
  }, []);

  useEffect(() => {
    if (loginSliceData.singleSignOnUser.org_id.length > 0) {
      setRegisteredUser(true);
    }
    const accessToken = getAccessToken();
    if (accessToken && accessToken.length > 0) {
      handleLogin(accessToken);
    } else if (loginSliceData.user?.access_token && loginSliceData.user?.access_token.length > 0) {
      handleLogin(loginSliceData.user?.access_token);
    }
  }, [
    loginSliceData.singleSignOnUser,
    loginSliceData.isPasswordRequired,
    loginSliceData.user?.access_token,
    formik.values.email,
  ]);

  useEffect(() => {
    if (loginSliceData.singleSignOnError || loginSliceData.user) {
      dispatch(resetPasswordError());
    }
    setShowInvalidUser(false);
  }, [formik.values.password]);

  useEffect(() => {
    setShowPassword(false);
    if (formik.values.email.length < 3 && formik.errors.email) {
      setShowPassword(false);
    }
    if (!formik.values.email) {
      formik.touched.email = false;
      dispatch(setIsSsoEnabledUser(false));
    }
    if (formik.errors.email) {
      formik.values.password = '';
    }
    setShowInvalidUser(false);
  }, [formik.values.email, formik.errors.email]);

  return {
    showPassword,
    setShowPassword,
    showInvaliduser,
    setShowInvalidUser,
    registeredUser,
    setRegisteredUser,
  };
};
