/* eslint-disable max-lines */ /* max lines rule is disabled since oraganization create form requries actions for input fields data updates*/ import { createSlice } from '@reduxjs/toolkit';
import { OrgDevice } from '../../../types/organization-types';
import { PaginationTypes } from 'types/pagination-types';
type InitialState = {
  searchText: string;
  bundleLicenseList: any;
  statusLoading: boolean;
  statusError: boolean;
  bundleLicenseListLoading: boolean;
  bundleLicenseError: boolean;
  createBundleLicenseApiFail: string;
  filterData: { status_id: string[] };
  selectedPage: number;
  bundleLicenseApiData: any;
  bundleLicensenumberOfPages: number;
  bundleLicensepaginationData: PaginationTypes | null;
  bundleLicenseDataLoading: boolean;
  bundleLicenseDataError: boolean;
  bundleLicenseGetData: any;
  bundleLicenseGetDataLoading: boolean;
  bundleLicenseGetDataError: boolean;
  bundleLicenseID: string | number;
  isSearchExpand: boolean;
  selectedOrderValue: number;
  bundleLicenseGridView: boolean;
};
export const initialState: InitialState = {
  searchText: '',
  bundleLicenseList: [],
  statusLoading: false,
  statusError: false,
  bundleLicenseListLoading: false,
  bundleLicenseError: false,
  selectedPage: 1,
  createBundleLicenseApiFail: '',
  bundleLicenseApiData: null,
  bundleLicensenumberOfPages: 1,
  filterData: { status_id: [] },
  bundleLicensepaginationData: null,
  bundleLicenseDataLoading: false,
  bundleLicenseDataError: false,
  bundleLicenseGetData: [],
  bundleLicenseGetDataLoading: false,
  bundleLicenseGetDataError: false,
  bundleLicenseID: '',
  isSearchExpand: false,
  selectedOrderValue: 0,
  bundleLicenseGridView: true,
};
const bundleLicenseSlice = createSlice({
  name: 'bundleLicense',
  initialState,
  reducers: {
    toggleLicenseView: (state, { payload }) => {
      state.bundleLicenseGridView = payload;
    },
    getSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    expandSearchInMobile: (state) => {
      state.isSearchExpand = true;
    },
    closeSearchInMobile: (state) => {
      state.isSearchExpand = false;
      state.searchText = '';
    },
    clearSearcText: (state) => {
      state.searchText = '';
    },
    getBundleLicenseFlterData: (state, { payload }) => {
      state.filterData = payload;
    },
    getBundleLicensePageNumber: (state, { payload }) => {
      state.selectedPage = payload;
    },

    fetchCreateBundleLicenseApiFail: (state, { payload }) => {
      state.createBundleLicenseApiFail = payload;
    },
    fetchBundleLicenseLoading: (state) => {
      state.bundleLicenseListLoading = true;
    },
    fetchBundleLicenseFail: (state) => {
      state.bundleLicenseError = true;
      state.bundleLicenseListLoading = false;
    },
    fetchBundleLicenseSuccess: (state, { payload }) => {
      state.bundleLicenseList = payload?.data?.license_items;
      state.bundleLicenseApiData = payload?.data;
      state.bundleLicensenumberOfPages = payload?.pagination?.num_pages;
      state.bundleLicensepaginationData = payload?.pagination;
      state.bundleLicenseListLoading = false;
    },
    fetchBundleLicenseID: (state, { payload }) => {
      state.bundleLicenseID = payload;
    },

    getBundleLicenseFilterValue: (state, { payload }) => {
      state.selectedOrderValue = payload;
    },
  },
});
export const {
  getSearchText,
  clearSearcText,
  expandSearchInMobile,
  closeSearchInMobile,
  getBundleLicensePageNumber,
  fetchBundleLicenseLoading,
  fetchBundleLicenseFail,
  fetchBundleLicenseSuccess,
  fetchCreateBundleLicenseApiFail,
  getBundleLicenseFlterData,

  fetchBundleLicenseID,
  getBundleLicenseFilterValue,
} = bundleLicenseSlice.actions;
export default bundleLicenseSlice.reducer;
