import { Flex, Image, Text, VStack, useColorModeValue, Box } from '@chakra-ui/react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { checkmark, error } from '../../assets/icons';
import { ExitIcon } from '../../assets/icons/icons';

type Props = {
  titleFormattedId: string;
  descriptionFormattedId?: string;
  onClose: () => void;
  type: 'success' | 'error' | 'loading';
};

export const Toast = (props: Props) => {
  const bgColor = useColorModeValue(
    'linear-gradient(0deg, rgba(52, 199, 89, 0.05) 0%, rgba(52, 199, 89, 0.05) 100%), #FFF',
    'linear-gradient(0deg, rgba(104, 100, 221, 0.10) 0%, rgba(104, 100, 221, 0.10) 100%), #1A1C22',
  );
  const textColor = useColorModeValue('#155024', '#D2D1F5');
  const timeTextColor = useColorModeValue('rgba(51, 51, 51, 0.60)', '#E7E9ED99');

  return (
    <Flex
      width={'470px'}
      p={'20px 30px'}
      bg={bgColor}
      borderRadius={'10px'}
      boxShadow={'0px 1px 20px 0px rgba(0, 0, 0, 0.25)'}
      border={'1px solid'}
      borderColor={props.type === 'success' ? '#34C759' : '#FF2D55'}
    >
      <Flex width={'21px'} alignItems="flex-start" mr="20px" mt="3px">
        {props.type === 'success' && <Image src={checkmark} />}
        {props.type === 'error' && <Image src={error} />}
      </Flex>
      <VStack alignItems={'flex-start'} flex={1} gap="5px">
        <Text fontSize={'16px'} fontWeight={700} color={textColor}>
          <FormattedMessage id={props.titleFormattedId} />
        </Text>
        {props.descriptionFormattedId && (
          <Text fontSize={'16px'} fontWeight={500} color={textColor}>
            <FormattedMessage id={props.descriptionFormattedId} />
          </Text>
        )}
        <Text fontSize={'14px'} fontWeight={500} color={timeTextColor} mt="2px">
          <FormattedTime value={new Date().getTime()} />
        </Text>
      </VStack>
      <Flex alignItems={'flex-start'}>
        <Box cursor="pointer" position={'relative'} top={'-10px'} right={'-20px'} onClick={props.onClose}>
          <ExitIcon color={timeTextColor} />
        </Box>
      </Flex>
    </Flex>
  );
};
