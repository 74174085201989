import { useParams } from 'react-router-dom';
import { userAccessConst } from 'utils/constants/userAccessConst';

export function getOrgRoutes(userPermissions: string[]) {
  const params = useParams();
  const userOrgAccess = userAccessConst.organization;
  return [
    {
      id: 1,
      pathname: `/organizations/${params.orgId}/info`,
      name: 'information',
      value: 'Information',
      hasAccess: userPermissions?.includes(userOrgAccess.viewOrg),
    },
    {
      id: 2,
      pathname: `/organizations/${params.orgId}/users`,
      name: 'users',
      value: 'Users',
      hasAccess: userPermissions?.includes(userOrgAccess.listUsers),
    },
    {
      id: 3,
      pathname: `/organizations/${params.orgId}/users-activity`,
      name: 'user_activity',
      value: 'User Activity',
      hasAccess: userPermissions?.includes(userOrgAccess.listAllUsrActivity),
    },
    {
      id: 4,
      pathname: `/organizations/${params.orgId}/devices`,
      name: 'device_info',
      value: 'Device Info',
      hasAccess: userPermissions?.includes(userOrgAccess.listDevices),
    },
    {
      id: 5,
      pathname: `/organizations/${params.orgId}/devices-activity`,
      name: 'device_activity',
      value: 'Device Activity',
      hasAccess: userPermissions?.includes(userOrgAccess.listAllDeviceActivity),
    },
    {
      id: 6,
      pathname: `licenses/bundles`,
      name: 'subscriptions',
      value: 'Subscriptions',
      hasAccess: userPermissions?.includes(userOrgAccess.listLicenses),
      children: [
        {
          id: 7,
          pathname: `licenses/bundles`,
          name: 'license_bundles',
          value: 'License Bundles',
          hasAccess: userPermissions?.includes(userOrgAccess.listLicenses),
        },
        {
          id: 8,
          pathname: `licenses/add-ons`,
          name: 'add-ons',
          value: 'License Addons',
          hasAccess: userPermissions?.includes(userOrgAccess.listLicenses),
        },
        {
          id: 9,
          pathname: `licenses/features`,
          name: 'features',
          value: 'License Features',
          hasAccess: userPermissions?.includes(userOrgAccess.listLicenses),
        },
      ],
    },
  ];
}

export function getOrgUserRoutes(userPermissions: string[]) {
  const params = useParams();
  const userOrgAccess = userAccessConst.organization;
  return [
    {
      id: 1,
      pathname: `/organizations/${params.orgId}/users/${params.userId ? params.userId : ''}/info`,
      name: 'information',
      hasAccess: userPermissions?.includes(userOrgAccess.viewUser),
    },
    {
      id: 2,
      pathname: `/organizations/${params.orgId}/users/${params.userId ? params.userId : ''}/activity`,
      name: 'user_activity',
      hasAccess: userPermissions?.includes(userOrgAccess.listUserActivity),
    },
  ];
}

export function getOrgDeviceRoutes(userPermissions: string[]) {
  const params = useParams();
  const userOrgAccess = userAccessConst.organization;
  return [
    {
      id: 1,
      pathname: `/organizations/${params.orgId}/devices/${params.deviceId ? params.deviceId : ''}/info`,
      name: 'information',
      hasAccess: userPermissions?.includes(userOrgAccess.viewDevice),
    },
    {
      id: 2,
      pathname: `/organizations/${params.orgId}/devices/${params.deviceId ? params.deviceId : ''}/activity`,
      name: 'device_activity',
      hasAccess: userPermissions?.includes(userOrgAccess.listDeviceActivity),
    },
  ];
}
