import React from 'react';
import { Box } from '@chakra-ui/react';
import NoDataFound from 'components/NoDataFound';
import Loader from 'components/loader/Loader';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';

export default function SingleUserActivityLandingScreen({ isFiltersApplied }: { isFiltersApplied: boolean }) {
  const isLoading = useAppSelector((state: RootState) => state.singleUserActivity.singleUserActivityDataLoading);
  return (
    <Box data-testid="landing-screen" h={`calc(100vh - 250px)`}>
      {' '}
      <Loader isLoading={isLoading} />
      {!isLoading && <NoDataFound title="User Activity" isFiltersApplied={isFiltersApplied} />}
    </Box>
  );
}
