import React from 'react';
import { Box, Text, Image, useColorModeValue } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import eclipse from '../../assets/images/eclipse.svg';

const UnAuthorized: React.FC = () => {
  return (
    <Box display="flex" h={{ xs: 'calc(100vh - 140px)', lg: 'calc(100vh - 140px)' }}>
      <Box
        w={{ xs: '100%', md: '60%' }}
        ml={{ xs: '20px', lg: '15%' }}
        display="flex"
        mt="-150px"
        flexDirection="column"
        justifyContent="center"
        data-testid="api-error"
        fontFamily="Helvetica"
      >
        <Box>
          <Text fontSize="48px" fontWeight="300">
            <FormattedMessage id="un_authorized_error.heading.message" />
          </Text>
        </Box>
        <Box h="20px">
          <Text fontSize="24px" fontWeight="300">
            <FormattedMessage id="un_authorized_error.sub_heading.message" />
          </Text>
        </Box>
      </Box>
      <Box
        w={{ xs: '0%', md: '40%' }}
        h="100%"
        position="relative"
        alignItems="center"
        display="flex"
        data-testid="Image"
      >
        <Image
          display={{ xs: 'none', md: 'block' }}
          src={eclipse}
          minW="521px"
          minH="521px"
          position="absolute"
          right={{ md: '-300px' }}
          data-testid="image-landingsection"
        />
      </Box>
    </Box>
  );
};

export default UnAuthorized;
