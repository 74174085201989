import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Wrap, WrapItem, Menu, MenuButton, Button, Icon, MenuList, MenuItem, Box, Text } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  fetchPostUserData,
  fetchPostUserFormDataFail,
  fetchUserCreateButtonEnableValue,
  fetchUserPostToggle,
  fetchUserRolePostData,
  fetchUserRoleValue,
  fetchUserSuccessCreation,
  getUserCreateButtonEnable,
  handleRoleFieldValidation,
} from 'redux/user/Reducers/userSlice';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserRolePermission } from 'types/user-types';

const UserRoleInputField = () => {
  const colors = customColor();
  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.user.roles);
  const selectedRole = useAppSelector((state) => state.user.userRoleValue);
  const postDataFail = useAppSelector((state) => state.user.postUserFormDataFail);
  const userRoleValue = useAppSelector((state) => state.user.userRoleValue);
  const roleFieldValidation = useAppSelector((state) => state.user.roleFieldValidation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRole = (e: any) => {
    dispatch(fetchUserRoleValue(e.target.name));
    dispatch(fetchUserRolePostData(e.target.value));
    if (postDataFail) {
      dispatch(fetchUserCreateButtonEnableValue(false));
      dispatch(fetchPostUserData(false));
      dispatch(fetchUserPostToggle(false));
      dispatch(fetchPostUserFormDataFail(false));
      dispatch(getUserCreateButtonEnable(false));
      dispatch(fetchUserSuccessCreation(false));
    }
  };
  const handleRoleOnBlur = () => {
    if (!selectedRole) {
      dispatch(handleRoleFieldValidation(true));
    } else {
      dispatch(handleRoleFieldValidation(false));
    }
  };

  return (
    <Box>
      <Wrap mt="5px" ml="5px" minH="70px">
        <WrapItem>
          <Box>
            <InputLabel value={selectedRole} name="user_role" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    fontSize="14px"
                    data-testid="license-info"
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {selectedRole ? (
                      <Text float="left" marginLeft="-15px" fontWeight="400" color={colors.subTextColor}>
                        {selectedRole}
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="user_role.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <>
                    {isOpen ? (
                      ''
                    ) : (
                      <Text fontSize="12px" color="#FB5654">
                        {roleFieldValidation ? <FormattedMessage id="field_validation.message" /> : ''}
                      </Text>
                    )}
                  </>

                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {roles &&
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      roles.map((role: UserRolePermission) => (
                        <MenuItem
                          key={role.id}
                          value={role.id}
                          name={role.role}
                          backgroundColor="transparent"
                          onBlur={handleRoleOnBlur}
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={handleRole}
                        >
                          {role.role}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default UserRoleInputField;
