import { Box, Flex, Text } from '@chakra-ui/react';
import { PacsWarning } from '../warning/PacsUploadWarning';
import { FOLDER_TYPES } from '../../types';
export type SwitchOption = {
  name: string;
  selected: boolean;
  icon?: React.ReactElement;
};
type SwitchProps = {
  options: SwitchOption[];
  handleToggle: (option: string) => void;
  readonly?: boolean;
  containerCss?: React.CSSProperties;
  buttonCss?: React.CSSProperties;
  insights?: boolean;
  iconOnly?: boolean;
};
const Option = ({
  option,
  handleToggle,
  readonly,
  buttonCss,
  iconOnly,
  insights,
}: {
  option: SwitchOption;
  handleToggle: (option: string) => void;
  readonly?: boolean;
  buttonCss?: React.CSSProperties;
  insights?: boolean;
  iconOnly?: boolean;
}) => {
  const selectedFilter = localStorage.getItem('filter');
  const showPacsUploadWarning = selectedFilter == FOLDER_TYPES.OUTBOX && option.name === 'Info';
  return (
    <Flex
      flex={1}
      justifyContent="center"
      alignItems="center"
      background={
        option.selected
          ? readonly
            ? 'brandGrey.600'
            : iconOnly || insights
            ? 'brandWhite.50'
            : 'brandBlack.50'
          : iconOnly || insights
          ? 'transparent'
          : 'brandWhite.50'
      }
      borderRadius={iconOnly ? '8px' : '10px'}
      onClick={() => handleToggle(option.name)}
      boxShadow={iconOnly && option.selected ? '0px 3px 8px 0px rgba(0, 0, 0, 0.12)' : ''}
      py="5px"
      cursor={readonly ? 'not-allowed' : 'pointer'}
      sx={readonly ? { pointerEvents: 'none', ...buttonCss } : buttonCss}
      width={iconOnly ? '34px !important' : 'auto'}
      height={iconOnly ? '100%' : 'auto'}
    >
      {option.icon}
      {!insights ? (
        <Flex alignItems="center">
          <Text
            color={option.selected ? 'brandWhite.50' : readonly ? 'brandDarkGrey.500' : 'brandGrey.175'}
            pr={showPacsUploadWarning ? '5px' : '0px'}
          >
            {option.name}
          </Text>
          {showPacsUploadWarning && <PacsWarning isDark={option.selected} />}
        </Flex>
      ) : (
        <Text color="brandBlack.50">{option.name}</Text>
      )}
    </Flex>
  );
};

export const Switch = ({
  options,
  handleToggle,
  readonly,
  containerCss,
  buttonCss,
  insights,
  iconOnly,
}: SwitchProps) => (
  <Flex
    align="center"
    background={iconOnly ? 'rgba(118, 118, 128, 0.12)' : 'brandWhite.50'}
    border="1px solid rgba(51, 51, 51, 0.2)"
    borderRadius="10px"
    p="5px"
    h="46px"
    sx={containerCss}
  >
    {options.map((option, index) => (
      <Option
        key={index}
        option={option}
        handleToggle={handleToggle}
        iconOnly={iconOnly}
        readonly={readonly}
        buttonCss={buttonCss}
        insights={insights}
      />
    ))}
  </Flex>
);
