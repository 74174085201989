import React, { useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { postEmailResetPassword } from 'redux/orgUser/Actions/orguserActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import { useParams } from 'react-router-dom';

const ResetOrgUserInputFields = () => {
  const dispatch = useAppDispatch();
  const { orgId } = useParams();
  const postResetEmail = useAppSelector((state) => state.orgUser.SingleUsersGetData);
  const postClientApiData = useAppSelector((state) => state.clientAccess.postClientApiData);
  const clientPostDataFail = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const postData = {
    email: postResetEmail.email,
  };
  useEffect(() => {
    if (!clientPostDataFail && postClientApiData == true) {
      dispatch(postEmailResetPassword(orgId || '', postData));
    }
  }, [postClientApiData]);

  return (
    <>
      <Text fontSize="20px" fontWeight="400" color="rgba(51, 51, 51, 0.6)" fontFamily="helvetica">
        <FormattedMessage id="user_will_receive_a_link_to_their_mail_to_reset_the_password.message" />
      </Text>
      <Text mt="10px">
        {postApiFailureMessage ? <BannerMessage message={postApiFailureMessage} defaultMessage={''} /> : ''}
      </Text>
    </>
  );
};

export default ResetOrgUserInputFields;
