import { Box, Flex, Text, Grid, GridItem, Spinner, AbsoluteCenter } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import UserCountSection from './UserCountSection';
import OrganizationCountSection from './OrganizationCountSection';
import GraphSection from './GraphSection';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';
import { getDashboardData } from 'redux/dashbaord/Actions/dashboardActions';

function Dashboard() {
  const dispatch = useAppDispatch();
  const isDashboardDataLoading = useAppSelector((state) => state.dashboard?.dashboardDataLoading);

  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  return (
    <Box p="30px" overflowY="auto" h="calc(100vh - 80px)">
      <Flex justifyContent="space-between" alignItems="end">
        <Text
          data-testid="dashboard-landing-page"
          mr={{ xs: '3' }}
          fontSize={{ xs: '22px', xl: '24px' }}
          h="50px"
          fontWeight={700}
        >
          <FormattedMessage id="dashboard.message" />
        </Text>
        <Text fontSize="14px" fontWeight="400" mb="10px">
          <FormattedMessage
            id="dashboard_info_status.message"
            values={{
              b: (chunks) => <span style={{ fontWeight: '700' }}>{chunks}</span>,
            }}
          />
        </Text>
      </Flex>
      <Grid pb="30px" templateColumns={{ dt: '8fr 4fr' }} gap="20px">
        <GridItem minH="200px" borderRadius="12px" boxShadow="4px 4px 16px 4px rgba(0, 0, 0, 0.08)" position="relative">
          {isDashboardDataLoading ? (
            <AbsoluteCenter>
              <Spinner />
            </AbsoluteCenter>
          ) : (
            <OrganizationCountSection />
          )}
        </GridItem>
        <GridItem minH="200px" borderRadius="12px" boxShadow="4px 4px 16px 4px rgba(0, 0, 0, 0.08)" position="relative">
          {isDashboardDataLoading ? (
            <AbsoluteCenter>
              <Spinner />
            </AbsoluteCenter>
          ) : (
            <UserCountSection />
          )}
        </GridItem>
      </Grid>
      <Box p="1px 15px 15px 15px" borderRadius="12px" boxShadow=" 4px 4px 16px 4px rgba(0, 0, 0, 0.08)">
        <GraphSection />
      </Box>
    </Box>
  );
}

export default Dashboard;
