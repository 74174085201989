/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Wrap, WrapItem, Menu, MenuButton, Button, Icon, MenuList, Box, Text, MenuItem } from '@chakra-ui/react';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { customColor } from 'assets/styles/colors';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import moment from 'moment';
import BannerMessage from 'pages/organizations/components/organizations/OrganizationCreatePage/BannerMessage/BannerMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from 'redux/hooks';
import { Addon } from 'types/add-on';
import { CalendarIcons } from 'assets/icons/icons';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';

const OrgAddOnsInputFields = (props: any) => {
  const colors = customColor();
  const intl = useIntl();
  const calendarDateToday = utils(intl.locale).getToday();
  const posAddonsFailMessage = useAppSelector((state) => state.orgLicense.postFailErrorMessage);
  const addonsData = useAppSelector((state: any) => state.softwareBundles.addonsData?.data);
  const orgAddOnsPostFail = useAppSelector((state) => state.orgLicense.isOrgAddOnModalFail);
  const singleOrgData = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const { values, setFieldValue, handleBlur, touched, errors } = props.formik;
  const expirationDate = singleOrgData?.bundle_info.expiration_date;
  const expirationDateObject = moment.utc(expirationDate);

  const filteredAddOnsData = addonsData?.filter((addon: any) => {
    return addon.supportedTiers?.some((tier: any) => tier.tierCode === singleOrgData?.tier?.tier_code);
  });
  const handleMenuItemClick = (selectedAddon: any) => {
    setFieldValue('addOn', selectedAddon);
  };

  function getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }
  const handleFieldBlur = (e: any) => {
    props.formik.setSubmitting(false);
    handleBlur(e);
  };
  const handleDateChange = (e: any, onClose: () => void) => {
    setFieldValue('availabilityDate', e);
    handleFieldBlur('availabilityDate');
    onClose();
  };
  const handleExpirationDateChange = (e: any, onClose: () => void) => {
    setFieldValue('expirationDate', e);
    handleFieldBlur('expirationDate');
    onClose();
  };
  return (
    <Box>
      <Wrap alignItems={'center'} spacingX="30px">
        <WrapItem w="305px">
          <Box minH="70px" mt="10px">
            <InputLabel value={'name'} name="org_name" />
            <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
              {singleOrgData?.org_name}
            </Text>
          </Box>
        </WrapItem>
        <WrapItem w="305px">
          <Box minH="70px" mt="10px">
            <InputLabel value={'code'} name="org_code" />
            <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
              {singleOrgData?.org_code}
            </Text>
          </Box>
        </WrapItem>
        <Text fontSize="18px" fontWeight="700" mb="20px">
          <FormattedMessage id="information.message" />
        </Text>
      </Wrap>
      <Wrap spacing="30px" h="100px">
        <WrapItem>
          <Box>
            <InputLabel value={values.addOn.name} name="add_addons" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {values.addOn.name ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {values.addOn.name}
                      </Text>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="add_addons.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <Text fontSize="12px" color="#FB5654" mt="5px">
                    {touched.addOn && errors.addOn}
                  </Text>

                  <MenuList
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    fontSize="14px"
                    zIndex={9999}
                    h="200px"
                    overflowY={'scroll'}
                  >
                    {filteredAddOnsData &&
                      filteredAddOnsData?.map((addOnData: Addon) => (
                        <MenuItem
                          key={addOnData.id}
                          data-testid="menu-item"
                          name={'add_ons'}
                          color="rgba(51, 51, 51, 1)"
                          backgroundColor="transparent"
                          _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                          onClick={() => handleMenuItemClick(addOnData)}
                          onBlur={handleBlur('addOn')}
                        >
                          {addOnData.name}
                        </MenuItem>
                      ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box>
            <InputLabel value={values?.orderID} name={'ord_id'} />
            <Box display="flex" flexDirection="column" minH="70px">
              <CustomInputField
                defaultValue={''}
                name={'ord_id'}
                isEditable={true}
                onKeyPress={specialCharacterValidation}
                maxLength={50}
                handleOnChange={(e: any) => setFieldValue('orderID', e.target?.value)}
                handleOnBlur={handleBlur('orderID')}
              />
              <Text fontSize="12px" color="#FB5654" mt="-40px">
                {touched.orderID && errors.orderID}
              </Text>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
      <Wrap spacingX={'30px'}>
        <WrapItem>
          <Box minH="80px">
            <InputLabel value={values?.availabilityDate?.day} name="start_date" />
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                    onBlur={handleBlur('availabilityDate')}
                  >
                    {values.availabilityDate.day ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {getMonthName(values.availabilityDate.month)} {values.availabilityDate.day},{' '}
                        {values.availabilityDate.year}
                      </Text>
                    ) : (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color="#8c8c8c"
                        paddingTop="5px"
                      >
                        <FormattedMessage id="start_date.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <Text fontSize="12px" color="#FB5654" mt="5px" ml="4px">
                    {touched.availabilityDate && errors.availabilityDate}
                  </Text>
                  <MenuList
                    zIndex={9999}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    backgroundColor="transparent"
                    border="none"
                    __css={{
                      '.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover':
                        {
                          color: colors.lightModeBlue,
                          background: 'none',
                        },
                      '.Calendar__monthArrowWrapper.-right': {
                        transform: 'none',
                      },
                      '.Calendar__monthArrowWrapper.-left': {
                        transform: 'rotate(180deg)',
                      },
                      '.Calendar__monthArrow': {
                        backgroundImage: "url('/assets/images/left-chevron.svg')",
                      },
                      '.Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                      '.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                    }}
                  >
                    <Calendar
                      value={values.availabilityDate?.day ? values.availabilityDate : calendarDateToday}
                      data-testid="availabilityDate-calender"
                      onChange={(e) => handleDateChange(e, onClose)}
                      colorPrimary={colors.lightModeBlue}
                      calendarClassName="custom-calendar"
                      calendarTodayClassName="custom-today-day"
                      minimumDate={{
                        year: calendarDateToday.year,
                        month: calendarDateToday.month,
                        day: calendarDateToday.day,
                      }}
                      maximumDate={{
                        year: expirationDateObject.year(),
                        month: expirationDateObject.month() + 1,
                        day: expirationDateObject.date(),
                      }}
                    />
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box minH="80px">
            <InputLabel value={values?.expirationDate?.day} name="expiration_date" />
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    rightIcon={<Icon as={CalendarIcons} w={4} h={4} color={colors.primaryColor} />}
                    onBlur={handleBlur('expirationDate')}
                  >
                    {values.expirationDate.day ? (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={colors.subTextColor}
                      >
                        {getMonthName(values.expirationDate.month)} {values.expirationDate.day},{' '}
                        {values.expirationDate.year}
                      </Text>
                    ) : (
                      <Text
                        float="left"
                        marginLeft="-15px"
                        fontSize="14px"
                        fontWeight="400"
                        color="#8c8c8c"
                        paddingTop="5px"
                      >
                        <FormattedMessage id="expiration_date.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <Text fontSize="12px" color="#FB5654" mt="5px" ml="4px">
                    {touched.expirationDate && errors.expirationDate}
                  </Text>
                  <MenuList
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    backgroundColor="transparent"
                    border="none"
                    zIndex={9999}
                    __css={{
                      '.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover':
                        {
                          color: colors.lightModeBlue,
                          background: 'none',
                        },
                      '.Calendar__monthArrowWrapper.-right': {
                        transform: 'none',
                      },
                      '.Calendar__monthArrowWrapper.-left': {
                        transform: 'rotate(180deg)',
                      },
                      '.Calendar__monthArrow': {
                        backgroundImage: "url('/assets/images/left-chevron.svg')",
                      },
                      '.Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                      '.Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover':
                        {
                          background: 'none',
                          color: colors.lightModeBlue,
                        },
                    }}
                  >
                    <Calendar
                      value={values.expirationDate?.day ? values.expirationDate : calendarDateToday}
                      data-testid="expirationDate-calender"
                      onChange={(e) => handleExpirationDateChange(e, onClose)}
                      colorPrimary={colors.lightModeBlue}
                      calendarClassName="custom-calendar"
                      calendarTodayClassName="custom-today-day"
                      minimumDate={{
                        year: calendarDateToday.year,
                        month: calendarDateToday.month,
                        day: calendarDateToday.day,
                      }}
                      maximumDate={{
                        year: expirationDateObject.year(),
                        month: expirationDateObject.month() + 1,
                        day: expirationDateObject.date(),
                      }}
                    />
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>

      {orgAddOnsPostFail ? <BannerMessage message={posAddonsFailMessage} defaultMessage={''} /> : ''}
    </Box>
  );
};

export default OrgAddOnsInputFields;
