import React, { useState } from 'react';
import { Text, Flex, Box, PopoverBody, Popover, PopoverTrigger, Link, PopoverContent } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import { SingleOrganizationTierValue } from 'utils/constants/Constants';

export default function OrgUserTitle() {
  const [isPopOverOpen, setIsPopOverOpen] = useState<boolean>(false);

  const orgTierValue = useAppSelector((state) => state.organization.SingleOrganizationsGetData?.tier?.tier_code);
  const isConnectTierValue = orgTierValue == SingleOrganizationTierValue;
  const analyticsData = useAppSelector((state: RootState) => state.orgUser.userAnalyticsData);

  const handleTogglePopover = (isOpen: boolean) => {
    setIsPopOverOpen(isOpen);
  };
  return (
    <Box alignItems="center" pl="30px" h="40px" mt="-5px">
      <Flex alignItems="center">
        <Text mr={{ xs: '3' }} fontWeight="700" fontSize="24px" data-testid="users">
          <FormattedMessage id="users.message" />
        </Text>
      </Flex>
      {!isConnectTierValue && (
        <Flex minW="180px">
          {' '}
          <Text color="light.1400">
            <FormattedMessage
              id="total_licenses_count.message"
              values={{ total_count: analyticsData?.total_licenses || 0 }}
            />
          </Text>
          <span>&nbsp;</span>
          <Flex as="span" zIndex={99999} pt={0}>
            <Popover placement="bottom-start" isOpen={isPopOverOpen}>
              <PopoverTrigger>
                <Box
                  as="span"
                  onMouseOverCapture={() => handleTogglePopover(true)}
                  onMouseLeave={() => handleTogglePopover(false)}
                  cursor="pointer"
                >
                  <Link color="#24B0FF" height="21px" _hover={{ textDecoration: 'none' }}>
                    <FormattedMessage
                      id="used_licenses_count.message"
                      values={{ used_count: analyticsData?.licenses_used || 0 }}
                    />
                  </Link>
                </Box>
              </PopoverTrigger>
              <PopoverContent
                className="popover-content"
                height="56px"
                width="283px"
                css={{
                  '&:focus': {
                    boxShadow: 'none',
                  },
                }}
                onMouseLeave={() => handleTogglePopover(false)}
                onBlur={() => handleTogglePopover(false)}
              >
                <PopoverBody
                  fontFamily="Helvetica"
                  fontSize="12px"
                  padding="10px 20px"
                  borderRadius="10px"
                  fontWeight="500"
                  boxShadow="0px 4px 17px rgba(0, 0, 0, 0.08)"
                  bg="white"
                  color="#33333399"
                  data-testid="tooltip-Accountinfo"
                >
                  <FormattedMessage id="add_user_disclaimer_message.message" />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
