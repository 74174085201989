/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit';
import { DeviceTypePostData } from 'types/device-types';

type InitialState = {
  handleCreateDeviceTypeModal: boolean;
  getModelIdentifierInputValue: string;
  getCatalogueInputValue: string;
  getDeviceTypeNameInputValue:string;
  getDeviveTypeInternalName:string;
  getDeviceTypeHarwareInputValue:string;
  deviceTypeStatusDataLoading:boolean;
  deviceTypeStatusDataSuccess:any[];
  deviceTypeStatusDataFail:boolean;
  getDeviceTypeStatusValue:string;
  getDeviceTypeManufacturedDay:string;
  getDeviceTypeManufacturedMonth:string;
  getDeviceTypeManufacturedYear:string;
  getDeviceTypeManufacturedValidation:boolean
  getDeviceTypeCreateButtonEnable:boolean
  postDeviceTypeLoading:boolean
  postDeviceTypeFormDataresult:string[]
  postDeviceTypeFail:boolean
  postDeviceTypeApiFail:string
  deviceTypeSuccessCreation:boolean
  deviceTypeCreateButtonEnable:boolean
  postDeviceTypeData:boolean
  postDeviceTypeApiFailToggle:boolean
  deviceTypesDataLoading:boolean
  deviceTypesData:[]
  deviceTypesDataFail:boolean
  singleDeviceTypeData:DeviceTypePostData | null
  singleDeviceTypeDataFail:boolean
  handleDeviceTypeEditModal:boolean
  editDeviceTypeApiLoading:boolean
  editDeviceTypeApiSuccess:string[]
  editDeviceTypeApiFail:string
  deviceTypeEditID:string | number
  editDeviceTypeEditButtonButtonEnable:boolean
  // device type modal unique value validation
  deviceTypeModelIdentifierUniqueValue:string
  deviceTypeModelIdentifierUniqueValueFail:boolean
  deviceTypeCatalogueUniqueValue:string
  deviceTypeCatalogueUniqueValueFail:boolean
  deviceTypeInternalNameUniqueValue:string
  deviceTypeInternalNameUniqueValueFail:boolean
  deviceTypeNameUniqueValue:string
  deviceTypeNameUniqueValueFail:boolean
  deviceTypeCatalogueValidation:boolean
  deviceTypeModelIdentifierValidation:boolean
  deviceTypeNameValidation:boolean
  deviceTypeInternalNameValidation:boolean
  deviceTypeHardwareLengthValidation:boolean
  modelSpecialCharacterValidation:boolean
  catalogueSpecialCharacterValidation:boolean
  nameSpecialCharacterValidation:boolean
  internalNameSpecialCharacterValidation:boolean
  hardwareVersionSpecialCharacterValidation:boolean

};

export const initialState: InitialState = {
  handleCreateDeviceTypeModal: false,
  getModelIdentifierInputValue: '',
  getCatalogueInputValue: '',
  getDeviceTypeNameInputValue:'',
  getDeviveTypeInternalName:'',
  getDeviceTypeHarwareInputValue:'',
  deviceTypeStatusDataLoading:false,
  deviceTypeStatusDataSuccess:[],
  deviceTypeStatusDataFail:false,
  getDeviceTypeStatusValue:'',
  getDeviceTypeManufacturedDay:'',
  getDeviceTypeManufacturedMonth:'',
  getDeviceTypeManufacturedYear:'',
  getDeviceTypeManufacturedValidation:false,
  getDeviceTypeCreateButtonEnable:false,
  postDeviceTypeLoading:false,
  postDeviceTypeFormDataresult:[],
  postDeviceTypeFail:false,
  postDeviceTypeApiFail:'',
  deviceTypeSuccessCreation:false,
  deviceTypeCreateButtonEnable:false,
  postDeviceTypeData:false,
  postDeviceTypeApiFailToggle:false,
  deviceTypesDataLoading:false,
  deviceTypesData:[],
  deviceTypesDataFail:false,
  singleDeviceTypeData:null,
  singleDeviceTypeDataFail:false,
  handleDeviceTypeEditModal:false,
  editDeviceTypeApiLoading:false,
  editDeviceTypeApiSuccess:[],
  editDeviceTypeApiFail:'',
  deviceTypeEditID: '',
  editDeviceTypeEditButtonButtonEnable:false,
  deviceTypeModelIdentifierUniqueValue:'',
  deviceTypeModelIdentifierUniqueValueFail:false,
  deviceTypeCatalogueUniqueValue:'',
  deviceTypeCatalogueUniqueValueFail:false,
  deviceTypeInternalNameUniqueValue:'',
  deviceTypeInternalNameUniqueValueFail:false,
  deviceTypeNameUniqueValue:'',
  deviceTypeNameUniqueValueFail:false,
  deviceTypeCatalogueValidation:false,
  deviceTypeModelIdentifierValidation:false,
  deviceTypeNameValidation:false,
  deviceTypeInternalNameValidation:false,
  deviceTypeHardwareLengthValidation: false,
  modelSpecialCharacterValidation:false,
  catalogueSpecialCharacterValidation:false,
  nameSpecialCharacterValidation:false,
  internalNameSpecialCharacterValidation:false,
  hardwareVersionSpecialCharacterValidation:false,
};
const deviceTypeSlice = createSlice({
  name: 'deviceTypes',
  initialState,
  reducers: {
    handleCreateDeviceTypeModal: (state, { payload }) => {
      state.handleCreateDeviceTypeModal = payload;
    },
    getModelIdentifierInputData: (state, { payload }) => {
        state.getModelIdentifierInputValue = payload
    },
    getCatalogueInputData: (state, { payload }) => {
        state.getCatalogueInputValue = payload
    },
    getDeviceTypeNameInputValue:(state,{payload}) => {
        state.getDeviceTypeNameInputValue = payload
    },
    getDeviveTypeInternalName:(state,{payload}) => {
        state.getDeviveTypeInternalName = payload
    },
    getDeviceTypeHarwareInputValue:(state,{payload}) => {
        state.getDeviceTypeHarwareInputValue = payload
    },
    getDeviceTypeStatusDataLoading:(state) => {
        state.deviceTypeStatusDataLoading = true;
        state.deviceTypeStatusDataFail = false
    },
    getDeviceTypeStatusDataSuccess:(state,{payload}) => {
        state.deviceTypeStatusDataSuccess = payload
    },
    getDeviceTypeStatusDataFail:(state) => {
        state.deviceTypeStatusDataFail = true
        state.deviceTypeStatusDataLoading = false;
    },

    getDeviceTYpeStatusInputValue:(state,{payload} ) => {
        state.getDeviceTypeStatusValue = payload;
    },
    fetchDeviceTypeManufacturedDay:(state,{payload}) => {
        state.getDeviceTypeManufacturedDay = payload;
    },
    fetchDeviceTypeManufacturedMonth:(state,{payload}) => {
        state.getDeviceTypeManufacturedMonth = payload;
    },
    fetchDeviceTypeManufacturedYear:(state,{payload}) => {
        state.getDeviceTypeManufacturedYear = payload;
    },
    fetchDeviceTypeManufacturedDateValidation:(state,{payload}) => {
        state.getDeviceTypeManufacturedValidation = payload;
    },

    fetchDeviceTypeCreateButtonEnable:(state,{payload}) => {
        state.getDeviceTypeCreateButtonEnable = payload;
    },

    postDeviceTypeFormDataLoading:(state) => {
        state.postDeviceTypeLoading = true
    },
    postDeviceTypeFormDataSuccess:(state,{payload}) => {
        state.postDeviceTypeFormDataresult = payload
    },
    postDeviceTypeFormDataFail:(state,{payload}) => {
        state.postDeviceTypeFail = payload
    },
    postDeviceTypeApiFail:(state,{payload}) => {
        state.postDeviceTypeApiFail = payload
    },


    fetchDeviceTypeSuccessCreation:(state,{payload}) => {
        state.deviceTypeSuccessCreation = payload;
    },
    deviceTypePostCreateButton:(state,{payload}) => {
        state.deviceTypeCreateButtonEnable = payload;
    },

    postDeviceTypeData:(state,{payload}) => {
        state.postDeviceTypeData = payload;
    },

    fetchDevicePostDataFailToggle:(state,{payload}) => {
        state.postDeviceTypeApiFailToggle = payload;
    },

    getDeviceTypesDataLoading:(state) => {
        state.deviceTypesDataLoading = true;
        state.deviceTypesDataFail = false;
    },
    getDeviceTypesData:(state,{payload}) => {
        state.deviceTypesData = payload;
        state.deviceTypesDataLoading = false;
        state.deviceTypesDataFail = false;
    },
    getDeviceTypesDataFail:(state) => {
        state.deviceTypesDataLoading = false;
        state.deviceTypesDataFail = true;
    },
    getSingleDeviceTypeData:(state,{payload}) => {
        state.singleDeviceTypeData = payload;
    },
    getSingleDeviceTypeDataFail:(state,{payload}) => {
        state.singleDeviceTypeDataFail = payload;
    },
    handleEditDeviceTypeModal:(state,{payload}) => {
        state.handleDeviceTypeEditModal= payload
    },

editDeviceTypeLoading:(state) => {
    state.editDeviceTypeApiLoading = false
},
editDeviceTypeApiSuccess:(state,{payload}) => {
    state.editDeviceTypeApiSuccess = payload
},
editDeviceTypeFail:(state,{payload}) => {
    state.editDeviceTypeApiFail = payload;
},
getDeviceTypeEditID:(state,{payload}) => {
    state.deviceTypeEditID = payload
},
editDeviceTypesSaveButtonEnable:(state,{payload}) => {
    state.editDeviceTypeEditButtonButtonEnable= payload
},
// device type modal field validations for unique values
  fetchDeviceTypeModelIdentifierUniqueValidation:(state,{payload}) =>{
    state.deviceTypeModelIdentifierUniqueValue = payload
  },
  fetchDeviceTypeModelIdentifierUniqueValidationFail:(state,{payload}) =>{
    state.deviceTypeModelIdentifierUniqueValueFail = payload
  },
  fetchDeviceTypeCatalogueUniqueValidation:(state,{payload}) =>{
    state.deviceTypeCatalogueUniqueValue = payload
  },
  fetchDeviceTypeCatalogueUniqueValidationFail:(state,{payload}) =>{
    state.deviceTypeCatalogueUniqueValueFail = payload
  },
  fetchDeviceTypeInternalNameUniqueValidation:(state,{payload}) =>{
    state.deviceTypeInternalNameUniqueValue = payload
  },
  fetchDeviceTypeInternalNameUniqueValidationFail:(state,{payload}) =>{
    state.deviceTypeInternalNameUniqueValueFail = payload
  },
  fetchDeviceTypeNameUniqueValidation:(state,{payload}) =>{
    state.deviceTypeNameUniqueValue = payload
  },
  fetchDeviceTypeNameUniqueValidationFail:(state,{payload}) =>{
    state.deviceTypeNameUniqueValueFail = payload
  },
  fetchDeviceTypeCatalogueValidation:(state,{payload}) =>{
  state.deviceTypeCatalogueValidation = payload
},
fetchDeviceTypeModelIdentifierValidation:(state,{payload}) =>{
    state.deviceTypeModelIdentifierValidation = payload
  },
  fetchDeviceTypeNameValidation:(state,{payload}) =>{
    state.deviceTypeNameValidation = payload
  },
  fetchDeviceTypeInternalNameValidation:(state,{payload}) =>{
    state.deviceTypeInternalNameValidation = payload
  },
  fetchDeviceTypeHardwareVersionValidation:(state,{payload}) =>{
    state.deviceTypeHardwareLengthValidation = payload
  },
// SPECIAL CHARACTER VALIDATION
fetchModelSpecialCharacterValidation :(state,{payload}) => {
state.modelSpecialCharacterValidation = payload;
},
fetchCatalogueSpecialCharacterValidation :(state,{payload}) => {
    state.catalogueSpecialCharacterValidation = payload;
    },
    fetchNameSpecialCharacterValidation :(state,{payload}) => {
        state.nameSpecialCharacterValidation = payload;
        },
        fetchInternalNameSpecialCharacterValidation :(state,{payload}) => {
            state.internalNameSpecialCharacterValidation = payload;
            },
            fetchHardwareVersionSpecialCharacterValidation :(state,{payload}) => {
                state.hardwareVersionSpecialCharacterValidation = payload;
                },

    resetDeviceTypeInputValues:(state) => {
        state.deviceTypeCatalogueValidation = false;
        state.deviceTypeModelIdentifierValidation = false
        state.getDeviceTypeStatusValue = '';
        state.deviceTypeNameValidation = false
        state.deviceTypeInternalNameValidation = false
        state.getDeviceTypeHarwareInputValue = '';
        state.getDeviveTypeInternalName = '';
        state.handleCreateDeviceTypeModal = false;
        state.getModelIdentifierInputValue = '';
        state.getCatalogueInputValue = '';
        state.getDeviceTypeNameInputValue = '';
        state.getDeviceTypeManufacturedDay ='',
        state.getDeviceTypeManufacturedMonth ='',
        state.getDeviceTypeManufacturedYear ='',
        state.getDeviceTypeManufacturedValidation =false,
        state.postDeviceTypeFail = false,
        state.postDeviceTypeFormDataresult = [],
        state.postDeviceTypeApiFail = '',
        state.deviceTypeSuccessCreation = false;
        state.deviceTypeCreateButtonEnable = false;
        state.postDeviceTypeData = false;
        state.postDeviceTypeApiFailToggle = false
        state.handleDeviceTypeEditModal = false
        state.editDeviceTypeApiLoading = false
        state.editDeviceTypeApiFail = ''
        state.editDeviceTypeApiSuccess = []
        state.getDeviceTypeCreateButtonEnable = false;
        state.singleDeviceTypeData =  null
        state.editDeviceTypeEditButtonButtonEnable= false
        state.editDeviceTypeEditButtonButtonEnable=false,
        state.deviceTypeModelIdentifierUniqueValue='',
         state.deviceTypeModelIdentifierUniqueValueFail=false,
        state.deviceTypeCatalogueUniqueValue='',
        state.deviceTypeCatalogueUniqueValueFail=false,
        state.deviceTypeInternalNameUniqueValue='',
        state.deviceTypeInternalNameUniqueValueFail=false,
        state.deviceTypeNameUniqueValue='',
        state.deviceTypeNameUniqueValueFail=false
        state.deviceTypeHardwareLengthValidation = false
        state.modelSpecialCharacterValidation = false;
        state.catalogueSpecialCharacterValidation = false;
        state.nameSpecialCharacterValidation = false;
        state.internalNameSpecialCharacterValidation = false;
        state.hardwareVersionSpecialCharacterValidation = false;
    }

  },
});
export const { handleCreateDeviceTypeModal,
    getSingleDeviceTypeData,
    fetchNameSpecialCharacterValidation,
    fetchModelSpecialCharacterValidation,
    fetchCatalogueSpecialCharacterValidation,
    fetchDeviceTypeCatalogueValidation,
    fetchInternalNameSpecialCharacterValidation,
    fetchDeviceTypeModelIdentifierValidation,
    fetchDeviceTypeModelIdentifierUniqueValidation,
    fetchDeviceTypeModelIdentifierUniqueValidationFail,
    fetchDeviceTypeCatalogueUniqueValidation,
    fetchHardwareVersionSpecialCharacterValidation,
    fetchDeviceTypeCatalogueUniqueValidationFail,
    fetchDeviceTypeInternalNameUniqueValidation,
    fetchDeviceTypeInternalNameUniqueValidationFail,
    fetchDeviceTypeInternalNameValidation,
    fetchDeviceTypeNameUniqueValidationFail,
    fetchDeviceTypeNameUniqueValidation,
    getSingleDeviceTypeDataFail,
    getModelIdentifierInputData,
    getCatalogueInputData,
    getDeviceTypeNameInputValue,
    getDeviveTypeInternalName ,
    getDeviceTypeStatusDataLoading,
    getDeviceTypeStatusDataSuccess,
    getDeviceTypeStatusDataFail,
    getDeviceTYpeStatusInputValue,
    resetDeviceTypeInputValues,
    fetchDeviceTypeManufacturedDay,
    fetchDeviceTypeManufacturedMonth,
    fetchDeviceTypeNameValidation,
    fetchDeviceTypeManufacturedYear,
    fetchDeviceTypeManufacturedDateValidation,
    fetchDeviceTypeCreateButtonEnable,
    postDeviceTypeFormDataLoading,
    postDeviceTypeFormDataSuccess,
    postDeviceTypeFormDataFail,
    postDeviceTypeApiFail,
    deviceTypePostCreateButton,
    fetchDeviceTypeSuccessCreation,
    postDeviceTypeData,
    getDeviceTypesDataLoading,
    getDeviceTypesData,
    editDeviceTypeApiSuccess,
    getDeviceTypeEditID,
    getDeviceTypesDataFail,
    editDeviceTypeLoading,
    editDeviceTypeFail,
    fetchDevicePostDataFailToggle,
    editDeviceTypesSaveButtonEnable,
    handleEditDeviceTypeModal,
    fetchDeviceTypeHardwareVersionValidation,
    getDeviceTypeHarwareInputValue} = deviceTypeSlice.actions;
export default deviceTypeSlice.reducer;
