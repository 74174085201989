import {
  Box,
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';

import {
  fetchDeviceCreateButtonEnable,
  fetchDeviceSuccessCreation,
  fetchPostDeviceData,
  getDevicePostCreateButton,
  getScannerValue,
  resetDevicePopUpErrors,
  toggleCreateDevicePopUp,
} from 'redux/devices/Reducers/deviceSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import DeviceInputFields from './createDeviceInputFields/DeviceInputFields';
import { customColor } from '../../../../../../../libs/shared-ui/src/lib/assets/styles/colors';
import { getDevices, getDeviceTypes } from 'redux/devices/Actions/deviceActions';
import {
  CircleCrossIcon,
  CircleCheckIcon,
  CircleRightEnabledIcon,
  CircleRightDisabledIcon,
} from '../../../../../../../libs/shared-ui/src/lib/assets';
import { Error_Type_Enum } from '../../../../utils/enum';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import { ModalTimeOut } from 'utils/constants/Constants';

const DeviceCreateModal = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const { onClose } = useDisclosure();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const openDeviceModal = useAppSelector((state) => state.device.handleCreateDevicePopUp);
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');
  const postData = useAppSelector((state) => state.device.postDeviceFormDataFail);
  const handleButton = useAppSelector((state) => state.device.deviceCreateButton);
  const successCreation = useAppSelector((state) => state.device.deviceSuccesCreation);
  const toggleSubmitButton = useAppSelector((state) => state.device.deviceCreateButtonEnable);
  const createDeviceApiFail = useAppSelector((state) => state.device.postDeviceApiFail);
  const selectedDevice = useAppSelector((state) => state.device.getDeviceTypeValue);
  const existedDevices = useAppSelector((state) => state.device.deviceTypesFilterData);
  const handleCreateDevicePopUp = useAppSelector((state) => state.device.handleCreateDevicePopUp);
  const data = existedDevices.map((item) => item.device_name);

  useEffect(() => {
    if (createDeviceApiFail.length > 0) {
      dispatch(fetchDeviceSuccessCreation(true));
    }
    if (createDeviceApiFail === Error_Type_Enum.Success) {
      setTimeout(() => {
        dispatch(resetDevicePopUpErrors());
        dispatch(toggleCreateDevicePopUp(false));
        dispatch(resetDevicePopUpErrors());
        dispatch(handlePostApiFailureMessage(''));
        onClose();
      }, ModalTimeOut);
    }
  }, [createDeviceApiFail]);
  const handleDeviceModalClose = () => {
    dispatch(toggleCreateDevicePopUp(false));
    dispatch(resetDevicePopUpErrors());
    dispatch(handlePostApiFailureMessage(''));
  };

  const handleClick = () => {
    dispatch(fetchDeviceCreateButtonEnable(true));
    dispatch(fetchPostDeviceData(true));
    dispatch(getDevicePostCreateButton(true));
    setTimeout(() => {
      // need to refactor
      if (!data.includes(selectedDevice)) {
        dispatch(getDeviceTypes());
      }
    }, 3000);

    if (handleCreateDevicePopUp == false) {
      dispatch(fetchDeviceSuccessCreation(false));
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openDeviceModal}
      onClose={handleDeviceModalClose}
      border-radius="0.1px"
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '368px', smd: '700px', md: '700px', lg: '700px', xl: '700px', dt: '700px' }} mt="80px">
        <ModalHeader fontSize="24px" ml="8px">
          <FormattedMessage id="add_device.message" />
        </ModalHeader>
        <ModalCloseButton
          disabled={!postData && toggleSubmitButton}
          color={closeIconColor}
          data-testid="close-ic"
          _hover={{ backgroundColor: 'white' }}
          backgroundColor="transparent"
        />
        <ModalBody ml="7px" backgroundColor="transparent">
          <DeviceInputFields />
        </ModalBody>
        <ModalFooter backgroundColor="transparent" zIndex={-9999}>
          {postData ? (
            <Button
              variant="outline"
              type="button"
              width="195px"
              height="42px"
              borderRadius="20px"
              borderColor="#BAC5CD"
              color="#BAC5CD"
              disabled
              fontSize="17px"
              float="right"
              data-testid="org-button-disabled"
              _hover={{ cursor: 'not-allowed' }}
            >
              <Flex>
                <Text
                  fontSize="12px"
                  ml="50px"
                  display="flex"
                  alignItems="center"
                  fontWeight="extrabold"
                  data-testid="org-button-text"
                >
                  <FormattedMessage id="failure.message" />
                </Text>
                <Icon w="30px" h="30px" ml="32px" float="right" data-testid="org-button-icon">
                  <CircleCrossIcon />
                </Icon>
              </Flex>
            </Button>
          ) : (
            <Box>
              {handleButton ? (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  position="relative"
                  color={blueColorMode}
                  cursor={successCreation ? 'not-allowed' : ''}
                  fill={blueColorMode}
                  borderColor={blueColorMode}
                  fontSize="17px"
                  float="right"
                  data-testid="create-button-active"
                  _hover={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  onClick={handleClick}
                  _disabled={{
                    bg: blueColorMode,
                    color: blackAndWhiteColorMode,
                    '&>div>div>svg>svg>circle': {
                      fill: blueColorMode,
                      stroke: blackAndWhiteColorMode,
                    },
                    '&>div>div>svg>svg>path': {
                      fill: blackAndWhiteColorMode,
                      stroke: 'none',
                    },
                  }}
                  isDisabled={successCreation}
                >
                  <Flex>
                    <Box fontSize="10px" display="flex" alignItems="center" data-testid="org-button-text-diabled">
                      {successCreation ? (
                        <FormattedMessage id="done.message">
                          {(txt) => (
                            <Text fontSize="12px" fontWeight="extrabold" ml="17px">
                              {txt}
                            </Text>
                          )}
                        </FormattedMessage>
                      ) : (
                        <FormattedMessage id="add_modal.message">
                          {(txt) => (
                            <Text fontWeight="extrabold" fontSize="12px">
                              {txt}
                            </Text>
                          )}
                        </FormattedMessage>
                      )}
                    </Box>
                    {toggleSubmitButton ? (
                      <Box>
                        {successCreation ? (
                          <Icon
                            w="30px"
                            h="30px"
                            mt="-7.5px"
                            float="right"
                            data-testid="org-button-checkIcon"
                            position="absolute"
                            right="7px"
                          >
                            <CircleCheckIcon />
                          </Icon>
                        ) : (
                          <Box
                            pl="10px"
                            h="40px"
                            mt="-7.5px"
                            w="40px"
                            data-testid="org-button-spinner"
                            position="absolute"
                            right="7px"
                          >
                            <Spinner />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Icon w="30px" h="30px" mt="-7.5px" float="right" position="absolute" right="5px">
                        <CircleRightEnabledIcon />
                      </Icon>
                    )}
                  </Flex>
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="button"
                  width="195px"
                  height="42px"
                  borderRadius="20px"
                  borderColor="#BAC5CD"
                  color="#BAC5CD"
                  disabled
                  fontSize="17px"
                  float="right"
                  position="relative"
                  data-testid="org-button-disabled"
                  _hover={{ cursor: 'not-allowed' }}
                >
                  <Flex>
                    <Text
                      fontSize="12px"
                      mr="10px"
                      display="flex"
                      alignItems="center"
                      fontWeight="extrabold"
                      data-testid="org-button-text"
                    >
                      <FormattedMessage id="add_modal.message" />
                    </Text>
                    <Icon
                      w="30px"
                      h="30px"
                      mt="-7.5px"
                      float="right"
                      data-testid="org-button-icon"
                      position="absolute"
                      right="7px"
                    >
                      <CircleRightDisabledIcon />
                    </Icon>
                  </Flex>
                </Button>
              )}
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeviceCreateModal;
