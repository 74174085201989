import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const onlyTick = definePartsStyle({
  label: {
    color: '#33333380',
    fontSize: '14px',
    fontWeight: '300',
    _dark: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  control: {
    borderRadius: '50%', // change the border radius of the control
    border: 0,
    svg: {
      width: '17px',
    },
    _focusVisible: {
      boxShadow: 'none',
    },
    _checked: {
      background: 'transparent',
      // border: 0,
      color: '#24B0FF',
      _dark: {
        color: '#10F8E4',
      },
      _hover: {
        background: 'transparent',
      },
    },
  },
});

const themeBlueBg = definePartsStyle({
  control: {
    borderRadius: '5px',
    borderColor: '#D9D9D9',
    width: '24px',
    height: '24px',
    _focusVisible: { boxShadow: 'none' },
    svg: {
      width: '12px',
      height: '7px',
    },
    _checked: {
      border: 0,
      fontSize: '20px',
      _dark: {
        background: '#10F8E4',
      },
      background: '#24B0FF',
      _hover: {
        background: '#24B0FF',
      },
      svg: {
        height: '20px',
      },
    },
  },
});

const themeVioletBg = definePartsStyle({
  control: {
    borderWidth: '1px',
    borderRadius: '5px',
    borderColor: '#384049B2',
    width: '18px',
    height: '18px',
    _focusVisible: { boxShadow: 'none' },
    svg: {
      width: '12px',
      height: '7px',
    },
    _hover: {
      cursor: 'pointer',
    },
    _checked: {
      border: 0,
      fontSize: '20px',
      _dark: {
        background: '#6864DD',
      },
      background: '#6864DD',
      _hover: {
        background: '#6864DD',
      },
      svg: {
        height: '20px',
      },
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({ variants: { onlyTick, themeBlueBg, themeVioletBg } });
