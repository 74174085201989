import {
  Box,
  Divider,
  Text,
  Icon,
  Flex,
  Switch,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  WrapItem,
  Wrap,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { customColor } from 'assets/styles/colors';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from 'redux/hooks';
import SubmitButton from '../add-ons/AddAddonsModal/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { Feature, FeatureGroup } from 'types/feature';
import { InputLabel } from 'components/InputField';
import { resetFeatureUpdateValues } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { ModalTimeOut } from 'utils/constants/Constants';
import { resetOrgFeatureUpdateValues } from 'redux/orgFeatures/Reducers/OrgFeaturesSlice';

const FeatureGroupsAccordion = ({
  dispatchCallback,
  orgId,
  featureGroupsData,
  hasEditAccess,
  isFeatureAvailableSuccess,
  isFeatureAvailableLoading,
  isFeatureAvailableFail,
}: any) => {
  const colors = customColor();
  const intl = useIntl();
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<Feature>();
  const [isModalButtonEnabled, setIsModalButtonEnabled] = useState(false);
  const [availableInputLabel, setAvailableInputLable] = useState('');

  const toggleGroup = (groupIndex: any) => {
    setExpandedGroup(expandedGroup === groupIndex ? null : groupIndex);
  };

  const numGroupsPerColumn = Math.ceil(
    featureGroupsData && featureGroupsData?.length ? featureGroupsData.length / 2 : 0,
  );
  const navigateFeatureInfo = (featureId: any) => {
    if (!orgId) {
      navigate(`features/${featureId}/info`);
    }
  };
  const handleToggle = (feature: Feature) => {
    setSelectedFeature(feature);
    setIsModalOpen(true);
  };
  const handleCloseButton = () => {
    setIsModalButtonEnabled(false);
    dispatch(resetFeatureUpdateValues());
    setAvailableInputLable('');
  };
  const handleUpdateFeature = () => {
    if (orgId) {
      dispatch(dispatchCallback(orgId, selectedFeature?.id, !selectedFeature?.enabled));
    } else {
      dispatch(dispatchCallback(selectedFeature?.id, !selectedFeature?.enabled));
    }
  };
  useEffect(() => {
    if (isFeatureAvailableSuccess) {
      setTimeout(() => {
        dispatch(resetFeatureUpdateValues());
        setIsModalOpen(false);
        setIsModalButtonEnabled(false);
        setAvailableInputLable('');
        onClose();
      }, ModalTimeOut);
    }
    if (orgId) {
      setTimeout(() => {
        dispatch(resetOrgFeatureUpdateValues());
        setIsModalOpen(false);
        setIsModalButtonEnabled(false);
        setAvailableInputLable('');
        onClose();
      }, ModalTimeOut);
    }
  }, [isFeatureAvailableSuccess]);
  const GroupAccordion = ({
    group,
    index,
    expandedGroup,
    toggleGroup,
  }: {
    group: FeatureGroup;
    index: number;
    expandedGroup: any;
    toggleGroup: any;
  }) => (
    <Box border="0.5px solid rgba(186, 197, 205, 1)" borderRadius={'10px'} borderColor="#BAC5CD">
      <Box onClick={() => toggleGroup(index)} style={{ cursor: 'pointer' }}>
        <Flex
          flex="1"
          textAlign="left"
          fontSize={'16px'}
          fontWeight="400"
          padding="10px"
          justifyContent="space-between"
          alignItems="center"
        >
          {group.groupName}
          <Icon w="25px" h="20px">
            {expandedGroup === index ? <ChevronUpIcon color="common.100" /> : <ChevronDownIcon color="common.100" />}
          </Icon>
        </Flex>
      </Box>
      {expandedGroup === index && (
        <>
          <Divider />
          <Box pb={3} pt={3} maxHeight="200px" overflowY={'auto'}>
            <Box>
              {group.features.map((feature: Feature, featureIndex: any) => (
                <Flex key={featureIndex} alignItems="center" fontSize="13px" fontWeight={400} py="4" px="4">
                  <Text
                    flex="1"
                    color={orgId ? 'colors.grey' : 'common.100'}
                    cursor={orgId ? '' : 'pointer'}
                    onClick={() => feature && feature.id && navigateFeatureInfo(feature?.id)}
                  >
                    {feature.name}
                  </Text>
                  <Box pr="15px">
                    {hasEditAccess ? (
                      <Flex alignItems="center" gap="15px" pl="60px">
                        <Flex minWidth="100px" pl="20px" alignItems="center">
                          <Text fontSize="13px" fontWeight={400} pr="10px">
                            <FormattedMessage id="available.message" />
                          </Text>
                          <Switch size="sm" isChecked={feature.enabled} onChange={() => handleToggle(feature)} />
                        </Flex>
                      </Flex>
                    ) : feature.enabled ? (
                      'Yes'
                    ) : (
                      'No'
                    )}
                  </Box>
                </Flex>
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
  return (
    <Box maxHeight="calc(100vh - 250px)" overflowY="auto">
      <Flex>
        <Box>
          {featureGroupsData?.slice(0, numGroupsPerColumn).map((group: any, index: number) => (
            <Box key={index} marginBottom="20px" pb="20px" w="430px" mr="20px">
              <GroupAccordion group={group} index={index} expandedGroup={expandedGroup} toggleGroup={toggleGroup} />
            </Box>
          ))}
        </Box>

        <Box flex="1">
          {featureGroupsData?.slice(numGroupsPerColumn).map((group: any, index: number) => (
            <Box key={index} marginBottom="20px" pb="20px" w="430px">
              <GroupAccordion
                group={group}
                index={index + numGroupsPerColumn}
                expandedGroup={expandedGroup}
                toggleGroup={toggleGroup}
              />
            </Box>
          ))}
        </Box>
      </Flex>
      <Box>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeOnOverlayClick={false} isCentered>
          <ModalOverlay />
          <ModalContent
            maxW={{ xs: '368px', smd: '720px', md: '720px', lg: '720px', xl: '720px', dt: '720px' }}
            mt="80px"
          >
            <ModalHeader>
              <Text fontSize="24px" fontWeight={700}>
                <FormattedMessage id="feature_toggle_confirmation_heading.message" />
              </Text>
            </ModalHeader>
            <ModalCloseButton mt="10px" _hover={{ backgroundColor: 'none' }} onClick={handleCloseButton} />
            <ModalBody>
              <Box minH="90px">
                <Text fontSize="20px" fontWeight={400} color={colors.paginationDisplay}>
                  <FormattedMessage
                    id={
                      !selectedFeature?.enabled
                        ? orgId
                          ? 'org_feature_enable_confirmation_text.message'
                          : 'feature_enable_confirmation_text.message'
                        : 'feature_disable_confirmation_text.message'
                    }
                    values={{
                      b: () => <span style={{ fontWeight: '700' }}>{selectedFeature?.name}</span>,
                    }}
                  />
                </Text>
                <Box pt="15px">
                  <InputLabel value={availableInputLabel} name={'feature_name'} />
                  <WrapItem>
                    <Input
                      placeholder={intl.formatMessage({ id: 'feature_name.message' })}
                      variant="flushed"
                      data-testid="feature_value"
                      fontSize="13px"
                      w="240px"
                      pr="20px"
                      borderColor={colors.textColor}
                      type="text"
                      paddingTop="10px"
                      onChange={(e) => {
                        const userInput = e.target.value;
                        setAvailableInputLable(userInput);
                        const isMatching = selectedFeature?.name.toLowerCase() === userInput.toLowerCase().trim();
                        setIsModalButtonEnabled(isMatching);
                      }}
                      fontWeight="400"
                      _focus={{ color: colors.textColor, opacity: 1 }}
                      color={colors.textColor}
                      focusBorderColor={colors.primaryColor}
                      borderBottomColor={colors.searchColor}
                      borderWidth="0 0 1px 0"
                      boxShadow="0px 1px 0px -1px common.100 !important"
                    />
                  </WrapItem>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <SubmitButton
                label={<FormattedMessage id="save_modal.message" />}
                isFail={isFeatureAvailableFail}
                isSucces={isFeatureAvailableSuccess}
                isDisbaled={!isModalButtonEnabled}
                handleSubmit={handleUpdateFeature}
                isLoading={isFeatureAvailableLoading}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default FeatureGroupsAccordion;
