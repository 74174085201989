/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Text,
  Tooltip,
  FormLabel,
  Flex,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import { FormattedMessage } from 'react-intl';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getOrganizationDevices, getSingleDevicesData } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import {
  getOrganisationInfoUsersData,
  getOrgUsersStatus,
  getSingleUserData,
} from 'redux/orgUser/Actions/orguserActions';
import { RootState } from 'redux/Store/store';
import { customColor } from '../../../../libs/shared-ui/src/lib/assets/styles/colors';
import { getSingleOrganizationsData, getStatus } from '../redux/organizations/Actions/organizationActions';
import { getOrgDeviceRoutes, getOrgRoutes, getOrgUserRoutes } from 'routes/orgInternalRoutes';
import { getOrganizationLicenses } from 'redux/organiztionLicenes/Actions/orgLicenseActions';
import StatusBadge from 'components/statusBadge/StatusBadge';

const SubLayout = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const params = useParams();
  const navigate = useNavigate();
  const isOrgUserInfoView = params.userId;
  const showLicenseInfo = !isOrgUserInfoView;
  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const organizationInfo = useAppSelector((state: RootState) => state.organization.SingleOrganizationsGetData);
  const singleUser = useAppSelector((state: RootState) => state.orgUser.SingleUsersGetData);
  const singleDeviceData = useAppSelector((state: RootState) => state.organizationdevice.SingleDeviceGetData);
  const orgUserStatus = useAppSelector((state) => state.orgUser.SingleUsersGetData)?.status?.status_name;

  let routes: any;

  if (params.userId) {
    routes = getOrgUserRoutes(userPermissions.organizations);
  } else if (params.deviceId) {
    routes = getOrgDeviceRoutes(userPermissions.organizations);
  } else {
    routes = getOrgRoutes(userPermissions.organizations);
  }
  const handleClick = () => {
    if (params.userId) {
      navigate(`users`);
    } else if (params.deviceId) {
      navigate(`devices`);
    } else navigate(`/customers/?tab=organizations`);
  };

  const handleName = () => {
    let name = '';
    if (params.userId) {
      if (singleUser) {
        name = singleUser?.first_name + ' ' + singleUser?.last_name;
      } else {
        name = '';
      }
    } else if (params.deviceId) {
      name = singleDeviceData ? singleDeviceData.serial_number : '';
    } else {
      name = organizationInfo ? organizationInfo?.org_name : '';
    }
    const truncatedName = name.length > 30 ? `${name.slice(0, 20)}...` : name;
    if (name.length > 30) {
      return (
        <Tooltip label={name} mt="-8px" fontSize="15px" data-testid="tooltip-orgName">
          {truncatedName}
        </Tooltip>
      );
    }
    return truncatedName;
  };

  const handleTitle = () => {
    return params.deviceId || params.userId ? organizationInfo?.org_name : 'Organizations';
  };

  useEffect(() => {
    if (params.userId) {
      dispatch(getSingleUserData(params.orgId, params.userId));
    } else if (params.deviceId) {
      dispatch(getSingleDevicesData(params.deviceId));
    }
  }, [params]);

  const getStatusText = () => {
    if (params.userId) {
      return orgUserStatus;
    }
    if (params.deviceId) {
      return singleDeviceData?.device_status.status;
    }
    if (params.orgId) {
      return organizationInfo?.status_name;
    }
  };

  useEffect(() => {
    dispatch(getStatus());
    dispatch(getDeviceStatus());
    dispatch(getOrganizationLicenses(params.orgId, ''));
    dispatch(getOrganizationDevices(params.orgId, ''));
    dispatch(getOrganisationInfoUsersData(params.orgId, ''));
    dispatch(getOrgUsersStatus());
    dispatch(getSingleOrganizationsData(params.orgId));
  }, []);

  return (
    <>
      <Box data-testid="org-info-container">
        <Box display="flex" h="90px" justifyContent="space-between" borderBottom="1px solid #3333331A" pl="15px">
          <Box data-testid="org-info-breadcumb">
            <Breadcrumb spacing="8px" pt="35px" separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  color="#24B0FF"
                  position="absolute"
                  top="14px"
                  onClick={handleClick}
                  display="flex"
                  fontSize="14px"
                  alignItems="center"
                  textTransform="capitalize"
                >
                  <RightChervonIcon />
                  <Text ml="3px">{handleTitle()}</Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            {organizationInfo && (
              <Flex gap="20px" alignItems={'center'} pl="15px">
                <Heading
                  fontSize="36px"
                  fontWeight="400"
                  fontFamily="Helvetica"
                  style={{ paddingLeft: '0px' }}
                  textTransform="capitalize"
                >
                  {handleName()}
                </Heading>
                <StatusBadge status={getStatusText()} />
                {showLicenseInfo && (
                  <Box>
                    <Flex alignItems="flex-start">
                      <FormLabel
                        color={colors.textColor}
                        opacity="0.6"
                        fontSize={{ xs: '10px', lg: '10px', xl: '10px' }}
                        mb="5px"
                        data-testid="container-label"
                        fontWeight="300"
                        pb="0"
                      >
                        <FormattedMessage id="license_bundle_id.message" />
                      </FormLabel>
                    </Flex>
                    <Box fontWeight="700" fontSize="13px" fontFamily="helvetica">
                      {organizationInfo.bundle_info.catalog_number ? (
                        <Text>
                          {organizationInfo.bundle_info.name} {'  '}#{organizationInfo.bundle_info.catalog_number}
                        </Text>
                      ) : (
                        '-'
                      )}
                    </Box>
                  </Box>
                )}
              </Flex>
            )}
          </Box>
        </Box>
        <Box display="flex" h={`calc(100vh - 180px)`}>
          <Box borderRight="1px solid #3333331A">
            <OrganizationInfoSidebar routes={routes} />
          </Box>
          <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 430px)' : 'calc(100vw - 300px)' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default SubLayout;
