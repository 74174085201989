import { Auth0Config } from '../types/types';

export default function getAuthConfig() {
  return {
    domain: localStorage.getItem('auth0_domain') || '',
    clientId: localStorage.getItem('auth0_clientId') || '',
    connection: localStorage.getItem('auth0_connection') || '',
    redirectURI: window.location.origin,
  } as Auth0Config;
}
