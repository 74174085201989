import { Box } from '@chakra-ui/react';

import React from 'react';

import OrgDevicesData from '../data/OrgLicenseData';
interface FilterProps {
  isFiltersApplied: boolean;
}
export default function OrgDevicesMainContent({ isFiltersApplied }: FilterProps) {
  return (
    <Box h={`calc(100vh - 250px)`} data-testid="org-devices">
      <OrgDevicesData isFiltersApplied={isFiltersApplied} />
    </Box>
  );
}
