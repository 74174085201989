import React from 'react';
import { Box, Text, Image, useColorModeValue, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import eclipse from '../../assets/images/eclipse.svg';
import { customColor } from '../../assets/styles/colors';

const ApiError: React.FC = () => {
  const colors = customColor();
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');

  return (
    <Box display="flex" h={{ xs: 'calc(100vh - 140px)', lg: 'calc(100vh - 140px)' }}>
      <Box
        w={{ xs: '100%', md: '60%' }}
        ml={{ xs: '20px', lg: '15%' }}
        display="flex"
        mt="-150px"
        flexDirection="column"
        justifyContent="center"
        data-testid="api-error"
        fontFamily="Helvetica"
      >
        <Box h="37px">
          <Text fontSize="31px" fontWeight="300" lineHeight="35.65px">
            <FormattedMessage id="api_error.message" />
          </Text>
        </Box>
        <Box h="62px">
          <Text fontSize="44px" fontWeight="700" lineHeight="50.6px">
            <FormattedMessage id="retry.message" />
          </Text>
        </Box>
        <Box h="20px">
          <Text color="#333333" opacity="0.6" fontSize="18px" fontWeight="400" lineHeight="20.7px">
            <FormattedMessage id="api_error_try_again.message" />
          </Text>
        </Box>
        <Box h="40px" mt="24px">
          <Button
            variant="outline"
            type="button"
            width="140px"
            height="40px"
            borderRadius="20px"
            color={blueColorMode}
            cursor={'pointer'}
            fill={blueColorMode}
            borderColor={blueColorMode}
            fontSize="10px"
            data-testid="retry-button"
            _hover={{
              bg: blueColorMode,
              color: blackAndWhiteColorMode,
            }}
            onClick={() => window.location.reload()}
          >
            <Text casing="uppercase" fontSize="10px" fontWeight="800" lineHeight="14.7px" letterSpacing="2px">
              <FormattedMessage id="retry.message" />
            </Text>
          </Button>
        </Box>
      </Box>
      <Box
        w={{ xs: '0%', md: '40%' }}
        h="100%"
        position="relative"
        alignItems="center"
        display="flex"
        data-testid="Image"
      >
        <Image
          display={{ xs: 'none', md: 'block' }}
          src={eclipse}
          minW="521px"
          minH="521px"
          position="absolute"
          right={{ md: '-300px' }}
          data-testid="image-landingsection"
        />
      </Box>
    </Box>
  );
};

export default ApiError;
