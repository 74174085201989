/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Wrap,
  WrapItem,
  Icon,
  Circle,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import { getDeviceTYpeStatusInputValue } from 'redux/deviceTypes/Reducers/DeviceTypeSlice';
import StatusInfo from 'components/status/StatusInfo';

const DeviceTypeStatusInputField = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();

  const [deviceStatusValue, setDeviceStatusValue] = useState<any>('');
  const deviceStatusData = useAppSelector((state) => state.deviceTypes.deviceTypeStatusDataSuccess);

  const handleDevice = (status_id: string) => {
    setDeviceStatusValue(Number(status_id));
    dispatch(getDeviceTYpeStatusInputValue(status_id.toString()));
  };

  const status = !!deviceStatusData.length && deviceStatusData.find((item: any) => item.status_id == deviceStatusValue);

  const renderItems = (data: any) => {
    return data.map((state: any) => (
      <MenuItem
        id={state.id}
        key={state.status_id}
        value={state.status_id}
        name={state.status_name}
        backgroundColor="transparent"
        _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
        onClick={() => handleDevice(state.status_id)}
      >
        <Box display="flex">
          <StatusInfo indicatorSize="6px" label={state.status_name} indicatorColor={state.color_code} />
        </Box>
      </MenuItem>
    ));
  };

  const renderMenuItems: JSX.Element[] = useMemo(() => renderItems(deviceStatusData), [deviceStatusData]);

  return (
    <Box>
      <Wrap spacing="30px" mt="-5px" ml="2px">
        <WrapItem>
          <Box minH="90px">
            <InputLabel value={deviceStatusValue} name="status" />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                    borderBottom="1px solid"
                    borderRadius="0px"
                    _focusVisible={{ borderBottomColor: colors.primaryColor }}
                    borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                    variant="flushed"
                    data-testid="license-info"
                    paddingTop={'5px'}
                    rightIcon={
                      isOpen ? (
                        <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                      ) : (
                        <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                      )
                    }
                  >
                    {deviceStatusValue ? (
                      <Box display="flex" ml="-15px" alignItems="center" fontWeight="400" fontSize="14px">
                        <StatusInfo
                          indicatorSize="6px"
                          label={status?.status_name}
                          indicatorColor={status?.color_code}
                        />
                      </Box>
                    ) : (
                      <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                        <FormattedMessage id="status.message" />
                      </Text>
                    )}
                  </MenuButton>
                  <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} fontSize="14px">
                    {!!deviceStatusData.length && renderMenuItems}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default DeviceTypeStatusInputField;
