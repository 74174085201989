import { Button } from '@chakra-ui/react';
import * as React from 'react';

type LogoutProps = {
  handleLogout: () => void,
};

const Logout = ({ handleLogout }: LogoutProps) => {
  return (
    <Button onClick={handleLogout} type="submit" data-testid="logout">
      LOGOUT
    </Button>
  );
};

export default Logout;
