import { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleAddOrgModal } from 'redux/organizations/Reducers/organizationSlice';
import { getOrganizations, postOrganizationsData } from 'redux/organizations/Actions/organizationActions';
import { clearSearchResults } from 'redux/customers/customersSlice';
import { customColor } from 'assets/styles/colors';
import { InputCutomerAutoComplete } from 'components/inputAutoComplete/InputCustomerAutoComplete';
import SubmitButton from './submitButton';
import { customer } from 'types/customer';
import { LeftIndicatorIcon } from 'assets/icons/icons';
import SelectedCustomerInfo from './selectedCustomerInfo';
import AddOrgInfo from './addOrgInfo';
import { useFormik } from 'formik';
import { addOrgValidationSchema, initialState } from './addOrgValidationSchema';
import { capitalizeText } from '@exo/shared-ui';

const addOrganizationSteps = ['customer', 'organization'];

const AddOrgModalPopup = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();

  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const isAddOrgModalOpen = useAppSelector((state) => state.organization.isAddOrgModalOpen);
  const isOrganizationNameDataLoading = useAppSelector((state) => state.organization.organizationNameDataLoading);
  const organizationPostDataLoading = useAppSelector((state) => state.organization.organizationPostDataLoading);
  const createOrganizationApiFail = useAppSelector((state) => state.organization.createOrganizationApiFail);
  const [currentStep, setCurrentStep] = useState(addOrganizationSteps[0]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<customer>();
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [isAddFail, setIsAddFail] = useState(false);
  const [isAddSuccess, setIsAddSuccess] = useState(false);

  const initialValues = initialState;
  const addOrgForm = useFormik({
    initialValues,
    validationSchema: addOrgValidationSchema(),
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission logic here
    },
  });

  useEffect(() => {
    setIsAddDisabled(!addOrgForm.dirty || Object.keys(addOrgForm.errors).length != 0);
    setIsAddFail(false);
  }, [addOrgForm]);

  useEffect(() => {
    if (organizationPostDataLoading) {
      setIsAddSuccess(false);
      setIsAddFail(false);
    }
    if (!organizationPostDataLoading && createOrganizationApiFail && createOrganizationApiFail === 'SUCCESS') {
      setIsAddSuccess(true);
      setIsAddFail(false);
      setTimeout(() => {
        handleModalClose();
        dispatch(getOrganizations());
      }, 1500);
    } else {
      setIsAddFail(true);
    }
  }, [createOrganizationApiFail, organizationPostDataLoading]);

  const handleModalClose = () => {
    dispatch(toggleAddOrgModal(false));
    dispatch(clearSearchResults());
    setCurrentStep(addOrganizationSteps[0]);
    addOrgForm.resetForm();
    setSelectedCustomer(undefined);
    setIsNextButtonDisabled(true);
  };

  const handleCustomerSelect = (customer: customer) => {
    setSelectedCustomer(customer);
    setIsNextButtonDisabled(false);
  };

  const handleClearSelectedCustomer = () => {
    setSelectedCustomer(undefined);
    setIsNextButtonDisabled(true);
  };

  const handleNext = () => {
    setCurrentStep(addOrganizationSteps[1]);
    addOrgForm.resetForm();
    setIsAddSuccess(false);
    setIsAddFail(false);
  };

  const handleBackBtn = () => {
    setCurrentStep(addOrganizationSteps[0]);
  };

  const handleOrgAddBtn = () => {
    const orgInfo = addOrgForm.values;
    const postData = {
      org_code: orgInfo.orgCode,
      org_name: capitalizeText(orgInfo.organizationName),
      country_code: orgInfo.country.id,
      language_code: orgInfo.language.id,
      tier: 1,
      created_by: 'PULSE_USER',
      org_owner_first_name: capitalizeText(orgInfo.ownerFirstName),
      org_owner_last_name: capitalizeText(orgInfo.ownerLastName),
      org_owner_email: orgInfo.ownerEmail,
      org_owner_phone: orgInfo.phone,
      country_phone_code: orgInfo.phone_code,
      status: 7,
      org_id: '',
      customer_id: selectedCustomer?.customer_id,
    };
    dispatch(postOrganizationsData(postData));
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isAddOrgModalOpen}
      onClose={handleModalClose}
      border-radius="0.1px"
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '368px', smd: '720px', md: '720px', lg: '720px', xl: '720px', dt: '720px' }} mt="80px">
        <ModalHeader fontSize="24px" ml="8px">
          <FormattedMessage id="new_organization.message" />
        </ModalHeader>
        <ModalCloseButton disabled={false} color={closeIconColor} backgroundColor="transparent" />
        <ModalBody ml="7px">
          {currentStep === addOrganizationSteps[0] && (
            <Box data-test-id="select-customer" height="300px">
              <Text color={colors.textColor} fontSize="14px" mb="20px">
                Select a customer to add an organization.
              </Text>
              <InputCutomerAutoComplete
                textSize="13px"
                optionsStyle={{ zIndex: '999', backgroundColor: 'white' }}
                onSelect={handleCustomerSelect}
                clearSelectedCustomer={handleClearSelectedCustomer}
                selectedCustomer={selectedCustomer}
              />
              <SelectedCustomerInfo customerInfo={selectedCustomer} />
            </Box>
          )}
          {currentStep === addOrganizationSteps[1] && selectedCustomer && (
            <Box data-test-id="add-org-info">
              <SelectedCustomerInfo customerInfo={selectedCustomer} />
              <AddOrgInfo orgInfo={addOrgForm} customerInfo={selectedCustomer} />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          {currentStep === addOrganizationSteps[0] && (
            <SubmitButton
              label={<FormattedMessage id="add_modal_next.message" />}
              isDisbaled={isNextButtonDisabled}
              handleSubmit={handleNext}
            />
          )}
          {currentStep === addOrganizationSteps[1] && (
            <Flex justifyContent="space-between" width="100%">
              <Box display="flex" alignItems="center" onClick={handleBackBtn}>
                <Text color={colors.primaryColor} cursor="pointer" mt="10px">
                  <LeftIndicatorIcon />
                </Text>
                <Text color={colors.primaryColor} cursor="pointer">
                  <FormattedMessage id="modify_customer.message" />
                </Text>
              </Box>
              <SubmitButton
                label={<FormattedMessage id="add_modal.message" />}
                isFail={isAddFail}
                isSucces={isAddSuccess}
                isDisbaled={isAddDisabled || isOrganizationNameDataLoading || organizationPostDataLoading}
                handleSubmit={handleOrgAddBtn}
                isLoading={organizationPostDataLoading}
              />
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddOrgModalPopup;
