import { Box } from '@chakra-ui/react';
import { useAppSelector } from 'redux/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import moment from 'moment';
import { userAccessConst } from 'utils/constants/userAccessConst';

function OrgDevicesListView() {
  const singleOrganizationDeviceData = useAppSelector((state) => state.organizationdevice.organizationDevicesList);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);

  const route = 'devices';
  const navigate = useNavigate();
  const data = Array.isArray(singleOrganizationDeviceData)
    ? singleOrganizationDeviceData &&
      singleOrganizationDeviceData.map((device: any) => {
        return {
          id: device.id,
          serial_number: device?.serial_number,
          two_rows: device?.catalogue + ' ' + device?.internal_name + '*' + device?.hardware_version,
          device_status: device?.status.device_status + '*' + device?.status.color_code,
          udid: device?.udid,
          manufacture_date: device.manufacture_date ? (
            moment(device.manufacture_date).format('ll')
          ) : (
            <FormattedMessage id="empty.message" />
          ),
          last_used_by_devices:
            device.last_used_by + '*' + (device.last_used_on ? moment(device.last_used_on).format('ll') : '-'),
        };
      })
    : [];
  const columns = [
    {
      field: 'serial_number',
      header: <FormattedMessage id="serial#.message" />,
      link: userPermissions.organizations?.includes(userAccessConst.organization.viewDevice),
    },
    { field: 'two_rows', header: <FormattedMessage id="device_modal.message" />, link: false, twoRows: true },
    { field: 'manufacture_date', header: <FormattedMessage id="manufactured_date.message" />, link: false },
    { field: 'udid', header: <FormattedMessage id="device_identifier.message" />, link: false },
    { field: 'last_used_by_devices', header: <FormattedMessage id="last_used_by.message" />, link: false },
    { field: 'device_status', header: <FormattedMessage id="status_field.message" />, link: false, color: false },
  ];

  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'serial_number') {
      navigate(`${row.id}/info`);
    } else {
      navigate(``);
    }
  };

  return (
    <Box data-testid="org-device-list">
      <CustomTable data={data} columns={columns} route={route} handleNavigateFromChild={handleNavigateFromChild} />{' '}
    </Box>
  );
}
export default OrgDevicesListView;
